import React from "react";
import { connect } from "react-redux";
import {
  getIntercomUserDataAction,
  GetIntercomUserDataSchema,
  AppBasicInfoSchema,
  SiteResponseSchema
} from "../../redux/actions/authActions";
import CryptoJS from "crypto-js";

import { RootSchema } from "../../redux/reducers";
import {
  getApiKeyAction,
  GetApiKeySchema
} from "../../redux/actions/platformConfigurationActions";
export interface IntercomProps {
  intercom: any; //reference to obj in redux store
  apikey: string;
  appName: string;
  intercomUserData: GetIntercomUserDataSchema;
  helpWidgetParam: GetApiKeySchema;
  getHelpWidgetParam: () => void;
  basicInfo: AppBasicInfoSchema;
  appInfo: SiteResponseSchema;
}

export interface IntercomState {}

class Intercom extends React.Component<IntercomProps, IntercomState> {
  componentDidMount() {
    //this.intercomHelper();
    this.props.getHelpWidgetParam();
  }

  componentDidUpdate(prevProps) {
    const { apiKey: oldApiKey, appName: oldAppName } = prevProps;
    const { apikey, appName, basicInfo } = this.props;

    if (
      (apikey !== oldApiKey || appName !== oldAppName) &&
      basicInfo.isSuccess
    ) {
      this.intercomHelper();
    }
  }

  intercomHelper() {
    const { helpWidgetParam, basicInfo } = this.props;
    let plan =
      basicInfo.plan_detail && basicInfo.plan_detail.name
        ? basicInfo.plan_detail.name.toLowerCase()
        : "";
    if (window.LoginRadiusV2) {
      var commonOptions: any = {};
      commonOptions.apiKey = this.props.apikey;
      commonOptions.appName = this.props.appName;

      var LRObject = new window.LoginRadiusV2(commonOptions);
      let intercomData = { ...this.props.intercom.response };
      let customData = {};
      if (
        intercomData.role &&
        intercomData.role.length &&
        typeof intercomData.role === "string"
      ) {
        intercomData.role = intercomData.role.split(",");
        intercomData.role = intercomData.role.map(
          e => this.props.appInfo.AppName + "_" + e.replace(/ /g, "_")
        );
      }
      if (intercomData && intercomData.email && helpWidgetParam.isSuccess) {
        intercomData.environment =
          "secure.loginradius.com" === window.location.hostname
            ? "Production"
            : "Development";
        delete intercomData.lastActivityDetail;

        if (
          (intercomData.company != null && intercomData.email != null) ||
          (intercomData.company =
            intercomData.email != null
              ? intercomData.email.split("@")[1]
              : "Unknown")
        ) {
          // ignore linting errors on these object keys since it is in the format to be sent to Intercom
          let name = intercomData.name;
          if (!intercomData.name && intercomData.email) {
            name = intercomData.email.split("@")[0];
          }
          customData = {
            /* eslint-disable @typescript-eslint/camelcase*/
            name: name,
            email: intercomData.email,
            phone: intercomData.phone,
            role: intercomData.role
          };
        }
        if (window.sh_init) {
          window.sh_init(
            {
              templateId: "sh_interfacesection_wrap",
              supportSetting: {
                Url: "/support/tickets",
                Target: "_self",
                Hide: plan === "free" ? true : false
              },
              chatWidgetSetting: {
                //assign intercom id and userData to intercom settings
                App_Id: helpWidgetParam.zendeskKey,
                profile: customData
              },
              algoliaSetting: {
                apiKey: helpWidgetParam.algoliaId,
                indexName: "loginradius",
                inputSelector: "#algoliasearch",
                debug: false,
                autocompleteOptions: {
                  autoselect: false
                }
              },
              googleSetting: {
                eventName: "SearchedText"
              }
            },
            LRObject
          );
        }
      }
    }
  }

  render() {
    return false;
  }
}

const mapStateToProps = (state: RootSchema) => {
  return {
    intercom: state.auth.intercomUserData, //gets obj from redux store
    apikey: state.auth.authConfig.apikey,
    appName: state.auth.authConfig.appName,
    intercomUserData: state.auth.intercomUserData,
    helpWidgetParam: state.platformConfiguration.getApiKey,
    basicInfo: state.auth.appBasicInfo,
    appInfo: state.auth.currentAppinfo
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getHelpWidgetParam: () => dispatch(getApiKeyAction())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Intercom);

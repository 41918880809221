import {
  BrandCustomizationConst,
  ControlValuesDefault,
  ControlValuesSchema,
  LoginTemplateValuesDefault,
  LoginTemplateValuesSchema
} from "../actions/brandCustomizationActions";
import { combineReducers } from "redux";
import { ActionSchema } from "../actions";

// Reducers
const setControlValuesReducer = (
  state = ControlValuesDefault,
  action: ActionSchema<ControlValuesSchema>
) => {
  switch (action.type) {
    case BrandCustomizationConst.SET_CONTROL_VALUES_LOADING:
    case BrandCustomizationConst.SET_CONTROL_VALUES_ERROR:
    case BrandCustomizationConst.SET_CONTROL_VALUES_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const setLoginTemplateValuesReducer = (
  state = LoginTemplateValuesDefault,
  action: ActionSchema<LoginTemplateValuesSchema>
) => {
  switch (action.type) {
    case BrandCustomizationConst.SET_LOGIN_TEMPLATE_VALUES_LOADING:
    case BrandCustomizationConst.SET_LOGIN_TEMPLATE_VALUES_ERROR:
    case BrandCustomizationConst.SET_LOGIN_TEMPLATE_VALUES_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

// Combine Reducers
const brandCustomizationReducer = combineReducers({
  controlValues: setControlValuesReducer,
  loginTemplateValues: setLoginTemplateValuesReducer
});

// Combine Schemas
export interface BrandCustomizationSchema {
  controlValues: ControlValuesSchema;
  loginTemplateValues: LoginTemplateValuesSchema;
}

export default brandCustomizationReducer;

import en from "./en";
import sp from "./sp";
import fr from "./fr";
import si from "./si";
import tr from "./tr";

export interface LanguageSchema {
  [key: string]: string;
}

export interface I18NSchema {
  [locale: string]: LanguageSchema;
}

const languages: I18NSchema = {
  en,
  sp,
  fr,
  si,
  tr
};

const loadLanguage = (locale = "en") => {
  return languages[locale];
};

export default loadLanguage;

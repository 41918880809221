import {
  generateConst,
  loadingAction,
  errorAction,
  successSideEffectState,
  SideEffectSchema,
  ObjectSchema,
  V2ErrorResponseSchema
} from ".";
import { QuerySchema } from "../../components/QueryBuilder";
import { UserSchema } from "./customerManagementActions";

// Constants
export const SegmentationConst = generateConst("SEGMENTATIONS", [
  "GET_CUSTOMFIELDS",
  "POST_USER_SEGMENTATION_QUERY",
  "GET_SEGMENTATIONS_TOP_TEN_USERS",
  "GET_SAVED_SEGMENTS",
  "DELETE_SEGMENT",
  "GET_ALL_CUSTOM_OBJECTS",
  "GET_CUSTOMOBJ_TOPTEN_USERS",
  "GET_CUSTOMOBJ_USERS"
]);

export interface CustomFieldObjSchema {
  Key: string;
  Display: string;
}

export interface GetCustomFieldsSchema extends SideEffectSchema {
  clientIP: string;
  Data?: CustomFieldObjSchema[];
  description?: string;
  errorCode?: number;
  message?: string;
}

export const getCustomFieldsAction = () => {
  return {
    type: SegmentationConst.GET_CUSTOMFIELDS
  };
};

export const getCustomFieldsLoadingAction = () =>
  loadingAction(SegmentationConst.GET_CUSTOMFIELDS_LOADING);
export const getCustomFieldsErrorAction = (error: string) =>
  errorAction(SegmentationConst.GET_CUSTOMFIELDS_ERROR, error);

export const getCustomFieldsSuccessAction = (data: any) => {
  const payload: GetCustomFieldsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: SegmentationConst.GET_CUSTOMFIELDS_SUCCESS,
    payload
  };
};

export interface GetTopTenResponseSchema {
  users: UserSchema[];
  totalUsers: number;
  clientIP: string;
  status?: number;
  description?: string;
  errorCode?: number;
  message?: string;
}

export interface GetTopTenSchema extends SideEffectSchema {
  response: GetTopTenResponseSchema;
}

export const getTopTenUsersAction = () => {
  return {
    type: SegmentationConst.GET_SEGMENTATIONS_TOP_TEN_USERS
  };
};

export const getTopTenUsersLoadingAction = () =>
  loadingAction(SegmentationConst.GET_SEGMENTATIONS_TOP_TEN_USERS_LOADING);
export const getTopTenUsersErrorAction = (error: string) =>
  errorAction(SegmentationConst.GET_SEGMENTATIONS_TOP_TEN_USERS_ERROR, error);

export const getTopTenUsersSuccessAction = (response: GetTopTenSchema) => {
  const payload: GetTopTenSchema = {
    ...response,
    ...successSideEffectState
  };
  return {
    type: SegmentationConst.GET_SEGMENTATIONS_TOP_TEN_USERS_SUCCESS,
    payload
  };
};

//Saved Segmentation Tab

export interface SegmentSchema {
  CustomObject: {
    appid: string;
    segmentation: any;
    segmentationname: string;
    userid: string;
  };
  DateCreated: string;
  DateModified: string;
  Id: string;
  IsActive: boolean;
  IsDeleted: boolean;
  Uid: string;
}

export interface GetSavedSegmentsSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  segmentations: SegmentSchema[];
}

export const getSavedSegmentsAction = () => {
  return {
    type: SegmentationConst.GET_SAVED_SEGMENTS
  };
};

export const getSavedSegmentsLoadingAction = () =>
  loadingAction(SegmentationConst.GET_SAVED_SEGMENTS_LOADING);

export const getSavedSegmentsErrorAction = (error: string) =>
  errorAction(SegmentationConst.GET_SAVED_SEGMENTS_ERROR, error);

export const getSavedSegmentsSuccessAction = (data: any) => {
  const payload: GetSavedSegmentsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: SegmentationConst.GET_SAVED_SEGMENTS_SUCCESS,
    payload
  };
};

export interface GetSegmentationsUsersQueryParameters {
  itemsPerPage: number;
  pagenumber: number;
}

export interface PostUserSegmentationQueryResponseSchema
  extends GetTopTenResponseSchema,
    V2ErrorResponseSchema {}

export interface PostUserSegmentationQuerySchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  response: PostUserSegmentationQueryResponseSchema;
}

export const postUserSegmentationQueryAction = (
  filter: QuerySchema,
  queryParameters: GetSegmentationsUsersQueryParameters
) => {
  return {
    type: SegmentationConst.POST_USER_SEGMENTATION_QUERY,
    payload: {
      filter,
      queryParameters
    }
  };
};

export const postUserSegmentationQueryLoadingAction = () =>
  loadingAction(SegmentationConst.POST_USER_SEGMENTATION_QUERY_LOADING);

export const postUserSegmentationQueryErrorAction = (error: string) =>
  errorAction(SegmentationConst.POST_USER_SEGMENTATION_QUERY_ERROR, error);

export const postUserSegmentationQuerySuccessAction = (
  data: PostUserSegmentationQuerySchema
) => {
  const payload: PostUserSegmentationQuerySchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: SegmentationConst.POST_USER_SEGMENTATION_QUERY_SUCCESS,
    payload
  };
};
export interface DeleteSegmentSchema extends SideEffectSchema {
  data: any;
}

export const deleteSegmentAction = (segID: string) => {
  return {
    type: SegmentationConst.DELETE_SEGMENT,
    payload: { id: segID }
  };
};

export const deleteSegmentLoadingAction = () =>
  loadingAction(SegmentationConst.DELETE_SEGMENT_LOADING);

export const deleteSegmentErrorAction = (error: string) =>
  errorAction(SegmentationConst.DELETE_SEGMENT_ERROR, error);

export const deleteSegmentSuccessAction = (data: DeleteSegmentSchema) => {
  const payload: DeleteSegmentSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: SegmentationConst.DELETE_SEGMENT_SUCCESS,
    payload
  };
};

// Get all customobjects
export interface CustomObjectSchema {
  title: string;
  type: string;
  properties: any;
  required: string[];
}
export interface CustomObjectDataSchema {
  CustomObjectSchema: CustomObjectSchema;
  CustomObjectId: string;
  CustomObjectName: string;
  CustomObjectSize: number;
  IsClientSideDisable: boolean;
}
export interface GetAllCustomObjectsSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  Data: CustomObjectDataSchema[];
  clientIP: string;
  description?: string;
  errorCode?: string;
  message?: string;
}

export const getAllCustomObjectsAction = () => {
  return {
    type: SegmentationConst.GET_ALL_CUSTOM_OBJECTS,
    payload: {}
  };
};

export const getAllCustomObjectsLoadingAction = () =>
  loadingAction(SegmentationConst.GET_ALL_CUSTOM_OBJECTS_LOADING);

export const getAllCustomObjectsErrorAction = (error: string) =>
  errorAction(SegmentationConst.GET_ALL_CUSTOM_OBJECTS_ERROR, error);

export const getAllCustomObjectsSuccessAction = (
  data: GetAllCustomObjectsSchema
) => {
  const payload: GetAllCustomObjectsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: SegmentationConst.GET_ALL_CUSTOM_OBJECTS_SUCCESS,
    payload
  };
};

interface CustomObjUser {
  IsActive: boolean;
  IsDeleted: boolean;
  DateCreated: string;
  DateModified: string;
  Uid: string;
  CustomObject: ObjectSchema<any>;
  _id: string;
}

export interface GetCustomObjUsersSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  totalUsers: number;
  users: CustomObjUser[];
  clientIP: string;
  description?: string;
  errorCode?: string;
  message?: string;
  status?: number;
}

// Get top ten Custom Object Users
export const getCustomObjTopTenUsersAction = (customObject: string) => {
  return {
    type: SegmentationConst.GET_CUSTOMOBJ_TOPTEN_USERS,
    payload: { customObject }
  };
};

export const getCustomObjTopTenUsersLoadingAction = () =>
  loadingAction(SegmentationConst.GET_CUSTOMOBJ_TOPTEN_USERS_LOADING);

export const getCustomObjTopTenUsersErrorAction = (error: string) =>
  errorAction(SegmentationConst.GET_CUSTOMOBJ_TOPTEN_USERS_ERROR, error);

export const getCustomObjTopTenUsersSuccessAction = (
  data: GetCustomObjUsersSchema
) => {
  const payload: GetCustomObjUsersSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: SegmentationConst.GET_CUSTOMOBJ_TOPTEN_USERS_SUCCESS,
    payload
  };
};

// Get Custom Objects Filtered Users
export const getCustomObjUsersAction = (
  filter: QuerySchema,
  name: string,
  queryParameters: GetSegmentationsUsersQueryParameters
) => {
  return {
    type: SegmentationConst.GET_CUSTOMOBJ_USERS,
    payload: {
      filter,
      name,
      queryParameters
    }
  };
};

export const getCustomObjUsersLoadingAction = () =>
  loadingAction(SegmentationConst.GET_CUSTOMOBJ_USERS_LOADING);

export const getCustomObjUsersErrorAction = (error: string) =>
  errorAction(SegmentationConst.GET_CUSTOMOBJ_USERS_ERROR, error);

export const getCustomObjUsersSuccessAction = (
  data: GetCustomObjUsersSchema
) => {
  const payload: GetCustomObjUsersSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: SegmentationConst.GET_CUSTOMOBJ_USERS_SUCCESS,
    payload
  };
};

import moment from "moment";
import { convertTimezone } from ".";

export const capitalizeFirstLetter = (str: string) => {
  if (str && str.charAt(0)) return str.charAt(0).toUpperCase() + str.slice(1);
  else return str;
};

export const capitalizeFirstLetterOfEachWord = (str: string) => {
  return str.replace(/\w\S*/g, capitalizeFirstLetter);
};

export const removeSymbols = (str: string) => {
  /* eslint-disable no-useless-escape */
  return str
    ? str.replace(
        /* eslint-disable no-useless-escape */
        /[\s\!\@\#\$\%\^\&\*\(\)\-\+\=\{\}\[\]\:\;'\"\<\,\>\.\?\/]/g,
        ""
      )
    : "";
};

export const lowercaseFirstLetter = (str: string) => {
  str = str.trim();
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const toTitleCase = phrase => {
  return phrase
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const checkEmptyString = (str: string) => {
  return str.length === 0;
};

export const camelCaseToWords = (str?: string) => {
  return str
    ? str
        .split(/([A-Z][a-z]+)/)
        .filter(function(e) {
          return e;
        })
        .join(" ")
    : "";
};

export const wordsToCamelCase = (str?: string) => {
  return str
    ? str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return word.toUpperCase();
        })
        .replace(/\s+/g, "")
    : "";
};

/**
 * This function helps to format the date in the account timezone
 * @param date Date to be formatted
 * @param timezone Account Timezone
 */

export const _formatToTimeZone = (
  date: string,
  timezone: string,
  excludeTime?: boolean
) => {
  let formatedStartDate = convertTimezone(date, "+00:00", timezone).substr(
    0,
    19
  );
  if (excludeTime) {
    return moment(formatedStartDate).format("MM-DD-YYYY");
  }
  return moment(formatedStartDate).format("MM-DD-YYYY HH:mm:ss");
};

/**
 * Returns a string with the uppercase letter whose offset is the offset of the letter from the letter 'A';
 * for instance, offset = 0  means string 'A' will be returned. Offset = 2 means string 'C' will be returned.
 * @param offset Offset from letter 'A' to letter to be returned.
 */
export const getUppercaseLetterFromOffset = (offset: number) => {
  return String.fromCharCode(65 + offset);
};

/**
 * This function accepts a string or number and returns the US number format output.
 * E.g 1234567 => 1,234,567
 * -1234 => -1,234
 */
export const getFormattedNumber = (
  num?: number | string | null,
  errorLabel?: string
): string => {
  if (num === undefined || num === null) return errorLabel || "0";
  else {
    let isNegative = false;
    let numToString = typeof num === "number" ? num.toString() : num;
    if (numToString[0] === "-") {
      isNegative = true;
      numToString = numToString.slice(1);
    }

    if (numToString.length <= 3) {
      return isNegative ? "-" + numToString : numToString;
    } else {
      let formattedNumString = "";
      let i = numToString.length % 3;
      if (i === 0) {
        i = 3;
      }
      formattedNumString += numToString.slice(0, i);

      while (i < numToString.length) {
        formattedNumString += ",";
        formattedNumString += numToString.slice(i, i + 3);
        i += 3;
      }

      return isNegative ? "-" + formattedNumString : formattedNumString;
    }
  }
};

/**
 * This function will return the string with space add before each capital word
 * For eg : LastPasswordChangeDate will become "Last Password Change Date"
 * @param phrase The string to be converted
 */
export const addSpaceInCamelCaseString = (phrase: string) => {
  return phrase ? phrase.replace(/([a-z](?=[A-Z]))/g, "$1 ") : "";
};

export const extractTimezoneFromLocaleString = (localeString: string) => {
  const timezone = localeString.match(/[+-]\d\d:\d\d/g);
  return timezone ? timezone[0] : "+00:00";
};

export const convertDateAndTimeToAccountTimeZone = (
  date: Date,
  accountTimeZone: string
) => {
  const dateInLocalSystemTimeZone = moment(date)
    .local()
    .format();
  const localTimeZoneUTCDiff = dateInLocalSystemTimeZone.substr(19); // Necessary to restrict values that are valid in the account's time zone.
  const convertedTime: any =
    convertTimezone(dateInLocalSystemTimeZone, null, accountTimeZone).substr(
      0,
      19
    ) + localTimeZoneUTCDiff;

  return new Date(convertedTime).toString();
};

export const getFilterQueryString = (filterQuery: any) => {
  if (Object.keys(filterQuery).length) {
    return concatQueryString(filterQuery.group);
  }

  return "";
};

function concatRule(rule) {
  if (rule.operator === "BETWEEN") {
    rule.value = rule.value[0] + " & " + rule.value[1];
  }

  return rule.name + " " + rule.operator + " " + rule.value;
}

function concatQueryString(group) {
  if (!group) return "";

  let str = "(";

  for (let i = 0; i < group.rules.length; i++) {
    i > 0 && (str += " " + group.operator + " ");
    str += group.rules[i].group
      ? concatQueryString(group.rules[i].group)
      : concatRule(group.rules[i]);
  }

  return str + ")";
}

export const lrValidator = (
  passwordComplexity,
  validationRuleMessages,
  passwordValue = ""
) => {
  const validationRules = [
    "required",
    "exact_length",
    "greater_than",
    "less_than",
    "matches",
    "max_length",
    "min_length",
    "custom_validation",
    "alpha",
    "alpha_dash",
    "alpha_numeric",
    "alphanumeric_combo",
    "alpha_numeric_dash_combo",
    "decimal",
    "integer",
    "is_natural",
    "is_natural_no_zero",
    "numeric",
    "valid_base64",
    "valid_ca_zip",
    "valid_email",
    "valid_ip",
    "valid_url",
    "valid_phoneno",
    "valid_credit_card",
    "callback_valid_date"
  ];

  if (passwordComplexity && passwordComplexity.rules) {
    const passwordComplexityRules: string[] = passwordComplexity.rules.split(
      "|"
    );
    const validationEnabled = passwordComplexityRules.length;
    const rules = validationEnabled ? passwordComplexityRules : [];

    let passwordExpressionFail;
    let passwordRegExp = new RegExp("");
    let message = "";
    let flag = false;
    let numberFlag = false;

    if (rules && Array.isArray(rules) && rules.length) {
      rules.forEach((rule: string) => {
        // if any error which was mentioned before in the sequence is violated, return from here
        if (passwordExpressionFail) {
          return;
        }
        let ruleNumber;
        let ruleString;
        let regExpLoop;
        let InValidExp = false;
        try {
          ruleNumber = rule.match(/\d+/g);
          ruleNumber = ruleNumber.map(Number)[0];
        } catch (err) {
          ruleNumber = null;
        }
        try {
          ruleString = rule.match(/[a-zA-Z]+/g);
          ruleString = ruleString.map(String)[0];
        } catch (err) {
          ruleString = null;
        }
        if (ruleString === "required") {
          // If required rule is present and value is not there simple pass error message and return from here
          if (!passwordValue.trim()) {
            InValidExp = true;
            flag = true;
            passwordExpressionFail = validationRuleMessages.required;
            return;
          }
        }
        try {
          // If Custom Validation

          if (ruleString === "custom") {
            let ExpMessage = rule.split("###");
            let matchPattern = ExpMessage[0].replace("custom_validation[", "");

            // /matchParameter/
            if (ExpMessage.length === 2) {
              message = ExpMessage[1].replace("]", "");
            } else {
              message = "";
            }
            regExpLoop = new RegExp((matchPattern || "").trim());
            InValidExp = true;
            flag = true;
          }
        } catch (err) {
          regExpLoop = null;
          InValidExp = false;
        }

        if (
          [
            "alpha",
            "alpha_dash",
            "alpha_numeric",
            "alphanumeric_combo",
            "alpha_numeric_dash_combo",
            "numeric",
            "valid_base64",
            "valid_ca_zip",
            "valid_email",
            "valid_credit_card",
            "valid_ip",
            "valid_url",
            "valid_phoneno",
            "is_natural_no_zero",
            "decimal",
            "integer",
            "is_natural",
            "callback_valid_date"
          ].includes(rule)
        ) {
          if (rule === "alpha") {
            passwordRegExp = /^[a-z]+$/i;
          }
          if (rule === "alpha_dash") {
            passwordRegExp = /^[a-z0-9_\-]+$/i;
          }
          if (rule === "alpha_numeric") {
            passwordRegExp = /^[a-z0-9]+$/i;
          }
          if (rule === "alphanumeric_combo") {
            passwordRegExp = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/;
          }
          if (rule === "alpha_numeric_dash_combo") {
            passwordRegExp = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[-])[A-Za-z\d][A-Za-z\d-]+$/i;
          }
          if (rule === "numeric") {
            passwordRegExp = /^[0-9]+$/;
          }
          if (rule === "decimal") {
            passwordRegExp = /^\-?[0-9]*\.?[0-9]+$/;
          }
          if (rule === "integer") {
            passwordRegExp = /^\-?[0-9]+$/;
          }
          if (rule === "is_natural") {
            passwordRegExp = /^[0-9]+$/i;
          }
          if (rule === "valid_base64") {
            passwordRegExp = /[^a-zA-Z0-9\/\+=]/i;
          }
          if (rule === "valid_ca_zip") {
            passwordRegExp = /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/;
          }
          if (rule === "valid_email") {
            passwordRegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          }
          if (rule === "valid_ip") {
            passwordRegExp = /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})$/i;
          }
          if (rule === "valid_url") {
            passwordRegExp = /^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/;
          }
          if (rule === "valid_phoneno") {
            passwordRegExp = /^(\\+)|(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
          }
          if (rule === "valid_credit_card") {
            passwordRegExp = /^[\d\-\s]+$/;
          }
          if (rule === "is_natural_no_zero") {
            passwordRegExp = /^[1-9][0-9]*$/i;
          }
          if (rule === "callback_valid_date") {
            passwordRegExp = /^(0[1-9]|1[012])[\/\-](0[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
          }

          message = validationRuleMessages[rule];
          regExpLoop = new RegExp(passwordRegExp);
          InValidExp = true;
          flag = true;
        }

        if (validationEnabled > 0) {
          /**
           * If contains this many validations
           "exact_length",
           "greater_than",
           "less_than",
           "matches",
           "max_length",
           "min_length"
          */
          if (validationRules[4] === ruleString) {
            let matchParameter = rule
              .replace("matches[", "")
              .substr(0)
              .slice(0, -1);
            let matchPattern1 = rules[matchParameter];
            // /matchParameter/
            message = validationRuleMessages.matches.replace(
              "%s",
              matchParameter
            );
            regExpLoop = new RegExp((matchPattern1 || "").trim());
            InValidExp = true;
            flag = true;
          }
          if (ruleNumber && ruleString) {
            if (
              passwordValue.trim() &&
              ruleNumber !== passwordValue.length &&
              validationRules[1] === ruleString + "_length"
            ) {
              passwordExpressionFail = validationRuleMessages.exact_length.replace(
                "%s",
                ruleNumber
              );

              flag = false;
              numberFlag = true;
              return;
            } else {
              flag = true;
            }
            if (
              passwordValue.trim() &&
              (ruleNumber >= Number(passwordValue) ||
                isNaN(Number(passwordValue)) ||
                passwordValue.match(/^\-?[0-9]*\.?[0-9]+$/) === null) &&
              validationRules[2] === ruleString + "_than" &&
              flag
            ) {
              passwordExpressionFail = validationRuleMessages.greater_than.replace(
                "%s",
                ruleNumber
              );

              flag = false;
              numberFlag = true;
              return;
            } else {
              flag = true;
            }
            if (
              passwordValue.trim() &&
              (ruleNumber <= Number(passwordValue) ||
                isNaN(Number(passwordValue)) ||
                passwordValue.match(/^\-?[0-9]*\.?[0-9]+$/) === null) &&
              validationRules[3] === ruleString + "_than" &&
              flag
            ) {
              passwordExpressionFail = validationRuleMessages.less_than.replace(
                "%s",
                ruleNumber
              );

              numberFlag = true;
              flag = false;
              return;
            } else {
              flag = true;
            }
            if (
              ruleNumber < passwordValue.length &&
              validationRules[5] === ruleString + "_length" &&
              flag
            ) {
              passwordExpressionFail = validationRuleMessages.max_length.replace(
                "%s",
                ruleNumber
              );

              numberFlag = true;
              flag = false;
              return;
            } else {
              flag = true;
            }
            if (
              // removed the trim so it recognises whitespaces as well
              ruleNumber > passwordValue.length &&
              validationRules[6] === ruleString + "_length" &&
              flag
            ) {
              passwordExpressionFail = validationRuleMessages.min_length.replace(
                "%s",
                ruleNumber
              );

              numberFlag = true;
              return;
            } else {
              flag = true;
            }
          }
          if (regExpLoop && flag && !numberFlag && InValidExp) {
            if (!regExpLoop.test(passwordValue)) {
              passwordExpressionFail = message;
              flag = false;
            } else {
              if (
                rule === "callback_valid_date" &&
                !moment(passwordValue).isValid()
              ) {
                passwordExpressionFail = message;
                flag = false;
              } else {
                flag = true;
              }
            }
          }
        }
      });
    }
    return passwordExpressionFail;
  }
};

/**
 * From our legacy code: returns str after removing strings "$customfields$", "$advancedfields$",
 * and symbols [\])}[{(&\s|-] from it.
 * @param str   String to be altered.
 */
export const removeCurlyBrackets = (str: string) => {
  str = str.replace("$customfields$", "").replace("$advancedfields$", "");
  return str.replace(/[\])}[{(&\s|-]/g, "");
};

/**
 * This method checks whether the URL of the selected tab item matching the path.
 * It also removes any trailing # from the link.
 * @param itemLink url of the selected tba item
 */
export const itemLinkEqualsCurrentPath = (itemLink: string) => {
  return (
    location.pathname ===
    (itemLink[itemLink.length - 1] === "#"
      ? itemLink.substr(0, itemLink.length - 1)
      : itemLink)
  );
};

/**
 * A function which will generate string For eg: "happy-unicorn-567"
 */
export const generateRandomString = (): string => {
  const adjectives = ["happy", "crazy", "sunny", "genuine", "wild"];
  const nouns = ["unicorn", "tiger", "ocean", "star", "robot"];

  const randomAdjective =
    adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
  const randomNumber = Math.floor(Math.random() * 900) + 100; // Generate a 3-digit number

  return `${randomAdjective}-${randomNoun}-${randomNumber}`;
};

export const removeSpacesFromString = (str: string) => {
  return str.replace(/\s/g, "");
};
export const isValidDomain = (value: string) => {
  if (value.trim()) {
    const regex = /(^|\s)(?!\S*\/)(?!www)([\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)$/;
    return regex.test(value);
  } else {
    return false;
  }
};
export const checkURLEncodedFormat = (value: string) => {
  if (value.trim()) {
    const regex = new RegExp(
      "^(([a-zA-Z 0-9])+([(0-9)*])?=[a-zA-Z+ 0-9%]+&?)*([a-zA-Z0-9])+([?(0-9)* ]?)=[a-zA-Z+0-9% ]+$"
    );
    return regex.test(value);
  } else {
    return false;
  }
};

export const getLastWord = (inputString: string) => {
  // Use regex to match the last word in the string
  const match = inputString.match(/\w+\b/g);

  // Check if a match is found
  if (match && match.length > 0) {
    // Return the last word
    return match[match.length - 1];
  } else {
    // Return an empty string if no words are found
    return "";
  }
};

export const stripSlashes = (str: string) => {
  return str.replace(/^\/+|\/+$/g, "");
};

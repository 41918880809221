/* eslint-disable @typescript-eslint/camelcase */

import { call, put, select, take, takeLatest } from "redux-saga/effects";
import { httpDelete, httpGet, httpPost, httpPut } from "../../services";

import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

import { ActionSchema } from "../actions";
import {
  addRoleContextErrorAction,
  addRoleContextLoadingAction,
  AddRoleContextSchema,
  addRoleContextSuccessAction,
  addSecondaryEmailErrorAction,
  addSecondaryEmailLoadingAction,
  AddSecondaryEmailSchema,
  addSecondaryEmailSuccessAction,
  addUserErrorAction,
  addUserLoadingAction,
  AddUserSchema,
  addUserSuccessAction,
  assignRolesErrorAction,
  assignRolesLoadingAction,
  AssignRolesSchema,
  assignRolesSuccessAction,
  blockOrUnblockUserErrorAction,
  blockOrUnblockUserLoadingAction,
  BlockOrUnblockUserSchema,
  blockOrUnblockUserSuccessAction,
  CustomerManagementConst,
  deleteRoleContextErrorAction,
  deleteRoleContextLoadingAction,
  DeleteRoleContextSchema,
  deleteRoleContextSuccessAction,
  deleteUserErrorAction,
  deleteUserLoadingAction,
  DeleteUserSchema,
  deleteUserSuccessAction,
  EmailAddressPayloadSchema,
  EmailTemplatesSchema,
  EnabledFieldSettingsSchema,
  getAllEmailTemplatesErrorAction,
  getAllEmailTemplatesLoadingAction,
  getAllEmailTemplatesSuccessAction,
  getAssignedRoleErrorAction,
  getAssignedRoleLoadingAction,
  GetAssignedRoleSchema,
  getAssignedRoleSuccessAction,
  getConsentLogsErrorAction,
  getConsentLogsLoadingAction,
  getConsentLogsSuccessAction,
  GetConsumerAuditLogsArgs,
  GetConsumerAuditLogsByUIDArgs,
  getConsumerAuditLogsByUIDErrorAction,
  getConsumerAuditLogsByUIDLoadingAction,
  getConsumerAuditLogsByUIDSuccessAction,
  getConsumerAuditLogsErrorAction,
  getConsumerAuditLogsLoadingAction,
  GetConsumerAuditLogsSchema,
  getConsumerAuditLogsSuccessAction,
  getCustomFieldsErrorAction,
  getCustomFieldsLoadingAction,
  GetCustomFieldsSchema,
  getCustomFieldsSuccessAction,
  getCustomObjectDataErrorAction,
  getCustomObjectDataLoadingAction,
  GetCustomObjectDataSchema,
  getCustomObjectDataSuccessAction,
  getCustomObjectsErrorAction,
  getCustomObjectsLoadingAction,
  GetCustomObjectsPayloadSchema,
  getCustomObjectsSuccessAction,
  getEmailTemplatesErrorAction,
  getEmailTemplatesLoadingAction,
  getEmailTemplatesSuccessAction,
  getESFieldsErrorAction,
  getESFieldsLoadingAction,
  GetESFieldsSchema,
  getESFieldsSuccessAction,
  getPrivacyPolicyHistoryErrorAction,
  getPrivacyPolicyHistoryLoadingAction,
  GetPrivacyPolicyHistorySchema,
  getPrivacyPolicyHistorySuccessAction,
  getRoleContextErrorAction,
  getRoleContextLoadingAction,
  GetRoleContextSchema,
  getRoleContextSuccessAction,
  GetSearchUsersSchema,
  getTotalUsersErrorAction,
  getTotalUsersLoadingAction,
  getTotalUsersSuccessAction,
  getTwoFASettingsErrorAction,
  getTwoFASettingsLoadingAction,
  GetTwoFASettingsSchema,
  getTwoFASettingsSuccessAction,
  GetUserBySearchFieldsArgs,
  getUserBySearchFieldsErrorAction,
  getUserBySearchFieldsLoadingAction,
  getUserBySearchFieldsSuccessAction,
  getUserIdentityErrorAction,
  getUserIdentityLoadingAction,
  GetUserIdentitySchema,
  getUserIdentitySuccessAction,
  GetUsersArgs,
  getUsersErrorAction,
  getUsersLoadingAction,
  getUsersSuccessAction,
  loadEnabledFieldSettingsErrorAction,
  loadEnabledFieldSettingsLoadingAction,
  loadEnabledFieldSettingsSuccessAction,
  postSessionsErrorAction,
  postSessionsLoadingAction,
  PostSessionsSchema,
  postSessionsSuccessAction,
  removeEmailAddressErrorAction,
  removeEmailAddressLoadingAction,
  RemoveEmailAddressSchema,
  removeEmailAddressSuccessAction,
  resendEmailErrorAction,
  resendEmailLoadingAction,
  ResendEmailSchema,
  resendEmailSuccessAction,
  resetPasswordErrorAction,
  resetPasswordLoadingAction,
  ResetPasswordSchema,
  resetPasswordSuccessAction,
  sendResetPasswordErrorAction,
  sendResetPasswordLoadingAction,
  SendResetPasswordSchema,
  sendResetPasswordSuccessAction,
  TotalUsersSchema,
  unassignRolesErrorAction,
  unassignRolesLoadingAction,
  UnassignRolesSchema,
  unassignRolesSuccessAction,
  unlockUserErrorAction,
  unlockUserLoadingAction,
  UnlockUserSchema,
  unlockUserSuccessAction,
  updateUserProfileErrorAction,
  updateUserProfileLoadingAction,
  UpdateUserProfileSchema,
  updateUserProfileSuccessAction,
  UserProfilePayloadSchema,
  VerifyArgs,
  verifyErrorAction,
  verifyLoadingAction,
  VerifySchema,
  verifySuccessAction
} from "../actions/customerManagementActions";
import { RootSchema } from "../reducers";

/**
 * Workers
 */
function* getUserBySearchFieldsHandler(args: GetUserBySearchFieldsArgs) {
  try {
    yield put(getUserBySearchFieldsLoadingAction());

    const { itemsPerPage, pagenumber, field, value, is_blocked, host } = args;
    let data: GetSearchUsersSchema;
    switch (field) {
      case "email":
        data = yield httpPost(
          "/customer-management/usersbyemail",
          { itemsPerPage, pagenumber, is_blocked, email_id: value },
          { host }
        );
        break;
      case "id":
        data = yield httpPost(
          "/customer-management/usersbyid",
          { itemsPerPage, pagenumber, is_blocked, id: value },
          { host }
        );
        break;
      case "uid":
        data = yield httpPost(
          "/customer-management/usersbyuid",
          { itemsPerPage, pagenumber, is_blocked, uid: value },
          { host }
        );
        break;
      case "name":
        data = yield httpPost(
          "/customer-management/usersbyname",
          { itemsPerPage, pagenumber, is_blocked, name: value },
          { host }
        );
        break;
      case "phoneid":
        data = yield httpPost(
          "/customer-management/usersbyphoneid",
          { itemsPerPage, pagenumber, is_blocked, phoneid: value },
          { host }
        );
        break;
      default:
        data = yield httpPost(
          "/customer-management/usersbycustomfields",
          { itemsPerPage, pagenumber, is_blocked, field, value },
          { host }
        );
    }

    if (data.ErrorCode) {
      throw new Error(data.Message);
    }

    yield put(getUserBySearchFieldsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getUserBySearchFieldsErrorAction(e.message));
  }
}

function* getUsersHandler(args: GetUsersArgs) {
  try {
    yield put(getUsersLoadingAction());

    const { itemsPerPage, pagenumber, is_blocked, host } = args;
    let data: GetSearchUsersSchema = yield httpPost(
      "/customer-management/users",
      { itemsPerPage, pagenumber, is_blocked },
      { host }
    );

    if (data.ErrorCode) {
      throw new Error(data.Message);
    }

    yield put(getUsersSuccessAction({ ...data }));
  } catch (e) {
    yield put(getUsersErrorAction(e.message));
  }
}

function* getConsumerAuditLogsHandler(args: GetConsumerAuditLogsArgs) {
  try {
    yield put(getConsumerAuditLogsLoadingAction());

    const { itemsPerPage, pagenumber } = args;
    let data: GetConsumerAuditLogsSchema = yield httpPost(
      "/customer-management/auditlogs",
      { itemsPerPage, pagenumber },
      { }
    );

    if (data.ErrorCode) {
      throw new Error(data.Message);
    }

    yield put(getConsumerAuditLogsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getConsumerAuditLogsErrorAction(e.message));
  }
}


function* getConsumerAuditLogsByUIDHandler(args: GetConsumerAuditLogsByUIDArgs) {
  try {
    yield put(getConsumerAuditLogsByUIDLoadingAction());

    const { itemsPerPage, pagenumber, uid } = args;
    let data: GetConsumerAuditLogsSchema = yield httpPost(
      "/customer-management/auditlogsbyuid",
      { itemsPerPage, pagenumber, uid },
      { }
    );

    if (data.ErrorCode) {
      throw new Error(data.Message);
    }

    yield put(getConsumerAuditLogsByUIDSuccessAction({ ...data }));
  } catch (e) {
    yield put(getConsumerAuditLogsByUIDErrorAction(e.message));
  }
}

function* loadEnabledFieldSettingsHandler() {
  try {
    yield put(loadEnabledFieldSettingsLoadingAction());

    const data: EnabledFieldSettingsSchema = yield httpGet(
      "/platform-configuration/registration-form-settings"
    );

    yield put(loadEnabledFieldSettingsSuccessAction({ ...data }));
  } catch (e) {
    yield put(loadEnabledFieldSettingsErrorAction(e.message));
  }
}

function* addUserHandler(payload: any) {
  try {
    yield put(addUserLoadingAction());

    const data: AddUserSchema = yield httpPost(
      "/customer-management/register",
      {},
      payload
    );

    yield put(addUserSuccessAction({ ...data }));
  } catch (e) {
    yield put(addUserErrorAction(e.message));
  }
}

function* getTotalUsersHandler() {
  try {
    yield put(getTotalUsersLoadingAction());

    const data: TotalUsersSchema = yield httpGet("/customer-management/counts");

    yield put(getTotalUsersSuccessAction({ ...data }));
  } catch (e) {
    yield put(getTotalUsersErrorAction(e.message));
  }
}

//! This handler should be part of deployment
function* getEmailTemplatesHandler() {
  try {
    yield put(getEmailTemplatesLoadingAction());

    const data: EmailTemplatesSchema = yield httpGet(
      "/deployment/email-workflow/email-settings/email-templates"
    );

    if (data.errorCode) {
      throw new Error(data.message || "");
    }

    yield put(getEmailTemplatesSuccessAction({ ...data }));
  } catch (e) {
    yield put(getEmailTemplatesErrorAction(e.message));
  }
}

function* getAllEmailTemplatesHandler() {
  try {
    yield put(getAllEmailTemplatesLoadingAction());

    const data: EmailTemplatesSchema = yield httpGet(
      "/deployment/email-workflow"
    );

    if (data.errorCode) {
      throw new Error(data.message || "");
    }

    yield put(
      getAllEmailTemplatesSuccessAction({
        ...data
      })
    );
  } catch (e) {
    yield put(getAllEmailTemplatesErrorAction(e.message));
  }
}

function* getUserIdentityHandler(uid: string) {
  try {
    yield put(getUserIdentityLoadingAction());

    const data: GetUserIdentitySchema = yield httpGet(
      "/customer-management/useridentities",
      { uid }
    );
    if (data.ErrorCode) {
      throw new Error(data.Description || "");
    }
    yield put(getUserIdentitySuccessAction({ ...data }));
  } catch (e) {
    yield put(getUserIdentityErrorAction(e.message));
  }
}

function* postSessionsHandler(uid: string) {
  try {
    yield put(postSessionsLoadingAction());

    const data: PostSessionsSchema = yield httpPost(
      "/customer-management/sessions",
      {},
      { uid }
    );
    if (data.errorCode) {
      throw new Error("The user is not active right now.");
    }
    yield put(postSessionsSuccessAction({ ...data }));
  } catch (e) {
    yield put(postSessionsErrorAction(e.message));
  }
}

function* addSecondaryEmailHandler(
  uid: string,
  secondaryEmail: string,
  secondaryEmailType: string
) {
  try {
    yield put(addSecondaryEmailLoadingAction());

    const data: AddSecondaryEmailSchema = yield httpPut(
      "/customer-management/addSecondaryEmail",
      {},
      { accountid: uid, secondaryEmail, secondaryEmailType }
    );

    if (data.ErrorCode) {
      throw new Error(
        parseErrorMessage(
          data,
          "Unable to add secondary email. Please try again."
        )
      );
    }

    yield put(
      getUserIdentitySuccessAction({ users: data, clientIP: data.clientIP })
    );
    yield put(addSecondaryEmailSuccessAction({ ...data }));
  } catch (e) {
    yield put(addSecondaryEmailErrorAction(e.message));
  }
}

function* blockOrUnblockUserHandler(uid: string, status: boolean) {
  try {
    yield put(blockOrUnblockUserLoadingAction());

    const data: BlockOrUnblockUserSchema = yield httpPost(
      "/customer-management/blockandunblockuser",
      {},
      { uid, status }
    );
    if (data.ErrorCode) {
      throw new Error(
        parseErrorMessage(
          data,
          "Unable to " +
            (status ? "block" : "unblock") +
            " the user. Please try again."
        )
      );
    }
    yield put(
      getUserIdentitySuccessAction({ users: data, clientIP: data.clientIP })
    );
    yield put(blockOrUnblockUserSuccessAction({ ...data }));
  } catch (e) {
    yield put(blockOrUnblockUserErrorAction(e.message));
  }
}

function* deleteUserHandler(uid: string) {
  try {
    yield put(deleteUserLoadingAction());

    const data: DeleteUserSchema = yield httpDelete(
      "/customer-management/user",
      {},
      { uid }
    );

    if (data.ErrorCode) {
      throw new Error(
        parseErrorMessage(
          data,
          "Unable to delete the customer. Please try again."
        )
      );
    }
    yield put(deleteUserSuccessAction({ ...data }));
  } catch (e) {
    yield put(deleteUserErrorAction(e.message));
  }
}

function* unlockUserHandler(uid: string) {
  try {
    yield put(unlockUserLoadingAction());

    const data: UnlockUserSchema = yield httpPut(
      "/customer-management/loginUnlocked",
      {},
      { uid, IsLoginLocked: false }
    );

    if (data.ErrorCode) {
      throw new Error(
        parseErrorMessage(
          data,
          "Unable to unlock the customer. Please try again."
        )
      );
    }
    yield put(
      getUserIdentitySuccessAction({ users: data, clientIP: data.clientIP })
    );
    yield put(unlockUserSuccessAction({ ...data }));
  } catch (e) {
    yield put(unlockUserErrorAction(e.message));
  }
}

function* verifyHandler({
  uid,
  EmailVerified,
  PhoneIdVerified,
  InvalidateEmail,
  InvalidatePhoneID
}: VerifyArgs) {
  try {
    yield put(verifyLoadingAction());

    const data: VerifySchema = yield httpPut(
      "/customer-management/verify",
      {},
      {
        uid,
        EmailVerified,
        PhoneIdVerified,
        InvalidateEmail,
        InvalidatePhoneID
      }
    );

    if (data.ErrorCode) {
      throw new Error(
        parseErrorMessage(
          data,
          "Unable to verify the customer. Please try again."
        )
      );
    }
    yield put(
      getUserIdentitySuccessAction({ users: data, clientIP: data.clientIP })
    );
    yield put(verifySuccessAction({ ...data }));
  } catch (e) {
    yield put(verifyErrorAction(e.message));
  }
}

function* resetPasswordHandler(accountid: string, password: string) {
  try {
    yield put(resetPasswordLoadingAction());

    const data: ResetPasswordSchema = yield httpPut(
      "/customer-management/resetpassword",
      {},
      { accountid, password }
    );

    if (data.ErrorCode) {
      throw new Error(
        parseErrorMessage(
          data,
          "Unable to reset password. Please try again.",
          "resetpassword"
        )
      );
    }
    let currentDateTime = moment()
      .utc()
      .format();

    const userIdentity = yield select(
      (state: RootSchema) => state.customerManagement.userIdentity.users
    );

    yield put(
      getUserIdentitySuccessAction({
        users: {
          ...userIdentity,
          LastPasswordChangeDate: currentDateTime,
          ModifiedDate: currentDateTime
        },
        clientIP: data.clientIP
      })
    );
    yield put(resetPasswordSuccessAction({ ...data }));
  } catch (e) {
    yield put(resetPasswordErrorAction(e.message));
  }
}

function* sendResetPasswordHandler(
  email: string,
  resetpasswordurl: string,
  template: string,
  username: string
) {
  try {
    yield put(sendResetPasswordLoadingAction());

    const data: SendResetPasswordSchema = yield httpPut(
      "/customer-management/forgotpassword",
      {},
      { email, resetpasswordurl, template, username }
    );

    if (data.ErrorCode) {
      throw new Error(
        parseErrorMessage(
          data,
          "Unable to send reset password link. Please try again."
        )
      );
    }
    yield put(sendResetPasswordSuccessAction({ ...data }));
  } catch (e) {
    yield put(sendResetPasswordErrorAction(e.message));
  }
}

function* resendEmailHandler(
  email: string,
  resendEmailUrl: string,
  template: string
) {
  try {
    yield put(resendEmailLoadingAction());

    const data: ResendEmailSchema = yield httpPut(
      "/customer-management/email/resend",
      {},
      { email, url: resendEmailUrl, template }
    );

    if (!data.IsPosted) {
      throw new Error(
        data.Message ||
          data.Description ||
          "Unable to send verification email. Please try again."
      );
    }
    yield put(resendEmailSuccessAction({ ...data }));
  } catch (e) {
    yield put(resendEmailErrorAction(e.message));
  }
}

function* removeEmailAddressHandler(payload: EmailAddressPayloadSchema) {
  try {
    yield put(removeEmailAddressLoadingAction());

    const data: RemoveEmailAddressSchema = yield httpDelete(
      "/customer-management/removeEmail",
      {},
      { ...payload }
    );

    if (data.ErrorCode) {
      throw new Error(
        parseErrorMessage(
          data,
          "Unable to remove email address. Please try again."
        )
      );
    }

    yield put(
      getUserIdentitySuccessAction({ users: data, clientIP: data.clientIP })
    );
    yield put(removeEmailAddressSuccessAction({ ...data }));
  } catch (e) {
    yield put(removeEmailAddressErrorAction(e.message));
  }
}

function* updateUserProfileHandler(payload: UserProfilePayloadSchema) {
  let action: UpdateUserProfileSchema["action"] = "";
  if (payload["ExternalIds"]) {
    action = "addexId";
    if (payload["ExternalIds"][0]["op"]) {
      action = "removeexId";
    }
  }
  try {
    yield put(updateUserProfileLoadingAction());

    const data: UpdateUserProfileSchema = yield httpPost(
      "/customer-management/updateprofile",
      {},
      { ...payload }
    );

    if (data.ErrorCode) {
      throw new Error(
        parseErrorMessage(
          data,
          "Unable to update user profile. Please try again."
        )
      );
    }

    yield put(
      getUserIdentitySuccessAction({ users: data, clientIP: data.clientIP })
    );

    yield put(updateUserProfileSuccessAction({ ...data, action }));
  } catch (e) {
    yield put(updateUserProfileErrorAction(e.message, { action }));
  }
}

function* getAssignedRoleHandler(uid: string) {
  try {
    yield put(getAssignedRoleLoadingAction());

    const data: GetAssignedRoleSchema = yield httpGet(
      `/customer-management/role/${uid}`
    );

    yield put(getAssignedRoleSuccessAction({ ...data }));
  } catch (e) {
    yield put(getAssignedRoleErrorAction(e.message));
  }
}

function* assignRolesHandler(payload: AssignRolesSchema) {
  try {
    yield put(assignRolesLoadingAction());

    const data: AssignRolesSchema = yield httpPut(
      "/customer-management/role",
      {},
      payload
    );

    if (data.ErrorCode) {
      throw new Error(data.Description || "Request couldn't be processed");
    }

    yield put(assignRolesSuccessAction({ data }));
  } catch (e) {
    yield put(assignRolesErrorAction(e.message));
  }
}

function* unassignRolesHandler(payload: UnassignRolesSchema) {
  try {
    yield put(unassignRolesLoadingAction());

    const data: UnassignRolesSchema = yield httpDelete(
      "/customer-management/role",
      {},
      payload
    );

    if (data.ErrorCode) {
      throw new Error(data.Description || "Request couldn't be processed");
    }

    yield put(unassignRolesSuccessAction({ ...data }));
  } catch (e) {
    yield put(unassignRolesErrorAction(e.message));
  }
}

function* getCustomObjectDataHandler() {
  try {
    yield put(getCustomObjectDataLoadingAction());

    const data: GetCustomObjectDataSchema = yield httpGet(
      "/data-configuration/data-storage/data-objects",
      {}
    );

    if (data.ErrorCode) {
      throw new Error(
        data.Description ||
          data.Message ||
          "Unable to retrieve custom object information. Please try again."
      );
    }

    yield put(getCustomObjectDataSuccessAction(data));
  } catch (e) {
    yield put(getCustomObjectDataErrorAction(e.message));
  }
}

function* getCustomObjectsHandler(payload: GetCustomObjectsPayloadSchema) {
  try {
    yield put(getCustomObjectsLoadingAction());

    const data: any = yield httpPost(
      "/customer-management/customobject",
      {},
      { ...payload }
    );

    let copy = cloneDeep(data);
    delete copy.clientIP;

    if (!Object.keys(copy).length) {
      throw new Error("The current user has no Custom Object data.");
    }

    if (data.ErrorCode) {
      throw new Error(
        data.Description ||
          data.Message ||
          "Unable to retrieve custom object information for the user. Please try again."
      );
    }

    yield put(getCustomObjectsSuccessAction({ response: data }));
  } catch (e) {
    yield put(getCustomObjectsErrorAction(e.message));
  }
}

function* getRoleContextHandler(uid: string) {
  try {
    yield put(getRoleContextLoadingAction());

    const data: GetRoleContextSchema = yield httpGet(
      `/customer-management/rolecontext/${uid}`
    );

    yield put(getRoleContextSuccessAction({ ...data }));
  } catch (e) {
    yield put(getRoleContextErrorAction(e.message));
  }
}

function* getConsentLogsHandler(uid: string) {
  try {
    yield put(getConsentLogsLoadingAction());

    const data = yield httpGet(`/customer-management/consentLogs/${uid}`);
    if (data.ErrorCode) {
      if (data.ErrorCode === 1228) {
        throw new Error("Consent info not available.");
      } else {
        throw new Error(data.Description || data.Message);
      }
    }
    yield put(getConsentLogsSuccessAction({ data }));
  } catch (e) {
    yield put(getConsentLogsErrorAction(e.message));
  }
}

function* addRoleContextHandler(payload: AddRoleContextSchema) {
  try {
    yield put(addRoleContextLoadingAction());

    const data: AddRoleContextSchema = yield httpPut(
      "/customer-management/rolecontext/",
      {},
      payload
    );

    yield put(addRoleContextSuccessAction({ ...data }));
  } catch (e) {
    yield put(addRoleContextErrorAction(e.message));
  }
}

function* deleteRoleContextHandler(payload: DeleteRoleContextSchema) {
  try {
    yield put(deleteRoleContextLoadingAction());

    const data: DeleteRoleContextSchema = yield httpDelete(
      "/customer-management/rolecontext/",
      {},
      payload
    );

    yield put(deleteRoleContextSuccessAction({ ...data }));
  } catch (e) {
    yield put(deleteRoleContextErrorAction(e.message));
  }
}

function* getCustomFieldsHandler() {
  try {
    yield put(getCustomFieldsLoadingAction());

    const data: GetCustomFieldsSchema = yield httpGet(
      "/customer-management/customfields",
      {}
    );

    if (data.ErrorCode) {
      throw new Error(data.Message || "No Custom Fields Found");
    }

    yield put(getCustomFieldsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getCustomFieldsErrorAction(e.message));
  }
}

function* getESFieldsHandler(uid: string) {
  try {
    yield put(getESFieldsLoadingAction());

    const data: GetESFieldsSchema = yield httpGet(
      "/customer-management/es-fields",
      { uid }
    );

    if (data.ErrorCode) {
      throw new Error(
        data.Message || "Unable to retrieve ES fields. Please try again."
      );
    }

    yield put(getESFieldsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getESFieldsErrorAction(e.message));
  }
}

function* getPrivacyPolicyHistoryHandler(uid: string) {
  try {
    yield put(getPrivacyPolicyHistoryLoadingAction());

    const data: GetPrivacyPolicyHistorySchema = yield httpGet(
      "/customer-management/privacypolicyhistory",
      { uid }
    );

    if (data.ErrorCode) {
      throw new Error(
        data.Message ||
          "Unable to retrieve privacy policy history. Please try again."
      );
    }

    yield put(getPrivacyPolicyHistorySuccessAction({ ...data }));
  } catch (e) {
    yield put(getPrivacyPolicyHistoryErrorAction(e.message));
  }
}

function* getTwoFASettingsHandler(uid: string) {
  try {
    yield put(getTwoFASettingsLoadingAction());

    const data: GetTwoFASettingsSchema = yield httpDelete(
      "/customer-management/2fa/settings",
      {},
      { uid }
    );

    if (data.ErrorCode) {
      throw new Error(
        data.Message || "Unable to retrieve 2fa settings. Please try again."
      );
    }

    yield put(getTwoFASettingsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getTwoFASettingsErrorAction(e.message));
  }
}

/**
 * Util function to parse and show validation error
 */
const parseErrorMessage = (
  data: any,
  customMessage: string,
  action?: string
) => {
  if (data.ErrorCode === 1134 && data.Errors) {
    const errorFields = data.Errors.map(err => {
      const msgArr = err.ErrorMessage.split(" ");
      const field: string[] = [];
      for (let i = 1; i < msgArr.length; i++) {
        let isEnd = false;
        if (msgArr[i] === "field") {
          isEnd = true;
        }
        if (isEnd) break;
        field.push(msgArr[i]);
      }
      return field.join(" ");
    });
    return `Enter the missing required fields to complete this action: ${errorFields.join(
      ", "
    )}`;
  } else if (data.ErrorCode === 908) {
    return data.Description || customMessage;
  } else {
    if (action === "resetpassword") {
      return data.Description || data.Message || customMessage;
    } else {
      return data.Message || data.Description || customMessage;
    }
  }
};

/**
 * Watchers
 */
export function* watchGetUserBySearchFields() {
  yield takeLatest(CustomerManagementConst.GET_USER_BY_SEARCHFIELDS, function*({
    payload
  }: ActionSchema<GetUserBySearchFieldsArgs>) {
    yield getUserBySearchFieldsHandler(payload);
  });
}

export function* watchGetUsers() {
  yield takeLatest(CustomerManagementConst.GET_USERS, function*({
    payload
  }: ActionSchema<GetUsersArgs>) {
    yield getUsersHandler(payload);
  });
}

export function* watchConsumerAuditLogs() {
  yield takeLatest(CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS, function*({
    payload
  }: ActionSchema<GetConsumerAuditLogsArgs>) {
    yield getConsumerAuditLogsHandler(payload);
  });
}
export function* watchConsumerAuditLogsByUID() {
  yield takeLatest(CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS_BY_UID, function*({
    payload
  }: ActionSchema<GetConsumerAuditLogsByUIDArgs>) {
    yield getConsumerAuditLogsByUIDHandler(payload);
  });
}
export function* watchLoadEnabledFieldSettings() {
  while (true) {
    yield take(CustomerManagementConst.LOAD_ENABLED_FIELDS_SETTINGS);
    yield call(loadEnabledFieldSettingsHandler);
  }
}

export function* watchAddUser() {
  while (true) {
    const { payload } = yield take(CustomerManagementConst.ADD_USER);
    yield call(addUserHandler, { ...payload });
  }
}

export function* watchGetTotalUsers() {
  while (true) {
    yield take(CustomerManagementConst.GET_TOTAL_USERS);
    yield call(getTotalUsersHandler);
  }
}

export function* watchGetEmailTemplates() {
  while (true) {
    yield take(CustomerManagementConst.GET_EMAIL_TEMPLATES);
    yield call(getEmailTemplatesHandler);
  }
}

export function* watchGetAllEmailTemplates() {
  while (true) {
    yield take(CustomerManagementConst.GET_ALL_EMAIL_TEMPLATES);
    yield call(getAllEmailTemplatesHandler);
  }
}

export function* watchGetUserIdentity() {
  while (true) {
    const {
      payload: { uid }
    } = yield take(CustomerManagementConst.GET_USER_IDENTITY);
    yield call(getUserIdentityHandler, uid);
  }
}

export function* watchPostSessions() {
  while (true) {
    const {
      payload: { uid }
    } = yield take(CustomerManagementConst.POST_SESSIONS);
    yield call(postSessionsHandler, uid);
  }
}

export function* watchAddSecondaryEmail() {
  while (true) {
    const {
      payload: { uid, secondaryEmail, secondaryEmailType }
    } = yield take(CustomerManagementConst.ADD_SECONDARY_EMAIL);
    yield call(
      addSecondaryEmailHandler,
      uid,
      secondaryEmail,
      secondaryEmailType
    );
  }
}

export function* watchBlockOrUnblockUser() {
  while (true) {
    const {
      payload: { uid, status }
    } = yield take(CustomerManagementConst.BLOCK_OR_UNBLOCK_USER);
    yield call(blockOrUnblockUserHandler, uid, status);
  }
}

export function* watchDeleteUser() {
  while (true) {
    const {
      payload: { uid }
    } = yield take(CustomerManagementConst.DELETE_USER);
    yield call(deleteUserHandler, uid);
  }
}

export function* watchUnlockUser() {
  while (true) {
    const {
      payload: { uid }
    } = yield take(CustomerManagementConst.UNLOCK_USER);
    yield call(unlockUserHandler, uid);
  }
}

export function* watchVerify() {
  while (true) {
    const { payload } = yield take(CustomerManagementConst.VERIFY);
    yield call(verifyHandler, payload);
  }
}

export function* watchResetPassword() {
  while (true) {
    const {
      payload: { accountid, password }
    } = yield take(CustomerManagementConst.RESET_PASSWORD);
    yield call(resetPasswordHandler, accountid, password);
  }
}

export function* watchSendResetPassword() {
  while (true) {
    const {
      payload: { email, resetpasswordurl, template, username }
    } = yield take(CustomerManagementConst.SEND_RESET_PASSWORD);
    yield call(
      sendResetPasswordHandler,
      email,
      resetpasswordurl,
      template,
      username
    );
  }
}

export function* watchResendEmail() {
  while (true) {
    const {
      payload: { email, resendEmailUrl, template }
    } = yield take(CustomerManagementConst.RESEND_EMAIL);
    yield call(resendEmailHandler, email, resendEmailUrl, template);
  }
}

export function* watchRemoveEmailAddressHandler() {
  while (true) {
    const { payload } = yield take(
      CustomerManagementConst.REMOVE_EMAIL_ADDRESS
    );
    yield call(removeEmailAddressHandler, payload);
  }
}

export function* watchUpdateUserProfile() {
  while (true) {
    const { payload } = yield take(CustomerManagementConst.UPDATE_USER_PROFILE);
    yield call(updateUserProfileHandler, payload);
  }
}

export function* watchGetAssignedRole() {
  while (true) {
    const { payload } = yield take(CustomerManagementConst.GET_ASSIGNED_ROLE);
    yield call(getAssignedRoleHandler, payload);
  }
}

export function* watchAssignRoles() {
  while (true) {
    const { payload } = yield take(CustomerManagementConst.ASSIGN_ROLES);
    yield call(assignRolesHandler, payload);
  }
}

export function* watchUnassignRoles() {
  while (true) {
    const { payload } = yield take(CustomerManagementConst.UNASSIGN_ROLES);
    yield call(unassignRolesHandler, payload);
  }
}

export function* watchGetCustomObjectData() {
  while (true) {
    yield take(CustomerManagementConst.GET_CUSTOM_OBJECT_DATA);
    yield call(getCustomObjectDataHandler);
  }
}

export function* watchGetCustomObjects() {
  while (true) {
    const { payload } = yield take(CustomerManagementConst.GET_CUSTOM_OBJECTS);
    yield call(getCustomObjectsHandler, payload);
  }
}

export function* watchGetRoleContext() {
  while (true) {
    const { payload } = yield take(CustomerManagementConst.GET_ROLE_CONTEXT);
    yield call(getRoleContextHandler, payload);
  }
}

export function* watchAddRoleContext() {
  while (true) {
    const { payload } = yield take(CustomerManagementConst.ADD_ROLE_CONTEXT);
    yield call(addRoleContextHandler, payload);
  }
}

export function* watchDeleteRoleContext() {
  while (true) {
    const { payload } = yield take(CustomerManagementConst.DELETE_ROLE_CONTEXT);
    yield call(deleteRoleContextHandler, payload);
  }
}

export function* watchGetConsentLogs() {
  while (true) {
    const { payload } = yield take(CustomerManagementConst.GET_CONSENT_LOGS);
    yield call(getConsentLogsHandler, payload.uid);
  }
}

export function* watchGetCustomFields() {
  while (true) {
    yield take(CustomerManagementConst.GET_CUSTOM_FIELDS);
    yield call(getCustomFieldsHandler);
  }
}

export function* watchGetESFields() {
  while (true) {
    const { payload } = yield take(CustomerManagementConst.GET_ES_FIELDS);
    yield call(getESFieldsHandler, payload.uid);
  }
}

export function* watchGetPrivacyPolicyHistory() {
  while (true) {
    const { payload } = yield take(
      CustomerManagementConst.GET_USER_PRIVACY_POLICY
    );
    yield call(getPrivacyPolicyHistoryHandler, payload.uid);
  }
}
export function* watchGetTwoFASettings() {
  while (true) {
    const { payload } = yield take(
      CustomerManagementConst.GET_USER_2FA_SETTING
    );
    yield call(getTwoFASettingsHandler, payload.uid);
  }
}

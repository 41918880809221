import { call, put, take } from "redux-saga/effects";
import { Toastify } from "../../components/Toaster";
import { httpDelete, httpGet, httpPost, httpPut } from "../../services";

import { setAppFeaturesAction } from "../actions/platformConfigurationActions";
import {
  DeleteSamlConfigSchema,
  ExistingUserSchema,
  GetAppUsersSchema,
  GetAuditLogsSchema,
  GetCheckedUserSchema,
  GetMapFieldsSchema,
  GetRoleListSchema,
  GetSamlConfigSchema,
  OwnerInfoDataSchema,
  PostSamlConfigSchema,
  PutSamlConfigSchema,
  ResetPasswordDataSchema,
  RoleSchema,
  TeamConst,
  TransferAccountSchema,
  UserRoleSchema,
  addExistingUserErrorAction,
  addExistingUserLoadingAction,
  addExistingUserSuccessAction,
  addUserRoleErrorAction,
  addUserRoleLoadingAction,
  addUserRoleSuccessAction,
  checkUserErrorAction,
  checkUserLoadingAction,
  checkUserSuccessAction,
  createUserAndTransferOwnershipErrorAction,
  createUserAndTransferOwnershipLoadingAction,
  createUserandTransferOwnershipSuccessAction,
  deleteSamlConfigErrorAction,
  deleteSamlConfigLoadingAction,
  deleteSamlConfigSuccessAction,
  deleteSelfErrorAction,
  deleteSelfLoadingAction,
  deleteSelfSuccessAction,
  deleteTeamUserErrorAction,
  deleteTeamUserLoadingAction,
  deleteTeamUserSuccessAction,
  getAppRolesErrorAction,
  getAppRolesLoadingAction,
  getAppRolesSuccessAction,
  getAppUsersErrorAction,
  getAppUsersLoadingAction,
  getAppUsersSuccessAction,
  getAuditLogsErrorAction,
  getAuditLogsLoadingAction,
  getAuditLogsSuccessAction,
  getCurrentUserErrorAction,
  getCurrentUserLoadingAction,
  getCurrentUserSuccessAction,
  getMapFieldsErrorAction,
  getMapFieldsLoadingAction,
  getMapFieldsSuccessAction,
  getOwnerInfoErrorAction,
  getOwnerInfoLoadingAction,
  getOwnerInfoSuccessAction,
  getRoleListErrorAction,
  getRoleListLoadingAction,
  getRoleListSuccessAction,
  getSamlConfigAction,
  getSamlConfigErrorAction,
  getSamlConfigLoadingAction,
  getSamlConfigSuccessAction,
  getTeamOwnerErrorAction,
  getTeamOwnerLoadingAction,
  getTeamOwnerSuccessAction,
  getTeamUserSessionAction,
  getTeamUserSessionErrorAction,
  getTeamUserSessionLoadingAction,
  getTeamUserSessionSuccessAction,
  getTeamUsersErrorAction,
  getTeamUsersLoadingAction,
  getTeamUsersSuccessAction,
  logOutTeamUserErrorAction,
  logOutTeamUserLoadingAction,
  logOutTeamUserSuccessAction,
  postSamlConfigErrorAction,
  postSamlConfigLoadingAction,
  postSamlConfigSuccessAction,
  putSamlConfigErrorAction,
  putSamlConfigLoadingAction,
  putSamlConfigSuccessAction,
  resendTeamUserPasswordErrorAction,
  resendTeamUserPasswordLoadingAction,
  resendTeamUserPasswordSuccessAction,
  sendResetPasswordAction,
  sendResetPasswordErrorAction,
  sendResetPasswordLoadingAction,
  sendResetPasswordSuccessAction,
  transferAccountAction,
  transferAccountErrorAction,
  transferAccountLoadingAction,
  transferAccountSuccessAction,
  updateCurrentUserErrorAction,
  updateCurrentUserLoadingAction,
  updateCurrentUserSuccessAction,
  updateTeamUserRoleErrorAction,
  updateTeamUserRoleLoadingAction,
  updateTeamUserRoleSuccessAction,
  wasUserAddedInActiveSessionErrorAction,
  wasUserAddedInActiveSessionLoadingAction,
  wasUserAddedInActiveSessionSuccessAction
} from "../actions/teamActions";

/**
 * Workers
 */

function* getTeamUsersHandler() {
  try {
    yield put(getTeamUsersLoadingAction());
    const response = yield httpGet("/team/users");
    verifySuccessOfResponse("Get Team User", response);
    for (let i = 0; i < response.length; i++) {
      response[i].Email =
        typeof response[i].Email === "string"
          ? response[i].Email
          : response[i].Email[0].Value;
    }
    yield put(getTeamUsersSuccessAction({ response }));
  } catch (e) {
    yield put(getTeamUsersErrorAction(e.message));
  }
}

function verifySuccessOfResponse(request: string, requestResponse: any) {
  if (requestResponse.lrmessage !== undefined) {
    throw Error(`${request}: ${requestResponse.lrmessage}`);
  }
}

function* getTeamOwnerHandler() {
  try {
    yield put(getTeamOwnerLoadingAction());
    const data = yield httpGet("/team/owner");
    verifySuccessOfResponse("Get Team Owner", data);
    if (data.ErrorCode && data.ErrorCode === 403) {
      // Handling error in component to make logic in sync with exsiting code
      yield put(getTeamOwnerSuccessAction({ ...data, response: {} }));
    } else {
      yield put(getTeamOwnerSuccessAction({ response: data }));
    }
  } catch (e) {
    yield put(getTeamOwnerErrorAction(e.message));
  }
}

function* deleteTeamUserHandler(
  uid: string,
  email: string,
  appID: number,
  appName: string
) {
  try {
    yield put(deleteTeamUserLoadingAction());
    const data = yield httpDelete(
      "/team/user",
      {},
      { uid, email, appList: [{ appID, AppName: appName }] }
    );
    data.userDeleted = uid;
    yield put(deleteTeamUserSuccessAction({ response: data }));
  } catch (e) {
    yield put(deleteTeamUserErrorAction(e.message));
  }
}

function* deleteSelfHandler(appID: number, appName: string) {
  try {
    yield put(deleteSelfLoadingAction());
    const data = yield httpDelete(
      "/team/self",
      {},
      { appList: [{ appID, AppName: appName }] }
    );
    yield put(deleteSelfSuccessAction({ response: data }));
  } catch (e) {
    yield put(deleteSelfErrorAction(e.message));
  }
}

function* resendTeamUserPasswordHandler(
  email: string,
  template: string,
  url: string
) {
  try {
    yield put(resendTeamUserPasswordLoadingAction());
    const response = yield httpPost(
      "/team/verifyemail",
      {},
      { Email: email, template, url }
    );
    response.email = email;
    yield put(resendTeamUserPasswordSuccessAction({ response }));
  } catch (e) {
    yield put(resendTeamUserPasswordErrorAction(e.message));
  }
}

function* getTeamUserSessionHandler(uid: string) {
  try {
    yield put(getTeamUserSessionLoadingAction());
    const response = yield httpPost("/team/sessions", {}, { uid });
    yield put(getTeamUserSessionSuccessAction({ response }));
  } catch (e) {
    yield put(getTeamUserSessionErrorAction(e.message));
  }
}

function* logOutTeamUserHandler(uid: string) {
  try {
    yield put(logOutTeamUserLoadingAction());
    yield put(getTeamUserSessionAction(uid));
    let userSessionResponse = yield take(
      TeamConst.GET_TEAM_USER_SESSION_SUCCESS
    );
    if (userSessionResponse.payload.response) {
      let errorCode = userSessionResponse.payload.response.errorCode;
      if (errorCode === undefined) {
        let sessions = userSessionResponse.payload.response.data,
          loggedOutSessions = {};
        for (let session of sessions) {
          const { AccessToken } = session;
          const response = yield httpPost(
            "/team/invalidate",
            {},
            { accessToken: AccessToken }
          );
          if (response.isPosted) {
            loggedOutSessions[AccessToken] = response.isPosted;
          }
          if (Object.keys(loggedOutSessions).length === sessions.length) {
            yield put(logOutTeamUserSuccessAction({ response }));
          }
        }
      } else {
        yield put(
          logOutTeamUserErrorAction("This user is not active right now.")
        );
      }
    }
  } catch (e) {
    yield put(logOutTeamUserErrorAction(e.message));
  }
}

function* getCurrentUserHandler() {
  try {
    yield put(getCurrentUserLoadingAction());
    const data = yield httpGet("/team/currentuser");
    verifySuccessOfResponse("Get Current User", data);
    yield put(getCurrentUserSuccessAction({ response: data }));
  } catch (e) {
    yield put(getCurrentUserErrorAction(e.message));
  }
}

function* getAppRolesHandler() {
  try {
    yield put(getAppRolesLoadingAction());
    const data = yield httpGet("/team/roles");
    verifySuccessOfResponse("Get App Roles", data);
    yield put(getAppRolesSuccessAction({ response: data }));
  } catch (e) {
    yield put(getAppRolesErrorAction(e.message));
  }
}

function* updateTeamUserRoleHandler(
  uid: string,
  roles: RoleSchema[],
  additionalPermissions: string[],
  site: string,
  appId: number
) {
  try {
    yield put(updateTeamUserRoleLoadingAction());
    const data = yield httpPut(
      "/team/role",
      {},
      {
        uid: uid,
        siteList: {
          site: site,
          appid: String(appId),
          role: roles,
          additionalpermission: additionalPermissions
        }
      }
    );
    data.uid = uid;
    yield put(updateTeamUserRoleSuccessAction({ response: data }));
  } catch (e) {
    let message = e.message;
    if (e.status === 401) message = e.message;
    yield put(updateTeamUserRoleErrorAction(message));
  }
}

function* getAuditLogsHandler(
  appId: number,
  cursor: number,
  customerId: string
) {
  try {
    yield put(getAuditLogsLoadingAction());

    const data: GetAuditLogsSchema = yield httpGet("/team/auditlogbyappid", {
      // eslint-disable-next-line
      app_id: appId,
      cursor,
      // eslint-disable-next-line
      customer_id: customerId,
    });
    if (data["description"]) {
      throw new Error(data["description"]);
    }
    yield put(getAuditLogsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getAuditLogsErrorAction(e.message));
  }
}

function* getRoleListHandler() {
  try {
    yield put(getRoleListLoadingAction());

    const roles: GetRoleListSchema = yield httpGet("/team/roles");
    let allRoles: GetRoleListSchema[] = [];
    if (roles && roles.data) {
      roles.data.forEach(function(role) {
        if (
          role.Name !== "SuperUser_ReadOnly" &&
          role.Name !== "SuperUser_Write" &&
          role.Name !== "RootAdmin_Write" &&
          role.Name !== "Owner"
        ) {
          allRoles.push({
            id: role.Name,
            permission: role.Permissions
          });
        }
      });
    }

    yield put(getRoleListSuccessAction(allRoles));
    yield put(getAppRolesSuccessAction({ response: roles as any }));
  } catch (e) {
    yield put(getRoleListErrorAction(e.message));
  }
}

function* getOwnerInfoHandler() {
  try {
    yield put(getOwnerInfoLoadingAction());

    const data: OwnerInfoDataSchema = yield httpGet("/team/owner");

    yield put(getOwnerInfoSuccessAction({ ...data }));
  } catch (e) {
    yield put(getOwnerInfoErrorAction(e.message));
  }
}

function* checkUserHandler(email: string) {
  try {
    yield put(checkUserLoadingAction());

    const data: GetCheckedUserSchema = yield httpPost("/team/email", {}, email);

    yield put(checkUserSuccessAction({ ...data }));
  } catch (e) {
    yield put(checkUserErrorAction(e.message));
  }
}

function* sendResetPasswordHandler(emailAndFlag: ResetPasswordDataSchema) {
  try {
    yield put(sendResetPasswordLoadingAction());

    const resetPasswordData = {
      Email: emailAndFlag.email,
      template: emailAndFlag.flag ? "" : "reset-team",
      url: window.location.hostname
    };
    const data = yield httpPost("/team/verifyemail", {}, resetPasswordData);

    yield put(sendResetPasswordSuccessAction({ ...data }));
  } catch (e) {
    yield put(sendResetPasswordErrorAction(e.message));
  }
}

function* addExistingUserHandler(existingUser: ExistingUserSchema) {
  try {
    yield put(addExistingUserLoadingAction());

    const data = yield httpPost("/team/v2/user", {}, existingUser);
    if (data.ErrorCode || data.errorCode) {
      throw new Error(data.Description || data.description);
    }
    yield put(addExistingUserSuccessAction({ ...data }));
  } catch (e) {
    yield put(addExistingUserErrorAction(e.message));
  }
}

function* addUserRoleHandler(userRole: UserRoleSchema) {
  try {
    yield put(addUserRoleLoadingAction());

    const data = yield httpPost("/team/role", {}, userRole);

    yield put(addUserRoleSuccessAction({ ...data }));
  } catch (e) {
    yield put(addUserRoleErrorAction(e.message));
  }
}

function* wasUserAddedInActiveSessionHandler() {
  try {
    yield put(wasUserAddedInActiveSessionLoadingAction());
    yield put(wasUserAddedInActiveSessionSuccessAction());
  } catch (e) {
    yield put(wasUserAddedInActiveSessionErrorAction(e.message));
  }
}

function* getMapFieldsHandler() {
  try {
    yield put(getMapFieldsLoadingAction());

    const data: GetMapFieldsSchema = yield httpGet("/team/saml/mapfields");

    yield put(getMapFieldsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getMapFieldsErrorAction(e.message));
  }
}

function* getSamlConfigHandler(appName: string) {
  try {
    yield put(getSamlConfigLoadingAction());

    const data: GetSamlConfigSchema = yield httpGet("/team/saml", {
      params: appName
    });

    yield put(getSamlConfigSuccessAction({ ...data, queriedAppName: appName }));
  } catch (e) {
    yield put(getSamlConfigErrorAction(e.message));
  }
}

function* postSamlConfigHandler(payload: { body: any; appName: string }) {
  try {
    yield put(postSamlConfigLoadingAction());

    const data: PostSamlConfigSchema = yield httpPost(
      "/team/saml",
      {},
      payload.body
    );
    yield put(getSamlConfigAction(payload.appName));
    if (data["errorCode"] === 1189) {
      throw new Error(data["description"]);
    }

    yield put(postSamlConfigSuccessAction({ ...data }));
    Toastify("success", "Single Sign-on configuration has been added.");
  } catch (e) {
    yield put(postSamlConfigErrorAction(e.message));
    Toastify("error", e.message || "Something went wrong. Please try again.");
  }
}

function* putSamlConfigHandler(payload: { body: any; appName: string }) {
  try {
    yield put(putSamlConfigLoadingAction());

    const data: PutSamlConfigSchema = yield httpPut(
      "/team/saml",
      {},
      payload.body
    );
    yield put(getSamlConfigAction(payload.appName));
    if (data["errorCode"] === 1189) {
      throw new Error(data["description"]);
    }
    yield put(putSamlConfigSuccessAction({ ...data }));
    Toastify("success", "Single Sign-on configuration has been updated.");
    yield put(getSamlConfigAction(payload.appName));
  } catch (e) {
    yield put(putSamlConfigErrorAction(e.message));
    Toastify("error", e.message || "Something went wrong. Please try again.");
  }
}

function* deleteSamlConfigHandler(payload: {
  provider: string;
  appName: string;
}) {
  let samlPayload = {
    data: [
      {
        status: false,
        feature: "only_sso_login_enabled"
      }
    ]
  };
  try {
    yield put(deleteSamlConfigLoadingAction());

    const data: DeleteSamlConfigSchema = yield httpDelete(
      "/team/saml",
      {},
      { Provider: payload.provider }
    );
    yield put(deleteSamlConfigSuccessAction({ ...data }));
    yield put(setAppFeaturesAction(samlPayload));
    Toastify("success", "Single Sign-on configuration has been deleted.");
    yield put(getSamlConfigAction(payload.appName));
  } catch (e) {
    yield put(deleteSamlConfigErrorAction(e.message));
    Toastify("error", "Something went wrong. Please try again.");
  }
}

function* getAppUsersHandler() {
  try {
    yield put(getAppUsersLoadingAction());

    const data: GetAppUsersSchema = yield httpGet("/team/users");

    yield put(getAppUsersSuccessAction({ ...data }));
  } catch (e) {
    yield put(getAppUsersErrorAction(e.message));
  }
}

function* transferAccountHandler(transferData) {
  try {
    yield put(transferAccountLoadingAction());

    const data: TransferAccountSchema = yield httpPost(
      "/team/transfer",
      {},
      transferData
    );

    yield put(transferAccountSuccessAction({ ...data }));
  } catch (e) {
    yield put(transferAccountErrorAction(e.message));
  }
}

function* updateCurrentUserHandler() {
  try {
    yield put(updateCurrentUserLoadingAction());
    const data: any = yield httpPut(
      "/team/currentuser",
      {},
      { role: ["Admin"] }
    );
    yield put(updateCurrentUserSuccessAction(data));
  } catch (e) {
    yield put(updateCurrentUserErrorAction(e.message));
  }
}

function* createUserAndTransferOwnershipHandler(payload: {
  email: string;
  userData: any;
  appId: number;
}) {
  try {
    yield put(createUserAndTransferOwnershipLoadingAction());
    const emailResponse = yield httpPost(
      "/team/email",
      {},
      { email: payload.email }
    );
    if (emailResponse.errorCode) {
      throw new Error(emailResponse.message);
    } else if (emailResponse.emailDomainValid === false) {
      throw new Error(
        "Email with restricted domains are not allowed. try with proper business email address."
      );
    }
    const addCustomerResponse = yield httpPost(
      "/team/v2/user",
      {},
      { ...payload.userData, email: payload.email }
    );
    if (addCustomerResponse.errorCode || addCustomerResponse.ErrorCode) {
      throw new Error(
        addCustomerResponse.message || addCustomerResponse.Message
      );
    }
    if (emailResponse.IsExist === false) {
      yield put(
        sendResetPasswordAction({ email: addCustomerResponse.EmailID })
      );
    }
    const transferData = {
      NewOwnerEmail: addCustomerResponse.EmailID,
      uid: addCustomerResponse.UId,
      transferSite: payload.appId
    };
    yield put(transferAccountAction(transferData));
    yield put(createUserandTransferOwnershipSuccessAction());
  } catch (e) {
    yield put(createUserAndTransferOwnershipErrorAction(e.message));
  }
}

/**
 * Watchers
 */

export function* watchGetTeamUsers() {
  while (true) {
    yield take(TeamConst.GET_TEAM_USERS);
    yield call(getTeamUsersHandler);
  }
}

export function* watchGetTeamOwner() {
  while (true) {
    yield take(TeamConst.GET_TEAM_OWNER);
    yield call(getTeamOwnerHandler);
  }
}

export function* watchDeleteTeamUser() {
  while (true) {
    const {
      payload: { uid, email, appID, appName }
    } = yield take(TeamConst.DELETE_TEAM_USER);
    yield call(deleteTeamUserHandler, uid, email, appID, appName);
  }
}

export function* watchDeleteSelf() {
  while (true) {
    const {
      payload: { appID, appName }
    } = yield take(TeamConst.DELETE_SELF);
    yield call(deleteSelfHandler, appID, appName);
  }
}

export function* watchResendTeamUserPassword() {
  while (true) {
    const {
      payload: { email, template, url }
    } = yield take(TeamConst.RESEND_TEAM_USER_PASSWORD);
    yield call(resendTeamUserPasswordHandler, email, template, url);
  }
}

export function* watchGetTeamUserSession() {
  while (true) {
    const {
      payload: { uid }
    } = yield take(TeamConst.GET_TEAM_USER_SESSION);
    yield call(getTeamUserSessionHandler, uid);
  }
}

export function* watchLogOutTeamUser() {
  while (true) {
    const {
      payload: { uid }
    } = yield take(TeamConst.LOG_OUT_TEAM_USER);
    yield call(logOutTeamUserHandler, uid);
  }
}

export function* watchGetCurrentUser() {
  while (true) {
    yield take(TeamConst.GET_CURRENT_USER);
    yield call(getCurrentUserHandler);
  }
}

export function* watchGetAppRoles() {
  while (true) {
    yield take(TeamConst.GET_APP_ROLES);
    yield call(getAppRolesHandler);
  }
}

export function* watchUpdateTeamUserRole() {
  while (true) {
    const {
      payload: { uid, roles, additionalPermissions, site, appId }
    } = yield take(TeamConst.UPDATE_TEAM_USER_ROLE);
    yield call(
      updateTeamUserRoleHandler,
      uid,
      roles,
      additionalPermissions,
      site,
      appId
    );
  }
}

export function* watchMapFields() {
  while (true) {
    yield take(TeamConst.GET_MAP_FIELDS);
    yield call(getMapFieldsHandler);
  }
}

export function* watchSamlConfig() {
  while (true) {
    const data = yield take(TeamConst.GET_SAML_CONFIG);
    yield call(getSamlConfigHandler, data.payload);
  }
}

export function* watchPostSamlConfig() {
  while (true) {
    const data = yield take(TeamConst.POST_SAML_CONFIG);
    yield call(postSamlConfigHandler, data.payload);
  }
}

export function* watchPutSamlConfig() {
  while (true) {
    const data = yield take(TeamConst.PUT_SAML_CONFIG);
    yield call(putSamlConfigHandler, data.payload);
  }
}

export function* watchDeleteSamlConfig() {
  while (true) {
    const data = yield take(TeamConst.DELETE_SAML_CONFIG);
    yield call(deleteSamlConfigHandler, data.payload);
  }
}

export function* watchAuditLogs() {
  while (true) {
    const data = yield take(TeamConst.GET_AUDIT_LOGS);
    yield call(
      getAuditLogsHandler,
      data.payload.appId,
      data.payload.cursor,
      "UID"
    );
  }
}

export function* watchRoleList() {
  while (true) {
    yield take(TeamConst.GET_ROLE_LIST);
    yield call(getRoleListHandler);
  }
}

export function* watchOwnerInfo() {
  while (true) {
    yield take(TeamConst.GET_OWNER_INFO);
    yield call(getOwnerInfoHandler);
  }
}

export function* watchCheckUser() {
  while (true) {
    const data = yield take(TeamConst.CHECK_USER);
    yield call(checkUserHandler, data.payload);
  }
}

export function* watchSendResetPassword() {
  while (true) {
    const data = yield take(TeamConst.SEND_RESET_PASSWORD);
    yield call(sendResetPasswordHandler, data.payload);
  }
}

export function* watchAddExistingUser() {
  while (true) {
    const data = yield take(TeamConst.ADD_EXISTING_USER);
    yield call(addExistingUserHandler, data.payload);
  }
}

export function* watchAddUserRole() {
  while (true) {
    const data = yield take(TeamConst.ADD_USER_ROLE);
    yield call(addUserRoleHandler, data.payload);
  }
}

export function* watchGetAppUsers() {
  while (true) {
    yield take(TeamConst.GET_APP_USERS);
    yield call(getAppUsersHandler);
  }
}

export function* watchTransferAccount() {
  while (true) {
    const data = yield take(TeamConst.TRANSFER_ACCOUNT);
    yield call(transferAccountHandler, data.payload);
  }
}

export function* watchUpdateCurrentUser() {
  while (true) {
    yield take(TeamConst.UPDATE_CURRENT_USER);
    yield call(updateCurrentUserHandler);
  }
}

export function* watchCreateUserAndTransferOwnership() {
  while (true) {
    const data = yield take(TeamConst.CREATE_USER_AND_TRANSFER_OWNERSHIP);
    yield call(createUserAndTransferOwnershipHandler, data.payload);
  }
}

export function* watchWasUserAddedInActiveSession() {
  while (true) {
    yield take(TeamConst.WAS_USER_ADDED_IN_ACTIVE_SESSION);
    yield call(wasUserAddedInActiveSessionHandler);
  }
}

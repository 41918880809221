import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { RootSchema } from "../../redux/reducers";
import { AppUISchema } from "../../redux/actions/appStateActions";
import {
  NavSectionSchema,
  NavSubSectionSchema
} from "../../containers/Navigation";
import { removeSymbols } from "../../utils";

import "./Navbar.scss";

export interface NavMultiDropdownProps {
  name: string;
  classes: string;
  toggleId: string;
  sections: NavSectionSchema[];
  subsections: NavSubSectionSchema[][];
  parentLink: string;
  parentIndex: number;
  tabIndex?: number;
  navigationHandler: (
    parentIndex: number,
    sectionIndex: number,
    subSectionIndex: number
  ) => void;
  appUI: AppUISchema;
  isShowing: boolean;
  handleLinkClick: (name: string) => void;
  currentActive: string;
  handleLinkFocus: (name: string) => void;
  currentFocused: string;
}

export interface NavMultiDropdownState {
  isShowing: boolean;
  isFocused: boolean;
}

class NavMultiDropdown extends React.Component<
  NavMultiDropdownProps,
  NavMultiDropdownState
> {
  COUNT = 0;
  constructor(props: NavMultiDropdownProps) {
    super(props);
    this.state = {
      isShowing: props.isShowing,
      isFocused: props.isShowing
    };
  }

  focusFirstElement = () => {
    const { subsections } = this.props;
    if (Array.isArray(subsections) && Array.isArray(subsections[0])) {
      const { displayName } = subsections[0][0];
      const firstDropdownItem = document.getElementById(
        `lnk_${removeSymbols(displayName)}`
      );
      if (firstDropdownItem instanceof HTMLAnchorElement) {
        firstDropdownItem.focus();
      }
    }
  };

  handleBlur = (event: React.FocusEvent) => {
    this.props.handleLinkFocus(removeSymbols(this.props.name));
    this.setState({ isFocused: event.target === document.activeElement });
  };

  handleKeyUp = (event: React.KeyboardEvent) => {
    this.props.handleLinkFocus(removeSymbols(this.props.name));
    let key = event.key;
    this.setState(
      { isFocused: event.target === document.activeElement },
      () => {
        if (key === "Enter") {
          this.setState(
            prevState => ({ isShowing: !prevState.isShowing }),
            () => {
              this.COUNT += 1;
              this.props.handleLinkClick(removeSymbols(this.props.name));
              // Using a 0ms setTimeout so it gets added to the event queue, because otherwise, the DOM element won't be focusable yet
              setTimeout(this.focusFirstElement, 0);
            }
          );
        } else if (key === "Escape") {
          this.setState({ isShowing: false });
        }
      }
    );
  };

  handleListItemClick = (event: React.MouseEvent) => {
    this.props.handleLinkClick(removeSymbols(this.props.name));
    this.props.handleLinkFocus(removeSymbols(this.props.name));

    this.setState(prevState => ({
      //isFocused: !prevState.isFocused,
      isShowing: false
    }));
  };

  generateNavSections = () => {
    const { sections } = this.props;

    return sections.map((section, index) => (
      <div key={index} className="custom-dropdown-menu-inner">
        <a className="custom-dropdown-section-header">{section.name}</a>
        <div className="navbar-sub-menu global-sub-menu">
          {this.generateSubsections(section, index)}
        </div>
      </div>
    ));
  };

  generateSubsections = (section, sectionIndex) => {
    const {
      sections,
      subsections,
      parentLink,
      parentIndex,
      navigationHandler
    } = this.props;

    return subsections[sectionIndex].map((subSection, subSectionIndex) => {
      return (
        subSection.link && (
          <Link
            id={"lnk_" + removeSymbols(subSection.displayName)}
            key={subSectionIndex}
            className={`dropdown-item ${subSection.isActive ? "active" : ""}`}
            to={parentLink + section.link + (subSection.link || "")}
            onClick={() => {
              this.setState({ isShowing: false });
              navigationHandler(parentIndex, sectionIndex, subSectionIndex);
            }}
            onKeyDown={(event: React.KeyboardEvent) => {
              const isFirstItem = sectionIndex === 0 && subSectionIndex === 0;
              const isLastItemInDropdown =
                sectionIndex === sections.length - 1 &&
                subSectionIndex === subsections[sectionIndex].length - 1;

              if (
                (isFirstItem && event.shiftKey && event.key === "Tab") ||
                (isLastItemInDropdown && event.key === "Tab")
              ) {
                this.setState({ isShowing: false });
              }
            }}
            onKeyUp={(event: React.KeyboardEvent) => {
              event.stopPropagation();

              if (event.key === "Enter" || event.key === "Escape") {
                this.setState({ isShowing: false });
              }
            }}
          >
            {subSection.displayName}
          </Link>
        )
      );
    });
  };

  render() {
    const { name, classes, toggleId, tabIndex } = this.props;

    return (
      <li
        key={"M" + this.COUNT + name}
        className={`nav-item ${
          this.state.isShowing &&
          this.props.currentActive === removeSymbols(name)
            ? "show"
            : ""
        }`}
        tabIndex={-1}
        onClick={this.handleListItemClick}
      >
        <a
          className={classes}
          id={toggleId}
          aria-haspopup="true"
          aria-expanded={
            (this.state.isShowing &&
              this.props.currentActive === removeSymbols(name)) ||
            (this.state.isFocused &&
              this.props.currentFocused === removeSymbols(name))
          }
          data-toggle="dropdown"
          role="button"
          tabIndex={tabIndex}
          onKeyUp={this.handleKeyUp}
          onBlur={this.handleBlur}
          onClick={this.handleListItemClick}
        >
          {name}
        </a>
        <div
          className={`dropdown-menu dropdown-secondary custom-multi-dropdown ${
            this.state.isShowing &&
            this.props.currentActive === removeSymbols(name)
              ? "show"
              : ""
          }`}
          aria-labelledby={toggleId}
        >
          {this.generateNavSections()}
        </div>
      </li>
    );
  }
}
const mapStateToProps = (state: RootSchema) => {
  return {
    appUI: state.appState.appUI
  };
};
export default connect(mapStateToProps)(NavMultiDropdown);

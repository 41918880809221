import loadLanguage, { LanguageSchema } from "../../i18n/index";
// Constants
export const AppStateConst = {
  UPDATE_BREADCRUMB: "UPDATE_BREADCRUMB",
  SHOW_LOADING: "SHOW_LOADING",
  INIT_APP_SUCCESS: "INIT_APP_SUCCESS",
  SET_I18N_LOCALE: "SET_I18N_LOCALE",
  NETWORK_UPDATE_SUCCESS: "NETWORK_UPDATE_SUCCESS",
  NAVIGATION_MENU_CHANGE: "NAVIGATION_MENU_CHANGE",
  INIT_LOGIN_EVENT_SUCCESS: "INIT_LOGIN_EVENT_SUCCESS"
};

export interface AppUISchema {
  appInitialized: boolean;
  showLoading: boolean;
  loadingMessage: string;
  networkConnected: boolean;
  menuChangeTrack: string;
  isUserLoggedIn: boolean;
}

export const navigationMenuChange = (value: string) => {
  const payload = { menuChangeTrack: value };
  return {
    type: AppStateConst.NAVIGATION_MENU_CHANGE,
    payload
  };
};
export const showLoadingAction = (
  showLoading = true,
  loadingMessage = "Loading..."
) => {
  const payload = { showLoading, loadingMessage };
  return {
    type: AppStateConst.SHOW_LOADING,
    payload
  };
};

export const initAppSuccessAction = () => {
  const payload = { appInitialized: true, networkConnected: true };
  return {
    type: AppStateConst.INIT_APP_SUCCESS,
    payload
  };
};
export const changeNetworkAction = (status: boolean) => {
  const payload = { networkConnected: status };
  return {
    type: AppStateConst.NETWORK_UPDATE_SUCCESS,
    payload
  };
};
export interface SetI18NSchema {
  locale: string;
  languages: LanguageSchema;
}

export const setI18NLocaleAction = (locale = "en") => {
  const payload: SetI18NSchema = {
    locale,
    languages: loadLanguage(locale)
  };
  return {
    type: AppStateConst.SET_I18N_LOCALE,
    payload
  };
};

export const initLoginEventSuccessAction = value => {
  const payload = { isUserLoggedIn: value };
  return {
    type: AppStateConst.INIT_LOGIN_EVENT_SUCCESS,
    payload
  };
};

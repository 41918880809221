/**
 * This file contains commonly used constants, schemas and action creators
 */

// Schemas
export interface SideEffectSchema {
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  error?: string;
}

export interface ActionSchema<T> {
  type: string;
  payload: T;
}

/**
 * Properties sometimes included in a response from the API when there was an error.
 */
export interface APIErrorResponseSchema {
  errorCode?: number;
  message?: string;
  description?: string;
}

export interface V2ErrorResponseSchema {
  Description?: string;
  ErrorCode?: number;
  IsProviderError?: false;
  Message?: string;
  ProviderErrorResponse?: null | string;
}

export interface ObjectSchema<T> {
  [key: string]: T;
}

//Declare global
declare global {
  interface Window {
    LoginRadiusV2?: any;
    CalHeatMap?: any;
    sh_zendesk: any;
    sh_init: any;
    sh_quickMenuList: any;
    amplitude: any;
    ChurnZero: any;
    getSearchJson: () => any;
  }
}

// Constants
export const successSideEffectState: SideEffectSchema = {
  isLoading: false,
  isSuccess: true,
  isError: false,
  error: ""
};

// Action Creators
export const loadingAction = (type: string, data = {}) => {
  return {
    type,
    payload: {
      isLoading: true,
      isSuccess: false,
      isError: false,
      error: "",
      ...data
    }
  };
};

export const errorAction = (type: string, error: string, data = {}) => {
  return {
    type,
    payload: {
      isLoading: false,
      isSuccess: false,
      isError: true,
      error,
      ...data
    }
  };
};

/**
 * This function returns a object with the const name prefixed and added side effects.
 *
 * E.g. generateConst("INSIGHTS", ["GET_USERS"]) =>
 *
 * {
 *
 *  GET_USERS: "INSIGHTS_GET_USERS",
 *
 *  GET_USERS_LOADING: "INSIGHTS_GET_USERS_LOADING",
 *
 *  GET_USERS_SUCCESS: "INSIGHTS_GET_USERS_SUCCESS",
 *
 *  GET_USERS_ERROR: "INSIGHTS_GET_USERS_ERROR",
 *
 * }
 * @param constName Name of the constant that gets appended to the keys
 * @param consts Array of constant names
 */
export const generateConst = (constName: string, consts: string[]) => {
  const newObj: ObjectSchema<string> = {};
  for (let key of consts) {
    newObj[key] = `${constName}_${key}`;
    newObj[`${key}_LOADING`] = `${constName}_${key}_LOADING`;
    newObj[`${key}_SUCCESS`] = `${constName}_${key}_SUCCESS`;
    newObj[`${key}_ERROR`] = `${constName}_${key}_ERROR`;
  }
  return newObj;
};

import { take, put, call, select } from "redux-saga/effects";
import { httpGet, httpPost } from "../../services";
import * as React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { commonHandlerForSiteSwitchingResponse } from "./accountSaga";
import {
  AuthConst,
  initAuthConfigLoadingAction,
  initAuthConfigErrorAction,
  initAuthConfigSuccessAction,
  AppViewPermissionSchema,
  AuthConfigSchema,
  initAuthLoginLoadingAction,
  initAuthLoginSuccessAction,
  AuthLoginSchema,
  initAuthLoginErrorAction,
  getYourSitesAction,
  getYourSitesLoadingAction,
  getYourSitesSuccessAction,
  getYourSitesErrorAction,
  getSharedSitesAction,
  getSharedSitesLoadingAction,
  getSharedSitesSuccessAction,
  getSharedSitesErrorAction,
  initAuthSamlLoginErrorAction,
  initAuthSamlLoginSuccessAction,
  initAuthSamlLoginLoadingAction,
  AuthSamlLoginSchema,
  initAppBasicInfoErrorAction,
  initAppBasicInfoLoadingAction,
  initAppBasicInfoSuccessAction,
  initAppBasicInfoAction,
  initAuthConfigAction,
  AppVersionSchema,
  initAppVersionLoadingAction,
  initAppVersionSuccessAction,
  initAppVersionErrorAction,
  initAppViewPermissionLoadingAction,
  initAppViewPermissionErrorAction,
  initAppViewPermissionSuccessAction,
  getAppFeatureAction,
  getAppFeatureLoadingAction,
  getAppFeatureSuccessAction,
  getAppFeatureErrorAction,
  getIntercomUserDataLoadingAction,
  getIntercomUserDataSuccessAction,
  getIntercomUserDataErrorAction,
  getAuthAppUsersLoadingAction,
  GetAuthAppUsersSchema,
  getAppUsersSuccessAction,
  getAuthAppUsersErrorAction,
  setCurrentAppInfoSuccessAction,
  updateAppBasicInfoErrorAction,
  updateAppBasicInfoSuccessAction,
  AppBasicInfoSchema,
  updateAppBasicInfoLoadingAction,
  initAuthValidateTokenAction,
  initAuthValidateTokenLoadingAction,
  AuthValidateTokenSchema,
  getIntercomUserDataAction,
  getAllSitesLoadingAction,
  GetAllSitesSchema,
  getAllSitesSuccessAction,
  getAllSitesErrorAction,
  GetYourSitesSchema,
  initAuthTokenSuccessAction,
  initAuthTokenLoadingAction,
  initSearchJsonSuccessAction
} from "../actions/authActions";
import {
  showLoadingAction,
  initAppSuccessAction,
  setI18NLocaleAction,
  initLoginEventSuccessAction
} from "../actions/appStateActions";
import {
  getAccountThemeCustomizationAction,
  getAccountThemeCustomizationSuccessAction
} from "../actions/accountActions";
import { setCurrentAppInfo } from "../../utils";
import { getWebAppsAction } from "../actions/deploymentActions";
import { getMultipleSocialProviderAction } from "../actions/platformConfigurationActions";
import { APIErrorResponseSchema, ObjectSchema } from "../actions";

import { localAppStore } from "../../utils";
import { RootSchema } from "../reducers";
import { defaultSearchJson } from "../../config/defaults";
let authConfigLoad = true;
/**
 * Handlers
 */

/*
 * Auth Config Handler
 */
let visitConfig = false;
function* getPermissionHandler() {
  try {
    yield put(initAppViewPermissionLoadingAction());
    const data = yield httpGet("/auth/permissions");
    let { permissions } = data;
    yield put(
      initAppViewPermissionSuccessAction(permissions as AppViewPermissionSchema)
    );
  } catch (e) {
    yield put(initAppViewPermissionErrorAction(e.message));
  }
}
function* authConfigHandler() {
  try {
    const havetoken = yield localAppStore.getItem("lr-x-token");
    const haveSamlApp = location.search.indexOf("samlconfig=") !== -1;
    if (havetoken && location.pathname === "/logout") {
      localStorage.clear();
      localAppStore.clear();
    }
    yield put(showLoadingAction(true, "Please wait..."));
    yield put(initAuthConfigLoadingAction());

    const configData: AuthConfigSchema = yield httpGet("/auth/config");
    yield put(initAuthConfigSuccessAction(configData));
    yield put(initAppSuccessAction());
    const LROptions = {
      apiKey: configData.apikey
    };
    //let $LR = new window.LoginRadiusV2(LROptions);
    const _token = localStorage.getItem("_LRTokenKey");
    if (location.pathname !== "/logout" && !havetoken && !_token) {
      yield put(initAuthTokenLoadingAction());
      const infoData = yield axios
        .get(`https://${configData.domain}/ssologin/login`, {
          withCredentials: true
        })
        .then(res => res.data);
      if (!infoData.token && !haveSamlApp) {
        location.href = "/logout";
      }
      yield put(initLoginEventSuccessAction(true));
      yield put(initAuthTokenSuccessAction(infoData));
    }
    if (
      !visitConfig &&
      configData.IOLoginUrl &&
      havetoken &&
      location.pathname === "/login" &&
      location.search.startsWith("?token=")
    ) {
      visitConfig = true;
      yield localAppStore.removeItem("lr-x-sign");
      yield localAppStore.removeItem("lr-x-token");
      localStorage.removeItem("isLoggedIn");
    }
    //If user loggedin and dont have xtoken then we need to logout user
    if (
      havetoken &&
      location.pathname !== "/logout" &&
      configData.xtoken === ""
    ) {
      localStorage.clear();
      localAppStore.clear();
      location.href = "/logout";
    }
    //User loggedin and have Appid then call basic setting route to get basic features of app
    if (havetoken && configData.currentAppId) {
      //yield put(getAccountThemeCustomizationAction("publish"));// for custom theme
      //Get Intercom user
      yield put(getIntercomUserDataAction());
      //Get Search Json File

      yield call(getSearchJsonData);
      //Get Basic Settings
      if (authConfigLoad) {
        yield put(initAppBasicInfoAction());
        //Get Own Sites
        yield put(getYourSitesAction());
        //Get Shared Sites
        yield put(getSharedSitesAction());
        //Get App Feature
        yield put(getAppFeatureAction());
        //Get Current App Credentials
        yield put(getWebAppsAction());
        yield put(getMultipleSocialProviderAction());
      }
      // Validate Token
      if (
        !configData.IOLoginUrl &&
        location.pathname !== "/login" &&
        location.pathname !== "/logout"
      ) {
        yield put(initAuthValidateTokenAction());
      }
    }
    // User logged in and user  is not superadmin then check "ssoNotLoginThenLogout"
    // if (
    //   typeof window.LoginRadiusV2 === "function" &&
    //   havetoken &&
    //   !configData.superadminUid &&
    //   location.pathname !== "/logout"
    // ) {
    //   const logoutOptions = {
    //     onError: function() {
    //       // If user is not log in then this function will execute.
    //       location.href = "/logout";
    //     }
    //   };

    //     $LR.init("ssoNotLoginThenLogout", logoutOptions);
    //   }
    // }
  } catch (e) {
    yield put(initAuthConfigErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
/*
 * Auth Saml Login  Handler
 */
function* authSamlLoginHandler(samlconfig: string) {
  try {
    yield put(showLoadingAction(true, "Logging in..."));
    yield put(initAuthSamlLoginLoadingAction());
    localStorage.clear();
    localAppStore.clear();
    const data: AuthSamlLoginSchema = yield httpGet("/auth/saml", {
      sitename: samlconfig
    });

    if (data.errorCode) {
      throw new Error();
    }
    data.samlProvider = samlconfig;
    yield put(initAuthSamlLoginSuccessAction(data));
  } catch (e) {
    yield put(initAuthSamlLoginErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
/*
 * Auth Login Handler
 */
function* authLoginHandler(payload) {
  try {
    yield put(showLoadingAction(true, "Logging in..."));
    yield put(initAuthLoginLoadingAction());

    // Clear any cached sign and token
    yield localAppStore.removeItem("lr-x-sign");
    yield localAppStore.removeItem("lr-x-token");
    localStorage.removeItem("isLoggedIn");
    const data: AuthLoginSchema = yield httpPost(
      "/auth/login",
      { appName: payload.appName },
      {
        accesstoken: payload.token,
        domain: payload.domain,
        dataCenter: payload.dataCenter,
        plan: payload.plan
      }
    );

    if (!data.xtoken || data.errorCode || data.ErrorCode) {
      const message = data.message || "";
      throw new Error(message);
    }

    // Set new sign and token
    yield localAppStore.setItem("lr-x-sign", data.xsign);
    yield localAppStore.setItem("lr-x-token", data.xtoken);
    //Get Basic Settings
    yield put(initAppBasicInfoAction());
    const theme = yield httpGet("/account/theme/customization", {
      dir: "publish"
    }); // for custom theme

    localStorage.setItem("isLoggedIn", "true");
    let name = data.app_name || "";
    let appId = data.app_id || "";
    setCurrentAppInfo(name, appId);
    //yield put(initAuthLoginSuccessAction(data));
    if (!theme.errorCode && Array.isArray(theme.Css) && theme.Css.length) {
      authConfigLoad = false;
      const css = yield httpPost(
        "/account/theme/publish/css",
        {},
        { url: String(theme.Css[0]) }
      );
      if (css && css.error !== "fail") {
        let logoPath = "";
        if (Array.isArray(theme.Images) && theme.Images.length) {
          let array = String(theme.Images[0]).split("/");
          if (array.length) {
            logoPath =
              "https://cdn.filestackcontent.com/" + array[array.length - 1];
          }
        }
        localAppStore.setItem("global-root-css", {
          css: css.data,
          appName: name,
          logoPath: logoPath
        });
        ReactDOM.render(
          <style type="text/css">{css.data}</style>,
          document.getElementById("global-root-css")
        );
      }
      //Get Own Sites
      yield put(getYourSitesAction());
      //Get Shared Sites
      yield put(getSharedSitesAction());
      //Get App Feature
      yield put(getAppFeatureAction());
      //Get Current App Credentials
      yield put(getWebAppsAction());
    }
    yield put(initAuthLoginSuccessAction(data));

    yield put(getAccountThemeCustomizationSuccessAction(theme));
    yield call(authConfigHandler);
  } catch (e) {
    yield put(initAuthLoginErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
/*
 * App Basic settings Handler
 */

function* appBasicInfoHandler(noRefresh?: boolean) {
  let plansToRedirect = ["free", "developer", "io"];
  if (process.env.REACT_APP_REDIRECT_DEV_PREMIUM) {
    plansToRedirect.push("business");
  }
  try {
    if (!noRefresh) yield put(showLoadingAction(true, "Please Wait..."));
    yield put(initAppBasicInfoLoadingAction());
    const infoData = yield httpGet("/auth/basicsettings?d=" + Date.now());
    infoData.navigationItems =
      typeof infoData.navigationItems === "string"
        ? JSON.parse(infoData.navigationItems)
        : infoData.navigationItems;
    // Set new sign and token
    yield localAppStore.setItem("lr-x-sign", infoData.xsign);
    yield localAppStore.setItem("lr-x-token", infoData.xtoken);
    let plan =
      (infoData.plan_detail && infoData.plan_detail.name) || "enterprise";
    if (plansToRedirect.includes(plan.toLowerCase())) {
      const redirectionUrl: ObjectSchema<string> = yield select(
        (state: RootSchema) => state.auth.authConfig.redirectionUrl
      );
      if (redirectionUrl[plan.toLowerCase()]) {
        //Clear Session
        localStorage.clear();
        localAppStore.clear();
        location.href = redirectionUrl[plan.toLowerCase()];
      }
    }
    yield put(initAppBasicInfoSuccessAction(infoData));
    // yield localAppStore.setItem("lr-x-sign", infoData.xsign);
    // yield localAppStore.setItem("lr-x-token", infoData.xtoken);
  } catch (e) {
    yield put(initAppBasicInfoErrorAction(e.message));
  } finally {
    //yield put(showLoadingAction(false));
  }
}

function* getYourSitesHandler() {
  try {
    yield put(getYourSitesLoadingAction());
    const data = yield httpGet("/auth/apps");
    let response = data.Data ? data.Data : [];
    yield put(getYourSitesSuccessAction({ response: response }));
    if (response.length > 0) {
      const siteName = localStorage.getItem("siteName");
      for (let i = 0; i < response.length; i++) {
        if (response[i].AppName === siteName) {
          yield put(setCurrentAppInfoSuccessAction(response[i]));
          break;
        }
      }
    }
  } catch (e) {
    yield put(getYourSitesErrorAction(e.message));
  }
}

function* getSharedSitesHandler() {
  try {
    yield put(getSharedSitesLoadingAction());
    const data = yield httpGet("/auth/sharedapps");
    let response = data.Data ? data.Data : [];
    if (response && response.length > 0) {
      const siteName = localStorage.getItem("siteName");
      for (let i = 0; i < response.length; i++) {
        if (response[i].AppName === siteName) {
          response[i].Key = response[i].ApiKey;
          yield put(setCurrentAppInfoSuccessAction(response[i]));
          break;
        }
      }
    } else {
      // get currentAppinfo in store
      const yourSites: GetYourSitesSchema = yield select(
        (state: RootSchema) => state.auth.yourSites
      );
      const currentAppinfo = yield select(
        (state: RootSchema) => state.auth.currentAppinfo
      );
      if (!currentAppinfo.AppName && yourSites.isSuccess) {
        if (yourSites.response && yourSites.response.length) {
          localStorage.setItem("siteName", yourSites.response[0].AppName);
          location.reload();
        } else {
          location.href = "/logout";
        }
      }
    }
    yield put(getSharedSitesSuccessAction({ response: response }));
  } catch (e) {
    yield put(getSharedSitesErrorAction(e.message));
  }
}

export function* initAppVersionHandler() {
  try {
    yield put(initAppVersionLoadingAction());
    const data: AppVersionSchema = yield httpGet("/auth/version");
    yield put(initAppVersionSuccessAction({ ...data }));
  } catch (e) {
    yield put(initAppVersionErrorAction(e.message));
  }
}

export function* initAuthValidateTokenHandler() {
  try {
    yield put(initAuthValidateTokenLoadingAction());
    const data: AuthValidateTokenSchema = yield httpGet("/auth/validatetoken");
    if (data.errorCode) {
      localStorage.clear();
      localAppStore.clear();
      location.href = "/logout";
    }
  } catch (e) {}
}

function* updateAppBasicInfo({ data, item, noReload }) {
  try {
    yield put(updateAppBasicInfoLoadingAction());
    const response: AppBasicInfoSchema = yield httpPost(
      "/auth/submitbasic",
      {},
      data
    );

    if (!response.errorCode) {
      //! Why is this required? -- Versha/Abhimanyu
      response.navigationItems = item;
    }
    const basicInfo = yield select(
      (state: RootSchema) => state.auth.appBasicInfo
    );
    yield put(updateAppBasicInfoSuccessAction(response));
    if (!noReload) {
      yield put(initAppBasicInfoSuccessAction({ ...basicInfo, ...response }));
    }
  } catch (e) {
    yield put(updateAppBasicInfoErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* getIntercomUserDataHandler() {
  const token = yield localAppStore.getItem("lr-x-token");
  const sign = yield localAppStore.getItem("lr-x-sign");
  if (sign && token) {
    try {
      yield put(getIntercomUserDataLoadingAction());

      const response = yield httpGet("/auth/intercom/userdata", {
        getLastLoginActivity: true
      });

      yield put(getIntercomUserDataSuccessAction({ response }));
    } catch (e) {
      yield put(getIntercomUserDataErrorAction(e.message));
    }
  }
}

function* getAppUsersHandler() {
  try {
    yield put(getAuthAppUsersLoadingAction());

    const response: GetAuthAppUsersSchema = yield httpGet("/auth/appusers");

    if (response.message) {
      throw new Error(response.message);
    }

    yield put(getAppUsersSuccessAction(response));
  } catch (e) {
    yield put(getAuthAppUsersErrorAction(e.message));
  }
}

function* getAllSitesHandler() {
  try {
    yield put(getAllSitesLoadingAction());

    const response: GetAllSitesSchema["data"] &
      APIErrorResponseSchema = yield httpGet("/auth/allapps");

    if (response.message) {
      throw new Error(response.message);
    }

    yield put(getAllSitesSuccessAction({ data: response }));
  } catch (e) {
    yield put(getAllSitesErrorAction(e.message));
  }
}

function* getSearchJsonData() {
  if (window.getSearchJson) {
    const searchJson = window.getSearchJson();
    yield put(initSearchJsonSuccessAction({ data: searchJson }));
  } else {
    yield put(initSearchJsonSuccessAction({ data: defaultSearchJson }));
  }
}
/**
 * Watchers
 */

export function* watchAuthConfig() {
  while (true) {
    yield take(AuthConst.INIT_AUTH_CONFIG);
    yield call(authConfigHandler);
  }
}

export function* watchAuthLogin() {
  while (true) {
    const { payload } = yield take(AuthConst.INIT_AUTH_LOGIN);
    yield call(authLoginHandler, payload);
  }
}

export function* watchGetYourSites() {
  while (true) {
    yield take(AuthConst.GET_YOUR_SITES);
    yield call(getYourSitesHandler);
  }
}

export function* watchGetSharedSites() {
  while (true) {
    yield take(AuthConst.GET_SHARED_SITES);
    yield call(getSharedSitesHandler);
  }
}

export function* watchAuthSamlLogin() {
  while (true) {
    const {
      payload: { samlProvider }
    } = yield take(AuthConst.INIT_AUTH_SAML_LOGIN);
    yield call(authSamlLoginHandler, samlProvider);
  }
}

export function* watchAppBasicInfo() {
  while (true) {
    const { payload } = yield take(AuthConst.INIT_APP_BASIC_INFO);
    yield call(appBasicInfoHandler, { ...payload });
  }
}

export function* watchAppVersion() {
  while (true) {
    yield take(AuthConst.INIT_APP_VERSION);
    yield call(initAppVersionHandler);
  }
}

export function* watchAuthValidateToken() {
  while (true) {
    yield take(AuthConst.INIT_AUTH_VALIDATE_TOKEN);
    yield call(initAuthValidateTokenHandler);
  }
}

export function* watchGetPermission() {
  while (true) {
    yield take(AuthConst.INIT_APP_VIEW_PERMISSION);
    yield call(getPermissionHandler);
  }
}
function* getAppFeatureHandler() {
  try {
    yield put(getAppFeatureLoadingAction());
    const data = yield httpGet("/auth/features");
    let features: string[] = [];
    data.Data.map(feature => {
      features[feature.feature] = feature.status;
    });
    yield put(getAppFeatureSuccessAction(features));
  } catch (e) {
    yield put(getAppFeatureErrorAction(e.message));
  }
}
export function* watchGetAppFeature() {
  while (true) {
    yield take(AuthConst.GET_APP_FEATURE);
    yield call(getAppFeatureHandler);
  }
}

export function* watchGetIntercomUserData() {
  while (true) {
    yield take(AuthConst.GET_INTERCOM_USER_DATA);
    yield call(getIntercomUserDataHandler);
  }
}

export function* watchGetAuthAppUsers() {
  while (true) {
    yield take(AuthConst.GET_AUTH_APP_USERS);
    yield call(getAppUsersHandler);
  }
}

export function* watchUpdateAppBasicInfo() {
  while (true) {
    const { payload } = yield take(AuthConst.UPDATE_APP_BASIC_INFO);
    yield call(updateAppBasicInfo, payload);
  }
}

export function* watchGetAllSites() {
  while (true) {
    yield take(AuthConst.GET_ALL_SITES);
    yield call(getAllSitesHandler);
  }
}

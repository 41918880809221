import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Position } from "react-flow-renderer";
import { ObjectSchema } from "../redux/actions";
import { currencyFormatter, getMagicNumber } from "./numberHelper";
import {
  camelCaseToWords,
  capitalizeFirstLetter,
  checkEmptyString,
  getFormattedNumber,
  getUppercaseLetterFromOffset,
  itemLinkEqualsCurrentPath,
  lowercaseFirstLetter,
  lrValidator,
  removeSymbols,
  toTitleCase
} from "./stringHelper";

import { detect } from "detect-browser";
const detectBrowser = detect();

import localForage from "localforage";
import { LanguageSchema } from "../i18n";

const parser = new DOMParser();

let docError;
let parsererrorNS;

if (detectBrowser && detectBrowser.name !== "ie") {
  docError = parser.parseFromString("INVALID", "text/xml");
  parsererrorNS = docError.getElementsByTagName("parsererror")[0].namespaceURI;
}

export const configureLocalForage = () => {
  localForage.config({
    name: "LoginRadius",
    version: parseFloat(process.env.REACT_APP_SITE_VERSION || "1.0.0")
  });
};

const raygunStore = localForage.createInstance({
  name: "LoginRadius",
  storeName: "lr_raygun"
});

const localAppStore = localForage.createInstance({
  name: "LoginRadius",
  storeName: "lr_adminconsole"
});

// util method to prepare data mappings
export const getDataMapping = (data: string[]) => {
  let sortedData = [...data].sort();
  let dataFields = {};
  for (let attr = 0; attr < sortedData.length; attr++) {
    let str = sortedData[attr];
    str = str
      .replace(/-/g, " ")
      .replace(/cf_/g, "")
      .replace(/(^|\s)([a-z])/g, function(m, p1, p2) {
        return p1 + p2.toUpperCase();
      })
      .replace(/([a-z])([A-Z])/g, "$1 $2");
    dataFields[sortedData[attr]] = str.trim();
  }
  return dataFields;
};

export const appendHttps = (url: string) => {
  if (!(url.startsWith("https://") || url.startsWith("http://"))) {
    return "https://" + url;
  } else {
    return url;
  }
};

export const validateEmail = (email: string) => {
  /* eslint-disable no-useless-escape */
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateUrl = (url: string) => {
  const urlPattern = new RegExp(
    /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/i
  );
  return urlPattern.test(url);
};
export const validateLatter = (): RegExp => {
  return new RegExp(
    /^(^[0-9A-Za-z]+|(^[^_!@#$%^&*(),.?":{}|<>]+[0-9A-Za-z]+)|(^[^_!@#$%^&*(),.?":{}|<>]+[0-9A-Za-z_]+))+[a-zA-Z0-9]+$/,
    "gi"
  );
};

export const generatePassword = () => {
  const length = 8;
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal + "1@";
};

export const setCurrentAppInfo = (name: string, id: string) => {
  localStorage.setItem("siteName", name);
};

export const readTextFile = file => {
  return new Promise((resolve: any, reject: any) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsText(file);
  });
};
export const readBinaryFile = (file: File) => {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to read file as ArrayBuffer.'));
      }
    };

    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};



export const parseXML = (xmlString: string) => {
  let doc: any;
  try {
    doc = parser.parseFromString(xmlString, "text/xml");
  } catch (err) {
    return false;
  }
  if (
    detectBrowser &&
    detectBrowser.name !== "ie" &&
    doc.getElementsByTagNameNS(parsererrorNS || "", "parsererror").length > 0
  ) {
    return false;
  }
  let root = document.createElement("div");
  root.appendChild(doc.documentElement);

  return root.innerHTML;
};

export const getTagName = (xmlString: string) => {
  const doc = parser.parseFromString(xmlString, "text/xml");
  return doc.documentElement.getAttribute("name")
    ? doc.documentElement.nodeName +
        "-" +
        doc.documentElement.getAttribute("name")
    : doc.documentElement.nodeName;
};

export const isValidUrl = (url: string) => {
  try {
    new URL("/", url);
    return true;
  } catch (_) {
    return false;
  }
};

export const isValidColor = (strColor: string) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== "";
};

export const containsDuplicates = array => {
  let elementMap = {},
    atLeastOneDuplicate = false,
    arrayLength = array.length;
  for (let i = 0; i < arrayLength; i++) {
    let currentDomain = array[i];
    if (elementMap[currentDomain]) {
      atLeastOneDuplicate = true;
      break;
    } else elementMap[currentDomain] = true;
  }
  return atLeastOneDuplicate;
};

export const isNotLeadingZeroIp = (ip: string) => {
  const octets = ip.split(".");
  for (let i = 0; i < octets.length; i++) {
    if (octets[i].length > 1 && octets[i].startsWith("0")) {
      return false;
    }
  }
  return true;
};
export const isValidIp = (ip: string) => {
  /* eslint-disable no-useless-escape */
  const ipRegexCheck = /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})$/;
  return ipRegexCheck.test(ip);
};

export const CompareTwoIPs = (ip1: any, ip2: any) => {
  var ip1Split = ip1.split(".");
  var ip2Split = ip2.split(".");

  const ip1ToInt =
    ((+ip1Split[0] * 256 + +ip1Split[1]) * 256 + +ip1Split[2]) * 256 +
    +ip1Split[3];
  const ip2ToInt =
    ((+ip2Split[0] * 256 + +ip2Split[1]) * 256 + +ip2Split[2]) * 256 +
    +ip2Split[3];
  if (ip1ToInt < ip2ToInt) {
    return true;
  } else {
    return false;
  }
};

export const isEqualArray = (
  a: any[] | null | undefined,
  b: any[] | null | undefined
) => {
  // Length check
  if (a === null && b === null) return true;
  else if (!a || !b || a.length !== b.length) return false;

  // Value check
  let aObj = {};
  for (let i of a) aObj[i] = true;

  for (let i of b) {
    if (!aObj[i]) return false;
  }
  return true;
};

/**
 * This function will check the equilty in two array of objects
 * @param a Array of Objects
 * @param b Array of Objects
 */
export const isEqualArrayOfObjects = (
  a: any[] | null | undefined,
  b: any[] | null | undefined
) => {
  // Length check
  if (!a || !b || a.length !== b.length) return false;

  for (let i = 0; i < a.length; i++) {
    // Object check
    if (typeof a[i] === "object" && typeof b[i] === "object") {
      if (!isEqualObject(a[i], b[i])) {
        return false;
      }
    }
    // Value check
    else if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
};

/**
 * This function compares 2 objects recursively and returns respective boolean value.
 */
export const isEqualObject = (a, b) => {
  if (!a || !b) {
    return a === b;
  }

  if (a instanceof Array || b instanceof Array) {
    return false;
  }
  // Create arrays of property names
  let aProps = Object.keys(a);
  let bProps = Object.keys(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  // Assuming that property names in a and b are equal
  for (let prop in a) {
    // type match
    if (typeof a[prop] !== typeof b[prop]) {
      return false;
    }
    // Array comparison
    else if (Array.isArray(a[prop])) {
      if (!Array.isArray(b[prop])) return false;
      const isEqual = isEqualArrayOfObjects(a[prop], b[prop]);
      if (!isEqual) return false;
    }
    // Object comparison
    else if (typeof a[prop] === "object") {
      const isEqual = isEqualObject(a[prop], b[prop]);
      if (!isEqual) return false;
    }
    // Primitive type comparison
    else if (a[prop] !== b[prop]) {
      return false;
    }
  }

  return true;
};

/**
 * This function fires actions based on the type of menu item it is.
 */
export const handleMenuItemType = (
  parentLink,
  parentIndex,
  subSection,
  subSectionIndex,
  navigationHandler
) => {
  if (subSection.link && subSection.link.startsWith("http")) {
    return (
      <a
        id={"lnk_" + removeSymbols(subSection.displayName)}
        key={subSectionIndex}
        target="_blank"
        rel="noopener noreferrer"
        href={subSection.link}
        className={`dropdown-item ${subSection.isActive ? " active" : ""}`}
      >
        {subSection.displayName}
      </a>
    );
  } else if (subSection.link && subSection.link.includes("/chat")) {
    return (
      <a
        href="#"
        id={"lnk_" + removeSymbols(subSection.displayName)}
        title="Live Chat"
        key={subSectionIndex}
        className={`dropdown-item ${subSection.isActive ? " active" : ""}`}
        onClick={() => window.sh_zendesk && window.sh_zendesk("show")}
      >
        {subSection.displayName}
      </a>
    );
  }
  return (
    <Link
      id={"lnk_" + removeSymbols(subSection.displayName)}
      key={subSectionIndex}
      className={`dropdown-item ${subSection.isActive ? " active" : ""}`}
      to={parentLink + (subSection.link || "")}
      onClick={() => navigationHandler(parentIndex, 0, subSectionIndex)}
    >
      {subSection.displayName}
    </Link>
  );
};

/**
 * This function converts timezone.
 * @param datetime A date string to convert timezone
 */
export const convertTimezone = (
  datetime: string,
  from: string | null,
  to?: string
) => {
  let datetimeString = datetime.substr(0, 19);
  from = from || datetime.substr(19);
  to = to || "+00:00";
  let rawDatetime = datetimeString + from;
  let splitedTo = to.split(":");
  return (
    moment(rawDatetime)
      .utc()
      .add(+splitedTo[0], "hours")
      .add(+splitedTo[1], "minutes")
      .format()
      .substr(0, 19) + (to === "+00:00" ? "Z" : to)
  );
};

/**
 * Get the first three letters of the month whose number is provided, where 1 corresponds to Jan (January)
 * and 12 to Dec (December.)
 * @param month Number of month whose three letter string is to be returned.
 */
export const getThreeLetterMonthWordFromNumber = (month: number) => {
  if (month < 1 || month > 12) {
    return `Argument must be a value between 1 and 12, but was ${month}`;
  }
  let word;
  switch (month) {
    case 1:
      word = "Jan";
      break;
    case 2:
      word = "Feb";
      break;
    case 3:
      word = "Mar";
      break;
    case 4:
      word = "Apr";
      break;
    case 5:
      word = "May";
      break;
    case 6:
      word = "Jun";
      break;
    case 7:
      word = "Jul";
      break;
    case 8:
      word = "Aug";
      break;
    case 9:
      word = "Sep";
      break;
    case 10:
      word = "Oct";
      break;
    case 11:
      word = "Nov";
      break;
    case 12:
      word = "Dec";
      break;
  }
  return word;
};

// Key constants for setting support ticket data in LocalStorage

const ForwardSupportTicketConsts = {
  SUPPORT_TICKET_TICKET_CATEGORY: "supportTicket_ticketCategory",
  SUPPORT_TICKET_PRIORITY: "supportTicket_priority",
  SUPPORT_TICKET_TITLE: "supportTicket_title",
  SUPPORT_TICKET_DESCRIPTION: "supportTicket_description"
};

export const setSupportData = (supportPageData: any) => {
  if ("ticketCategory" in supportPageData) {
    localStorage.setItem(
      ForwardSupportTicketConsts.SUPPORT_TICKET_TICKET_CATEGORY,
      supportPageData.ticketCategory
    );
  }
  if ("priority" in supportPageData) {
    localStorage.setItem(
      ForwardSupportTicketConsts.SUPPORT_TICKET_PRIORITY,
      supportPageData.priority
    );
  }
  if ("title" in supportPageData) {
    localStorage.setItem(
      ForwardSupportTicketConsts.SUPPORT_TICKET_TITLE,
      supportPageData.title
    );
  }
  if ("description" in supportPageData) {
    localStorage.setItem(
      ForwardSupportTicketConsts.SUPPORT_TICKET_DESCRIPTION,
      supportPageData.description
    );
  }
};

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
const getNodeIntersection = (intersectionNode: any, targetNode: any) => {
  /// Source: https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    positionAbsolute: intersectionNodePosition
  } = intersectionNode;
  const targetPosition = targetNode.positionAbsolute;

  const w = intersectionNodeWidth / 2;
  const h = intersectionNodeHeight / 2;

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + w;
  const y1 = targetPosition.y + h;

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;

  return { x, y };
};

// returns the position (top,right,bottom or right) passed node compared to the intersection point
const getEdgePosition = (node: any, intersectionPoint: any) => {
  const n = { ...node.positionAbsolute, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
};

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export const getEdgeParams = (source: any, target: any) => {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos
  };
};

export const getSupportData = () => {
  let supportData: any = {};

  const ticketCategory = localStorage.getItem(
    ForwardSupportTicketConsts.SUPPORT_TICKET_TICKET_CATEGORY
  );
  if (ticketCategory) {
    supportData.ticketCategory = ticketCategory;
  }
  const priority = localStorage.getItem(
    ForwardSupportTicketConsts.SUPPORT_TICKET_PRIORITY
  );
  if (priority) {
    supportData.priority = priority;
  }
  const title = localStorage.getItem(
    ForwardSupportTicketConsts.SUPPORT_TICKET_TITLE
  );
  if (title) {
    supportData.title = title;
  }
  const description = localStorage.getItem(
    ForwardSupportTicketConsts.SUPPORT_TICKET_DESCRIPTION
  );
  if (description) {
    supportData.description = description;
  }

  return supportData;
};

export const clearSupportData = () => {
  localStorage.setItem(
    ForwardSupportTicketConsts.SUPPORT_TICKET_TICKET_CATEGORY,
    ""
  );
  localStorage.setItem(ForwardSupportTicketConsts.SUPPORT_TICKET_PRIORITY, "");
  localStorage.setItem(ForwardSupportTicketConsts.SUPPORT_TICKET_TITLE, "");
  localStorage.setItem(
    ForwardSupportTicketConsts.SUPPORT_TICKET_DESCRIPTION,
    ""
  );
};

export const getVerificationUrlList = () => {
  const lrVerificationUrls = localStorage.getItem("lrVerificationUrls");

  return lrVerificationUrls !== null ? lrVerificationUrls.split(";") : [""];
};

export const setVerificationUrls = (url: string) => {
  if (url && url.length) {
    url = url.replace(/\s+/g, "").toLowerCase();

    if (!getVerificationUrlList().includes(url)) {
      localStorage.setItem(
        "lrVerificationUrls",
        getVerificationUrlList().join(";")
      );
    }
  }
};

/**
 * This function clones the object passed in the argument to it's deepest level
 * @param obj Object
 */
const deepClone = (obj: any) => {
  const newObj: any = {};

  for (let i in obj) {
    // Clone array
    if (obj[i] instanceof Array) {
      const newArr: any = [];
      for (let j in obj[i]) {
        if (typeof obj[i][j] === "object") {
          newArr.push(deepClone(obj[i][j]));
        } else newArr.push(obj[i][j]);
      }
      newObj[i] = newArr;
    }
    // Null handle
    else if (obj[i] === null) {
      newObj[i] = null;
    }
    // Clone object
    else if (typeof obj[i] === "object") {
      newObj[i] = deepClone(obj[i]);
    }
    // Simple value
    else newObj[i] = obj[i];
  }
  return newObj;
};

const keyToLowerCase = (obj: any) => {
  const newObj: any = {};
  for (let i in obj) {
    // Clone array
    if (obj[i] instanceof Array) {
      const newArr: any = [];
      for (let j in obj[i]) {
        if (typeof obj[i][j] === "object") {
          newArr.push(keyToLowerCase(obj[i][j]));
        } else newArr.push(obj[i][j]);
      }
      newObj[i.toLowerCase()] = newArr;
    }
    // Null handle
    else if (obj[i] === null) {
      newObj[i.toLowerCase()] = null;
    }
    // Clone object
    else if (typeof obj[i] === "object") {
      newObj[i.toLowerCase()] = keyToLowerCase(obj[i]);
    }
    // Simple value
    else newObj[i.toLowerCase()] = obj[i];
  }
  return newObj;
};

/**
 * This function flattens the object to its simplest form.
 * @param obj Object needs to be flattned
 */

const flatten = (obj: ObjectSchema<any>) => {
  let temp: ObjectSchema<any> = {};

  for (let key in obj) {
    if (obj[key] instanceof Array) {
      temp[key] = [];
      for (let item of obj[key]) {
        if (typeof item === "object") {
          temp[key].push(flatten(item));
        } else {
          temp[key].push(item);
        }
      }
    } else if (typeof obj[key] === "object") {
      const flatObj = flatten(obj[key]);
      for (let key2 in flatObj) {
        temp[key + "." + key2] = flatObj[key2];
      }
    } else {
      temp[key] = obj[key];
    }
  }

  return temp;
};

/**
 * This function provides an easy way to cancel the pending promise.
 * The pending subscription can be cancelled by calling the cancel() method.
 * @param promise A Promise Object
 */
const makeCancelable = (promise: Promise<any>) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then(val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)))
      .catch(error =>
        hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
      );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    }
  };
};
const checkIfArrayHasUniqueItems = myArray => {
  return myArray.length === new Set(myArray).size;
};
const arrayHasUniqueObject = (arr, prop, isBoolean) => {
  let isUnique = true;
  let tempArray = arr.reduce((a, d) => {
    if (!a.includes(d[prop])) {
      a.push(d[prop]);
    } else {
      isUnique = false;
    }
    return a;
  }, []);

  return isBoolean ? isUnique : tempArray;
};

/**
 * Returns the Boolean version of the provided string; if the string is not "true", then
 * Boolean value false is returned.
 * @param str   String representing a potential boolean value; ideally either "true" or "false".
 */
const booleanStringToBoolean = (str: string): boolean => {
  return str === "true";
};

const xmlToJson = xml => {
  // Create the return object
  let obj = {};

  if (xml.nodeType === 1) {
    // element
    // do attributes
    if (xml.attributes.length > 0) {
      obj["@attributes"] = {};
      for (let j = 0; j < xml.attributes.length; j++) {
        let attribute = xml.attributes.item(j);
        obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType === 3) {
    // text
    obj = xml.nodeValue;
  }

  // do children
  // If all text nodes inside, get concatenated text from them.
  let textNodes = [].slice
    .call(xml.childNodes)
    .filter(({ nodeType }) => nodeType === 3);
  if (xml.hasChildNodes() && xml.childNodes.length === textNodes.length) {
    obj = [].slice
      .call(xml.childNodes)
      .reduce((text, { nodeValue }) => text + nodeValue, "");
  } else if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      let item = xml.childNodes.item(i);
      let nodeName = item.nodeName.replace("md:", "");
      if (typeof obj[nodeName] === "undefined") {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (typeof obj[nodeName].push === "undefined") {
          let old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xmlToJson(item));
      }
    }
  }
  return obj;
};

const loadContent = (src: string, type?: "script" | "link") => {
  return new Promise((resolve, reject) => {
    type = type || "script";

    const ele = document.createElement(type);
    ele.onload = resolve;
    ele.onerror = reject;

    if (ele instanceof HTMLScriptElement) {
      ele.type = "text/javascript";
      ele.src = src;
      document.body.appendChild(ele);
    } else if (ele instanceof HTMLLinkElement) {
      ele.rel = "stylesheet";
      ele.href = src;
      document.head.appendChild(ele);
    }
  });
};

/* eslint-disable @typescript-eslint/camelcase */
const validationRuleMessages = (i18n: LanguageSchema, display = "") => {
  return {
    required: `The ${display} ${i18n.REQUIRED}`,
    custom_validation: `${display} ${i18n.CUSTOM_VALIDATION}`,
    matches: `The ${display} ${i18n.MATCHES}`,
    min_length: `The ${display} ${i18n.MIN_LENGTH}`,
    max_length: `The ${display} ${i18n.MAX_LENGTH}`,
    exact_length: `The ${display} ${i18n.EXACT_LENGTH}`,
    greater_than: `The ${display} ${i18n.GREATER_THAN}`,
    less_than: `The ${display} ${i18n.LESS_THAN}`,
    alpha: `The ${display} ${i18n.ALPHA}`,
    alpha_numeric: `The ${display} ${i18n.ALPHA_NUMERIC}`,
    alphanumeric_combo: `The ${display} ${i18n.ALPHANUMERIC_COMBO}`,
    alpha_dash: `The ${display} ${i18n.ALPHA_DASH}`,
    alpha_numeric_dash_combo: `The ${display} ${i18n.ALPHA_NUMERIC_DASH_COMBO}`,
    numeric: `The ${display} ${i18n.NUMERIC}`,
    integer: `The ${display} ${i18n.INTEGER}`,
    decimal: `The ${display} ${i18n.DECIMAL}`,
    is_natural: `The ${display} ${i18n.IS_NATURAL}`,
    is_natural_no_zero: `The ${display} ${i18n.IS_NATURAL_NO_ZERO}`,
    valid_ca_zip: `The ${display} ${i18n.VALID_CA_ZIP}`,
    valid_credit_card: `The ${display} ${i18n.VALID_CREDIT_CARD}`,
    valid_phoneno: `The ${display} ${i18n.VALID_PHONENO}`,
    valid_url: `The ${display} ${i18n.VALID_URL}`,
    valid_ip: `The ${display} ${i18n.VALID_IP}`,
    valid_email: `The ${display} ${i18n.VALID_EMAIL}`,
    valid_base64: `The ${display} ${i18n.VALID_BASE64}`,
    callback_valid_date: `The ${display} ${i18n.VALID_DATE}`
  };
};

/**
 * This function performs a diff between the set of source and dest array.
 *
 * The arrays must be only of primitive types: string, number, boolean
 *
 * @return [missingItems, addedItems]
 */
function diffArrays<T = any>(srcArr: T[], destArr: T[]): [T[], T[]] {
  const missingFromSrc: T[] = [];
  const addedToSrc: T[] = [];

  try {
    // Map source and dest arrays
    const srcMap = new Map<T, boolean>();
    const destMap = new Map<T, boolean>();
    for (let item of srcArr) srcMap.set(item, true);
    for (let item of destArr) destMap.set(item, true);

    // Diff source and dest maps
    for (let [key, _value] of srcMap) {
      if (!destMap.get(key)) missingFromSrc.push(key);
    }
    for (let [key, _value] of destMap) {
      if (!srcMap.get(key)) addedToSrc.push(key);
    }
  } catch (e) {
    console.error(e);
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return [missingFromSrc, addedToSrc]; //NOSONAR
  }
}
const churnzeroEventLog = (pageName?: string) => {
  if (window.ChurnZero && pageName) {
    window.ChurnZero &&
      window.ChurnZero.push(["trackEvent", pageName, pageName]);
  }
};
const SpecialCapsConversion = [
  {
    from: "Sms",
    to: "SMS"
  },
  {
    from: "Etl",
    to: "ETL"
  },
  {
    from: "Otp",
    to: "OTP"
  },
  {
    from: "Oauth",
    to: "OAuth"
  },
  {
    from: "Saml",
    to: "SAML"
  },
  {
    from: "Adfs",
    to: "ADFS"
  },
  {
    from: "Jwt",
    to: "JWT"
  },
  {
    from: "Idps",
    to: "IDPs"
  },
  {
    from: "Rba",
    to: "RBA"
  },
  {
    from: "Api",
    to: "API"
  },
  {
    from: "Sso",
    to: "SSO"
  },
  {
    from: "Js",
    to: "JS"
  }
];

const _getFormattedPath = (newPath: string) => {
  let pathList = newPath.split("/");
  const pathToArray = pathList.map(path => {
    // eslint-disable-next-line
		let p = toTitleCase(path.replace(/\-/g, ' '));
    for (let conversion of SpecialCapsConversion) {
      p = p.replace(conversion.from, conversion.to);
    }
    return p;
  });
  return pathToArray.join("/");
};

const IdpMetadatTemplate = idp => {
  return (
    `<?xml version="1.0" encoding="UTF-8"?>
    <md:EntityDescriptor xmlns:md="#xmlns_metadata#" entityID="#entityID#" xmlns:ds="http://www.w3.org/2000/09/xmldsig#">
    <md:SPSSODescriptor protocolSupportEnumeration="#protocol#">
       <md:KeyDescriptor use="signing">
          <ds:KeyInfo>
             <ds:X509Data>
                <ds:X509Certificate>#spcertificate#</ds:X509Certificate>
             </ds:X509Data>
          </ds:KeyInfo>
       </md:KeyDescriptor>
      <md:NameIDFormat>urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified</md:NameIDFormat>
      <md:AssertionConsumerService Binding="#` +
    (idp ? "bindingidp" : "binding") +
    `#" Location="#sso_location#"/>
    </md:SPSSODescriptor>
  </md:EntityDescriptor>`
  );
};

const checkOauthApp = (appList: any[], appName: string): string => {
  let errMsg = "";
  for (const app of appList) {
    if (app.AppName === appName) {
      if (app.Protocol.includes("OpenID")) {
        errMsg = "A OpenID config already exists";
        break;
      }
      if (app.Protocol.includes("OAuth 2.0")) {
        errMsg = "A OAuth 2.0 config already exists";
        break;
      }
    }
  }
  return errMsg;
};

export {
  IdpMetadatTemplate,
  SpecialCapsConversion,
  _getFormattedPath,
  arrayHasUniqueObject,
  booleanStringToBoolean,
  camelCaseToWords,
  capitalizeFirstLetter,
  checkEmptyString,
  checkIfArrayHasUniqueItems,
  checkOauthApp,
  churnzeroEventLog,
  currencyFormatter,
  deepClone,
  diffArrays,
  flatten,
  getFormattedNumber,
  getMagicNumber,
  getUppercaseLetterFromOffset,
  itemLinkEqualsCurrentPath,
  keyToLowerCase,
  loadContent,
  localAppStore,
  lowercaseFirstLetter,
  lrValidator,
  makeCancelable,
  raygunStore,
  removeSymbols,
  toTitleCase,
  validationRuleMessages,
  xmlToJson
};

import { NavigationItemsSchema } from "../containers/Navigation";
// Assets
import mailazy from "../assets/image/SmtpProviderLogos/mailazy.png";
import other from "../assets/image/SmtpProviderLogos/other-logo.png";
import amazonSesUsEastLogo from "../assets/image/SmtpProviderLogos/amazon-ses-us-east.jpg";
import amazonSesUsWestLogo from "../assets/image/SmtpProviderLogos/amazon-ses-us-west.jpg";
import amazonSesEuLogo from "../assets/image/SmtpProviderLogos/amazon-ses-eu.jpg";
import gmailLogo from "../assets/image/SmtpProviderLogos/gmail.jpg";
import mandrillLogo from "../assets/image/SmtpProviderLogos/mandrill.jpg";
import mailgunLogo from "../assets/image/SmtpProviderLogos/mailgun.jpg";
import sendgridLogo from "../assets/image/SmtpProviderLogos/sendgrid.jpg";
import yahooLogo from "../assets/image/SmtpProviderLogos/yahoo.jpg";

export const timeZonesJson = [
  {
    value: "Dateline Standard Time",
    abbr: "DST",
    offset: -12,
    isdst: false,
    text: "(UTC-12:00) International Date Line West",
    utc: ["Etc/GMT+12"]
  },
  {
    value: "UTC-11",
    abbr: "U",
    offset: -11,
    isdst: false,
    text: "(UTC-11:00) Coordinated Universal Time-11",
    utc: ["Etc/GMT+11", "Pacific/Midway", "Pacific/Niue", "Pacific/Pago_Pago"]
  },
  {
    value: "Hawaiian Standard Time",
    abbr: "HST",
    offset: -10,
    isdst: false,
    text: "(UTC-10:00) Hawaii",
    utc: [
      "Etc/GMT+10",
      "Pacific/Honolulu",
      "Pacific/Johnston",
      "Pacific/Rarotonga",
      "Pacific/Tahiti"
    ]
  },
  {
    value: "Alaskan Standard Time",
    abbr: "AKDT",
    offset: -8,
    isdst: true,
    text: "(UTC-09:00) Alaska",
    utc: [
      "America/Anchorage",
      "America/Juneau",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat"
    ]
  },
  {
    value: "Pacific Standard Time (Mexico)",
    abbr: "PDT",
    offset: -7,
    isdst: true,
    text: "(UTC-08:00) Baja California",
    utc: ["America/Santa_Isabel"]
  },
  {
    value: "Pacific Standard Time",
    abbr: "PDT",
    offset: -7,
    isdst: true,
    text: "(UTC-08:00) Pacific Time (US & Canada)",
    utc: [
      "America/Dawson",
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "America/Whitehorse",
      "PST8PDT"
    ]
  },
  {
    value: "US Mountain Standard Time",
    abbr: "UMST",
    offset: -7,
    isdst: false,
    text: "(UTC-07:00) Arizona",
    utc: [
      "America/Creston",
      "America/Dawson_Creek",
      "America/Hermosillo",
      "America/Phoenix",
      "Etc/GMT+7"
    ]
  },
  {
    value: "Mountain Standard Time (Mexico)",
    abbr: "MDT",
    offset: -6,
    isdst: true,
    text: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    utc: ["America/Chihuahua", "America/Mazatlan"]
  },
  {
    value: "Mountain Standard Time",
    abbr: "MDT",
    offset: -6,
    isdst: true,
    text: "(UTC-07:00) Mountain Time (US & Canada)",
    utc: [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT"
    ]
  },
  {
    value: "Central America Standard Time",
    abbr: "CAST",
    offset: -6,
    isdst: false,
    text: "(UTC-06:00) Central America",
    utc: [
      "America/Belize",
      "America/Costa_Rica",
      "America/El_Salvador",
      "America/Guatemala",
      "America/Managua",
      "America/Tegucigalpa",
      "Etc/GMT+6",
      "Pacific/Galapagos"
    ]
  },
  {
    value: "Central Standard Time",
    abbr: "CDT",
    offset: -5,
    isdst: true,
    text: "(UTC-06:00) Central Time (US & Canada)",
    utc: [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT"
    ]
  },
  {
    value: "Central Standard Time (Mexico)",
    abbr: "CDT",
    offset: -5,
    isdst: true,
    text: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    utc: [
      "America/Bahia_Banderas",
      "America/Cancun",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey"
    ]
  },
  {
    value: "Canada Central Standard Time",
    abbr: "CCST",
    offset: -6,
    isdst: false,
    text: "(UTC-06:00) Saskatchewan",
    utc: ["America/Regina", "America/Swift_Current"]
  },
  {
    value: "SA Pacific Standard Time",
    abbr: "SPST",
    offset: -5,
    isdst: false,
    text: "(UTC-05:00) Bogota, Lima, Quito",
    utc: [
      "America/Bogota",
      "America/Cayman",
      "America/Coral_Harbour",
      "America/Eirunepe",
      "America/Guayaquil",
      "America/Jamaica",
      "America/Lima",
      "America/Panama",
      "America/Rio_Branco",
      "Etc/GMT+5"
    ]
  },
  {
    value: "Eastern Standard Time",
    abbr: "EDT",
    offset: -4,
    isdst: true,
    text: "(UTC-05:00) Eastern Time (US & Canada)",
    utc: [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
      "EST5EDT"
    ]
  },
  {
    value: "US Eastern Standard Time",
    abbr: "UEDT",
    offset: -4,
    isdst: true,
    text: "(UTC-05:00) Indiana (East)",
    utc: [
      "America/Indiana/Marengo",
      "America/Indiana/Vevay",
      "America/Indianapolis"
    ]
  },
  {
    value: "Venezuela Standard Time",
    abbr: "VST",
    offset: -4.5,
    isdst: false,
    text: "(UTC-04:30) Caracas",
    utc: ["America/Caracas"]
  },
  {
    value: "Paraguay Standard Time",
    abbr: "PST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Asuncion",
    utc: ["America/Asuncion"]
  },
  {
    value: "Atlantic Standard Time",
    abbr: "ADT",
    offset: -3,
    isdst: true,
    text: "(UTC-04:00) Atlantic Time (Canada)",
    utc: [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
      "America/Thule",
      "Atlantic/Bermuda"
    ]
  },
  {
    value: "Central Brazilian Standard Time",
    abbr: "CBST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Cuiaba",
    utc: ["America/Campo_Grande", "America/Cuiaba"]
  },
  {
    value: "SA Western Standard Time",
    abbr: "SWST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    utc: [
      "America/Anguilla",
      "America/Antigua",
      "America/Aruba",
      "America/Barbados",
      "America/Blanc-Sablon",
      "America/Boa_Vista",
      "America/Curacao",
      "America/Dominica",
      "America/Grand_Turk",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guyana",
      "America/Kralendijk",
      "America/La_Paz",
      "America/Lower_Princes",
      "America/Manaus",
      "America/Marigot",
      "America/Martinique",
      "America/Montserrat",
      "America/Port_of_Spain",
      "America/Porto_Velho",
      "America/Puerto_Rico",
      "America/Santo_Domingo",
      "America/St_Barthelemy",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/St_Thomas",
      "America/St_Vincent",
      "America/Tortola",
      "Etc/GMT+4"
    ]
  },
  {
    value: "Pacific SA Standard Time",
    abbr: "PSST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Santiago",
    utc: ["America/Santiago", "Antarctica/Palmer"]
  },
  {
    value: "Newfoundland Standard Time",
    abbr: "NDT",
    offset: -2.5,
    isdst: true,
    text: "(UTC-03:30) Newfoundland",
    utc: ["America/St_Johns"]
  },
  {
    value: "E. South America Standard Time",
    abbr: "ESAST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Brasilia",
    utc: ["America/Sao_Paulo"]
  },
  {
    value: "Argentina Standard Time",
    abbr: "AST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Buenos Aires",
    utc: [
      "America/Argentina/La_Rioja",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Buenos_Aires",
      "America/Catamarca",
      "America/Cordoba",
      "America/Jujuy",
      "America/Mendoza"
    ]
  },
  {
    value: "SA Eastern Standard Time",
    abbr: "SEST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Cayenne, Fortaleza",
    utc: [
      "America/Araguaina",
      "America/Belem",
      "America/Cayenne",
      "America/Fortaleza",
      "America/Maceio",
      "America/Paramaribo",
      "America/Recife",
      "America/Santarem",
      "Antarctica/Rothera",
      "Atlantic/Stanley",
      "Etc/GMT+3"
    ]
  },
  {
    value: "Greenland Standard Time",
    abbr: "GDT",
    offset: -2,
    isdst: true,
    text: "(UTC-03:00) Greenland",
    utc: ["America/Godthab"]
  },
  {
    value: "Montevideo Standard Time",
    abbr: "MST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Montevideo",
    utc: ["America/Montevideo"]
  },
  {
    value: "Bahia Standard Time",
    abbr: "BST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Salvador",
    utc: ["America/Bahia"]
  },
  {
    value: "UTC-02",
    abbr: "U",
    offset: -2,
    isdst: false,
    text: "(UTC-02:00) Coordinated Universal Time-02",
    utc: ["America/Noronha", "Atlantic/South_Georgia", "Etc/GMT+2"]
  },
  {
    value: "Mid-Atlantic Standard Time",
    abbr: "MDT",
    offset: -1,
    isdst: true,
    text: "(UTC-02:00) Mid-Atlantic - Old"
  },
  {
    value: "Azores Standard Time",
    abbr: "ADT",
    offset: 0,
    isdst: true,
    text: "(UTC-01:00) Azores",
    utc: ["America/Scoresbysund", "Atlantic/Azores"]
  },
  {
    value: "Cape Verde Standard Time",
    abbr: "CVST",
    offset: -1,
    isdst: false,
    text: "(UTC-01:00) Cape Verde Is.",
    utc: ["Atlantic/Cape_Verde", "Etc/GMT+1"]
  },
  {
    value: "Morocco Standard Time",
    abbr: "MDT",
    offset: 1,
    isdst: true,
    text: "(UTC) Casablanca",
    utc: ["Africa/Casablanca", "Africa/El_Aaiun"]
  },
  {
    value: "UTC",
    abbr: "CUT",
    offset: 0,
    isdst: false,
    text: "(UTC) Coordinated Universal Time",
    utc: ["America/Danmarkshavn", "Etc/GMT"]
  },
  {
    value: "GMT Standard Time",
    abbr: "GDT",
    offset: 1,
    isdst: true,
    text: "(UTC) Dublin, Edinburgh, Lisbon, London",
    utc: [
      "Atlantic/Canary",
      "Atlantic/Faeroe",
      "Atlantic/Madeira",
      "Europe/Dublin",
      "Europe/Guernsey",
      "Europe/Isle_of_Man",
      "Europe/Jersey",
      "Europe/Lisbon",
      "Europe/London"
    ]
  },
  {
    value: "Greenwich Standard Time",
    abbr: "GST",
    offset: 0,
    isdst: false,
    text: "(UTC) Monrovia, Reykjavik",
    utc: [
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Bamako",
      "Africa/Banjul",
      "Africa/Bissau",
      "Africa/Conakry",
      "Africa/Dakar",
      "Africa/Freetown",
      "Africa/Lome",
      "Africa/Monrovia",
      "Africa/Nouakchott",
      "Africa/Ouagadougou",
      "Africa/Sao_Tome",
      "Atlantic/Reykjavik",
      "Atlantic/St_Helena"
    ]
  },
  {
    value: "W. Europe Standard Time",
    abbr: "WEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    utc: [
      "Arctic/Longyearbyen",
      "Europe/Amsterdam",
      "Europe/Andorra",
      "Europe/Berlin",
      "Europe/Busingen",
      "Europe/Gibraltar",
      "Europe/Luxembourg",
      "Europe/Malta",
      "Europe/Monaco",
      "Europe/Oslo",
      "Europe/Rome",
      "Europe/San_Marino",
      "Europe/Stockholm",
      "Europe/Vaduz",
      "Europe/Vatican",
      "Europe/Vienna",
      "Europe/Zurich"
    ]
  },
  {
    value: "Central Europe Standard Time",
    abbr: "CEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    utc: [
      "Europe/Belgrade",
      "Europe/Bratislava",
      "Europe/Budapest",
      "Europe/Ljubljana",
      "Europe/Podgorica",
      "Europe/Prague",
      "Europe/Tirane"
    ]
  },
  {
    value: "Romance Standard Time",
    abbr: "RDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    utc: [
      "Africa/Ceuta",
      "Europe/Brussels",
      "Europe/Copenhagen",
      "Europe/Madrid",
      "Europe/Paris"
    ]
  },
  {
    value: "Central European Standard Time",
    abbr: "CEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    utc: ["Europe/Sarajevo", "Europe/Skopje", "Europe/Warsaw", "Europe/Zagreb"]
  },
  {
    value: "W. Central Africa Standard Time",
    abbr: "WCAST",
    offset: 1,
    isdst: false,
    text: "(UTC+01:00) West Central Africa",
    utc: [
      "Africa/Algiers",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Douala",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Luanda",
      "Africa/Malabo",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Porto-Novo",
      "Africa/Tunis",
      "Etc/GMT-1"
    ]
  },
  {
    value: "Namibia Standard Time",
    abbr: "NST",
    offset: 1,
    isdst: false,
    text: "(UTC+01:00) Windhoek",
    utc: ["Africa/Windhoek"]
  },
  {
    value: "GTB Standard Time",
    abbr: "GDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Athens, Bucharest",
    utc: [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau"
    ]
  },
  {
    value: "Middle East Standard Time",
    abbr: "MEDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Beirut",
    utc: ["Asia/Beirut"]
  },
  {
    value: "Egypt Standard Time",
    abbr: "EST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Cairo",
    utc: ["Africa/Cairo"]
  },
  {
    value: "Syria Standard Time",
    abbr: "SDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Damascus",
    utc: ["Asia/Damascus"]
  },
  {
    value: "E. Europe Standard Time",
    abbr: "EEDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) E. Europe"
  },
  {
    value: "South Africa Standard Time",
    abbr: "SAST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Harare, Pretoria",
    utc: [
      "Africa/Blantyre",
      "Africa/Bujumbura",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Kigali",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Etc/GMT-2"
    ]
  },
  {
    value: "FLE Standard Time",
    abbr: "FDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    utc: [
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye"
    ]
  },
  {
    value: "Turkey Standard Time",
    abbr: "TDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Istanbul",
    utc: ["Europe/Istanbul"]
  },
  {
    value: "Israel Standard Time",
    abbr: "JDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Jerusalem",
    utc: ["Asia/Jerusalem"]
  },
  {
    value: "Libya Standard Time",
    abbr: "LST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Tripoli",
    utc: ["Africa/Tripoli"]
  },
  {
    value: "Jordan Standard Time",
    abbr: "JST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Amman",
    utc: ["Asia/Amman"]
  },
  {
    value: "Arabic Standard Time",
    abbr: "AST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Baghdad",
    utc: ["Asia/Baghdad"]
  },
  {
    value: "Kaliningrad Standard Time",
    abbr: "KST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Kaliningrad, Minsk",
    utc: ["Europe/Kaliningrad", "Europe/Minsk"]
  },
  {
    value: "Arab Standard Time",
    abbr: "AST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Kuwait, Riyadh",
    utc: [
      "Asia/Aden",
      "Asia/Bahrain",
      "Asia/Kuwait",
      "Asia/Qatar",
      "Asia/Riyadh"
    ]
  },
  {
    value: "E. Africa Standard Time",
    abbr: "EAST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Nairobi",
    utc: [
      "Africa/Addis_Ababa",
      "Africa/Asmera",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Khartoum",
      "Africa/Mogadishu",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Etc/GMT-3",
      "Indian/Antananarivo",
      "Indian/Comoro",
      "Indian/Mayotte"
    ]
  },
  {
    value: "Iran Standard Time",
    abbr: "IDT",
    offset: 4.5,
    isdst: true,
    text: "(UTC+03:30) Tehran",
    utc: ["Asia/Tehran"]
  },
  {
    value: "Arabian Standard Time",
    abbr: "AST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Abu Dhabi, Muscat",
    utc: ["Asia/Dubai", "Asia/Muscat", "Etc/GMT-4"]
  },
  {
    value: "Azerbaijan Standard Time",
    abbr: "ADT",
    offset: 5,
    isdst: true,
    text: "(UTC+04:00) Baku",
    utc: ["Asia/Baku"]
  },
  {
    value: "Russian Standard Time",
    abbr: "RST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Moscow, St. Petersburg, Volgograd",
    utc: [
      "Europe/Moscow",
      "Europe/Samara",
      "Europe/Simferopol",
      "Europe/Volgograd"
    ]
  },
  {
    value: "Mauritius Standard Time",
    abbr: "MST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Port Louis",
    utc: ["Indian/Mahe", "Indian/Mauritius", "Indian/Reunion"]
  },
  {
    value: "Georgian Standard Time",
    abbr: "GST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Tbilisi",
    utc: ["Asia/Tbilisi"]
  },
  {
    value: "Caucasus Standard Time",
    abbr: "CST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Yerevan",
    utc: ["Asia/Yerevan"]
  },
  {
    value: "Afghanistan Standard Time",
    abbr: "AST",
    offset: 4.5,
    isdst: false,
    text: "(UTC+04:30) Kabul",
    utc: ["Asia/Kabul"]
  },
  {
    value: "West Asia Standard Time",
    abbr: "WAST",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Ashgabat, Tashkent",
    utc: [
      "Antarctica/Mawson",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Ashgabat",
      "Asia/Dushanbe",
      "Asia/Oral",
      "Asia/Samarkand",
      "Asia/Tashkent",
      "Etc/GMT-5",
      "Indian/Kerguelen",
      "Indian/Maldives"
    ]
  },
  {
    value: "Pakistan Standard Time",
    abbr: "PST",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Islamabad, Karachi",
    utc: ["Asia/Karachi"]
  },
  {
    value: "India Standard Time",
    abbr: "IST",
    offset: 5.5,
    isdst: false,
    text: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    utc: ["Asia/Calcutta"]
  },
  {
    value: "Sri Lanka Standard Time",
    abbr: "SLST",
    offset: 5.5,
    isdst: false,
    text: "(UTC+05:30) Sri Jayawardenepura",
    utc: ["Asia/Colombo"]
  },
  {
    value: "Nepal Standard Time",
    abbr: "NST",
    offset: 5.75,
    isdst: false,
    text: "(UTC+05:45) Kathmandu",
    utc: ["Asia/Kathmandu"]
  },
  {
    value: "Central Asia Standard Time",
    abbr: "CAST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Astana",
    utc: [
      "Antarctica/Vostok",
      "Asia/Almaty",
      "Asia/Bishkek",
      "Asia/Qyzylorda",
      "Asia/Urumqi",
      "Etc/GMT-6",
      "Indian/Chagos"
    ]
  },
  {
    value: "Bangladesh Standard Time",
    abbr: "BST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Dhaka",
    utc: ["Asia/Dhaka", "Asia/Thimphu"]
  },
  {
    value: "Ekaterinburg Standard Time",
    abbr: "EST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Ekaterinburg",
    utc: ["Asia/Yekaterinburg"]
  },
  {
    value: "Myanmar Standard Time",
    abbr: "MST",
    offset: 6.5,
    isdst: false,
    text: "(UTC+06:30) Yangon (Rangoon)",
    utc: ["Asia/Rangoon", "Indian/Cocos"]
  },
  {
    value: "SE Asia Standard Time",
    abbr: "SAST",
    offset: 7,
    isdst: false,
    text: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    utc: [
      "Antarctica/Davis",
      "Asia/Bangkok",
      "Asia/Hovd",
      "Asia/Jakarta",
      "Asia/Phnom_Penh",
      "Asia/Pontianak",
      "Asia/Saigon",
      "Asia/Vientiane",
      "Etc/GMT-7",
      "Indian/Christmas"
    ]
  },
  {
    value: "N. Central Asia Standard Time",
    abbr: "NCAST",
    offset: 7,
    isdst: false,
    text: "(UTC+07:00) Novosibirsk",
    utc: ["Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Omsk"]
  },
  {
    value: "China Standard Time",
    abbr: "CST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    utc: ["Asia/Hong_Kong", "Asia/Macau", "Asia/Shanghai"]
  },
  {
    value: "North Asia Standard Time",
    abbr: "NAST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Krasnoyarsk",
    utc: ["Asia/Krasnoyarsk"]
  },
  {
    value: "Singapore Standard Time",
    abbr: "MPST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Kuala Lumpur, Singapore",
    utc: [
      "Asia/Brunei",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Makassar",
      "Asia/Manila",
      "Asia/Singapore",
      "Etc/GMT-8"
    ]
  },
  {
    value: "W. Australia Standard Time",
    abbr: "WAST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Perth",
    utc: ["Antarctica/Casey", "Australia/Perth"]
  },
  {
    value: "Taipei Standard Time",
    abbr: "TST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Taipei",
    utc: ["Asia/Taipei"]
  },
  {
    value: "Ulaanbaatar Standard Time",
    abbr: "UST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Ulaanbaatar",
    utc: ["Asia/Choibalsan", "Asia/Ulaanbaatar"]
  },
  {
    value: "North Asia East Standard Time",
    abbr: "NAEST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Irkutsk",
    utc: ["Asia/Irkutsk"]
  },
  {
    value: "Tokyo Standard Time",
    abbr: "TST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    utc: [
      "Asia/Dili",
      "Asia/Jayapura",
      "Asia/Tokyo",
      "Etc/GMT-9",
      "Pacific/Palau"
    ]
  },
  {
    value: "Korea Standard Time",
    abbr: "KST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Seoul",
    utc: ["Asia/Pyongyang", "Asia/Seoul"]
  },
  {
    value: "Cen. Australia Standard Time",
    abbr: "CAST",
    offset: 9.5,
    isdst: false,
    text: "(UTC+09:30) Adelaide",
    utc: ["Australia/Adelaide", "Australia/Broken_Hill"]
  },
  {
    value: "AUS Central Standard Time",
    abbr: "ACST",
    offset: 9.5,
    isdst: false,
    text: "(UTC+09:30) Darwin",
    utc: ["Australia/Darwin"]
  },
  {
    value: "E. Australia Standard Time",
    abbr: "EAST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Brisbane",
    utc: ["Australia/Brisbane", "Australia/Lindeman"]
  },
  {
    value: "AUS Eastern Standard Time",
    abbr: "AEST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Canberra, Melbourne, Sydney",
    utc: ["Australia/Melbourne", "Australia/Sydney"]
  },
  {
    value: "West Pacific Standard Time",
    abbr: "WPST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Guam, Port Moresby",
    utc: [
      "Antarctica/DumontDUrville",
      "Etc/GMT-10",
      "Pacific/Guam",
      "Pacific/Port_Moresby",
      "Pacific/Saipan",
      "Pacific/Truk"
    ]
  },
  {
    value: "Tasmania Standard Time",
    abbr: "TST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Hobart",
    utc: ["Australia/Currie", "Australia/Hobart"]
  },
  {
    value: "Yakutsk Standard Time",
    abbr: "YST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Yakutsk",
    utc: ["Asia/Chita", "Asia/Khandyga", "Asia/Yakutsk"]
  },
  {
    value: "Central Pacific Standard Time",
    abbr: "CPST",
    offset: 11,
    isdst: false,
    text: "(UTC+11:00) Solomon Is., New Caledonia",
    utc: [
      "Antarctica/Macquarie",
      "Etc/GMT-11",
      "Pacific/Efate",
      "Pacific/Guadalcanal",
      "Pacific/Kosrae",
      "Pacific/Noumea",
      "Pacific/Ponape"
    ]
  },
  {
    value: "Vladivostok Standard Time",
    abbr: "VST",
    offset: 11,
    isdst: false,
    text: "(UTC+11:00) Vladivostok",
    utc: ["Asia/Sakhalin", "Asia/Ust-Nera", "Asia/Vladivostok"]
  },
  {
    value: "New Zealand Standard Time",
    abbr: "NZST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Auckland, Wellington",
    utc: ["Antarctica/McMurdo", "Pacific/Auckland"]
  },
  {
    value: "UTC+12",
    abbr: "U",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Coordinated Universal Time+12",
    utc: [
      "Etc/GMT-12",
      "Pacific/Funafuti",
      "Pacific/Kwajalein",
      "Pacific/Majuro",
      "Pacific/Nauru",
      "Pacific/Tarawa",
      "Pacific/Wake",
      "Pacific/Wallis"
    ]
  },
  {
    value: "Fiji Standard Time",
    abbr: "FST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Fiji",
    utc: ["Pacific/Fiji"]
  },
  {
    value: "Magadan Standard Time",
    abbr: "MST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Magadan",
    utc: ["Asia/Anadyr", "Asia/Kamchatka", "Asia/Magadan", "Asia/Srednekolymsk"]
  },
  {
    value: "Kamchatka Standard Time",
    abbr: "KDT",
    offset: 13,
    isdst: true,
    text: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old"
  },
  {
    value: "Tonga Standard Time",
    abbr: "TST",
    offset: 13,
    isdst: false,
    text: "(UTC+13:00) Nuku'alofa",
    utc: [
      "Etc/GMT-13",
      "Pacific/Enderbury",
      "Pacific/Fakaofo",
      "Pacific/Tongatapu"
    ]
  },
  {
    value: "Samoa Standard Time",
    abbr: "SST",
    offset: 13,
    isdst: false,
    text: "(UTC+13:00) Samoa",
    utc: ["Pacific/Apia"]
  }
];
export const countryListJson = [
  {
    name: "Afghanistan",
    dialCode: "+93",
    code: "AF"
  },
  {
    name: "Albania",
    dialCode: "+355",
    code: "AL"
  },
  {
    name: "Algeria",
    dialCode: "+213",
    code: "DZ"
  },
  {
    name: "AmericanSamoa",
    dialCode: "+1 684",
    code: "AS"
  },
  {
    name: "Andorra",
    dialCode: "+376",
    code: "AD"
  },
  {
    name: "Angola",
    dialCode: "+244",
    code: "AO"
  },
  {
    name: "Anguilla",
    dialCode: "+1 264",
    code: "AI"
  },
  {
    name: "Antigua and Barbuda",
    dialCode: "+1268",
    code: "AG"
  },
  {
    name: "Argentina",
    dialCode: "+54",
    code: "AR"
  },
  {
    name: "Armenia",
    dialCode: "+374",
    code: "AM"
  },
  {
    name: "Aruba",
    dialCode: "+297",
    code: "AW"
  },
  {
    name: "Australia",
    dialCode: "+61",
    code: "AU"
  },
  {
    name: "Austria",
    dialCode: "+43",
    code: "AT"
  },
  {
    name: "Azerbaijan",
    dialCode: "+994",
    code: "AZ"
  },
  {
    name: "Bahamas",
    dialCode: "+1 242",
    code: "BS"
  },
  {
    name: "Bahrain",
    dialCode: "+973",
    code: "BH"
  },
  {
    name: "Bangladesh",
    dialCode: "+880",
    code: "BD"
  },
  {
    name: "Barbados",
    dialCode: "+1 246",
    code: "BB"
  },
  {
    name: "Belarus",
    dialCode: "+375",
    code: "BY"
  },
  {
    name: "Belgium",
    dialCode: "+32",
    code: "BE"
  },
  {
    name: "Belize",
    dialCode: "+501",
    code: "BZ"
  },
  {
    name: "Benin",
    dialCode: "+229",
    code: "BJ"
  },
  {
    name: "Bermuda",
    dialCode: "+1 441",
    code: "BM"
  },
  {
    name: "Bhutan",
    dialCode: "+975",
    code: "BT"
  },
  {
    name: "Bosnia and Herzegovina",
    dialCode: "+387",
    code: "BA"
  },
  {
    name: "Botswana",
    dialCode: "+267",
    code: "BW"
  },
  {
    name: "Brazil",
    dialCode: "+55",
    code: "BR"
  },
  {
    name: "British Indian Ocean Territory",
    dialCode: "+246",
    code: "IO"
  },
  {
    name: "Bulgaria",
    dialCode: "+359",
    code: "BG"
  },
  {
    name: "Burkina Faso",
    dialCode: "+226",
    code: "BF"
  },
  {
    name: "Burundi",
    dialCode: "+257",
    code: "BI"
  },
  {
    name: "Cambodia",
    dialCode: "+855",
    code: "KH"
  },
  {
    name: "Cameroon",
    dialCode: "+237",
    code: "CM"
  },
  {
    name: "Canada/United States",
    dialCode: "+1",
    code: "CA"
  },
  {
    name: "Cape Verde",
    dialCode: "+238",
    code: "CV"
  },
  {
    name: "Cayman Islands",
    dialCode: "+ 345",
    code: "KY"
  },
  {
    name: "Central African Republic",
    dialCode: "+236",
    code: "CF"
  },
  {
    name: "Chad",
    dialCode: "+235",
    code: "TD"
  },
  {
    name: "Chile",
    dialCode: "+56",
    code: "CL"
  },
  {
    name: "China",
    dialCode: "+86",
    code: "CN"
  },
  {
    name: "Christmas Island",
    dialCode: "+61",
    code: "CX"
  },
  {
    name: "Colombia",
    dialCode: "+57",
    code: "CO"
  },
  {
    name: "Comoros",
    dialCode: "+269",
    code: "KM"
  },
  {
    name: "Congo",
    dialCode: "+242",
    code: "CG"
  },
  {
    name: "Cook Islands",
    dialCode: "+682",
    code: "CK"
  },
  {
    name: "Costa Rica",
    dialCode: "+506",
    code: "CR"
  },
  {
    name: "Croatia",
    dialCode: "+385",
    code: "HR"
  },
  {
    name: "Cuba",
    dialCode: "+53",
    code: "CU"
  },
  {
    name: "Cyprus",
    dialCode: "+537",
    code: "CY"
  },
  {
    name: "Czech Republic",
    dialCode: "+420",
    code: "CZ"
  },
  {
    name: "Denmark",
    dialCode: "+45",
    code: "DK"
  },
  {
    name: "Djibouti",
    dialCode: "+253",
    code: "DJ"
  },
  {
    name: "Dominica",
    dialCode: "+1 767",
    code: "DM"
  },
  {
    name: "Dominican Republic",
    dialCode: "+1 849",
    code: "DO"
  },
  {
    name: "Ecuador",
    dialCode: "+593",
    code: "EC"
  },
  {
    name: "Egypt",
    dialCode: "+20",
    code: "EG"
  },
  {
    name: "El Salvador",
    dialCode: "+503",
    code: "SV"
  },
  {
    name: "Equatorial Guinea",
    dialCode: "+240",
    code: "GQ"
  },
  {
    name: "Eritrea",
    dialCode: "+291",
    code: "ER"
  },
  {
    name: "Estonia",
    dialCode: "+372",
    code: "EE"
  },
  {
    name: "Ethiopia",
    dialCode: "+251",
    code: "ET"
  },
  {
    name: "Faroe Islands",
    dialCode: "+298",
    code: "FO"
  },
  {
    name: "Fiji",
    dialCode: "+679",
    code: "FJ"
  },
  {
    name: "Finland",
    dialCode: "+358",
    code: "FI"
  },
  {
    name: "France",
    dialCode: "+33",
    code: "FR"
  },
  {
    name: "French Guiana",
    dialCode: "+594",
    code: "GF"
  },
  {
    name: "French Polynesia",
    dialCode: "+689",
    code: "PF"
  },
  {
    name: "Gabon",
    dialCode: "+241",
    code: "GA"
  },
  {
    name: "Gambia",
    dialCode: "+220",
    code: "GM"
  },
  {
    name: "Georgia",
    dialCode: "+995",
    code: "GE"
  },
  {
    name: "Germany",
    dialCode: "+49",
    code: "DE"
  },
  {
    name: "Ghana",
    dialCode: "+233",
    code: "GH"
  },
  {
    name: "Gibraltar",
    dialCode: "+350",
    code: "GI"
  },
  {
    name: "Greece",
    dialCode: "+30",
    code: "GR"
  },
  {
    name: "Greenland",
    dialCode: "+299",
    code: "GL"
  },
  {
    name: "Grenada",
    dialCode: "+1 473",
    code: "GD"
  },
  {
    name: "Guadeloupe",
    dialCode: "+590",
    code: "GP"
  },
  {
    name: "Guam",
    dialCode: "+1 671",
    code: "GU"
  },
  {
    name: "Guatemala",
    dialCode: "+502",
    code: "GT"
  },
  {
    name: "Guinea",
    dialCode: "+224",
    code: "GN"
  },
  {
    name: "Guinea-Bissau",
    dialCode: "+245",
    code: "GW"
  },
  {
    name: "Guyana",
    dialCode: "+595",
    code: "GY"
  },
  {
    name: "Haiti",
    dialCode: "+509",
    code: "HT"
  },
  {
    name: "Honduras",
    dialCode: "+504",
    code: "HN"
  },
  {
    name: "Hungary",
    dialCode: "+36",
    code: "HU"
  },
  {
    name: "Iceland",
    dialCode: "+354",
    code: "IS"
  },
  {
    name: "India",
    dialCode: "+91",
    code: "IN"
  },
  {
    name: "Indonesia",
    dialCode: "+62",
    code: "ID"
  },
  {
    name: "Iraq",
    dialCode: "+964",
    code: "IQ"
  },
  {
    name: "Ireland",
    dialCode: "+353",
    code: "IE"
  },
  {
    name: "Israel",
    dialCode: "+972",
    code: "IL"
  },
  {
    name: "Italy",
    dialCode: "+39",
    code: "IT"
  },
  {
    name: "Jamaica",
    dialCode: "+1 876",
    code: "JM"
  },
  {
    name: "Japan",
    dialCode: "+81",
    code: "JP"
  },
  {
    name: "Jordan",
    dialCode: "+962",
    code: "JO"
  },
  {
    name: "Kazakhstan",
    dialCode: "+7 7",
    code: "KZ"
  },
  {
    name: "Kenya",
    dialCode: "+254",
    code: "KE"
  },
  {
    name: "Kiribati",
    dialCode: "+686",
    code: "KI"
  },
  {
    name: "Kuwait",
    dialCode: "+965",
    code: "KW"
  },
  {
    name: "Kyrgyzstan",
    dialCode: "+996",
    code: "KG"
  },
  {
    name: "Latvia",
    dialCode: "+371",
    code: "LV"
  },
  {
    name: "Lebanon",
    dialCode: "+961",
    code: "LB"
  },
  {
    name: "Lesotho",
    dialCode: "+266",
    code: "LS"
  },
  {
    name: "Liberia",
    dialCode: "+231",
    code: "LR"
  },
  {
    name: "Liechtenstein",
    dialCode: "+423",
    code: "LI"
  },
  {
    name: "Lithuania",
    dialCode: "+370",
    code: "LT"
  },
  {
    name: "Luxembourg",
    dialCode: "+352",
    code: "LU"
  },
  {
    name: "Madagascar",
    dialCode: "+261",
    code: "MG"
  },
  {
    name: "Malawi",
    dialCode: "+265",
    code: "MW"
  },
  {
    name: "Malaysia",
    dialCode: "+60",
    code: "MY"
  },
  {
    name: "Maldives",
    dialCode: "+960",
    code: "MV"
  },
  {
    name: "Mali",
    dialCode: "+223",
    code: "ML"
  },
  {
    name: "Malta",
    dialCode: "+356",
    code: "MT"
  },
  {
    name: "Marshall Islands",
    dialCode: "+692",
    code: "MH"
  },
  {
    name: "Martinique",
    dialCode: "+596",
    code: "MQ"
  },
  {
    name: "Mauritania",
    dialCode: "+222",
    code: "MR"
  },
  {
    name: "Mauritius",
    dialCode: "+230",
    code: "MU"
  },
  {
    name: "Mayotte",
    dialCode: "+262",
    code: "YT"
  },
  {
    name: "Mexico",
    dialCode: "+52",
    code: "MX"
  },
  {
    name: "Monaco",
    dialCode: "+377",
    code: "MC"
  },
  {
    name: "Mongolia",
    dialCode: "+976",
    code: "MN"
  },
  {
    name: "Montenegro",
    dialCode: "+382",
    code: "ME"
  },
  {
    name: "Montserrat",
    dialCode: "+1664",
    code: "MS"
  },
  {
    name: "Morocco",
    dialCode: "+212",
    code: "MA"
  },
  {
    name: "Myanmar",
    dialCode: "+95",
    code: "MM"
  },
  {
    name: "Namibia",
    dialCode: "+264",
    code: "NA"
  },
  {
    name: "Nauru",
    dialCode: "+674",
    code: "NR"
  },
  {
    name: "Nepal",
    dialCode: "+977",
    code: "NP"
  },
  {
    name: "Netherlands",
    dialCode: "+31",
    code: "NL"
  },
  {
    name: "Netherlands Antilles",
    dialCode: "+599",
    code: "AN"
  },
  {
    name: "New Caledonia",
    dialCode: "+687",
    code: "NC"
  },
  {
    name: "New Zealand",
    dialCode: "+64",
    code: "NZ"
  },
  {
    name: "Nicaragua",
    dialCode: "+505",
    code: "NI"
  },
  {
    name: "Niger",
    dialCode: "+227",
    code: "NE"
  },
  {
    name: "Nigeria",
    dialCode: "+234",
    code: "NG"
  },
  {
    name: "Niue",
    dialCode: "+683",
    code: "NU"
  },
  {
    name: "Norfolk Island",
    dialCode: "+672",
    code: "NF"
  },
  {
    name: "Northern Mariana Islands",
    dialCode: "+1 670",
    code: "MP"
  },
  {
    name: "Norway",
    dialCode: "+47",
    code: "NO"
  },
  {
    name: "Oman",
    dialCode: "+968",
    code: "OM"
  },
  {
    name: "Pakistan",
    dialCode: "+92",
    code: "PK"
  },
  {
    name: "Palau",
    dialCode: "+680",
    code: "PW"
  },
  {
    name: "Panama",
    dialCode: "+507",
    code: "PA"
  },
  {
    name: "Papua New Guinea",
    dialCode: "+675",
    code: "PG"
  },
  {
    name: "Paraguay",
    dialCode: "+595",
    code: "PY"
  },
  {
    name: "Peru",
    dialCode: "+51",
    code: "PE"
  },
  {
    name: "Philippines",
    dialCode: "+63",
    code: "PH"
  },
  {
    name: "Poland",
    dialCode: "+48",
    code: "PL"
  },
  {
    name: "Portugal",
    dialCode: "+351",
    code: "PT"
  },
  {
    name: "Puerto Rico",
    dialCode: "+1 939",
    code: "PR"
  },
  {
    name: "Qatar",
    dialCode: "+974",
    code: "QA"
  },
  {
    name: "Romania",
    dialCode: "+40",
    code: "RO"
  },
  {
    name: "Rwanda",
    dialCode: "+250",
    code: "RW"
  },
  {
    name: "Samoa",
    dialCode: "+685",
    code: "WS"
  },
  {
    name: "San Marino",
    dialCode: "+378",
    code: "SM"
  },
  {
    name: "Saudi Arabia",
    dialCode: "+966",
    code: "SA"
  },
  {
    name: "Senegal",
    dialCode: "+221",
    code: "SN"
  },
  {
    name: "Serbia",
    dialCode: "+381",
    code: "RS"
  },
  {
    name: "Seychelles",
    dialCode: "+248",
    code: "SC"
  },
  {
    name: "Sierra Leone",
    dialCode: "+232",
    code: "SL"
  },
  {
    name: "Singapore",
    dialCode: "+65",
    code: "SG"
  },
  {
    name: "Slovakia",
    dialCode: "+421",
    code: "SK"
  },
  {
    name: "Slovenia",
    dialCode: "+386",
    code: "SI"
  },
  {
    name: "Solomon Islands",
    dialCode: "+677",
    code: "SB"
  },
  {
    name: "South Africa",
    dialCode: "+27",
    code: "ZA"
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dialCode: "+500",
    code: "GS"
  },
  {
    name: "Spain",
    dialCode: "+34",
    code: "ES"
  },
  {
    name: "Sri Lanka",
    dialCode: "+94",
    code: "LK"
  },
  {
    name: "Sudan",
    dialCode: "+249",
    code: "SD"
  },
  {
    name: "Suriname",
    dialCode: "+597",
    code: "SR"
  },
  {
    name: "Swaziland",
    dialCode: "+268",
    code: "SZ"
  },
  {
    name: "Sweden",
    dialCode: "+46",
    code: "SE"
  },
  {
    name: "Switzerland",
    dialCode: "+41",
    code: "CH"
  },
  {
    name: "Tajikistan",
    dialCode: "+992",
    code: "TJ"
  },
  {
    name: "Thailand",
    dialCode: "+66",
    code: "TH"
  },
  {
    name: "Togo",
    dialCode: "+228",
    code: "TG"
  },
  {
    name: "Tokelau",
    dialCode: "+690",
    code: "TK"
  },
  {
    name: "Tonga",
    dialCode: "+676",
    code: "TO"
  },
  {
    name: "Trinidad and Tobago",
    dialCode: "+1 868",
    code: "TT"
  },
  {
    name: "Tunisia",
    dialCode: "+216",
    code: "TN"
  },
  {
    name: "Turkey",
    dialCode: "+90",
    code: "TR"
  },
  {
    name: "Turkmenistan",
    dialCode: "+993",
    code: "TM"
  },
  {
    name: "Turks and Caicos Islands",
    dialCode: "+1 649",
    code: "TC"
  },
  {
    name: "Tuvalu",
    dialCode: "+688",
    code: "TV"
  },
  {
    name: "Uganda",
    dialCode: "+256",
    code: "UG"
  },
  {
    name: "Ukraine",
    dialCode: "+380",
    code: "UA"
  },
  {
    name: "United Arab Emirates",
    dialCode: "+971",
    code: "AE"
  },
  {
    name: "United Kingdom",
    dialCode: "+44",
    code: "GB"
  },
  {
    name: "United States/Canada",
    dialCode: "+1",
    code: "US"
  },
  {
    name: "Uruguay",
    dialCode: "+598",
    code: "UY"
  },
  {
    name: "Uzbekistan",
    dialCode: "+998",
    code: "UZ"
  },
  {
    name: "Vanuatu",
    dialCode: "+678",
    code: "VU"
  },
  {
    name: "Wallis and Futuna",
    dialCode: "+681",
    code: "WF"
  },
  {
    name: "Yemen",
    dialCode: "+967",
    code: "YE"
  },
  {
    name: "Zambia",
    dialCode: "+260",
    code: "ZM"
  },
  {
    name: "Zimbabwe",
    dialCode: "+263",
    code: "ZW"
  },
  {
    name: "land Islands",
    dialCode: "+358",
    code: "AX"
  },
  {
    name: "Antarctica",
    dialCode: "+672",
    code: "AQ"
  },
  {
    name: "Bolivia, Plurinational State of",
    dialCode: "+591",
    code: "BO"
  },
  {
    name: "Brunei Darussalam",
    dialCode: "+673",
    code: "BN"
  },
  {
    name: "Cocos (Keeling) Islands",
    dialCode: "+61",
    code: "CC"
  },
  {
    name: "Congo, The Democratic Republic of the",
    dialCode: "+243",
    code: "CD"
  },
  {
    name: "Cote d'Ivoire",
    dialCode: "+225",
    code: "CI"
  },
  {
    name: "Falkland Islands (Malvinas)",
    dialCode: "+500",
    code: "FK"
  },
  {
    name: "Holy See (Vatican City State)",
    dialCode: "+379",
    code: "VA"
  },
  {
    name: "Hong Kong",
    dialCode: "+852",
    code: "HK"
  },
  {
    name: "Iran, Islamic Republic of",
    dialCode: "+98",
    code: "IR"
  },
  {
    name: "Korea, Democratic People's Republic of",
    dialCode: "+850",
    code: "KP"
  },
  {
    name: "Korea, Republic of",
    dialCode: "+82",
    code: "KR"
  },
  {
    name: "Lao People's Democratic Republic",
    dialCode: "+856",
    code: "LA"
  },
  {
    name: "Libyan Arab Jamahiriya",
    dialCode: "+218",
    code: "LY"
  },
  {
    name: "Macao",
    dialCode: "+853",
    code: "MO"
  },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    dialCode: "+389",
    code: "MK"
  },
  {
    name: "Micronesia, Federated States of",
    dialCode: "+691",
    code: "FM"
  },
  {
    name: "Moldova, Republic of",
    dialCode: "+373",
    code: "MD"
  },
  {
    name: "Mozambique",
    dialCode: "+258",
    code: "MZ"
  },
  {
    name: "Palestinian Territory, Occupied",
    dialCode: "+970",
    code: "PS"
  },
  {
    name: "Pitcairn",
    dialCode: "+872",
    code: "PN"
  },
  {
    name: "Réunion",
    dialCode: "+262",
    code: "RE"
  },
  {
    name: "Russia",
    dialCode: "+7",
    code: "RU"
  },
  {
    name: "Saint Barthélemy",
    dialCode: "+590",
    code: "BL"
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dialCode: "+290",
    code: "SH"
  },
  {
    name: "Saint Kitts and Nevis",
    dialCode: "+1 869",
    code: "KN"
  },
  {
    name: "Saint Lucia",
    dialCode: "+1 758",
    code: "LC"
  },
  {
    name: "Saint Martin",
    dialCode: "+590",
    code: "MF"
  },
  {
    name: "Saint Pierre and Miquelon",
    dialCode: "+508",
    code: "PM"
  },
  {
    name: "Saint Vincent and the Grenadines",
    dialCode: "+1 784",
    code: "VC"
  },
  {
    name: "Sao Tome and Principe",
    dialCode: "+239",
    code: "ST"
  },
  {
    name: "Somalia",
    dialCode: "+252",
    code: "SO"
  },
  {
    name: "Svalbard and Jan Mayen",
    dialCode: "+47",
    code: "SJ"
  },
  {
    name: "Syrian Arab Republic",
    dialCode: "+963",
    code: "SY"
  },
  {
    name: "Taiwan, Province of China",
    dialCode: "+886",
    code: "TW"
  },
  {
    name: "Tanzania, United Republic of",
    dialCode: "+255",
    code: "TZ"
  },
  {
    name: "Timor-Leste",
    dialCode: "+670",
    code: "TL"
  },
  {
    name: "Venezuela, Bolivarian Republic of",
    dialCode: "+58",
    code: "VE"
  },
  {
    name: "Viet Nam",
    dialCode: "+84",
    code: "VN"
  },
  {
    name: "Virgin Islands, British",
    dialCode: "+1 284",
    code: "VG"
  },
  {
    name: "Virgin Islands, U.S.",
    dialCode: "+1 340",
    code: "VI"
  }
];

export const listOfDataCompliances = [
  "Australian Government IRAP",
  "Canadian Privacy Laws",
  "CDSA",
  "CCSL (IRAP)",
  "Cloud Security Alliance CCM",
  "COPPA",
  // "CSA STAR Certification",
  "EU Model Clauses",
  "EU - US Privacy Shield",
  "FIPS 140-2",
  "FISMA",
  "GDPR (Compliant-ready)",
  "HIPAA/HITECH",
  "ISO 22301",
  //"ISO/IEC 27001/27002",
  //"ISO/IEC 27017",
  "ISO/IEC 27018",
  //  "OpenID Certification",
  // "PCI DSS",
  "SOC 1/SSAE 16/ISAE 3402 and SOC 2",
  "Spain ENS",
  "United Kingdom G-Cloud",
  "WCAG 2.0"
];
export const listOfSecurityCompliances = [
  "ISO/IEC 27001:2013",
  "ISO/IEC 27017:2015",
  "SOC 2 (Type II)",
  "ISAE 3000",
  "NIST Cybersecurity Framework",
  "CSA Star CCM",
  "CIS Critical Security Controls",
  // "PCI DSS Compliant",
  "OpenID"
];

export interface SmtpProviderSchema {
  name: string;
  smtpHost: string;
  smtpPort: string;
  enableSSL: boolean;
  source: string;
}

export interface SmtpOptionSchema {
  optionID: string;
  optionName: string;
  optionType: string;
  optionValue: string;
  errorMessage?: string;
  RequireValue: string;
  tooltip?: string;
}

export interface SmtpOptionsSchema {
  [option: string]: SmtpOptionSchema;
}

export const SMTP_OPTION_NAMES: { [name: string]: string } = {
  FROM_NAME: "FromName",
  FROM_EMAIL: "FromEmailId",
  USERNAME: "UserName",
  PASSWORD: "Password",
  HOST: "SmtpHost",
  PORT: "SmtpPort",
  SSL: "IsSsl",
  PROVIDERS: "SMTPProviders",
  KEY: "Key",
  SECRET: "Secret"
};

export const SMTP_PROVIDERS: SmtpProviderSchema[] = [
    {
      name: "Amazon SES (US East)",
      smtpHost: "email-smtp.us-east-1.amazonaws.com",
      smtpPort: "587",
      enableSSL: true,
      source: amazonSesUsEastLogo
    },
    {
      name: "Amazon SES (US West)",
      smtpHost: "email-smtp.us-west-2.amazonaws.com",
      smtpPort: "587",
      enableSSL: true,
      source: amazonSesUsWestLogo
    },
    {
      name: "Amazon SES (EU)",
      smtpHost: "email-smtp.eu-west-1.amazonaws.com",
      smtpPort: "587",
      enableSSL: true,
      source: amazonSesEuLogo
    },
    {
      name: "Gmail",
      smtpHost: "smtp.gmail.com",
      smtpPort: "587",
      enableSSL: true,
      source: gmailLogo
    },
    {
      name: "Mandrill",
      smtpHost: "smtp.mandrillapp.com",
      smtpPort: "587",
      enableSSL: true,
      source: mandrillLogo
    },
    {
      name: "Rackspace-mailgun",
      smtpHost: "smtp.mailgun.org",
      smtpPort: "587",
      enableSSL: true,
      source: mailgunLogo
    },
    {
      name: "SendGrid",
      smtpHost: "smtp.sendgrid.net",
      smtpPort: "587",
      enableSSL: true,
      source: sendgridLogo
    },
    {
      name: "Yahoo",
      smtpHost: "smtp.mail.yahoo.com",
      smtpPort: "587",
      enableSSL: true,
      source: yahooLogo
    },
    {
      name: "Other",
      smtpHost: "",
      smtpPort: "",
      enableSSL: false,
      source: other
    }
  ],
  SMTP_OPTIONS: SmtpOptionsSchema = {
    [SMTP_OPTION_NAMES.PROVIDERS]: {
      optionID: "SMTPProviders",
      optionName: "SMTP Providers",
      optionType: "option",
      optionValue: "",
      RequireValue: "both"
    },
    [SMTP_OPTION_NAMES.KEY]: {
      optionID: "Key",
      optionName: "Key",
      optionType: "text",
      optionValue: "",
      RequireValue: "key",
      tooltip: "Configure mailazy to get Key"
    },
    [SMTP_OPTION_NAMES.SECRET]: {
      optionID: "Secret",
      optionName: "Secret",
      optionType: "text",
      optionValue: "",
      RequireValue: "key",
      tooltip: "Configure mailazy to get Secret"
    },
    [SMTP_OPTION_NAMES.HOST]: {
      optionID: "SMTPHost",
      optionName: "SMTP Host",
      optionType: "text",
      optionValue: "",
      RequireValue: "withoutkey"
    },
    [SMTP_OPTION_NAMES.PORT]: {
      optionID: "SMTPPort",
      optionName: "SMTP Port",
      optionType: "text",
      optionValue: "0",
      RequireValue: "withoutkey"
    },
    [SMTP_OPTION_NAMES.FROM_NAME]: {
      optionID: "FromName",
      optionName: "From Name",
      optionType: "text",
      optionValue: "",
      RequireValue: "both"
    },
    [SMTP_OPTION_NAMES.FROM_EMAIL]: {
      optionID: "FromEmail",
      optionName: "From Email Address",
      optionType: "text",
      optionValue: "",
      RequireValue: "both"
    },
    [SMTP_OPTION_NAMES.USERNAME]: {
      optionID: "SMTPUsername",
      optionName: "SMTP User Name",
      optionType: "text",
      optionValue: "",
      RequireValue: "withoutkey"
    },
    [SMTP_OPTION_NAMES.PASSWORD]: {
      optionID: "SMTPPassword",
      optionName: "SMTP Password",
      optionType: "password",
      optionValue: "",
      RequireValue: "withoutkey"
    },
    [SMTP_OPTION_NAMES.SSL]: {
      optionID: "EnableSSL",
      optionName: "Enable SSL",
      optionType: "checkbox",
      optionValue: "false",
      RequireValue: "withoutkey"
    }
  };

export const INT32_MAX = 2147483647;
export const MAX_PERMISSION_LENGTH = 500;

// Sonar Qube refactoring
export const MONTH_DATE_YEAR_FORMAT = "MM/DD/YYYY";
export const SCRIPT_TYPE_JAVASCRIPT =
  "text/javascript_application/x-javascript";

export const ExportData = {
  PROFILE: "profile",
  CUSTOM_OBJECT: "customobject",
  PROFILE_AND_CUSTOM_OBJECT: "profile-customobject"
};

export const FrequencyPayloadValues = {
  Daily: "Day",
  Weekly: "Week",
  Monthly: "Month"
};

export const FrequencyOptions = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly"
};

export const Days = {
  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday",
  SUNDAY: "Sunday"
};

export const DEFAULT_FREQUENCY_FOR_ON_DEMAND_EXPORT =
  FrequencyPayloadValues.Weekly;
export const DEFAULT_DAY_FOR_WEEKLY_EXPORT = Days.MONDAY;

export const INTEGRATION_EVENTS = [
  "login",
  "register",
  "updateProfile",
  "resetPassword",
  "changePassword",
  "emailVerification",
  "addEmail",
  "removeEmail",
  "blockAccount",
  "deleteAccount",
  "setUsername",
  "createTraditionalAccount",
  "assignRoles",
  "unassignRoles",
  "SetPassword",
  "linkAccount",
  "unlinkAccount",
  "updatePhoneId",
  "verifyPhoneNumber",
  "updateCustomobject",
  "deleteCustomObject",
  "createCustomObject",
  "invalidateEmailVerification"
].sort((s, f) => (s.toLowerCase() < f.toLowerCase() ? -1 : 1));

export const bodyCenter: React.CSSProperties = {
  position: "fixed",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)"
};
export const sectionCenter: React.CSSProperties = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)"
};

export const CDNFILE_UPLOAD_DOMAIN = {
  fileStack: "https://cdn.filestackcontent.com/"
};

export enum PlaceholderLength {
  FULL = "full",
  VERY_LONG = "very long",
  LONG = "long",
  MEDIUM = "medium",
  SHORT = "short",
  VERY_SHORT = "very short"
}

export const defaultSearchJson = [
  {
    link: "/dashboard",
    tags: [
      "dashboard",
      "login activity",
      "customer growth",
      "new customers",
      "implmentation guides",
      "product updates"
    ]
  },
  {
    link:
      "/platform-configuration/identity-workflow/authentication-workflow/account-workflow",
    tags: ["account workflow"]
  },
  {
    link:
      "/platform-configuration/identity-workflow/authentication-workflow/advanced-workflow",
    tags: ["advanced workflow"]
  },
  {
    link:
      "/platform-configuration/identity-workflow/verification-workflow/email-workflow",
    tags: ["email workflow"]
  },
  {
    text: "Email Configuration",
    link:
      "/platform-configuration/identity-workflow/communication-configuration/email-configuration",
    tags: [
      "email configuration",
      "smtp Platforms",
      "smtp",
      "Configure your smtp",
      "mailazy",
      "mandrill",
      "gmail",
      "mailgun",
      "sendgrid",
      "yahoo",
      "amazon ses"
    ]
  },
  {
    text: "Global Email Settings",
    link:
      "/platform-configuration/identity-workflow/communication-configuration/global-email-settings",
    tags: ["global email settings", "email settings"]
  },
  {
    text: "Email Configuration",
    link:
      "/platform-configuration/identity-workflow/communication-configuration/text-and-sms-and-voice-otp-configuration",
    tags: [
      "sms configuration",
      "voice OTP configuration",
      "sms and voice OTP configuration",
      "sms provider settings",
      "sms messages"
    ]
  },
  {
    link:
      "/platform-configuration/identity-workflow/communication-configuration/global-text-and-sms-and-voice-otp-settings",
    tags: [
      "global text/sms/voice OTP settings",
      "global text and sms and voice OTP settings",
      "global sms settings",
      "global voice OTP settings",
      "global text settings"
    ]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/standard-login/data-schema",
    tags: [
      "data schema",
      "fields",
      "active fields",
      "disabled fields",
      "standard fields",
      "custom fields",
      "advanced fields"
    ]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/standard-login/verification-email",
    tags: ["verification email", "email verification setting", "email template"]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/standard-login/forgot-password-email",
    tags: [
      "forgot password email",
      "forgot password email settings",
      "email template"
    ]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/standard-login/welcome-email",
    tags: ["welcome email", "Welcome Email Settings", "email template"]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/standard-login/delete-account-email",
    tags: [
      "delete account email",
      "Delete Account Email Settings",
      "email template"
    ]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/standard-login/add-email",
    tags: ["add email", "add email settings", "email template"]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/standard-login/password-reset-email",
    tags: [
      "password reset email",
      "Reset Password Confirmation Email Settings",
      "email template"
    ]
  },

  {
    link:
      "/platform-configuration/authentication-configuration/phone-login/otp-settings",
    tags: ["otp settings"]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/phone-login/phone-number-verification",
    tags: [
      "Phone Number Verification",
      "Phone Number Verification Settings",
      "sms template"
    ]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/phone-login/phone-number-change",
    tags: [
      "Phone Number Change Settings",
      "Phone Number Change",
      "sms template"
    ]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/phone-login/password-reset",
    tags: ["Password Reset", "Password Reset Settings", "sms template"]
  },
  {
    link:
      "  /platform-configuration/authentication-configuration/phone-login/welcome-sms",
    tags: ["Welcome SMS", "Welcome SMS Settings", "sms template"]
  },

  {
    link:
      "/platform-configuration/authentication-configuration/passwordless-login/passwordless-login-email",
    tags: [
      "passwordless login email",
      "one click sign",
      "Passwordless Login With Email Settings",
      "Instant Login"
    ]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/passwordless-login/passwordless-login-otp",
    tags: ["passwordless login otp", "one click otp"]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/social-login/social-providers",
    tags: [
      "social providers",
      "configure social id providers",
      "Configure the selected ID providers",
      "facebook",
      "google",
      "yahoo",
      "apple",
      "mail.ru",
      "wordpress",
      "live",
      "pinterest",
      "QQ",
      "twitter",
      "linkedin",
      "paypal",
      "amazon",
      "disqus",
      "line",
      "foursquare",
      "Vkontakte",
      "Sina Weibo",
      "Salesforce",
      "Odnoklassniki",
      "github"
    ]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/social-login/social-data-settings",
    tags: [
      "social data settings",
      "data points",
      "basic data",
      "extended data "
    ]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/advance-login-methods/smart-login-settings",
    tags: ["SMART LOGIN SETTINGS"]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/advance-login-methods/one-touch-login-email",
    tags: ["One Touch Login Email Settings", "One Touch Login Email"]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/advance-login-methods/one-touch-login-sms",
    tags: ["ONE TOUCH LOGIN SMS", "One Touch Login SMS Settings"]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/advance-login-methods/smart-login",
    tags: ["Smart Login Email Settings", "Smart Login"]
  },

  {
    link:
      "/platform-configuration/authentication-configuration/advance-login-methods/one-touch-login-settings",
    tags: ["One Touch Login Setting"]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/custom-idps/integrations",
    tags: ["Custom IDP Provider Integrations"]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/custom-idps/oauth-provider",
    tags: [
      "OAuth Provider",
      "Manage your Custom oAuth Configurations",
      "Add a New Provider"
    ]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/custom-idps/adfs-provider",
    tags: ["adfs provider"]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/custom-idps/jwt-provider",
    tags: ["jwt provider"]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/custom-idps/saml-provider",
    tags: ["saml provider"]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/machine-to-machine-authorization/authorization-server-apis",
    tags: [
      "MANAGE AUTHORIZATION SERVER APIS",
      "m2m",
      "Authorization Server APIs"
    ]
  },
  {
    link:
      "/platform-configuration/authentication-configuration/machine-to-machine-authorization/machine-to-machine-app",
    tags: [
      "MANAGE MACHINE TO MACHINE APP",
      "Machine to Machine App List",
      "M2M apps"
    ]
  },
  {
    link:
      "/platform-configuration/access-configuration/web-sso/sso-domain-grouping",
    tags: ["SSO DOMAIN GROUPING", "website url", "web sso", "sso domains"]
  },
  {
    link:
      "/platform-configuration/access-configuration/federated-sso/integrations",
    tags: ["SSO Integration", "Available Integrations"]
  },
  {
    link: "/platform-configuration/access-configuration/federated-sso/saml",
    tags: ["saml", "Manage your SAML Configurations"]
  },
  {
    link: "/platform-configuration/access-configuration/federated-sso/jwt",
    tags: ["jwt", "JSON Web Token"]
  },
  {
    link: "/platform-configuration/access-configuration/federated-sso/oauth2",
    tags: ["OAUTH2"]
  },
  {
    link:
      "/platform-configuration/access-configuration/federated-sso/trouble-shoot",
    tags: ["saml trouble shoot", "Trouble Shoot"]
  },
  {
    link:
      "/platform-configuration/access-configuration/federated-sso/openid-connect",
    tags: ["oidc", "OPENID CONNECT"]
  },
  {
    link: "/platform-configuration/access-configuration/sso-connector/shopify",
    tags: ["shopify", "Configured Stores", "SSO integrations"]
  },
  {
    link:
      "/platform-configuration/access-configuration/sso-connector/big-commerce",
    tags: ["big commerce", "SSO integrations", "Configured Stores"]
  },
  {
    link:
      "/platform-configuration/access-configuration/sso-connector/perfectmind",
    tags: ["perfectmind", "SSO integrations", "Configured Stores"]
  },
  {
    link:
      "/platform-configuration/access-configuration/delegation/delegation-auth",
    tags: ["delegation auth", "Delegation API", "store"]
  },
  {
    link:
      "/platform-configuration/access-configuration/delegation/password-delegation",
    tags: ["password delegation", "Configured Apps"]
  },
  {
    link:
      "/platform-configuration/access-configuration/roles-and-membership/manage-roles-permissions",
    tags: ["manage role permissions", "add new role"]
  },
  {
    link:
      "/platform-configuration/access-configuration/roles-and-membership/default-role-permission",
    tags: ["default role permission"]
  },
  {
    link:
      "/platform-security/account-protection/auth-security/captcha-settings",
    tags: ["captcha settings", "reCAPTCHA", "google reCAPTCHA", "QQ tenent"]
  },
  {
    link:
      "/platform-security/account-protection/auth-security/access-restriction",
    tags: ["access restriction", "Restriction Type", "Blacklist", "whitelist"]
  },
  {
    link:
      "/platform-security/account-protection/auth-security/brute-force-lockout",
    tags: ["brute force lockout"]
  },
  {
    link:
      "/platform-security/account-protection/password-policy/password-expiration",
    tags: ["password expiration", "reset periodically"]
  },
  {
    link:
      "/platform-security/account-protection/password-policy/password-history",
    tags: ["password history", "Max Password History"]
  },
  {
    link:
      "/platform-security/account-protection/password-policy/password-complexity",
    tags: [
      "password complexity",
      "Password Validations",
      "Common password protection",
      "Dictionary Password Prevention",
      "Profile Fields Password Prevention"
    ]
  },
  {
    link:
      "/platform-security/account-protection/session-management/token-lifetime",
    tags: ["access token expiration", "token lifetime"]
  },
  {
    link:
      "/platform-security/account-protection/session-management/force-logout",
    tags: ["force logout"]
  },
  {
    link:
      "/platform-security/account-protection/session-management/remember-me",
    tags: ["remember me"]
  },
  {
    link:
      "/platform-security/multi-layered-security/security-question/settings",
    tags: ["security question", "Add new security Question"]
  },
  {
    link:
      "/platform-security/multi-layered-security/multi-factor-authentication/settings",
    tags: [
      "Multi Factor Authentication",
      "2fa",
      "Two factor authentication",
      "mfa"
    ]
  },
  {
    link:
      "/platform-security/multi-layered-security/multi-factor-authentication/email-passcode",
    tags: ["email passcode"]
  },
  {
    link:
      "/platform-security/multi-layered-security/multi-factor-authentication/sms-passcode",
    tags: ["sms passcode"]
  },
  {
    link:
      "/platform-security/multi-layered-security/multi-factor-authentication/authenticator-apps",
    tags: ["authenticator apps"]
  },
  {
    link:
      "/platform-security/multi-layered-security/multi-factor-authentication/security-questions",
    tags: ["security questions"]
  },
  {
    link:
      "/platform-security/multi-layered-security/risk-based-authentication/rba-settings",
    tags: ["RBA SETTINGS"]
  },
  {
    link:
      "/platform-security/multi-layered-security/risk-based-authentication/multi-factor-settings",
    tags: ["multi factor settings"]
  },
  {
    link:
      "/platform-security/multi-layered-security/risk-based-authentication/admin-email",
    tags: ["admin email"]
  },
  {
    link:
      "/platform-security/multi-layered-security/risk-based-authentication/email-templates",
    tags: [
      "email templates",
      "city",
      "country",
      "rba",
      "browser",
      "ip",
      "Suspicious City Email Settings",
      "Suspicious country Email Settings",
      "Suspicious browser Email Settings",
      "Suspicious ip Email Settings",
      "Risk Identified Email To Admin Settings"
    ]
  },
  {
    link:
      "/platform-security/multi-layered-security/risk-based-authentication/sms-templates",
    tags: [
      "sms templates",
      "city",
      "country",
      "rba",
      "browser",
      "ip",
      "Suspicious City sms Settings",
      "Suspicious country sms Settings",
      "Suspicious browser sms Settings",
      "Suspicious ip sms Settings"
    ]
  },
  {
    link: "/platform-security/multi-layered-security/pin-authentication",
    tags: ["pin authentication"]
  },
  {
    link:
      "/platform-security/multi-layered-security/pin-authentication/email-template",
    tags: ["email template", "pin authentication"]
  },
  {
    link:
      "/platform-security/data-access-protection/api-credentials/account-api-keys",
    tags: ["account api keys", "API Key", "API Secret", "Reset Secret"]
  },
  {
    link:
      "/platform-security/data-access-protection/api-credentials/additional-api-secrets",
    tags: ["additional api secret", "add new api secret"]
  },
  {
    link:
      "/platform-security/data-access-protection/api-security/bot-protection",
    tags: ["bot protection", "captcha"]
  },
  {
    link:
      "/platform-security/data-access-protection/api-security/ip-access-restriction",
    tags: ["ip access restriction", "Allowed IPs"]
  },
  {
    link: "/data-governance/data-storage/data-center",
    tags: ["data center"]
  },
  {
    link: "/data-governance/data-storage/data-fields/standard-data-fields",
    tags: ["STANDARD DATA FIELDS", "data fields"]
  },
  {
    link: "/data-governance/data-storage/data-fields/custom-data-fields",
    tags: ["custom data fields", "custom fields"]
  },
  {
    link: "/data-governance/data-storage/data-fields/custom-objects",
    tags: ["custom objects"]
  },
  {
    link: "/data-governance/data-security/hashing-algorithm",
    tags: ["hashing algorithm"]
  },
  {
    link: "/data-governance/data-security/encryption-in-transit",
    tags: ["ENCRYPTION IN TRANSIT"]
  },
  {
    link: "/data-governance/data-security/encryption-at-rest",
    tags: ["encryption at rest"]
  },
  {
    link: "/data-governance/trust-center/security-center/data-compliances",
    tags: ["security center", "data compliances"]
  },
  {
    link: "/data-governance/trust-center/security-center/security-compliances",
    tags: ["security compliances"]
  },
  {
    link: "/data-governance/trust-center/privacy-center/privacy-versioning",
    tags: [
      "privacy versioning",
      "Privacy Policy Version Enable Settings",
      "Privacy Policy"
    ]
  },
  {
    link: "/data-governance/trust-center/privacy-center/gdpr-compliance",
    tags: ["gdpr compliance"]
  },
  {
    link: "/data-governance/trust-center/privacy-center/age-verification",
    tags: ["age verification"]
  },
  {
    link: "/data-governance/trust-center/consent-center/consent-management",
    tags: ["consent management", "Published Form"]
  },
  {
    link: "/deployment/apps/web-apps",
    tags: [
      "web apps",
      "Production Website URL",
      "Dev/Staging/Sandbox Website URL",
      "add new site"
    ]
  },
  {
    link: "/deployment/apps/mobile-apps",
    tags: ["sott", "mobile apps", "Secure One Time Token"]
  },
  {
    link: "/deployment/apps/custom-domain",
    tags: ["custom domain"]
  },
  {
    link: "/deployment/profiling/progressive-profiling",
    tags: ["progressive profiling"]
  },
  {
    link: "/deployment/idx",
    tags: [
      "idx",
      "IDENTITY EXPERIENCE FRAMEWORK",
      "Theme Editor",
      "Auth pages",
      "Profile pages",
      "Authentication pages",
      "before script"
    ]
  },
  {
    link: "/deployment/js-widgets/settings",
    tags: [
      "Ask Required Fields On TraditionalLogin",
      "Is Logout On Email Verification",
      "Is UserName Login",
      "Google Recaptcha",
      "Ask Password On Social Login",
      "Ask EmailId For Unverified User Login",
      "Ask Optional Fields On Social Signup",
      "Enable automatic login after email verification"
    ]
  },
  {
    link: "/deployment/js-widgets/registration-forms",
    tags: ["REGISTRATION FORMS", "generate schema"]
  },
  {
    link: "/deployment/libs/sdk-library",
    tags: ["sdk library"]
  },
  {
    link: "/deployment/js-widgets/login"
  },
  {
    link: "/deployment/js-widgets/registration"
  },
  {
    link: "/deployment/js-widgets/forgotpassword"
  },
  {
    link: "/deployment/js-widgets/resetpassword"
  },
  {
    link: "/deployment/js-widgets/verifyemail"
  },
  {
    link: "/deployment/js-widgets/changepassword"
  },
  {
    link: "/deployment/js-widgets/linkaccount"
  },
  {
    link: "/deployment/js-widgets/sociallogin"
  },
  {
    link: "/deployment/js-widgets/unlinkaccount"
  },
  {
    link: "/deployment/js-widgets/profileeditor"
  },
  {
    link: "/deployment/js-widgets/twofactorauthentication"
  },
  {
    link: "/deployment/js-widgets/addemail"
  },
  {
    link: "/deployment/js-widgets/removeemail"
  },
  {
    link: "/deployment/js-widgets/resendemailverification"
  },
  {
    link: "/deployment/js-widgets/updatephonenumber"
  },
  {
    link: "/deployment/js-widgets/passwordlessloginvalidate",
    tags: ["auto login"]
  },
  {
    link: "/deployment/js-widgets/changeusername"
  },
  {
    link: "/deployment/libs/mobile-library",
    tags: ["mobile library"]
  },
  {
    link: "/deployment/libs/turnkey-plugins",
    tags: ["turnkey plugins", "cms", "drupal", "magento", "wordpress"]
  },
  {
    link: "/deployment/configuration-deploy/configuration-deployment",
    tags: ["CONFIGURATION DEPLOYMENT"]
  },
  // {
  //   link: "/deployment/migration/data-migration",
  //   tags: ["data migration"]
  // },
  // {
  //   link: "/deployment/migration/data-migration-logs",
  //   tags: ["migration logs"]
  // },
  {
    link: "/integration/third-party-software/configuration",
    tags: ["Integrations"]
  },
  {
    link: "/integration/third-party-software/available-integrations",
    tags: ["integrations"]
  },
  {
    link: "/integration/data-sync/web-hooks",
    tags: ["web hooks"]
  },
  {
    link: "/integration/data-export/on-demand-export",
    tags: ["on demand export"]
  },
  {
    link: "/integration/data-export/scheduled-export",
    tags: ["scheduled export"]
  },
  {
    link: "/integration/audit-log/audit-log-integration",
    tags: ["Audit log", "Integration"]
  },
  {
    link: "/integration/data-export/export-logs",
    tags: ["export logs"]
  },
  {
    link: "/integration/data-etl-service/data-etl-migration",
    tags: ["data etl migration"]
  },
  {
    link: "/integration/data-etl-service/data-etl-logs",
    tags: ["etl logs"]
  },
  {
    link: "/profile-management/customer-management/search-customers",
    tags: [
      "SEARCH CUSTOMERS",
      "Reset password",
      "update verification status",
      "resend verification mail",
      "add secondary email",
      "logout customer",
      "block customer",
      "unblock customer",
      "delete customer"
    ]
  },
  {
    link: "/profile-management/customer-management/blocked-customers",
    tags: [
      "Reset password",
      "update verification status",
      "resend verification mail",
      "add secondary email",
      "logout customer",
      "block customer",
      "unblock customer",
      "delete customer"
    ]
  },
  {
    link: "/profile-management/customer-management/add-new-customers",
    tags: ["add a new customer"]
  },
  {
    link: "/profile-management/customer-segmentation/basic-segmentation",
    tags: ["basic segmentation", "export", "save segment"]
  },
  {
    link: "/profile-management/customer-segmentation/advanced-segmentation",
    tags: ["advanced segmentation", "save segment"]
  },
  {
    link: "/profile-management/customer-segmentation/saved-segmentation",
    tags: ["apply segment"]
  },
  {
    link: "/profile-management/data-query/customer-query",
    tags: ["customer query"]
  },
  {
    link: "/profile-management/data-query/custom-object-query",
    tags: ["custom object query"]
  },
  {
    link: "/insights/overview",
    tags: ["insights", "Login Activity", "Customer Profiles Per Day"]
  },
  {
    link: "/insights/identity-analytics/customer-stats",
    tags: ["customer stats"]
  },
  {
    link: "/insights/identity-analytics/customer-growth",
    tags: ["customer growth"]
  },
  {
    link: "/insights/identity-analytics/region",
    tags: ["region"]
  },
  {
    link: "/insights/identity-analytics/age-group",
    tags: ["age group"]
  },
  {
    link: "/insights/identity-analytics/gender",
    tags: ["gender"]
  },
  {
    link: "/insights/identity-analytics/browser",
    tags: ["browser"]
  },
  {
    link: "/insights/identity-analytics/device",
    tags: ["device"]
  },
  {
    link: "/insights/provider-analytics/daily-distribution",
    tags: ["Daily Distribution"]
  },
  {
    link: "/insights/provider-analytics/region",
    tags: ["region"]
  },
  {
    link: "/insights/provider-analytics/age-group",
    tags: ["age group"]
  },
  {
    link: "/insights/provider-analytics/gender",
    tags: ["gender"]
  },
  {
    link: "/insights/login-analytics/login-stats",
    tags: ["Login Stats"]
  },
  {
    link: "/insights/login-analytics/login-distribution",
    tags: ["login distribution"]
  },
  {
    link: "/insights/login-analytics/daily-active-customers",
    tags: ["Daily Active Customers"]
  },
  {
    link: "/insights/login-analytics/monthly-active-customers",
    tags: ["Monthly Active Customers"]
  },
  {
    link: "/insights/login-analytics/customer-return-rate",
    tags: ["Customer Return Rate"]
  },
  {
    link: "/insights/login-analytics/provider-engagement",
    tags: ["Provider Engagement"]
  },
  {
    link: "/insights/login-analytics/browser-engagement",
    tags: ["Browser Engagement"]
  },
  {
    link: "/insights/login-analytics/device-engagement",
    tags: ["Device Engagement"]
  },
  {
    link: "/insights/comparative-analytics/month-comparison",
    tags: ["Month Comparison"]
  },
  {
    link: "/insights/comparative-analytics/registration-streaks",
    tags: ["registration streaks"]
  },
  {
    link: "/insights/api-analytics/request-count",
    tags: ["request count"]
  },
  {
    link: "/insights/api-analytics/response-code",
    tags: ["response code"]
  },
  {
    link: "/insights/api-analytics/performance/lookup-profile",
    tags: ["lookup profile"]
  },
  {
    link: "/insights/api-analytics/performance/auth-profile",
    tags: ["auth profile"]
  },
  {
    link: "/insights/api-analytics/performance/create-profile",
    tags: ["create profile"]
  },
  {
    link: "/insights/api-analytics/performance/delete-profile",
    tags: ["delete profile"]
  },
  {
    link: "/insights/api-analytics/performance/modify-profile",
    tags: ["modify profile"]
  },
  {
    link: "/insights/communication-analytics/sms-analytics",
    tags: ["sms analytics"]
  },
  {
    text: "API Docs",
    link:
      "https://www.loginradius.com/docs/api/v2/getting-started/introduction/",
    tags: ["api docs", "api documentation"],
    href: true
  },
  {
    text: "Support Docs",
    link: "https://www.loginradius.com/docs/",
    tags: ["support docs"],
    href: true
  },
  {
    link: "/support/tickets/open-a-new-ticket",
    tags: ["support ticket", "new ticket"]
  },
  {
    link: "/support/tickets/existing-tickets",
    tags: ["existing ticket"]
  },
  {
    link: "/account/account-settings/profile",
    tags: [
      "profile",
      "my account",
      "reset my password",
      "edit profile",
      "Connected Social Accounts",
      "Connect Additional Accounts",
      "reset password"
    ]
  },
  {
    link: "/account/account-settings/login-session",
    tags: ["login session", "web session", "signout web session"]
  },
  {
    link: "/account/account-settings/security",
    tags: [
      "Account Access",
      "impersonation",
      "security",
      "Authenticator Apps",
      "Verify identity",
      "Multi Factor Authentication"
    ]
  },
  {
    link: "/account/appearance/theme-customization",
    tags: ["theme customization", "dashboard customization"]
  },
  {
    link: "/account/team/manage-team-member",
    tags: [
      "manage team member",
      "logout this user",
      "Edit",
      "Remove User",
      "Resend Password"
    ]
  },

  {
    link: "/account/team/audit-logs",
    tags: ["audit logs", "activities"]
  },
  {
    link: "/account/team/add-team-member",
    tags: ["add team member"]
  },
  {
    link: "/account/team/single-sign-on",
    tags: [
      "single sign on",
      "sso",
      "azure ad",
      "adfs",
      "salesforce",
      "saml",
      "Email and Password"
    ]
  },
  {
    link: "/account/team/ownership-settings",
    tags: ["ownership settings", "Additonal Permission"]
  },
  {
    link: "/account/billing/subscription-details",
    tags: ["billing", "subscription details"]
  },
  {
    link: "/account/billing/invoices",
    tags: ["invoices"]
  },
  {
    link: "/site-list",
    tags: [
      "site list",
      "List of LoginRadius Sites",
      "shared sites",
      "Get all sites",
      "your sites"
    ]
  }
];

export const docLinksJson = [
  {
    "Feature-name": "Account-Workflow",
    "Doc-Link": "https://www.loginradius.com/docs/authentication/overview/"
  },
  {
    "Feature-name": "Registration-data",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/customer-identity-api/custom-registration-data/custom-registration-data-overview/#htmldropdownmenuforcustomfields1"
  },
  {
    "Feature-name": "Email-Workflow",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/email-verification-workflow/"
  },
  {
    "Feature-name": "Email-Configuration",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/email-communications/"
  },
  {
    "Feature-name": "Global-Email-Settings",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-configuration/communication-configuration/email/global-email-settings/"
  },
  {
    "Feature-name": "TEXT-And-SMS-And-Voice-OTP-Configuration",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/sms-configuration/"
  },
  {
    "Feature-name": "TEXT-And-SMS-And-Voice-OTP-Settings",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-configuration/communication-configuration/sms/global-sms-settings/"
  },
  {
    "Feature-name": "Data-Schema",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-configuration/standard-login/data-schema/"
  },
  {
    "Feature-name": "Email-Templates",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/email-communications/#partemailtemplates5"
  },
  {
    "Feature-name": "Phone-Login",
    "Doc-Link": ""
  },
  {
    "Feature-name": "OTP-Settings",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/tutorial/phone-login/"
  },
  {
    "Feature-name": "Phone-Login",
    "Doc-Link": ""
  },
  {
    "Feature-name": "SMS-Templates",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/tutorial/phone-login/#partconfiguration2"
  },
  {
    "Feature-name": "Smart-Login-Settings",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/tutorial/smart-login/"
  },
  {
    "Feature-name": "One-Touch-Login-Settings",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/tutorial/one-touch-login/"
  },
  {
    "Feature-name": "One-Touch-Login-Email",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/tutorial/one-touch-login/#onetouchloginbyemail2"
  },
  {
    "Feature-name": "One-Touch-Login-SMS",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/tutorial/one-touch-login/#onetouchloginbysms3"
  },
  {
    "Feature-name": "smart-login",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/tutorial/smart-login/#partconfiguration2"
  },

  {
    "Feature-name": "Social-Providers",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/quick-start/social-login/"
  },
  {
    "Feature-name": "Social-Data-Settings",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/quick-start/social-login/#configuresocialdatapoints3",
    FIELD3: "This-is-subsection-of-main-doc"
  },
  {
    "Feature-name": "Passwordless-Login-Email",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/tutorial/passwordless-login#passwordlessloginwithemail3",
    FIELD3: "This-is-subsection-of-main-doc"
  },
  {
    "Feature-name": "Passwordless-Login-OTP",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/tutorial/passwordless-login#passwordlessloginwithotp4",
    FIELD3: "This-is-subsection-of-main-doc"
  },
  {
    "Feature-name": "Custom-IDPs",
    "Doc-Link": ""
  },
  {
    "Feature-name": "Integrations",
    "Doc-Link":
      "https://www.loginradius.com/docs/single-sign-on/tutorial/federated-sso/pre-built-connections/"
  },
  {
    "Feature-name": "OAuth-Provider",
    "Doc-Link":
      "https://www.loginradius.com/docs/single-sign-on/tutorial/custom-identity-providers/custom-oauth-provider/"
  },
  {
    "Feature-name": "Custom-IDPs",
    "Doc-Link": ""
  },
  {
    "Feature-name": "ADFS-Provider",
    "Doc-Link":
      "https://www.loginradius.com/docs/single-sign-on/tutorial/custom-identity-providers/custom-adfs-provider/"
  },
  {
    "Feature-name": "Custom-IDPs",
    "Doc-Link": ""
  },
  {
    "Feature-name": "JWT-Provider",
    "Doc-Link":
      "https://www.loginradius.com/docs/single-sign-on/tutorial/custom-identity-providers/custom-jwt-provider/"
  },
  {
    "Feature-name": "Custom-IDPs",
    "Doc-Link": ""
  },
  {
    "Feature-name": "SAML-Provider",
    "Doc-Link":
      "https://www.loginradius.com/docs/single-sign-on/tutorial/custom-identity-providers/custom-saml-provider/"
  },
  {
    "Feature-name": "authorization-server-apis",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/single-sign-on/Machine-to-Machine/overview/#overview0"
  },
  {
    "Feature-name": "Machine-To-Machine-App",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/single-sign-on/Machine-to-Machine/overview/#adminconsoleconfigurationformmauthentication3"
  },
  {
    "Feature-name": "Federated-SSO",
    "Doc-Link": ""
  },
  {
    "Feature-name": "Integrations",
    "Doc-Link":
      "https://www.loginradius.com/docs/single-sign-on/tutorial/federated-sso/pre-built-connections/"
  },
  {
    "Feature-name": "SAML",
    "Doc-Link":
      "https://www.loginradius.com/docs/single-sign-on/tutorial/Federated-SSO/saml/overview/"
  },
  {
    "Feature-name": "Federated-SSO",
    "Doc-Link": ""
  },
  {
    "Feature-name": "JWT",
    "Doc-Link":
      "https://www.loginradius.com/docs/single-sign-on/tutorial/Federated-SSO/JWT-login/JWT-login-overview/"
  },
  {
    "Feature-name": "Federated-SSO",
    "Doc-Link": ""
  },
  {
    "Feature-name": "OAuth2",
    "Doc-Link":
      "https://www.loginradius.com/docs/single-sign-on/tutorial/Federated-SSO/OAuth-2-0/OAuth-2-0-overview/"
  },
  {
    "Feature-name": "Federated-SSO",
    "Doc-Link": ""
  },
  {
    "Feature-name": "Openid-Connect",
    "Doc-Link":
      "https://www.loginradius.com/docs/single-sign-on/tutorial/Federated-SSO/OpenId-Connect/OpenId-Connect-overview/"
  },
  {
    "Feature-name": "SSO-Connector",
    "Doc-Link": ""
  },
  {
    "Feature-name": "Shopify",
    "Doc-Link":
      "https://www.loginradius.com/docs/libraries/turn-key-plugins/shopify-multipass-integration/"
  },
  {
    "Feature-name": "SSO-Connector",
    "Doc-Link": ""
  },
  {
    "Feature-name": "Big-Commerce",
    "Doc-Link":
      "https://www.loginradius.com/docs/libraries/turn-key-plugins/bigcommerce-blueprint-plugin/"
  },
  {
    "Feature-name": "SSO-Connector",
    "Doc-Link": ""
  },
  {
    "Feature-name": "Perfectmind",
    "Doc-Link":
      "https://www.loginradius.com/docs/libraries/turn-key-plugins/perfectmind/"
  },
  {
    "Feature-name": "Delegation-Auth",
    "Doc-Link":
      "https://www.loginradius.com/docs/single-sign-on/concept/delegation-login-api/#delegation-authentication"
  },
  {
    "Feature-name": "Password-Delegation",
    "Doc-Link":
      "https://www.loginradius.com/docs/single-sign-on/concept/password-delegation-api/#delegation-authentication"
  },
  {
    "Feature-name": "Manage-Roles-Permissions",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/roles-and-membership/"
  },
  {
    "Feature-name": "Default-Role-Permission",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/roles-and-membership/"
  },
  {
    "Feature-name": "Captcha-Settings",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/auth-security-configuration/#captchasetting0"
  },
  {
    "Feature-name": "Access-Restriction",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/customer-security/#partaccessrestriction1"
  },
  {
    "Feature-name": "Brute-Force-Lockout",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/customer-security/#partbruteforcelockout3"
  },
  {
    "Feature-name": "Password-Expiration",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/password-policy/#partpasswordexpiration0"
  },
  {
    "Feature-name": "Password-History",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/password-policy/#partpasswordhistory1"
  },
  {
    "Feature-name": "Password-Complexity",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/password-policy/#partpasswordcomplexity2"
  },
  {
    "Feature-name": "Token-Lifetime",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/session-management/#partaccesstokenlifetime0"
  },
  {
    "Feature-name": "Force-Logout",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/session-management/#partforcelogout2"
  },
  {
    "Feature-name": "Remember-Me",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/session-management/#partrememberme4"
  },
  {
    "Feature-name": "Security-Question",
    "Doc-Link": ""
  },
  {
    "Feature-name": "Settings",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/security-question/#securityquestion0"
  },
  {
    "Feature-name": "Multi-Factor-Authentication",
    "Doc-Link": ""
  },
  {
    "Feature-name": "Settings",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/multi-factor-auth/"
  },
  {
    "Feature-name": "Multi-Factor-Authentication",
    "Doc-Link": ""
  },
  {
    "Feature-name": "Email-Passcode",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/multi-factor-auth/#configuringemailpasscode0"
  },
  {
    "Feature-name": "Multi-Factor-Authentication",
    "Doc-Link": ""
  },
  {
    "Feature-name": "SMS-Passcode",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/multi-factor-auth/#configuringsmspasscode1"
  },
  {
    "Feature-name": "Multi-Factor-Authentication",
    "Doc-Link": ""
  },
  {
    "Feature-name": "Authenticator-Apps",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/multi-factor-auth/#configuringgoogleauthenticator2"
  },
  {
    "Feature-name": "Multi-Factor-Authentication",
    "Doc-Link": ""
  },
  {
    "Feature-name": "Security-Questions",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/multi-factor-auth/#configuringsecurityquestions3"
  },
  {
    "Feature-name": "rba-settings",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/risk-based-auth/"
  },
  {
    "Feature-name": "Multi-Factor-Settings",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/risk-based-auth/#multifactorsettings1"
  },
  {
    "Feature-name": "Admin-Email",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/risk-based-auth/#adminemail2"
  },
  {
    "Feature-name": "Email-Templates",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/risk-based-auth/#emailtemplates3"
  },
  {
    "Feature-name": "SMS-Templates",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/risk-based-auth/#smstemplates4"
  },
  {
    "Feature-name": "Pin-Authentication",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/customer-identity-api/pin-authentication/overview/#partconfiguration1"
  },
  {
    "Feature-name": "Email-Template",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/customer-identity-api/pin-authentication/overview/#partconfiguration1"
  },
  {
    "Feature-name": "SMS-Template",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/customer-identity-api/pin-authentication/overview/#partconfiguration1"
  },
  {
    "Feature-name": "Account-API-Keys",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/api-key-and-secret/#accountapikeys0"
  },
  {
    "Feature-name": "Additional-API-Secrets",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/api-key-and-secret/#additionalapisecrets3"
  },
  {
    "Feature-name": "Bot-Protection",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/api-security/#botprotection0"
  },
  {
    "Feature-name": "Ip-Access-Restriction",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/api-security/#ipaccessrestriction1"
  },
  {
    "Feature-name": "Data-Center",
    "Doc-Link":
      "https://www.loginradius.com/docs/governance/data-governance-overview/"
  },
  {
    "Feature-name": "Standard-Data-Fields",
    "Doc-Link": "https://www.loginradius.com/docs/governance/data-schema/"
  },
  {
    "Feature-name": "Custom-Data-Fields",
    "Doc-Link":
      "https://www.loginradius.com/docs/governance/custom-field-configuration/"
  },
  {
    "Feature-name": "Custom-Objects",
    "Doc-Link":
      "https://www.loginradius.com/docs/governance/customer-profiling/custom-object-overview/"
  },
  {
    "Feature-name": "Hashing-Algorithm",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/password-policy/#partpasswordhashingalgorithm10"
  },
  {
    "Feature-name": "Encryption-In-Transit",
    "Doc-Link":
      "https://www.loginradius.com/docs/governance/customer-profiling/data-security/#dataintransit1"
  },
  {
    "Feature-name": "Encryption-At-Rest",
    "Doc-Link":
      "https://www.loginradius.com/docs/governance/customer-profiling/data-security/#dataatrest2"
  },
  {
    "Feature-name": "Data-Compliances",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/regulations/#datacompliances0"
  },
  {
    "Feature-name": "Security-Compliances",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/regulations/#securitycompliances1"
  },
  {
    "Feature-name": "Privacy-Versioning",
    "Doc-Link":
      "https://www.loginradius.com/docs/governance/customer-profiling/privacy-policy/"
  },
  {
    "Feature-name": "Gdpr-Compliance",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/regulations/#gdprcompliance2"
  },
  {
    "Feature-name": "Age-Verification",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-security/regulations/#ageverification3"
  },
  {
    "Feature-name": "Consent-Management",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/customer-identity-api/consent-mangement/overview/"
  },
  {
    "Feature-name": "Web-Apps",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/production-environments/"
  },
  {
    "Feature-name": "Mobile-Apps",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/customer-identity-api/sott-usage/#staticsott7"
  },
  {
    "Feature-name": "Custom-Domain",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/custom-domain-and-ssl-configuration/#stepsforsettingupcustomdomain1"
  },
  {
    "Feature-name": "Progressive-Profiling",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/progressive-profiling/#partpasswordhashingalgorithm9"
  },
  {
    "Feature-name": "Idx",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/ui-ux-customizations-idx/"
  },
  {
    "Feature-name": "Settings",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#settingswidgets1"
  },
  {
    "Feature-name": "Registration-Forms",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#registrationforms2"
  },
  {
    "Feature-name": "Login",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },

  {
    "Feature-name": "Registration",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },

  {
    "Feature-name": "Forgotpassword",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "Resetpassword",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "verifyemail",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "linkaccount",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "sociallogin",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "changepassword",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "unlinkaccount",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "profileeditor",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "twofactorauthentication",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "addemail",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "removeemail",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "resendemailverification",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "changeusername",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "updatephonenumber",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "passwordlessloginvalidate",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/js-widgets/#jswidgets3"
  },
  {
    "Feature-name": "SDK-Library",
    "Doc-Link":
      "https://www.loginradius.com/docs/libraries/sdk-libraries/overview/"
  },
  {
    "Feature-name": "Mobile-Library",
    "Doc-Link":
      "https://www.loginradius.com/docs/libraries/mobile-sdk-libraries/overview/"
  },
  {
    "Feature-name": "Turnkey-Plugins",
    "Doc-Link":
      "https://www.loginradius.com/docs/libraries/turn-key-plugins/overview/"
  },
  {
    "Feature-name": "Configuration-Deployment",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/deployment/site-configurations-sync/#site-configuration-sync"
  },
  {
    "Feature-name": "Data-Migration",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/self-serve-data-migration/#self-serve-data-migration"
  },
  {
    "Feature-name": "Data-Migration-Logs",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/self-serve-data-migration/#partdatamigrationlogs3"
  },
  {
    "Feature-name": "Configuration",
    "Doc-Link":
      "https://www.loginradius.com/docs/integrations/server-side-cloud-connectors/overview/#managingcloudconnectorconfiguration6"
  },
  {
    "Feature-name": "available-integrations",
    "Doc-Link":
      "https://www.loginradius.com/docs/integrations/available-integrations/#available-integrations"
  },

  {
    "Feature-name": "Web-Hooks",
    "Doc-Link":
      "https://www.loginradius.com/docs/integrations/webhook/#webhooks"
  },
  {
    "Feature-name": "On-demand-export",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/user-data-export/on-demand-export/#on-demand-export"
  },
  {
    "Feature-name": "Scheduled-export",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/user-data-export/scheduled-export/#on-demand-export"
  },
  {
    "Feature-name": "audit-log-integration",
    "Doc-Link":
      "https://www.loginradius.com/docs/integrations/audit-log/overview/"
  },
  {
    "Feature-name": "Login-Session",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/my-account/login-session"
  },
  {
    "Feature-name": "Export-logs",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/user-data-export/export-logs/#on-demand-export"
  },
  {
    "Feature-name": "Search-Customers",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/search-customers/#search-customer"
  },
  {
    "Feature-name": "Blocked-Customers",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/blocked-customer/#search-customer"
  },
  {
    "Feature-name": "Add-new-customers",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/add-a-new-customer/#search-customer"
  },
  {
    "Feature-name": "Basic-Segmentation",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/customer-segmentation/basic-segmentation/#search-customer"
  },
  {
    "Feature-name": "Advanced-Segmentation",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/customer-segmentation/advanced-segmentation/#search-customer"
  },
  {
    "Feature-name": "Saved-Segmentation",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/customer-segmentation/save-segmentation/#search-customer"
  },
  {
    "Feature-name": "Customer-query",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/data-query/customer-query/#customer-query"
  },
  {
    "Feature-name": "Custom-Object-query",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/data-query/custom-object-query/#customer-query"
  },
  {
    "Feature-name": "Identity-analytics",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/identity-analytics/#identity-analytics"
  },
  {
    "Feature-name": "overview",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/analytics-overview"
  },
  {
    "Feature-name": "customer-stats",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/identity-analytics/#customerstats0"
  },
  {
    "Feature-name": "customer-growth",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/identity-analytics/#customergrowth1"
  },
  {
    "Feature-name": "provider",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/identity-analytics/#provider2"
  },
  {
    "Feature-name": "identity-analytics/region",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/identity-analytics/#region3"
  },
  {
    "Feature-name": "identity-analytics/age-group",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/identity-analytics/#agegroup4"
  },
  {
    "Feature-name": "identity-analytics/gender",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/identity-analytics/#gender5"
  },
  {
    "Feature-name": "browser",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/identity-analytics/#browser6"
  },
  {
    "Feature-name": "device",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/identity-analytics/#device7"
  },
  {
    "Feature-name": "daily-distribution",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-insights/provider-analytics#dailydistribution0"
  },
  {
    "Feature-name": "provider-analytics/region",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/provider-analytics/#region1"
  },
  {
    "Feature-name": "provider-analytics/age-group",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/provider-analytics/#agegroup2"
  },
  {
    "Feature-name": "provider-analytics/gender",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/provider-analytics/#gender3"
  },
  {
    "Feature-name": "Provider-analytics",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/provider-analytics/#identity-analytics"
  },
  {
    "Feature-name": "login-stats",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-insights/login-analytics#loginstats0"
  },
  {
    "Feature-name": "login-distribution",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-insights/login-analytics#logindistribution1"
  },
  {
    "Feature-name": "daily-active-customers",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-insights/login-analytics#dailyactivecustomers2"
  },
  {
    "Feature-name": "monthly-active-customers",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-insights/login-analytics#monthlyactivecustomers3"
  },
  {
    "Feature-name": "customer-return-rate",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-insights/login-analytics#userreturnratebyprovider4"
  },
  {
    "Feature-name": "provider-engagement",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-insights/login-analytics#providerengagement5"
  },
  {
    "Feature-name": "browser-engagement",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-insights/login-analytics#browserengagement6"
  },
  {
    "Feature-name": "device-engagement",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-insights/login-analytics#deviceengagement7"
  },
  {
    "Feature-name": "month-comparison",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-insights/comparative-analysis/#monthtomonthcomparison0"
  },
  {
    "Feature-name": "registration-streaks",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-insights/comparative-analysis/#registrationstreaks1"
  },
  {
    "Feature-name": "Comparative-analytics",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/comparative-analysis/#identity-analytics"
  },
  {
    "Feature-name": "API-analytics",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/api-analytics/#identity-analytics"
  },
  {
    "Feature-name": "request-count",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/api-analytics/#requestcount0"
  },
  {
    "Feature-name": "response-code",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/api-analytics/#responsecode1"
  },
  {
    "Feature-name": "sms-analytics",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/sms-analytics/#smsanalytics0"
  },
  {
    "Feature-name": "lookup-profile",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/api-analytics/#lookupprofile3"
  },
  {
    "Feature-name": "auth-profile",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/api-analytics/#authprofile4"
  },
  {
    "Feature-name": "create-profile",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/api-analytics/#createprofile5"
  },
  {
    "Feature-name": "delete-profile",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/api-analytics/#deleteprofile6"
  },
  {
    "Feature-name": "modify-profile",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-intelligence/api-analytics/#modifyprofile7"
  },
  {
    "Feature-name": "Profile",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/my-account/profile/"
  },

  {
    "Feature-name": "Security",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/my-account/security"
  },
  {
    "Feature-name": "Manage-Team-member",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/team-management/team-management-overview/#manageteammember0"
  },
  {
    "Feature-name": "Audit-logs",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/team-management/audit-logs/#manageteammember0"
  },
  {
    "Feature-name": "Add-Team-Member",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/team-management/manage-team-members/#addteammember2"
  },

  {
    "Feature-name": "Single-Sign-On",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/team-management/sso-connectors/overview/"
  },
  {
    "Feature-name": "Ownership-Settings",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/team-management/transfer-site-ownership/"
  },

  {
    "Feature-name": "Subscription-Details",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/overview/#billing10"
  },

  {
    "Feature-name": "Invoices",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/overview/#billing10"
  },
  {
    "Feature-name": "Shared-Sites",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/team-management/shared-sites/"
  },
  {
    "Feature-name": "Site-list",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/team-management/shared-sites/#getallsites2"
  },
  {
    "Feature-name": "Verification-Email",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/dashboard/email-template-management"
  },
  {
    "Feature-name": "Forgot-Password-Email",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/dashboard/email-template-management"
  },
  {
    "Feature-name": "Welcome-Email",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/dashboard/email-template-management"
  },
  {
    "Feature-name": "Delete-Account-Email",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/dashboard/email-template-management"
  },
  {
    "Feature-name": "Add-Email",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/dashboard/email-template-management"
  },
  {
    "Feature-name": "Password-Reset-Email",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/dashboard/email-template-management"
  },
  {
    "Feature-name": "data-etl-migration",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/data-etl-service/#data-etl-service-introduction"
  },
  {
    "Feature-name": "Data-ETL-Logs",
    "Doc-Link":
      "https://www.loginradius.com/docs/customer-management/data-etl-service/#partdataetllogs3"
  },

  {
    "Feature-name": "Verify-Email",
    "Doc-Link":
      "Already-mapped-in-dashboard.-UI-will-be-improved-as-per-hitesh-design"
  },
  {
    "Feature-name": "Change-Password",
    "Doc-Link":
      "Already-mapped-in-dashboard.-UI-will-be-improved-as-per-hitesh-design"
  },
  {
    "Feature-name": "Social-Login",
    "Doc-Link":
      "Already-mapped-in-dashboard.-UI-will-be-improved-as-per-hitesh-design"
  },
  {
    "Feature-name": "Link-Account",
    "Doc-Link":
      "Already-mapped-in-dashboard.-UI-will-be-improved-as-per-hitesh-design"
  },
  {
    "Feature-name": "Unlink-Account",
    "Doc-Link":
      "Already-mapped-in-dashboard.-UI-will-be-improved-as-per-hitesh-design"
  },
  {
    "Feature-name": "Profile-Editor",
    "Doc-Link":
      "Already-mapped-in-dashboard.-UI-will-be-improved-as-per-hitesh-design"
  },
  {
    "Feature-name": "Two-Factor-Authentication",
    "Doc-Link":
      "Already-mapped-in-dashboard.-UI-will-be-improved-as-per-hitesh-design"
  },
  {
    "Feature-name": "Add-Email",
    "Doc-Link":
      "Already-mapped-in-dashboard.-UI-will-be-improved-as-per-hitesh-design"
  },
  {
    "Feature-name": "Remove-Email",
    "Doc-Link":
      "Already-mapped-in-dashboard.-UI-will-be-improved-as-per-hitesh-design"
  },
  {
    "Feature-name": "Resend-Email-Verification",
    "Doc-Link":
      "Already-mapped-in-dashboard.-UI-will-be-improved-as-per-hitesh-design"
  },
  {
    "Feature-name": "Change-Username",
    "Doc-Link":
      "Already-mapped-in-dashboard.-UI-will-be-improved-as-per-hitesh-design"
  },
  {
    "Feature-name": "Auto-Login",
    "Doc-Link":
      "Already-mapped-in-dashboard.-UI-will-be-improved-as-per-hitesh-design"
  },
  {
    "Feature-name": "Phone-Number-Verification",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-configuration/phone-login-configuration/#smstemplatesettings1"
  },
  {
    "Feature-name": "Phone-Number-Change",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-configuration/phone-login-configuration/#smstemplatesettings1"
  },
  {
    "Feature-name": "Password-Reset",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-configuration/phone-login-configuration/#smstemplatesettings1"
  },
  {
    "Feature-name": "Welcome-SMS",
    "Doc-Link":
      "https://www.loginradius.com/docs/api/v2/admin-console/platform-configuration/phone-login-configuration/#smstemplatesettings1"
  },
  {
    "Feature-name": "Advanced-Workflow",
    "Doc-Link":
      "https://www.loginradius.com/docs/authentication/concepts/advanced-workflow/"
  },
  {
    "Feature-name": "sso-domain-grouping",
    "Doc-Link":
      "https://www.loginradius.com/docs/single-sign-on/tutorial/web-sso/overview/#web-sso-introduction"
  }
];

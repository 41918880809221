import { combineReducers } from "redux";
import { ActionSchema } from "../actions";
import {
  SupportConst,
  GetExistingTicketsSchema,
  GetUsersForAppSchema,
  GetCustomerDataSchema,
  GetFilePickerKeySchema,
  SendTicketDataSchema
} from "../actions/supportActions";

const existingTicketsReducer = (
  state = {},
  action: ActionSchema<GetExistingTicketsSchema>
) => {
  switch (action.type) {
    case SupportConst.GET_EXISTING_TICKETS_LOADING:
    case SupportConst.GET_EXISTING_TICKETS_ERROR:
    case SupportConst.GET_EXISTING_TICKETS_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getUsersForAppReducer = (
  state = {},
  action: ActionSchema<GetUsersForAppSchema>
) => {
  switch (action.type) {
    case SupportConst.GET_USERS_FOR_APP_LOADING:
    case SupportConst.GET_USERS_FOR_APP_SUCCESS:
    case SupportConst.GET_USERS_FOR_APP_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getCustomerDataReducer = (
  state = {},
  action: ActionSchema<GetCustomerDataSchema>
) => {
  switch (action.type) {
    case SupportConst.GET_CUSTOMER_DATA_LOADING:
    case SupportConst.GET_CUSTOMER_DATA_SUCCESS:
    case SupportConst.GET_CUSTOMER_DATA_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getFilePickerKeyReducer = (
  state = {},
  action: ActionSchema<GetFilePickerKeySchema>
) => {
  switch (action.type) {
    case SupportConst.GET_FILE_PICKER_KEY_LOADING:
    case SupportConst.GET_FILE_PICKER_KEY_SUCCESS:
    case SupportConst.GET_FILE_PICKER_KEY_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const sendTicketDataReducer = (
  state = {},
  action: ActionSchema<SendTicketDataSchema>
) => {
  switch (action.type) {
    case SupportConst.SUBMIT_TICKET_LOADING:
    case SupportConst.SUBMIT_TICKET_SUCCESS:
    case SupportConst.SUBMIT_TICKET_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

// Combine Reducers
const supportReducer = combineReducers({
  existingTickets: existingTicketsReducer,
  usersForApp: getUsersForAppReducer,
  customerData: getCustomerDataReducer,
  filePickerKey: getFilePickerKeyReducer,
  submittedTicketData: sendTicketDataReducer
});

// Combine schemas
export interface SupportSchema {
  existingTickets: GetExistingTicketsSchema;
  usersForApp: GetUsersForAppSchema;
  customerData: GetCustomerDataSchema;
  filePickerKey: GetFilePickerKeySchema;
  submittedTicketData: SendTicketDataSchema;
}

export default supportReducer;

import {
  SideEffectSchema,
  V2ErrorResponseSchema,
  loadingAction,
  errorAction,
  successSideEffectState,
  generateConst,
  ObjectSchema,
  APIErrorResponseSchema
} from ".";
import { CustomObjectDataSchema } from "./segmentationsAction";
import { RegistarationFieldSchema } from "./deploymentActions";

// Constants
export const CustomerManagementConst = generateConst("CUSTOMER_MANAGEMENT", [
  "GET_USER_BY_SEARCHFIELDS",
  "GET_USERS",
  "GET_CONSUMER_AUDIT_LOGS",
  "GET_CONSUMER_AUDIT_LOGS_BY_UID",
  "LOAD_ENABLED_FIELDS_SETTINGS",
  "ADD_USER",
  "GET_TOTAL_USERS",
  "GET_EMAIL_TEMPLATES",
  "GET_ALL_EMAIL_TEMPLATES",
  "UPDATE_TOTAL_USERS",
  "GET_USER_IDENTITY",
  "POST_SESSIONS",
  "ADD_SECONDARY_EMAIL",
  "BLOCK_OR_UNBLOCK_USER",
  "DELETE_USER",
  "UNLOCK_USER",
  "VERIFY",
  "RESET_PASSWORD",
  "SEND_RESET_PASSWORD",
  "RESEND_EMAIL",
  "USER_IDENTITIES",
  "REMOVE_EMAIL_ADDRESS",
  "UPDATE_USER_PROFILE",
  "GET_ROLE",
  "GET_ASSIGNED_ROLE",
  "ASSIGN_ROLES",
  "UNASSIGN_ROLES",
  "GET_CUSTOM_OBJECT_DATA",
  "GET_CUSTOM_OBJECTS",
  "GET_ROLE_CONTEXT",
  "ADD_ROLE_CONTEXT",
  "DELETE_ROLE_CONTEXT",
  "GET_CONSENT_LOGS",
  "GET_CUSTOM_FIELDS",
  "GET_ES_FIELDS",
  "GET_USER_PRIVACY_POLICY",
  "GET_USER_2FA_SETTING"
]);

/** Common Schemas */
export interface UserSchema {
  ConsentProfile: any;
  NickName: string;
  ProfileName: string;
  Website: string;
  Favicon: string;
  Industry: string;
  About: string;
  CoverPhoto: string;
  TagLine: string;
  Positions: string;
  IMAccounts: any;
  RelationshipStatus: string;
  Quota: string;
  InterestedIn: string[];
  Interests: any[];
  Religion: string;
  Political: string;
  Sports: SocialFieldSchema[];
  InspirationalPeople: SocialFieldSchema[];
  Associations: string;
  Honors: string;
  Awards: SocialFieldSchema[];
  Skills: SocialFieldSchema[];
  CurrentStatus: any[];
  Certifications: SocialFieldSchema[];
  Courses: SocialFieldSchema[];
  Volunteer: any[];
  RecommendationsReceived: any[];
  Projects: any;
  Games: SocialFieldSchema[]; // Take Reference
  TeleVisionShow: SocialFieldSchema[]; // Take Reference
  MutualFriends: any[];
  Movies: SocialFieldSchema[];
  Books: SocialFieldSchema[];
  PublicRepository: string;
  RepositoryUrl: string;
  Patents: any;
  FavoriteThings: any[];
  ProfessionalHeadline: string;
  RelatedProfileViews: any[];
  KloutScore: any;
  PlacesLived: any;
  Publications: any;
  JobBookmarks: any;
  Suggestions: any;
  Badges: any;
  Currency: string;
  StarredUrl: string;
  GistsUrl: string;
  Subscription: any;
  Company: SocialFieldSchema[];
  WebProfiles: ObjectSchema<string>[];
  LastPasswordChangeDate: string;
  PasswordExpirationDate: string;
  LastPasswordChangeToken: string;
  EmailVerified: boolean;
  IsActive: boolean;
  IsDeleted: boolean;
  Uid: string;
  IsEmailSubscribed: boolean;
  UserName: string;
  NoOfLogins: number;
  PhoneId: string;
  PhoneIdVerified: boolean;
  Roles: string[] | null;
  ExternalUserLoginId: string;
  RegistrationProvider: string;
  IsLoginLocked: boolean;
  LoginLockedType: string;
  RegistrationSource: string;
  IsCustomUid: boolean;
  UnverifiedEmail: EmailSchema[] | null;
  IsSecurePassword: boolean;
  ExternalIds: ExIdsSchema[] | null;
  IsRequiredFieldsFilledOnce: boolean;
  RegistrationData: null;
  ID: string;
  Provider: string;
  Prefix: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Suffix: string;
  FullName: string;
  BirthDate: string;
  Gender: string;
  Country: CountrySchema | null;
  HomeTown: string;
  State: string;
  City: string;
  TimeZone: string;
  LocalLanguage: string;
  Language: string;
  UpdatedTime: string;
  Educations: EducationSchema[];
  PhoneNumbers: PhoneNumberSchema[] | null;
  Addresses: AddressSchema[];
  MainAddress: string;
  CreatedDate: string;
  ModifiedDate: string;
  ProfileModifiedDate: string;
  LocalCity: string;
  ProfileCity: string;
  LocalCountry: string;
  ProfileCountry: string;
  FirstLogin: boolean;
  Quote: string;
  IsGeoEnabled: string;
  Languages: any | null;
  Family: any | null;
  AgeRange: any | null;
  Age: string;
  LRUserID: string;
  MemberUrlResources: any | null;
  SignupDate: string;
  LastLoginDate: string;
  PreviousUids: string[] | null;
  Email: EmailSchema[];
  NumRecommenders: number;
  PrivacyPolicyHistory?: PrivacyPolicySchema[];
  HttpsImageUrl: string;
  Verified: string;
  FollowersCount: number;
  SecurityQuestionFailedResetPasswordAttempts: number;
  LikesCount: number;
  ImageUrl: string;
  TotalStatusesCount: number;
  GravatarImageUrl: string;
  BoardsCount: number;
  CustomFields: ObjectSchema<string>;
  PublicGists: number;
  FailedLoginAttempt: number;
  TotalPrivateRepository: number;
  ProfileUrl: string;
  ProfileImageUrls: ProfileImageUrlsSchema;
  LastAcceptedConsentVersion: number;
  Consents: string | null;
  FriendsCount: number;
  LastLoginLocation: string;
  Created: string | null;
  AppName: string;
  PinsCount: number;
  user_agent: UserAgentSchema;
  IsProtected: boolean;
  Hireable: boolean;
  PrivacyPolicy?: PrivacyPolicySchema;
  SignupLog: SignupLogSchema;
  PrivateGists: number;
  PIN: string | null;
  ThumbnailImageUrl: string;
  DisableLogin: boolean;
  Identities: IdentitySchema[];
}

export interface IdentitySchema {
  ID: string;
  Provider: string;
  Prefix: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Suffix: string;
  FullName: string;
  NickName: string;
  ProfileName: string;
  BirthDate: string;
  Gender: string;
  Website: string;
  Email: EmailSchema[];
  Country: CountrySchema[] | null;
  ThumbnailImageUrl: string;
  ImageUrl: string;
  Favicon: string;
  ProfileUrl: string;
  HomeTown: string;
  State: string;
  City: string;
  Industry: string;
  About: string;
  TimeZone: string;
  LocalLanguage: string;
  CoverPhoto: string;
  TagLine: string;
  Language: string;
  Verified: string;
  UpdatedTime: string;
  Positions: any;
  Educations: EducationSchema[] | null;
  PhoneNumbers: PhoneNumberSchema[] | null;
  IMAccounts: any;
  Addresses: AddressSchema[] | null;
  MainAddress: string;
  Created: string | null;
  CreatedDate: string;
  ModifiedDate: string;
  ProfileModifiedDate: string;
  LocalCity: string;
  ProfileCity: string;
  LocalCountry: string;
  ProfileCountry: string | null;
  FirstLogin: boolean;
  IsProtected: boolean;
  RelationshipStatus: string;
  Quota: string;
  Quote: string;
  InterestedIn: string[] | null;
  Interests: any;
  Religion: string;
  Political: string;
  Sports: any;
  InspirationalPeople: any;
  HttpsImageUrl: string;
  FollowersCount: number;
  FriendsCount: number;
  IsGeoEnabled: boolean;
  TotalStatusesCount: number;
  Associations: string;
  NumRecommenders: number;
  Honors: string;
  Awards: any;
  Skills: any;
  CurrentStatus: any;
  Certifications: any;
  Courses: any;
  Volunteer: any;
  RecommendationsReceived: any;
  Languages: any;
  Projects: any;
  Games: any;
  Family: any;
  TeleVisionShow: any;
  MutualFriends: any;
  Movies: any;
  Books: any;
  AgeRange: AgeRange;
  PublicRepository: any;
  Hireable: boolean;
  RepositoryUrl: null;
  Age: string;
  Patents: any;
  FavoriteThings: any[];
  ProfessionalHeadline: any;
  RelatedProfileViews: any;
  KloutScore: any;
  LRUserID: any;
  PlacesLived: any;
  Publications: any;
  JobBookmarks: any;
  Suggestions: any;
  Badges: any;
  MemberUrlResources: any;
  TotalPrivateRepository: number;
  Currency: string;
  StarredUrl: any;
  GistsUrl: any;
  PublicGists: number;
  PrivateGists: number;
  Subscription: any;
  Company: any;
  GravatarImageUrl: string;
  ProfileImageUrls: ProfileImageUrlsSchema;
  WebProfiles: any;
  PinsCount: number;
  BoardsCount: number;
  LikesCount: number;
  SignupDate: string;
  LastLoginDate: string;
  PreviousUids: any;
}

export interface AgeRange {
  Min: number;
  Max: number;
}

export interface SocialFieldSchema {
  Id: string;
  Name: string;
  Issuer?: string;
  Authority?: string;
  Number?: string;
  StartDate?: Date;
  EndDate?: Date;
  Category?: string;
  CreateDate?: Date;
}

export interface AddressSchema {
  Type: string;
  Address1: string;
  Address2: string;
  City: string;
  State: string;
  PostalCode: string;
  Region: string;
  Country: string;
}

export interface CountrySchema {
  Code: string;
  Name: string;
}

export interface EducationSchema {
  School: string;
  year: string;
  type: string;
  notes: string;
  activities: string;
  degree: string;
  fieldofstudy: string;
  StartDate: string;
  EndDate: string;
}

export interface PhoneNumberSchema {
  PhoneType: string;
  PhoneNumber: string;
}

export interface EmailSchema {
  Type: string;
  Value: string;
}

export interface ExIdsSchema {
  Source: string;
  SourceId: string;
}

export interface PrivacyPolicySchema {
  AcceptDateTime: Date;
  Version: string;
  AcceptSource: string;
}

export interface ProfileImageUrlsSchema {
  "Image Url"?: string;
  "ThumbnailImage Url"?: string;
  Profile?: string;
  Small?: string;
  Square?: string;
  Large?: string;
}

export interface SignupLogSchema {
  IP: string;
  UserAgent: string;
  Host: string;
}

export interface UserAgentSchema {
  patch: string;
  major: string;
  minor: string;
  os: string;
  name: string;
  os_name: string;
  device: string;
}

/**
 * GET_USER_BY_SEARCHFIELDS
 */

export interface GetSearchUsersSchema extends SideEffectSchema {
  clientIP: string;
  totalUsers: number;
  users: UserSchema[];
  ErrorCode?: number;
  Message?: string;
}

export interface GetUserBySearchFieldsArgs {
  field: "email" | "id" | "uid" | "name" | "phoneid";
  itemsPerPage: number;
  pagenumber: number;
  value: string;
  host: string[] | null;
  is_blocked?: boolean;
}

export const getUserBySearchFieldsAction = (
  args: GetUserBySearchFieldsArgs
) => {
  return {
    type: CustomerManagementConst.GET_USER_BY_SEARCHFIELDS,
    payload: { ...args }
  };
};

export const getUserBySearchFieldsLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_USER_BY_SEARCHFIELDS_LOADING);
export const getUserBySearchFieldsErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_USER_BY_SEARCHFIELDS_ERROR, error);

export const getUserBySearchFieldsSuccessAction = (
  data: GetSearchUsersSchema
) => {
  const payload: GetSearchUsersSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_USER_BY_SEARCHFIELDS_SUCCESS,
    payload
  };
};

/**
 * GET_USERS
 * The respone schema is same as GetSearchUsersSchema
 */

export interface GetUsersArgs {
  itemsPerPage: number;
  pagenumber: number;
  is_blocked: boolean;
  host: string[] | null;
}

export const getUsersAction = (args: GetUsersArgs) => {
  return {
    type: CustomerManagementConst.GET_USERS,
    payload: { ...args }
  };
};

export const getUsersLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_USERS_LOADING);
export const getUsersErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_USERS_ERROR, error);

export const getUsersSuccessAction = (data: GetSearchUsersSchema) => {
  const payload: GetSearchUsersSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_USERS_SUCCESS,
    payload
  };
};

// AuditLogs


export interface GetConsumerAuditLogsSchema extends SideEffectSchema {

  logs:ConsumerAuditLogsSchema[];
  totalLogs: number;
  ErrorCode?: number;
  Message?: string;
}

export interface ConsumerAuditLogsSchema {
  timestamp: number;
  operation:string;
  uid: string | null;

appname: string;

category: string;

code : number
email : string | null;

extra_info : string | null
host : string;
id : string;
ip: string;

message : string;
method : string;

module: string;

request_path: string;
sub_module: string;
type : string;
useragent: string
}

export interface GetConsumerAuditLogsArgs {
  itemsPerPage: number;
  pagenumber: number;
}
export interface GetConsumerAuditLogsByUIDArgs {
  uid: string;
  itemsPerPage: number;
  pagenumber: number;
}
export const getConsumerAuditLogsAction = (args: GetConsumerAuditLogsArgs) => {
  return {
    type: CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS,
    payload: { ...args }
  };
};
export const getConsumerAuditLogsLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS_LOADING);
export const getConsumerAuditLogsErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS_ERROR, error);

export const getConsumerAuditLogsSuccessAction = (data: GetConsumerAuditLogsSchema) => {
  const payload: GetConsumerAuditLogsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS_SUCCESS,
    payload
  };
};



export const getConsumerAuditLogsByUIDAction = (args: GetConsumerAuditLogsByUIDArgs) => {
  return {
    type: CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS_BY_UID,
    payload: { ...args }
  };
};
export const getConsumerAuditLogsByUIDLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS_BY_UID_LOADING);
export const getConsumerAuditLogsByUIDErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS_BY_UID_ERROR, error);

export const getConsumerAuditLogsByUIDSuccessAction = (data: GetConsumerAuditLogsSchema) => {
  const payload: GetConsumerAuditLogsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS_BY_UID_SUCCESS,
    payload
  };
};



export interface EnabledFieldSettingsSchema extends SideEffectSchema {
  data?: any;
}

export const loadEnabledFieldSettingsAction = () => {
  return {
    type: CustomerManagementConst.LOAD_ENABLED_FIELDS_SETTINGS,
    payload: {}
  };
};

export const loadEnabledFieldSettingsLoadingAction = () =>
  loadingAction(CustomerManagementConst.LOAD_ENABLED_FIELDS_SETTINGS_LOADING);

export const loadEnabledFieldSettingsErrorAction = (error: string) =>
  errorAction(
    CustomerManagementConst.LOAD_ENABLED_FIELDS_SETTINGS_ERROR,
    error
  );

export const loadEnabledFieldSettingsSuccessAction = (
  data: EnabledFieldSettingsSchema
) => {
  const payload: EnabledFieldSettingsSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.LOAD_ENABLED_FIELDS_SETTINGS_SUCCESS,
    payload
  };
};

export interface AddUserSchema extends SideEffectSchema {
  data?: any;
}

export const addUserAction = (payload: any) => {
  return {
    type: CustomerManagementConst.ADD_USER,
    payload
  };
};

export const addUserLoadingAction = () =>
  loadingAction(CustomerManagementConst.ADD_USER_LOADING);

export const addUserErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.ADD_USER_ERROR, error);

export const addUserSuccessAction = (data: AddUserSchema) => {
  const payload: AddUserSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.ADD_USER_SUCCESS,
    payload
  };
};

export interface TotalUsersSchema extends SideEffectSchema {
  data?: any;
}

export const getTotalUsersAction = () => {
  return {
    type: CustomerManagementConst.GET_TOTAL_USERS,
    payload: {}
  };
};

export const getTotalUsersLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_TOTAL_USERS_LOADING);

export const getTotalUsersErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_TOTAL_USERS_ERROR, error);

export const getTotalUsersSuccessAction = (data: TotalUsersSchema) => {
  const payload: TotalUsersSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_TOTAL_USERS_SUCCESS,
    payload
  };
};

export interface EmailTemplatesSchema extends SideEffectSchema {
  REGISTRATION: string[];
  FORGOTPASSWORD: string[];
  FORGOTPROVIDER: string[];
  DELETEACCOUNT: string[];
  WELCOME: string[];
  ONECLICKSIGNIN: string[];
  clientIP: string;
  Description?: string;
  IsProviderError?: false;
  message?: string;
  error?: string;
  errorCode?: string;
  ProviderErrorResponse?: null | string;
}

export const getEmailTemplatesAction = () => {
  return {
    type: CustomerManagementConst.GET_EMAIL_TEMPLATES,
    payload: {}
  };
};

export const getEmailTemplatesLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_EMAIL_TEMPLATES_LOADING);

export const getEmailTemplatesErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_EMAIL_TEMPLATES_ERROR, error);

export const getEmailTemplatesSuccessAction = (data: EmailTemplatesSchema) => {
  const payload: EmailTemplatesSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_EMAIL_TEMPLATES_SUCCESS,
    payload
  };
};

/**
 * GET_ALL_EMAIL_TEMPLATES
 * This action is used to get all email templates in an Array format
 *
 * @returns {Array} Array of all email templates
 * */
export interface AllEmailTemplatesSchema extends SideEffectSchema {
  data?: any;
}

export const getAllEmailTemplatesAction = () => {
  return {
    type: CustomerManagementConst.GET_ALL_EMAIL_TEMPLATES,
    payload: {}
  };
};

export const getAllEmailTemplatesLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_ALL_EMAIL_TEMPLATES_LOADING);

export const getAllEmailTemplatesErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_ALL_EMAIL_TEMPLATES_ERROR, error);

export const getAllEmailTemplatesSuccessAction = (
  data: AllEmailTemplatesSchema
) => {
  const payload: AllEmailTemplatesSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_ALL_EMAIL_TEMPLATES_SUCCESS,
    payload
  };
};

/**
 * UPDATE_TOTAL_USERS
 */
export interface UpdateTotalUsersSchema {
  totalRegisteredUsers: number;
  totalBlockedUsers: number;
}

export const updateTotalUsersAction = (payload: UpdateTotalUsersSchema) => {
  return {
    type: CustomerManagementConst.UPDATE_TOTAL_USERS,
    payload
  };
};

/**
 * GET_USER_IDENTITY
 */
export interface GetUserIdentitySchema extends SideEffectSchema {
  clientIP: string;
  users: UserSchema;
  ErrorCode?: number;
  Message?: string;
  Description?: string;
}

export const getUserIdentityAction = (uid: string) => {
  return {
    type: CustomerManagementConst.GET_USER_IDENTITY,
    payload: { uid }
  };
};
export const getUserIdentityLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_USER_IDENTITY_LOADING);
export const getUserIdentityErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_USER_IDENTITY_ERROR, error);
export const getUserIdentitySuccessAction = (data: GetUserIdentitySchema) => {
  const payload: GetUserIdentitySchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_USER_IDENTITY_SUCCESS,
    payload
  };
};

/**
 * POST_SESSIONS
 * This actions is used to logout a customer
 */
export interface PostSessionsSchema extends SideEffectSchema {
  clientIP: string;
  description?: string;
  errorCode?: number;
  isProviderError?: boolean;
  message?: string;
  providerErrorResponse?: null | string;
  data: {
    AccessToken: string;
    Browser: string;
    City: string;
    Country: string;
    Device: string;
    DeviceType: string;
    Ip: string;
    LoginDate: string;
    Os: string;
  }[];
  nextcursor: number;
}
export const postSessionsAction = (uid: string) => {
  return {
    type: CustomerManagementConst.POST_SESSIONS,
    payload: { uid }
  };
};
export const postSessionsLoadingAction = () =>
  loadingAction(CustomerManagementConst.POST_SESSIONS_LOADING);
export const postSessionsErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.POST_SESSIONS_ERROR, error);
export const postSessionsSuccessAction = (data: PostSessionsSchema) => {
  const payload: PostSessionsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.POST_SESSIONS_SUCCESS,
    payload
  };
};

/**
 * ADD_SECONDARY_EMAIL
 * This action is used to add/update emails in an account
 */
export interface AddSecondaryEmailSchema
  extends UserSchema,
    SideEffectSchema,
    V2ErrorResponseSchema {
  clientIP: string;
}
export const addSecondaryEmailAction = (
  uid: string,
  secondaryEmail: string,
  secondaryEmailType: string
) => {
  return {
    type: CustomerManagementConst.ADD_SECONDARY_EMAIL,
    payload: { uid, secondaryEmail, secondaryEmailType }
  };
};
export const addSecondaryEmailLoadingAction = () =>
  loadingAction(CustomerManagementConst.ADD_SECONDARY_EMAIL_LOADING);
export const addSecondaryEmailErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.ADD_SECONDARY_EMAIL_ERROR, error);
export const addSecondaryEmailSuccessAction = (
  data: AddSecondaryEmailSchema
) => {
  const payload: AddSecondaryEmailSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.ADD_SECONDARY_EMAIL_SUCCESS,
    payload
  };
};

/**
 * BLOCK_OR_UNBLOCK_USER
 * This action is used to either block or unblock a user
 */
export interface BlockOrUnblockUserSchema
  extends UserSchema,
    SideEffectSchema,
    V2ErrorResponseSchema {
  clientIP: string;
}
export const blockOrUnblockUserAction = (uid: string, status: boolean) => {
  return {
    type: CustomerManagementConst.BLOCK_OR_UNBLOCK_USER,
    payload: { uid, status }
  };
};
export const blockOrUnblockUserLoadingAction = () =>
  loadingAction(CustomerManagementConst.BLOCK_OR_UNBLOCK_USER_LOADING);
export const blockOrUnblockUserErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.BLOCK_OR_UNBLOCK_USER_ERROR, error);
export const blockOrUnblockUserSuccessAction = (
  data: BlockOrUnblockUserSchema
) => {
  const payload: BlockOrUnblockUserSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.BLOCK_OR_UNBLOCK_USER_SUCCESS,
    payload
  };
};

/**
 * DELETE_USER
 * This actions is used to delete a user
 */
export interface DeleteUserSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  IsDeleted: boolean;

  clientIP: string;
}
export const deleteUserAction = (uid: string) => {
  return {
    type: CustomerManagementConst.DELETE_USER,
    payload: { uid }
  };
};
export const deleteUserLoadingAction = () =>
  loadingAction(CustomerManagementConst.DELETE_USER_LOADING);
export const deleteUserErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.DELETE_USER_ERROR, error);
export const deleteUserSuccessAction = (data: DeleteUserSchema) => {
  const payload: DeleteUserSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.DELETE_USER_SUCCESS,
    payload
  };
};

/**
 * UNLOCK_USER
 * This actions is used to delete a user
 */
export interface UnlockUserSchema
  extends UserSchema,
    SideEffectSchema,
    V2ErrorResponseSchema {
  IsDeleted: boolean;

  clientIP: string;
}
export const unlockUserAction = (uid: string) => {
  return {
    type: CustomerManagementConst.UNLOCK_USER,
    payload: { uid }
  };
};
export const unlockUserLoadingAction = () =>
  loadingAction(CustomerManagementConst.UNLOCK_USER_LOADING);
export const unlockUserErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.UNLOCK_USER_ERROR, error);
export const unlockUserSuccessAction = (data: UnlockUserSchema) => {
  const payload: UnlockUserSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.UNLOCK_USER_SUCCESS,
    payload
  };
};

/**
 * VERIFY
 * This action is used to verify either the email or phone associated with an account
 */
export interface VerifySchema
  extends UserSchema,
    SideEffectSchema,
    V2ErrorResponseSchema {
  clientIP: string;
}
export interface VerifyArgs {
  uid: string;
  EmailVerified?: boolean;
  PhoneIdVerified?: boolean;
  InvalidateEmail?: boolean;
  InvalidatePhoneID?: boolean;
}
export const verifyAction = (args: VerifyArgs) => {
  return {
    type: CustomerManagementConst.VERIFY,
    payload: args
  };
};
export const verifyLoadingAction = () =>
  loadingAction(CustomerManagementConst.VERIFY_LOADING);
export const verifyErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.VERIFY_ERROR, error);
export const verifySuccessAction = (data: VerifySchema) => {
  const payload: VerifySchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.VERIFY_SUCCESS,
    payload
  };
};

/**
 * RESET_PASSWORD
 * Reset a customer's password
 */
export interface ResetPasswordSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  PasswordHash: string;

  clientIP: string;
}
export const resetPasswordAction = (accountid: string, password: string) => {
  return {
    type: CustomerManagementConst.RESET_PASSWORD,
    payload: { accountid, password }
  };
};
export const resetPasswordLoadingAction = () =>
  loadingAction(CustomerManagementConst.RESET_PASSWORD_LOADING);
export const resetPasswordErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.RESET_PASSWORD_ERROR, error);
export const resetPasswordSuccessAction = (data: ResetPasswordSchema) => {
  const payload: ResetPasswordSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.RESET_PASSWORD_SUCCESS,
    payload
  };
};

/**
 * SEND_RESET_PASSWORD
 * Send a reset password email to the user
 */
export interface ResetAndVarificationUrl {
  ResetPasswordAndVerificationUrl: string;
}

export interface SendResetPasswordSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  ForgotToken: string;
  IdentityProviders: any[] | null;
  clientIP: string;
}
export const sendResetPasswordAction = (
  email: string,
  resetpasswordurl: string,
  template: string,
  username: string
) => {
  return {
    type: CustomerManagementConst.SEND_RESET_PASSWORD,
    payload: { email, resetpasswordurl, template, username }
  };
};
export const sendResetPasswordLoadingAction = () =>
  loadingAction(CustomerManagementConst.SEND_RESET_PASSWORD_LOADING);
export const sendResetPasswordErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.SEND_RESET_PASSWORD_ERROR, error);
export const sendResetPasswordSuccessAction = (
  data: SendResetPasswordSchema
) => {
  const payload: SendResetPasswordSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.SEND_RESET_PASSWORD_SUCCESS,
    payload
  };
};

/**
 * RESEND_VERIFICATION_EMAIL
 * Send a reset password email to the user
 */
export interface ResendEmailSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  IsPosted: boolean;
  clientIP: string;
}
export const resendEmailAction = (
  email: string,
  resendEmailUrl: string,
  template: string
) => {
  return {
    type: CustomerManagementConst.RESEND_EMAIL,
    payload: { email, resendEmailUrl, template }
  };
};
export const resendEmailLoadingAction = () =>
  loadingAction(CustomerManagementConst.RESEND_EMAIL_LOADING);
export const resendEmailErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.RESEND_EMAIL_ERROR, error);
export const resendEmailSuccessAction = (data: ResendEmailSchema) => {
  const payload: ResendEmailSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.RESEND_EMAIL_SUCCESS,
    payload
  };
};

export interface GetUsersIdentitiesSchema extends SideEffectSchema {
  users: IdentitySchema[];
}

export const getUserIdentitiesAction = (user: GetUsersIdentitiesSchema) => {
  return {
    type: CustomerManagementConst.USER_IDENTITIES,
    payload: user
  };
};

/**
 * REMOVE_EMAIL_ADDRESS
 * Remove an email address from a user on Manage Profile
 */
export interface RemoveEmailAddressSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema,
    UserSchema {
  clientIP: string;
}
export interface EmailAddressPayloadSchema {
  uid: string;
  email: string;
}

export const removeEmailAddressAction = (
  payload: EmailAddressPayloadSchema
) => {
  return {
    type: CustomerManagementConst.REMOVE_EMAIL_ADDRESS,
    payload
  };
};

export const removeEmailAddressLoadingAction = () =>
  loadingAction(CustomerManagementConst.REMOVE_EMAIL_ADDRESS_LOADING);

export const removeEmailAddressErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.REMOVE_EMAIL_ADDRESS_ERROR, error);

export const removeEmailAddressSuccessAction = (
  data: RemoveEmailAddressSchema
) => {
  const payload: RemoveEmailAddressSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.REMOVE_EMAIL_ADDRESS_SUCCESS,
    payload
  };
};

/**
 * UPDATE_USER_PROFILE
 * Update the user profile in Manage Profile > Personal Info
 */
export interface UpdateUserProfileSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema,
    UserSchema {
  clientIP: string;
  action?: string;
}
export interface UserProfilePayloadSchema {
  uid: string;
  nullsupport?: boolean;
  [key: string]: any;
}

export const updateUserProfileAction = (payload: UserProfilePayloadSchema) => {
  return {
    type: CustomerManagementConst.UPDATE_USER_PROFILE,
    payload
  };
};

export const updateUserProfileLoadingAction = () =>
  loadingAction(CustomerManagementConst.UPDATE_USER_PROFILE_LOADING);

export const updateUserProfileErrorAction = (error: string, data = {}) =>
  errorAction(CustomerManagementConst.UPDATE_USER_PROFILE_ERROR, error, data);

export const updateUserProfileSuccessAction = (
  data: UpdateUserProfileSchema
) => {
  const payload: UpdateUserProfileSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.UPDATE_USER_PROFILE_SUCCESS,
    payload
  };
};

/**
 * GET_ASSIGNED_ROLE
 * Get the assigned role(s) from the user
 */
export interface GetAssignedRoleSchema extends SideEffectSchema {
  Roles?: string[];
}
export const getAssignedRoleAction = (uid: string) => {
  return {
    type: CustomerManagementConst.GET_ASSIGNED_ROLE,
    payload: uid
  };
};
export const getAssignedRoleLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_ASSIGNED_ROLE_LOADING);
export const getAssignedRoleErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_ASSIGNED_ROLE_ERROR, error);
export const getAssignedRoleSuccessAction = (data: GetAssignedRoleSchema) => {
  const payload: GetAssignedRoleSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_ASSIGNED_ROLE_SUCCESS,
    payload
  };
};

/**
 * ASSIGN_ROLES
 * Assign and unassign roles for a user with their UID
 */
export interface AssignRolesSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  data?: any;
  uid?: string;
  roleData?: {
    roles: any[];
  };
  unassignRoles?: {
    roles: any[];
  };
}
export const assignRolesAction = (roles: AssignRolesSchema) => {
  return {
    type: CustomerManagementConst.ASSIGN_ROLES,
    payload: roles
  };
};
export const assignRolesLoadingAction = () =>
  loadingAction(CustomerManagementConst.ASSIGN_ROLES_LOADING);
export const assignRolesErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.ASSIGN_ROLES_ERROR, error);
export const assignRolesSuccessAction = (data: AssignRolesSchema) => {
  const payload: AssignRolesSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.ASSIGN_ROLES_SUCCESS,
    payload
  };
};

/**
 * UNASSIGN_ROLES
 * Assign and unassign roles for a user with their UID
 */
export interface UnassignRolesSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  data?: any;
  uid: string;
  roleData: {
    roles: string[];
  };
  IsDeleted?: boolean;
}
export const unassignRolesAction = (payload: UnassignRolesSchema) => {
  return {
    type: CustomerManagementConst.UNASSIGN_ROLES,
    payload
  };
};
export const unassignRolesLoadingAction = () =>
  loadingAction(CustomerManagementConst.UNASSIGN_ROLES_LOADING);
export const unassignRolesErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.UNASSIGN_ROLES_ERROR, error);
export const unassignRolesSuccessAction = (data: UnassignRolesSchema) => {
  const payload: UnassignRolesSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.UNASSIGN_ROLES_SUCCESS,
    payload
  };
};

/**
 * GET_CUSTOM_OBJECT_DATA
 * Get all custom object schema information of the site
 */

export interface GetCustomObjectDataSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema,
    APIErrorResponseSchema {
  Data: CustomObjectDataSchema[];
  clientIP: string;
}

export const getCustomObjectDataAction = () => {
  return {
    type: CustomerManagementConst.GET_CUSTOM_OBJECT_DATA
  };
};

export const getCustomObjectDataLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_CUSTOM_OBJECT_DATA_LOADING);

export const getCustomObjectDataErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_CUSTOM_OBJECT_DATA_ERROR, error);

export const getCustomObjectDataSuccessAction = (
  data: GetCustomObjectDataSchema
) => {
  const payload: GetCustomObjectDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_CUSTOM_OBJECT_DATA_SUCCESS,
    payload
  };
};

/**
 * GET_CUSTOM_OBJECTS
 * Get all custom object information for a particular user
 */

export interface GetCustomObjectsSchema extends SideEffectSchema {
  response: any;
}

export interface GetCustomObjectsPayloadSchema {
  customdata: any[];
  uid: string;
}

export const getCustomObjectsAction = (
  payload: GetCustomObjectsPayloadSchema
) => {
  return {
    type: CustomerManagementConst.GET_CUSTOM_OBJECTS,
    payload
  };
};

export const getCustomObjectsLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_CUSTOM_OBJECTS_LOADING);

export const getCustomObjectsErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_CUSTOM_OBJECTS_ERROR, error);

export const getCustomObjectsSuccessAction = (data: GetCustomObjectsSchema) => {
  const payload: GetCustomObjectsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_CUSTOM_OBJECTS_SUCCESS,
    payload
  };
};

/* GET_ROLE_CONTEXT
 * Get the role context from the user
 */
export interface GetRoleContextSchema extends SideEffectSchema {
  Data?: RoleContextSchema[];
}
export interface RoleContextSchema {
  AdditionalPermissions: string[];
  Context: string;
  Expiration?: string | null;
  Roles: string[];
}
export const getRoleContextAction = (uid: string) => {
  return {
    type: CustomerManagementConst.GET_ROLE_CONTEXT,
    payload: uid
  };
};
export const getRoleContextLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_ROLE_CONTEXT_LOADING);
export const getRoleContextErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_ROLE_CONTEXT_ERROR, error);
export const getRoleContextSuccessAction = (data: GetRoleContextSchema) => {
  const payload: GetRoleContextSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_ROLE_CONTEXT_SUCCESS,
    payload
  };
};

/**
 * ADD_ROLE_CONTEXT
 * Add a new role context for the customer
 */
export interface ContextDataSchema {
  rolecontext: {
    context: string;
    roles: string[];
    additionalpermissions: string[];
  }[];
}
export interface AddRoleContextSchema extends SideEffectSchema {
  Data: RoleContextSchema[];
  clientIP?: string;
}
export const addRoleContextAction = (
  uid: string,
  contextData: ContextDataSchema
) => {
  return {
    type: CustomerManagementConst.ADD_ROLE_CONTEXT,
    payload: { uid, contextData }
  };
};
export const addRoleContextLoadingAction = () =>
  loadingAction(CustomerManagementConst.ADD_ROLE_CONTEXT_LOADING);
export const addRoleContextErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.ADD_ROLE_CONTEXT_ERROR, error);
export const addRoleContextSuccessAction = (data: AddRoleContextSchema) => {
  const payload: AddRoleContextSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.ADD_ROLE_CONTEXT_SUCCESS,
    payload
  };
};

/**
 * DELETE_ROLE_CONTEXT
 * Add a new role context for the customer
 */
export interface DeleteRoleContextSchema extends SideEffectSchema {
  IsDeleted: boolean;
  clientIP: string;
}
export const deleteRoleContextAction = (
  uid: string,
  rolecontextname: string
) => {
  return {
    type: CustomerManagementConst.DELETE_ROLE_CONTEXT,
    payload: { uid, rolecontextname }
  };
};
export const deleteRoleContextLoadingAction = () =>
  loadingAction(CustomerManagementConst.DELETE_ROLE_CONTEXT_LOADING);
export const deleteRoleContextErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.DELETE_ROLE_CONTEXT_ERROR, error);
export const deleteRoleContextSuccessAction = (
  data: DeleteRoleContextSchema
) => {
  const payload: DeleteRoleContextSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.DELETE_ROLE_CONTEXT_SUCCESS,
    payload
  };
};

/**
 * GET_CONSENT_LOGS
 */

/**
 * GET_USERS
 * The respone schema is same as GetSearchUsersSchema
 */

interface ConsentLog {
  ConsentId: string;
  Event: "Withdrawn" | "Accepted" | "Not Accepted";
  LastModifiedDate: string;
}

export interface GetConsentLogsResponse
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  data: ConsentLog[];
}

export const getConsentLogsAction = (uid: string) => {
  return {
    type: CustomerManagementConst.GET_CONSENT_LOGS,
    payload: { uid }
  };
};

export const getConsentLogsLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_CONSENT_LOGS_LOADING);
export const getConsentLogsErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_CONSENT_LOGS_ERROR, error);

export const getConsentLogsSuccessAction = (data: GetConsentLogsResponse) => {
  const payload: GetConsentLogsResponse = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_CONSENT_LOGS_SUCCESS,
    payload
  };
};

/**
 * GET_CF_SCHEMA
 */

export interface GetCustomFieldsSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  Data?: RegistarationFieldSchema[];
}
export const getCustomFieldsAction = () => {
  return {
    type: CustomerManagementConst.GET_CUSTOM_FIELDS
  };
};
export const getCustomFieldsLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_CUSTOM_FIELDS_LOADING);
export const getCustomFieldsErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_CUSTOM_FIELDS_ERROR, error);
export const getCustomFieldsSuccessAction = (data: GetCustomFieldsSchema) => {
  const payload: GetCustomFieldsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_CUSTOM_FIELDS_SUCCESS,
    payload
  };
};

/**
 * GET_ES_FIELDS
 */

export interface GetESFieldsSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  Data: {
    Security: {
      LastPasswordChangeDate: string;
      PasswordExpirationDate: string;
      LastPasswordChangeToken: string;
      IsDeleted: boolean;
      DisableLogin: boolean;
      FailedLoginAttempt: 0;
      SecurityQuestionFailedResetPasswordAttempts: 0;
    };
    Insights: {
      SignupDate: string;
      CreatedDate: string;
      FirstLogin: boolean;
      RegistrationSource: string;
      SignupLog: {
        Host: string;
        IP: string;
        UserAgent: string;
      };
    };
  };
}

export const getESFieldsAction = (uid: string) => {
  return {
    type: CustomerManagementConst.GET_ES_FIELDS,
    payload: { uid }
  };
};
export const getESFieldsLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_ES_FIELDS_LOADING);
export const getESFieldsErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_ES_FIELDS_ERROR, error);
export const getESFieldsSuccessAction = (data: GetESFieldsSchema) => {
  const payload: GetESFieldsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_ES_FIELDS_SUCCESS,
    payload
  };
};

export interface GetPrivacyPolicyHistorySchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  Uid: string;
  Current: PrivacyPolicySchema;
  History: PrivacyPolicySchema[];
}
export interface GetTwoFASettingsSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {}
export const getPrivacyPolicyHistoryAction = (uid: string) => {
  return {
    type: CustomerManagementConst.GET_USER_PRIVACY_POLICY,
    payload: { uid }
  };
};
export const getPrivacyPolicyHistoryLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_USER_PRIVACY_POLICY_LOADING);
export const getPrivacyPolicyHistoryErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_USER_PRIVACY_POLICY_ERROR, error);
export const getPrivacyPolicyHistorySuccessAction = (
  data: GetPrivacyPolicyHistorySchema
) => {
  const payload: GetPrivacyPolicyHistorySchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_USER_PRIVACY_POLICY_SUCCESS,
    payload
  };
};

export const getTwoFASettingsAction = (uid: string) => {
  return {
    type: CustomerManagementConst.GET_USER_2FA_SETTING,
    payload: { uid }
  };
};
export const getTwoFASettingsLoadingAction = () =>
  loadingAction(CustomerManagementConst.GET_USER_2FA_SETTING_LOADING);
export const getTwoFASettingsErrorAction = (error: string) =>
  errorAction(CustomerManagementConst.GET_USER_2FA_SETTING_ERROR, error);
export const getTwoFASettingsSuccessAction = (data: GetTwoFASettingsSchema) => {
  const payload: GetTwoFASettingsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomerManagementConst.GET_USER_2FA_SETTING_SUCCESS,
    payload
  };
};

import { ActionSchema } from "../actions";
import {
  GetSearchUsersSchema,
  EnabledFieldSettingsSchema,
  AddUserSchema,
  CustomerManagementConst,
  TotalUsersSchema,
  EmailTemplatesSchema,
  GetUserIdentitySchema,
  PostSessionsSchema,
  AddSecondaryEmailSchema,
  BlockOrUnblockUserSchema,
  DeleteUserSchema,
  VerifySchema,
  ResetPasswordSchema,
  RemoveEmailAddressSchema,
  UpdateUserProfileSchema,
  GetAssignedRoleSchema,
  AssignRolesSchema,
  UnassignRolesSchema,
  GetCustomObjectsSchema,
  GetCustomObjectDataSchema,
  GetRoleContextSchema,
  AddRoleContextSchema,
  DeleteRoleContextSchema,
  UnlockUserSchema,
  ResendEmailSchema,
  GetConsentLogsResponse,
  GetCustomFieldsSchema,
  GetESFieldsSchema,
  GetPrivacyPolicyHistorySchema,
  GetTwoFASettingsSchema,
  GetUsersIdentitiesSchema,
  AllEmailTemplatesSchema,
  GetConsumerAuditLogsSchema
} from "../actions/customerManagementActions";
import { combineReducers } from "redux";
import { SendResetPasswordSchema } from "../actions/teamActions";

const userBySearchFieldsReducer = (
  state = {},
  action: ActionSchema<GetSearchUsersSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_USER_BY_SEARCHFIELDS_LOADING:
    case CustomerManagementConst.GET_USER_BY_SEARCHFIELDS_SUCCESS:
    case CustomerManagementConst.GET_USER_BY_SEARCHFIELDS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const usersReducer = (
  state = {},
  action: ActionSchema<GetSearchUsersSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_USERS_LOADING:
    case CustomerManagementConst.GET_USERS_SUCCESS:
    case CustomerManagementConst.GET_USERS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const consumerAuditLogsReducer = (
  state = {},
  action: ActionSchema<GetConsumerAuditLogsSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS_LOADING:
    case CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS_SUCCESS:
    case CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const consumerAuditLogsByUIDReducer = (
  state = {},
  action: ActionSchema<GetConsumerAuditLogsSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS_BY_UID_LOADING:
    case CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS_BY_UID_SUCCESS:
    case CustomerManagementConst.GET_CONSUMER_AUDIT_LOGS_BY_UID_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const enabledFieldSettingsReducer = (
  state = {},
  action: ActionSchema<EnabledFieldSettingsSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.LOAD_ENABLED_FIELDS_SETTINGS_LOADING:
    case CustomerManagementConst.LOAD_ENABLED_FIELDS_SETTINGS_SUCCESS:
    case CustomerManagementConst.LOAD_ENABLED_FIELDS_SETTINGS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const addUserReducer = (state = {}, action: ActionSchema<AddUserSchema>) => {
  switch (action.type) {
    case CustomerManagementConst.ADD_USER_LOADING:
    case CustomerManagementConst.ADD_USER_SUCCESS:
    case CustomerManagementConst.ADD_USER_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const totalUsersReducer = (
  state = {},
  action: ActionSchema<TotalUsersSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.UPDATE_TOTAL_USERS:
    case CustomerManagementConst.GET_TOTAL_USERS_LOADING:
    case CustomerManagementConst.GET_TOTAL_USERS_SUCCESS:
    case CustomerManagementConst.GET_TOTAL_USERS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const emailTemplatesReducer = (
  state = {},
  action: ActionSchema<EmailTemplatesSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_EMAIL_TEMPLATES_LOADING:
    case CustomerManagementConst.GET_EMAIL_TEMPLATES_SUCCESS:
    case CustomerManagementConst.GET_EMAIL_TEMPLATES_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const allEmailTemplatesReducer = (
  state = {},
  action: ActionSchema<AllEmailTemplatesSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_ALL_EMAIL_TEMPLATES_LOADING:
    case CustomerManagementConst.GET_ALL_EMAIL_TEMPLATES_SUCCESS:
    case CustomerManagementConst.GET_ALL_EMAIL_TEMPLATES_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const userIdentityReducer = (
  state = {},
  action: ActionSchema<GetUserIdentitySchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_USER_IDENTITY_LOADING:
    case CustomerManagementConst.GET_USER_IDENTITY_SUCCESS:
    case CustomerManagementConst.GET_USER_IDENTITY_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const postSessionsReducer = (
  state = {},
  action: ActionSchema<PostSessionsSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.POST_SESSIONS_LOADING:
    case CustomerManagementConst.POST_SESSIONS_SUCCESS:
    case CustomerManagementConst.POST_SESSIONS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const addSecondaryEmailReducer = (
  state = {},
  action: ActionSchema<AddSecondaryEmailSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.ADD_SECONDARY_EMAIL_LOADING:
    case CustomerManagementConst.ADD_SECONDARY_EMAIL_SUCCESS:
    case CustomerManagementConst.ADD_SECONDARY_EMAIL_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const blockOrUnblockUserReducer = (
  state = {},
  action: ActionSchema<BlockOrUnblockUserSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.BLOCK_OR_UNBLOCK_USER_LOADING:
    case CustomerManagementConst.BLOCK_OR_UNBLOCK_USER_SUCCESS:
    case CustomerManagementConst.BLOCK_OR_UNBLOCK_USER_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const deleteUserReducer = (
  state = {},
  action: ActionSchema<DeleteUserSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.DELETE_USER_LOADING:
    case CustomerManagementConst.DELETE_USER_SUCCESS:
    case CustomerManagementConst.DELETE_USER_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const unlockUserReducer = (
  state = {},
  action: ActionSchema<UnlockUserSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.UNLOCK_USER_LOADING:
    case CustomerManagementConst.UNLOCK_USER_SUCCESS:
    case CustomerManagementConst.UNLOCK_USER_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const verifyReducer = (state = {}, action: ActionSchema<VerifySchema>) => {
  switch (action.type) {
    case CustomerManagementConst.VERIFY_LOADING:
    case CustomerManagementConst.VERIFY_SUCCESS:
    case CustomerManagementConst.VERIFY_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const resetPasswordReducer = (
  state = {},
  action: ActionSchema<ResetPasswordSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.RESET_PASSWORD_LOADING:
    case CustomerManagementConst.RESET_PASSWORD_SUCCESS:
    case CustomerManagementConst.RESET_PASSWORD_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const sendResetPasswordReducer = (
  state = {},
  action: ActionSchema<SendResetPasswordSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.SEND_RESET_PASSWORD_LOADING:
    case CustomerManagementConst.SEND_RESET_PASSWORD_SUCCESS:
    case CustomerManagementConst.SEND_RESET_PASSWORD_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const resendEmailReducer = (
  state = {},
  action: ActionSchema<ResendEmailSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.RESEND_EMAIL_LOADING:
    case CustomerManagementConst.RESEND_EMAIL_SUCCESS:
    case CustomerManagementConst.RESEND_EMAIL_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getUserIdentitesReducer = (
  state = {},
  action: ActionSchema<GetUsersIdentitiesSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.USER_IDENTITIES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const removeEmailAddressReducer = (
  state = {},
  action: ActionSchema<RemoveEmailAddressSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.REMOVE_EMAIL_ADDRESS_LOADING:
    case CustomerManagementConst.REMOVE_EMAIL_ADDRESS_SUCCESS:
    case CustomerManagementConst.REMOVE_EMAIL_ADDRESS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const updateUserProfileReducer = (
  state = {},
  action: ActionSchema<UpdateUserProfileSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.UPDATE_USER_PROFILE_LOADING:
    case CustomerManagementConst.UPDATE_USER_PROFILE_SUCCESS:
    case CustomerManagementConst.UPDATE_USER_PROFILE_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getAssignedRoleReducer = (
  state = {},
  action: ActionSchema<GetAssignedRoleSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_ASSIGNED_ROLE_LOADING:
    case CustomerManagementConst.GET_ASSIGNED_ROLE_SUCCESS:
    case CustomerManagementConst.GET_ASSIGNED_ROLE_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const assignRolesReducer = (
  state = {},
  action: ActionSchema<AssignRolesSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.ASSIGN_ROLES_LOADING:
    case CustomerManagementConst.ASSIGN_ROLES_SUCCESS:
    case CustomerManagementConst.ASSIGN_ROLES_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const unassignRolesReducer = (
  state = {},
  action: ActionSchema<UnassignRolesSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.UNASSIGN_ROLES_LOADING:
    case CustomerManagementConst.UNASSIGN_ROLES_SUCCESS:
    case CustomerManagementConst.UNASSIGN_ROLES_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getCustomObjectDataReducer = (
  state = {},
  action: ActionSchema<GetCustomObjectDataSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_CUSTOM_OBJECT_DATA_LOADING:
    case CustomerManagementConst.GET_CUSTOM_OBJECT_DATA_SUCCESS:
    case CustomerManagementConst.GET_CUSTOM_OBJECT_DATA_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getCustomObjectsReducer = (
  state = {},
  action: ActionSchema<GetCustomObjectsSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_CUSTOM_OBJECTS_LOADING:
    case CustomerManagementConst.GET_CUSTOM_OBJECTS_SUCCESS:
    case CustomerManagementConst.GET_CUSTOM_OBJECTS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getRoleContextReducer = (
  state = {},
  action: ActionSchema<GetRoleContextSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_ROLE_CONTEXT_LOADING:
    case CustomerManagementConst.GET_ROLE_CONTEXT_SUCCESS:
    case CustomerManagementConst.GET_ROLE_CONTEXT_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const addRoleContextReducer = (
  state = {},
  action: ActionSchema<AddRoleContextSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.ADD_ROLE_CONTEXT_LOADING:
    case CustomerManagementConst.ADD_ROLE_CONTEXT_SUCCESS:
    case CustomerManagementConst.ADD_ROLE_CONTEXT_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const deleteRoleContextReducer = (
  state = {},
  action: ActionSchema<DeleteRoleContextSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.DELETE_ROLE_CONTEXT_LOADING:
    case CustomerManagementConst.DELETE_ROLE_CONTEXT_SUCCESS:
    case CustomerManagementConst.DELETE_ROLE_CONTEXT_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getConsentLogsReducer = (
  state = {},
  action: ActionSchema<GetConsentLogsResponse>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_CONSENT_LOGS_LOADING:
    case CustomerManagementConst.GET_CONSENT_LOGS_SUCCESS:
    case CustomerManagementConst.GET_CONSENT_LOGS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getCustomFieldsReducer = (
  state = {},
  action: ActionSchema<GetCustomFieldsSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_CUSTOM_FIELDS_LOADING:
    case CustomerManagementConst.GET_CUSTOM_FIELDS_SUCCESS:
    case CustomerManagementConst.GET_CUSTOM_FIELDS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getESFieldsReducer = (
  state = {},
  action: ActionSchema<GetESFieldsSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_ES_FIELDS_LOADING:
    case CustomerManagementConst.GET_ES_FIELDS_SUCCESS:
    case CustomerManagementConst.GET_ES_FIELDS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getPrivacyPolicyHistoryReducer = (
  state = {},
  action: ActionSchema<GetPrivacyPolicyHistorySchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_USER_PRIVACY_POLICY_LOADING:
    case CustomerManagementConst.GET_USER_PRIVACY_POLICY_SUCCESS:
    case CustomerManagementConst.GET_USER_PRIVACY_POLICY_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const getTwoFASettingsReducer = (
  state = {},
  action: ActionSchema<GetTwoFASettingsSchema>
) => {
  switch (action.type) {
    case CustomerManagementConst.GET_USER_2FA_SETTING_LOADING:
    case CustomerManagementConst.GET_USER_2FA_SETTING_SUCCESS:
    case CustomerManagementConst.GET_USER_2FA_SETTING_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
// Combine Reducers
const customerManagementReducer = combineReducers({
  userBySearchFields: userBySearchFieldsReducer,
  users: usersReducer,
  consumerAuditLogs: consumerAuditLogsReducer,
  consumerAuditLogsByUID: consumerAuditLogsByUIDReducer,
  enabledFieldSettings: enabledFieldSettingsReducer,
  addUserResponse: addUserReducer,
  totalUsers: totalUsersReducer,
  emailTemplates: emailTemplatesReducer,
  allEmailTemplates: allEmailTemplatesReducer,
  userIdentity: userIdentityReducer,
  postSessions: postSessionsReducer,
  addSecondaryEmail: addSecondaryEmailReducer,
  blockOrUnblockUser: blockOrUnblockUserReducer,
  deleteUser: deleteUserReducer,
  unlockUser: unlockUserReducer,
  verify: verifyReducer,
  resetPassword: resetPasswordReducer,
  sendResetPassword: sendResetPasswordReducer,
  resendEmail: resendEmailReducer,
  userIdentities: getUserIdentitesReducer,
  removeEmailAddress: removeEmailAddressReducer,
  updateUserProfile: updateUserProfileReducer,
  getAssignedRole: getAssignedRoleReducer,
  assignRoles: assignRolesReducer,
  unassignRoles: unassignRolesReducer,
  getCustomObjectData: getCustomObjectDataReducer,
  getCustomObjects: getCustomObjectsReducer,
  getRoleContext: getRoleContextReducer,
  addRoleContext: addRoleContextReducer,
  deleteRoleContext: deleteRoleContextReducer,
  getConsentLogs: getConsentLogsReducer,
  getCustomFields: getCustomFieldsReducer,
  getESFields: getESFieldsReducer,
  getPrivacyPolicyHistory: getPrivacyPolicyHistoryReducer,
  getTwoFactorSettings: getTwoFASettingsReducer
});

// Combine schemas
export interface CustomerManagementSchema {
  userBySearchFields: GetSearchUsersSchema;
  users: GetSearchUsersSchema;
  consumerAuditLogs: GetConsumerAuditLogsSchema;
  consumerAuditLogsByUID: GetConsumerAuditLogsSchema;
  enabledFieldSettings: EnabledFieldSettingsSchema;
  addUserResponse: AddUserSchema;
  totalUsers: TotalUsersSchema;
  emailTemplates: EmailTemplatesSchema;
  allEmailTemplates: AllEmailTemplatesSchema;
  userIdentity: GetUserIdentitySchema;
  userIdentities: GetUsersIdentitiesSchema;
  postSessions: PostSessionsSchema;
  addSecondaryEmail: AddSecondaryEmailSchema;
  blockOrUnblockUser: BlockOrUnblockUserSchema;
  deleteUser: DeleteUserSchema;
  unlockUser: UnlockUserSchema;
  verify: VerifySchema;
  resetPassword: ResetPasswordSchema;
  sendResetPassword: SendResetPasswordSchema;
  resendEmail: ResendEmailSchema;
  removeEmailAddress: RemoveEmailAddressSchema;
  updateUserProfile: UpdateUserProfileSchema;
  getAssignedRole: GetAssignedRoleSchema;
  assignRoles: AssignRolesSchema;
  unassignRoles: UnassignRolesSchema;
  getCustomObjectData: GetCustomObjectDataSchema;
  getCustomObjects: GetCustomObjectsSchema;
  getRoleContext: GetRoleContextSchema;
  addRoleContext: AddRoleContextSchema;
  deleteRoleContext: DeleteRoleContextSchema;
  getConsentLogs: GetConsentLogsResponse;
  getCustomFields: GetCustomFieldsSchema;
  getESFields: GetESFieldsSchema;
  getPrivacyPolicyHistory: GetPrivacyPolicyHistorySchema;
  getTwoFactorSettings: GetTwoFASettingsSchema;
}

export default customerManagementReducer;

import {
  SideEffectSchema,
  successSideEffectState,
  errorAction,
  loadingAction,
  generateConst
} from ".";
// Constants
export const OrchestrationConst = generateConst("WORKFLOW", [
  "GET_ALL_WORKFLOWS"
]);

// Schema

export interface GetWorkflowsSchema extends SideEffectSchema {
  Data?: any;
  ErrorCode?: number;
  ErrorDescription?: string;
  ErrorMessage?: string;
}

export const getAllWorkflowsAction = () => {
  return {
    type: OrchestrationConst.GET_ALL_WORKFLOWS,
    payload: {}
  };
};

export const getAllWorkflowsLoadingAction = () =>
  loadingAction(OrchestrationConst.GET_ALL_WORKFLOWS_LOADING);

export const getAllWorkflowsErrorAction = (error: string) =>
  errorAction(OrchestrationConst.GET_ALL_WORKFLOWS_ERROR, error);

export const getAllWorkflowsSuccessAction = (data: GetWorkflowsSchema) => {
  const payload: GetWorkflowsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: OrchestrationConst.GET_ALL_WORKFLOWS_SUCCESS,
    payload
  };
};

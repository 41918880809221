import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import ReactDOM from "react-dom";
// Redux
import {
  initAuthLoginAction,
  initAuthSamlLoginAction,
  AuthLoginSchema,
  AuthSamlLoginSchema,
  AuthConfigSchema,
  AuthTokenSchema
} from "../../redux/actions/authActions";
import { RootSchema } from "../../redux/reducers";
import { ObjectSchema } from "../../redux/actions";

import { httpPost, httpGet } from "../../services";
// I18N
import { LanguageSchema } from "../../i18n";
// localAppStore
import { localAppStore } from "../../utils";
// Components
import Toaster, { Toastify } from "../../components/Toaster";
import Loader from "../../components/Loader";
import CenterDiv from "../../components/CenterDiv";
import {
  GetAccountThemeCustomizationSchema,
  getAccountThemeCustomizationAction
} from "../../redux/actions/accountActions";
import { AppBasicInfoSchema } from "../../redux/actions/authActions";
import { SiteResponseSchema } from "../../redux/actions/authActions";
import { boolean } from "yup";
interface QuerySchema extends ObjectSchema<string> {
  //token: string;
  samlconfig: string;
}

export interface LoginProps extends RouteComponentProps {
  samlCreds: AuthSamlLoginSchema;
  loginDomain: string;
  IOLoginUrl: string;
  IOLogoutUrl: string;
  currentAppinfo: SiteResponseSchema;
  authLogin: AuthLoginSchema;
  appBasicInfo: AppBasicInfoSchema;
  fetchAccountTheme: (dir: string) => void;
  accountThemeCustomization: GetAccountThemeCustomizationSchema;
  initTokenLogin: (
    token: string,
    appName?: string,
    domain?: string,
    dataCenter?: string,
    plan?: string
  ) => void;
  initSamlLogin: (samlconfig: string) => void;
  i18n: LanguageSchema;
  authConfig: AuthConfigSchema;
  authToken: AuthTokenSchema;
}

class Login extends React.Component<LoginProps> {
  state = {
    themeEnambel: false
  };
  parseQueryParams(queryString: string): QuerySchema {
    const qsToArray = queryString.substr(1).split("&"); // Array of key values with = delimeter

    const qsToObject: QuerySchema = {
      samlconfig: "",
      appName: "",
      plan: ""
    };

    for (let i = 0; i < qsToArray.length; i++) {
      let query = qsToArray[i].split("=");
      qsToObject[query[0]] = query[1];
    }
    return qsToObject;
  }
  getBasicSettings = async () => {
    return await httpGet("/auth/basicsettings");
  };
  getPublishCSS = async data => {
    return await httpPost("/account/theme/publish/css", {}, data);
  };
  _loginCheck = token => {
    const { history, initSamlLogin } = this.props;
    const {
      loginDomain,
      currentAppinfo,
      IOLoginUrl,
      appBasicInfo,
      authConfig,
      samlCreds
    } = this.props;
    const {
      samlconfig,
      appName,
      domain,
      dataCenter,
      plan
    } = this.parseQueryParams(history.location.search);
    if (token && appName && domain && dataCenter) {
      this.props.initTokenLogin(token, appName, domain, dataCenter, plan);
    } else if (token) {
      this._initTokenLogin(token, appName);
    } else if (samlconfig) {
      initSamlLogin(samlconfig);
    } else if (!token && !samlconfig && authConfig.isSuccess && loginDomain) {
      let url = IOLoginUrl
        ? IOLoginUrl
        : `https://${loginDomain}/auth.aspx?return_url=${location.origin}/login`;
      if (url && appBasicInfo.IsDashboardCustomizationEnabled) {
        url += encodeURIComponent("&appName=" + currentAppinfo.AppName);
        location.href = url;
      }
      if (url) {
        location.href = url;
      }
    }
  };
  componentDidUpdate(pProps: LoginProps) {
    const {
      authLogin,
      samlCreds,
      history,
      appBasicInfo,
      accountThemeCustomization,
      authToken,
      loginDomain,
      currentAppinfo,
      i18n
    } = this.props;

    if (pProps.samlCreds.isLoading && !this.props.samlCreds.isLoading) {
      if (samlCreds.isSuccess) {
        if (samlCreds.domain && samlCreds.apikey) {
          let url = `https://${samlCreds.domain}RequestHandlor.aspx?apikey=${samlCreds.apikey}&provider=${samlCreds.samlProvider}&is_access_token=true&scope=&callbacktype=&disablesignup=&callback=https://${loginDomain}/auth.aspx`;
          location.href = url;
          // return null;
        }
      } else {
        Toastify("error", i18n.MSG_SAML_NOT_CONFIGURED);
      }
    }
    if (authLogin.isSuccess || samlCreds.isSuccess) {
      if (
        appBasicInfo.IsDashboardCustomizationEnabled &&
        (accountThemeCustomization.isSuccess || accountThemeCustomization.Css)
      ) {
        if (
          Array.isArray(accountThemeCustomization.Css) &&
          accountThemeCustomization.Css.length
        ) {
          this.getPublishCSS({ url: accountThemeCustomization.Css[0] })
            .then(css => {
              if (css.error === "ok" && css.data !== "") {
                ReactDOM.render(
                  <style type="text/css">{css.data}</style>,
                  document.getElementById("global-root-css")
                );
              }
            })
            .catch(err => {})
            .finally(() => {
              localStorage.setItem("lr-x-dashboard", "true");
              history.push("/dashboard");
            });
        } else {
          localStorage.setItem("lr-x-dashboard", "true");
          history.push("/dashboard");
        }
      } else if (
        Object.keys(appBasicInfo).length &&
        Object.keys(accountThemeCustomization).length
      ) {
        localStorage.setItem("lr-x-dashboard", "true");
        history.push("/dashboard");
      }
    } else if (authToken.token) {
      const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
      const token = authToken.token;
      if (isLoggedIn === false) {
        this._loginCheck(token);
      }
    }
  }
  componentDidMount() {
    const { history } = this.props;
    const { authToken } = this.props;
    const token = authToken.token;
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    if (isLoggedIn === false) {
      this._loginCheck(token);
    } else {
      if (
        this.props.accountThemeCustomization.isSuccess ||
        this.props.accountThemeCustomization.Css
      ) {
        localStorage.setItem("lr-x-dashboard", "true");
        if (
          this.props.accountThemeCustomization.Images &&
          this.props.accountThemeCustomization.Images.length
        )
          localStorage.setItem(
            "logoPath",
            this.props.accountThemeCustomization.Images[0]
          );
        setTimeout(() => {
          history.push("/dashboard");
        }, 10);
      } else {
        localStorage.setItem("lr-x-dashboard", "true");
        history.push("/dashboard");
      }
    }
  }

  render() {
    const {
      authLogin,
      i18n,
      appBasicInfo,
      loginDomain,
      currentAppinfo,
      authToken
    } = this.props;
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    if (authToken.token && isLoggedIn) {
      localStorage.setItem("LRTokenKey", authToken.token);
    }
    if (authLogin.isLoading) {
      return (
        <div className="lr-model-backdrop-wrap">
          <CenterDiv>
            <Loader message={i18n.PLEASE_WAIT} />;
          </CenterDiv>
        </div>
      );
    } else if (authLogin.isError) {
      if (loginDomain) {
        let url = `https://${loginDomain}/auth.aspx?action=logout&return_url=https://${loginDomain}/auth.aspx?return_url=${location.origin}/login`;
        if (appBasicInfo.IsDashboardCustomizationEnabled) {
          url += encodeURIComponent("?&appName=" + currentAppinfo.AppName);
        }
        setTimeout(function() {
          location.href = url;
        }, 2000);
      }
      Toastify("error", i18n.VALID_USER_MESSAGE);
      return <Toaster />;
    }
    return null;
  }

  _initTokenLogin = (
    token: string,
    appName: string,
    domain?: string,
    dataCenter?: string,
    plan?: string
  ) => {
    const { initTokenLogin } = this.props;
    if (localStorage.getItem("isLoggedIn") !== "true") {
      initTokenLogin(token, appName, domain, dataCenter, plan);
    }
  };
}

const mapStateToProps = (state: RootSchema) => {
  return {
    authLogin: state.auth.authLogin,
    loginDomain: state.auth.authConfig.domain,
    IOLoginUrl: state.auth.authConfig.IOLoginUrl,
    IOLogoutUrl: state.auth.authConfig.IOLogoutUrl,
    samlCreds: state.auth.authSamlLogin,
    i18n: state.appState.i18n.languages,
    appBasicInfo: state.auth.appBasicInfo,
    accountThemeCustomization: state.account.accountThemeCustomization,
    currentAppinfo: state.auth.currentAppinfo,
    authConfig: state.auth.authConfig,
    authToken: state.auth.tokenData
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    initTokenLogin: (
      token: string,
      appName?: string,
      domain?: string,
      dataCenter?: string,
      plan?: string
    ) =>
      dispatch(initAuthLoginAction(token, appName, domain, dataCenter, plan)),
    initSamlLogin: (samlconfig: string) =>
      dispatch(initAuthSamlLoginAction(samlconfig)),
    fetchAccountTheme: (args: string) =>
      dispatch(getAccountThemeCustomizationAction(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<LoginProps>(Login));

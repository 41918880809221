import { combineReducers } from "redux";
import { ActionSchema } from "../actions";
import {
  GetWorkflowsSchema,
  OrchestrationConst
} from "../actions/orchestrationActions";

const getAllWorkflowsReducer = (
  state = {},
  action: ActionSchema<GetWorkflowsSchema>
) => {
  switch (action.type) {
    case OrchestrationConst.GET_ALL_WORKFLOWS_SUCCESS:
    case OrchestrationConst.GET_ALL_WORKFLOWS_LOADING:
    case OrchestrationConst.GET_ALL_WORKFLOWS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export interface OrchestrationSchema {
  allWorkflows: GetWorkflowsSchema;
}

const orchestrationReducer = combineReducers({
  allWorkflows: getAllWorkflowsReducer
});

export default orchestrationReducer;

import { call, put, take, select } from "redux-saga/effects";
import { LanguageSchema } from "../../i18n";
import { httpDelete, httpGet, httpPost, httpPut } from "../../services";
import { showLoadingAction } from "../actions/appStateActions";
import {
  AddMobileAppsSchema,
  addMobileAppsSottErrorAction,
  addMobileAppsSottLoadingAction,
  addMobileAppsSottSuccessAction,
  addWebAppsErrorAction,
  addWebAppsLoadingAction,
  addWebAppsSuccessAction,
  CustomDomainConst,
  CustomDomainSchema,
  CustomRegistrationField,
  DeleteMobileAppsSchema,
  DeploymentConst,
  getCustomDomainErrorAction,
  getCustomDomainLoadingAction,
  getCustomDomainSuccessAction,
  getCustomFieldErrorAction,
  getCustomFieldLoadingAction,
  getCustomFieldSuccessAction,
  getHostedPageErrorAction,
  getHostedPageLoadingAction,
  GetHostedPageSchema,
  getHostedPageSuccessAction,
  resetHostedPageErrorAction,
  resetHostedPageLoadingAction,
  resetHostedPageSuccessAction,
  fetchHostedPageUrlErrorAction,
  fetchHostedPageUrlLoadingAction,
  fetchHostedPageUrlSuccessAction,
  getMobileAppsSottErrorAction,
  getMobileAppsSottLoadingAction,
  getMobileAppsSottSuccessAction,
  getPlatformFieldErrorAction,
  getPlatformFieldLoadingAction,
  getPlatformSuccessAction,
  getRegistrationFieldErrorAction,
  getRegistrationFieldLoadingAction,
  getRegistrationFieldSuccessAction,
  getSMTPSettingsErrorAction,
  getSMTPSettingsLoadingAction,
  getSMTPSettingsSuccessAction,
  getWebAppsErrorAction,
  getWebAppsLoadingAction,
  getWebAppsSuccessAction,
  HostedPagesSchema,
  ResetHostedPageResponseSchema,
  FetchHostedPageUrlResponseSchema,
  JSWidgetsConst,
  MobileAppsConst,
  MobileAppsSchema,
  postPlatformFieldErrorAction,
  postPlatformFieldLoadingAction,
  postPlatformSuccessAction,
  ResetSMTPSettingResponseSchema,
  resetSMTPSettingsErrorAction,
  resetSMTPSettingsLoadingAction,
  resetSMTPSettingsSuccessAction,
  revokeallMobileAppsSottErrorAction,
  revokeallMobileAppsSottLoadingAction,
  revokeallMobileAppsSottSuccessAction,
  revokeMobileAppsSottErrorAction,
  revokeMobileAppsSottLoadingAction,
  revokeMobileAppsSottSuccessAction,
  saveHostedPageErrorAction,
  saveHostedPageLoadingAction,
  saveHostedPageSuccessAction,
  saveJSWidgetsAdvanceOptionsErrorAction,
  saveJSWidgetsAdvanceOptionsLoadingAction,
  saveJSWidgetsAdvanceOptionsSuccessAction,
  saveJSWidgetsOptionalCaptchaErrorAction,
  saveJSWidgetsOptionalCaptchaLoadingAction,
  saveJSWidgetsOptionalCaptchaSuccessAction,
  SaveSMTPSettingSchema,
  saveSMTPSettingsErrorAction,
  saveSMTPSettingsLoadingAction,
  saveSMTPSettingsSuccessAction,
  SMTPSettingSchema,
  SMTPSettingsConst,
  updateHostedPageErrorAction,
  updateHostedPageLoadingAction,
  updateHostedPageSuccessAction,
  updatePropsDataSchemaSuccessAction,
  VerifySMTPSettingResponseSchema,
  VerifySMTPSettingSchema,
  verifySMTPSettingsErrorAction,
  verifySMTPSettingsLoadingAction,
  verifySMTPSettingsSuccessAction,
  WebAppsConst,
  WebAppsSchema,
  getIEFLoadingAction,
  getIEFErrorAction,
  getIEFSuccessAction,
  getIEFByTypeAndThemeLoadingAction,
  getIEFByTypeAndThemeErrorAction,
  getIEFByTypeAndThemeSuccessAction,
  GetIEFByTypeAndThemeArgs,
  updateIEFLoadingAction,
  updateIEFErrorAction,
  updateIEFSuccessAction,
  IEFSchema,
  MultiIEFSchema,
  SaveCustomDomainArgs,
  DeleteCustomDomainArgs,
  deleteCustomDomainSuccessAction,
  deleteCustomDomainErrorAction,
  DeleteCustomDomainSchema,
  deleteCustomDomainLoadingAction,
  saveCustomDomainSuccessAction,
  saveCustomDomainErrorAction,
  SaveCustomDomainSchema,
  saveCustomDomainLoadingAction
} from "../actions/deploymentActions";
import { initAppBasicInfoAction } from "../actions/authActions";
import { ActionSchema } from "../actions";

/*
 * Web apps Handler
 */
function filterSites(Urls, WebAppsData) {
  Urls.forEach(function(element) {
    element = element.trim();
    let found = false;
    for (let i = 0; i < WebAppsData.length; i++) {
      if (WebAppsData[i].url === element) {
        found = true;
      }
    }
    if (!found) {
      WebAppsData.push({ url: element });
    }
  });

  return WebAppsData;
}
function appendSites(data) {
  let prodSites: string[] = [],
    stagSites: string[] = [];
  data.production &&
    data.production.map(item => {
      if (item.url) {
        prodSites.push(item.url);
      }
    });
  data.staging &&
    data.staging.map(item => {
      if (item.url) {
        stagSites.push(item.url);
      }
    });
  return {
    domain: prodSites[0],
    production: prodSites.join(";"),
    staging: stagSites.join(";")
  };
}
function* getwebAppsHandler(payload: any) {
  //const WebAppsData = { production: [], staging: [] };
  try {
    //  yield put(showLoadingAction(true, "Please Wait..."));
    yield put(getWebAppsLoadingAction());
    let ownerUid = "";
    if (payload.data) {
      ownerUid = payload.data;
    }
    const webApps: WebAppsSchema = yield httpGet("/deployment/sites", {
      ownerUid
    });
    if (webApps.errorCode) {
      throw new Error(webApps.message || "");
    }
    if (webApps.CallbackUrl) {
      const WepAppsList = webApps.CallbackUrl.split(";");
      webApps.production = filterSites(WepAppsList, []);
    }
    if (webApps.DevDomain) {
      const devDomainList = webApps.DevDomain.split(";");
      if (webApps.production && webApps.production.length) {
        webApps.production = filterSites(devDomainList, webApps.production);
      } else {
        webApps.production = filterSites(devDomainList, []);
      }
    }
    yield put(getWebAppsSuccessAction(webApps));
  } catch (e) {
    yield put(getWebAppsErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
function* addWebAppsHandler(payload: any) {
  const jsonData = appendSites(payload);
  try {
    //  yield put(showLoadingAction(true, "Please Wait..."));
    yield put(addWebAppsLoadingAction());
    const data: WebAppsSchema = yield httpPost(
      "/deployment/sites",
      {},
      jsonData
    );
    if (data.errorCode) {
      const Deployment = state => state.deployment;
      const _deployment = yield select(Deployment);
      if (_deployment.webApps.CallbackUrl) {
        const WepAppsList = _deployment.webApps.CallbackUrl.split(";");
        _deployment.webApps.production = filterSites(WepAppsList, "production");
      }
      if (_deployment.webApps.DevDomain) {
        const devDomainList = _deployment.webApps.DevDomain.split(";");
        _deployment.webApps.production = filterSites(
          devDomainList,
          "production"
        );
      }

      const msg = data.message || "error";
      yield put(addWebAppsErrorAction(msg));
      yield put(getWebAppsSuccessAction(_deployment.webApps));
    }
    yield put(addWebAppsSuccessAction(data));
  } catch (e) {
    yield put(addWebAppsErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* getIEFHandler() {
  try {
    yield put(getIEFLoadingAction());
    let data: MultiIEFSchema = yield httpGet("/deployment/ief");

    yield put(getIEFSuccessAction({ ...data }));
  } catch (e) {
    yield put(getIEFErrorAction(e.message));
  }
}

function* getIEFByTypeAndThemeHandler(args: GetIEFByTypeAndThemeArgs) {
  try {
    yield put(getIEFByTypeAndThemeLoadingAction());

    const { type, theme } = args;
    let data: MultiIEFSchema = yield httpGet(
      `/deployment/ief/${type}/${theme}`
    );

    yield put(getIEFByTypeAndThemeSuccessAction({ ...data }));
  } catch (e) {
    yield put(getIEFByTypeAndThemeErrorAction(e.message));
  }
}

function* updateIEFHandler(payload: IEFSchema) {
  try {
    yield put(updateIEFLoadingAction());

    let data: MultiIEFSchema = yield httpPut(
      "/deployment/ief/theme",
      {},
      { ...payload }
    );

    yield put(updateIEFSuccessAction({ ...data }));
  } catch (e) {
    yield put(updateIEFErrorAction(e.message));
  }
}

function* getHostedPageHandler() {
  try {
    yield put(getHostedPageLoadingAction());
    let data: GetHostedPageSchema = yield httpGet("/deployment/hostedpage");
    // ** Theme change code starts here
    if (data && data.Pages && data.Pages.length) {
      if (!data.Pages[0].Status) {
        data.Pages[0].Status = "0";
        if (data.Pages.length > 1) {
          data.Pages[1].Status = "0";
        }
      }
    }
    // ** Theme change code ends here
    yield put(getHostedPageSuccessAction({ ...data }));
  } catch (e) {
    yield put(getHostedPageErrorAction(e.message));
  }
}
function* saveHostedPageHandler(payload: GetHostedPageSchema) {
  try {
    yield put(saveHostedPageLoadingAction());
    const data: HostedPagesSchema = yield httpPut(
      "/deployment/hostedpage?v" + new Date().getTime(),
      {},
      payload
    );
    yield put(saveHostedPageSuccessAction({ ...data }));
  } catch (e) {
    yield put(saveHostedPageErrorAction(e.message));
  }
}

function* updateHostedPageHandler(payload) {
  try {
    yield put(updateHostedPageLoadingAction());
    const data: GetHostedPageSchema = yield httpPost(
      "/deployment/hostedPage/update/?v" + new Date().getTime(),
      {},
      { ...payload }
    );
    yield put(updateHostedPageSuccessAction({ ...data }));
  } catch (e) {
    yield put(updateHostedPageErrorAction(e.message));
  }
}

function* resetHostedPageHandler(payload) {
  try {
    yield put(resetHostedPageLoadingAction());
    const data: ResetHostedPageResponseSchema = yield httpPut(
      "/deployment/hostedPage/reset/?v" + new Date().getTime(),
      {},
      payload
    );
    yield put(resetHostedPageSuccessAction({ ...data }));
  } catch (e) {
    yield put(resetHostedPageErrorAction(e.message));
  }
}

function* fetchHostedPageUrlHandler(payload, pageType) {
  try {
    yield put(fetchHostedPageUrlLoadingAction());
    const data: FetchHostedPageUrlResponseSchema = yield httpPost(
      "/deployment/hostedPage/script/" + pageType + "?" + new Date().getTime(),
      {},
      payload
    );
    yield put(fetchHostedPageUrlSuccessAction({ ...data }));
  } catch (e) {
    yield put(fetchHostedPageUrlErrorAction(e.message));
  }
}

//jswidget
function* saveJSWidgetAdvanceOptionHandler({ payload }) {
  try {
    yield put(saveJSWidgetsAdvanceOptionsLoadingAction());
    const data = yield httpPost(
      "/platform-configuration/app-settings/basic",
      {},
      { ...payload }
    );
    yield put(saveJSWidgetsAdvanceOptionsSuccessAction(data));

    yield put(initAppBasicInfoAction(true));
  } catch (e) {
    yield put(saveJSWidgetsAdvanceOptionsErrorAction(e.message));
  }
}
export function* watchSaveAdvanceOptions() {
  while (true) {
    const data = yield take(JSWidgetsConst.SAVE_ADVANCE_OPTION);
    yield call(saveJSWidgetAdvanceOptionHandler, { ...data });
  }
}
//Optional Captcha
function* saveJSWidgetsOptionalCaptcha({ payload }) {
  try {
    yield put(saveJSWidgetsOptionalCaptchaLoadingAction());
    const data = yield httpPut(
      "/platform-configuration/advance/optional-captcha",
      {},
      payload
    );
    yield put(saveJSWidgetsOptionalCaptchaSuccessAction(data));
  } catch (e) {
    yield put(saveJSWidgetsOptionalCaptchaErrorAction(e.message));
  }
}
//Custom Field
function* getCustomFieldHandler() {
  try {
    yield put(getCustomFieldLoadingAction());
    const data = yield httpGet("/platform-configuration/custom-fields", {});
    if (data.errorCode || data.StatusCode) {
      throw new Error(data.message || "");
    }
    yield put(getCustomFieldSuccessAction(data));
  } catch (e) {
    yield put(getCustomFieldErrorAction(e.message));
  }
}
export function* watchGetCustomField() {
  while (true) {
    yield take(CustomRegistrationField.GET_CUSTOM_FIELD);
    yield call(getCustomFieldHandler);
  }
}
// Registration Field
function* getRegistrationFieldHandler() {
  try {
    yield put(getRegistrationFieldLoadingAction());
    const data = yield httpGet(
      "/platform-configuration/registration-form-settings",
      {}
    );
    if (data.errorCode || data.StatusCode) {
      throw new Error(data.message || "");
    }
    yield put(getRegistrationFieldSuccessAction(data));
  } catch (e) {
    yield put(getRegistrationFieldErrorAction(e.message));
  }
}
// Post Platform Field
//postPlatformFieldAction,
function* postPlatformFieldHandler(dataField: any) {
  try {
    yield put(postPlatformFieldLoadingAction());
    const Data = yield httpPost(
      "/platform-configuration/default-fields",
      {},
      dataField
    );
    if (Data.errorCode || Data.StatusCode) {
      throw new Error(Data.message || "");
    }
    yield put(postPlatformSuccessAction(Data));
  } catch (e) {
    yield put(postPlatformFieldErrorAction(e.message));
  }
}
// Platform Field
function* getPlatformFieldHandler() {
  try {
    yield put(getPlatformFieldLoadingAction());
    const data = yield httpGet(
      "/platform-configuration/platform-registration-fields",
      {}
    );
    if (data.errorCode || data.StatusCode) {
      throw new Error(data.message || "");
    }
    yield put(getPlatformSuccessAction(data));
  } catch (e) {
    yield put(getPlatformFieldErrorAction(e.message));
  }
}
export function* watchPostPlatformField() {
  while (true) {
    const data = yield take(CustomRegistrationField.POST_PLATFORM_FIELD);
    yield call(postPlatformFieldHandler, data.payload);
  }
}
export function* watchGetPlatformField() {
  while (true) {
    yield take(CustomRegistrationField.GET_PLATFORM_FIELD);
    yield call(getPlatformFieldHandler);
  }
}
export function* watchGetRegistrationField() {
  while (true) {
    yield take(CustomRegistrationField.GET_REGISTRATION_FORMS_FIELD);
    yield call(getRegistrationFieldHandler);
  }
}

//-----------------------------------------
export function* watchGetWebApps() {
  while (true) {
    const data = yield take(WebAppsConst.GET_WEB_APPS);
    yield call(getwebAppsHandler, data.payload);
  }
}

export function* watchAddWebApps() {
  while (true) {
    const data = yield take(WebAppsConst.ADD_WEB_APPS);

    yield call(addWebAppsHandler, data.payload);
  }
}

/*
 * Custom Domain Handler
 */

function* getCustomDomainHandler() {
  try {
    // yield put(showLoadingAction(true, "Please Wait..."));
    yield put(getCustomDomainLoadingAction());
    const data: CustomDomainSchema = yield httpGet(
      "/deployment/customdomain/bindingrequest"
    );
    if (data.errorCode) {
      throw new Error(
        data.description ||
          "Unable to retrieve custom domain information. Please try again."
      );
    }
    yield put(getCustomDomainSuccessAction(data));
  } catch (e) {
    yield put(getCustomDomainErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
export function* watchGetCustomDomain() {
  while (true) {
    yield take(CustomDomainConst.GET_CUSTOM_DOMAIN);
    yield call(getCustomDomainHandler);
  }
}

function* saveCustomDomainHandler({ payload }: SaveCustomDomainArgs) {
  try {
    yield put(saveCustomDomainLoadingAction());
    const response: SaveCustomDomainSchema = yield httpPost(
      "/deployment/customdomain/bindingrequest",
      {},
      payload
    );
    if (response.errorCode) {
      throw new Error(response.description || response.message);
    }
    yield put(saveCustomDomainSuccessAction(response));
  } catch (e) {
    yield put(saveCustomDomainErrorAction(e.message));
  }
}

function* deleteCustomDomainHandler({ payload }: DeleteCustomDomainArgs) {
  try {
    yield put(deleteCustomDomainLoadingAction());
    const response: DeleteCustomDomainSchema = yield httpDelete(
      "/deployment/customdomain/bindingrequest",
      {},
      payload
    );
    if (response.errorCode) {
      throw new Error(response.description || response.message);
    }
    yield put(deleteCustomDomainSuccessAction(response));
  } catch (e) {
    yield put(deleteCustomDomainErrorAction(e.message));
  }
}

export function* watchSaveCustomDomain() {
  while (true) {
    const { payload }: ActionSchema<SaveCustomDomainArgs> = yield take(
      CustomDomainConst.SAVE_CUSTOM_DOMAIN
    );
    yield call(saveCustomDomainHandler, payload);
  }
}

export function* watchDeleteCustomDomain() {
  while (true) {
    const { payload }: ActionSchema<DeleteCustomDomainArgs> = yield take(
      CustomDomainConst.DELETE_CUSTOM_DOMAIN
    );
    yield call(deleteCustomDomainHandler, payload);
  }
}
export function* watchGetIEF() {
  while (true) {
    yield take(DeploymentConst.GET_IEF);
    yield call(getIEFHandler);
  }
}

export function* watchGetIEFByTypeAndTheme() {
  while (true) {
    const { payload } = yield take(DeploymentConst.GET_IEF_BY_TYPE_AND_THEME);
    yield call(getIEFByTypeAndThemeHandler, payload);
  }
}

export function* watchUpdateIEF() {
  while (true) {
    const { payload } = yield take(DeploymentConst.UPDATE_IEF);
    yield call(updateIEFHandler, { ...payload });
  }
}

export function* watchGetHostedPage() {
  while (true) {
    yield take(DeploymentConst.GET_HOSTED_PAGE);
    yield call(getHostedPageHandler);
  }
}
export function* watchSaveHostedPage() {
  while (true) {
    const { payload } = yield take(DeploymentConst.SAVE_HOSTED_PAGE);
    yield call(saveHostedPageHandler, { ...payload });
  }
}

export function* watchUpdateHostedPage() {
  while (true) {
    const { payload } = yield take(DeploymentConst.UPDATE_HOSTED_PAGE);
    yield call(updateHostedPageHandler, { ...payload });
  }
}

export function* watchResetHostedPage() {
  while (true) {
    const { payload } = yield take(DeploymentConst.RESET_HOSTED_PAGE);
    yield call(resetHostedPageHandler, { ...payload });
  }
}

export function* watchFetchHostedPageUrl() {
  while (true) {
    const { payload, pageType } = yield take(
      DeploymentConst.FETCH_HOSTED_PAGE_URL
    );
    yield call(fetchHostedPageUrlHandler, { ...payload }, pageType);
  }
}

//Mobile Apps Sott
function* getMobileAppsSottHandler() {
  try {
    // yield put(showLoadingAction(true, "Please Wait..."));
    yield put(getMobileAppsSottLoadingAction());
    const data: MobileAppsSchema = yield httpGet("/deployment/sott");
    yield put(getMobileAppsSottSuccessAction(data));
  } catch (e) {
    yield put(getMobileAppsSottErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
export function* watchGetMobileAppsSott() {
  while (true) {
    yield take(MobileAppsConst.GET_MOBILE_APPS_SOTT);
    yield call(getMobileAppsSottHandler);
  }
}
function* revokeMobileAppsSottHandler(token: string) {
  try {
    yield put(revokeMobileAppsSottLoadingAction());
    const data: DeleteMobileAppsSchema = yield httpDelete("/deployment/sott", {
      authenticityToken: token
    });
    yield put(revokeMobileAppsSottSuccessAction(data));
  } catch (e) {
    yield put(revokeMobileAppsSottErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
export function* watchRevokeMobileAppsSott() {
  while (true) {
    const {
      payload: { authenticityToken }
    } = yield take(MobileAppsConst.REVOKE_SOTT);
    yield call(revokeMobileAppsSottHandler, authenticityToken);
  }
}
function* revokeMobileAppsAllSottHandler() {
  try {
    yield put(revokeallMobileAppsSottLoadingAction());
    const data: DeleteMobileAppsSchema = yield httpDelete(
      "/deployment/sott/all"
    );
    yield put(revokeallMobileAppsSottSuccessAction(data));
  } catch (e) {
    yield put(revokeallMobileAppsSottErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
export function* watchRevokeAllMobileAppsSott() {
  while (true) {
    yield take(MobileAppsConst.REVOKE_ALL_SOTT);
    yield call(revokeMobileAppsAllSottHandler);
  }
}
function* smtpSettingsHandler() {
  try {
    yield put(getSMTPSettingsLoadingAction());
    const data: SMTPSettingSchema = yield httpGet(
      "/deployment/smtp-settings/config",
      {}
    );
    yield put(getSMTPSettingsSuccessAction(data));
  } catch (e) {
    yield put(getSMTPSettingsErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
function* saveSMTPSettingsHandler(settings: SaveSMTPSettingSchema) {
  try {
    yield put(saveSMTPSettingsLoadingAction());
    let endpoint = "/deployment/smtp-settings";
    if (settings.provider && settings.provider.toLowerCase() === "mailazy") {
      endpoint += "/smtpprovider";
    }
    const data: SMTPSettingSchema = yield httpPost(endpoint, {}, settings);
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    }
    yield put(saveSMTPSettingsSuccessAction(data));
  } catch (e) {
    yield put(saveSMTPSettingsErrorAction(e.message));
  }
}

function* verifySMTPSettingsHandler(
  settings: VerifySMTPSettingSchema,
  i18n: LanguageSchema
) {
  try {
    yield put(verifySMTPSettingsLoadingAction());
    const data: VerifySMTPSettingResponseSchema = yield httpPost(
      "/deployment/smtp-settings/verifysmtpsettings",
      {},
      settings
    );
    if (data.errorCode !== undefined) {
      let error = "";
      if (data.errorCode === 1027) {
        const LEARN_MORE_AT = i18n.LEARN_MORE_AT;
        if (data.description) {
          if (data.description.indexOf(LEARN_MORE_AT) !== -1) {
            error = data.description.replace(LEARN_MORE_AT, "");
          } else if (
            data.description.indexOf(
              i18n.SPECIFIC_STRING_NOT_VALID_FORM_FOR_EMAIL_ADDRESS
            ) !== -1
          ) {
            error = i18n.EMAIL_CANNOT_BE_SENT_CHECK_EMAIL_CONFIGURATION;
          } else {
            error = data.description;
          }
        }
      } else {
        error = data.description || data.message || "";
      }
      throw Error(error);
    }
    yield put(verifySMTPSettingsSuccessAction(data));
  } catch (e) {
    yield put(verifySMTPSettingsErrorAction(e.message));
  }
}
function* resetSMTPSettingsHandler() {
  try {
    yield put(resetSMTPSettingsLoadingAction());
    const data: ResetSMTPSettingResponseSchema = yield httpPost(
      "/deployment/smtp-settings/reset"
    );
    if (data.responseReset && data.responseReset.description) {
      throw Error(data.responseReset.description);
    }
    let saveData: SMTPSettingSchema = data.responseSMTPSetting;
    yield put(resetSMTPSettingsSuccessAction(data));
    yield put(getSMTPSettingsSuccessAction(saveData));
  } catch (e) {
    yield put(resetSMTPSettingsErrorAction(e.message));
  }
}

function* addMobileAppsSottHandler(sdata) {
  try {
    yield put(addMobileAppsSottLoadingAction());
    const data: AddMobileAppsSchema = yield httpPost(
      "/deployment/sott",
      {},
      { ...sdata }
    );
    yield put(addMobileAppsSottSuccessAction(data));
  } catch (e) {
    yield put(addMobileAppsSottErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* updateDataSchemaHandler(
  platFormFields,
  registrationDataFields,
  customFilesds
) {
  yield put(
    updatePropsDataSchemaSuccessAction(
      platFormFields,
      registrationDataFields,
      customFilesds
    )
  );
}
export function* watchUpdateDataSchema() {
  while (true) {
    const { payload } = yield take(
      CustomRegistrationField.UPDATE_DATASCHEMA_FIELDS_DATA
    );
    yield call(
      updateDataSchemaHandler,
      payload.platFormFields,
      payload.registrationDataFields,
      payload.customFilesds
    );
  }
}
export function* watchAddMobileAppSott() {
  while (true) {
    const { payload } = yield take(MobileAppsConst.ADD_MOBILE_APPS_SOTT);
    yield call(addMobileAppsSottHandler, { ...payload });
  }
}
export function* watchJSWidgetOptionalCaptcha() {
  while (true) {
    const data = yield take(JSWidgetsConst.UPDATE_OPTIONAL_CAPTCHA);
    yield call(saveJSWidgetsOptionalCaptcha, { ...data });
  }
}
export function* watchGetSMTPSettings() {
  while (true) {
    yield take(DeploymentConst.GET_SMTP_SETTINGS);
    yield call(smtpSettingsHandler);
  }
}

export function* watchSaveSMTPSettings() {
  while (true) {
    const { payload } = yield take(SMTPSettingsConst.SAVE_SMTP_SETTINGS);
    yield call(saveSMTPSettingsHandler, payload);
  }
}

export function* watchVerifySMTPSettings() {
  while (true) {
    const request = yield take(SMTPSettingsConst.VERIFY_SMTP_SETTINGS);
    yield call(verifySMTPSettingsHandler, request.payload, request.i18n);
  }
}
export function* watchResetSMTPSettings() {
  while (true) {
    yield take(SMTPSettingsConst.RESET_SMTP_SETTINGS);
    yield call(resetSMTPSettingsHandler);
  }
}

import {
  SideEffectSchema,
  loadingAction,
  errorAction,
  successSideEffectState,
  generateConst
} from ".";

export const AccountPlanConst = generateConst("AUTH", ["UPDATE_PLAN_DETAIL"]);
export const AccountConst = {
  GET_REQUEST_PROFILE: "GET_REQUEST_PROFILE",
  GET_REQUEST_PROFILE_DATA_LOADING: "GET_REQUEST_PROFILE_DATA_LOADING",
  GET_REQUEST_PROFILE_DATA_ERROR: "GET_REQUEST_PROFILE_DATA_ERROR",
  GET_REQUEST_PROFILE_DATA_SUCCESS: "GET_REQUEST_PROFILE_DATA_SUCCESS",

  UPDATE_REQUEST_PROFILE_DATA: "UPDATE_REQUEST_PROFILE_DATA",
  UPDATE_REQUEST_PROFILE_DATA_LOADING: "UPDATE_REQUEST_PROFILE_DATA_LOADING",
  UPDATE_REQUEST_PROFILE_DATA_ERROR: "UPDATE_REQUEST_PROFILE_DATA_ERROR",
  UPDATE_REQUEST_PROFILE_DATA_SUCCESS: "UPDATE_REQUEST_PROFILE_DATA_SUCCESS",
  SHOW_ACCOUNT_LOADING: "SHOW_ACCOUNT_LOADING",

  GET_REQUEST_ACCOUNT_SITE_CREDENTIAL_LOADING:
    "GET_REQUEST_ACCOUNT_SITE_CREDENTIAL_LOADING",
  GET_REQUEST_ACCOUNT_SITE_CREDENTIAL_ERROR:
    "GET_REQUEST_ACCOUNT_SITE_CREDENTIAL_ERROR",
  GET_REQUEST_ACCOUNT_SITE_CREDENTIAL: "GET_REQUEST_ACCOUNT_SITE_CREDENTIAL",
  GET_REQUEST_ACCOUNT_SITE_CREDENTIAL_SUCCESS:
    "GET_REQUEST_ACCOUNT_SITE_CREDENTIAL_SUCCESS",

  UPDATE_REQUEST_ACCOUNT_SITE_SETTING_LOADING:
    "UPDATE_REQUEST_ACCOUNT_SITE_SETTING_LOADING",
  UPDATE_REQUEST_ACCOUNT_SITE_SETTING_ERROR:
    "UPDATE_REQUEST_ACCOUNT_SITE_SETTING_ERROR",
  UPDATE_REQUEST_ACCOUNT_SITE_SETTING: "UPDATE_REQUEST_ACCOUNT_SITE_SETTING",
  UPDATE_REQUEST_ACCOUNT_SITE_SETTING_SUCCESS:
    "UPDATE_REQUEST_ACCOUNT_SITE_SETTING_SUCCESS",

  GET_REQUEST_ACCOUNT_SESSION_LOADING: "GET_REQUEST_ACCOUNT_SESSION_LOADING",
  GET_REQUEST_ACCOUNT_SESSION_ERROR: "GET_REQUEST_ACCOUNT_SESSION_ERROR",
  GET_REQUEST_ACCOUNT_SESSION: "GET_REQUEST_ACCOUNT_SESSION",
  GET_REQUEST_ACCOUNT_SESSION_SUCCESS: "GET_REQUEST_ACCOUNT_SESSION_SUCCESS",

  GET_REQUEST_ACCOUNT_2FA_LOADING: "GET_REQUEST_ACCOUNT_2FA_LOADING",
  GET_REQUEST_ACCOUNT_2FA_ERROR: "GET_REQUEST_ACCOUNT_2FA_ERROR",
  GET_REQUEST_ACCOUNT_2FA: "GET_REQUEST_ACCOUNT_2FA",
  GET_REQUEST_ACCOUNT_2FA_SUCCESS: "GET_REQUEST_ACCOUNT_2FA_SUCCESS",

  POST_REQUEST_ACCOUNT_GOOGLE_2FA_LOADING:
    "POST_REQUEST_ACCOUNT_GOOGLE_2FA_LOADING",
  POST_REQUEST_ACCOUNT_GOOGLE_2FA_ERROR:
    "POST_REQUEST_ACCOUNT_GOOGLE_2FA_ERROR",
  POST_REQUEST_ACCOUNT_GOOGLE_2FA: "POST_REQUEST_ACCOUNT_GOOGLE_2FA",
  POST_REQUEST_ACCOUNT_GOOGLE_2FA_SUCCESS:
    "POST_REQUEST_ACCOUNT_GOOGLE_2FA_SUCCESS",

  DELETE_REQUEST_ACCOUNT_GOOGLE_2FA_LOADING:
    "DELETE_REQUEST_ACCOUNT_GOOGLE_2FA_LOADING",
  DELETE_REQUEST_ACCOUNT_GOOGLE_2FA_ERROR:
    "DELETE_REQUEST_ACCOUNT_GOOGLE_2FA_ERROR",
  DELETE_REQUEST_ACCOUNT_GOOGLE_2FA: "DELETE_REQUEST_ACCOUNT_GOOGLE_2FA",
  DELETE_REQUEST_ACCOUNT_GOOGLE_2FA_SUCCESS:
    "DELETE_REQUEST_ACCOUNT_GOOGLE_2FA_SUCCESS",

  POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA_LOADING:
    "POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA_LOADING",
  POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA_ERROR:
    "POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA_ERROR",
  POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA: "POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA",
  POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA_SUCCESS:
    "POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA_SUCCESS",

  POST_REQUEST_ACCOUNT_SMS_2FA_LOADING: "POST_REQUEST_ACCOUNT_SMS_2FA_LOADING",
  POST_REQUEST_ACCOUNT_SMS_2FA_ERROR: "POST_REQUEST_ACCOUNT_SMS_2FA_ERROR",
  POST_REQUEST_ACCOUNT_SMS_2FA: "POST_REQUEST_ACCOUNT_SMS_2FA",
  POST_REQUEST_ACCOUNT_SMS_2FA_SUCCESS: "POST_REQUEST_ACCOUNT_SMS_2FA_SUCCESS",

  DELETE_REQUEST_ACCOUNT_SMS_2FA_LOADING:
    "DELETE_REQUEST_ACCOUNT_SMS_2FA_LOADING",
  DELETE_REQUEST_ACCOUNT_SMS_2FA_ERROR: "DELETE_REQUEST_ACCOUNT_SMS_2FA_ERROR",
  DELETE_REQUEST_ACCOUNT_SMS_2FA: "DELETE_REQUEST_ACCOUNT_SMS_2FA",
  DELETE_REQUEST_ACCOUNT_SMS_2FA_SUCCESS:
    "DELETE_REQUEST_ACCOUNT_SMS_2FA_SUCCESS",

  GET_REQUEST_ACCOUNT_BACKUP_CODES_LOADING:
    "GET_REQUEST_ACCOUNT_BACKUP_CODES_LOADING",
  GET_REQUEST_ACCOUNT_BACKUP_CODES_ERROR:
    "GET_REQUEST_ACCOUNT_BACKUP_CODES_ERROR",
  GET_REQUEST_ACCOUNT_BACKUP_CODES: "GET_REQUEST_ACCOUNT_BACKUP_CODES",
  GET_REQUEST_ACCOUNT_BACKUP_CODES_SUCCESS:
    "GET_REQUEST_ACCOUNT_BACKUP_CODES_SUCCESS",

  GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES_LOADING:
    "GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES_LOADING",
  GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES_ERROR:
    "GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES_ERROR",
  GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES:
    "GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES",
  GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES_SUCCESS:
    "GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES_SUCCESS",

  UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT_LOADING:
    "UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT_LOADING",
  UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT_ERROR:
    "UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT_ERROR",
  UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT_SUCCESS:
    "UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT_SUCCESS",
  UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT:
    "UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT",

  UPDATE_ACCOUTN_SOCIAL_LINKING_LOADING:
    "UPDATE_ACCOUTN_SOCIAL_LINKING_LOADING",
  UPDATE_ACCOUTN_SOCIAL_LINKING_ERROR: "UPDATE_ACCOUTN_SOCIAL_LINKING_ERROR",
  UPDATE_ACCOUTN_SOCIAL_LINKING_SUCCESS:
    "UPDATE_ACCOUTN_SOCIAL_LINKING_SUCCESS",
  UPDATE_ACCOUTN_SOCIAL_LINKING: "UPDATE_ACCOUTN_SOCIAL_LINKING",
  GET_SITES_SUBSCRIPTION_LIST: "GET_SITES_SUBSCRIPTION_LIST",
  GET_SITES_SUBSCRIPTION_LIST_LOADING: "GET_SITES_SUBSCRIPTION_LIST_LOADING",
  GET_SITES_SUBSCRIPTION_LIST_SUCCESS: "GET_SITES_SUBSCRIPTION_LIST",
  GET_SITES_SUBSCRIPTION_LIST_ERROR: "GET_SITES_SUBSCRIPTION_LIST_ERROR",
  SWITCH_TO_OWNED_SITE: "SWITCH_TO_OWNED_SITE",
  SWITCH_TO_OWNED_SITE_RESET: "SWITCH_TO_OWNED_SITE_RESET",
  SWITCH_TO_OWNED_SITE_SUCCESS: "SWITCH_TO_OWNED_SITE_SUCCESS",
  SWITCH_TO_OWNED_SITE_ERROR: "SWITCH_TO_OWNED_SITE_ERROR",
  SWITCH_TO_SHARED_SITE: "SWITCH_TO_SHARED_SITE",
  SWITCH_TO_SHARED_SITE_RESET: "SWITCH_TO_SHARED_SITE_RESET",
  SWITCH_TO_SHARED_SITE_SUCCESS: "SWITCH_TO_SHARED_SITE_SUCCESS",
  SWITCH_TO_SHARED_SITE_ERROR: "SWITCH_TO_SHARED_SITE_ERROR",

  ACCOUNT_STORE_CLEAR_API_RESPONSE_ERROR:
    "ACCOUNT_STORE_CLEAR_API_RESPONSE_ERROR",

  GET_ACCOUNT_THEME_CUSTOMIZATION: "GET_ACCOUNT_THEME_CUSTOMIZATION",
  GET_ACCOUNT_THEME_CUSTOMIZATION_LOADING:
    "GET_ACCOUNT_THEME_CUSTOMIZATION_LOADING",
  GET_ACCOUNT_THEME_CUSTOMIZATION_SUCCESS:
    "GET_ACCOUNT_THEME_CUSTOMIZATION_SUCCESS",
  GET_ACCOUNT_THEME_CUSTOMIZATION_ERROR: "ACCOUNT_THEME_CUSTOMIZATION_ERROR",

  GET_REQUEST_UPGRADE_ACCOUNT_2FA_LOADING:
    "GET_REQUEST_UPGRADE_ACCOUNT_2FA_LOADING",
  GET_REQUEST_UPGRADE_ACCOUNT_2FA_ERROR:
    "GET_REQUEST_UPGRADE_ACCOUNT_2FA_ERROR",
  GET_REQUEST_UPGRADE_ACCOUNT_2FA: "GET_REQUEST_UPGRADE_ACCOUNT_2FA",
  GET_REQUEST_UPGRADE_ACCOUNT_2FA_SUCCESS:
    "GET_REQUEST_UPGRADE_ACCOUNT_2FA_SUCCESS",

  POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA_LOADING:
    "POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA_LOADING",
  POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA_ERROR:
    "POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA_ERROR",
  POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA:
    "POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA",
  POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA_SUCCESS:
    "POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA_SUCCESS"
};
export const updateStoreAPIResponseErrorAction = (data: any) => {
  let payload: any = data;
  if (payload.isError) payload["isError"] = false;
  delete payload["error"];
  delete payload["errorCode"];
  delete payload["description"];
  delete payload["isPosted"];
  delete payload["ErrorCode"];
  delete payload["Description"];
  delete payload["IsPosted"];
  if (data.clearAll) {
    payload = {};
  }
  return {
    type: AccountConst.ACCOUNT_STORE_CLEAR_API_RESPONSE_ERROR,
    payload
  };
};

export interface ProfileRequestSchema extends SideEffectSchema {
  Name: string;
  phone: string;
  TitleOrRole?: any;
  Organisation: string;
  TimeZone?: string;
  CommunicationEmailId?: string;
  save?: any;
  onChange?: any;
  onBlur?: any;
  apiCallPending?: boolean;
}
export interface ProfileSchema extends SideEffectSchema {
  Name: string;
  Company: any;
  Organisation: string;
  IsEmailVerified: any;
  EmailID?: any;
  EmailVerified?: any;
  Phone: string;
  Country: any;
  ProfilePhoto: string;
  CommunicationEmailId?: string;
  Website?: any;
  LocalCity?: any;
  LocalCountry?: any;
  StateOrProvince?: any;
  Industry?: any;
  TitleOrRole?: any;
  UId: string;
  LastLoginDate: string;
  TimeZone?: string;
  Comments?: any;
  AccountCreationDate: string;
}

export interface SettingsRequestSchema {
  Confirm: string;
  New: string;
  Old: string;
}
export interface Credential extends SideEffectSchema {
  apikey: string;
  sitename: string;
  token: string;
  uid: string;
  description?: string;
  errorCode?: number;
  isPosted?: boolean;
}
export interface LoginSessionUser extends SideEffectSchema {
  AccessToken: string;
  Browser: string;
  Device: string;
  Os: string;
  DeviceType: string;
  City?: string;
  Country: string;
  Ip: string;
  LoginDate: Date;
}
export interface GetRequestProfileDataSchema extends ProfileSchema {}
export interface UpdateRequestProfileDataSchema extends ProfileSchema {}
export interface GetAccountCredentialDataSchema extends Credential {}
export interface GetAccountLinkingDataSchema
  extends SideEffectSchema,
    Credential {
  isLoading?: boolean;
  isSuccess?: false;
  isError?: boolean;
  error?: string;
  isPosted?: boolean;
  description?: string;
  errorCode?: number;
}
export interface GetRequestAccountLoginSessionDataSchema
  extends LoginSessionUser {}
export interface Account2FA extends SideEffectSchema {
  QRCode: string;
  ManualEntryCode: string;
  IsGoogleAuthenticatorVerified: boolean;
  IsOTPAuthenticatorVerified: boolean;
  OTPPhoneNo?: string;
  OTPStatus?: string;
  clientIP: string;
}
export interface GetRequestAccount2FADataSchema
  extends Account2FA,
    SideEffectSchema {}
export interface Account2FASuccess extends SideEffectSchema {
  success: boolean;
}
export interface SMS2FARequestSchema {
  CountryCode: string;
  MFAPhone: string;
}
export interface Account2FASuccessDataSchema extends Account2FASuccess {}
export interface AccountSMS2FAVerify extends SideEffectSchema {
  AccountSid?: string;
  Sid: string;
  clientIP: string;
}
export interface AccountSMS2FAVerifyDataSchema extends AccountSMS2FAVerify {}
export interface AccountBackupCodes extends SideEffectSchema {
  BackUpCodes: any;
  clientIP: string;
}
export interface AccountBackupCodesDataSchema extends AccountBackupCodes {}
export const getRequestProfile = (data: string) => {
  return {
    type: AccountConst.GET_REQUEST_PROFILE,
    payload: data
  };
};
export const getRequestLoadingAction = () =>
  loadingAction(AccountConst.GET_REQUEST_PROFILE_DATA_LOADING);

export const getRequestErrorAction = (error: string) =>
  errorAction(AccountConst.GET_REQUEST_PROFILE_DATA_ERROR, error);

export const getRequestProfileSuccessAction = (data: any) => {
  const payload: GetRequestProfileDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.GET_REQUEST_PROFILE_DATA_SUCCESS,
    payload: payload
  };
};
export const updateRequestProfile = (data: ProfileRequestSchema) => {
  const payload: ProfileRequestSchema = { ...data, ...successSideEffectState };
  return {
    type: AccountConst.UPDATE_REQUEST_PROFILE_DATA,
    payload: payload
  };
};

export const updateRequestProfileLoadingAction = () =>
  loadingAction(AccountConst.UPDATE_REQUEST_PROFILE_DATA_LOADING);

export const updateRequestProfileErrorAction = (error: string) =>
  errorAction(AccountConst.UPDATE_REQUEST_PROFILE_DATA_ERROR, error);

export const updateRequestProfileSuccessAction = (data: any) => {
  const payload: UpdateRequestProfileDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.UPDATE_REQUEST_PROFILE_DATA_SUCCESS,
    payload: payload
  };
};
export const getLoadAccountSiteCredential = () =>
  loadingAction(AccountConst.GET_REQUEST_ACCOUNT_SITE_CREDENTIAL_LOADING);

export const getErrorAccountSiteCredential = (error: string) =>
  loadingAction(AccountConst.GET_REQUEST_ACCOUNT_SITE_CREDENTIAL_ERROR);

export const getAccountSiteCredential = (data: string) => {
  return {
    type: AccountConst.GET_REQUEST_ACCOUNT_SITE_CREDENTIAL,
    payload: data
  };
};
export const getSuccessAccountSiteCredential = (data: any) => {
  const payload: GetAccountCredentialDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.GET_REQUEST_ACCOUNT_SITE_CREDENTIAL_SUCCESS,
    payload: payload
  };
};

export const updateLoadAccountSiteCredential = () =>
  loadingAction(AccountConst.UPDATE_REQUEST_ACCOUNT_SITE_SETTING_LOADING);
export const updateErrorAccountSiteCredential = (error: string) =>
  loadingAction(AccountConst.UPDATE_REQUEST_ACCOUNT_SITE_SETTING_ERROR);
export const updateAccountSiteCredential = (data: SettingsRequestSchema) => {
  return {
    type: AccountConst.UPDATE_REQUEST_ACCOUNT_SITE_SETTING,
    payload: data
  };
};
export const updateSuccessAccountSiteCredential = (data: any) => {
  const payload: UpdateRequestProfileDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.UPDATE_REQUEST_ACCOUNT_SITE_SETTING_SUCCESS,
    payload: payload
  };
};

//Login-Session
export const getRequestLoginSessionLoadingAction = () =>
  loadingAction(AccountConst.GET_REQUEST_ACCOUNT_SESSION_LOADING);
export const getRequestLoginSessionErrorAction = (error: string) =>
  loadingAction(AccountConst.GET_REQUEST_ACCOUNT_SESSION_ERROR);

export const getRequestLoginSessionSuccessAction = (data: any) => {
  const payload: GetRequestAccountLoginSessionDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.GET_REQUEST_ACCOUNT_SESSION_SUCCESS,
    payload: payload
  };
};
export const getRequestLoginSessionAction = (data: any) => {
  return {
    type: AccountConst.GET_REQUEST_ACCOUNT_SESSION,
    payload: data
  };
};
//Logout Session
export const updateRequestLogOutSessionLoadingAction = () =>
  loadingAction(AccountConst.UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT_LOADING);
export const updateRequestLogOutSessionErrorAction = (error: string) =>
  loadingAction(AccountConst.UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT_ERROR);

export const updateRequestLogOutSessionSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT_SUCCESS,
    payload: payload
  };
};
export const updateRequestLogOutSessionAction = (data: any) => {
  return {
    type: AccountConst.UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT,
    payload: data
  };
};

export const updateAccountLinkingLoadingAction = () =>
  loadingAction(AccountConst.UPDATE_ACCOUTN_SOCIAL_LINKING_LOADING);
export const updateAccountLinkingErrorAction = (error: string) =>
  loadingAction(AccountConst.UPDATE_ACCOUTN_SOCIAL_LINKING_ERROR);
export const updateAccountLinkingSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.UPDATE_ACCOUTN_SOCIAL_LINKING_SUCCESS,
    payload: payload
  };
};
export const updateAccountLinkinAction = (data: any) => {
  return {
    type: AccountConst.UPDATE_ACCOUTN_SOCIAL_LINKING,
    payload: data
  };
};

//Account-2FA
export const getRequestAccount2FALoadingAction = () =>
  loadingAction(AccountConst.GET_REQUEST_ACCOUNT_2FA_LOADING);
export const getRequestAccount2FAErrorAction = (error: string) =>
  loadingAction(AccountConst.GET_REQUEST_ACCOUNT_2FA_ERROR);

export const getRequestAccount2FASuccessAction = (data: any) => {
  const payload: GetRequestAccount2FADataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.GET_REQUEST_ACCOUNT_2FA_SUCCESS,
    payload: payload
  };
};
export const getRequestAccount2FAAction = () => {
  return {
    type: AccountConst.GET_REQUEST_ACCOUNT_2FA
  };
};

export const postRequestAccountGoogle2FALoadingAction = () =>
  loadingAction(AccountConst.POST_REQUEST_ACCOUNT_GOOGLE_2FA_LOADING);
export const postRequestAccountGoogle2FAErrorAction = (error: string) =>
  errorAction(AccountConst.POST_REQUEST_ACCOUNT_GOOGLE_2FA_ERROR, error);

export const postRequestAccountGoogle2FASuccessAction = (data: any) => {
  const payload: Account2FASuccessDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.POST_REQUEST_ACCOUNT_GOOGLE_2FA_SUCCESS,
    payload: payload
  };
};
export const postRequestAccountGoogle2FAAction = (data: any) => {
  return {
    type: AccountConst.POST_REQUEST_ACCOUNT_GOOGLE_2FA,
    payload: data
  };
};

export const deleteRequestAccountGoogle2FALoadingAction = () =>
  loadingAction(AccountConst.DELETE_REQUEST_ACCOUNT_GOOGLE_2FA_LOADING);
export const deleteRequestAccountGoogle2FAErrorAction = (error: string) =>
  loadingAction(AccountConst.DELETE_REQUEST_ACCOUNT_GOOGLE_2FA_ERROR);

export const deleteRequestAccountGoogle2FASuccessAction = (data: any) => {
  const payload: Account2FASuccessDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.DELETE_REQUEST_ACCOUNT_GOOGLE_2FA_SUCCESS,
    payload: payload
  };
};
export const deleteRequestAccountGoogle2FAAction = (data: any) => {
  return {
    type: AccountConst.DELETE_REQUEST_ACCOUNT_GOOGLE_2FA,
    payload: data
  };
};

export const postRequestAccountSMS2FAVerifyLoadingAction = () =>
  loadingAction(AccountConst.POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA_LOADING);
export const postRequestAccountSMS2FAVerifyErrorAction = (error: string) =>
  loadingAction(AccountConst.POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA_ERROR);

export const postRequestAccountSMS2FAVerifySuccessAction = (data: any) => {
  const payload: AccountSMS2FAVerifyDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA_SUCCESS,
    payload: payload
  };
};
export const postRequestAccountSMS2FAVerifyAction = (data: any) => {
  return {
    type: AccountConst.POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA,
    payload: data
  };
};

export const postRequestAccountSMS2FALoadingAction = () =>
  loadingAction(AccountConst.POST_REQUEST_ACCOUNT_SMS_2FA_LOADING);
export const postRequestAccountSMS2FAErrorAction = (error: string) =>
  errorAction(AccountConst.POST_REQUEST_ACCOUNT_SMS_2FA_ERROR, error);

export const postRequestAccountSMS2FASuccessAction = (data: any) => {
  const payload: Account2FASuccessDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.POST_REQUEST_ACCOUNT_SMS_2FA_SUCCESS,
    payload: payload
  };
};
export const postRequestAccountSMS2FAAction = (data: any) => {
  return {
    type: AccountConst.POST_REQUEST_ACCOUNT_SMS_2FA,
    payload: data
  };
};

export const deleteRequestAccountSMS2FALoadingAction = () =>
  loadingAction(AccountConst.DELETE_REQUEST_ACCOUNT_SMS_2FA_LOADING);
export const deleteRequestAccountSMS2FAErrorAction = (error: string) =>
  loadingAction(AccountConst.DELETE_REQUEST_ACCOUNT_SMS_2FA_ERROR);

export const deleteRequestAccountSMS2FASuccessAction = (data: any) => {
  const payload: Account2FASuccessDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.DELETE_REQUEST_ACCOUNT_SMS_2FA_SUCCESS,
    payload: payload
  };
};
export const deleteRequestAccountSMS2FAAction = (data: any) => {
  return {
    type: AccountConst.DELETE_REQUEST_ACCOUNT_SMS_2FA,
    payload: data
  };
};

export const getRequestAccountBackupCodesLoadingAction = () =>
  loadingAction(AccountConst.GET_REQUEST_ACCOUNT_BACKUP_CODES_LOADING);
export const getRequestAccountBackupCodesErrorAction = (error: string) =>
  loadingAction(AccountConst.GET_REQUEST_ACCOUNT_BACKUP_CODES_ERROR);

export const getRequestAccountBackupCodesSuccessAction = (data: any) => {
  const payload: AccountBackupCodesDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.GET_REQUEST_ACCOUNT_BACKUP_CODES_SUCCESS,
    payload: payload
  };
};
export const getRequestAccountBackupCodesAction = (data: any) => {
  return {
    type: AccountConst.GET_REQUEST_ACCOUNT_BACKUP_CODES,
    payload: data
  };
};

export const getRequestAccountResetBackupCodesLoadingAction = () =>
  loadingAction(AccountConst.GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES_LOADING);
export const getRequestAccountResetBackupCodesErrorAction = (error: string) =>
  loadingAction(AccountConst.GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES_ERROR);

export const getRequestAccountResetBackupCodesSuccessAction = (data: any) => {
  const payload: AccountBackupCodesDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES_SUCCESS,
    payload: payload
  };
};
export const getRequestAccountResetBackupCodesAction = (data: any) => {
  return {
    type: AccountConst.GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES,
    payload: data
  };
};
//*****************Message Show***************************************

export type RequestSitesSubscriptionListResponse = {
  AppName: string;
  Subscription: string;
}[];

export interface GetSitesSubscriptionListSchema extends SideEffectSchema {
  response: RequestSitesSubscriptionListResponse;
}

export const getSitesSubscriptionListLoadingAction = () =>
  loadingAction(AccountConst.GET_SITES_SUBSCRIPTION_LIST_LOADING);

export const getSitesSubscriptionListAction = () => {
  return {
    type: AccountConst.GET_SITES_SUBSCRIPTION_LIST,
    payload: {}
  };
};

export const getSitesSubscriptionListSuccessAction = (
  data: GetSitesSubscriptionListSchema
) => {
  const payload = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.GET_SITES_SUBSCRIPTION_LIST_SUCCESS,
    payload
  };
};

export const getSitesSubscriptionListErrorAction = (error: string) =>
  errorAction(AccountConst.GET_SITES_SUBSCRIPTION_LIST_ERROR, error);

export const switchToOwnedSiteAction = (
  appId: number,
  appName: string,
  path: string
) => {
  const payload = { appId, appName, path };
  return { type: AccountConst.SWITCH_TO_OWNED_SITE, payload };
};

export const switchToOwnedSiteResetAction = () => {
  return {
    type: AccountConst.SWITCH_TO_OWNED_SITE_RESET,
    payload: {
      isLoading: undefined,
      isSuccess: undefined,
      isError: undefined,
      error: undefined
    }
  };
};

export const switchToOwnedSiteSuccessAction = () => {
  return {
    type: AccountConst.SWITCH_TO_OWNED_SITE_SUCCESS,
    payload: successSideEffectState
  };
};

export const switchToOwnedSiteErrorAction = (error: string) =>
  errorAction(AccountConst.SWITCH_TO_OWNED_SITE_ERROR, error);

export const switchToSharedSiteAction = (
  appId: number,
  appName: string,
  path: string
) => {
  const payload = { appId, appName, path };
  return { type: AccountConst.SWITCH_TO_SHARED_SITE, payload };
};

export const switchToSharedSiteSuccessAction = () => {
  return {
    type: AccountConst.SWITCH_TO_SHARED_SITE_SUCCESS,
    payload: successSideEffectState
  };
};

export const switchToSharedSiteResetAction = () => {
  return {
    type: AccountConst.SWITCH_TO_SHARED_SITE_RESET,
    payload: {
      isLoading: undefined,
      isSuccess: undefined,
      isError: undefined,
      error: undefined
    }
  };
};

export const switchToSharedSiteErrorAction = (error: string) =>
  errorAction(AccountConst.SWITCH_TO_SHARED_SITE_ERROR, error);

/**
 * GET_ACCOUNT_THEME_CUSTOMIZATION
 */
export interface GetAccountThemeCustomizationSchema extends SideEffectSchema {
  Css: string[] | null;
  Font: string[] | null;
  Images: string[] | null;
  Options: { [key: string]: string };
  clientIP: string;
}

export const getAccountThemeCustomizationAction = (dir: string) => {
  return {
    type: AccountConst.GET_ACCOUNT_THEME_CUSTOMIZATION,
    payload: { dir }
  };
};

export const getAccountThemeCustomizationLoadingAction = () =>
  loadingAction(AccountConst.GET_ACCOUNT_THEME_CUSTOMIZATION_LOADING);

export const getAccountThemeCustomizationErrorAction = (error: string) =>
  errorAction(AccountConst.GET_ACCOUNT_THEME_CUSTOMIZATION_ERROR, error);

export const getAccountThemeCustomizationSuccessAction = (
  data: GetAccountThemeCustomizationSchema
) => {
  const payload: GetAccountThemeCustomizationSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.GET_ACCOUNT_THEME_CUSTOMIZATION_SUCCESS,
    payload
  };
};
//Plan Details
export const updateBillingPlanAction = data => {
  return {
    type: AccountPlanConst.UPDATE_PLAN_DETAIL,
    payload: { ...data }
  };
};
export const updateBillingPlanLoadinAction = () =>
  loadingAction(AccountPlanConst.UPDATE_PLAN_DETAIL_LOADING);

export const updateBillingPlanErrorAction = (error: string) =>
  errorAction(AccountPlanConst.UPDATE_PLAN_DETAIL_ERROR, error);

export const updateBillingPlanSuccessAction = data => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountPlanConst.UPDATE_PLAN_DETAIL_SUCCESS,
    payload
  };
};

export const getRequestUpgradeAccount2FALoadingAction = () =>
  loadingAction(AccountConst.GET_REQUEST_UPGRADE_ACCOUNT_2FA_LOADING);
export const getRequestUpgradeAccount2FAErrorAction = (error: string) =>
  loadingAction(AccountConst.GET_REQUEST_UPGRADE_ACCOUNT_2FA_ERROR);

export const getRequestUpgradeAccount2FASuccessAction = (data: any) => {
  const payload: GetRequestAccount2FADataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.GET_REQUEST_UPGRADE_ACCOUNT_2FA_SUCCESS,
    payload: payload
  };
};
export const getRequestUpgradeAccount2FAAction = () => {
  return {
    type: AccountConst.GET_REQUEST_UPGRADE_ACCOUNT_2FA
  };
};

export const postRequestUpgradeAccountGoogle2FALoadingAction = () =>
  loadingAction(AccountConst.POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA_LOADING);
export const postRequestUpgradeAccountGoogle2FAErrorAction = (error: string) =>
  errorAction(
    AccountConst.POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA_ERROR,
    error
  );

export const postRequestUpgradeAccountGoogle2FASuccessAction = (data: any) => {
  const payload: Account2FASuccessDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AccountConst.POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA_SUCCESS,
    payload: payload
  };
};
export const postRequestUpgradeAccountGoogle2FAAction = (data: any) => {
  return {
    type: AccountConst.POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA,
    payload: data
  };
};

import React from "react";
import "./BranchTag.scss";

export interface BranchTagProps {
  branchName: string;
}

export interface BranchTagState {
  toggleClass: string;
}

class BranchTag extends React.Component<BranchTagProps, BranchTagState> {
  shouldClose = false;
  isOnClickEventSet = false;
  constructor(props: BranchTagProps) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.state = {
      toggleClass: "hide"
    };
  }

  onClose() {
    if (this.shouldClose) {
      this.setState({
        toggleClass: "slideOutLeft"
      });
      // Removing the listners when the select dropdown is closed
      if (this.isOnClickEventSet) {
        $("body").off("click", this.onClose);
        $(window).off("scroll", this.onClose);
        this.isOnClickEventSet = false;
      }
    }
  }

  render() {
    const { branchName } = this.props;
    return (
      <div
        onMouseMove={() => {
          this.shouldClose = false;
        }}
        onMouseLeave={() => {
          this.shouldClose = true;
        }}
      >
        <div
          className="branch-tag"
          onClick={() => {
            if (this.state.toggleClass.indexOf("slideInLeft") !== -1) {
              this.setState({
                toggleClass: "slideOutLeft"
              });
            } else {
              this.setState({
                toggleClass: "slideInLeft"
              });
              if (!this.isOnClickEventSet) {
                $("body").on("click", this.onClose);
                $(window).on("scroll", this.onClose);
                this.isOnClickEventSet = true;
              }
            }
          }}
        >
          <span id="spn_BranchTag">{branchName}</span>
        </div>
        <div
          className={`branch-message animated faster ${this.state.toggleClass}`}
        >
          <span>
            You are currently working on a {branchName.toLowerCase()} instance.
          </span>
        </div>
      </div>
    );
  }
}

export default BranchTag;

import { all } from "redux-saga/effects";
import {
  watchDeleteAccountGoogle2FA,
  watchDeleteAccountSMS2FA,
  watchGetAccount2FAData,
  watchGetAccountBackupCodes2FA,
  watchGetAccountCredentialData,
  watchGetAccountResetBackupCodes2FA,
  watchGetAccountThemeCustomization,
  watchGetLoginSessionData,
  watchGetProfileData,
  watchGetSitesSubscriptionList,
  watchPostAccountGoogle2FA,
  watchPostAccountSMS2FA,
  watchPostAccountSMS2FAVerify,
  watchSwitchToOwnedSite,
  watchSwitchToSharedSite,
  watchUpdateAccountLinking,
  watchUpdateAccountSettingsData,
  watchUpdateLogOutSession,
  watchUpdateProfileData,
  watchPlanDetail,
  watchGetAccountUpgrade2FAData,
  watchPostAccountUpgradeGoogle2FA
} from "./accountSaga";
import {
  watchGetResponseCodeData,
  watchPerformanceData,
  watchRequestCountData,
  watchSmsAnalyticsData,
  watchSmsStatusData,
  watchLogsData
} from "./apiMetricsSaga";
import {
  watchAppBasicInfo,
  watchAppVersion,
  watchAuthConfig,
  watchAuthLogin,
  watchAuthSamlLogin,
  watchAuthValidateToken,
  watchGetAllSites,
  watchGetAppFeature,
  watchGetAuthAppUsers,
  watchGetIntercomUserData,
  watchGetPermission,
  watchGetSharedSites,
  watchGetYourSites,
  watchUpdateAppBasicInfo
} from "./authSaga";
import {
  watchAppData,
  watchBillingSubscriptions,
  watchGetRecurlyAccountDetails,
  watchInvoices
} from "./billingSaga";
import {
  watchAddSecondaryEmail,
  watchAddUser,
  watchAssignRoles,
  watchBlockOrUnblockUser,
  watchDeleteRoleContext,
  watchDeleteUser,
  watchGetAssignedRole,
  watchGetConsentLogs,
  watchGetCustomObjectData,
  watchGetCustomObjects,
  watchGetEmailTemplates,
  watchGetRoleContext,
  watchGetTotalUsers,
  watchGetUserBySearchFields,
  watchGetUserIdentity,
  watchGetUsers,
  watchLoadEnabledFieldSettings,
  watchPostSessions,
  watchRemoveEmailAddressHandler,
  watchResendEmail,
  watchResetPassword,
  watchSendResetPassword as watchSendResetPasswordManagement,
  watchUnassignRoles,
  watchUnlockUser,
  watchUpdateUserProfile,
  watchVerify,
  watchAddRoleContext,
  watchGetCustomFields,
  watchGetESFields,
  watchGetPrivacyPolicyHistory,
  watchGetTwoFASettings,
  watchGetAllEmailTemplates,
  watchConsumerAuditLogs,
  watchConsumerAuditLogsByUID
} from "./customerManagementSaga";
import {
  watchAddConsentForm,
  watchUpdateConsentForm,
  watchUpdateConsentLiveForm,
  watchDeleteConsentForm,
  watchAddConsentGroup,
  watchAddConsentOption,
  watchDataFieldsKey,
  watchDataObjectKey,
  watchEncryptionAtRest,
  watchGetConsentForm,
  watchGetConsentGroup,
  watchGetConsentOption,
  watchGetDataStorageLocation,
  watchGetHashingAlgorithm,
  watchGetPrivacyPolicyVersion,
  watchGetPrivacyPolicyVersionEnabled,
  watchPlatformRegistrationFields,
  watchSavePrivacyPolicyVersion,
  watchSavePrivacyPolicyVersionEnabled,
  watchSendAgeVerification,
  watchSiteRegistrationFormSettings
} from "./dataConfigurationSaga";
import {
  watchAddMobileAppSott,
  watchAddWebApps,
  watchGetCustomDomain,
  watchGetCustomField,
  watchGetHostedPage,
  watchResetHostedPage,
  watchFetchHostedPageUrl,
  watchGetMobileAppsSott,
  watchGetPlatformField,
  watchGetRegistrationField,
  watchGetSMTPSettings,
  watchGetWebApps,
  watchJSWidgetOptionalCaptcha,
  watchPostPlatformField,
  watchResetSMTPSettings,
  watchRevokeAllMobileAppsSott,
  watchRevokeMobileAppsSott,
  watchSaveAdvanceOptions,
  watchSaveHostedPage,
  watchSaveSMTPSettings,
  watchUpdateDataSchema,
  watchUpdateHostedPage,
  watchVerifySMTPSettings,
  watchGetIEF,
  watchGetIEFByTypeAndTheme,
  watchUpdateIEF,
  watchSaveCustomDomain,
  watchDeleteCustomDomain
} from "./deploymentSaga";
import {
  watchGetBestRegistrationStreaks,
  watchGetCreateDate,
  watchGetDeletedUsers,
  watchGetGrowthData,
  watchGetHighestGrowthData,
  watchGetLogsHosts,
  watchGetMonthlyHistory,
  watchGetRegisteredUsers,
  watchGetUserHosts,
  watchGetUserLogs,
  watchGetUsersData,
  watchGetWorstRegistrationStreaks,
  watchProviderData,
  watchReturnRate,
  watchTotalLogins
} from "./insightsSaga";
import {
  watchActivateIntegration,
  watchAddDataExportDumpRequest,
  watchAddWebHook,
  watchUpdateWebHook,
  watchDeactivateIntegration,
  watchDeleteWebHook,
  watchGetAvailableIntegrations,
  watchGetCreatedIntegrations,
  watchGetDataExportDumpRequests,
  watchGetDataSyncFeatures,
  watchGetEtlLogs,
  watchGetExportLogs,
  watchGetIntegrationConfig,
  watchGetIntegrationData,
  watchGetWebHookQuota,
  watchGetWebHookRequests,
  watchQueueBulkAction,
  watchRemoveDataExportDumpRequest,
  watchSaveIntegrationData,
  watchSetDefaultQuota,
  watchGetAuditLogConfig,
  watchSaveAuditLogConfig,
  watchResetAuditLogConfig
} from "./integrationsSaga";
import {
  watchGetApiKey,
  watchGetConfigurationList,
  watchGetSocialProviderList,
  watchGetGlobalEmailConfig,
  watchGetGlobalSMSSettings,
  watchGetRoles,
  watchGetSMSProviderConfig,
  watchGetCustomSMSProviderConfig,
  watchGetSMSSettingsAndConfig,
  watchResetSMSConfig,
  watchSaveGlobalEmailConfig,
  watchSaveGlobalSMSSettings,
  watchSaveSMSConfig,
  watchSaveCustomSMSConfig,
  watchTransferConfigurations,
  watchGetMultipleSocialProvider,
  watchPostMultipleSocialProvider,
  watchPutMultipleSocialProvider,
  watchDeleteMultipleSocialProvider,
  watchGetAuthServerApiConfig,
  watchPostAuthServerApiConfig,
  watchDeleteAuthServerApiConfig,
  watchUpdateAuthServerApiConfig,
  watchSetAppFeature,
  watchRenewCertificate,
  watchGetSSOIntegration,
  watchGetAvailableSSOIntegration,
  watchPostSSOIntegration,
  watchPutSSOIntegration,
  watchDeleteSSOIntegration,
  watchGetSMSTemplates,
  watchGetOAuthProviders,
  watchGetJWTProviders,
  watchGetSAMLProviders,
  watchGetOIDCandOAuthApps,
  watchUpdatePassKeyConfig,
  watchGetPassKeyConfig
} from "./platformConfigurationSaga";
import {
  watchGetAppBasicInfo,
  watchGetAppInfo,
  watchGetPasswordComplexityData,
  watchGetPinAuth,
  watchGetRiskAuthSettings,
  watchGetSecurityQuestions,
  watchGetWebhookSecrets,
  watchPriodicPasswordData,
  watchGetBreachedPasswordPolicy,
  watchPostBreachedPasswordPolicy,
  watchUpdatePinAuthSettings,
  watchUpdateRiskAuthSettings,
  watchGetPushNotifications,
  watchputPushNotificationsSettings,
  watchpostPushNotificationsSettings
} from "./securityConfigurationSaga";
import {
  watchCustomFields,
  watchDeleteSegment,
  watchGetAllCustomObjects,
  watchGetCustomObjTopTenUsers,
  watchGetCustomObjUsers,
  watchGetSegmentationsTopTenUsers,
  watchPostUserSegmentationQuery,
  watchSavedSegments
} from "./segmentationSaga";
import {
  watchGetCustomerData,
  watchGetExistingTickets,
  watchGetFilePickerKey,
  watchGetUsersForApp,
  watchsendTicketData
} from "./supportSaga";
import {
  watchAddExistingUser,
  watchAddUserRole,
  watchAuditLogs,
  watchCheckUser,
  watchCreateUserAndTransferOwnership,
  watchDeleteSamlConfig,
  watchDeleteTeamUser,
  watchDeleteSelf,
  watchGetAppRoles,
  watchGetAppUsers,
  watchGetCurrentUser,
  watchGetTeamOwner,
  watchGetTeamUsers,
  watchGetTeamUserSession,
  watchLogOutTeamUser,
  watchMapFields,
  watchOwnerInfo,
  watchPostSamlConfig,
  watchPutSamlConfig,
  watchResendTeamUserPassword,
  watchRoleList,
  watchSamlConfig,
  watchSendResetPassword,
  watchTransferAccount,
  watchUpdateCurrentUser,
  watchUpdateTeamUserRole,
  watchWasUserAddedInActiveSession
} from "./teamSaga";
import { watchGetProductUpdates } from "./updatesSaga";
import { watchGetAllWorkflows } from "./orchestrationSaga";
import {
  watchSetControlValues,
  watchSetLoginTemplateValues
} from "./brandCustomizationSaga";

function* rootSaga() {
  yield all([
    // Auth
    watchGetAppInfo(),
    watchAuthConfig(),
    watchAuthLogin(),
    watchAuthSamlLogin(),
    watchAppBasicInfo(),
    watchAppVersion(),
    watchGetYourSites(),
    watchGetSharedSites(),
    watchGetSitesSubscriptionList(),
    watchSwitchToOwnedSite(),
    watchSwitchToSharedSite(),
    watchGetPermission(),
    watchGetAuthAppUsers(),
    watchPlanDetail(),
    // API Metrics
    watchRequestCountData(),
    watchGetResponseCodeData(),
    watchPerformanceData(),
    watchSmsAnalyticsData(),
    watchSmsStatusData(),
    watchLogsData(),

    // Insights
    watchGetCreateDate(),
    watchGetDeletedUsers(),
    watchGetGrowthData(),
    watchGetUserHosts(),
    watchGetRegisteredUsers(),
    watchGetUserLogs(),
    watchProviderData(),
    watchGetMonthlyHistory(),
    watchGetLogsHosts(),
    watchTotalLogins(),
    watchReturnRate(),
    watchGetHighestGrowthData(),
    watchGetBestRegistrationStreaks(),
    watchGetWorstRegistrationStreaks(),
    watchGetUsersData(),
    watchLoadEnabledFieldSettings(),
    watchAddUser(),
    watchGetTotalUsers(),
    watchGetEmailTemplates(),
    watchGetAllEmailTemplates(),
    watchConsumerAuditLogs(),
    watchConsumerAuditLogsByUID(),
    watchGetIntercomUserData(),
    watchAppData(),
    watchBillingSubscriptions(),
    watchInvoices(),
    watchGetRecurlyAccountDetails(),
    watchGetTeamUsers(),
    watchGetTeamOwner(),
    watchDeleteTeamUser(),
    watchDeleteSelf(),
    watchResendTeamUserPassword(),
    watchGetTeamUserSession(),
    watchLogOutTeamUser(),
    watchGetAppRoles(),
    watchUpdateTeamUserRole(),
    watchGetProductUpdates(),

    // ThirdParty Software - Configuration
    watchGetAvailableIntegrations(),
    watchGetCreatedIntegrations(),
    watchActivateIntegration(),
    watchDeactivateIntegration(),
    watchGetIntegrationData(),
    watchGetIntegrationConfig(),
    watchSaveIntegrationData(),

    watchGetProfileData(),
    watchUpdateProfileData(),
    watchGetAccountCredentialData(),
    watchGetLoginSessionData(),
    watchUpdateAccountSettingsData(),
    watchUpdateLogOutSession(),
    watchUpdateAccountLinking(),
    watchGetAccount2FAData(),
    watchPostAccountGoogle2FA(),
    watchDeleteAccountGoogle2FA(),
    watchPostAccountSMS2FAVerify(),
    watchPostAccountSMS2FA(),
    watchDeleteAccountSMS2FA(),
    watchGetAccountBackupCodes2FA(),
    watchGetAccountResetBackupCodes2FA(),
    watchGetAccountThemeCustomization(),

    //Deployment //webapps
    watchGetWebApps(),
    watchAddWebApps(),
    watchAuditLogs(),
    watchRoleList(),
    watchOwnerInfo(),
    watchPostPlatformField(),

    //Deployment //Orchestration

    watchGetAllWorkflows(),
    // Team Management - SSO
    watchMapFields(),
    watchSamlConfig(),
    watchPostSamlConfig(),
    watchPutSamlConfig(),
    watchDeleteSamlConfig(),
    watchGetProductUpdates(),
    watchGetProfileData(),
    watchUpdateProfileData(),
    watchGetAccountCredentialData(),
    watchGetLoginSessionData(),
    watchUpdateLogOutSession(),
    watchUpdateAccountLinking(),
    watchGetCurrentUser(),

    // Team Management - Audit Logs and Add Team Member
    watchAuditLogs(),
    watchRoleList(),
    watchOwnerInfo(),
    watchCheckUser(),
    watchAddUserRole(),
    watchSendResetPassword(),
    watchAddExistingUser(),
    watchWasUserAddedInActiveSession(),

    watchGetAppUsers(),
    watchTransferAccount(),
    watchUpdateCurrentUser(),
    watchCreateUserAndTransferOwnership(),

    watchGetIEF(),
    watchGetIEFByTypeAndTheme(),
    watchUpdateIEF(),

    watchGetHostedPage(),
    watchSaveHostedPage(),
    watchUpdateHostedPage(),
    watchResetHostedPage(),
    watchFetchHostedPageUrl(),

    //Custom Domain
    watchGetCustomDomain(),

    // Get Feature Permission
    watchGetAppFeature(),

    // JSWidget
    watchSaveAdvanceOptions(),
    watchGetMobileAppsSott(),
    watchRevokeMobileAppsSott(),
    watchRevokeAllMobileAppsSott(),
    watchAddMobileAppSott(),
    watchGetDataExportDumpRequests(),
    watchRemoveDataExportDumpRequest(),
    watchAddDataExportDumpRequest(),
    watchGetCustomField(),
    watchGetPlatformField(),
    watchGetRegistrationField(),
    watchJSWidgetOptionalCaptcha(),
    watchGetExportLogs(),
    watchGetDataSyncFeatures(),
    watchGetWebHookRequests(),
    watchDeleteWebHook(),
    watchAddWebHook(),
    watchUpdateWebHook(),
    watchGetWebHookQuota(),
    watchSetDefaultQuota(),

    // Support
    watchGetExistingTickets(),
    watchGetUsersForApp(),
    watchGetCustomerData(),
    watchGetFilePickerKey(),
    watchsendTicketData(),
    // Data Governance
    watchGetDataStorageLocation(),

    // password ploicy
    watchGetPasswordComplexityData(),
    watchPriodicPasswordData(),
    watchGetBreachedPasswordPolicy(),
    watchPostBreachedPasswordPolicy(),
    //watchPutPriodicPasswordData(),

    // Customer Management
    watchGetUserBySearchFields(),
    watchGetUsers(),
    watchGetUserIdentity(),
    watchPostSessions(),
    watchAddSecondaryEmail(),
    watchBlockOrUnblockUser(),
    watchDeleteUser(),
    watchUnlockUser(),
    watchVerify(),
    watchResetPassword(),
    watchSendResetPasswordManagement(),
    watchResendEmail(),
    watchRemoveEmailAddressHandler(),
    watchGetAssignedRole(),
    watchAssignRoles(),
    watchUnassignRoles(),
    watchGetRoleContext(),
    watchAddRoleContext(),
    watchDeleteRoleContext(),
    watchGetConsentLogs(),
    watchGetCustomFields(),
    watchGetESFields(),
    watchGetPrivacyPolicyHistory(),

    // Data Configuration
    watchEncryptionAtRest(),
    watchGetAppBasicInfo(),
    watchGetHashingAlgorithm(),
    watchPlatformRegistrationFields(),
    watchSiteRegistrationFormSettings(),
    watchDataObjectKey(),
    watchDataFieldsKey(),
    watchSendAgeVerification(),
    watchAddConsentGroup(),
    watchGetConsentGroup(),
    watchAddConsentOption(),
    watchGetConsentOption(),
    watchAddConsentForm(),
    watchUpdateConsentForm(),
    watchUpdateConsentLiveForm(),
    watchDeleteConsentForm(),
    watchGetConsentForm(),

    //Segementation
    watchCustomFields(),
    watchPostUserSegmentationQuery(),
    watchGetSegmentationsTopTenUsers(),
    watchSavedSegments(),
    watchDeleteSegment(),
    watchGetAllCustomObjects(),
    watchGetCustomObjTopTenUsers(),
    watchGetCustomObjUsers(),

    //Risk based Auth
    watchGetRiskAuthSettings(),
    watchUpdateRiskAuthSettings(),

    // Platform Configuration
    watchGetSMSSettingsAndConfig(),
    watchGetSMSTemplates(),

    watchGetOAuthProviders(),
    watchGetJWTProviders(),
    watchGetSAMLProviders(),
    watchGetOIDCandOAuthApps(),
    watchGetSMSProviderConfig(),
    watchGetCustomSMSProviderConfig(),
    watchSaveSMSConfig(),
    watchSaveCustomSMSConfig(),
    watchResetSMSConfig(),
    watchGetRoles(),
    watchGetConfigurationList(),
    watchGetSocialProviderList(),
    watchTransferConfigurations(),
    watchGetMultipleSocialProvider(),
    watchPostMultipleSocialProvider(),
    watchPutMultipleSocialProvider(),
    watchDeleteMultipleSocialProvider(),
    // Trust Center
    watchSavePrivacyPolicyVersionEnabled(),
    watchSavePrivacyPolicyVersion(),
    watchGetPrivacyPolicyVersionEnabled(),
    watchGetPrivacyPolicyVersion(),
    watchGetSMTPSettings(),

    // Email Configuration
    watchSaveSMTPSettings(),
    watchVerifySMTPSettings(),
    watchResetSMTPSettings(),

    // Global Email Config
    watchGetGlobalEmailConfig(),
    watchSaveGlobalEmailConfig(),
    watchGetGlobalSMSSettings(),
    watchSaveGlobalSMSSettings(),

    watchGetApiKey(),
    watchUpdateAppBasicInfo(),
    watchUpdateDataSchema(),

    watchUpdateUserProfile(),
    watchGetCustomObjectData(),
    watchGetCustomObjects(),
    watchAuthValidateToken(),
    watchGetAllSites(),

    // ETL
    watchGetEtlLogs(),
    watchQueueBulkAction(),
    watchGetPinAuth(),
    watchUpdatePinAuthSettings(),
    watchGetSecurityQuestions(),
    watchGetWebhookSecrets(),
    watchGetAuthServerApiConfig(),
    watchDeleteAuthServerApiConfig(),
    watchUpdateAuthServerApiConfig(),
    watchPostAuthServerApiConfig(),

    watchSetAppFeature(),
    watchRenewCertificate(),
    watchSaveCustomDomain(),
    watchDeleteCustomDomain(),
    watchGetTwoFASettings(),
    watchGetAccountUpgrade2FAData(),
    watchPostAccountUpgradeGoogle2FA(),

    watchGetSSOIntegration(),
    watchGetAvailableSSOIntegration(),
    watchPostSSOIntegration(),
    watchPutSSOIntegration(),
    watchDeleteSSOIntegration(),

    watchGetAuditLogConfig(),
    watchSaveAuditLogConfig(),
    watchResetAuditLogConfig(),

    //brand customization
    watchSetControlValues(),
    watchSetLoginTemplateValues(),


    //push notifications
    watchGetPushNotifications(),
    watchpostPushNotificationsSettings(),
    watchputPushNotificationsSettings(),

    //passkey
    watchUpdatePassKeyConfig(),
    watchGetPassKeyConfig()
  ]);
}
export default rootSaga;

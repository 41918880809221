import { take, takeLatest, call, put } from "redux-saga/effects";
import { httpGet, httpPost, httpDelete } from "../../services";

import {
  getCustomFieldsLoadingAction,
  GetCustomFieldsSchema,
  getCustomFieldsSuccessAction,
  getCustomFieldsErrorAction,
  SegmentationConst,
  GetSegmentationsUsersQueryParameters,
  postUserSegmentationQueryLoadingAction,
  postUserSegmentationQuerySuccessAction,
  postUserSegmentationQueryErrorAction,
  getTopTenUsersLoadingAction,
  getTopTenUsersErrorAction,
  getTopTenUsersSuccessAction,
  getSavedSegmentsLoadingAction,
  GetSavedSegmentsSchema,
  getSavedSegmentsSuccessAction,
  getSavedSegmentsErrorAction,
  deleteSegmentLoadingAction,
  deleteSegmentSuccessAction,
  deleteSegmentErrorAction,
  DeleteSegmentSchema,
  getAllCustomObjectsLoadingAction,
  GetAllCustomObjectsSchema,
  getAllCustomObjectsSuccessAction,
  getAllCustomObjectsErrorAction,
  getCustomObjTopTenUsersLoadingAction,
  GetCustomObjUsersSchema,
  getCustomObjTopTenUsersSuccessAction,
  getCustomObjTopTenUsersErrorAction,
  getCustomObjUsersLoadingAction,
  getCustomObjUsersSuccessAction,
  getCustomObjUsersErrorAction,
  GetTopTenResponseSchema,
  PostUserSegmentationQueryResponseSchema
} from "../actions/segmentationsAction";
import { QuerySchema } from "../../components/QueryBuilder";
import { showLoadingAction } from "../actions/appStateActions";
import { ActionSchema } from "../actions";

/**
 * Workers
 */
function* getCustomFieldsHandler() {
  try {
    yield put(getCustomFieldsLoadingAction());

    const data: GetCustomFieldsSchema = yield httpGet(
      "/segmentations/customfields"
    );
    yield put(getCustomFieldsSuccessAction(data));
  } catch (e) {
    yield put(getCustomFieldsErrorAction(e.message));
  }
}

function* postUserSegmentationQuery(
  filter: QuerySchema,
  queryParameters: GetSegmentationsUsersQueryParameters
) {
  try {
    yield put(postUserSegmentationQueryLoadingAction());
    const response: PostUserSegmentationQueryResponseSchema = yield httpPost(
      "/segmentations/users",
      queryParameters,
      { filter }
    );
    if (response.status !== undefined) {
      if (response.status === 404) {
        response.totalUsers = 0;
      } else throw Error("Invalid Query.");
    }
    if (response.ErrorCode) {
      throw Error(response.Message);
    }
    yield put(postUserSegmentationQuerySuccessAction({ response }));
  } catch (e) {
    yield put(postUserSegmentationQueryErrorAction(e.message));
  }
}

function* getSegmentationsTopTenUsers() {
  try {
    yield put(getTopTenUsersLoadingAction());
    const response: GetTopTenResponseSchema = yield httpGet(
      "/segmentations/users/topten"
    );
    if (response.status !== undefined) {
      if (response.status === 404) {
        response.totalUsers = 0;
      } else throw Error("Invalid Query.");
    }
    yield put(getTopTenUsersSuccessAction({ response }));
  } catch (e) {
    yield put(getTopTenUsersErrorAction(e.message));
  }
}

function* getSavedSegmentsHandler() {
  try {
    yield put(getSavedSegmentsLoadingAction());
    const data: GetSavedSegmentsSchema = yield httpGet("/segmentations/");
    if (data.ErrorCode) {
      throw new Error(data.Description || data.Message);
    }
    yield put(getSavedSegmentsSuccessAction(data));
  } catch (e) {
    yield put(getSavedSegmentsErrorAction(e.message));
  }
}

function* deleteSegmentHandler(token: string) {
  try {
    yield put(deleteSegmentLoadingAction());
    const data: DeleteSegmentSchema = yield httpDelete(
      "/segmentations/",
      { id: token },
      {}
    );
    yield put(deleteSegmentSuccessAction(data));
  } catch (e) {
    yield put(deleteSegmentErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* getAllCustomObjectsHandler() {
  try {
    yield put(getAllCustomObjectsLoadingAction());
    const data: GetAllCustomObjectsSchema = yield httpGet(
      "/segmentations/customobject/all"
    );
    if (data.errorCode || data.ErrorCode) {
      throw new Error(data.Message || "");
    }
    yield put(getAllCustomObjectsSuccessAction(data));
  } catch (e) {
    yield put(getAllCustomObjectsErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* getCustomObjTopTenUsersHandler(customObject: string) {
  try {
    yield put(getCustomObjTopTenUsersLoadingAction());
    const data: GetCustomObjUsersSchema = yield httpGet(
      "/segmentations/customobject/topten",
      { customObject }
    );
    if (data.errorCode || data.status || data.ErrorCode) {
      if (data.status === 404) {
        data.totalUsers = 0;
      } else throw new Error(data.Message || "");
    }
    yield put(getCustomObjTopTenUsersSuccessAction(data));
  } catch (e) {
    yield put(getCustomObjTopTenUsersErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* getCustomObjUsersHandler(
  filter: QuerySchema,
  name: string,
  queryParameters: GetSegmentationsUsersQueryParameters
) {
  try {
    yield put(getCustomObjUsersLoadingAction());
    const data: GetCustomObjUsersSchema = yield httpPost(
      "/segmentations/customobject",
      queryParameters,
      {
        filter,
        name
      }
    );
    if (data.errorCode || data.ErrorCode) {
      throw Error(data.Message || data.message || "");
    }
    yield put(getCustomObjUsersSuccessAction(data));
  } catch (e) {
    yield put(getCustomObjUsersErrorAction(e.message));
  }
}

/**
 * Watchers
 */
export function* watchCustomFields() {
  while (true) {
    yield take(SegmentationConst.GET_CUSTOMFIELDS);
    yield call(getCustomFieldsHandler);
  }
}

export function* watchPostUserSegmentationQuery() {
  // while (true) {
  //   const {
  //     payload: { filter, queryParameters }
  //   } = yield take(SegmentationConst.POST_USER_SEGMENTATION_QUERY);
  //   yield call(postUserSegmentationQuery, filter, queryParameters);
  // }

  yield takeLatest(
    SegmentationConst.POST_USER_SEGMENTATION_QUERY,
    ({ payload: { filter, queryParameters } }: ActionSchema<any>) =>
      postUserSegmentationQuery(filter, queryParameters)
  );
}

export function* watchGetSegmentationsTopTenUsers() {
  while (true) {
    yield take(SegmentationConst.GET_SEGMENTATIONS_TOP_TEN_USERS);
    yield call(getSegmentationsTopTenUsers);
  }
}

export function* watchSavedSegments() {
  while (true) {
    yield take(SegmentationConst.GET_SAVED_SEGMENTS);
    yield call(getSavedSegmentsHandler);
  }
}

export function* watchDeleteSegment() {
  while (true) {
    const {
      payload: { id }
    } = yield take(SegmentationConst.DELETE_SEGMENT);
    yield call(deleteSegmentHandler, id);
  }
}

export function* watchGetAllCustomObjects() {
  while (true) {
    yield take(SegmentationConst.GET_ALL_CUSTOM_OBJECTS);
    yield call(getAllCustomObjectsHandler);
  }
}

export function* watchGetCustomObjTopTenUsers() {
  while (true) {
    const {
      payload: { customObject }
    } = yield take(SegmentationConst.GET_CUSTOMOBJ_TOPTEN_USERS);
    yield call(getCustomObjTopTenUsersHandler, customObject);
  }
}

export function* watchGetCustomObjUsers() {
  while (true) {
    const {
      payload: { filter, name, queryParameters }
    } = yield take(SegmentationConst.GET_CUSTOMOBJ_USERS);
    yield call(getCustomObjUsersHandler, filter, name, queryParameters);
  }
}

import {
  SideEffectSchema,
  loadingAction,
  errorAction,
  successSideEffectState,
  ObjectSchema,
  generateConst
} from ".";
import { AppBasicInfoSchema } from "./authActions";

export interface RiskAuthSchema extends SideEffectSchema {
  EmailAddresses: string[];
  SecondFactorAuthenticationMethod: string;
  RiskFactors: {
    Browser: ObjectSchema<string>;
    Country: ObjectSchema<string>;
    Ip: ObjectSchema<string>;
    City: ObjectSchema<string>;
  };
  errorCode: number;
  description: string;
}
export interface PinAuthSchema extends SideEffectSchema {
  IsRequired: boolean;
  AskOnRegistration: boolean;
  AskOnLogin: boolean;
  AskOnlyOnFirstLogin: boolean;
  PINLogin: boolean;
  SessionTokenExpiration: number;
  errorCode?: number;
  description?: string;
}
export interface UpdatePinAuthSchema extends SideEffectSchema {
  IsPosted: boolean;
  errorCode?: number;
  description?: string;
}
export interface MFAActionSchema {
  isEnabled: boolean;
}
interface AWSSettings {
  AccessKeyId: string;
  SecretAccessKey: string;
  Region: string;
}

interface AndroidSettings {
  Enabled: boolean;
  PlaystoreUrl: string;
  ServiceJson: string;
  IsServiceJsonPresent?: boolean;
  PlatformARN: string;
}

interface AIOSSettings {
  Enabled: boolean;
  AppstoreUrl: string;
  BundleId: string;
  ApnsCertificate: string;
  IsApnsCertPresent?: boolean;
  Environment: string;
  PlatformARN: string;
}

export interface PushNotificationsResponse extends SideEffectSchema {
  IsEnabled: boolean;
  NotificationApp: string;
  NotificationService: string;
  Message?: string;
  QRCodeWidth?: number;
  CustomAppName: string;
  AWSsettings?: AWSSettings;
  AndroidSettings?: AndroidSettings;
  IOSsettings?: AIOSSettings;
  errorCode?: number;
  description?: string;
  ErrorCode?: number;
  Description?: string;
}
export const PasswordPolicyAction = {
  GET_APP_INFO: "GET_APP_INFO",
  GET_APP_INFO_LOADING: "GET_APP_INFO_LOADING",
  GET_APP_INFO_ERROR: "GET_APP_INFO_ERROR",
  GET_APP_INFO_SUCCESS: "GET_APP_INFO_SUCCESS",

  GET_PASSWORD_COMPLEXITY: "GET_PASSWORD_COMPLEXITY",
  GET_PASSWORD_COMPLEXITY_LOADIN: "GET_PASSWORD_COMPLEXITY_LOADIN",
  GET_PASSWORD_COMPLEXITY_ERROR: "GET_PASSWORD_COMPLEXITY_ERROR",
  GET_PASSWORD_COMPLEXITY_SUCCESS: "GET_PASSWORD_COMPLEXITY_SUCCESS",
  GET_PERIODIC_PASSWORD: "GET_PERIODIC_PASSWORD",
  GET_PERIODIC_PASSWORD_LOADING: "GET_PERIODIC_PASSWORD",
  GET_PERIODIC_PASSWORD_ERROR: "GET_PERIODIC_PASSWORD_ERROR",
  GET_PERIODIC_PASSWORD_SUCCESS: "GET_PERIODIC_PASSWORD_SUCCESS",

  PUT_PERIODIC_PASSWORD: "PUT_PERIODIC_PASSWORD",
  PUT_PERIODIC_PASSWORD_LOADING: "PUT_PERIODIC_PASSWORD",
  PUT_PERIODIC_PASSWORD_ERROR: "PUT_PERIODIC_PASSWORD_ERROR",
  PUT_PERIODIC_PASSWORD_SUCCESS: "PUT_PERIODIC_PASSWORD_SUCCESS",

  POST_PASSWORD_HISTORY_SETTINGS: "POST_PASSWORD_HISTORY_SETTINGS",
  POST_PASSWORD_HISTORY_SETTINGS_LOADING:
    "POST_PASSWORD_HISTORY_SETTINGS_LOADING",
  POST_PASSWORD_HISTORY_SETTINGS_ERROR: "POST_PASSWORD_HISTORY_SETTINGS_ERROR",
  POST_PASSWORD_HISTORY_SETTINGS_SUCCESS:
    "POST_PASSWORD_HISTORY_SETTINGS_SUCCESS",
    GET_BREACHED_PASSWORD_POLICY: "GET_BREACHED_PASSWORD_POLICY",
    GET_BREACHED_PASSWORD_POLICY_LOADING: "GET_BREACHED_PASSWORD_POLICY_LOADING",
    GET_BREACHED_PASSWORD_POLICY_ERROR: "GET_BREACHED_PASSWORD_POLICY_ERROR",
    GET_BREACHED_PASSWORD_POLICY_SUCCESS: "GET_BREACHED_PASSWORD_POLICY_SUCCESS",
    POST_BREACHED_PASSWORD_POLICY: "POST_BREACHED_PASSWORD_POLICY",
    POST_BREACHED_PASSWORD_POLICY_LOADING: "POST_BREACHED_PASSWORD_POLICY_LOADING",
    POST_BREACHED_PASSWORD_POLICY_ERROR: "POST_BREACHED_PASSWORD_POLICY_ERROR",
    POST_BREACHED_PASSWORD_POLICY_SUCCESS: "POST_BREACHED_PASSWORD_POLICY_SUCCESS"
};
export const PinAuthAction = {
  PIN_AUTH: "PIN_AUTH",
  PIN_AUTH_LOADING: "PIN_AUTH_LOADING",
  PIN_AUTH_ERROR: "PIN_AUTH_ERROR",
  PIN_AUTH_SUCCESS: "PIN_AUTH_SUCCESS",
  UPDATE_PIN_AUTH: "UPDATE_PIN_AUTH",
  UPDATE_PIN_AUTH_LOADING: "UPDATE_PIN_AUTH_LOADING",
  UPDATE_PIN_AUTH_ERROR: "UPDATE_PIN_AUTH_ERROR",
  UPDATE_PIN_AUTH_SUCCESS: "UPDATE_PIN_AUTH_SUCCESS"
};
export const AppBasicSettingsAction = {
  UPDATE_APP_BASIC_INFO: "UPDATE_APP_BASIC_INFO",
  UPDATE_APP_BASIC_INFO_LOADING: "UPDATE_APP_BASIC_INFO_LOADING",
  UPDATE_APP_BASIC_INFO_ERROR: "UPDATE_APP_BASIC_INFO_ERROR",
  UPDATE_APP_BASIC_INFO_SUCCESS: "UPDATE_APP_BASIC_INFO_SUCCESS"
};
export const SecurityQuestionsAction = generateConst("SECURITY_QUESTIONS", [
  "GET_SECURITY_QUESTIONS"
]);

export const WebhookSecretsAction = generateConst("WEBHOOK_SECRETS", [
  "GET_WEBHOOK_SECRETS_OPTIONS"
]);

export const PushNotificationsAction = generateConst("MFA", [
  "GET_PUSH_NOTIFICATIONS",
  "POST_PUSH_NOTIFICATIONS",
  "PUT_PUSH_NOTIFICATIONS"
]);

export const getPushNotificationsAction = () => {
  return {
    type: PushNotificationsAction.GET_PUSH_NOTIFICATIONS,
    payload: {}
  };
};

export const getPushNotificationsLoadingAction = () =>
  loadingAction(PushNotificationsAction.GET_PUSH_NOTIFICATIONS_LOADING);

export const getPushNotificationsErrorAction = (error: string) =>
  errorAction(PushNotificationsAction.GET_PUSH_NOTIFICATIONS_ERROR, error);

export const getPushNotificationsSuccessAction = (
  data: PushNotificationsResponse
) => {
  const payload: PushNotificationsResponse = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PushNotificationsAction.GET_PUSH_NOTIFICATIONS_SUCCESS,
    payload
  };
};

export const postPushNotificationsAction = (pushNotificationData: any) => {
  return {
    type: PushNotificationsAction.POST_PUSH_NOTIFICATIONS,
    payload: pushNotificationData
  };
};

export const postPushNotificationsLoadingAction = () =>
  loadingAction(PushNotificationsAction.POST_PUSH_NOTIFICATIONS_LOADING);

export const postPushNotificationsErrorAction = (error: string) =>
  errorAction(PushNotificationsAction.POST_PUSH_NOTIFICATIONS_ERROR, error);

export const postPushNotificationsSuccessAction = (
  data: PushNotificationsResponse
) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PushNotificationsAction.POST_PUSH_NOTIFICATIONS_SUCCESS,
    payload
  };
};

export const putPushNotificationsAction = pushNotificationData => {
  return {
    type: PushNotificationsAction.PUT_PUSH_NOTIFICATIONS,
    payload: pushNotificationData
  };
};

export const putPushNotificationsLoadingAction = () =>
  loadingAction(PushNotificationsAction.PUT_PUSH_NOTIFICATIONS_LOADING);

export const putPushNotificationsErrorAction = (error: string) =>
  errorAction(PushNotificationsAction.PUT_PUSH_NOTIFICATIONS_ERROR, error);

export const putPushNotificationsSuccessAction = (
  data: PushNotificationsResponse
) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PushNotificationsAction.PUT_PUSH_NOTIFICATIONS_SUCCESS,
    payload
  };
};

export const appBasicSettingsAction = (data, navItem) => {
  return {
    type: AppBasicSettingsAction.UPDATE_APP_BASIC_INFO,
    payload: { navItem: navItem, data: data }
  };
};

export const appBasicSettingsLoadingAction = () =>
  loadingAction(AppBasicSettingsAction.UPDATE_APP_BASIC_INFO_LOADING);

export const appBasicSettingsErrorAction = (error: string) =>
  errorAction(AppBasicSettingsAction.UPDATE_APP_BASIC_INFO_ERROR, error);

export const appBasicSettingsSuccessAction = (data: AppBasicInfoSchema) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AppBasicSettingsAction.UPDATE_APP_BASIC_INFO_SUCCESS,
    payload
  };
};
export const RiskBasedAuthenticationAction = {
  GET_RISK_AUTH_SETTINGS: "GET_RISK_AUTH_SETTINGS",
  GET_RISK_AUTH_SETTINGS_LOADING: "GET_RISK_AUTH_SETTINGS_LOADING",
  GET_RISK_AUTH_SETTINGS_ERROR: "GET_RISK_AUTH_SETTINGS_ERROR",
  GET_RISK_AUTH_SETTINGS_SUCCESS: "GET_RISK_AUTH_SETTINGS_SUCCESS",

  UPDATE_RISK_AUTH_SETTINGS: "UPDATE_RISK_AUTH_SETTINGS",
  UPDATE_RISK_AUTH_SETTINGS_LOADING: "UPDATE_RISK_AUTH_SETTINGS_LOADING",
  UPDATE_RISK_AUTH_SETTINGS_ERROR: "UPDATE_RISK_AUTH_SETTINGS_ERROR",
  UPDATE_RISK_AUTH_SETTINGS_SUCCESS: "UPDATE_RISK_AUTH_SETTINGS_SUCCESS",

  GET_MFA_ACTIONS: "GET_MFA_ACTIONS",
  UPDATE_MFA_ACTIONS: "UPDATE_MFA_ACTIONS"
};
export const getMFAActions = () => {
  return {
    type: RiskBasedAuthenticationAction.GET_MFA_ACTIONS,
    payload: {}
  };
};
export const updateMFAActions = (data: MFAActionSchema) => {
  const payload: any = {
    ...data
  };
  return {
    type: RiskBasedAuthenticationAction.UPDATE_MFA_ACTIONS,
    payload
  };
};

export const getRiskAuthSettingsAction = () => {
  return {
    type: RiskBasedAuthenticationAction.GET_RISK_AUTH_SETTINGS,
    payload: {}
  };
};

export const getRiskAuthSettingsLoadingAction = () =>
  loadingAction(RiskBasedAuthenticationAction.GET_RISK_AUTH_SETTINGS_LOADING);

export const getRiskAuthSettingsErrorAction = (error: string) =>
  errorAction(
    RiskBasedAuthenticationAction.GET_RISK_AUTH_SETTINGS_ERROR,
    error
  );

export const getRiskAuthSettingsSuccessAction = (data: RiskAuthSchema) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: RiskBasedAuthenticationAction.GET_RISK_AUTH_SETTINGS_SUCCESS,
    payload
  };
};

export const updateRiskAuthSettingsAction = data => {
  return {
    type: RiskBasedAuthenticationAction.UPDATE_RISK_AUTH_SETTINGS,
    payload: data
  };
};

export const updateRiskAuthSettingsLoadingAction = () =>
  loadingAction(
    RiskBasedAuthenticationAction.UPDATE_RISK_AUTH_SETTINGS_LOADING
  );

export const updateRiskAuthSettingsErrorAction = (error: string) =>
  errorAction(
    RiskBasedAuthenticationAction.UPDATE_RISK_AUTH_SETTINGS_ERROR,
    error
  );

export const updateRiskAuthSettingsSuccessAction = (data: RiskAuthSchema) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: RiskBasedAuthenticationAction.UPDATE_RISK_AUTH_SETTINGS_SUCCESS,
    payload
  };
};
/**
 * APP INFO
 */

export interface AppInfoSchema extends SideEffectSchema {
  appInfo: any;
}
export const appInfoAction = () => {
  return {
    type: PasswordPolicyAction.GET_APP_INFO,
    payload: {}
  };
};

export const appInfoLoadingAction = () =>
  loadingAction(PasswordPolicyAction.GET_APP_INFO_LOADING);

export const appInfoErrorAction = (error: string) =>
  errorAction(PasswordPolicyAction.GET_APP_INFO_ERROR, error);

export const appInfoSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PasswordPolicyAction.GET_APP_INFO_SUCCESS,
    payload
  };
};

export interface PasswordCompliexitySchema extends SideEffectSchema {
  rules: string;
  clientIP: string;
}
export const getPasswordComplexityAction = () => {
  return {
    type: PasswordPolicyAction.GET_PASSWORD_COMPLEXITY,
    payload: {}
  };
};
export const getPasswordComplexityLoadingAction = () =>
  loadingAction(PasswordPolicyAction.GET_PASSWORD_COMPLEXITY_LOADIN);

export const getPasswordComplexityErrorAction = (error: string) =>
  errorAction(PasswordPolicyAction.GET_PASSWORD_COMPLEXITY_ERROR, error);

export const getPasswordComplexitySuccessAction = (
  data: PasswordCompliexitySchema
) => {
  const payload: PasswordCompliexitySchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PasswordPolicyAction.GET_PASSWORD_COMPLEXITY_SUCCESS,
    payload: payload
  };
};
//-----------------------------------------------------------------------------------
export const getPeriodicPasswordAction = () => {
  return {
    type: PasswordPolicyAction.GET_PERIODIC_PASSWORD,
    payload: {}
  };
};
export const getPeriodicPasswordLoadingAction = () =>
  loadingAction(PasswordPolicyAction.GET_PERIODIC_PASSWORD_LOADING);

export const getPeriodicPasswordErrorAction = (error: string) =>
  errorAction(PasswordPolicyAction.GET_PERIODIC_PASSWORD_ERROR, error);

export const getPeriodicPasswordSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PasswordPolicyAction.GET_PERIODIC_PASSWORD_SUCCESS,
    payload: payload
  };
};

export const getBreachedPasswordPolicyAction = () => {
  return {
    type: PasswordPolicyAction.GET_BREACHED_PASSWORD_POLICY,
    payload: {}
  };
};
export const getBreachedPasswordPolicyLoadingAction = () =>
  loadingAction(PasswordPolicyAction.GET_BREACHED_PASSWORD_POLICY_LOADING);

export const getBreachedPasswordPolicyErrorAction = (error: string) =>
  errorAction(PasswordPolicyAction.GET_BREACHED_PASSWORD_POLICY_ERROR, error);

export const getBreachedPasswordPolicySuccessAction = (data: any) => {
  const payload: any = {
    data: data,
    ...successSideEffectState
  };
  return {
    type: PasswordPolicyAction.GET_BREACHED_PASSWORD_POLICY_SUCCESS,
    payload: payload
  };
};

export const postBreachedPasswordPolicyAction = (data: any) => {
  return {
    type: PasswordPolicyAction.POST_BREACHED_PASSWORD_POLICY,
    payload: data
  };
}
export const postBreachedPasswordPolicyLoadingAction = () =>
  loadingAction(PasswordPolicyAction.POST_BREACHED_PASSWORD_POLICY_LOADING);

export const postBreachedPasswordPolicyErrorAction = (error: string) =>
  errorAction(PasswordPolicyAction.POST_BREACHED_PASSWORD_POLICY_ERROR, error);

export const postBreachedPasswordPolicySuccessAction = (data: any) => {
  const payload: any = {
    data : data,
    ...successSideEffectState
  };
  return {
    type: PasswordPolicyAction.POST_BREACHED_PASSWORD_POLICY_SUCCESS,
    payload: payload
  };
}



//---------------------------------------------------------------------
export const getPinAuthAction = () => {
  return {
    type: PinAuthAction.PIN_AUTH,
    payload: {}
  };
};
export const getPinAuthLoadingAction = () =>
  loadingAction(PinAuthAction.PIN_AUTH_LOADING);

export const getPinAuthErrorAction = (error: string) =>
  errorAction(PinAuthAction.PIN_AUTH_ERROR, error);

export const getPinAuthSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PinAuthAction.PIN_AUTH_SUCCESS,
    payload: payload
  };
};
export const updatePinAuthAction = (data: PinAuthSchema) => {
  return {
    type: PinAuthAction.UPDATE_PIN_AUTH,
    payload: data
  };
};
export const updatePinAuthLoadingAction = () =>
  loadingAction(PinAuthAction.UPDATE_PIN_AUTH_LOADING);

export const updatePinAuthErrorAction = (error: string) =>
  errorAction(PinAuthAction.UPDATE_PIN_AUTH_ERROR, error);

export const updatePinAuthSuccessAction = (data: UpdatePinAuthSchema) => {
  const payload: UpdatePinAuthSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PinAuthAction.UPDATE_PIN_AUTH_SUCCESS,
    payload: payload
  };
};

export const getSecurityQuestionsAction = () => {
  return {
    type: SecurityQuestionsAction.GET_SECURITY_QUESTIONS,
    payload: {}
  };
};

export interface SecurityQuestionsSchema extends SideEffectSchema {
  Question?: any[];
  RenderCount?: any;
}
export const getSecurityQuestionsLoadingAction = () =>
  loadingAction(SecurityQuestionsAction.GET_SECURITY_QUESTIONS_LOADING);

export const getSecurityQuestionsErrorAction = (error: string) =>
  errorAction(SecurityQuestionsAction.GET_SECURITY_QUESTIONS_ERROR, error);

export const getSecurityQuestionsSuccessAction = (
  data: SecurityQuestionsSchema
) => {
  const payload: SecurityQuestionsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: SecurityQuestionsAction.GET_SECURITY_QUESTIONS_SUCCESS,
    payload: payload
  };
};

export interface WebhookSecretsSchema extends SideEffectSchema {
  Data: any[];
}

export const getWebhookSecretsAction = () => {
  return {
    type: WebhookSecretsAction.GET_WEBHOOK_SECRETS_OPTIONS,
    payload: {}
  };
};
export const getWebhookSecretsLoadingAction = () =>
  loadingAction(WebhookSecretsAction.GET_WEBHOOK_SECRETS_OPTIONS_LOADING);

export const getWebhookSecretsErrorAction = (error: string) =>
  errorAction(WebhookSecretsAction.GET_WEBHOOK_SECRETS_OPTIONS_ERROR, error);

export const getWebhookSecretsSuccessAction = (data: WebhookSecretsSchema) => {
  const payload: WebhookSecretsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: WebhookSecretsAction.GET_WEBHOOK_SECRETS_OPTIONS_SUCCESS,
    payload: payload
  };
};

export interface SavePeriodicSchema {
  ExpirationFrequencyType: string;
  ExpirationFrequency: number;
}
export interface PeriodicSchema extends SideEffectSchema {
  ExpirationFrequencyType: string;
  ExpirationFrequency: number;
}

//---------------------------------------------------------------------
export interface SavePasswordHistorySchema {
  MaxPassWordHistory: number;
}
export interface PasswordHistory extends SideEffectSchema {
  MaxPassWordHistory: number;
}

import { combineReducers } from "redux";
import { ActionSchema } from "../actions";
import {
  ApiMetricsConst,
  GetRequestCountDataSchema,
  GetResponseCodeDataSchema,
  GetPerformanceDataSchema,
  GetSmsAnalyticsDataSchema,
  GetSmsStatusDataSchema,
  GetLogsDataSchema
} from "../actions/apiMetricsActions";

// Reducers
const getRequestCountDataReducer = (
  state = {},
  action: ActionSchema<GetRequestCountDataSchema>
) => {
  switch (action.type) {
    case ApiMetricsConst.GET_REQUEST_COUNT_DATA_LOADING:
    case ApiMetricsConst.GET_REQUEST_COUNT_DATA_ERROR:
    case ApiMetricsConst.GET_REQUEST_COUNT_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const getResponseCodeDataReducer = (
  state = {},
  action: ActionSchema<GetResponseCodeDataSchema>
) => {
  switch (action.type) {
    case ApiMetricsConst.GET_RESPONSE_CODE_DATA_LOADING:
    case ApiMetricsConst.GET_RESPONSE_CODE_DATA_ERROR:
    case ApiMetricsConst.GET_RESPONSE_CODE_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const getPerformanceDataReducer = (
  state = {},
  action: ActionSchema<GetPerformanceDataSchema>
) => {
  switch (action.type) {
    case ApiMetricsConst.GET_PERFORMANCE_DATA_LOADING:
    case ApiMetricsConst.GET_PERFORMANCE_DATA_ERROR:
    case ApiMetricsConst.GET_PERFORMANCE_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const getSmsAnalyticsDataReducer = (
  state = {},
  action: ActionSchema<GetSmsAnalyticsDataSchema>
) => {
  switch (action.type) {
    case ApiMetricsConst.GET_SMS_ANALYTICS_DATA_LOADING:
    case ApiMetricsConst.GET_SMS_ANALYTICS_DATA_ERROR:
    case ApiMetricsConst.GET_SMS_ANALYTICS_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const getSmsStatusDataReducer = (
  state = {},
  action: ActionSchema<GetSmsStatusDataSchema>
) => {
  switch (action.type) {
    case ApiMetricsConst.GET_SMS_STATUS_DATA_LOADING:
    case ApiMetricsConst.GET_SMS_STATUS_DATA_ERROR:
    case ApiMetricsConst.GET_SMS_STATUS_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const getLogsDataReducer = (
  state = {},
  action: ActionSchema<GetLogsDataSchema>
) => {
  switch (action.type) {
    case ApiMetricsConst.GET_LOGS_DATA_LOADING:
    case ApiMetricsConst.GET_LOGS_DATA_ERROR:
    case ApiMetricsConst.GET_LOGS_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

// Combine Reducers
const apiMetricsReducer = combineReducers({
  requestCount: getRequestCountDataReducer,
  responseCode: getResponseCodeDataReducer,
  performanceData: getPerformanceDataReducer,
  smsAnalytics: getSmsAnalyticsDataReducer,
  smsStatus: getSmsStatusDataReducer,
  logs: getLogsDataReducer
});

// Combine Schemas
export interface ApiMetricsSchema {
  requestCount: GetRequestCountDataSchema;
  responseCode: GetResponseCodeDataSchema;
  performanceData: GetPerformanceDataSchema;
  smsAnalytics: GetSmsAnalyticsDataSchema;
  smsStatus: GetSmsStatusDataSchema;
  logs: GetLogsDataSchema;
}

export default apiMetricsReducer;

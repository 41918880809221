import { combineReducers } from "redux";
import { ActionSchema } from "../actions";
import {
  AccountConst,
  AccountPlanConst,
  GetRequestProfileDataSchema,
  UpdateRequestProfileDataSchema,
  GetAccountCredentialDataSchema,
  GetAccountLinkingDataSchema,
  GetRequestAccountLoginSessionDataSchema,
  GetSitesSubscriptionListSchema,
  GetRequestAccount2FADataSchema,
  Account2FASuccessDataSchema,
  AccountSMS2FAVerifyDataSchema,
  AccountBackupCodesDataSchema,
  GetAccountThemeCustomizationSchema
} from "../actions/accountActions";
import { SideEffectSchema } from "../actions/";
//
//plan Detail
const appPlanDetailsReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case AccountPlanConst.UPDATE_PLAN_DETAIL_LOADING:
    case AccountPlanConst.UPDATE_PLAN_DETAIL_ERROR:
    case AccountPlanConst.UPDATE_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
const accountStoreClearReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case AccountConst.ACCOUNT_STORE_CLEAR_API_RESPONSE_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const sitesSubscriptionListReducer = (
  state = {},
  action: ActionSchema<GetSitesSubscriptionListSchema>
) => {
  switch (action.type) {
    case AccountConst.GET_SITES_SUBSCRIPTION_LIST_LOADING:
    case AccountConst.GET_SITES_SUBSCRIPTION_LIST_ERROR:
    case AccountConst.GET_SITES_SUBSCRIPTION_LIST_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const switchToOwnedSiteReducer = (
  state = {},
  action: ActionSchema<SideEffectSchema>
) => {
  switch (action.type) {
    case AccountConst.SWITCH_TO_OWNED_SITE_RESET:
    case AccountConst.SWITCH_TO_OWNED_SITE_SUCCESS:
    case AccountConst.SWITCH_TO_OWNED_SITE_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const switchToSharedSiteReducer = (
  state = {},
  action: ActionSchema<SideEffectSchema>
) => {
  switch (action.type) {
    case AccountConst.SWITCH_TO_SHARED_SITE_RESET:
    case AccountConst.SWITCH_TO_SHARED_SITE_SUCCESS:
    case AccountConst.SWITCH_TO_SHARED_SITE_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const AccountGetProfileReducer = (
  state = {},
  action: ActionSchema<GetRequestProfileDataSchema>
) => {
  switch (action.type) {
    case AccountConst.GET_REQUEST_PROFILE_DATA_LOADING:
    case AccountConst.GET_REQUEST_PROFILE_DATA_ERROR:
    case AccountConst.GET_REQUEST_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountUpdateProfileReducer = (
  state = {},
  action: ActionSchema<UpdateRequestProfileDataSchema>
) => {
  switch (action.type) {
    case AccountConst.UPDATE_REQUEST_PROFILE_DATA_LOADING:
    case AccountConst.UPDATE_REQUEST_PROFILE_DATA_ERROR:
    case AccountConst.UPDATE_REQUEST_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountGetCredentialReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case AccountConst.GET_REQUEST_ACCOUNT_SITE_CREDENTIAL_LOADING:
    case AccountConst.GET_REQUEST_ACCOUNT_SITE_CREDENTIAL_ERROR:
    case AccountConst.GET_REQUEST_ACCOUNT_SITE_CREDENTIAL_SUCCESS:
    case AccountConst.UPDATE_REQUEST_ACCOUNT_SITE_SETTING_LOADING:
    case AccountConst.UPDATE_REQUEST_ACCOUNT_SITE_SETTING_ERROR:
    case AccountConst.UPDATE_REQUEST_ACCOUNT_SITE_SETTING_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountGetLoginSessionReducer = (
  state = {},
  action: ActionSchema<GetRequestAccountLoginSessionDataSchema>
) => {
  switch (action.type) {
    case AccountConst.GET_REQUEST_ACCOUNT_SESSION_LOADING:
    case AccountConst.GET_REQUEST_ACCOUNT_SESSION_ERROR:
    case AccountConst.GET_REQUEST_ACCOUNT_SESSION_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountUpdateLogOutSessionReducer = (
  state = {},
  action: ActionSchema<any>
) => {
  switch (action.type) {
    case AccountConst.UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT_LOADING:
    case AccountConst.UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT_ERROR:
    case AccountConst.UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountUpdateLinkingReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case AccountConst.UPDATE_ACCOUTN_SOCIAL_LINKING_LOADING:
    case AccountConst.UPDATE_ACCOUTN_SOCIAL_LINKING_ERROR:
    case AccountConst.UPDATE_ACCOUTN_SOCIAL_LINKING_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountGet2FAReducer = (
  state = {},
  action: ActionSchema<GetRequestAccount2FADataSchema>
) => {
  switch (action.type) {
    case AccountConst.GET_REQUEST_ACCOUNT_2FA_LOADING:
    case AccountConst.GET_REQUEST_ACCOUNT_2FA_ERROR:
    case AccountConst.GET_REQUEST_ACCOUNT_2FA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountPostGoogle2FAReducer = (
  state = {},
  action: ActionSchema<Account2FASuccessDataSchema>
) => {
  switch (action.type) {
    case AccountConst.POST_REQUEST_ACCOUNT_GOOGLE_2FA_LOADING:
    case AccountConst.POST_REQUEST_ACCOUNT_GOOGLE_2FA_ERROR:
    case AccountConst.POST_REQUEST_ACCOUNT_GOOGLE_2FA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountDeleteGoogle2FAReducer = (
  state = {},
  action: ActionSchema<Account2FASuccessDataSchema>
) => {
  switch (action.type) {
    case AccountConst.DELETE_REQUEST_ACCOUNT_GOOGLE_2FA_LOADING:
    case AccountConst.DELETE_REQUEST_ACCOUNT_GOOGLE_2FA_ERROR:
    case AccountConst.DELETE_REQUEST_ACCOUNT_GOOGLE_2FA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountPostSMSVerify2FAReducer = (
  state = {},
  action: ActionSchema<AccountSMS2FAVerifyDataSchema>
) => {
  switch (action.type) {
    case AccountConst.POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA_LOADING:
    case AccountConst.POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA_ERROR:
    case AccountConst.POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountPostSMS2FAReducer = (
  state = {},
  action: ActionSchema<Account2FASuccessDataSchema>
) => {
  switch (action.type) {
    case AccountConst.POST_REQUEST_ACCOUNT_SMS_2FA_LOADING:
    case AccountConst.POST_REQUEST_ACCOUNT_SMS_2FA_ERROR:
    case AccountConst.POST_REQUEST_ACCOUNT_SMS_2FA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountDeleteSMS2FAReducer = (
  state = {},
  action: ActionSchema<Account2FASuccessDataSchema>
) => {
  switch (action.type) {
    case AccountConst.DELETE_REQUEST_ACCOUNT_SMS_2FA_LOADING:
    case AccountConst.DELETE_REQUEST_ACCOUNT_SMS_2FA_ERROR:
    case AccountConst.DELETE_REQUEST_ACCOUNT_SMS_2FA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountGetBackupCodesReducer = (
  state = {},
  action: ActionSchema<AccountBackupCodesDataSchema>
) => {
  switch (action.type) {
    case AccountConst.GET_REQUEST_ACCOUNT_BACKUP_CODES_LOADING:
    case AccountConst.GET_REQUEST_ACCOUNT_BACKUP_CODES_ERROR:
    case AccountConst.GET_REQUEST_ACCOUNT_BACKUP_CODES_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountGetResetBackupCodesReducer = (
  state = {},
  action: ActionSchema<AccountBackupCodesDataSchema>
) => {
  switch (action.type) {
    case AccountConst.GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES_LOADING:
    case AccountConst.GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES_ERROR:
    case AccountConst.GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const GetAccountThemeCustomizationReducer = (
  state = {},
  action: ActionSchema<GetAccountThemeCustomizationSchema>
) => {
  switch (action.type) {
    case AccountConst.GET_ACCOUNT_THEME_CUSTOMIZATION_LOADING:
    case AccountConst.GET_ACCOUNT_THEME_CUSTOMIZATION_ERROR:
    case AccountConst.GET_ACCOUNT_THEME_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountGetUpgrade2FAReducer = (
  state = {},
  action: ActionSchema<GetRequestAccount2FADataSchema>
) => {
  switch (action.type) {
    case AccountConst.GET_REQUEST_UPGRADE_ACCOUNT_2FA_LOADING:
    case AccountConst.GET_REQUEST_UPGRADE_ACCOUNT_2FA_ERROR:
    case AccountConst.GET_REQUEST_UPGRADE_ACCOUNT_2FA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
const AccountPostGoogleUpgrade2FAReducer = (
  state = {},
  action: ActionSchema<Account2FASuccessDataSchema>
) => {
  switch (action.type) {
    case AccountConst.POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA_LOADING:
    case AccountConst.POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA_ERROR:
    case AccountConst.POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default: {
      return state;
    }
  }
};
export const AccountCombineReducer = combineReducers({
  AccountGetProfile: AccountGetProfileReducer,
  AccountUpdateProfile: AccountUpdateProfileReducer,
  AccountGetCredential: AccountGetCredentialReducer,
  AccountLoginSession: AccountGetLoginSessionReducer,
  AccountLogOutSession: AccountUpdateLogOutSessionReducer,
  AccountLinking: AccountUpdateLinkingReducer,
  Account2FA: AccountGet2FAReducer,
  AccountPostGoogle2FA: AccountPostGoogle2FAReducer,
  AccountDeleteGoogle2FA: AccountDeleteGoogle2FAReducer,
  AccountPostSMSVerify2FA: AccountPostSMSVerify2FAReducer,
  AccountPostSMS2FA: AccountPostSMS2FAReducer,
  AccountDeleteSMS2FA: AccountDeleteSMS2FAReducer,
  AccountGetBackupCodes: AccountGetBackupCodesReducer,
  AccountGetResetBackupCodes: AccountGetResetBackupCodesReducer,
  sitesSubscriptions: sitesSubscriptionListReducer,
  switchToOwnedSiteStatus: switchToOwnedSiteReducer,
  switchToSharedSiteStatus: switchToSharedSiteReducer,
  accountThemeCustomization: GetAccountThemeCustomizationReducer,
  planUpdateDetail: appPlanDetailsReducer,
  upgrade2FADetail: AccountGetUpgrade2FAReducer,
  AccountPostGoogleUpgrade2FA: AccountPostGoogleUpgrade2FAReducer
});
export interface ApiAccountSchema {
  AccountGetProfile: GetRequestProfileDataSchema;
  AccountUpdateProfile: UpdateRequestProfileDataSchema;
  AccountGetCredential: GetAccountCredentialDataSchema;
  AccountLoginSession: GetRequestAccountLoginSessionDataSchema;
  Account2FA: GetRequestAccount2FADataSchema;
  AccountPostGoogle2FA: Account2FASuccessDataSchema;
  AccountDeleteGoogle2FA: Account2FASuccessDataSchema;
  AccountPostSMSVerify2FA: AccountSMS2FAVerifyDataSchema;
  AccountPostSMS2FA: Account2FASuccessDataSchema;
  AccountDeleteSMS2FA: Account2FASuccessDataSchema;
  AccountGetBackupCodes: AccountBackupCodesDataSchema;
  AccountGetResetBackupCodes: AccountBackupCodesDataSchema;
  AccountLogOutSession: any;
  AccountLinking: GetAccountLinkingDataSchema;
  sitesSubscriptions: GetSitesSubscriptionListSchema;
  switchToOwnedSiteStatus: SideEffectSchema;
  switchToSharedSiteStatus: SideEffectSchema;
  accountThemeCustomization: GetAccountThemeCustomizationSchema;
  planUpdateDetail: any;
  upgrade2FADetail: GetRequestAccount2FADataSchema;
  AccountPostGoogleUpgrade2FA: Account2FASuccessDataSchema;
}

export default AccountCombineReducer;

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  removeSymbols,
  lowercaseFirstLetter,
  toTitleCase,
  _getFormattedPath,
  SpecialCapsConversion
} from "../../utils";
import { RootSchema } from "../../redux/reducers";
import { LanguageSchema } from "../../i18n";

import { AppUISchema } from "./../../redux/actions/appStateActions";
import Input from "../Input";
import ReactDOM from "react-dom";
import { SearchJsonSchema } from "../../redux/actions/authActions";
export interface NavUserDropdownProps {
  name: string;
  email: string;
  thumbnail: string;
  sites: string[];
  parentLink: string;
  sharedSites: string[];
  //TODO : Fix schema Type
  subsections: any;
  appUI: AppUISchema;
  tabIndex?: number;
  navigationHandler: () => void;
  switchSiteHandler: (siteName: string) => void;
  IOLoginUrl: string;
  i18n: LanguageSchema;
  handleLinkClick: (name: string) => void;
  currentActive: string;
  handleLinkFocus: (name: string) => void;
  currentFocused: string;
  isSsoOnly: boolean;
  searchResultJson: SearchJsonSchema;
}
export interface NavUserDropdownState {
  isShowing: boolean;
  isFocused: boolean;
  searchTerm: string;
  showSearchResult: string;
}

interface NavUserNotificationsProps {
  count: number;
}

const NavUserNotifications: React.FC<NavUserNotificationsProps> = props => {
  const { count } = props;
  return (
    <li className="nav-item">
      <a className="nav-link waves-effect waves-light custom-notification-icon">
        {count}
        <i className="fas fa-envelope" />
      </a>
      <a className="nav-link waves-effect waves-light custom-notification-text">
        Notifications <i className="fas fa-envelope" />({count})
      </a>
    </li>
  );
};

const siteTypes = {
  OWNED_SITES: "owned-site-",
  SHARED_SITES: "shared-site-"
};

const userNavOptionsIconSuffixes = {
  "My Account": "person_outline",
  "Team Management": "people_outline",
  Appearance: "theme_customization",
  Billing: "registration_forms"
};

const dropdownIcons = {
  arrowRight: "lr-material-keyboard_arrow_right",
  arrowDown: "lr-material-keyboard_arrow_down"
};

/**
 * Handler for click on one of the two elements that control the site list dropdowns: extends the dropdown for the site list if the dropdown
 * is not showing; otherwise, extends the dropdown.
 * @param siteListType  Indicates for which type of site list (owned or shared) the handling must be done; can be one of the two values in
 *   object siteTypes.
 */
function siteListClickHandler(siteListType: string) {
  if (
    siteListType !== siteTypes.OWNED_SITES &&
    siteListType !== siteTypes.SHARED_SITES
  ) {
    return;
  }
  const siteList = document.getElementsByClassName(siteListType);
  if (siteList.length < 1) {
    return;
  }
  const dropdownIcon = document.getElementsByClassName(
    siteListType + "dropdown"
  );
  if (siteList[0].classList.contains("d-none")) {
    for (let i = 0; i < siteList.length; i++) {
      siteList[i].classList.remove("d-none");
    }
    if (dropdownIcon[0]) {
      dropdownIcon[0].classList.remove(dropdownIcons.arrowRight);
      dropdownIcon[0].classList.add(dropdownIcons.arrowDown);
    }
  } else {
    for (let i = 0; i < siteList.length; i++) {
      siteList[i].classList.add("d-none");
    }
    if (dropdownIcon[0]) {
      dropdownIcon[0].classList.remove(dropdownIcons.arrowDown);
      dropdownIcon[0].classList.add(dropdownIcons.arrowRight);
    }
  }
}

const assignClickHandlerForYourSitesToggle = setInterval(() => {
  const yourSitesToggle = document.getElementById("lnk_yourSites");
  if (yourSitesToggle !== null) {
    yourSitesToggle.addEventListener("click", e => {
      siteListClickHandler(siteTypes.OWNED_SITES);
      e.stopPropagation();
    });
    yourSitesToggle.addEventListener("keyup", e => {
      if (e.key === "Enter") {
        siteListClickHandler(siteTypes.OWNED_SITES);
        e.stopPropagation();
      }
    });
    clearInterval(assignClickHandlerForYourSitesToggle);
  }
}, 1000);

const assignHandlerForSharedSitesDropdown = setInterval(() => {
  const sharedSitesToggle = document.getElementById("lnk_sharedSites");
  if (sharedSitesToggle !== null) {
    sharedSitesToggle.addEventListener("click", e => {
      siteListClickHandler(siteTypes.SHARED_SITES);
      e.stopPropagation();
    });
    sharedSitesToggle.addEventListener("keyup", e => {
      if (e.key === "Enter") {
        siteListClickHandler(siteTypes.SHARED_SITES);
        e.stopPropagation();
      }
    });
    clearInterval(assignHandlerForSharedSitesDropdown);
  }
}, 1000);

class NavUserDropdown extends React.Component<
  NavUserDropdownProps,
  NavUserDropdownState
> {
  private mySearchResultRef: React.RefObject<any>;
  private myRef: React.RefObject<any>;

  constructor(props: NavUserDropdownProps) {
    super(props);
    this.mySearchResultRef = React.createRef();
    this.myRef = React.createRef();

    this.state = {
      isShowing: false,
      isFocused: false,
      searchTerm: "",
      showSearchResult: ""
    };
  }

  focusFirstElement = () => {
    const { subsections } = this.props;
    if (Array.isArray(subsections)) {
      const { displayName } = subsections[0];
      const firstDropdownItem = document.getElementById(
        `lnk_${lowercaseFirstLetter(removeSymbols(displayName))}`
      );
      if (firstDropdownItem instanceof HTMLAnchorElement) {
        firstDropdownItem.focus();
      }
    }
  };
  renderSearchResults = searchTerm => {
    const { searchResultJson } = this.props;
    const SearchJsonSchemaData = searchResultJson.data;
    let result: JSX.Element[] = [];
    searchTerm = searchTerm.toLowerCase();
    var searchTermWithoutSpaces = searchTerm.replace(/ /g, "");
    for (var i = 0; i < SearchJsonSchemaData.length; i++) {
      var hrefText = "";
      if (SearchJsonSchemaData[i].href && SearchJsonSchemaData[i].text) {
        var _hrefText = (SearchJsonSchemaData[i].text || "").toLowerCase();
        hrefText = _hrefText + "," + _hrefText.replace(/ /g, "");
      }
      var tags = "";
      if ((SearchJsonSchemaData[i].tags || []).length) {
        tags = (SearchJsonSchemaData[i].tags || []).join(",");
        tags = tags.toLowerCase();
      }
      var link = SearchJsonSchemaData[i].link.replace(/-/g, "");
      link = link.split("/").join(",");
      link = link.toLowerCase();
      var linkWithoutSpaces = tags.replace(/ /g, "");
      var tagsWithoutSpaces = tags.replace(/ /g, "");
      var data = SearchJsonSchemaData[i].href
        ? hrefText
        : tags + "," + tagsWithoutSpaces + "," + link + "," + linkWithoutSpaces;
      if (
        data &&
        (data.indexOf(searchTerm) > -1 ||
          data.indexOf(searchTermWithoutSpaces) > -1 ||
          searchTerm.indexOf(data) > -1)
      ) {
        var title = SearchJsonSchemaData[i].link.split("/").pop();
        /* eslint-disable no-useless-escape */
        title = title && toTitleCase(title.replace(/\-/g, " "));
        if (title) {
          for (let conversion of SpecialCapsConversion) {
            title = title.replace(conversion.from, conversion.to);
          }
        }
        const pathToArray = _getFormattedPath(
          SearchJsonSchemaData[i].link
        ).split("/");
        pathToArray.shift();
        const breadcrumbContent = pathToArray.map((p, index) => {
          let _path = "";
          if (index === 0) _path = "";
          _path += index + 1 === pathToArray.length ? p : p + " > ";
          return (
            <React.Fragment key={index}>
              {index + 1 !== pathToArray.length && (
                <React.Fragment>
                  <span>{p}</span>
                  <span>{index + 2 === pathToArray.length ? "" : " > "}</span>
                </React.Fragment>
              )}
            </React.Fragment>
          );
        });
        result.push(
          <li>
            <Link
              to={SearchJsonSchemaData[i].link}
              onClick={() => {
                this.mySearchResultRef.current.scrollTo(0, 0);
                this.setState({
                  showSearchResult: "inactive",
                  searchTerm: ""
                });
              }}
              tabIndex={0}
            >
              {SearchJsonSchemaData[i].href
                ? SearchJsonSchemaData[i].text
                : title}
              <p>{SearchJsonSchemaData[i].href ? "" : breadcrumbContent}</p>{" "}
            </Link>
          </li>
        );
      }
    }
    return (
      <React.Fragment>
        {result.length ? (
          <ul>{result}</ul>
        ) : (
          <div className="search-nodata">
            <svg
              width="72"
              height="72"
              viewBox="0 0 72 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.9999 69.4286C54.4622 69.4286 69.4284 54.4624 69.4284 36C69.4284 17.5379 54.4622 2.57144 35.9999 2.57144C17.5378 2.57144 2.57129 17.5379 2.57129 36C2.57129 54.4624 17.5378 69.4286 35.9999 69.4286Z"
                stroke="#B3B6B8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.0283 54C21.5997 44.7429 31.3712 39.0857 41.1426 41.6572C46.7997 43.2 51.4283 47.8286 53.4855 54"
                stroke="#B3B6B8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M24.9429 28.0286C24.2329 28.0286 23.6572 27.4529 23.6572 26.7429C23.6572 26.0328 24.2329 25.4572 24.9429 25.4572"
                stroke="#B3B6B8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M24.9424 28.0286C25.6525 28.0286 26.2281 27.4529 26.2281 26.7429C26.2281 26.0328 25.6525 25.4572 24.9424 25.4572"
                stroke="#B3B6B8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M47.5709 28.0286C46.8608 28.0286 46.2852 27.4529 46.2852 26.7429C46.2852 26.0328 46.8608 25.4572 47.5709 25.4572"
                stroke="#B3B6B8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M47.5713 28.0286C48.2814 28.0286 48.857 27.4529 48.857 26.7429C48.857 26.0328 48.2814 25.4572 47.5713 25.4572"
                stroke="#B3B6B8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p>
              Your search did not match any results. Try another keyword or
              browse through the navigation menu.
            </p>
          </div>
        )}
      </React.Fragment>
    );
  };

  handleClick = () => {
    this.setState({ isShowing: false, isFocused: false });
    this.props.navigationHandler();
  };

  handleBlur = (event: React.FocusEvent) => {
    this.props.handleLinkFocus(removeSymbols(this.props.name));
    this.setState({ isFocused: event.target === document.activeElement });
  };

  handleKeyUp = (event: React.KeyboardEvent) => {
    this.props.handleLinkFocus(removeSymbols(this.props.name));
    event.persist();
    this.setState(
      { isFocused: event.target === document.activeElement },
      () => {
        if (event.key === "Enter") {
          this.setState(
            prevState => ({
              isShowing: !prevState.isShowing,
              isFocused: false
            }),
            () => {
              this.props.handleLinkClick(removeSymbols(this.props.name));
              this.props.handleLinkFocus(removeSymbols(this.props.name));
              // Using a 0ms setTimeout so it gets added to the event queue, because otherwise, the DOM element won't be focusable yet
              setTimeout(this.focusFirstElement, 0);
            }
          );
        } else if (event.key === "Escape") {
          this.setState({ isShowing: false });
        }
      }
    );
  };

  handleListItemClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    this.props.handleLinkClick(removeSymbols(this.props.name));
    this.props.handleLinkFocus(removeSymbols(this.props.name));

    this.setState({
      // isFocused: false,
      isShowing: false
    });
  };

  generateFirstFourSharedSites = () => {
    const { navigationHandler, sharedSites } = this.props;

    return sharedSites.slice(0, 4).map((sharedSite, index) => (
      <Link
        id={"lnk_switchToSharedSite" + String.fromCharCode(65 + index)}
        key={index}
        className={"dropdown-item d-none " + siteTypes.SHARED_SITES}
        to={location.pathname}
        onClick={() => {
          navigationHandler();
          this.props.switchSiteHandler(sharedSite);
        }}
        onKeyUp={(event: React.KeyboardEvent) => {
          if (event.key === "Enter") {
            navigationHandler();
            this.props.switchSiteHandler(sharedSite);
          }
        }}
      >
        {/** The icon below is used to ensure alignment of the shared site names with the Shared Sites title: I could have used
         CSS for margin to the left of the Link component, but this ensures alignment and maintains on its own.*/}
        <span className="lr-material-keyboard_arrow_right user-nav-option-icon" />
        <span>{sharedSite}</span>
      </Link>
    ));
  };

  generateSubsections = () => {
    const { parentLink, subsections, isSsoOnly } = this.props;

    if (subsections && !isSsoOnly)
      return subsections.map((section, sectionIndex) => {
        if (section.displayName) {
          return (
            <Link
              id={`lnk_${lowercaseFirstLetter(
                removeSymbols(section.displayName)
              )}`}
              key={sectionIndex}
              className="dropdown-item"
              to={parentLink + section.link}
              onClick={this.handleClick}
              onKeyUp={this.handleLinkKeyUp}
              onKeyDown={event => this.handleLinkKeyDown(event, sectionIndex)}
            >
              <span
                className={`lr-material-${
                  userNavOptionsIconSuffixes[section.displayName]
                }  user-nav-option-icon`}
              />
              <span>{section.displayName}</span>
            </Link>
          );
        }
      });
  };

  handleLinkKeyUp = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === "Enter" || event.key === "Escape") {
      this.setState({ isShowing: false });
    }
  };
  componentWillReceiveProps(nextProps: NavUserDropdownProps) {
    let { appUI } = { ...nextProps };
    const { name } = { ...this.props };
    const { isShowing } = { ...this.state };
    if (
      isShowing &&
      appUI.menuChangeTrack &&
      appUI.menuChangeTrack !== removeSymbols(name)
    ) {
      this.setState({ isShowing: false });
    }
  }
  handleLinkKeyDown = (event: React.KeyboardEvent, sectionIndex) => {
    event.stopPropagation();

    if (sectionIndex === 0 && event.shiftKey && event.key === "Tab") {
      this.setState({ isShowing: false }, () => {
        this.props.navigationHandler();
      });
    }
  };
  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }
  handleClickOutside = event => {
    const domNode = this.myRef.current;
    if (!domNode || !domNode.contains(event.target)) {
      if (this.mySearchResultRef && this.mySearchResultRef.current) {
        this.mySearchResultRef.current.scrollTo(0, 0);
      }
      //document.body.classList.remove("search-open");
      this.setState({
        showSearchResult: "inactive",
        searchTerm: ""
      });
    }
  };

  render() {
    const {
      name,
      email,
      navigationHandler,
      sites,
      sharedSites,
      tabIndex,
      IOLoginUrl,
      i18n,
      appUI
    } = this.props;

    const { searchTerm, showSearchResult } = this.state;

    const yourSiteDisplayCount =
      sites.length > 4 ? " (4/" + sites.length + ")" : "";
    const sharedSiteDisplayCount =
      sharedSites.length > 4 ? " (4/" + sharedSites.length + ")" : "";
    return (
      <React.Fragment>
        <div className="navbar-nav custom-nav-items-right">
          <div className="search" ref={this.myRef}>
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              className={showSearchResult}
              onClick={e => {
                //document.body.classList.add("search-open");
                this.setState({ showSearchResult: "active" }, () => {
                  this.mySearchResultRef.current.scrollTo(0, 0);
                });
              }}
              onChange={e => {
                this.setState({
                  searchTerm: e.target.value || "",
                  showSearchResult: "active"
                });
              }}
              onKeyUp={e => {
                if (e.keyCode === 13) {
                  this.setState({ searchTerm: searchTerm });
                }
              }}
            />
            <div className="search-result" ref={this.mySearchResultRef}>
              {showSearchResult === "active" &&
                this.renderSearchResults(searchTerm)}
            </div>
          </div>
          <ul>
            {/*<NavUserNotifications count={12} />*/}
            <li
              className={`nav-item avatar dropdown ml-auto ${
                this.state.isShowing ||
                this.props.currentFocused === removeSymbols(name)
                  ? "show"
                  : ""
              }`}
              tabIndex={-1}
              onClick={this.handleListItemClick}
            >
              <a
                className={
                  this.props.currentActive === removeSymbols(name) ||
                  appUI.menuChangeTrack === removeSymbols(name)
                    ? "nav-link dropdown-toggle active"
                    : "nav-link dropdown-toggle"
                }
                id="lnk_user_dropdown"
                aria-haspopup="true"
                aria-expanded={this.state.isFocused || this.state.isShowing}
                data-toggle="dropdown"
                role="button"
                tabIndex={tabIndex}
                onKeyUp={this.handleKeyUp}
                onBlur={this.handleBlur}
                onClick={this.handleListItemClick}
              >
                <span className="dropdown-label">Account</span>
                <div className="d-flex align-items-center justify-content-end ">
                  <div className="nav-user-info d-flex flex-md-column w-75 text-right pr-2">
                    <span
                      title={name}
                      id="spn_NavSelectedSiteUsername"
                      className="user-full-name text-truncate"
                    >
                      {name}
                    </span>
                    <span
                      title={email}
                      id="spn_NavSelectedSiteEmail"
                      className="user-email text-truncate"
                    >
                      {email}
                    </span>
                  </div>
                  <div className="rounded-circle z-depth-0 custom-navbar-thumbnail">
                    <svg
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <defs />
                      <title>user-icon</title>
                      <circle className="cls-1" cx="256" cy="256" r="256" />
                      <path
                        className="cls-2"
                        d="M311.3,285.63c-17.71,0-26.23,9.87-55.3,9.87s-37.53-9.87-55.3-9.87a83,83,0,0,0-82.95,82.94v15.81A29.63,29.63,0,0,0,147.38,414H364.63a29.63,29.63,0,0,0,29.62-29.62V368.57A83,83,0,0,0,311.3,285.63Zm53.33,98.75H147.38V368.57a53.41,53.41,0,0,1,53.32-53.32c9,0,23.64,9.88,55.3,9.88,31.91,0,46.23-9.88,55.3-9.88a53.42,53.42,0,0,1,53.33,53.32ZM256,275.75a88.88,88.88,0,1,0-88.87-88.87A88.9,88.9,0,0,0,256,275.75Zm0-148.12a59.25,59.25,0,1,1-59.25,59.25A59.34,59.34,0,0,1,256,127.63Z"
                      />
                    </svg>
                  </div>
                </div>
              </a>
              <div className="dropdown">
                <div
                  className={`dropdown-menu dropdown-menu-right dropdown-secondary ${
                    this.state.isShowing &&
                    this.props.currentActive === removeSymbols(name)
                      ? "show"
                      : ""
                  }`}
                >
                  <ul className="custom-nav-item-user">
                    <li className="nav-item">
                      <div className="account-dropdown p-3">
                        <span
                          title={name}
                          id="spn_DropdownSelectedSiteUsername"
                          className="user-full-name text-truncate"
                        >
                          {name}
                        </span>
                        <span
                          title={email}
                          id="spn_DropdownSelectedSiteEmail"
                          className="user-email text-truncate"
                        >
                          {email}
                        </span>
                      </div>
                      {this.generateSubsections()}
                    </li>
                    <li className="nav-item">
                      <a
                        id="lnk_yourSites"
                        className={
                          "dropdown-item custom-text-muted " +
                          (sites.length > 0 ? "" : "d-none")
                        }
                        tabIndex={0}
                      >
                        <span className="lr-material-standard_data_fields user-nav-option-icon" />
                        <span>
                          {i18n.YOUR_SITES}
                          {yourSiteDisplayCount}
                        </span>
                        <span
                          className={
                            "dropdown-icon " +
                            (siteTypes.OWNED_SITES +
                              "dropdown " +
                              dropdownIcons.arrowRight)
                          }
                        />
                      </a>
                      {sites.slice(0, 4).map((site, index) => (
                        <Link
                          id={
                            "lnk_switchToOwnedSite" +
                            String.fromCharCode(65 + index)
                          }
                          key={index}
                          className={
                            "dropdown-item d-none " + siteTypes.OWNED_SITES
                          }
                          to={location.pathname}
                          onClick={() => {
                            navigationHandler();
                            this.props.switchSiteHandler(site);
                          }}
                          onKeyUp={(event: React.KeyboardEvent) => {
                            if (event.key === "Enter") {
                              navigationHandler();
                              this.props.switchSiteHandler(site);
                            }
                          }}
                        >
                          {/** The icon below is used to ensure alignment of the shared site names with the Shared Sites title: I could have used
                       CSS for margin to the left of the Link component, but this ensures alignment and maintains on its own.*/}
                          <span className="lr-material-keyboard_arrow_right user-nav-option-icon" />
                          <span>{site}</span>
                        </Link>
                      ))}
                      <a
                        id="lnk_sharedSites"
                        className={
                          "dropdown-item custom-text-muted " +
                          (sharedSites.length > 0 ? "" : "d-none")
                        }
                        tabIndex={0}
                      >
                        <span className="lr-material-shared_sites user-nav-option-icon" />
                        <span>Shared Sites{sharedSiteDisplayCount}</span>
                        <span
                          className={
                            "dropdown-icon " +
                            (siteTypes.SHARED_SITES +
                              "dropdown " +
                              dropdownIcons.arrowRight)
                          }
                        />
                      </a>
                      {this.generateFirstFourSharedSites()}
                    </li>
                    <li className="nav-item">
                      {!IOLoginUrl && (
                        <Link
                          id="lnk_getAllSites"
                          className="dropdown-item"
                          to="/site-list"
                          onClick={navigationHandler}
                          onKeyUp={(event: React.KeyboardEvent) => {
                            event.stopPropagation();

                            if (event.key === "Enter") {
                              this.setState(
                                { isShowing: false },
                                navigationHandler
                              );
                            }
                          }}
                          tabIndex={0}
                        >
                          <span className="lr-material-get_all_sites user-nav-option-icon" />
                          <span>Get all sites</span>
                        </Link>
                      )}
                    </li>
                    <li className="nav-item">
                      <Link
                        id="lnk_logout"
                        className="dropdown-item"
                        to="/logout"
                        onClick={navigationHandler}
                        onKeyDown={(event: React.KeyboardEvent) => {
                          if (event.key === "Enter") {
                            navigationHandler();
                          } else if (event.key === "Tab") {
                            this.setState({ isShowing: false });
                          }
                        }}
                        tabIndex={0}
                      >
                        <span className="lr-material-logout user-nav-option-icon" />
                        <span>Logout</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: RootSchema) => {
  return {
    IOLoginUrl: state.auth.authConfig.IOLoginUrl,
    i18n: state.appState.i18n.languages,
    appUI: state.appState.appUI,
    searchResultJson: state.auth.searchResultJson
  };
};
export default connect(mapStateToProps)(NavUserDropdown);

import {
  SideEffectSchema,
  successSideEffectState,
  errorAction,
  loadingAction,
  generateConst
} from ".";
// Constants
export const BrandCustomizationConst = generateConst("BRAND_CUSTOMIZATION", [
  // "GET_CONTROL_VALUES",
  "SET_CONTROL_VALUES",
  // "GET_LOGIN_TEMPLATE_VALUES",
  "SET_LOGIN_TEMPLATE_VALUES"
]);

interface ColorPaletteSchema {
  brandColor: string;
  bodyColor: string;
  cardColor: string;
  textColor: string;
}

export enum CurrentTabEnum {
  LOGIN = "login",
  EMAIL = "email",
  SMS = "sms"
}

interface LoginTemplateContentSchema {
  signinTitle: string;
  signinSubtitle: string;
  signupTitle: string;
  signupSubtitle: string;
  forgotPasswordTitle: string;
  forgotPasswordSubtitle: string;
  mfaTitle: string;
  mfaSubtitle: string;
}

export enum LoginSocialButtonLayoutEnum {
  TOP = "top",
  BOTTOM = "bottom"
}

// Schema
export interface ControlValuesSchema extends SideEffectSchema {
  url: string;
  currentStep: number;
  selectedLogo: string;
  viewPort: number;
  selectedColorPalette: number;
  currentTab: CurrentTabEnum;
  logos: string[];
  logoUploadFile: string | null;
  logoUploadSrc: string;
  logoUploadColorPalettes: ColorPaletteSchema[];
  colorPalettes: ColorPaletteSchema[];
}

export interface LoginTemplateValuesSchema extends SideEffectSchema {
  loginTemplates: string[];
  loginFont: string;
  loginSelectedTemplate: string;
  loginSelectedTab: number;
  loginBrandColor: string;
  loginTextColor: string;
  loginBackgroundColor: string;
  loginCardColor: string;
  loginLinkColor: string;
  loginInputBackgroundColor: string;
  loginInputBorderColor: string;
  loginButtonBackgroundColor: string;
  loginButtonTextColor: string;
  loginSocialButtonLayout: LoginSocialButtonLayoutEnum;
  loginLanguage: string;
  loginTranslationData: any;
  loginBackgroundImage: string;
  loginTemplateContent: LoginTemplateContentSchema;
}

// select schemas to allow for updating of only separate fields,
// instead of having to pass the whole schema in
export interface ControlValuesSelectSchema extends SideEffectSchema {
  url?: string;
  currentStep?: number;
  selectedLogo?: string;
  viewPort?: number;
  selectedColorPalette?: number;
  currentTab?: CurrentTabEnum;
  logos?: string[];
  logoUploadFile?: string | null;
  logoUploadSrc?: string;
  logoUploadColorPalettes?: ColorPaletteSchema[];
  colorPalettes?: ColorPaletteSchema[];
}

export interface LoginTemplateValuesSelectSchema extends SideEffectSchema {
  loginTemplates?: string[];
  loginFont?: string;
  loginSelectedTemplate?: string;
  loginSelectedTab?: number;
  loginBrandColor?: string;
  loginTextColor?: string;
  loginBackgroundColor?: string;
  loginCardColor?: string;
  loginLinkColor?: string;
  loginInputBackgroundColor?: string;
  loginInputBorderColor?: string;
  loginButtonBackgroundColor?: string;
  loginButtonTextColor?: string;
  loginSocialButtonLayout?: LoginSocialButtonLayoutEnum;
  loginLanguage?: string;
  loginTranslationData?: any;
  loginBackgroundImage?: string;
  loginTemplateContent?: LoginTemplateContentSchema;
}

export const ControlValuesDefault: ControlValuesSchema = {
  url: "",
  currentStep: 1,
  selectedLogo: "",
  viewPort: 418,
  selectedColorPalette: 0,
  // progress: 0,
  currentTab: CurrentTabEnum.LOGIN,
  logos: [],
  logoUploadFile: null,
  logoUploadSrc: "",
  logoUploadColorPalettes: [],
  colorPalettes: []
};

export const LoginTemplateValuesDefault: LoginTemplateValuesSchema = {
  loginTemplates: ["001", "002", "003", "004", "005"],
  // loginTemplates: ["001", "003", "004", "005", "006", "007", "008"],
  loginFont: "Barlow",
  loginSelectedTemplate: "001",
  loginSelectedTab: 1,
  loginBrandColor: "#048ccc",
  loginTextColor: "#048ccc",
  loginBackgroundColor: "#0454ac",
  loginCardColor: "#0454ac",
  loginLinkColor: "#000000",
  loginInputBackgroundColor: "#000000",
  loginInputBorderColor: "#000000",
  loginButtonBackgroundColor: "#000000",
  loginButtonTextColor: "#000000",
  loginSocialButtonLayout: LoginSocialButtonLayoutEnum.TOP,
  loginLanguage: "en",
  loginTranslationData: {},
  loginBackgroundImage: "",
  loginTemplateContent: {
    signinTitle: "",
    signinSubtitle: "",
    signupTitle: "",
    signupSubtitle: "",
    forgotPasswordTitle: "",
    forgotPasswordSubtitle: "",
    mfaTitle: "",
    mfaSubtitle: ""
  }
};

// export const getControlValuesAction = () => {
//   return {
//     type: BrandCustomizationConst.GET_CONTROL_VALUES,
//     payload: {}
//   };
// };

// export const getControlValuesLoadingAction = () =>
//   loadingAction(BrandCustomizationConst.GET_CONTROL_VALUES_LOADING);

// export const getControlValuesErrorAction = (error: string) =>
//   errorAction(BrandCustomizationConst.GET_CONTROL_VALUES_ERROR, error);

// export const getControlValuesSuccessAction = (data: ControlValuesSchema) => {
//   const payload: ControlValuesSchema = {
//     ...data,
//     ...successSideEffectState
//   };
//   return {
//     type: BrandCustomizationConst.GET_CONTROL_VALUES_SUCCESS,
//     payload
//   };
// };

export const setControlValuesAction = (payload: ControlValuesSelectSchema) => {
  return {
    type: BrandCustomizationConst.SET_CONTROL_VALUES,
    payload
  };
};

export const setControlValuesLoadingAction = () =>
  loadingAction(BrandCustomizationConst.SET_CONTROL_VALUES_LOADING);

export const setControlValuesErrorAction = (error: string) =>
  errorAction(BrandCustomizationConst.SET_CONTROL_VALUES_ERROR, error);

export const setControlValuesSuccessAction = (
  data: ControlValuesSelectSchema
) => {
  const payload: ControlValuesSelectSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: BrandCustomizationConst.SET_CONTROL_VALUES_SUCCESS,
    payload
  };
};

// export const getLoginTemplateValuesAction = () => {
//   return {
//     type: BrandCustomizationConst.GET_LOGIN_TEMPLATE_VALUES,
//     payload: {}
//   };
// };

// export const getLoginTemplateValuesLoadingAction = () =>
//   loadingAction(BrandCustomizationConst.GET_LOGIN_TEMPLATE_VALUES_LOADING);

// export const getLoginTemplateValuesErrorAction = (error: string) =>
//   errorAction(BrandCustomizationConst.GET_LOGIN_TEMPLATE_VALUES_ERROR, error);

// export const getLoginTemplateValuesSuccessAction = (data: LoginTemplateValuesSchema) => {
//   const payload: LoginTemplateValuesSchema = {
//     ...data,
//     ...successSideEffectState
//   };
//   return {
//     type: BrandCustomizationConst.GET_LOGIN_TEMPLATE_VALUES_SUCCESS,
//     payload
//   };
// };

export const setLoginTemplateValuesAction = (
  payload: LoginTemplateValuesSelectSchema
) => {
  return {
    type: BrandCustomizationConst.SET_LOGIN_TEMPLATE_VALUES,
    payload
  };
};

export const setLoginTemplateValuesLoadingAction = () =>
  loadingAction(BrandCustomizationConst.SET_LOGIN_TEMPLATE_VALUES_LOADING);

export const setLoginTemplateValuesErrorAction = (error: string) =>
  errorAction(BrandCustomizationConst.SET_LOGIN_TEMPLATE_VALUES_ERROR, error);

export const setLoginTemplateValuesSuccessAction = (
  data: LoginTemplateValuesSelectSchema
) => {
  const payload: LoginTemplateValuesSelectSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: BrandCustomizationConst.SET_LOGIN_TEMPLATE_VALUES_SUCCESS,
    payload
  };
};

export default {
  LOGIN: "S'identifier",
  SIGNUP: "S'inscrire",
  FORGOT_PASSWORD: "Mot de passe oublié?",
  OR_LOGIN_WITH_EMAIL: "Ou",
  LOGIN_WITH: "Connectez avec",
  OR_SIGNUP_WITH_EMAIL: "Ou vous pouvez vous inscrire avec email",
  SIGNUP_WITH: "Inscrivez-vous avec",
  EMAIL_FOR_RESET_PASSWORD: "Vérifiez votre email"
};

import React, { Component, Fragment } from "react";
import { ToastContainer, toast } from "mdbreact";
import "./Toaster.scss";

interface ToasterProps {
  message?: string;
  type?: string;
  duration?: number;
}
export const Toastify = (type: string, message = "", duration?: number) => {
  switch (type) {
    case "info":
      toast.info(message, {
        autoClose: duration || 5000
      });
      break;
    case "success":
      toast.success(message, {
        position: "top-right",
        autoClose: duration || 5000
      });
      break;
    case "warning":
      toast.warn(message);
      break;
    case "error":
      toast.error(message);
      break;
  }
};
class Toaster extends Component<ToasterProps> {
  /** This static method allows using the Toaster in a specific flow: add a Toaster component as part of
   * the components that make up a page. When an alert has to be displayed, you can just called Toastify
   * with the appropriate argument and then the alert will be displayed. Required to correctly reflect
   * some request status updates.
   */
  static notify(toasterType, message, options = {}) {
    let toasterOptions = {
      ...options, // any advanced options can be specified here to be included in the toaster option.
      className: "toaster",
      pauseOnHover: true
    };

    switch (toasterType) {
      case "success":
        toasterOptions.className = "toaster toaster-success";
        toast.success(message, toasterOptions);
        break;
      case "error":
        toasterOptions.className = "toaster toaster-error";
        toast.error(message, toasterOptions);
        break;
      case "info":
        toasterOptions.className = "toaster toaster-info";
        toast.info(message, toasterOptions);
        break;
      case "warning":
        toasterOptions.className = "toaster toaster-warning";
        toast.warning(message, toasterOptions);
        break;
    }
  }

  render() {
    return (
      <Fragment>
        <ToastContainer
          id="toastmessage"
          position={"top-right"}
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={this.props.duration || 5000}
        />
        {Toaster.notify(this.props.type, this.props.message)}
      </Fragment>
    );
  }
}
export default Toaster;

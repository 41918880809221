import { combineReducers } from "redux";
import { ActionSchema } from "../actions";
import {
  InsightsConst,
  GetCreateDateSchema,
  GetLogsOverviewSchema,
  GetUserHostsSchema,
  GetDeleteUsersSchema,
  GetUsersOverviewSchema,
  GetGrowthDataSchema,
  GetUsersDataSchema,
  GetRegisteredUsersSchema,
  GetUserLogsSchema,
  GetProviderDataSchema,
  GetMonthlyHistorySchema,
  GetLogsHostsSchema,
  GetTotalLoginsSchema,
  GetReturnRateSchema,
  GetHighestGrowthDataSchema,
  GetBestRegistrationStreaksSchema,
  GetWorstRegistrationStreaksSchema
} from "../actions/insightsActions";

const createDateReducer = (
  state = {},
  action: ActionSchema<GetCreateDateSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_CREATEDATE_LOADING:
    case InsightsConst.GET_CREATEDATE_SUCCESS:
    case InsightsConst.GET_CREATEDATE_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const logsOverviewReducer = (
  state = {},
  action: ActionSchema<GetLogsOverviewSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_LOGS_OVERVIEW_LOADING:
    case InsightsConst.GET_LOGS_OVERVIEW_SUCCESS:
    case InsightsConst.GET_LOGS_OVERVIEW_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const userHostsReducer = (
  state = {},
  action: ActionSchema<GetUserHostsSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_USER_HOSTS_LOADING:
    case InsightsConst.GET_USER_HOSTS_SUCCESS:
    case InsightsConst.GET_USER_HOSTS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const logsHostsReducer = (
  state = {},
  action: ActionSchema<GetLogsHostsSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_LOGS_HOSTS_LOADING:
    case InsightsConst.GET_LOGS_HOSTS_SUCCESS:
    case InsightsConst.GET_LOGS_HOSTS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const deleteUsersReducer = (
  state = {},
  action: ActionSchema<GetDeleteUsersSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_DELETE_USERS_LOADING:
    case InsightsConst.GET_DELETE_USERS_SUCCESS:
    case InsightsConst.GET_DELETE_USERS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const usersOverviewReducer = (
  state = {},
  action: ActionSchema<GetUsersOverviewSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_USERS_OVERVIEW_LOADING:
    case InsightsConst.GET_USERS_OVERVIEW_SUCCESS:
    case InsightsConst.GET_USERS_OVERVIEW_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const growthDataReducer = (
  state = {},
  action: ActionSchema<GetGrowthDataSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_GROWTH_DATA_LOADING:
    case InsightsConst.GET_GROWTH_DATA_SUCCESS:
    case InsightsConst.GET_GROWTH_DATA_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const usersDataReducer = (
  state = {},
  action: ActionSchema<GetUsersDataSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_USERS_DATA_LOADING:
    case InsightsConst.GET_USERS_DATA_SUCCESS:
    case InsightsConst.GET_USERS_DATA_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const registeredUsersReducer = (
  state = {},
  action: ActionSchema<GetRegisteredUsersSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_REGISTERED_USERS_LOADING:
    case InsightsConst.GET_REGISTERED_USERS_SUCCESS:
    case InsightsConst.GET_REGISTERED_USERS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const userLogsReducer = (
  state = {},
  action: ActionSchema<GetUserLogsSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_USER_LOGS_LOADING:
    case InsightsConst.GET_USER_LOGS_SUCCESS:
    case InsightsConst.GET_USER_LOGS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const providerDataReducer = (
  state = {},
  action: ActionSchema<GetProviderDataSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_PROVIDER_DATA_LOADING:
    case InsightsConst.GET_PROVIDER_DATA_SUCCESS:
    case InsightsConst.GET_PROVIDER_DATA_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const monthlyHistoryReducer = (
  state = {},
  action: ActionSchema<GetMonthlyHistorySchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_MONTHLY_HISTORY_LOADING:
    case InsightsConst.GET_MONTHLY_HISTORY_ERROR:
    case InsightsConst.GET_MONTHLY_HISTORY_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const totalLoginsReducer = (
  state = {},
  action: ActionSchema<GetTotalLoginsSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_TOTAL_LOGINS_LOADING:
    case InsightsConst.GET_TOTAL_LOGINS_ERROR:
    case InsightsConst.GET_TOTAL_LOGINS_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const returnRateReducer = (
  state = {},
  action: ActionSchema<GetReturnRateSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_RETURN_RATE_LOADING:
    case InsightsConst.GET_RETURN_RATE_ERROR:
    case InsightsConst.GET_RETURN_RATE_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getHighestGrowthDataReducer = (
  state = {},
  action: ActionSchema<GetHighestGrowthDataSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_HIGHEST_GROWTH_DATA_LOADING:
    case InsightsConst.GET_HIGHEST_GROWTH_DATA_ERROR:
    case InsightsConst.GET_HIGHEST_GROWTH_DATA_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getBestRegistrationStreaksReducer = (
  state = {},
  action: ActionSchema<GetBestRegistrationStreaksSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_BEST_REGISTRATION_STREAKS_LOADING:
    case InsightsConst.GET_BEST_REGISTRATION_STREAKS_ERROR:
    case InsightsConst.GET_BEST_REGISTRATION_STREAKS_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getWorstRegistrationStreaksReducer = (
  state = {},
  action: ActionSchema<GetWorstRegistrationStreaksSchema>
) => {
  switch (action.type) {
    case InsightsConst.GET_WORST_REGISTRATION_STREAKS_LOADING:
    case InsightsConst.GET_WORST_REGISTRATION_STREAKS_ERROR:
    case InsightsConst.GET_WORST_REGISTRATION_STREAKS_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

// Combine Reducers
const insightsReducer = combineReducers({
  createDate: createDateReducer,
  logsOverview: logsOverviewReducer,
  userHosts: userHostsReducer,
  logsHosts: logsHostsReducer,
  deleteUsers: deleteUsersReducer,
  usersOverview: usersOverviewReducer,
  growthData: growthDataReducer,
  usersData: usersDataReducer,
  registeredUsers: registeredUsersReducer,
  userLogs: userLogsReducer,
  providerData: providerDataReducer,
  monthlyHistory: monthlyHistoryReducer,
  totalLogins: totalLoginsReducer,
  returnRate: returnRateReducer,
  highestGrowthData: getHighestGrowthDataReducer,
  bestRegistrationStreaks: getBestRegistrationStreaksReducer,
  worstRegistrationStreaks: getWorstRegistrationStreaksReducer
});

// Combine schemas
export interface InsightsSchema {
  createDate: GetCreateDateSchema;
  logsOverview: GetLogsOverviewSchema;
  userHosts: GetUserHostsSchema;
  logsHosts: GetLogsHostsSchema;
  deleteUsers: GetDeleteUsersSchema;
  usersOverview: GetUsersOverviewSchema;
  growthData: GetGrowthDataSchema;
  usersData: GetUsersDataSchema;
  registeredUsers: GetRegisteredUsersSchema;
  userLogs: GetUserLogsSchema;
  providerData: GetProviderDataSchema;
  monthlyHistory: GetMonthlyHistorySchema;
  totalLogins: GetTotalLoginsSchema;
  returnRate: GetReturnRateSchema;
  highestGrowthData: GetHighestGrowthDataSchema;
  bestRegistrationStreaks: GetBestRegistrationStreaksSchema;
  worstRegistrationStreaks: GetWorstRegistrationStreaksSchema;
}

export default insightsReducer;

export default function() {
  // elementsFromPoint polyfill
  if (!document.elementsFromPoint) {
    document.elementsFromPoint = elementsFromPointPolyfill;
  }

  // ParentNode.prepend polyfill
  prependPolyfill([
    Element.prototype,
    Document.prototype,
    DocumentFragment.prototype
  ]);
}

// Adapted from https://gist.github.com/iddan/54d5d9e58311b0495a91bf06de661380
function elementsFromPointPolyfill(x, y) {
  var parents: any = [];
  var parent: any = void 0;
  do {
    const element = document.elementFromPoint(x, y);
    if (element && parent !== element) {
      parent = element;
      parents.push(parent);
      parent.style.pointerEvents = "none";
    } else {
      parent = false;
    }
  } while (parent);
  parents.forEach(function(parent) {
    return (parent.style.pointerEvents = "all");
  });
  return parents;
}

// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/prepend()/prepend().md
function prependPolyfill(arr) {
  arr.forEach(function(item) {
    if (item.hasOwnProperty("prepend")) {
      return;
    }
    Object.defineProperty(item, "prepend", {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function prepend() {
        var argArr = Array.prototype.slice.call(arguments),
          docFrag = document.createDocumentFragment();

        argArr.forEach(function(argItem) {
          var isNode = argItem instanceof Node;
          docFrag.appendChild(
            isNode ? argItem : document.createTextNode(String(argItem))
          );
        });

        this.insertBefore(docFrag, this.firstChild);
      }
    });
  });
}

import {
  SideEffectSchema,
  loadingAction,
  errorAction,
  successSideEffectState,
  generateConst,
  APIErrorResponseSchema
} from ".";

// Constants
export const DataConfigurationConst = generateConst("DATA_CONFIGURATION", [
  "GET_ENCRYPTION_AT_REST",
  "GET_DATA_STORAGE_LOCATION",
  "GET_HASHING_ALGORITHM",
  "GET_PLATFORM_REGISTRATION_FIELDS",
  "GET_SITE_REGISTRATION_FORM_SETTINGS",
  "GET_DATA_OBJECT_KEY",
  "GET_DATA_FIELDS_KEY",
  "SAVE_PRIVACY_POLICY_VERSION_ENABLED",
  "SAVE_PRIVACY_POLICY_VERSION",
  "GET_PRIVACY_POLICY_VERSION_ENABLED",
  "GET_PRIVACY_POLICY_VERSION",
  "RESET_DATA_CONFIGURATION_STATE",
  "SUBMIT_AGE_VERIFICATION",
  "ADD_CONSENT_GROUP",
  "GET_CONSENT_GROUP",
  "ADD_CONSENT_OPTION",
  "GET_CONSENT_OPTION",
  "ADD_CONSENT_FORM",
  "ADD_WORKFLOW_CONSENT_FORM",
  "UPDATE_CONSENT_FORM",
  "UPDATE_CONSENT_LIVE_FORM",
  "DELETE_CONSENT_FORM",
  "GET_CONSENT_FORM"
]);

export interface ResponseDataCenterSchema {
  IsLegacySettings: boolean;
  IsDefaultServer: boolean;
  IsLoginRadiusGlobalServer: boolean;
  GlobalServer: string;
  CustomDbConnectionString: string;
  DrConnectionString: string;
  CustomDbName: string;
  errorCode?: string;
  description?: string;
  message?: string;
}

export interface ResponseDataCenterKeySchema {
  AppName: string;
  CustomerName: string;
  WebTechnology: number;
  Domain: string;
  CallbackUrl: string;
  DevDomain: string;
  IsMobile: boolean;
  AppId: number;
  Key: string;
  Secret: string;
  Role: string;
  IsWelcomeEmailEnabled: boolean;
  Ishttps: boolean;
  InterfaceId: number;
  RecurlyAccountCode: string;
  UserLimit: number;
  DomainLimit: number;
  DateCreated: string;
  DateModified: string;
  Status: boolean;
  ProfilePhoto: null;
  ApiVersion: string;
  IsRaasEnabled: boolean;
  PrivacyPolicy: null;
  TermsOfService: null;
  OwnerId: string;
}

export interface GetDataStorageLocationResponseSchema {
  responseDataCenter: ResponseDataCenterSchema;
  responseKey: ResponseDataCenterKeySchema;
  clientIP: string;
}

export interface GetDataStorageLocationDataSchema extends SideEffectSchema {
  response: GetDataStorageLocationResponseSchema;
}

export const getDataStorageLocationAction = () => {
  return {
    type: DataConfigurationConst.GET_DATA_STORAGE_LOCATION,
    payload: {}
  };
};

export const getDataStorageLocationLoadingAction = () =>
  loadingAction(DataConfigurationConst.GET_DATA_STORAGE_LOCATION_LOADING);

export const getDataStorageLocationSuccessAction = (
  data: GetDataStorageLocationDataSchema
) => {
  return {
    type: DataConfigurationConst.GET_DATA_STORAGE_LOCATION_SUCCESS,
    payload: { ...data, ...successSideEffectState }
  };
};

export const getDataStorageLocationErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.GET_DATA_STORAGE_LOCATION_ERROR, error);

/**
 * GET_ENCRYPTION_AT_REST
 */

export interface EncryptionAtRestSchema extends SideEffectSchema {
  data?: any;
}

export const getEncryptionAtRestAction = () => {
  return {
    type: DataConfigurationConst.GET_ENCRYPTION_AT_REST,
    payload: {}
  };
};

export const getEncryptionAtRestLoadingAction = () =>
  loadingAction(DataConfigurationConst.GET_ENCRYPTION_AT_REST_LOADING);

export const getEncryptionAtRestErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.GET_ENCRYPTION_AT_REST_ERROR, error);

export const getEncryptionAtRestSuccessAction = (
  data: EncryptionAtRestSchema
) => {
  const payload: EncryptionAtRestSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.GET_ENCRYPTION_AT_REST_SUCCESS,
    payload
  };
};

/**
 * GET_HASHING_ALGORITHM
 */
export interface GetHashingAlgorithmResponseSchema {
  ExpirationFrequency: number;
  ExpirationFrequencyType: string;
  IsPerPasswordSalt: boolean;
  NumberOfIteration: number;
  PasswordHashEncodingType: string;
  PasswordSaltEncodingType: string;
  PlaintextPasswordEncoding: string;
  Salt: string;
  SaltAttachType: string;
  Type: string;
  clientIP: string;
  description?: string;
  errorCode?: number;
  message?: string;
}

export interface GetHashingAlgorthimSchema extends SideEffectSchema {
  response: GetHashingAlgorithmResponseSchema;
}

export const getHashingAlgorithmAction = () => {
  return {
    type: DataConfigurationConst.GET_HASHING_ALGORITHM,
    payload: {}
  };
};

export const getHashingAlgorithmLoadingAction = () =>
  loadingAction(DataConfigurationConst.GET_HASHING_ALGORITHM_LOADING);

export const getHashingAlgorithmSuccessAction = (
  data: GetHashingAlgorthimSchema
) => {
  const payload = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.GET_HASHING_ALGORITHM_SUCCESS,
    payload
  };
};

export const getHashingAlgorithmErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.GET_HASHING_ALGORITHM_ERROR, error);

/**
 * GET_PLATFORM_REGISTRATION_FIELDS
 */

export interface PlatformRegistrationFieldsSchema extends SideEffectSchema {
  Data: any;
}

export const getPlatformRegistrationFieldsAction = () => {
  return {
    type: DataConfigurationConst.GET_PLATFORM_REGISTRATION_FIELDS,
    payload: {}
  };
};

/**
 * SAVE_PRIVACY_POLICY_VERSION_ENABLED
 */

export interface SavePrivacyPolicyVersionEnabledSchema
  extends SideEffectSchema {
  data?: any;
  IsEnabled?: boolean;
  Mode?: string;
}

export const savePrivacyPolicyVersionEnabledAction = payload => {
  return {
    type: DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION_ENABLED,
    payload
  };
};

export const getPlatformRegistrationFieldsLoadingAction = () =>
  loadingAction(
    DataConfigurationConst.GET_PLATFORM_REGISTRATION_FIELDS_LOADING
  );

export const getPlatformRegistrationFieldsErrorAction = (error: string) =>
  errorAction(
    DataConfigurationConst.GET_PLATFORM_REGISTRATION_FIELDS_ERROR,
    error
  );

export const getPlatformRegistrationFieldsSuccessAction = (Data: any) => {
  const payload: PlatformRegistrationFieldsSchema = {
    Data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.GET_PLATFORM_REGISTRATION_FIELDS_SUCCESS,
    payload
  };
};

export const savePrivacyPolicyVersionEnabledLoadingAction = () =>
  loadingAction(
    DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION_ENABLED_LOADING
  );

export const savePrivacyPolicyVersionEnabledErrorAction = (error: string) =>
  errorAction(
    DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION_ENABLED_ERROR,
    error
  );

export const savePrivacyPolicyVersionEnabledSuccessAction = (
  data: SavePrivacyPolicyVersionEnabledSchema
) => {
  const payload: any = {
    data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION_ENABLED_SUCCESS,
    payload
  };
};

/**
 * GET_SITE_REGISTRATION_FORM_SETTINGS
 */

export interface SiteRegistrationFormSettingsSchema extends SideEffectSchema {
  Data: any;
}

export const getSiteRegistrationFormSettingsAction = () => {
  return {
    type: DataConfigurationConst.GET_SITE_REGISTRATION_FORM_SETTINGS,
    payload: {}
  };
};

export const getSiteRegistrationFormSettingsLoadingAction = () =>
  loadingAction(
    DataConfigurationConst.GET_SITE_REGISTRATION_FORM_SETTINGS_LOADING
  );

export const getSiteRegistrationFormSettingsErrorAction = (error: string) =>
  errorAction(
    DataConfigurationConst.GET_SITE_REGISTRATION_FORM_SETTINGS_ERROR,
    error
  );

export const getSiteRegistrationFormSettingsSuccessAction = (Data: any) => {
  const payload: SiteRegistrationFormSettingsSchema = {
    Data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.GET_SITE_REGISTRATION_FORM_SETTINGS_SUCCESS,
    payload
  };
};

/**
 * GET_DATA_OBJECT_KEY
 */

export interface DataObjectKeySchema extends SideEffectSchema {
  responseDataObject: any;
  responseKey: any;
}

export const getDataObjectKeyAction = () => {
  return {
    type: DataConfigurationConst.GET_DATA_OBJECT_KEY,
    payload: {}
  };
};

export const getDataObjectKeyLoadingAction = () =>
  loadingAction(DataConfigurationConst.GET_DATA_OBJECT_KEY_LOADING);

export const getDataObjectKeyErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.GET_DATA_OBJECT_KEY_ERROR, error);

export const getDataObjectKeySuccessAction = (data: DataObjectKeySchema) => {
  const payload: DataObjectKeySchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.GET_DATA_OBJECT_KEY_SUCCESS,
    payload
  };
};

/**
 * SAVE_PRIVACY_POLICY_VERSION
 */

export interface SavePrivacyPolicyVersionSchema extends SideEffectSchema {
  data?: any;
  description?: string;
  Data?: {
    StartDateTime: string;
    Version: string;
  }[];
}

export const savePrivacyPolicyVersionAction = payload => {
  return {
    type: DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION,
    payload
  };
};

export const savePrivacyPolicyVersionLoadingAction = () =>
  loadingAction(DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION_LOADING);

export const savePrivacyPolicyVersionErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION_ERROR, error);

export const savePrivacyPolicyVersionSuccessAction = (
  data: SavePrivacyPolicyVersionSchema
) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION_SUCCESS,
    payload
  };
};

/**
 * GET_DATA_FIELDS_KEY
 */

export interface DataFieldsKeySchema extends SideEffectSchema {
  responseFields: {
    Data: any[];
  };
  responseKey: any;
}

export const getDataFieldsKeyAction = () => {
  return {
    type: DataConfigurationConst.GET_DATA_FIELDS_KEY,
    payload: {}
  };
};

/**
 * GET_PRIVACY_POLICY_VERSION_ENABLED
 */

export interface GetPrivacyPolicyVersionEnabledSchema extends SideEffectSchema {
  data?: any;
}

export const getPrivacyPolicyVersionEnabledAction = () => {
  return {
    type: DataConfigurationConst.GET_PRIVACY_POLICY_VERSION_ENABLED,
    payload: {}
  };
};

export const getDataFieldsKeyLoadingAction = () =>
  loadingAction(DataConfigurationConst.GET_DATA_FIELDS_KEY_LOADING);

export const getDataFieldsKeyErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.GET_DATA_FIELDS_KEY_ERROR, error);

export const getDataFieldsKeySuccessAction = (data: DataFieldsKeySchema) => {
  const payload: DataFieldsKeySchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.GET_DATA_FIELDS_KEY_SUCCESS,
    payload
  };
};

export const getPrivacyPolicyVersionEnabledLoadingAction = () =>
  loadingAction(
    DataConfigurationConst.GET_PRIVACY_POLICY_VERSION_ENABLED_LOADING
  );

export const getPrivacyPolicyVersionEnabledErrorAction = (error: string) =>
  errorAction(
    DataConfigurationConst.GET_PRIVACY_POLICY_VERSION_ENABLED_ERROR,
    error
  );

export const getPrivacyPolicyVersionEnabledSuccessAction = (
  data: GetPrivacyPolicyVersionEnabledSchema
) => {
  const payload: GetPrivacyPolicyVersionEnabledSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.GET_PRIVACY_POLICY_VERSION_ENABLED_SUCCESS,
    payload
  };
};

/**
 * GET_PRIVACY_POLICY_VERSION
 */

export interface GetPrivacyPolicyVersionSchema extends SideEffectSchema {
  data?: any;
}

export const getPrivacyPolicyVersionAction = () => {
  return {
    type: DataConfigurationConst.GET_PRIVACY_POLICY_VERSION,
    payload: {}
  };
};

export const getPrivacyPolicyVersionLoadingAction = () =>
  loadingAction(DataConfigurationConst.GET_PRIVACY_POLICY_VERSION_LOADING);

export const getPrivacyPolicyVersionErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.GET_PRIVACY_POLICY_VERSION_ERROR, error);

export const getPrivacyPolicyVersionSuccessAction = (
  data: GetPrivacyPolicyVersionSchema
) => {
  const payload: GetPrivacyPolicyVersionSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.GET_PRIVACY_POLICY_VERSION_SUCCESS,
    payload
  };
};

/**
 * SUBMIT_AGE_VERIFICATION
 */
export interface SendAgeVerificationSchema extends SideEffectSchema {
  data?: any;
  payload?: any;
}

export const sendAgeVerificationAction = data => {
  return {
    type: DataConfigurationConst.SUBMIT_AGE_VERIFICATION,
    payload: { ...data }
  };
};

export const sendAgeVerificationLoadingAction = () =>
  loadingAction(DataConfigurationConst.SUBMIT_AGE_VERIFICATION_LOADING);

export const sendAgeVerificationErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.SUBMIT_AGE_VERIFICATION_ERROR, error);

export const sendAgeVerificationSuccessAction = (
  data: SendAgeVerificationSchema
) => {
  const payload: SendAgeVerificationSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.SUBMIT_AGE_VERIFICATION_SUCCESS,
    payload
  };
};

export const resetPrivacyPolicyVersionEnabledStateAction = () => {
  return {
    type: DataConfigurationConst.RESET_DATA_CONFIGURATION_STATE,
    payload: {}
  };
};

/**
 * ADD CONSENT GROUP
 */

export interface ConsentGroupSchema {
  GroupId: string;
  Title: string;
  Description: string;
  IsActive: boolean;
}

export interface AddConsentGroupArgs {
  Data: ConsentGroupSchema[];
}

export interface AddConsentGrpRespSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  isPosted: boolean;
}

export const addConsentGroupAction = (payload: AddConsentGroupArgs) => {
  return {
    type: DataConfigurationConst.ADD_CONSENT_GROUP,
    payload
  };
};

export const addConsentGroupLoadingAction = () =>
  loadingAction(DataConfigurationConst.ADD_CONSENT_GROUP_LOADING);

export const addConsentGroupErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.ADD_CONSENT_GROUP_ERROR, error);

export const addConsentGroupSuccessAction = (data: AddConsentGrpRespSchema) => {
  const payload: AddConsentGrpRespSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.ADD_CONSENT_GROUP_SUCCESS,
    payload
  };
};

/**
 * GET CONSENT OPTIONS GROUP
 */

export interface GetConsentGrpRespSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  Data: ConsentGroupSchema[];
}

export const getConsentGroupAction = () => {
  return {
    type: DataConfigurationConst.GET_CONSENT_GROUP
  };
};

export const getConsentGroupLoadingAction = () =>
  loadingAction(DataConfigurationConst.GET_CONSENT_GROUP_LOADING);

export const getConsentGroupErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.GET_CONSENT_GROUP_ERROR, error);

export const getConsentGroupSuccessAction = (data: GetConsentGrpRespSchema) => {
  const payload: GetConsentGrpRespSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.GET_CONSENT_GROUP_SUCCESS,
    payload
  };
};

/**
 * ADD CONSENT OPTION
 */

export interface ConsentOptionSchema {
  ConsentId: string;
  Title: string;
  Description: string;
  IsActive: boolean;
}

export interface AddConsentOptionArgs {
  Data: ConsentOptionSchema[];
}

export interface AddConsentOptRespSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  isPosted: boolean;
}

export const addConsentOptionAction = (payload: AddConsentOptionArgs) => {
  return {
    type: DataConfigurationConst.ADD_CONSENT_OPTION,
    payload
  };
};

export const addConsentOptionLoadingAction = () =>
  loadingAction(DataConfigurationConst.ADD_CONSENT_OPTION_LOADING);

export const addConsentOptionErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.ADD_CONSENT_OPTION_ERROR, error);

export const addConsentOptionSuccessAction = (
  data: AddConsentOptRespSchema
) => {
  const payload: AddConsentOptRespSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.ADD_CONSENT_OPTION_SUCCESS,
    payload
  };
};

/**
 * GET CONSENT OPTIONS GROUP
 */

export interface GetConsentOptRespSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  Data: ConsentOptionSchema[];
}

export const getConsentOptionAction = () => {
  return {
    type: DataConfigurationConst.GET_CONSENT_OPTION
  };
};

export const getConsentOptionLoadingAction = () =>
  loadingAction(DataConfigurationConst.GET_CONSENT_OPTION_LOADING);

export const getConsentOptionErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.GET_CONSENT_OPTION_ERROR, error);

export const getConsentOptionSuccessAction = (
  data: GetConsentOptRespSchema
) => {
  const payload: GetConsentOptRespSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.GET_CONSENT_OPTION_SUCCESS,
    payload
  };
};

/**
 * ADD CONSENT FORMS
 */
export interface ConsentFormOptSchema {
  ConsentOptionId: string;
  GroupName?: string;
  IsRequired: boolean;
}
export interface ConsentFormSchema {
  Version?: number;
  Status: string;
  StartFromDate: string;
  TermOfService: string;
  PrivacyPolicy: string;
  CreatedOnDate: string;
  LockedOnDate: string;
  ConsentOptionGroups?: {
    ConsentOptionGroupId: string;
    IsRequiredAtleastOne: boolean;
  }[];

  ConsentOptions: ConsentFormOptSchema[];
  IsActive: boolean;
  Events: {
    Name: string;
    IsCustom: boolean;
    IsDisabled: boolean;
  }[];
  IsWorkflowForm?: boolean;
}

export interface AddConsentFormRespSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  isPosted: boolean;
}

export const addConsentFormAction = (payload: ConsentFormSchema) => {
  return {
    type: DataConfigurationConst.ADD_CONSENT_FORM,
    payload
  };
};

export const addConsentFormLoadingAction = () =>
  loadingAction(DataConfigurationConst.ADD_CONSENT_FORM_LOADING);

export const addConsentFormErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.ADD_CONSENT_FORM_ERROR, error);

export const addConsentFormSuccessAction = (data: AddConsentFormRespSchema) => {
  const payload: AddConsentFormRespSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.ADD_CONSENT_FORM_SUCCESS,
    payload
  };
};

export interface UpdateConsentFormRespSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  isPosted: boolean;
}

export const updateConsentFormAction = (payload: ConsentFormSchema) => {
  return {
    type: DataConfigurationConst.UPDATE_CONSENT_FORM,
    payload
  };
};

export const updateConsentFormLoadingAction = () =>
  loadingAction(DataConfigurationConst.UPDATE_CONSENT_FORM_LOADING);

export const updateConsentFormErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.UPDATE_CONSENT_FORM_ERROR, error);

export const updateConsentFormSuccessAction = (
  data: UpdateConsentFormRespSchema
) => {
  const payload: UpdateConsentFormRespSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.UPDATE_CONSENT_FORM_SUCCESS,
    payload
  };
};

export interface UpdateConsentLiveFormRespSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  isPosted: boolean;
}

export const updateConsentLiveFormAction = (payload: ConsentFormSchema) => {
  return {
    type: DataConfigurationConst.UPDATE_CONSENT_LIVE_FORM,
    payload
  };
};

export const updateConsentLiveFormLoadingAction = () =>
  loadingAction(DataConfigurationConst.UPDATE_CONSENT_LIVE_FORM_LOADING);

export const updateConsentLiveFormErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.UPDATE_CONSENT_LIVE_FORM_ERROR, error);

export const updateConsentLiveFormSuccessAction = (
  data: UpdateConsentLiveFormRespSchema
) => {
  const payload: UpdateConsentLiveFormRespSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.UPDATE_CONSENT_LIVE_FORM_SUCCESS,
    payload
  };
};

export interface DeleteConsentFormRespSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  isPosted: boolean;
}

export const deleteConsentFormAction = (payload: any) => {
  return {
    type: DataConfigurationConst.DELETE_CONSENT_FORM,
    payload
  };
};

export const deleteConsentFormLoadingAction = () =>
  loadingAction(DataConfigurationConst.DELETE_CONSENT_FORM_LOADING);

export const deleteConsentFormErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.DELETE_CONSENT_FORM_ERROR, error);

export const deleteConsentFormSuccessAction = (
  data: DeleteConsentFormRespSchema
) => {
  const payload: DeleteConsentFormRespSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.DELETE_CONSENT_FORM_SUCCESS,
    payload
  };
};

/**
 * GET CONSENT FORM
 */

export interface GetConsentFormRespSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  Data: ConsentFormSchema[];
}

export const getConsentFormAction = () => {
  return {
    type: DataConfigurationConst.GET_CONSENT_FORM
  };
};

export const getConsentFormLoadingAction = () =>
  loadingAction(DataConfigurationConst.GET_CONSENT_FORM_LOADING);

export const getConsentFormErrorAction = (error: string) =>
  errorAction(DataConfigurationConst.GET_CONSENT_FORM_ERROR, error);

export const getConsentFormSuccessAction = (data: GetConsentFormRespSchema) => {
  const payload: GetConsentFormRespSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DataConfigurationConst.GET_CONSENT_FORM_SUCCESS,
    payload
  };
};

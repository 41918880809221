import { combineReducers } from "redux";
import { ActionSchema } from "../actions";
import {
  UpdatesConst,
  GetProductUpdatesDataSchema
} from "../actions/updatesActions";

const productUpdatesReducer = (
  state = {},
  action: ActionSchema<GetProductUpdatesDataSchema>
) => {
  switch (action.type) {
    case UpdatesConst.GET_PRODUCT_UPDATES_LOADING:
    case UpdatesConst.GET_PRODUCT_UPDATES_SUCCESS:
    case UpdatesConst.GET_PRODUCT_UPDATES_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

// Combine Reducers
const updatesReducer = combineReducers({
  productUpdates: productUpdatesReducer
});

// Combine schemas
export interface UpdatesSchema {
  productUpdates: GetProductUpdatesDataSchema;
}

export default updatesReducer;

import React from "react";
import { MDBBtn, MDBIcon } from "mdbreact";
export interface ButtonWithIconProps {
  ariaLabel?: string;
  id?: string;
  type?: string;
  size?: string;
  color?: string;
  tag?: string;
  value?: string;
  icon?: string;
  role?: string;
  href?: string;
  target?: string;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  floating?: boolean;
  rounded?: boolean;
  outline?: boolean;
  flat?: boolean;
  ghost?: boolean;
  onClick?: (event: any) => void;
  spin?: boolean;
  title?: string;
  iconclassname?: string;
}

const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  ariaLabel,
  ...props
}) => {
  const customeOnClick = (e: Event) => {
    const event = e && e.target ? "Clicked on " + e.target["id"] : "";
    props.onClick && props.onClick(e);
  };
  const { icon, value, spin, className, iconclassname } = props;
  return (
    <MDBBtn
      aria-label={ariaLabel || value}
      {...props}
      onClick={e => customeOnClick(e)}
      className={" global-btn-with-icon"}
    >
      {/* <MDBIcon
        spin={spin}
        icon={icon}
        className={"lricon_" + (iconclassname || className)}
      >
        {" "}
      </MDBIcon>{" "} */}
      {value}
    </MDBBtn>
  );
};

export default ButtonWithIcon;

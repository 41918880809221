import { call, put, take } from "redux-saga/effects";
import {
  GetWorkflowsSchema,
  OrchestrationConst,
  getAllWorkflowsErrorAction,
  getAllWorkflowsLoadingAction,
  getAllWorkflowsSuccessAction
} from "../actions/orchestrationActions";
import { httpGet } from "../../services";

function* getAllWorkflowsHandler() {
  try {
    yield put(getAllWorkflowsLoadingAction());

    const data: GetWorkflowsSchema = yield httpGet(
      "/deployment/orchestration/workflows",
      {}
    );

    const stateData = data.ErrorCode ? { Data: [] } : data;
    yield put(getAllWorkflowsSuccessAction({ ...stateData }));
  } catch (e) {
    yield put(getAllWorkflowsErrorAction(e.message));
  }
}

export function* watchGetAllWorkflows() {
  while (true) {
    yield take(OrchestrationConst.GET_ALL_WORKFLOWS);
    yield call(getAllWorkflowsHandler);
  }
}

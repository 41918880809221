import React, { RefObject, useState } from "react";
import { MDBBtn, MDBTooltip } from "mdbreact";
export interface ButtonProps {
  ariaLabel?: string;
  ariaLabelledby?: string;
  ariaDescribedby?: string;
  id?: string;
  type?: string;
  size?: string;
  color?: string;
  tag?: string;
  value?: string;
  role?: string;
  href?: string;
  target?: string;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  floating?: boolean;
  rounded?: boolean;
  icon?: string;
  spin?: boolean;
  outline?: boolean;
  flat?: boolean;
  secondary?: string;
  title?: string;
  onClick?: (event: any) => void;
  tooltip?: string;
  tooltipPlacement?: "right" | "top" | "bottom" | "left";
  innerRef?: RefObject<any>;
}

const Button = React.memo(
  ({
    ariaDescribedby,
    ariaLabel,
    ariaLabelledby,
    tooltip,
    tooltipPlacement,
    disabled,
    ...props
  }: React.PropsWithChildren<ButtonProps>) => {
    const { value } = props;

    let [isTooltipVisible, updateTooltipVisibility] = useState(false);

    const handleFocus = () => {
      updateTooltipVisibility(!isTooltipVisible);
    };
    const customeOnClick = (e: Event) => {
      const event = e && e.target ? "Clicked on " + e.target["id"] : "";
      props.onClick && props.onClick(e);
    };
    return tooltip ? (
      <MDBTooltip
        placement={tooltipPlacement || "right"}
        tag="span"
        isVisible={isTooltipVisible}
      >
        <MDBBtn
          {...props}
          onFocus={handleFocus}
          onBlur={handleFocus}
          onClick={disabled ? e => {} : e => customeOnClick(e)}
          style={disabled ? { opacity: "0.65" } : undefined}
          aria-describedby={ariaDescribedby}
          aria-label={ariaLabel || value}
          aria-labelledby={ariaLabelledby}
          className={
            props.className +
            (props.icon ? " btn.global-btn-with-icon" : " global-btn")
          }
        >
          {props.children}
          {value}
        </MDBBtn>
        <div>{tooltip}</div>
      </MDBTooltip>
    ) : (
      <MDBBtn
        {...props}
        disabled={disabled}
        aria-describedby={ariaDescribedby}
        aria-label={ariaLabel || value}
        aria-labelledby={ariaLabelledby}
        onClick={e => customeOnClick(e)}
        className={
          props.className +
          (props.icon ? " btn.global-btn-with-icon" : " global-btn")
        }
      >
        {props.children}
        {value}
      </MDBBtn>
    );
  }
);

export default Button;

import { take, put, call, all, select } from "redux-saga/effects";
import { httpGet, httpPost, httpPut, httpDelete } from "../../services";
import { getAppFeatureSuccessAction } from "../actions/authActions";

import {
  PlatformConfigurationConst,
  getGlobalEmailConfigLoadingAction,
  getGlobalEmailConfigSuccessAction,
  getGlobalEmailConfigErrorAction,
  EmailConfigResponseSchema,
  saveGlobalEmailConfigLoadingAction,
  saveGlobalEmailConfigSuccessAction,
  saveGlobalEmailConfigErrorAction,
  SaveEmailConfigBodyAndResponseSchema,
  getGlobalEmailConfigAction,
  GetApiKeySchema,
  getApiKeyLoadingAction,
  getApiKeySuccessAction,
  getApiKeyErrorAction,
  GetSMSSettingsAndConfigSchema,
  getSMSSettingsAndConfigLoadingAction,
  getSMSSettingsAndConfigSuccessAction,
  getSMSSettingsAndConfigErrorAction,
  GetSMSTemplatesSchema,
  getSMSTemplatesLoadingAction,
  getSMSTemplatesSuccessAction,
  getSMSTemplatesErrorAction,
  GetSMSProviderConfigSchema,
  getSMSProviderConfigLoadingAction,
  getSMSProviderConfigSuccessAction,
  getSMSProviderConfigErrorAction,
  SaveSMSConfigSchema,
  saveSMSConfigLoadingAction,
  saveSMSConfigSuccessAction,
  saveSMSConfigErrorAction,
  ResetSMSConfigSchema,
  resetSMSConfigLoadingAction,
  resetSMSConfigSuccessAction,
  resetSMSConfigErrorAction,
  getGlobalSMSSettingsLoadingAction,
  getGlobalSMSSettingsSuccessAction,
  getGlobalSMSSettingsErrorAction,
  SMSSettingsResponseSchema,
  saveGlobalSMSSettingsLoadingAction,
  saveGlobalSMSSettingsSuccessAction,
  saveGlobalSMSSettingsErrorAction,
  SaveSMSSettingsBodyAndResponseSchema,
  GetRolesSchema,
  getRolesLoadingAction,
  getRolesSuccessAction,
  getRolesErrorAction,
  getConfigurationListLoadingAction,
  GetConfigurationListSchema,
  getConfigurationListSuccessAction,
  getConfigurationListErrorAction,
  TransferConfigurationsArgs,
  transferConfigurationsLoadingAction,
  TransferConfigurationsSchema,
  transferConfigurationsSuccessAction,
  transferConfigurationsErrorAction,
  getSocialProviderListLoadingAction,
  getSocialProviderListSuccessAction,
  getSocialProviderListErrorAction,
  GetSocialProviderListSchema,
  getMultipleSocialProviderLoadingAction,
  getMultipleSocialProviderErrorAction,
  getMultipleSocialProviderSuccessAction,
  postMultipleSocialProviderLoadingAction,
  postMultipleSocialProviderErrorAction,
  postMultipleSocialProviderSuccessAction,
  putMultipleSocialProviderLoadingAction,
  putMultipleSocialProviderErrorAction,
  putMultipleSocialProviderSuccessAction,
  deleteMultipleSocialProviderLoadingAction,
  deleteMultipleSocialProviderErrorAction,
  deleteMultipleSocialProviderSuccessAction,
  GetMultipleSocialProviderSchema,
  getAuthServerAPIConfigLoadingAction,
  getAuthServerAPIConfigSuccessAction,
  getAuthServerAPIConfigErrorAction,
  deleteAuthServerAPIConfigLoadingAction,
  deleteAuthServerAPIConfigSuccessAction,
  deleteAuthServerAPIConfigErrorAction,
  postAuthServerAPIConfigLoadingAction,
  postAuthServerAPIConfigSuccessAction,
  postAuthServerAPIConfigErrorAction,
  putAuthServerAPIConfigLoadingAction,
  putAuthServerAPIConfigSuccessAction,
  putAuthServerAPIConfigErrorAction,
  setAppFeaturesLoadingAction,
  setAppFeaturesSuccessAction,
  setAppFeaturesErrorAction,
  renewCertificateLoadingAction,
  renewCertificateSuccessAction,
  renewCertificateErrorAction,
  getCustomSMSProviderConfigLoadingAction,
  GetCustomSMSProviderConfigSchema,
  getCustomSMSProviderConfigSuccessAction,
  getCustomSMSProviderConfigErrorAction,
  saveCustomSMSConfigSuccessAction,
  saveCustomSMSConfigErrorAction,
  saveCustomSMSConfigLoadingAction,
  SaveCustomSMSConfigSchema,
  getSSOIntegrationLoadingAction,
  getSSOIntegrationSuccessAction,
  getSSOIntegrationErrorAction,
  getAvailableSSOIntegrationLoadingAction,
  getAvailableSSOIntegrationSuccessAction,
  getAvailableSSOIntegrationErrorAction,
  postSSOIntegrationLoadingAction,
  postSSOIntegrationSuccessAction,
  postSSOIntegrationErrorAction,
  putSSOIntegrationLoadingAction,
  putSSOIntegrationSuccessAction,
  putSSOIntegrationErrorAction,
  deleteSSOIntegrationLoadingAction,
  deleteSSOIntegrationSuccessAction,
  deleteSSOIntegrationErrorAction,
  getOAuthProvidersAction,
  getOAuthProvidersLoadingAction,
  getOAuthProvidersSuccessAction,
  getOAuthProvidersErrorAction,
  getJWTProvidersLoadingAction,
  getJWTProvidersSuccessAction,
  getJWTProvidersErrorAction,
  getSAMLProvidersLoadingAction,
  getSAMLProvidersSuccessAction,
  getSAMLProvidersErrorAction,
  getOIDCandOAuthAppsAction,
  getOIDCandOAuthAppsLoadingAction,
  getOIDCandOAuthAppsSuccessAction,
  getOIDCandOAuthAppsErrorAction,
  getPassKeyConfigAction,
  getPassKeyConfigSuccessAction,
  getPassKeyConfigErrorAction,
  putPassKeyConfigAction,
  getPassKeyConfigLoadingAction,
  putPassKeyConfigLoadingAction,
  putPassKeyConfigSuccessAction,
  putPassKeyConfigErrorAction
} from "../actions/platformConfigurationActions";
import {
  getSamlConfigSuccessAction,
  PutSamlConfigSchema
} from "../actions/teamActions";
import { RootSchema } from "../reducers";
import {
  getAvailableIntegrationsAction,
  getAvailableIntegrationsErrorAction,
  getAvailableIntegrationsSuccessAction
} from "../actions/integrationsActions";

/**
 * Workers
 */

function* getGlobalEmailConfigHandler() {
  try {
    yield put(getGlobalEmailConfigLoadingAction());
    let response: EmailConfigResponseSchema = yield httpGet(
      "/platform-configuration/global-email-configuration"
    );
    yield put(getGlobalEmailConfigSuccessAction(response));
  } catch (e) {
    yield put(getGlobalEmailConfigErrorAction(e.message));
  }
}

function* saveGlobalEmailConfigHandler(
  payload: SaveEmailConfigBodyAndResponseSchema
) {
  try {
    yield put(saveGlobalEmailConfigLoadingAction());

    let response: SaveEmailConfigBodyAndResponseSchema = yield httpPut(
      "/platform-configuration/global-email-configuration",
      {},
      payload
    );
    yield put(saveGlobalEmailConfigSuccessAction(response));
  } catch (e) {
    yield put(saveGlobalEmailConfigErrorAction(e.message));
  }
}

function* getAuthServerApiConfigHandler() {
  try {
    yield put(getAuthServerAPIConfigLoadingAction());
    let response = yield httpGet("/platform-configuration/authserverapiconfig");
    if (response.errorCode) {
      throw new Error(response.message || response.description);
    }
    yield put(getAuthServerAPIConfigSuccessAction(response));
  } catch (e) {
    yield put(getAuthServerAPIConfigErrorAction(e.message));
  }
}

function* deleteAuthServerApiConfigHandler(payload) {
  try {
    yield put(deleteAuthServerAPIConfigLoadingAction());

    let response = yield httpDelete(
      "/platform-configuration/authserverapiconfig",
      {},
      payload
    );
    if (response.isdeleted) {
      let getAuthServerConfigApiData = yield select(
        (state: RootSchema) =>
          state.platformConfiguration.getAuthServerConfigApi.Data
      );
      getAuthServerConfigApiData = getAuthServerConfigApiData.filter(
        e => e.Id !== payload.id
      )
        ? getAuthServerConfigApiData.filter(e => e.Id !== payload.id)
        : [];
      yield put(getAuthServerAPIConfigLoadingAction());
      yield put(
        getAuthServerAPIConfigSuccessAction({
          Data: getAuthServerConfigApiData
        })
      );
    }
    if (response.errorCode) {
      throw new Error(response.message || response.description);
    }

    yield put(deleteAuthServerAPIConfigSuccessAction(response));
  } catch (e) {
    yield put(deleteAuthServerAPIConfigErrorAction(e.message));
  }
}

function* updateAuthServerApiConfigHandler(payload) {
  try {
    yield put(putAuthServerAPIConfigLoadingAction());

    let response = yield httpPut(
      "/platform-configuration/authserverapiconfig",
      {},
      payload
    );

    if (response.errorCode) {
      throw new Error(response.message || response.description);
    }

    yield put(putAuthServerAPIConfigSuccessAction(response));
    if (response.Edited) {
      let getAuthServerConfigApiData = yield select(
        (state: RootSchema) =>
          state.platformConfiguration.getAuthServerConfigApi.Data
      );
      getAuthServerConfigApiData = getAuthServerConfigApiData.map(data => {
        if (data.Id === payload.id) {
          delete payload.id;
          payload.Id = data.Id;
          data = payload;
        }
        return data;
      });

      yield put(getAuthServerAPIConfigLoadingAction());
      yield put(
        getAuthServerAPIConfigSuccessAction({
          Data: getAuthServerConfigApiData
        })
      );
    }
    if (response.errorCode) {
      throw new Error(response.message || response.description);
    }
  } catch (e) {
    yield put(putAuthServerAPIConfigErrorAction(e.message));
  }
}
function* postAuthServerApiConfigHandler(payload) {
  try {
    yield put(postAuthServerAPIConfigLoadingAction());

    let response = yield httpPost(
      "/platform-configuration/authserverapiconfig",
      {},
      payload
    );
    if (response.errorCode) {
      throw new Error(response.message || response.description);
    }

    let getAuthServerConfigApiData = yield select(
      (state: RootSchema) =>
        state.platformConfiguration.getAuthServerConfigApi.Data
    );
    let defaultApi = {};
    for (var i = 0; i < getAuthServerConfigApiData.length; i++) {
      if (!getAuthServerConfigApiData[i].Id)
        defaultApi = getAuthServerConfigApiData[i];
    }
    if (response.Data.length) {
      response.Data.unshift(defaultApi);
    }

    yield put(postAuthServerAPIConfigSuccessAction(response));
    yield put(getAuthServerAPIConfigLoadingAction());
    yield put(getAuthServerAPIConfigSuccessAction(response));
  } catch (e) {
    yield put(postAuthServerAPIConfigErrorAction(e.message));
  }
}

function* postAppFeatureHandler(payload) {
  try {
    yield put(setAppFeaturesLoadingAction());

    let response = yield httpPost(
      "/platform-configuration/app-features",
      {},
      payload
    );
    if (response.errorCode) {
      throw new Error(response.message || response.description);
    }
    let features: string[] = [];
    response.Data.map(feature => {
      features[feature.feature] = feature.status;
    });
    yield put(setAppFeaturesSuccessAction(features));
    yield put(getAppFeatureSuccessAction(features));
  } catch (e) {
    yield put(setAppFeaturesErrorAction(e.message));
  }
}

function* putRenewCertificateHandler(payload) {
  try {
    yield put(renewCertificateLoadingAction());

    let response: PutSamlConfigSchema = yield httpPut(
      "/platform-configuration/renew-certificate",
      {},
      payload
    );
    if (!response["errorCode"]) {
      yield put(renewCertificateSuccessAction(response));
      if (response.Data && payload.type === "teamsso") {
        yield put(
          getSamlConfigSuccessAction({
            ...response.Data.filter(
              resp => resp.Provider === payload.Provider
            )[0],
            queriedAppName: payload.Provider.split("saml_")[1]
          })
        );
      }
    } else {
      yield put(renewCertificateErrorAction(response["message"]));
    }
  } catch (e) {
    yield put(renewCertificateErrorAction(e.message));
  }
}

function* getSSOIntegrationHandler(payload: any) {
  try {
    yield put(getSSOIntegrationSuccessAction({ Data: [] }));
    yield put(getSSOIntegrationLoadingAction());
    let response: any;
    if (payload.type === "sp") {
      response = yield httpGet("/platform-configuration/integration/samlsp");
    } else {
      response = yield httpGet("/platform-configuration/integration/saml");
    }

    yield put(
      getSSOIntegrationSuccessAction({
        ...response,
        message: "",
        description: ""
      })
    );
  } catch (e) {
    yield put(getSSOIntegrationErrorAction(e.message));
  }
}

function* getAvailableSSOIntegrationHandler() {
  try {
    yield put(getAvailableSSOIntegrationLoadingAction());

    let response: any = yield httpGet(
      "/platform-configuration/available-integrations"
    );
    if (response.ErrorCode) {
      yield put(
        getAvailableSSOIntegrationErrorAction(
          response.Message || response.Description || ""
        )
      );
    } else {
      yield put(
        getAvailableSSOIntegrationSuccessAction({
          ...response,
          message: "",
          description: ""
        })
      );
    }
  } catch (e) {
    yield put(getAvailableSSOIntegrationErrorAction(e.message));
  }
}

function* postSSOIntegrationHandler(payload: any) {
  try {
    yield put(postSSOIntegrationLoadingAction());
    let response;
    if (payload.type === "sp") {
      delete payload.type;
      response = yield httpPost(
        "/platform-configuration/integration/samlsp",
        {},
        payload
      );
    } else {
      delete payload.type;
      response = yield httpPost(
        "/platform-configuration/integration/saml",
        {},
        payload
      );
    }
    if (response.errorCode) {
      throw new Error(response.message || response.description || "");
    } else {
      yield put(postSSOIntegrationSuccessAction(response));
      yield put(getSSOIntegrationSuccessAction(response));
    }
  } catch (e) {
    yield put(postSSOIntegrationErrorAction(e.message));
  }
}

function* putSSOIntegrationHandler(payload: any) {
  try {
    yield put(putSSOIntegrationLoadingAction());

    let response;
    if (payload.type === "sp") {
      delete payload.type;
      response = yield httpPut(
        "/platform-configuration/integration/samlsp",
        {},
        payload
      );
    } else {
      delete payload.type;
      response = yield httpPut(
        "/platform-configuration/integration/saml",
        {},
        payload
      );
    }
    if (response.errorCode) {
      throw new Error(response.message || response.description || "");
    } else {
      yield put(putSSOIntegrationSuccessAction(response));
      yield put(getSSOIntegrationSuccessAction(response));
    }
  } catch (e) {
    yield put(putSSOIntegrationErrorAction(e.message));
  }
}

function* deleteSSOIntegrationHandler(payload: any) {
  try {
    yield put(deleteSSOIntegrationLoadingAction());
    let response;
    if (payload.type === "sp") {
      delete payload.type;
      response = httpDelete(
        "/platform-configuration/adfs?v=" + `${new Date().getTime()}`,
        {},
        { Provider: payload.AppName }
      );
    } else {
      delete payload.type;
      response = yield httpDelete(
        "/platform-configuration/saml?v=" + new Date().getTime(),
        {},
        payload
      );
    }

    if (response.errorCode) {
      throw new Error(response.message || response.description || "");
    } else {
      yield put(
        deleteSSOIntegrationSuccessAction({
          ...response,
          AppName: payload.AppName
        })
      );
    }
  } catch (e) {
    yield put(deleteSSOIntegrationErrorAction(e.message));
  }
}

function* getApiKeyHandler() {
  try {
    yield put(getApiKeyLoadingAction());

    let response: GetApiKeySchema = yield httpGet(
      "/security-configuration/api-credentials/keys",
      { fields: "Key", helpwidgetParams: true }
    );
    yield put(getApiKeySuccessAction(response));
  } catch (e) {
    yield put(getApiKeyErrorAction(e.message));
  }
}

function* getSMSSettingsAndConfigHandler() {
  try {
    yield put(getSMSSettingsAndConfigLoadingAction());

    const data: GetSMSSettingsAndConfigSchema = yield httpGet(
      "/platform-configuration/sms-settings-and-config",
      {}
    );

    yield put(getSMSSettingsAndConfigSuccessAction({ ...data }));
  } catch (e) {
    yield put(getSMSSettingsAndConfigErrorAction(e.message));
  }
}

function* getSMSTemplatesHandler() {
  try {
    yield put(getSMSTemplatesLoadingAction());

    const data: GetSMSTemplatesSchema = yield httpGet(
      "/platform-configuration/sms-template",
      {}
    );

    yield put(getSMSTemplatesSuccessAction({ ...data }));
  } catch (e) {
    yield put(getSMSTemplatesErrorAction(e.message));
  }
}

function* getSMSProviderConfigHandler() {
  try {
    yield put(getSMSProviderConfigLoadingAction());

    const data: GetSMSProviderConfigSchema = yield httpGet(
      "/platform-configuration/sms-config",
      {}
    );

    yield put(getSMSProviderConfigSuccessAction({ ...data }));
  } catch (e) {
    yield put(getSMSProviderConfigErrorAction(e.message));
  }
}

function* getCustomSMSProviderConfigHandler() {
  try {
    yield put(getCustomSMSProviderConfigLoadingAction());

    const data: GetCustomSMSProviderConfigSchema = yield httpGet(
      "/platform-configuration/sms-config/custom",
      {}
    );

    yield put(getCustomSMSProviderConfigSuccessAction({ ...data }));
  } catch (e) {
    yield put(getCustomSMSProviderConfigErrorAction(e.message));
  }
}

function* saveSMSConfigHandler(payload: any) {
  try {
    yield put(saveSMSConfigLoadingAction());

    const data: SaveSMSConfigSchema = yield httpPost(
      "/platform-configuration/sms-config",
      {},
      payload
    );

    yield put(saveSMSConfigSuccessAction({ ...data }));
    yield put(saveCustomSMSConfigSuccessAction({}));
  } catch (e) {
    yield put(saveSMSConfigErrorAction(e.message));
  }
}

function* saveCustomSMSConfigHandler(payload: any) {
  try {
    yield put(saveCustomSMSConfigLoadingAction());

    const data: SaveCustomSMSConfigSchema = yield httpPost(
      "/platform-configuration/sms-config/custom",
      {},
      payload
    );

    yield put(saveCustomSMSConfigSuccessAction({ ...data }));
    yield put(saveSMSConfigSuccessAction({}));
  } catch (e) {
    yield put(saveCustomSMSConfigErrorAction(e.message));
  }
}
function* resetSMSConfigHandler() {
  try {
    yield put(resetSMSConfigLoadingAction());

    const data: ResetSMSConfigSchema = yield httpPut(
      "/platform-configuration/sms-config/reset",
      {}
    );

    yield put(resetSMSConfigSuccessAction({ ...data }));
    yield put(saveSMSConfigSuccessAction({}));
    yield put(saveCustomSMSConfigSuccessAction({}));
  } catch (e) {
    yield put(resetSMSConfigErrorAction(e.message));
  }
}

function* getGlobalSMSSettingsHandler() {
  try {
    yield put(getGlobalSMSSettingsLoadingAction());
    let response: SMSSettingsResponseSchema = yield httpGet(
      "/platform-configuration/global-sms-configuration"
    );
    yield put(getGlobalSMSSettingsSuccessAction(response));
  } catch (e) {
    yield put(getGlobalSMSSettingsErrorAction(e.message));
  }
}

function* saveGlobalSMSSettingsHandler(
  payload: SaveSMSSettingsBodyAndResponseSchema
) {
  try {
    yield put(saveGlobalSMSSettingsLoadingAction());

    let response: SaveSMSSettingsBodyAndResponseSchema = yield httpPut(
      "/platform-configuration/global-sms-configuration",
      {},
      payload
    );
    yield put(saveGlobalSMSSettingsSuccessAction(response));
  } catch (e) {
    yield put(saveGlobalSMSSettingsErrorAction(e.message));
  }
}

function* getRolesHandler() {
  try {
    yield put(getRolesLoadingAction());

    const data: GetRolesSchema = yield httpGet("/platform-configuration/role");

    yield put(getRolesSuccessAction({ ...data }));
  } catch (e) {
    yield put(getRolesErrorAction(e.message));
  }
}

function* getConfigurationListHandler() {
  try {
    yield put(getConfigurationListLoadingAction());

    const data: GetConfigurationListSchema = yield httpGet(
      "/platform-configuration/configuration/list"
    );

    yield put(getConfigurationListSuccessAction(data));
  } catch (e) {
    yield put(getConfigurationListErrorAction(e.message));
  }
}

function* getSocialProviderListHandler() {
  try {
    yield put(getSocialProviderListLoadingAction());

    const data: GetSocialProviderListSchema = yield httpGet(
      "/platform-configuration/social-provider/list"
    );

    yield put(getSocialProviderListSuccessAction(data));
  } catch (e) {
    yield put(getSocialProviderListErrorAction(e.message));
  }
}

function* transferConfigurationsHandler(payload: TransferConfigurationsArgs) {
  try {
    yield put(transferConfigurationsLoadingAction());

    const data: TransferConfigurationsSchema = yield httpPost(
      "/platform-configuration/configuration/transfer",
      {},
      payload
    );

    if (data.errorCode) {
      throw new Error(data.message || data.description);
    }
    yield put(transferConfigurationsSuccessAction(data));
  } catch (e) {
    yield put(transferConfigurationsErrorAction(e.message));
  }
}

function* getMultipleSocialProviderHandler() {
  try {
    yield put(getMultipleSocialProviderLoadingAction());
    //GetSocialProviderListSchema
    let data: any = yield httpGet(
      "/platform-configuration/multipel-social-provider"
    );
    if (data.errorCode === 1193) {
      data = { response: [] };
    } else if (data.errorCode) {
      throw new Error(data.description || data.message);
    }
    yield put(getMultipleSocialProviderSuccessAction(data.Data));
  } catch (e) {
    yield put(getMultipleSocialProviderErrorAction(e.message));
  }
}
function* postMultipleSocialProviderHandler(payload: any) {
  try {
    yield put(postMultipleSocialProviderLoadingAction());
    //GetSocialProviderListSchema
    const data: any = yield httpPost(
      "/platform-configuration/multipel-social-provider",
      {},
      payload
    );
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    }
    yield put(postMultipleSocialProviderSuccessAction(data.Data));
  } catch (e) {
    yield put(postMultipleSocialProviderErrorAction(e.message));
  }
}
function* putMultipleSocialProviderHandler(payload: any) {
  try {
    yield put(putMultipleSocialProviderLoadingAction());
    //GetSocialProviderListSchema
    const data: any = yield httpPut(
      "/platform-configuration/multipel-social-provider",
      {},
      payload
    );
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    }
    yield put(putMultipleSocialProviderSuccessAction(data.Data));
  } catch (e) {
    yield put(putMultipleSocialProviderErrorAction(e.message));
  }
}

function* deleteMultipleSocialProviderHandler(payload: {
  provider: string;
  appname: string;
}) {
  try {
    yield put(deleteMultipleSocialProviderLoadingAction());
    //GetSocialProviderListSchema
    let data: any = yield httpDelete(
      "/platform-configuration/multipel-social-provider/delete/" +
        payload.provider +
        "/" +
        payload.appname,
      {},
      {}
    );
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    } else {
      data = { ...data, ...payload };
    }
    yield put(deleteMultipleSocialProviderSuccessAction(data));
  } catch (e) {
    yield put(deleteMultipleSocialProviderErrorAction(e.message));
  }
}

function* getOAuthProvidersHandler() {
  try {
    yield put(getOAuthProvidersLoadingAction());
    const data: any = yield httpGet(
      "/platform-configuration/custom-oauth-provider/all?v=" +
        `${new Date().getTime()}`
    );
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    }
    yield put(getOAuthProvidersSuccessAction(data));
  } catch (e) {
    yield put(getOAuthProvidersErrorAction(e.message));
  }
}

function* getJWTProvidersHandler() {
  try {
    yield put(getJWTProvidersLoadingAction());
    const data: any = yield httpGet(
      "/platform-configuration/customidp/jwt/sp?v=" + `${new Date().getTime()}`
    );
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    }
    yield put(getJWTProvidersSuccessAction(data));
  } catch (e) {
    yield put(getJWTProvidersErrorAction(e.message));
  }
}

function* getSAMLProvidersHandler() {
  try {
    yield put(getSAMLProvidersLoadingAction());
    const data: any = yield httpGet(
      "/platform-configuration/adfs?v=" + `${new Date().getTime()}`
    );
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    }
    yield put(getSAMLProvidersSuccessAction(data));
  } catch (e) {
    yield put(getSAMLProvidersErrorAction(e.message));
  }
}

function* getOIDCandOAuthAppsHandler() {
  try {
    yield put(getOIDCandOAuthAppsLoadingAction());
    const data: any = yield httpGet(
      "/platform-configuration/sso/oauth?v=" + new Date().getTime()
    );
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    }

    yield put(
      getOIDCandOAuthAppsSuccessAction(
        data.Data.filter(r => {
          if (r.ApplicationType !== "service") {
            return r;
          }
        })
      )
    );
  } catch (e) {
    yield put(getOIDCandOAuthAppsErrorAction(e.message));
  }
}

function* getPassKeyConfigHandler() {
  try {
    yield put(getPassKeyConfigLoadingAction());
    let response: any = yield httpGet("/platform-configuration/passkey/config");
    yield put(getPassKeyConfigSuccessAction(response));
  } catch (e) {
    yield put(getPassKeyConfigErrorAction(e.message));
  }
}

function* updatePassKeyConfigHandler(payload: any) {
  try {
    yield put(putPassKeyConfigLoadingAction());

    let response: any = yield httpPut(
      "/platform-configuration/passkey/config",
      {},
      payload
    );
    yield put(putPassKeyConfigSuccessAction(response));
  } catch (e) {
    yield put(putPassKeyConfigErrorAction(e.message));
  }
}

/**
 * Watchers
 */

export function* watchGetOAuthProviders() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_OAUTH_PROVIDERS);
    yield call(getOAuthProvidersHandler);
  }
}

export function* watchGetJWTProviders() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_JWT_PROVIDERS);
    yield call(getJWTProvidersHandler);
  }
}

export function* watchGetSAMLProviders() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_SAML_PROVIDERS);
    yield call(getSAMLProvidersHandler);
  }
}

export function* watchGetOIDCandOAuthApps() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_OIDC_AND_OAUTH_APPS);
    yield call(getOIDCandOAuthAppsHandler);
  }
}

export function* watchDeleteMultipleSocialProvider() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.DELETE_MULTIPLE_SOCIAL_PROVIDER
    );
    yield call(deleteMultipleSocialProviderHandler, payload);
  }
}
export function* watchGetMultipleSocialProvider() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_MULTIPLE_SOCIAL_PROVIDER);
    yield call(getMultipleSocialProviderHandler);
  }
}
export function* watchPostMultipleSocialProvider() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.POST_MULTIPLE_SOCIAL_PROVIDER
    );
    yield call(postMultipleSocialProviderHandler, payload);
  }
}
export function* watchPutMultipleSocialProvider() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.PUT_MULTIPLE_SOCIAL_PROVIDER
    );
    yield call(putMultipleSocialProviderHandler, payload);
  }
}
export function* watchGetGlobalEmailConfig() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_GLOBAL_EMAIL_CONFIG);
    yield call(getGlobalEmailConfigHandler);
  }
}

export function* watchSaveGlobalEmailConfig() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.SAVE_GLOBAL_EMAIL_CONFIG
    );
    yield call(saveGlobalEmailConfigHandler, payload);
  }
}

export function* watchGetApiKey() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_API_KEY);
    yield call(getApiKeyHandler);
  }
}

export function* watchGetSMSSettingsAndConfig() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_SMS_SETTINGS_AND_CONFIG);
    yield call(getSMSSettingsAndConfigHandler);
  }
}

export function* watchGetSMSTemplates() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_SMS_TEMPLATES);
    yield call(getSMSTemplatesHandler);
  }
}

export function* watchGetSMSProviderConfig() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_SMS_PROVIDER_CONFIG);
    yield call(getSMSProviderConfigHandler);
  }
}

export function* watchGetCustomSMSProviderConfig() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_CUSTOM_SMS_PROVIDER_CONFIG);
    yield call(getCustomSMSProviderConfigHandler);
  }
}

export function* watchSaveSMSConfig() {
  while (true) {
    const { payload } = yield take(PlatformConfigurationConst.SAVE_SMS_CONFIG);
    yield call(saveSMSConfigHandler, payload);
  }
}

export function* watchSaveCustomSMSConfig() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.SAVE_CUSTOM_SMS_CONFIG
    );
    yield call(saveCustomSMSConfigHandler, payload);
  }
}

export function* watchResetSMSConfig() {
  while (true) {
    yield take(PlatformConfigurationConst.RESET_SMS_CONFIG);
    yield call(resetSMSConfigHandler);
  }
}

export function* watchGetGlobalSMSSettings() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_GLOBAL_SMS_SETTINGS);
    yield call(getGlobalSMSSettingsHandler);
  }
}

export function* watchSaveGlobalSMSSettings() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.SAVE_GLOBAL_SMS_SETTINGS
    );
    yield call(saveGlobalSMSSettingsHandler, payload);
  }
}

export function* watchGetRoles() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_ROLES);
    yield call(getRolesHandler);
  }
}

export function* watchGetConfigurationList() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_CONFIGURATION_LIST);
    yield call(getConfigurationListHandler);
  }
}

export function* watchGetSocialProviderList() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_SOCIALPROVIDER_LIST);
    yield call(getSocialProviderListHandler);
  }
}

export function* watchTransferConfigurations() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.TRANSFER_CONFIGURATIONS
    );
    yield call(transferConfigurationsHandler, payload);
  }
}

export function* watchGetAuthServerApiConfig() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_AUTH_SERVER_API_CONFIG);
    yield call(getAuthServerApiConfigHandler);
  }
}

export function* watchDeleteAuthServerApiConfig() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.DELETE_AUTH_SERVER_API_CONFIG
    );
    yield call(deleteAuthServerApiConfigHandler, payload);
  }
}
export function* watchUpdateAuthServerApiConfig() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.PUT_AUTH_SERVER_API_CONFIG
    );
    yield call(updateAuthServerApiConfigHandler, payload);
  }
}
export function* watchPostAuthServerApiConfig() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.POST_AUTH_SERVER_API_CONFIG
    );
    yield call(postAuthServerApiConfigHandler, payload);
  }
}

export function* watchSetAppFeature() {
  while (true) {
    const { payload } = yield take(PlatformConfigurationConst.SET_APP_FEATURES);
    yield call(postAppFeatureHandler, payload);
  }
}

export function* watchRenewCertificate() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.RENEW_CERTIFICATE
    );
    yield call(putRenewCertificateHandler, payload);
  }
}

export function* watchGetSSOIntegration() {
  while (true) {
    const { payload } = yield take(PlatformConfigurationConst.SSO_INTEGRATION);
    yield call(getSSOIntegrationHandler, payload);
  }
}

export function* watchGetAvailableSSOIntegration() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.AVAILABLE_SSO_INTEGRATION
    );
    yield call(getAvailableSSOIntegrationHandler);
  }
}

export function* watchPostSSOIntegration() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.POST_SSO_INTEGRATION
    );
    yield call(postSSOIntegrationHandler, payload);
  }
}

export function* watchPutSSOIntegration() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.PUT_SSO_INTEGRATION
    );
    yield call(putSSOIntegrationHandler, payload);
  }
}

export function* watchDeleteSSOIntegration() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.DELETE_SSO_INTEGRATION
    );
    yield call(deleteSSOIntegrationHandler, payload);
  }
}

export function* watchGetPassKeyConfig() {
  while (true) {
    yield take(PlatformConfigurationConst.GET_PASSKEY_CONFIG);
    yield call(getPassKeyConfigHandler);
  }
}

export function* watchUpdatePassKeyConfig() {
  while (true) {
    const { payload } = yield take(
      PlatformConfigurationConst.UPDATE_PASSKEY_CONFIG
    );
    yield call(updatePassKeyConfigHandler, payload);
  }
}

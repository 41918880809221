import { combineReducers } from "redux";
import { ActionSchema } from "../actions";
import authReducer, { AuthSchema } from "./authReducer";
import appStateReducer, { AppStateSchema } from "./appStateReducer";
import apiMetricsReducer, { ApiMetricsSchema } from "./apiMetricsReducer";
import insightsReducer, { InsightsSchema } from "./insightsReducer";
import billingReducer, { BillingSchema } from "./billingReducer";
import updatesReducer, { UpdatesSchema } from "./updatesReducer";
import deploymentReducer, { DeploymentSchema } from "./deploymentReducer";
import teamReducer, { TeamSchema } from "./teamReducer";
import integrationsReducer, { IntegrationsSchema } from "./integrationsReducer";
import supportReducer, { SupportSchema } from "./supportReducer";
import dataConfigurationReducer, {
  DataConfigurationSchema
} from "./dataConfigurationReducer";

import {
  securityConfigurationReducer,
  SecurityConfigurationSchema
} from "./securityConfigurationReducer";

import AccountCombineReducer, { ApiAccountSchema } from "./accountReducer";
import customerManagementReducer, {
  CustomerManagementSchema
} from "./customerManagementReducer";
import segmentationsReducer, {
  SegmentationsSchema
} from "./segmentationReducer";
import platformConfigurationReducer, {
  PlatformConfigurationSchema
} from "./platformConfigurationReducer";
import orchestrationReducer, {
  OrchestrationSchema
} from "./orchestrationReducer";
import brandCustomizationReducer, {
  BrandCustomizationSchema
} from "./brandCustomizationReducer";

// Default reducer
function defaultReducer(state = {}, _action: ActionSchema<any>) {
  return state;
}

// Combine all schemas
export interface RootSchema {
  default: {};
  appState: AppStateSchema;
  auth: AuthSchema;
  apiMetrics: ApiMetricsSchema;
  insights: InsightsSchema;
  billing: BillingSchema;
  team: TeamSchema;
  updates: UpdatesSchema;
  account: ApiAccountSchema;
  deployment: DeploymentSchema;
  integrations: IntegrationsSchema;
  support: SupportSchema;
  securityConfiguration: SecurityConfigurationSchema;
  platformConfiguration: PlatformConfigurationSchema;
  dataConfiguration: DataConfigurationSchema;
  customerManagement: CustomerManagementSchema;
  segmentations: SegmentationsSchema;
  orchestration: OrchestrationSchema;
  brandCustomization: BrandCustomizationSchema;
}

// Combine all reducers
const rootReducer = combineReducers({
  default: defaultReducer,
  appState: appStateReducer,
  auth: authReducer,
  apiMetrics: apiMetricsReducer,
  insights: insightsReducer,
  team: teamReducer,
  billing: billingReducer,
  updates: updatesReducer,
  account: AccountCombineReducer,
  deployment: deploymentReducer,
  integrations: integrationsReducer,
  support: supportReducer,
  securityConfiguration: securityConfigurationReducer,
  platformConfiguration: platformConfigurationReducer,
  customerManagement: customerManagementReducer,
  dataConfiguration: dataConfigurationReducer,
  segmentations: segmentationsReducer,
  orchestration: orchestrationReducer,
  brandCustomization: brandCustomizationReducer
});

export default rootReducer;

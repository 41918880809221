import {
  SideEffectSchema,
  loadingAction,
  errorAction,
  successSideEffectState,
  generateConst,
  ObjectSchema
} from ".";

// Constants
export const ApiMetricsConst = generateConst("API_METRICS", [
  "GET_REQUEST_COUNT_DATA",
  "GET_RESPONSE_CODE_DATA",
  "GET_PERFORMANCE_DATA",
  "GET_SMS_ANALYTICS_DATA",
  "GET_SMS_STATUS_DATA",
  "GET_LOGS_DATA"
]);

/**
 * GET_REQUEST_COUNT
 */
export interface GetRequestCountDataSchema extends SideEffectSchema {
  data: {
    datetime: string;
    createprofile: string;
    auth: string;
    modifyprofile: string;
    profilelookup: string;
    deleteprofile: string;
  }[];
}
export interface GetApiMetricsArgs {
  startTime: number;
  endTime: number;
  apiKey: string;
  endpoint:
    | "lookupprofile"
    | "auth"
    | "createprofile"
    | "deleteprofile"
    | "updateprofile";
  timeslice: "1m";
}
export const getRequestCountDataAction = (
  payload: Pick<GetApiMetricsArgs, Exclude<keyof GetApiMetricsArgs, "endpoint">>
) => {
  return {
    type: ApiMetricsConst.GET_REQUEST_COUNT_DATA,
    payload
  };
};

export const getRequestCountDataLoadingAction = () =>
  loadingAction(ApiMetricsConst.GET_REQUEST_COUNT_DATA_LOADING);

export const getRequestCountDataErrorAction = (error: string) =>
  errorAction(ApiMetricsConst.GET_REQUEST_COUNT_DATA_ERROR, error);

export const getRequestCountDataSuccessAction = (
  data: GetRequestCountDataSchema
) => {
  const payload: GetRequestCountDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: ApiMetricsConst.GET_REQUEST_COUNT_DATA_SUCCESS,
    payload
  };
};

/**
 * GET_RESPONSE_CODE
 */
export interface GetResponseCodeDataSchema extends SideEffectSchema {
  data: {
    datetime: string;
    success: string;
    http4xx: string;
    http5xx: string;
  }[];
}
export const getResponseCodeDataAction = (
  payload: Pick<GetApiMetricsArgs, Exclude<keyof GetApiMetricsArgs, "endpoint">>
) => {
  return {
    type: ApiMetricsConst.GET_RESPONSE_CODE_DATA,
    payload
  };
};

export const getResponseCodeDataLoadingAction = () =>
  loadingAction(ApiMetricsConst.GET_RESPONSE_CODE_DATA_LOADING);

export const getResponseCodeDataSuccessAction = (
  data: GetResponseCodeDataSchema
) => {
  const payload: GetResponseCodeDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: ApiMetricsConst.GET_RESPONSE_CODE_DATA_SUCCESS,
    payload
  };
};

export const getResponseCodeDataErrorAction = (error: string) =>
  errorAction(ApiMetricsConst.GET_RESPONSE_CODE_DATA_ERROR, error);

/**
 * GET_PERFORMANCE_CREATE_DATA
 */
type PerformanceDataSchema = {
  datetime: string;
  total: string;
  responselt1s: string;
  responselt500ms: string;
  responselt3s: string;
  responsegt3s: string;
}[];
export interface GetPerformanceDataSchema extends SideEffectSchema {
  data: PerformanceDataSchema;
  code?: string;
}
export const getPerformanceDataAction = (payload: GetApiMetricsArgs) => {
  return {
    type: ApiMetricsConst.GET_PERFORMANCE_DATA,
    payload
  };
};

export const getPerformanceDataLoadingAction = () =>
  loadingAction(ApiMetricsConst.GET_PERFORMANCE_DATA_LOADING);

export const getPerformanceDataErrorAction = (error: string) =>
  errorAction(ApiMetricsConst.GET_PERFORMANCE_DATA_ERROR, error);

export const getPerformanceDataSuccessAction = (
  data: GetPerformanceDataSchema
) => {
  const payload: GetPerformanceDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: ApiMetricsConst.GET_PERFORMANCE_DATA_SUCCESS,
    payload
  };
};

/**
 * GET_SMS_ANALYTICS
 */
export interface GetSmsAnalyticsDataSchema extends SideEffectSchema {
  data: {
    timestamp: string;
  }[];
}
export interface GetSmsAnalyticsArgs {
  startTime: number;
  endTime: number;
  appName: string;
  appId: number;
  timeslice: "1m";
}
export const getSmsAnalyticsDataAction = (
  payload: Pick<
    GetSmsAnalyticsArgs,
    Exclude<keyof GetSmsAnalyticsArgs, "endpoint">
  >
) => {
  return {
    type: ApiMetricsConst.GET_SMS_ANALYTICS_DATA,
    payload
  };
};

export const getSmsAnalyticsDataLoadingAction = () =>
  loadingAction(ApiMetricsConst.GET_SMS_ANALYTICS_DATA_LOADING);

export const getSmsAnalyticsDataErrorAction = (error: string) =>
  errorAction(ApiMetricsConst.GET_SMS_ANALYTICS_DATA_ERROR, error);

export const getSmsAnalyticsDataSuccessAction = (
  data: GetSmsAnalyticsDataSchema
) => {
  const payload: GetSmsAnalyticsDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: ApiMetricsConst.GET_SMS_ANALYTICS_DATA_SUCCESS,
    payload
  };
};

/**
 * GET_LOGS_DATA
 */
export interface LogsDataSchema extends ObjectSchema<string> {
  type: string;
  message: string;
  requestpath: string;
  appname: string;
  timestamp: string;
}
export interface GetLogsDataSchema extends SideEffectSchema {
  data: LogsDataSchema[];
}

export interface GetLogsArgs {
  startTime: number;
  endTime: number;
  appName: string;
  appId: number;
}

export const getLogsDataAction = (
  payload: Pick<GetLogsArgs, Exclude<keyof GetLogsArgs, "endpoint">>
) => {
  return {
    type: ApiMetricsConst.GET_LOGS_DATA,
    payload
  };
};

export const getLogsDataLoadingAction = () =>
  loadingAction(ApiMetricsConst.GET_LOGS_DATA_LOADING);

export const getLogsDataErrorAction = (error: string) =>
  errorAction(ApiMetricsConst.GET_LOGS_DATA_ERROR, error);

export const getLogsDataSuccessAction = (data: GetLogsDataSchema) => {
  const payload: GetLogsDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: ApiMetricsConst.GET_LOGS_DATA_SUCCESS,
    payload
  };
};

/**
 * GET_SMS_STATUS
 */
export interface GetSmsStatusDataSchema extends SideEffectSchema {
  data: {
    timestamp: string;
  }[];
}
export interface GetSmsStatusArgs {
  startTime: number;
  endTime: number;
  twilioId: string;
  timeslice: "1m";
}
export const getSmsStatusDataAction = (
  payload: Pick<GetSmsStatusArgs, Exclude<keyof GetSmsStatusArgs, "endpoint">>
) => {
  return {
    type: ApiMetricsConst.GET_SMS_STATUS_DATA,
    payload
  };
};

export const getSmsStatusDataLoadingAction = () =>
  loadingAction(ApiMetricsConst.GET_SMS_STATUS_DATA_LOADING);

export const getSmsStatusDataErrorAction = (error: string) =>
  errorAction(ApiMetricsConst.GET_SMS_STATUS_DATA_ERROR, error);

export const getSmsStatusDataSuccessAction = (data: GetSmsStatusDataSchema) => {
  const payload: GetSmsStatusDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: ApiMetricsConst.GET_SMS_STATUS_DATA_SUCCESS,
    payload
  };
};

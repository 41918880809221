import { take, call, put } from "redux-saga/effects";
import { httpGet, httpPost, httpPut, httpDelete } from "../../services";

import {
  DataConfigurationConst,
  GetDataStorageLocationResponseSchema,
  getDataStorageLocationLoadingAction,
  getDataStorageLocationSuccessAction,
  getDataStorageLocationErrorAction,
  EncryptionAtRestSchema,
  getEncryptionAtRestLoadingAction,
  getEncryptionAtRestSuccessAction,
  getEncryptionAtRestErrorAction,
  getHashingAlgorithmLoadingAction,
  getHashingAlgorithmSuccessAction,
  getHashingAlgorithmErrorAction,
  GetHashingAlgorthimSchema,
  getPlatformRegistrationFieldsLoadingAction,
  getPlatformRegistrationFieldsSuccessAction,
  getPlatformRegistrationFieldsErrorAction,
  PlatformRegistrationFieldsSchema,
  getSiteRegistrationFormSettingsLoadingAction,
  SiteRegistrationFormSettingsSchema,
  getSiteRegistrationFormSettingsSuccessAction,
  getSiteRegistrationFormSettingsErrorAction,
  getDataObjectKeyLoadingAction,
  DataObjectKeySchema,
  getDataObjectKeySuccessAction,
  getDataObjectKeyErrorAction,
  getDataFieldsKeyLoadingAction,
  getDataFieldsKeySuccessAction,
  getDataFieldsKeyErrorAction,
  DataFieldsKeySchema,
  GetPrivacyPolicyVersionEnabledSchema,
  getPrivacyPolicyVersionEnabledLoadingAction,
  getPrivacyPolicyVersionEnabledSuccessAction,
  getPrivacyPolicyVersionEnabledErrorAction,
  SavePrivacyPolicyVersionEnabledSchema,
  savePrivacyPolicyVersionEnabledLoadingAction,
  savePrivacyPolicyVersionEnabledSuccessAction,
  savePrivacyPolicyVersionEnabledErrorAction,
  SavePrivacyPolicyVersionSchema,
  savePrivacyPolicyVersionLoadingAction,
  savePrivacyPolicyVersionSuccessAction,
  savePrivacyPolicyVersionErrorAction,
  GetPrivacyPolicyVersionSchema,
  getPrivacyPolicyVersionLoadingAction,
  getPrivacyPolicyVersionSuccessAction,
  getPrivacyPolicyVersionErrorAction,
  sendAgeVerificationLoadingAction,
  sendAgeVerificationSuccessAction,
  sendAgeVerificationErrorAction,
  SendAgeVerificationSchema,
  AddConsentGroupArgs,
  AddConsentGrpRespSchema,
  addConsentGroupLoadingAction,
  addConsentGroupSuccessAction,
  addConsentGroupErrorAction,
  getConsentGroupLoadingAction,
  GetConsentGrpRespSchema,
  getConsentGroupSuccessAction,
  getConsentGroupErrorAction,
  getConsentGroupAction,
  AddConsentOptionArgs,
  addConsentOptionLoadingAction,
  addConsentOptionSuccessAction,
  getConsentOptionAction,
  addConsentOptionErrorAction,
  getConsentOptionLoadingAction,
  getConsentOptionSuccessAction,
  getConsentOptionErrorAction,
  GetConsentOptRespSchema,
  AddConsentOptRespSchema,
  ConsentFormSchema,
  addConsentFormLoadingAction,
  addConsentFormSuccessAction,
  addConsentFormErrorAction,
  AddConsentFormRespSchema,
  getConsentFormLoadingAction,
  getConsentFormSuccessAction,
  getConsentFormErrorAction,
  GetConsentFormRespSchema,
  updateConsentFormLoadingAction,
  updateConsentFormSuccessAction,
  updateConsentFormErrorAction,
  UpdateConsentFormRespSchema,
  DeleteConsentFormRespSchema,
  deleteConsentFormLoadingAction,
  deleteConsentFormSuccessAction,
  deleteConsentFormErrorAction,
  updateConsentLiveFormLoadingAction,
  updateConsentLiveFormSuccessAction,
  updateConsentLiveFormErrorAction,
  UpdateConsentLiveFormRespSchema
} from "../actions/dataConfigurationActions";

/**
 * Workers
 */

function* getDataStorageLocationHandler() {
  try {
    yield put(getDataStorageLocationLoadingAction());
    const response: GetDataStorageLocationResponseSchema = yield httpGet(
      "/data-configuration/data-storage/location"
    );
    if (response.responseDataCenter) {
      if (response.responseDataCenter.errorCode) {
        throw Error(
          `Error code ${response.responseDataCenter.errorCode}.` ||
            response.responseDataCenter.message ||
            response.responseDataCenter.description
        );
      } else if (!response.responseDataCenter.GlobalServer) {
        throw Error("Data storage location missing in response.");
      }
    } else {
      throw Error("Property responseDataCenter missing in response.");
    }
    yield put(getDataStorageLocationSuccessAction({ response }));
  } catch (e) {
    yield put(getDataStorageLocationErrorAction(e.message));
  }
}

function* getEncryptionAtRestHandler() {
  try {
    yield put(getEncryptionAtRestLoadingAction());

    const data: EncryptionAtRestSchema = yield httpGet(
      "/data-configuration/basic",
      { fields: "EncryptionAtRest" }
    );

    yield put(getEncryptionAtRestSuccessAction({ ...data }));
  } catch (e) {
    yield put(getEncryptionAtRestErrorAction(e.message));
  }
}

function* getHashingAlgorithmHandler() {
  try {
    yield put(getHashingAlgorithmLoadingAction());
    const response = yield httpGet("/data-configuration/password-settings");
    yield put(getHashingAlgorithmSuccessAction({ response }));
  } catch (e) {
    yield put(getHashingAlgorithmErrorAction(e.message));
  }
}

function* getPlatformRegistrationFieldsHandler() {
  try {
    yield put(getPlatformRegistrationFieldsLoadingAction());

    const data: PlatformRegistrationFieldsSchema = yield httpGet(
      "/data-configuration/platform-registration-fields",
      {}
    );

    yield put(getPlatformRegistrationFieldsSuccessAction({ ...data.Data }));
  } catch (e) {
    yield put(getPlatformRegistrationFieldsErrorAction(e.message));
  }
}

function* getSiteRegistrationFormSettingsHandler() {
  try {
    yield put(getSiteRegistrationFormSettingsLoadingAction());

    const data: SiteRegistrationFormSettingsSchema = yield httpGet(
      "/data-configuration/site-registration-form-settings",
      {}
    );

    yield put(getSiteRegistrationFormSettingsSuccessAction({ ...data.Data }));
  } catch (e) {
    yield put(getSiteRegistrationFormSettingsErrorAction(e.message));
  }
}

function* getDataObjectKeyHandler() {
  try {
    yield put(getDataObjectKeyLoadingAction());

    const data: DataObjectKeySchema = yield httpGet(
      "/data-configuration/data-storage/data-object-key",
      {}
    );

    yield put(getDataObjectKeySuccessAction({ ...data }));
  } catch (e) {
    yield put(getDataObjectKeyErrorAction(e.message));
  }
}

function* getDataFieldsKeyHandler() {
  try {
    yield put(getDataFieldsKeyLoadingAction());

    const data: DataFieldsKeySchema = yield httpGet(
      "/data-configuration/data-storage/data-fields-key",
      {}
    );

    yield put(getDataFieldsKeySuccessAction({ ...data }));
  } catch (e) {
    yield put(getDataFieldsKeyErrorAction(e.message));
  }
}

function* savePrivacyPolicyVersionEnabled(payload: any) {
  try {
    yield put(savePrivacyPolicyVersionEnabledLoadingAction());

    const data: SavePrivacyPolicyVersionEnabledSchema = yield httpPut(
      "/data-configuration/privacy-policy/configuration",
      {},
      payload.payload
    );

    yield put(savePrivacyPolicyVersionEnabledSuccessAction({ ...data }));
  } catch (e) {
    yield put(savePrivacyPolicyVersionEnabledErrorAction(e.message));
  }
}

function* savePrivacyPolicyVersion(payload: any) {
  try {
    yield put(savePrivacyPolicyVersionLoadingAction());

    const data: SavePrivacyPolicyVersionSchema = yield httpPut(
      "/data-configuration/privacy-policy/version",
      {},
      payload.payload
    );

    yield put(savePrivacyPolicyVersionSuccessAction({ data }));
  } catch (e) {
    yield put(savePrivacyPolicyVersionErrorAction(e.message));
  }
}

function* getPrivacyPolicyVersionEnabled() {
  try {
    yield put(getPrivacyPolicyVersionEnabledLoadingAction());
    const data: GetPrivacyPolicyVersionEnabledSchema = yield httpGet(
      "/data-configuration/privacy-policy/configuration"
    );
    yield put(getPrivacyPolicyVersionEnabledSuccessAction({ ...data }));
  } catch (e) {
    yield put(getPrivacyPolicyVersionEnabledErrorAction(e.message));
  }
}

function* getPrivacyPolicyVersion() {
  try {
    yield put(getPrivacyPolicyVersionLoadingAction());
    const data: GetPrivacyPolicyVersionSchema = yield httpGet(
      "/data-configuration/privacy-policy/version-list"
    );
    yield put(getPrivacyPolicyVersionSuccessAction({ ...data }));
  } catch (e) {
    yield put(getPrivacyPolicyVersionErrorAction(e.message));
  }
}

function* sendAgeVerificationHandler(
  ageRestriction: SendAgeVerificationSchema
) {
  try {
    yield put(sendAgeVerificationLoadingAction());

    const data: SendAgeVerificationSchema = yield httpPost(
      "/data-configuration/basic",
      {},
      ageRestriction.payload
    );

    yield put(sendAgeVerificationSuccessAction({ ...data }));
  } catch (e) {
    yield put(sendAgeVerificationErrorAction(e.message));
  }
}

function* addConsentGroupHandler(payload: AddConsentGroupArgs) {
  try {
    yield put(addConsentGroupLoadingAction());

    const data: AddConsentGrpRespSchema = yield httpPost(
      "/data-configuration/consent-center/optionGroups",
      {},
      payload
    );
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    }
    yield put(addConsentGroupSuccessAction({ ...data }));
    yield put(getConsentGroupAction());
  } catch (e) {
    yield put(addConsentGroupErrorAction(e.message));
  }
}

function* getConsentGroupHandler() {
  try {
    yield put(getConsentGroupLoadingAction());

    const data: GetConsentGrpRespSchema = yield httpGet(
      "/data-configuration/consent-center/optionGroups",
      {}
    );
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    }
    yield put(getConsentGroupSuccessAction({ ...data }));
  } catch (e) {
    yield put(getConsentGroupErrorAction(e.message));
  }
}

function* addConsentOptionHandler(payload: AddConsentOptionArgs) {
  try {
    yield put(addConsentOptionLoadingAction());

    const data: AddConsentOptRespSchema = yield httpPost(
      "/data-configuration/consent-center/options",
      {},
      payload
    );
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    }
    yield put(addConsentOptionSuccessAction({ ...data }));
    yield put(getConsentOptionAction());
  } catch (e) {
    yield put(addConsentOptionErrorAction(e.message));
  }
}

function* getConsentOptionHandler() {
  try {
    yield put(getConsentOptionLoadingAction());

    const data: GetConsentOptRespSchema = yield httpGet(
      "/data-configuration/consent-center/options",
      {}
    );
    if (data.errorCode) {
      if (data.errorCode !== 1150)
        throw new Error(data.description || data.message);
      data.Data = [];
    }
    yield put(getConsentOptionSuccessAction({ ...data }));
  } catch (e) {
    yield put(getConsentOptionErrorAction(e.message));
  }
}

function* addConsentFormHandler(payload: ConsentFormSchema) {
  try {
    yield put(addConsentFormLoadingAction());

    const data: AddConsentFormRespSchema = yield httpPost(
      "/data-configuration/consent-center/form",
      {},
      payload
    );
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    }
    yield put(addConsentFormSuccessAction({ ...data }));
  } catch (e) {
    yield put(addConsentFormErrorAction(e.message));
  }
}

function* updateConsentFormHandler(payload: ConsentFormSchema) {
  try {
    yield put(updateConsentFormLoadingAction());

    const data: UpdateConsentFormRespSchema = yield httpPut(
      "/data-configuration/consent-center/form",
      {},
      payload
    );
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    }
    yield put(updateConsentFormSuccessAction({ ...data }));
  } catch (e) {
    yield put(updateConsentFormErrorAction(e.message));
  }
}

function* updateConsentLiveFormHandler(payload: ConsentFormSchema) {
  try {
    yield put(updateConsentLiveFormLoadingAction());

    const data: UpdateConsentLiveFormRespSchema = yield httpPut(
      "/data-configuration/consent-center/live-form",
      {},
      payload
    );
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    }
    yield put(updateConsentLiveFormSuccessAction({ ...data }));
  } catch (e) {
    yield put(updateConsentLiveFormErrorAction(e.message));
  }
}

function* deleteConsentFormHandler(payload: any) {
  try {
    yield put(deleteConsentFormLoadingAction());

    const data: DeleteConsentFormRespSchema = yield httpDelete(
      "/data-configuration/consent-center/form",
      {},
      payload
    );
    if (data.errorCode) {
      throw new Error(data.description || data.message);
    }
    yield put(deleteConsentFormSuccessAction({ ...data }));
  } catch (e) {
    yield put(deleteConsentFormErrorAction(e.message));
  }
}

function* getConsentFormHandler() {
  try {
    yield put(getConsentFormLoadingAction());

    const data: GetConsentFormRespSchema = yield httpGet(
      "/data-configuration/consent-center/form",
      {}
    );
    if (data.errorCode) {
      if (data.errorCode !== 1150)
        throw new Error(data.description || data.message);
      data.Data = [];
    }
    yield put(getConsentFormSuccessAction({ ...data }));
  } catch (e) {
    yield put(getConsentFormErrorAction(e.message));
  }
}

/**
 * Watchers
 */

export function* watchGetDataStorageLocation() {
  while (true) {
    yield take(DataConfigurationConst.GET_DATA_STORAGE_LOCATION);
    yield call(getDataStorageLocationHandler);
  }
}
export function* watchEncryptionAtRest() {
  while (true) {
    yield take(DataConfigurationConst.GET_ENCRYPTION_AT_REST);
    yield call(getEncryptionAtRestHandler);
  }
}

export function* watchGetHashingAlgorithm() {
  while (true) {
    yield take(DataConfigurationConst.GET_HASHING_ALGORITHM);
    yield call(getHashingAlgorithmHandler);
  }
}

export function* watchPlatformRegistrationFields() {
  while (true) {
    yield take(DataConfigurationConst.GET_PLATFORM_REGISTRATION_FIELDS);
    yield call(getPlatformRegistrationFieldsHandler);
  }
}

export function* watchSiteRegistrationFormSettings() {
  while (true) {
    yield take(DataConfigurationConst.GET_SITE_REGISTRATION_FORM_SETTINGS);
    yield call(getSiteRegistrationFormSettingsHandler);
  }
}

export function* watchDataObjectKey() {
  while (true) {
    yield take(DataConfigurationConst.GET_DATA_OBJECT_KEY);
    yield call(getDataObjectKeyHandler);
  }
}

export function* watchDataFieldsKey() {
  while (true) {
    yield take(DataConfigurationConst.GET_DATA_FIELDS_KEY);
    yield call(getDataFieldsKeyHandler);
  }
}

export function* watchSavePrivacyPolicyVersionEnabled() {
  while (true) {
    const payload = yield take(
      DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION_ENABLED
    );
    yield call(savePrivacyPolicyVersionEnabled, payload);
  }
}

export function* watchSavePrivacyPolicyVersion() {
  while (true) {
    const payload = yield take(
      DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION
    );
    yield call(savePrivacyPolicyVersion, payload);
  }
}

export function* watchGetPrivacyPolicyVersionEnabled() {
  while (true) {
    yield take(DataConfigurationConst.GET_PRIVACY_POLICY_VERSION_ENABLED);
    yield call(getPrivacyPolicyVersionEnabled);
  }
}

export function* watchGetPrivacyPolicyVersion() {
  while (true) {
    yield take(DataConfigurationConst.GET_PRIVACY_POLICY_VERSION);
    yield call(getPrivacyPolicyVersion);
  }
}

export function* watchSendAgeVerification() {
  while (true) {
    const data = yield take(DataConfigurationConst.SUBMIT_AGE_VERIFICATION);
    yield call(sendAgeVerificationHandler, data);
  }
}

export function* watchAddConsentGroup() {
  while (true) {
    const { payload } = yield take(DataConfigurationConst.ADD_CONSENT_GROUP);
    yield call(addConsentGroupHandler, payload);
  }
}

export function* watchGetConsentGroup() {
  while (true) {
    yield take(DataConfigurationConst.GET_CONSENT_GROUP);
    yield call(getConsentGroupHandler);
  }
}

export function* watchAddConsentOption() {
  while (true) {
    const { payload } = yield take(DataConfigurationConst.ADD_CONSENT_OPTION);
    yield call(addConsentOptionHandler, payload);
  }
}

export function* watchGetConsentOption() {
  while (true) {
    yield take(DataConfigurationConst.GET_CONSENT_OPTION);
    yield call(getConsentOptionHandler);
  }
}

export function* watchAddConsentForm() {
  while (true) {
    const { payload } = yield take(DataConfigurationConst.ADD_CONSENT_FORM);
    yield call(addConsentFormHandler, payload);
  }
}

export function* watchUpdateConsentLiveForm() {
  while (true) {
    const { payload } = yield take(
      DataConfigurationConst.UPDATE_CONSENT_LIVE_FORM
    );
    yield call(updateConsentLiveFormHandler, payload);
  }
}

export function* watchUpdateConsentForm() {
  while (true) {
    const { payload } = yield take(DataConfigurationConst.UPDATE_CONSENT_FORM);
    yield call(updateConsentFormHandler, payload);
  }
}

export function* watchDeleteConsentForm() {
  while (true) {
    const { payload } = yield take(DataConfigurationConst.DELETE_CONSENT_FORM);
    yield call(deleteConsentFormHandler, payload);
  }
}

export function* watchGetConsentForm() {
  while (true) {
    yield take(DataConfigurationConst.GET_CONSENT_FORM);
    yield call(getConsentFormHandler);
  }
}

import {
  ObjectSchema,
  SideEffectSchema,
  loadingAction,
  errorAction,
  successSideEffectState,
  generateConst,
  V2ErrorResponseSchema
} from ".";

// Constants
export const BillingConst = generateConst("BILLING", [
  "GET_APP_DATA",
  "GET_BILLING_SUBSCRIPTIONS",
  "GET_INVOICES",
  "GET_RECURLY_ACCOUNT_DETAILS"
]);

/**
 * GET_APP_DATA
 */

export interface GetAppDataSchema extends SideEffectSchema {
  appId: string;
  accountCode: string;
  isNoBilling: boolean;
  RecurlyAccountCode: string;
  errorCode?: number;
  description?: string;
}

export const getAppDataAction = (appId: string) => {
  return {
    type: BillingConst.GET_APP_DATA,
    payload: appId
  };
};

export const getAppDataLoadingAction = () =>
  loadingAction(BillingConst.GET_APP_DATA_LOADING);

export const getAppDataErrorAction = (error: string) =>
  errorAction(BillingConst.GET_APP_DATA_ERROR, error);

export const getAppDataSuccessAction = (data: GetAppDataSchema) => {
  const payload: GetAppDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: BillingConst.GET_APP_DATA_SUCCESS,
    payload
  };
};

/**
 * GET_BILLING_SUBSCRIPTIONS
 */

export interface GetBillingSubscriptionsSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  data: { subscriptions: { account: ObjectSchema<string> } };
}

export const getBillingSubscriptionsAction = (
  accountCode: string,
  accountInfo: ObjectSchema<string>
) => {
  return {
    type: BillingConst.GET_BILLING_SUBSCRIPTIONS,
    payload: {
      accountCode,
      accountInfo
    }
  };
};

export const getBillingSubscriptionsLoadingAction = () =>
  loadingAction(BillingConst.GET_BILLING_SUBSCRIPTIONS_LOADING);

export const getBillingSubscriptionsErrorAction = (error: string) =>
  errorAction(BillingConst.GET_BILLING_SUBSCRIPTIONS_ERROR, error);

export const getBillingSubscriptionsSuccessAction = (
  data: GetBillingSubscriptionsSchema
) => {
  const payload: GetBillingSubscriptionsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: BillingConst.GET_BILLING_SUBSCRIPTIONS_SUCCESS,
    payload
  };
};

/**
 * GET_INVOICES
 */

export interface GetInvoicesSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  data: { invoices: { invoice: any } };
}

export const getInvoicesAction = (accountCode: string) => {
  return {
    type: BillingConst.GET_INVOICES,
    payload: { accountCode }
  };
};

export const getInvoicesLoadingAction = () =>
  loadingAction(BillingConst.GET_INVOICES_LOADING);

export const getInvoicesErrorAction = (error: string) =>
  errorAction(BillingConst.GET_INVOICES_ERROR, error);

export const getInvoicesSuccessAction = (data: GetInvoicesSchema) => {
  const payload: GetInvoicesSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: BillingConst.GET_INVOICES_SUCCESS,
    payload
  };
};

/**
 * GET_RECURLY_ACCOUNT_DETAILS
 */

export interface GetRecurlyAccountDetailsSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  recurlyaccountData: { recurlyAccount: string };
  recurlyData: { data: any };
}

export const getRecurlyAccountDetailsAction = (accountCode: string) => {
  return {
    type: BillingConst.GET_RECURLY_ACCOUNT_DETAILS,
    payload: { accountCode }
  };
};

export const getRecurlyAccountDetailsLoadingAction = () =>
  loadingAction(BillingConst.GET_RECURLY_ACCOUNT_DETAILS_LOADING);

export const getRecurlyAccountDetailsErrorAction = (error: string) =>
  errorAction(BillingConst.GET_RECURLY_ACCOUNT_DETAILS_ERROR, error);

export const getRecurlyAccountDetailsSuccessAction = (
  data: GetRecurlyAccountDetailsSchema
) => {
  const payload: GetRecurlyAccountDetailsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: BillingConst.GET_RECURLY_ACCOUNT_DETAILS_SUCCESS,
    payload
  };
};

import {
  SideEffectSchema,
  loadingAction,
  errorAction,
  successSideEffectState,
  ObjectSchema,
  generateConst,
  APIErrorResponseSchema
} from ".";
import { NavigationItemsSchema } from "../../containers/Navigation";

// Constants
export const AuthConst = generateConst("AUTH", [
  "INIT_AUTH_CONFIG",
  "INIT_AUTH_LOGIN",
  "INIT_AUTH_VALIDATE_TOKEN",
  "INIT_AUTH_SAML_LOGIN",
  "INIT_APP_BASIC_INFO",
  "INIT_APP_VERSION",
  "INIT_APP_VIEW_PERMISSION",
  "GET_YOUR_SITES",
  "GET_SHARED_SITES",
  "GET_APP_FEATURE",
  "GET_INTERCOM_USER_DATA",
  "GET_AUTH_APP_USERS",
  "SET_CURRENT_APP_INFO_SUCCESS_ACTION",
  "UPDATE_APP_BASIC_INFO",
  "GET_ALL_SITES",
  "INIT_AUTH_TOKEN",
  "INIT_SEARCH_JSON_SUCCESS"
]);

/**
 * INIT_APP_VIEW_PERMISSION
 */
export interface AppViewPermissionSchema extends SideEffectSchema {
  UserManagement_View: boolean;
  UserManagement_Edit: boolean;
  UserManagement_Admin: boolean;
  API_ViewConfiguration: boolean;
  API_AdminConfiguration: boolean;
  API_ViewAdditionalCredentials: boolean;
  API_AdminAdditionalCredentials: boolean;
  UserInsights_View: boolean;
  UserSegmentation_View: boolean;
  UserSegmentation_Export: boolean;
  DataExport_Export: boolean;
  API_EditConfiguration: boolean;
  API_ViewCredentials: boolean;
  API_EditCredentials: boolean;
  API_ViewThirdPartyCredentials: boolean;
  API_EditThirdPartyCredentials: boolean;
  API_SyncConfiguration: boolean;
  SecurityPolicy_View: boolean;
  SecurityPolicy_Edit: boolean;
  SecurityPolicy_Admin: boolean;
  TeamManagement_View: boolean;
  TeamManagement_Admin: boolean;
  TeamManagement_TransferOwnerShip: boolean;
  ThemeCustomization_Edit: boolean;
  ThemeCustomization_Admin: boolean;
  Billing_View: boolean;
  Billing_DownloadInvoice: boolean;
  Billing_Update: boolean;
  Support_Chat: boolean;
  upport_Tickets: boolean;
  ThirdPartyIntegration_View: boolean;
  ThirdPartyIntegration_Edit: boolean;
  ThirdPartyIntegration_Admin: boolean;
  Audit_View: boolean;
  Audit_Restore: boolean;
  TeamManagement_Edit: boolean;
}

/**
 * INIT_APP_VIEW_PERMISSION
 */
export const initAppViewPermissionAction = () => {
  return {
    type: AuthConst.INIT_APP_VIEW_PERMISSION,
    payload: {}
  };
};

export const initAppViewPermissionLoadingAction = () =>
  loadingAction(AuthConst.INIT_APP_VIEW_PERMISSION_LOADING);

export const initAppViewPermissionErrorAction = (error: string) =>
  errorAction(AuthConst.INIT_APP_VIEW_PERMISSION_ERROR, error);

export const initAppViewPermissionSuccessAction = (
  data: AppViewPermissionSchema
) => {
  const payload: AppViewPermissionSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.INIT_APP_VIEW_PERMISSION_SUCCESS,
    payload
  };
};

/**
 * INIT_AUTH_CONFIG
 */
export interface AuthConfigSchema extends SideEffectSchema {
  apikey: string;
  appName: string;
  maintenance: boolean;
  domain: string;
  currentYear: number;
  AppVersion: string;
  aiInstrumentationKey: string;
  hubDomain: string;
  superadminUid?: string;
  currentAppId?: string;
  jsPath: string;
  IOLoginUrl: string;
  IOLogoutUrl: string;
  xtoken: string;
  IODocsUrl: string;
  plan_detail: ObjectSchema<string>;
  captchaKey: string;
  redirectionUrl: ObjectSchema<string>;
  churnzeroAppKey: string;
}

export const initAuthConfigAction = () => {
  return {
    type: AuthConst.INIT_AUTH_CONFIG,
    payload: {}
  };
};
export const initAuthConfigLoadingAction = () =>
  loadingAction(AuthConst.INIT_AUTH_CONFIG_LOADING);

export const initAuthConfigErrorAction = (error: string) =>
  errorAction(AuthConst.INIT_AUTH_CONFIG_ERROR, error);

export const initAuthConfigSuccessAction = (data: AuthConfigSchema) => {
  const payload: AuthConfigSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.INIT_AUTH_CONFIG_SUCCESS,
    payload
  };
};

/**
 * INIT_AUTH_VALIDATE_TOKEN
 *
 */
export interface AuthValidateTokenSchema extends SideEffectSchema {
  access_token: string;
  expires_in: string;
  errorCode: string;
}

export const initAuthValidateTokenAction = () => {
  return {
    type: AuthConst.INIT_AUTH_VALIDATE_TOKEN,
    payload: {}
  };
};

export const initAuthValidateTokenLoadingAction = () =>
  loadingAction(AuthConst.INIT_AUTH_VALIDATE_TOKEN_LOADING);

/**
 * INIT_AUTH_LOGIN
 */
export interface AuthLoginSchema extends SideEffectSchema {
  isadminauthenticated?: boolean;
  xtoken: string;
  xsign: string;
  errorCode?: number;
  description?: string;
  message?: string;
  ErrorCode?: number;
  app_id?: string;
  api_Version?: string;
  authenticated?: boolean;
  app_name?: string;
  token?: string;
}

export interface AuthTokenSchema extends SideEffectSchema {
  token: string;
  isauthenticated: boolean;
}
export interface SearchJsonSchemaObject {
  link: string;
  text?: string;
  tags?: string[];
  href?: string;
}
export interface SearchJsonSchema extends SideEffectSchema {
  data: SearchJsonSchemaObject[];
}

export const initAuthLoginAction = (
  token: string,
  appName?: string,
  domain?: string,
  dataCenter?: string,
  plan?: string
) => {
  return {
    type: AuthConst.INIT_AUTH_LOGIN,
    payload: { token, appName, domain, dataCenter, plan }
  };
};

export const initAuthLoginLoadingAction = () =>
  loadingAction(AuthConst.INIT_AUTH_LOGIN_LOADING);

export const initAuthLoginSuccessAction = (data: AuthLoginSchema) => {
  const payload: AuthLoginSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.INIT_AUTH_LOGIN_SUCCESS,
    payload
  };
};

export const initAuthLoginErrorAction = (error: string) =>
  errorAction(AuthConst.INIT_AUTH_LOGIN_ERROR, error);

/**
 * INIT_AUTH_SAML_LOGIN
 */
export interface AuthSamlLoginSchema extends SideEffectSchema {
  errorCode?: string;
  domain?: string;
  apikey?: string;
  isSamlError?: string;
  samlProvider?: string;
}
export const initAuthSamlLoginAction = (samlconfig: string) => {
  return {
    type: AuthConst.INIT_AUTH_SAML_LOGIN,
    payload: { samlProvider: "saml_" + samlconfig }
  };
};

export const initAuthSamlLoginLoadingAction = () => {
  return loadingAction(AuthConst.INIT_AUTH_SAML_LOGIN_LOADING);
};

export const initAuthSamlLoginErrorAction = (error: string) => {
  return errorAction(AuthConst.INIT_AUTH_SAML_LOGIN_ERROR, error);
};
export const initAuthSamlLoginSuccessAction = (data: AuthSamlLoginSchema) => {
  const payload: AuthSamlLoginSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.INIT_AUTH_SAML_LOGIN_SUCCESS,
    payload
  };
};

/**
 * INIT_APP_BASIC_INFO
 */

export interface PasswordComplexityPayloadSchema extends SideEffectSchema {
  PasswordPolicy: {
    CommonPasswordPrevention: {
      IsEnabled: boolean;
    };
    Dictionary: {
      IsEnabled: boolean;
    };
    ProfileData: {
      IsEnabled: boolean;
    };
  };
  navigationItems: NavigationItemsSchema[];
}

export interface AppBasicInfoSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  /**
   * TODO Work on any type
   */
  DefaultRoles: any;
  PasswordPolicy: any;
  PasswordDelegation: ObjectSchema<any>;
  MaxPassWordHistory: number;
  IsAnyDomain: boolean;
  TokenExpire: number;
  RefreshTokenExpiryTime: number;
  IsMobile: boolean;
  LoginOnEmailVerification: boolean;
  DisabledEmailVerification: boolean;
  OptionalEmailVerification: boolean;
  IsRememberMe: boolean;
  RememberMeTokenExpiry: 0;
  IsKloutScoreEnabled: boolean;
  DeveloperEmail: any;
  IsWelcomeEmailEnabled: boolean;
  IsAccessToken: boolean;
  RegistrationRestrictionType: string;
  IsSecureCookies: boolean;
  IsWelcomeSmsEnabled: boolean;
  TtlCacheExpiry: number;
  MaximumFailedLoginAttempts: number;
  EnforceSocialEmailVerification: boolean;
  AllowCaseSensitiveUserName: boolean;
  LoginLockedType: string;
  ModelValidationOnServerEnabled: boolean;
  EncryptionAtRest: boolean;
  SandBoxEmailDomains: any;
  AppleSecretConfiguration?: any;
  MaximumFailedSecurityQuestionAttempts: 3;
  IsProviderScopesCustomOnly: boolean;
  AgeRestriction: any;
  IsResetPasswordSmsEnabled: boolean;
  IsResetPasswordEmailEnabled: boolean;
  AskRequiredFieldsOnTraditionalLogin: boolean;
  IsLogoutOnEmailVerification: boolean;
  IsUserNameLogin: boolean;
  IsInvisibleRecaptcha: boolean;
  AskPasswordOnSocialLogin: boolean;
  AskEmailIdForUnverifiedUserLogin: boolean;
  AskOptionalFieldsOnSocialSignup: boolean;
  IsV2Recaptcha: boolean;
  EnforcePasswordRuleValidateOnLogin: boolean;
  DuplicateEmailWithUniqueUsername: false;
  AccountMergingEnabled: false;
  IsBackendJobEnabled: false;
  RegistrationControl: ObjectSchema<any>;
  LoginLockedSuspendEffectivePeriod: number;
  IgnoreValidation: any;
  AutoUpdateTokenExpiry: false;
  SetAppTokenExpiryOnRefresh: boolean;
  ForcedAccountLinking: false;
  LoginOnPasswordReset: false;
  MoveLoginLogToCustomerDB: false;
  AllowEmailLimitToManagement: false;
  IsSmallMediumBusinessType: false;
  PushSocialProfileOnTraditional: false;
  IsDashboardCustomizationEnabled: boolean;
  CheckEmailRestrictionForExistingUsers: false;
  xtoken: string;
  xsign: string;
  navigationItems: NavigationItemsSchema[];
  Impersonation?: boolean;
  plan_detail: ObjectSchema<string>;
}
export const initAppBasicInfoAction = (noRefresh?: boolean) => {
  return {
    type: AuthConst.INIT_APP_BASIC_INFO,
    payload: { noRefresh: noRefresh }
  };
};

export const initAppBasicInfoLoadingAction = () => {
  return loadingAction(AuthConst.INIT_APP_BASIC_INFO_LOADING);
};

export const initAppBasicInfoSuccessAction = (data: AppBasicInfoSchema) => {
  const payload = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.INIT_APP_BASIC_INFO_SUCCESS,
    payload
  };
};

export const initAppBasicInfoErrorAction = (error: string) => {
  return errorAction(AuthConst.INIT_APP_BASIC_INFO_ERROR, error);
};

export const updateAppBasicInfo = (data, navItem, noReload?: boolean) => {
  return {
    type: AuthConst.UPDATE_APP_BASIC_INFO,
    payload: { item: navItem, data: data, noReload: noReload }
  };
};

export const updateAppBasicInfoLoadingAction = () =>
  loadingAction(AuthConst.UPDATE_APP_BASIC_INFO_LOADING);

export const updateAppBasicInfoErrorAction = (error: string) =>
  errorAction(AuthConst.UPDATE_APP_BASIC_INFO_ERROR, error);

export const updateAppBasicInfoSuccessAction = (data: AppBasicInfoSchema) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.UPDATE_APP_BASIC_INFO_SUCCESS,
    payload
  };
};

/**
 * INIT_APP_VERSION
 */
export interface AppVersionSchema extends SideEffectSchema {
  aiInstrumentationKey: string;
  apikey: string;
  appName: string;
  clientIP: string;
  currentIp: string[];
  currentYear: number;
  domain: string;
  forwardIp: string;
  hubDomain: string;
  maintenance: string;
  siteName: string;
  siteVersion: string;
  superAdmin: boolean;
  version: string;
}
export const initAppVersionAction = () => {
  return {
    type: AuthConst.INIT_APP_VERSION,
    payload: {}
  };
};

export const initAppVersionLoadingAction = () => {
  return loadingAction(AuthConst.INIT_APP_VERSION_LOADING);
};

export const initAppVersionSuccessAction = (data: AppVersionSchema) => {
  const payload = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.INIT_APP_VERSION_SUCCESS,
    payload
  };
};

export const initAppVersionErrorAction = (error: string) => {
  return errorAction(AuthConst.INIT_APP_VERSION_ERROR, error);
};
export interface SiteResponseSchema {
  AdditionalPermissions: string[];
  ApiKey: string;
  ApiVersion: string;
  AppId: number;
  AppName: string;
  CallbackUrl: string;
  CustomerName: string;
  DateCreated: string;
  DateModified: string;
  DevDomain: string;
  Domain: string;
  DomainLimit: number;
  InterfaceId: number;
  IsMobile: boolean;
  IsRaasEnabled: boolean;
  IsWelcomeEmailEnabled: boolean;
  Ishttps: boolean;
  Key: string;
  OwnerId: string;
  PrivacyPolicy: string;
  ProfilePhoto: string;
  RecurlyAccountCode: string;
  Role: string;
  Secret: string;
  Status: boolean;
  TermsOfService: string;
  UserLimit: number;
  WebTechnology: number;
}

/**
 * GET_YOUR_SITES
 */
export type GetYourSitesResponseSchema = SiteResponseSchema[];

export interface GetYourSitesSchema extends SideEffectSchema {
  response: GetYourSitesResponseSchema;
}

export const getYourSitesLoadingAction = () =>
  loadingAction(AuthConst.GET_YOUR_SITES_LOADING);

export const getYourSitesAction = () => {
  return {
    type: AuthConst.GET_YOUR_SITES,
    payload: {}
  };
};

export const getYourSitesSuccessAction = (data: GetYourSitesSchema) => {
  const payload: GetYourSitesSchema = { ...data, ...successSideEffectState };
  return { type: AuthConst.GET_YOUR_SITES_SUCCESS, payload };
};

export const getYourSitesErrorAction = (error: string) =>
  errorAction(AuthConst.GET_YOUR_SITES_ERROR, error);

/**
 * GET_SHARED_SITES
 */
export type GetSharedSitesResponseSchema = SiteResponseSchema[];

export interface GetSharedSitesSchema extends SideEffectSchema {
  response: GetSharedSitesResponseSchema;
}

export const getSharedSitesLoadingAction = () => {
  return loadingAction(AuthConst.GET_SHARED_SITES_LOADING);
};

export const getSharedSitesAction = () => {
  return {
    type: AuthConst.GET_SHARED_SITES,
    payload: {}
  };
};

export const getSharedSitesSuccessAction = (data: GetSharedSitesSchema) => {
  const payload: GetSharedSitesSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.GET_SHARED_SITES_SUCCESS,
    payload
  };
};

export const getSharedSitesErrorAction = (error: string) =>
  errorAction(AuthConst.GET_SHARED_SITES_ERROR, error);

/**
 * GET_APP_FEATURE
 */
export interface AppFeatureSchema extends SideEffectSchema {
  [key: string]: any;
}

export const getAppFeatureLoadingAction = () => {
  return loadingAction(AuthConst.GET_APP_FEATURE_LOADING);
};

export const getAppFeatureAction = () => {
  return {
    type: AuthConst.GET_APP_FEATURE,
    payload: {}
  };
};

export const getAppFeatureErrorAction = (error: string) =>
  errorAction(AuthConst.GET_APP_FEATURE_ERROR, error);

export const getAppFeatureSuccessAction = (data: AppFeatureSchema) => {
  const payload: AppFeatureSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.GET_APP_FEATURE_SUCCESS,
    payload
  };
};

/**
 * GET_INTERCOM_USER_DATA
 */
export interface GetIntercomUserDataResponseSchema {
  apiversion: string;
  clientIP: string;
  company: any;
  email: string;
  id: string;
  lastActivityDetail: string;
  name: string;
  phone: any;
  planname: string;
  role: string;
  title: string;
  tags: any;
  recurlyId: string;
  authVersion: string;
}

export interface GetIntercomUserDataSchema
  extends SideEffectSchema,
    ObjectSchema<any> {
  response: GetIntercomUserDataResponseSchema;
}

export const getIntercomUserDataAction = () => {
  return {
    type: AuthConst.GET_INTERCOM_USER_DATA,
    payload: {}
  };
};

export const getIntercomUserDataLoadingAction = () =>
  loadingAction(AuthConst.GET_INTERCOM_USER_DATA_LOADING);

export const getIntercomUserDataSuccessAction = (
  data: GetIntercomUserDataSchema
) => {
  const payload = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.GET_INTERCOM_USER_DATA_SUCCESS,
    payload
  };
};

export const getIntercomUserDataErrorAction = (error: string) =>
  errorAction(AuthConst.GET_INTERCOM_USER_DATA_ERROR, error);

/**
 * GET_AUTH_APP_USERS
 */
export interface GetAuthAppUsersSchema extends SideEffectSchema {
  count: number;
  message?: string;
  clientIP: string;
}

export const getAuthAppUsersAction = () => {
  return {
    type: AuthConst.GET_AUTH_APP_USERS,
    payload: {}
  };
};

export const getAuthAppUsersLoadingAction = () =>
  loadingAction(AuthConst.GET_AUTH_APP_USERS_LOADING);

export const getAppUsersSuccessAction = (data: GetAuthAppUsersSchema) => {
  const payload = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.GET_AUTH_APP_USERS_SUCCESS,
    payload
  };
};

export const getAuthAppUsersErrorAction = (error: string) =>
  errorAction(AuthConst.GET_AUTH_APP_USERS_ERROR, error);

export const setCurrentAppInfoSuccessAction = (data: SiteResponseSchema) => {
  const payload = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.SET_CURRENT_APP_INFO_SUCCESS_ACTION,
    payload
  };
};

/**
 * GET_ALL_SITES
 */
export interface GetAllSitesSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  // data key is not returned in the server response;
  data: { AppId: number; AppName: string; IsV2Enabled: boolean }[];
}

export const getAllSitesAction = () => {
  return { type: AuthConst.GET_ALL_SITES };
};

export const getAllSitesLoadingAction = () =>
  loadingAction(AuthConst.GET_ALL_SITES_LOADING);

export const getAllSitesErrorAction = (error: string) =>
  errorAction(AuthConst.GET_ALL_SITES_ERROR, error);

export const getAllSitesSuccessAction = (data: GetAllSitesSchema) => {
  const payload = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.GET_ALL_SITES_SUCCESS,
    payload
  };
};

/**
 * INIT Auth Token Login
 */
export const initAuthTokenLoadingAction = () =>
  loadingAction(AuthConst.INIT_AUTH_TOKEN_LOADING);
export const initAuthTokenErrorAction = (error: string) =>
  errorAction(AuthConst.INIT_AUTH_TOKEN_ERROR, error);

export const initAuthTokenSuccessAction = (data: AuthTokenSchema) => {
  const payload: AuthTokenSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.INIT_AUTH_TOKEN_SUCCESS,
    payload
  };
};

/**
 *
 */

export const initSearchJsonSuccessAction = (data: any) => {
  const payload: SearchJsonSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: AuthConst.INIT_SEARCH_JSON_SUCCESS,
    payload
  };
};

import { combineReducers } from "redux";
import { ActionSchema } from "../actions";
import {
  GetDataStorageLocationDataSchema,
  DataConfigurationConst,
  EncryptionAtRestSchema,
  GetHashingAlgorthimSchema,
  PlatformRegistrationFieldsSchema,
  SiteRegistrationFormSettingsSchema,
  DataObjectKeySchema,
  DataFieldsKeySchema,
  SavePrivacyPolicyVersionEnabledSchema,
  SavePrivacyPolicyVersionSchema,
  GetPrivacyPolicyVersionEnabledSchema,
  GetPrivacyPolicyVersionSchema,
  SendAgeVerificationSchema,
  AddConsentGrpRespSchema,
  GetConsentGrpRespSchema,
  AddConsentOptRespSchema,
  GetConsentOptRespSchema,
  AddConsentFormRespSchema,
  GetConsentFormRespSchema,
  UpdateConsentFormRespSchema,
  DeleteConsentFormRespSchema,
  UpdateConsentLiveFormRespSchema
} from "../actions/dataConfigurationActions";

const getDataStorageLocationReducer = (
  state = {},
  action: ActionSchema<GetDataStorageLocationDataSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.GET_DATA_STORAGE_LOCATION_LOADING:
    case DataConfigurationConst.GET_DATA_STORAGE_LOCATION_ERROR:
    case DataConfigurationConst.GET_DATA_STORAGE_LOCATION_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const encryptionAtRestReducer = (
  state = {},
  action: ActionSchema<EncryptionAtRestSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.RESET_DATA_CONFIGURATION_STATE:
      return {};
    case DataConfigurationConst.GET_ENCRYPTION_AT_REST_SUCCESS:
      return { ...state, ...action.payload };
    case DataConfigurationConst.GET_ENCRYPTION_AT_REST_LOADING:
    case DataConfigurationConst.GET_ENCRYPTION_AT_REST_ERROR:
    default:
      return state;
  }
};

const hashingAlgorithmReducer = (
  state = {},
  action: ActionSchema<GetHashingAlgorthimSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.GET_HASHING_ALGORITHM_LOADING:
    case DataConfigurationConst.GET_HASHING_ALGORITHM_ERROR:
    case DataConfigurationConst.GET_HASHING_ALGORITHM_SUCCESS:
      return { ...state, ...action.payload }; //payload is return from action
    default:
      return state;
  }
};

const platformRegistrationFieldsReducer = (
  state = {},
  action: ActionSchema<PlatformRegistrationFieldsSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.GET_PLATFORM_REGISTRATION_FIELDS_SUCCESS:
      return { ...state, ...action.payload };
    case DataConfigurationConst.GET_PLATFORM_REGISTRATION_FIELDS_LOADING:
    case DataConfigurationConst.GET_PLATFORM_REGISTRATION_FIELDS_ERROR:
    default:
      return state;
  }
};

const savePrivacyPolicyVersionEnabledReducer = (
  state = {},
  action: ActionSchema<SavePrivacyPolicyVersionEnabledSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.RESET_DATA_CONFIGURATION_STATE:
      return {};
    case DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION_ENABLED_LOADING:
    case DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION_ENABLED_ERROR:
    case DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION_ENABLED_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const siteRegistrationFormSettingsReducer = (
  state = {},
  action: ActionSchema<SiteRegistrationFormSettingsSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.GET_SITE_REGISTRATION_FORM_SETTINGS_SUCCESS:
      return { ...state, ...action.payload };
    case DataConfigurationConst.GET_SITE_REGISTRATION_FORM_SETTINGS_LOADING:
    case DataConfigurationConst.GET_SITE_REGISTRATION_FORM_SETTINGS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const savePrivacyPolicyVersionReducer = (
  state = {},
  action: ActionSchema<SavePrivacyPolicyVersionSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.RESET_DATA_CONFIGURATION_STATE:
      return {};
    case DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION_LOADING:
    case DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION_ERROR:
    case DataConfigurationConst.SAVE_PRIVACY_POLICY_VERSION_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const dataObjectKeyReducer = (
  state = {},
  action: ActionSchema<DataObjectKeySchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.GET_DATA_OBJECT_KEY_SUCCESS:
      return { ...state, ...action.payload };
    case DataConfigurationConst.GET_DATA_OBJECT_KEY_LOADING:
    case DataConfigurationConst.GET_DATA_OBJECT_KEY_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const dataFieldsKeyReducer = (
  state = {},
  action: ActionSchema<DataFieldsKeySchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.GET_DATA_FIELDS_KEY_SUCCESS:
      return { ...state, ...action.payload };
    case DataConfigurationConst.GET_DATA_FIELDS_KEY_LOADING:
    case DataConfigurationConst.GET_DATA_FIELDS_KEY_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getPrivacyPolicyVersionEnabledReducer = (
  state = {},
  action: ActionSchema<GetPrivacyPolicyVersionEnabledSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.RESET_DATA_CONFIGURATION_STATE:
      return {};
    case DataConfigurationConst.GET_PRIVACY_POLICY_VERSION_ENABLED_LOADING:
    case DataConfigurationConst.GET_PRIVACY_POLICY_VERSION_ENABLED_ERROR:
    case DataConfigurationConst.GET_PRIVACY_POLICY_VERSION_ENABLED_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getPrivacyPolicyVersionReducer = (
  state = {},
  action: ActionSchema<GetPrivacyPolicyVersionSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.GET_PRIVACY_POLICY_VERSION_LOADING:
    case DataConfigurationConst.GET_PRIVACY_POLICY_VERSION_ERROR:
    case DataConfigurationConst.GET_PRIVACY_POLICY_VERSION_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const SendAgeVerificationReducer = (
  state = {},
  action: ActionSchema<SendAgeVerificationSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.SUBMIT_AGE_VERIFICATION_LOADING:
    case DataConfigurationConst.SUBMIT_AGE_VERIFICATION_SUCCESS:
    case DataConfigurationConst.SUBMIT_AGE_VERIFICATION_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const addConsentGroupReducer = (
  state = {},
  action: ActionSchema<AddConsentGrpRespSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.ADD_CONSENT_GROUP_LOADING:
    case DataConfigurationConst.ADD_CONSENT_GROUP_SUCCESS:
    case DataConfigurationConst.ADD_CONSENT_GROUP_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getConsentGroupReducer = (
  state = {},
  action: ActionSchema<GetConsentGrpRespSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.GET_CONSENT_GROUP_LOADING:
    case DataConfigurationConst.GET_CONSENT_GROUP_SUCCESS:
    case DataConfigurationConst.GET_CONSENT_GROUP_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const addConsentOptionReducer = (
  state = {},
  action: ActionSchema<AddConsentOptRespSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.ADD_CONSENT_OPTION_LOADING:
    case DataConfigurationConst.ADD_CONSENT_OPTION_SUCCESS:
    case DataConfigurationConst.ADD_CONSENT_OPTION_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getConsentOptionReducer = (
  state = {},
  action: ActionSchema<GetConsentOptRespSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.GET_CONSENT_OPTION_LOADING:
    case DataConfigurationConst.GET_CONSENT_OPTION_SUCCESS:
    case DataConfigurationConst.GET_CONSENT_OPTION_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const addConsentFormReducer = (
  state = {},
  action: ActionSchema<AddConsentFormRespSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.ADD_CONSENT_FORM_LOADING:
    case DataConfigurationConst.ADD_CONSENT_FORM_SUCCESS:
    case DataConfigurationConst.ADD_CONSENT_FORM_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const updateConsentFormReducer = (
  state = {},
  action: ActionSchema<UpdateConsentFormRespSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.UPDATE_CONSENT_FORM_LOADING:
    case DataConfigurationConst.UPDATE_CONSENT_FORM_SUCCESS:
    case DataConfigurationConst.UPDATE_CONSENT_FORM_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const updateConsentLiveFormReducer = (
  state = {},
  action: ActionSchema<UpdateConsentLiveFormRespSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.UPDATE_CONSENT_LIVE_FORM_LOADING:
    case DataConfigurationConst.UPDATE_CONSENT_LIVE_FORM_SUCCESS:
    case DataConfigurationConst.UPDATE_CONSENT_LIVE_FORM_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const deleteConsentFormReducer = (
  state = {},
  action: ActionSchema<DeleteConsentFormRespSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.DELETE_CONSENT_FORM_LOADING:
    case DataConfigurationConst.DELETE_CONSENT_FORM_SUCCESS:
    case DataConfigurationConst.DELETE_CONSENT_FORM_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getConsentFormReducer = (
  state = {},
  action: ActionSchema<GetConsentFormRespSchema>
) => {
  switch (action.type) {
    case DataConfigurationConst.GET_CONSENT_FORM_LOADING:
    case DataConfigurationConst.GET_CONSENT_FORM_SUCCESS:
    case DataConfigurationConst.GET_CONSENT_FORM_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

// Combine Reducers
const dataConfigurationReducer = combineReducers({
  dataStorageLocation: getDataStorageLocationReducer,
  encryptionAtRest: encryptionAtRestReducer,
  hashingAlgorithm: hashingAlgorithmReducer,
  platformRegistrationFields: platformRegistrationFieldsReducer,
  siteRegistrationFormSettings: siteRegistrationFormSettingsReducer,
  dataObjectKey: dataObjectKeyReducer,
  dataFieldsKey: dataFieldsKeyReducer,
  savePrivacyPolicyVersionEnabled: savePrivacyPolicyVersionEnabledReducer,
  savePrivacyPolicyVersionResponse: savePrivacyPolicyVersionReducer,
  getPrivacyPolicyVersionEnabled: getPrivacyPolicyVersionEnabledReducer,
  privacyPolicyVersion: getPrivacyPolicyVersionReducer,
  submittedAgeVerification: SendAgeVerificationReducer,
  addConsentGroup: addConsentGroupReducer,
  getConsentGroup: getConsentGroupReducer,
  addConsentOption: addConsentOptionReducer,
  getConsentOption: getConsentOptionReducer,
  addConsentForm: addConsentFormReducer,
  updateConsentForm: updateConsentFormReducer,
  updateConsentLiveForm: updateConsentLiveFormReducer,
  deleteConsentForm: deleteConsentFormReducer,
  getConsentForm: getConsentFormReducer
});

export interface HashingAlgorithmSchema {
  hashingAlgorithm: GetHashingAlgorthimSchema;
}

// Combine schemas
export interface DataConfigurationSchema {
  dataStorageLocation: GetDataStorageLocationDataSchema;
  encryptionAtRest: EncryptionAtRestSchema;
  hashingAlgorithm: GetHashingAlgorthimSchema;
  platformRegistrationFields: PlatformRegistrationFieldsSchema;
  siteRegistrationFormSettings: SiteRegistrationFormSettingsSchema;
  dataObjectKey: DataObjectKeySchema;
  dataFieldsKey: DataFieldsKeySchema;
  savePrivacyPolicyVersionEnabled: SavePrivacyPolicyVersionEnabledSchema;
  savePrivacyPolicyVersionResponse: SavePrivacyPolicyVersionSchema;
  getPrivacyPolicyVersionEnabled: GetPrivacyPolicyVersionEnabledSchema;
  privacyPolicyVersion: GetPrivacyPolicyVersionSchema;
  submittedAgeVerification: SendAgeVerificationSchema;
  addConsentGroup: AddConsentGrpRespSchema;
  getConsentGroup: GetConsentGrpRespSchema;
  addConsentOption: AddConsentOptRespSchema;
  getConsentOption: GetConsentOptRespSchema;
  addConsentForm: AddConsentFormRespSchema;
  updateConsentForm: UpdateConsentFormRespSchema;
  updateConsentLiveForm: UpdateConsentLiveFormRespSchema;
  deleteConsentForm: DeleteConsentFormRespSchema;
  getConsentForm: GetConsentFormRespSchema;
}

export default dataConfigurationReducer;

import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
// Utils
import { connect } from "react-redux";
import { removeSymbols, toTitleCase, churnzeroEventLog } from "../../utils";
import "./Breadcrumb.scss";
import { RootSchema } from "../../redux/reducers";
import {
  AuthConfigSchema,
  GetIntercomUserDataSchema,
  SiteResponseSchema
} from "../../redux/actions/authActions";
import { GetAccountThemeCustomizationSchema } from "../../redux/actions/accountActions";
import { initLoginEventSuccessAction } from "../../redux/actions/appStateActions";
export interface BreadcrumbProps extends RouteComponentProps {
  appInfo: SiteResponseSchema;
  accountThemeCustomization: GetAccountThemeCustomizationSchema;
  LoginEventSuccessAction: (value) => void;
  isUserLoggedIn: boolean;
  authConfig: AuthConfigSchema;
  intercomUserData: GetIntercomUserDataSchema;
}
//window["EventPath"] = "";
let _path = "";
const Breadcrumb: React.FC<BreadcrumbProps> = ({
  location,
  appInfo,
  accountThemeCustomization,
  history,
  LoginEventSuccessAction,
  isUserLoggedIn,
  authConfig,
  intercomUserData
}) => {
  const [previousPath, setPreviousPath] = React.useState("");
  const pathToArray = _getFormattedPath(location.pathname).split("/");
  pathToArray.shift();
  const length = pathToArray.length;
  const breadcrumbContent = pathToArray.map((p, index) => {
    if (index === 0) _path = "";
    _path += index + 1 === pathToArray.length ? p : p + " > ";
    return appInfo.AppName ? (
      <li
        key={index.toString()}
        className={
          "breadcrumb-item" +
          (index === pathToArray.length - 1 ? " active" : "")
        }
      >
        <span id={"spn_" + removeSymbols(p) + "_Breadcrumb"}>{p}</span>
      </li>
    ) : null;
  });

  if (appInfo.AppName) {
    //After success, We log the events to churnzero.
    if (
      accountThemeCustomization.isSuccess &&
      authConfig.isSuccess &&
      intercomUserData.isSuccess &&
      previousPath !== location.pathname
    ) {
      setPreviousPath(location.pathname);
      if (isUserLoggedIn) {
        churnzeroEventLog("Login");
        churnzeroEventLog("Dashboard");
        LoginEventSuccessAction(false);
      } else {
        churnzeroEventLog(_path);
      }
    }

    return (
      <React.Fragment>
        <div className="breadcrumb-main global-breadcrumb">
          <div className="container" id="dv_breadcrumb">
            {accountThemeCustomization.isSuccess && (
              <ol className="breadcrumb" id="ol_breadcrumb">
                {breadcrumbContent}
              </ol>
            )}
            {!accountThemeCustomization.isSuccess && (
              <ol className="breadcrumb" id="ol_breadcrumb" />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  } else return null;
};

const SpecialCapsConversion = [
  {
    from: "Sms",
    to: "SMS"
  },
  {
    from: "Etl",
    to: "ETL"
  },
  {
    from: "Otp",
    to: "OTP"
  },
  {
    from: "Oauth",
    to: "OAuth"
  },
  {
    from: "Saml",
    to: "SAML"
  },
  {
    from: "Adfs",
    to: "ADFS"
  },
  {
    from: "Jwt",
    to: "JWT"
  },
  {
    from: "Idps",
    to: "IDPs"
  },
  {
    from: "Rba",
    to: "RBA"
  },
  {
    from: "Api",
    to: "API"
  },
  {
    from: "Sso",
    to: "SSO"
  },
  {
    from: "Js",
    to: "JS"
  },
  {
    from: "Web Hooks",
    to: "Webhooks"
  }
];

const _getFormattedPath = (newPath: string) => {
  let pathList = newPath.split("/");
  const pathToArray = pathList.map(path => {
    // eslint-disable-next-line
    let p = toTitleCase(path.replace(/\-/g, " "));
    for (let conversion of SpecialCapsConversion) {
      p = p.replace(conversion.from, conversion.to);
    }
    return p;
  });
  return pathToArray.join("/");
};
const mapStateToProps = (state: RootSchema) => {
  return {
    appInfo: state.auth.currentAppinfo,
    accountThemeCustomization: state.account.accountThemeCustomization,
    isUserLoggedIn: state.appState.appUI.isUserLoggedIn,
    authConfig: state.auth.authConfig,
    intercomUserData: state.auth.intercomUserData
  };
};
const mapDispatchtoProps = (dispatch: any) => {
  return {
    LoginEventSuccessAction: value =>
      dispatch(initLoginEventSuccessAction(value))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(withRouter<BreadcrumbProps>(Breadcrumb));

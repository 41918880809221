import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";

// HOC
import PrivateRoute from "./PrivateRoute";

// Containers
import Login from "../containers/Login";
const Dashboard = React.lazy(() => import("../containers/Dashboard"));
const Logout = React.lazy(() => import("../containers/Logout"));
const Integration = React.lazy(() => import("../containers/Integration"));
const Insights = React.lazy(() => import("../containers/Insights"));
const Support = React.lazy(() => import("../containers/Support"));
const SiteList = React.lazy(() => import("../containers/Home/SiteList"));
const Deployment = React.lazy(() => import("../containers/Deployment"));
const PlatformSecurity = React.lazy(() =>
  import("../containers/PlatformSecurity")
);
const PlatformConfiguration = React.lazy(() =>
  import("../containers/PlatformConfiguration")
);
const Maintenance = React.lazy(() => import("../containers/Maintenance"));
const Account = React.lazy(() => import("../containers/Account"));
const DataGovernance = React.lazy(() => import("../containers/DataGovernance"));
const ProfileManagement = React.lazy(() =>
  import("../containers/ProfileManagement")
);

// Redux
import { RootSchema } from "../redux/reducers";
import { bodyCenter } from "../config/defaults";

// Schema
export interface RoutesProps extends RouteComponentProps {
  IOLoginUrl: string;
}

class Routes extends React.Component<RoutesProps> {
  constructor(props) {
    super(props);
  }

  _renderIORedirect = () => {
    if (this.props.IOLoginUrl && location.pathname === "/dashboard") {
      return (
        <Redirect to="/platform-configuration/authentication-configuration/standard-login/data-schema" />
      );
    }
  };

  render() {
    const fallback = <div style={bodyCenter}>Please wait...</div>;
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

    return (
      <Switch>
        {this._renderIORedirect()}
        <PrivateRoute path="/platform-configuration">
          <React.Suspense fallback={fallback}>
            <PlatformConfiguration />
          </React.Suspense>
        </PrivateRoute>
        <PrivateRoute path="/platform-security">
          <React.Suspense fallback={fallback}>
            <PlatformSecurity />
          </React.Suspense>
        </PrivateRoute>
        <PrivateRoute path="/dashboard">
          <React.Suspense fallback={fallback}>
            <Dashboard />
          </React.Suspense>
        </PrivateRoute>
        <PrivateRoute path="/data-governance">
          <React.Suspense fallback={fallback}>
            <DataGovernance />
          </React.Suspense>
        </PrivateRoute>
        <PrivateRoute path="/integration">
          <React.Suspense fallback={fallback}>
            <Integration />
          </React.Suspense>
        </PrivateRoute>
        <PrivateRoute path="/insights">
          <React.Suspense fallback={fallback}>
            <Insights />
          </React.Suspense>
        </PrivateRoute>
        <PrivateRoute path="/deployment">
          <React.Suspense fallback={fallback}>
            <Deployment />
          </React.Suspense>
        </PrivateRoute>
        <PrivateRoute path="/profile-management">
          <React.Suspense fallback={fallback}>
            <ProfileManagement />
          </React.Suspense>
        </PrivateRoute>
        <PrivateRoute path="/site-list">
          <React.Suspense fallback={fallback}>
            <SiteList />
          </React.Suspense>
        </PrivateRoute>
        <PrivateRoute path="/support">
          <React.Suspense fallback={fallback}>
            <Support />
          </React.Suspense>
        </PrivateRoute>
        <PrivateRoute isMaintenanceRoute={true} exact path="/maintenance">
          <React.Suspense fallback={fallback}>
            <Maintenance />
          </React.Suspense>
        </PrivateRoute>
        <PrivateRoute path="/account">
          <React.Suspense fallback={fallback}>
            <Account />
          </React.Suspense>
        </PrivateRoute>
        <Route exact path="/login" component={Login} />

        <Route
          exact
          path="/logout"
          component={() => (
            <React.Suspense fallback={fallback}>
              <Logout />
            </React.Suspense>
          )}
        />

        {isLoggedIn ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
      </Switch>
    );
  }
}

const mapStateToProps = (state: RootSchema) => {
  return {
    IOLoginUrl: state.auth.authConfig.IOLoginUrl
  };
};

export default connect(mapStateToProps)(withRouter<RoutesProps>(Routes));

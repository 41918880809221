import {
  AuthConst,
  AuthConfigSchema,
  AuthLoginSchema,
  AppVersionSchema,
  GetYourSitesSchema,
  GetSharedSitesSchema,
  AuthSamlLoginSchema,
  AppBasicInfoSchema,
  AppViewPermissionSchema,
  AppFeatureSchema,
  GetIntercomUserDataSchema,
  GetAuthAppUsersSchema,
  GetYourSitesResponseSchema,
  SiteResponseSchema,
  AuthValidateTokenSchema,
  GetAllSitesSchema,
  AuthTokenSchema,
  SearchJsonSchemaObject,
  SearchJsonSchema
} from "../actions/authActions";
import { combineReducers } from "redux";
import { ActionSchema } from "../actions";

// Reducers
const appPermissionReducer = (
  state = {},
  action: ActionSchema<AppViewPermissionSchema>
) => {
  switch (action.type) {
    case AuthConst.INIT_APP_VIEW_PERMISSION_LOADING:
    case AuthConst.INIT_APP_VIEW_PERMISSION_ERROR:
    case AuthConst.INIT_APP_VIEW_PERMISSION_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
const authConfigReducer = (
  state = {},
  action: ActionSchema<AuthConfigSchema>
) => {
  switch (action.type) {
    case AuthConst.INIT_AUTH_CONFIG_LOADING:
    case AuthConst.INIT_AUTH_CONFIG_ERROR:
    case AuthConst.INIT_AUTH_CONFIG_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const authTokenReducer = (
  state = {},
  action: ActionSchema<AuthTokenSchema>
) => {
  switch (action.type) {
    case AuthConst.INIT_AUTH_TOKEN_ERROR:
    case AuthConst.INIT_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const authLoginReducer = (
  state = {},
  action: ActionSchema<AuthLoginSchema>
) => {
  switch (action.type) {
    case AuthConst.INIT_AUTH_LOGIN_LOADING:
    case AuthConst.INIT_AUTH_LOGIN_ERROR:
    case AuthConst.INIT_AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const yourSitesReducer = (
  state = {},
  action: ActionSchema<GetYourSitesSchema>
) => {
  switch (action.type) {
    case AuthConst.GET_YOUR_SITES_LOADING:
    case AuthConst.GET_YOUR_SITES_ERROR:
    case AuthConst.GET_YOUR_SITES_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const authSamlLoginReducer = (
  state = {},
  action: ActionSchema<AuthSamlLoginSchema>
) => {
  switch (action.type) {
    case AuthConst.INIT_AUTH_SAML_LOGIN_LOADING:
    case AuthConst.INIT_AUTH_SAML_LOGIN_ERROR:
    case AuthConst.INIT_AUTH_SAML_LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const sharedSitesReducer = (
  state = {},
  action: ActionSchema<GetSharedSitesSchema>
) => {
  switch (action.type) {
    case AuthConst.GET_SHARED_SITES_LOADING:
    case AuthConst.GET_SHARED_SITES_ERROR:
    case AuthConst.GET_SHARED_SITES_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const appBasicInfoReducer = (
  state = {},
  action: ActionSchema<AppBasicInfoSchema>
) => {
  switch (action.type) {
    case AuthConst.INIT_APP_BASIC_INFO_LOADING:
    case AuthConst.INIT_APP_BASIC_INFO_SUCCESS:
    case AuthConst.INIT_APP_BASIC_INFO_ERROR:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
const updateAppBasicInfoReducer = (
  state = {},
  action: ActionSchema<AppBasicInfoSchema>
) => {
  switch (action.type) {
    case AuthConst.UPDATE_APP_BASIC_INFO_LOADING:
    case AuthConst.UPDATE_APP_BASIC_INFO_ERROR:
    case AuthConst.UPDATE_APP_BASIC_INFO_SUCCESS:
      delete state["errorCode"];
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const appFeatureReducer = (
  state = {},
  action: ActionSchema<AppFeatureSchema>
) => {
  switch (action.type) {
    case AuthConst.GET_APP_FEATURE_ERROR:
    case AuthConst.GET_APP_FEATURE_LOADING:
    case AuthConst.GET_APP_FEATURE_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const appVersionReducer = (
  state = {},
  action: ActionSchema<AppVersionSchema>
) => {
  switch (action.type) {
    case AuthConst.INIT_APP_VERSION_LOADING:
    case AuthConst.INIT_APP_VERSION_ERROR:
    case AuthConst.INIT_APP_VERSION_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const intercomUserDataReducer = (
  state = {},
  action: ActionSchema<GetIntercomUserDataSchema>
) => {
  switch (action.type) {
    case AuthConst.GET_INTERCOM_USER_DATA_LOADING:
    case AuthConst.GET_INTERCOM_USER_DATA_ERROR:
    case AuthConst.GET_INTERCOM_USER_DATA_SUCCESS:
      return { ...state, ...action.payload }; //payload is return from action
    default:
      return state;
  }
};

const getAuthAppUsersReducer = (
  state = {},
  action: ActionSchema<GetAuthAppUsersSchema>
) => {
  switch (action.type) {
    case AuthConst.GET_AUTH_APP_USERS_LOADING:
    case AuthConst.GET_AUTH_APP_USERS_ERROR:
    case AuthConst.GET_AUTH_APP_USERS_SUCCESS:
      return { ...state, ...action.payload }; //payload is return from action
    default:
      return state;
  }
};

const setCurrentAppInfoReducer = (
  state = {},
  action: ActionSchema<SiteResponseSchema>
) => {
  if (action.type === AuthConst.SET_CURRENT_APP_INFO_SUCCESS_ACTION) {
    return { ...state, ...action.payload };
  }
  return state;
};

const authValidateTokenReducer = (
  state = {},
  action: ActionSchema<AuthValidateTokenSchema>
) => {
  switch (action.type) {
    case AuthConst.INIT_AUTH_VALIDATE_TOKEN_LOADING:
    default:
      return state;
  }
};
const getAllSitesReducer = (
  state = {},
  action: ActionSchema<GetAllSitesSchema>
) => {
  switch (action.type) {
    case AuthConst.GET_ALL_SITES_LOADING:
    case AuthConst.GET_ALL_SITES_ERROR:
    case AuthConst.GET_ALL_SITES_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getSearchJsonReducer = (
  state = {},
  action: ActionSchema<SearchJsonSchema>
) => {
  switch (action.type) {
    case AuthConst.INIT_SEARCH_JSON_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export interface IntercomUserDataSchema {
  userData: GetIntercomUserDataSchema;
}

// Combine Reducers
const authReducer = combineReducers({
  authConfig: authConfigReducer,
  authLogin: authLoginReducer,
  yourSites: yourSitesReducer,
  sharedSites: sharedSitesReducer,
  authSamlLogin: authSamlLoginReducer,
  appBasicInfo: appBasicInfoReducer,
  appVersion: appVersionReducer,
  appPermission: appPermissionReducer,
  appFeatures: appFeatureReducer,
  intercomUserData: intercomUserDataReducer,
  authAppUsers: getAuthAppUsersReducer,
  currentAppinfo: setCurrentAppInfoReducer,
  updateAppBasicInfo: updateAppBasicInfoReducer,
  authValidateToken: authValidateTokenReducer,
  allSites: getAllSitesReducer,
  tokenData: authTokenReducer,
  searchResultJson: getSearchJsonReducer
});

// Combine Schemas
export interface AuthSchema {
  authConfig: AuthConfigSchema;
  authLogin: AuthLoginSchema;
  yourSites: GetYourSitesSchema;
  sharedSites: GetSharedSitesSchema;
  authSamlLogin: AuthSamlLoginSchema;
  appBasicInfo: AppBasicInfoSchema;
  updateAppBasicInfo: AppBasicInfoSchema;
  appVersion: AppVersionSchema;
  appPermission: AppViewPermissionSchema;
  appFeatures: AppFeatureSchema;
  intercomUserData: GetIntercomUserDataSchema;
  authAppUsers: GetAuthAppUsersSchema;
  currentAppinfo: SiteResponseSchema;
  authValidateToken: AuthValidateTokenSchema;
  allSites: GetAllSitesSchema;
  tokenData: AuthTokenSchema;
  searchResultJson: SearchJsonSchema;
}

export default authReducer;

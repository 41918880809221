import { take, put, call, select } from "redux-saga/effects";
import { httpGet, httpPost, httpPut } from "../../services";
import { showLoadingAction } from "../actions/appStateActions";
import {
  PasswordPolicyAction,
  getPasswordComplexityErrorAction,
  getPasswordComplexityLoadingAction,
  getPasswordComplexitySuccessAction,
  getPeriodicPasswordErrorAction,
  getPeriodicPasswordLoadingAction,
  getPeriodicPasswordSuccessAction,
  PeriodicSchema,
  appInfoAction,
  appInfoErrorAction,
  appInfoLoadingAction,
  appInfoSuccessAction,
  PasswordCompliexitySchema,
  AppBasicSettingsAction,
  appBasicSettingsSuccessAction,
  appBasicSettingsErrorAction,
  appBasicSettingsLoadingAction,
  RiskBasedAuthenticationAction,
  getRiskAuthSettingsLoadingAction,
  getRiskAuthSettingsSuccessAction,
  getRiskAuthSettingsErrorAction,
  RiskAuthSchema,
  updateRiskAuthSettingsLoadingAction,
  updateRiskAuthSettingsSuccessAction,
  updateRiskAuthSettingsErrorAction,
  updateMFAActions,
  PinAuthAction,
  getPinAuthLoadingAction,
  getPinAuthSuccessAction,
  getPinAuthErrorAction,
  PinAuthSchema,
  UpdatePinAuthSchema,
  updatePinAuthLoadingAction,
  updatePinAuthSuccessAction,
  updatePinAuthErrorAction,
  getSecurityQuestionsLoadingAction,
  SecurityQuestionsSchema,
  getSecurityQuestionsSuccessAction,
  getSecurityQuestionsErrorAction,
  SecurityQuestionsAction,
  getWebhookSecretsLoadingAction,
  WebhookSecretsSchema,
  getWebhookSecretsSuccessAction,
  getWebhookSecretsErrorAction,
  WebhookSecretsAction,
  PushNotificationsAction,
  getPushNotificationsAction,
  getPushNotificationsSuccessAction,
  getPushNotificationsErrorAction,
  getPushNotificationsLoadingAction,
  postPushNotificationsLoadingAction,
  postPushNotificationsErrorAction,
  postPushNotificationsSuccessAction,
  putPushNotificationsLoadingAction,
  putPushNotificationsSuccessAction,
  putPushNotificationsErrorAction,
  PushNotificationsResponse,
  getBreachedPasswordPolicyLoadingAction,
  getBreachedPasswordPolicySuccessAction,
  getBreachedPasswordPolicyErrorAction,
  postBreachedPasswordPolicyLoadingAction,
  postBreachedPasswordPolicySuccessAction,
  postBreachedPasswordPolicyErrorAction,
} from "../actions/securityConfigurationActions";
import { RootSchema } from "../reducers";
import {
  AppBasicInfoSchema,
  initAppBasicInfoAction,
  initAppBasicInfoSuccessAction
} from "../actions/authActions";
/**
 * APP Info
 */
function* getAppInfoHandler() {
  try {
    yield put(appInfoLoadingAction());
    const data = yield httpGet("/platform-configuration/app-info");
    yield put(appInfoSuccessAction(data));
  } catch (e) {
    yield put(appInfoErrorAction(e.message));
  }
}
function* getPasswordComplexityHandler() {
  try {
    yield put(getPasswordComplexityLoadingAction());
    const data: PasswordCompliexitySchema = yield httpGet(
      "/security-configuration/password-complexity"
    );
    yield put(getPasswordComplexitySuccessAction(data));
  } catch (e) {
    yield put(getPasswordComplexityErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* getBreachedPasswordPolicyHandler() {
  try {
    yield put(getBreachedPasswordPolicyLoadingAction());
    const data: any = yield httpGet(
      "/security-configuration/passwordpolicy/breachedpassword"
    );
    yield put(getBreachedPasswordPolicySuccessAction(data));
  } catch (e) {
    yield put(getBreachedPasswordPolicyErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

export function* watchGetBreachedPasswordPolicy() {
  while (true) {
    yield take(PasswordPolicyAction.GET_BREACHED_PASSWORD_POLICY);
    yield call(getBreachedPasswordPolicyHandler);
  }
}

function* postBreachedPasswordPolicyHandler(data) {
  try {
    yield put(postBreachedPasswordPolicyLoadingAction());
    const response: any = yield httpPut(
      "/security-configuration/passwordpolicy/breachedpassword",
      {},
      data
    );
    yield put(postBreachedPasswordPolicySuccessAction(response));
  } catch (e) {
    yield put(postBreachedPasswordPolicyErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
export function* watchPostBreachedPasswordPolicy() {
  while (true) {
    const {payload} = yield take(PasswordPolicyAction.POST_BREACHED_PASSWORD_POLICY);
    yield call(postBreachedPasswordPolicyHandler, payload);
  }
}

function* getPeriodicPasswordHandler() {
  try {
    yield put(getPeriodicPasswordLoadingAction());

    const data: PeriodicSchema = yield httpGet(
      "/security-configuration/periodic-password"
    );
    yield put(getPeriodicPasswordSuccessAction(data));
  } catch (e) {
    yield put(getPeriodicPasswordErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
function* updateBasicSettings(data, item) {
  try {
    yield put(appBasicSettingsLoadingAction());
    const response: AppBasicInfoSchema = yield httpPost(
      "/security-configuration/basic",
      {},
      data
    );
    yield put(appBasicSettingsSuccessAction(response));
    if (!response.errorCode) {
      response.navigationItems = item;
      yield put(initAppBasicInfoSuccessAction(response));
    }
  } catch (e) {
    yield put(appBasicSettingsErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
/**
 * Watchers
 */

export function* watchGetPasswordComplexityData() {
  while (true) {
    yield take(PasswordPolicyAction.GET_PASSWORD_COMPLEXITY);
    yield call(getPasswordComplexityHandler);
  }
}
export function* watchPriodicPasswordData() {
  while (true) {
    yield take(PasswordPolicyAction.GET_PERIODIC_PASSWORD);
    yield call(getPeriodicPasswordHandler);
  }
}

export function* watchGetAppInfo() {
  while (true) {
    yield take(PasswordPolicyAction.GET_APP_INFO);
    yield call(getAppInfoHandler);
  }
}
export function* watchGetAppBasicInfo() {
  while (true) {
    const { payload } = yield take(
      AppBasicSettingsAction.UPDATE_APP_BASIC_INFO
    );
    yield call(updateBasicSettings, payload.data, payload.navItem);
  }
}
function* riskAuthSettingsAction() {
  try {
    let flag = false;
    yield put(getRiskAuthSettingsLoadingAction());
    const response: RiskAuthSchema = yield httpGet(
      "/security-configuration//risk-based-auth"
    );
    if (response.errorCode) {
      throw Error(response.description);
    }
    if (response && response.RiskFactors) {
      for (let i in response.RiskFactors) {
        if (
          response.RiskFactors[i] &&
          response.RiskFactors[i].IsEnabled &&
          response.RiskFactors[i].Actions &&
          response.RiskFactors[i].Actions.includes("SecondFactorAuthentication")
        ) {
          flag = true;
        }
      }
    }
    yield put(getRiskAuthSettingsSuccessAction(response));
    if (flag) {
      yield put(updateMFAActions({ isEnabled: true }));
    } else {
      yield put(updateMFAActions({ isEnabled: false }));
    }
  } catch (e) {
    yield put(getRiskAuthSettingsErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
function* updateRiskAuthSettingsAction(data) {
  try {
    yield put(updateRiskAuthSettingsLoadingAction());
    const response: RiskAuthSchema = yield httpPut(
      "/security-configuration//risk-based-auth",
      {},
      data
    );
    if (response.errorCode) {
      throw Error(response.description);
    }
    yield put(updateRiskAuthSettingsSuccessAction(response));
    yield put(getRiskAuthSettingsSuccessAction(response));
  } catch (e) {
    yield put(updateRiskAuthSettingsErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

export function* watchGetRiskAuthSettings() {
  while (true) {
    yield take(RiskBasedAuthenticationAction.GET_RISK_AUTH_SETTINGS);
    yield call(riskAuthSettingsAction);
  }
}

export function* watchUpdateRiskAuthSettings() {
  while (true) {
    const { payload } = yield take(
      RiskBasedAuthenticationAction.UPDATE_RISK_AUTH_SETTINGS
    );
    yield call(updateRiskAuthSettingsAction, payload);
  }
}
function* getPinAuthSettings() {
  try {
    yield put(getPinAuthLoadingAction());

    const data: PinAuthSchema = yield httpGet(
      "/security-configuration/pin-auth"
    );
    if (data.errorCode) {
      throw Error(data.description);
    }
    yield put(getPinAuthSuccessAction(data));
  } catch (e) {
    yield put(getPinAuthErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
export function* watchGetPinAuth() {
  while (true) {
    yield take(PinAuthAction.PIN_AUTH);
    yield call(getPinAuthSettings);
  }
}

function* updatePinAuthSettingsAction(data) {
  try {
    yield put(updatePinAuthLoadingAction());
    const response: UpdatePinAuthSchema = yield httpPut(
      "/security-configuration/pin-auth",
      {},
      data
    );
    if (response.errorCode) {
      throw Error(response.description);
    }
    yield put(updatePinAuthSuccessAction(response));
    yield put(getPinAuthSuccessAction(data));
  } catch (e) {
    yield put(updatePinAuthErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

export function* watchUpdatePinAuthSettings() {
  while (true) {
    const { payload } = yield take(PinAuthAction.UPDATE_PIN_AUTH);
    yield call(updatePinAuthSettingsAction, payload);
  }
}

function* getSecurityQuestionsHandler() {
  try {
    yield put(getSecurityQuestionsLoadingAction());

    const data: SecurityQuestionsSchema = yield httpGet(
      "/security-configuration/security-questions/render-count",
      {}
    );

    yield put(getSecurityQuestionsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getSecurityQuestionsErrorAction(e.message));
  }
}

export function* watchGetSecurityQuestions() {
  while (true) {
    yield take(SecurityQuestionsAction.GET_SECURITY_QUESTIONS);
    yield call(getSecurityQuestionsHandler);
  }
}

function* getWebhookSecretsHandler() {
  try {
    yield put(getWebhookSecretsLoadingAction());

    const data: WebhookSecretsSchema = yield httpGet(
      "/security-configuration/api-credentials/secondary-secret",
      {
        fields: "Data(Description,Roles)"
      }
    );

    yield put(getWebhookSecretsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getWebhookSecretsErrorAction(e.message));
  }
}

export function* watchGetWebhookSecrets() {
  while (true) {
    yield take(WebhookSecretsAction.GET_WEBHOOK_SECRETS_OPTIONS);
    yield call(getWebhookSecretsHandler);
  }
}

function* getPushNotificationSettings() {
  try {
    yield put(getPushNotificationsLoadingAction());

    const data: PushNotificationsResponse = yield httpGet(
      "/security-configuration/two-factor-auth/push-notification-settings"
    );
    if ((data.errorCode && data.errorCode !== 1093) || data.ErrorCode) {
      throw Error(data.description || data.Description);
    }
    yield put(getPushNotificationsSuccessAction(data));
  } catch (e) {
    yield put(getPushNotificationsErrorAction(e.message));
  }
}
export function* watchGetPushNotifications() {
  while (true) {
    yield take(PushNotificationsAction.GET_PUSH_NOTIFICATIONS);
    yield call(getPushNotificationSettings);
  }
}

function* postPushNotificationsSettings(data) {
  try {
    yield put(postPushNotificationsLoadingAction());
    const response: PushNotificationsResponse = yield httpPost(
      "/security-configuration/two-factor-auth/push-notification-settings",
      {},
      data
    );
    if (response.errorCode || response.ErrorCode) {
      throw Error(response.description || response.Description);
    }
    yield put(postPushNotificationsSuccessAction(response));
  } catch (e) {
    yield put(postPushNotificationsErrorAction(e.message));
  }
}

export function* watchpostPushNotificationsSettings() {
  while (true) {
    const { payload } = yield take(
      PushNotificationsAction.POST_PUSH_NOTIFICATIONS
    );
    yield call(postPushNotificationsSettings, payload);
  }
}

function* putPushNotificationsSettings(data) {
  try {
    yield put(putPushNotificationsLoadingAction());
    const response: PushNotificationsResponse = yield httpPut(
      "/security-configuration/two-factor-auth/push-notification-settings",
      {},
      data
    );
    if (response.errorCode || response.ErrorCode) {
      throw Error(response.description || response.Description);
    }
    yield put(putPushNotificationsSuccessAction(response));
  } catch (e) {
    yield put(putPushNotificationsErrorAction(e.message));
  }
}

export function* watchputPushNotificationsSettings() {
  while (true) {
    const { payload } = yield take(
      PushNotificationsAction.PUT_PUSH_NOTIFICATIONS
    );
    yield call(putPushNotificationsSettings, payload);
  }
}

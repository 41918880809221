import {
  ObjectSchema,
  SideEffectSchema,
  loadingAction,
  errorAction,
  successSideEffectState,
  generateConst,
  V2ErrorResponseSchema
} from ".";

// Constants
export const TeamConst = generateConst("TEAM", [
  "GET_TEAM_USERS",
  "GET_TEAM_OWNER",
  "DELETE_TEAM_USER",
  "DELETE_SELF",
  "RESEND_TEAM_USER_PASSWORD",
  "GET_TEAM_USER_SESSION",
  "LOG_OUT_TEAM_USER",
  "GET_CURRENT_USER",
  "GET_APP_ROLES",
  "UPDATE_TEAM_USER_ROLE",
  "GET_AUDIT_LOGS",
  "GET_ROLE_LIST",
  "GET_OWNER_INFO",
  "CHECK_USER",
  "SEND_RESET_PASSWORD",
  "ADD_EXISTING_USER",
  "ADD_USER_ROLE",
  "GET_APP_USERS",
  "TRANSFER_ACCOUNT",
  "UPDATE_CURRENT_USER",
  "WAS_USER_ADDED_IN_ACTIVE_SESSION",
  "GET_MAP_FIELDS",
  "GET_SAML_CONFIG",
  "POST_SAML_CONFIG",
  "PUT_SAML_CONFIG",
  "DELETE_SAML_CONFIG",
  "RESET_TEAM_STATE_AFTER_ADDING_USER",
  "CREATE_USER_AND_TRANSFER_OWNERSHIP"
]);

/**
 * MANAGE TEAM MEMBER
 */

export interface RoleContextSchema {
  AdditionalPermissions: string[];
  Expiration: string;
  Roles: string[];
}

export interface TeamUserSchema {
  FullName: string;
  Email: string;
  EmailVerified: boolean;
  ImageUrl: string;
  LastLoginDate: string;
  RoleContext: RoleContextSchema;
  Uid: string;
  location: string;
  SecondFactorAuthentication?: {
    GoogleAuthenticator: {
      SecondFactor: string;
      IsVerified: boolean;
      IsEnabled: boolean;
    };
    OTPAuthenticator: {
      SecondFactor: string;
      IsVerified: boolean;
      IsEnabled: boolean;
    };
    EmailOTPAuthenticator: {
      SecondFactor: string;
      IsVerified: boolean;
      IsEnabled: boolean;
    };
    BackUpCodes: string[];
  };
}

export interface GetTeamUsersDataSchema extends SideEffectSchema {
  response: TeamUserSchema[];
}

export const getTeamUsersAction = () => {
  return {
    type: TeamConst.GET_TEAM_USERS,
    payload: {}
  };
};

export const getTeamUsersLoadingAction = () =>
  loadingAction(TeamConst.GET_TEAM_USERS_LOADING);

export const getTeamUsersSuccessAction = (data: GetTeamUsersDataSchema) => {
  const payload: GetTeamUsersDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.GET_TEAM_USERS_SUCCESS,
    payload
  };
};

export const getTeamUsersErrorAction = (error: string) =>
  errorAction(TeamConst.GET_TEAM_USERS_ERROR, error);

export interface TeamOwnerSchema {
  Name: string;
  EmailID: string;
  LastLoginDate: string;
  ProfilePhoto: string;
  Role: string[];
  Uid: string;
}

export interface GetTeamOwnerDataSchema
  extends SideEffectSchema,
    V2ErrorResponseSchema {
  response: TeamOwnerSchema;
  ErrorMessage?: string;
}

export const getTeamOwnerAction = () => {
  return {
    type: TeamConst.GET_TEAM_OWNER,
    payload: {}
  };
};

export const getTeamOwnerLoadingAction = () =>
  loadingAction(TeamConst.GET_TEAM_OWNER_LOADING);

export const getTeamOwnerSuccessAction = (data: GetTeamOwnerDataSchema) => {
  const payload: GetTeamOwnerDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.GET_TEAM_OWNER_SUCCESS,
    payload
  };
};

export const getTeamOwnerErrorAction = (error: string) =>
  errorAction(TeamConst.GET_TEAM_OWNER_ERROR, error);

interface DeleteTeamUserMessageSchema {
  message: string;
  removedSite: string;
  action: string;
  detail: { isDeleted: boolean };
}

export interface DeleteTeamUserSchema {
  userDeleted: string;
  message: DeleteTeamUserMessageSchema[];
  response: { isDeleted: boolean };
  ErrorCode: string;
  Message: string;
}

export interface DeleteTeamUserDataSchema extends SideEffectSchema {
  response: DeleteTeamUserSchema;
}

export const deleteTeamUserAction = (
  uid: string,
  email: string,
  appID: number,
  appName: string
) => {
  return {
    type: TeamConst.DELETE_TEAM_USER,
    payload: { uid, email, appID, appName }
  };
};

export const deleteTeamUserLoadingAction = () =>
  loadingAction(TeamConst.DELETE_TEAM_USER_LOADING);

export const deleteTeamUserSuccessAction = (data: DeleteTeamUserDataSchema) => {
  const payload: DeleteTeamUserDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.DELETE_TEAM_USER_SUCCESS,
    payload
  };
};

export const deleteTeamUserErrorAction = (error: string) =>
  errorAction(TeamConst.DELETE_TEAM_USER_ERROR, error);

interface DeleteSelfMessageSchema {
  message: string;
  removedSite: string;
  action: string;
  detail: { isDeleted: boolean };
}

export interface DeleteSelfSchema {
  message: DeleteSelfMessageSchema[];
  response: { isDeleted: boolean };
  ErrorCode: string;
  Message: string;
}

export interface DeleteSelfDataSchema extends SideEffectSchema {
  response: DeleteSelfSchema;
}

export const deleteSelfAction = (appID: number, appName: string) => {
  return {
    type: TeamConst.DELETE_SELF,
    payload: { appID, appName }
  };
};

export const deleteSelfLoadingAction = () =>
  loadingAction(TeamConst.DELETE_SELF_LOADING);

export const deleteSelfSuccessAction = (data: DeleteSelfDataSchema) => {
  const payload: DeleteSelfDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.DELETE_SELF_SUCCESS,
    payload
  };
};

export const deleteSelfErrorAction = (error: string) =>
  errorAction(TeamConst.DELETE_SELF_ERROR, error);

export interface ResendTeamUserPassword {
  email: string;
  clientIP: string;
  IsPosted: boolean;
}

export interface ResendTeamUserPasswordDataSchema extends SideEffectSchema {
  response: ResendTeamUserPassword;
}

export const resendTeamUserPasswordAction = (
  email: string,
  template: string,
  url: string
) => {
  return {
    type: TeamConst.RESEND_TEAM_USER_PASSWORD,
    payload: { email, template, url }
  };
};

export const resendTeamUserPasswordLoadingAction = () =>
  loadingAction(TeamConst.RESEND_TEAM_USER_PASSWORD_LOADING);

export const resendTeamUserPasswordSuccessAction = (
  data: ResendTeamUserPasswordDataSchema
) => {
  const payload: ResendTeamUserPasswordDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.RESEND_TEAM_USER_PASSWORD_SUCCESS,
    payload
  };
};

export const resendTeamUserPasswordErrorAction = (error: string) =>
  errorAction(TeamConst.RESEND_TEAM_USER_PASSWORD_ERROR, error);

export interface GetTeamUserSessionSchema {
  AccessToken: string;
  Browser: string;
  Device: string;
  Os: string;
  DeviceType: string;
  City: string;
  Country: string;
  Ip: string;
  LoginDate: string;
}

// Optional properties in the interface are for error responses (e.g. no active session for the user)
export interface GetTeamUserSessionResponseSchema {
  data: GetTeamUserSessionSchema;
  nextcursor: number;
  clientIP: string;
  description?: string;
  errorCode?: number;
  isProviderError?: boolean;
  message?: string;
  providerErrorResponse?: any;
}

export interface GetTeamUserSessionDataSchema extends SideEffectSchema {
  response: GetTeamUserSessionResponseSchema;
}

export const getTeamUserSessionAction = (uid: string) => {
  return {
    type: TeamConst.GET_TEAM_USER_SESSION,
    payload: { uid }
  };
};

export const getTeamUserSessionLoadingAction = () =>
  loadingAction(TeamConst.GET_TEAM_USER_SESSION_LOADING);

export const getTeamUserSessionSuccessAction = (
  data: GetTeamUserSessionDataSchema
) => {
  const payload: GetTeamUserSessionDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.GET_TEAM_USER_SESSION_SUCCESS,
    payload
  };
};

export const getTeamUserSessionErrorAction = (error: string) =>
  errorAction(TeamConst.GET_TEAM_USER_SESSION_ERROR, error);

export interface LogoutTeamUserSchema {
  isPosted: boolean;
  clientIP: string;
}

export interface LogoutTeamUserDataSchema extends SideEffectSchema {
  response: LogoutTeamUserSchema;
}

export const logOutTeamUserAction = (uid: string) => {
  return {
    type: TeamConst.LOG_OUT_TEAM_USER,
    payload: { uid }
  };
};

export const logOutTeamUserLoadingAction = () =>
  loadingAction(TeamConst.LOG_OUT_TEAM_USER_LOADING);

export const logOutTeamUserSuccessAction = (data: LogoutTeamUserDataSchema) => {
  const payload: LogoutTeamUserDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.LOG_OUT_TEAM_USER_SUCCESS,
    payload
  };
};

export const logOutTeamUserErrorAction = (error: string) =>
  errorAction(TeamConst.LOG_OUT_TEAM_USER_ERROR, error);

export interface GetCurrentUserSchema {
  OwnerId: string;
  additionalpermission?: any;
  appid: number;
  appname: string;
  clientIP: string;
  role: string[];
  uid: string;
  email: string;
  name: string;
}

export interface GetCurrentUserDataSchema extends SideEffectSchema {
  response: GetCurrentUserSchema;
}

export const getCurrentUserAction = () => {
  return {
    type: TeamConst.GET_CURRENT_USER,
    payload: {}
  };
};

export const getCurrentUserLoadingAction = () =>
  loadingAction(TeamConst.GET_CURRENT_USER_LOADING);

export const getCurrentUserSuccessAction = (data: GetCurrentUserDataSchema) => {
  const payload: GetCurrentUserDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.GET_CURRENT_USER_SUCCESS,
    payload
  };
};

export const getCurrentUserErrorAction = (error: string) =>
  errorAction(TeamConst.GET_CURRENT_USER_ERROR, error);

export interface AppRole {
  Name: string;
  Permissions: { [permission: string]: boolean };
}

export interface GetAppRolesSchema {
  Count: number;
  clientIP: string;
  data: AppRole[];
  additionalpermissions: { Permission: string }[];
  Description?: string;
  ErrorCode?: number;
  Message?: string;
  IsProviderError?: boolean;
  ProviderErrorResponse?: any;
}

export interface GetAppRolesDataSchema extends SideEffectSchema {
  response: GetAppRolesSchema;
}

export const getAppRolesAction = () => {
  return {
    type: TeamConst.GET_APP_ROLES,
    payload: {}
  };
};

export const getAppRolesLoadingAction = () =>
  loadingAction(TeamConst.GET_APP_ROLES_LOADING);

export const getAppRolesSuccessAction = (data: GetCurrentUserDataSchema) => {
  const payload: GetCurrentUserDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.GET_APP_ROLES_SUCCESS,
    payload
  };
};

export const getAppRolesErrorAction = (error: string) =>
  errorAction(TeamConst.GET_APP_ROLES_ERROR, error);

export interface UpdateTeamUserRoleResponseDataElementSchema {
  Context: string;
  Roles: string[];
  AdditionalPermissions: string[];
  Expiration: any;
}

export interface UpdateTeamUserRoleSchema {
  uid: string;
  Data: UpdateTeamUserRoleResponseDataElementSchema[];
  clientIP: string;
}

export interface UpdateTeamUserRoleDataSchema extends SideEffectSchema {
  response: UpdateTeamUserRoleSchema;
}

export interface RoleSchema {
  id: string;
}

export const updateTeamUserRoleAction = (
  uid: string,
  roles: RoleSchema[],
  additionalPermissions: string[],
  site: string,
  appId: number
) => {
  return {
    type: TeamConst.UPDATE_TEAM_USER_ROLE,
    payload: { uid, roles, additionalPermissions, site, appId }
  };
};

export const updateTeamUserRoleLoadingAction = () =>
  loadingAction(TeamConst.UPDATE_TEAM_USER_ROLE_LOADING);

export const updateTeamUserRoleSuccessAction = (
  data: UpdateTeamUserRoleDataSchema
) => {
  const payload: UpdateTeamUserRoleDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.UPDATE_TEAM_USER_ROLE_SUCCESS,
    payload
  };
};

export const updateTeamUserRoleErrorAction = (error: string) =>
  errorAction(TeamConst.UPDATE_TEAM_USER_ROLE_ERROR, error);

/**
 * GET_AUDIT_LOGS
 */

export interface GetAuditLogsSchema extends SideEffectSchema {
  TotalLogCount: number;
  Data: AuditLogSchema[];
}

export interface AuditLogSchema extends ObjectSchema<string | number> {
  AppId: number;
  Activity: string;
  UserName: string;
  UserEmail: string;
  RequestTimestamp: string;
}

export const getAuditLogsAction = (appId: number, cursor: number = 0) => {
  return {
    type: TeamConst.GET_AUDIT_LOGS,
    payload: { appId, cursor }
  };
};

export const getAuditLogsLoadingAction = () =>
  loadingAction(TeamConst.GET_AUDIT_LOGS_LOADING);

export const getAuditLogsErrorAction = (error: string) =>
  errorAction(TeamConst.GET_AUDIT_LOGS_ERROR, error);

export const getAuditLogsSuccessAction = (data: GetAuditLogsSchema) => {
  const payload: GetAuditLogsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.GET_AUDIT_LOGS_SUCCESS,
    payload
  };
};

/**
 * GET_ROLE_LIST
 */

export interface GetRoleListSchema extends ObjectSchema<any> {
  id?: string;
  permission?: ObjectSchema<boolean>;
}

export const getRoleListAction = () => {
  return {
    type: TeamConst.GET_ROLE_LIST,
    payload: []
  };
};

export const getRoleListLoadingAction = () =>
  loadingAction(TeamConst.GET_ROLE_LIST_LOADING);

export const getRoleListErrorAction = (error: string) =>
  errorAction(TeamConst.GET_ROLE_LIST_ERROR, error);

export const getRoleListSuccessAction = (data: GetRoleListSchema[]) => {
  const payload: GetRoleListSchema = [...data];
  return {
    type: TeamConst.GET_ROLE_LIST_SUCCESS,
    payload
  };
};

/**
 * GET_OWNER_INFO
 */

export interface GetOwnerInfoSchema extends SideEffectSchema {
  data: OwnerInfoDataSchema;
}
export interface OwnerInfoDataSchema {
  EmailID?: string;
}

export const getOwnerInfoAction = () => {
  return {
    type: TeamConst.GET_OWNER_INFO,
    payload: {}
  };
};

export const getOwnerInfoLoadingAction = () =>
  loadingAction(TeamConst.GET_OWNER_INFO_LOADING);

export const getOwnerInfoErrorAction = (error: string) =>
  errorAction(TeamConst.GET_OWNER_INFO_ERROR, error);

export const getOwnerInfoSuccessAction = (data: OwnerInfoDataSchema) => {
  const payload: GetOwnerInfoSchema = { data, ...successSideEffectState };
  return {
    type: TeamConst.GET_OWNER_INFO_SUCCESS,
    payload
  };
};

/**
 * CHECK_USER
 */

export interface GetCheckedUserSchema extends SideEffectSchema {
  data: any;
  isExist?: boolean;
  emailDomainValid?: boolean;
  clientIP?: string;
}

export const checkUserAction = (email: string) => {
  return {
    type: TeamConst.CHECK_USER,
    payload: { email }
  };
};

export const checkUserLoadingAction = () =>
  loadingAction(TeamConst.CHECK_USER_LOADING);

export const checkUserErrorAction = (error: string) =>
  errorAction(TeamConst.CHECK_USER_ERROR, error);

export const checkUserSuccessAction = (data: GetCheckedUserSchema) => {
  const payload: GetCheckedUserSchema = { data, ...successSideEffectState };
  return {
    type: TeamConst.CHECK_USER_SUCCESS,
    payload
  };
};

/**
 * SEND_RESET_PASSWORD
 */

export interface SendResetPasswordSchema extends SideEffectSchema {
  data?: ResetPasswordDataSchema;
}

export interface ResetPasswordDataSchema {
  email?: string;
  flag?: any;
  isPosted?: boolean;
  IsPosted?: boolean;
}

export const sendResetPasswordAction = (
  resetPasswordData: ResetPasswordDataSchema
) => {
  return {
    type: TeamConst.SEND_RESET_PASSWORD,
    payload: resetPasswordData
  };
};

export const sendResetPasswordLoadingAction = () =>
  loadingAction(TeamConst.SEND_RESET_PASSWORD_LOADING);

export const sendResetPasswordErrorAction = (error: string) =>
  errorAction(TeamConst.SEND_RESET_PASSWORD_ERROR, error);

export const sendResetPasswordSuccessAction = (
  resetPasswordData: ResetPasswordDataSchema
) => {
  const payload: SendResetPasswordSchema = {
    data: resetPasswordData,
    ...successSideEffectState
  };
  return {
    type: TeamConst.SEND_RESET_PASSWORD_SUCCESS,
    payload
  };
};

/**
 * ADD_EXISTING_USER
 */

export interface ExistingUserSchema
  extends SideEffectSchema,
    ObjectSchema<any> {
  data: any;
}

export const addExistingUserAction = (data: ExistingUserSchema) => {
  return {
    type: TeamConst.ADD_EXISTING_USER,
    payload: data
  };
};

export const addExistingUserLoadingAction = () =>
  loadingAction(TeamConst.ADD_EXISTING_USER_LOADING);

export const addExistingUserErrorAction = (error: string) =>
  errorAction(TeamConst.ADD_EXISTING_USER_ERROR, error);

export const addExistingUserSuccessAction = (data: ExistingUserSchema) => {
  const payload: ExistingUserSchema = { data, ...successSideEffectState };
  return {
    type: TeamConst.ADD_EXISTING_USER_SUCCESS,
    payload
  };
};

/**
 * ADD_USER_ROLE
 */

export interface UserRoleSchema extends SideEffectSchema, ObjectSchema<any> {
  data: any;
}

export const addUserRoleAction = (data: UserRoleSchema) => {
  return {
    type: TeamConst.ADD_USER_ROLE,
    payload: data
  };
};

export const addUserRoleLoadingAction = () =>
  loadingAction(TeamConst.ADD_USER_ROLE_LOADING);

export const addUserRoleErrorAction = (error: string) =>
  errorAction(TeamConst.ADD_USER_ROLE_ERROR, error);

export const addUserRoleSuccessAction = (data: UserRoleSchema) => {
  const payload: UserRoleSchema = { data, ...successSideEffectState };
  return {
    type: TeamConst.ADD_USER_ROLE_SUCCESS,
    payload
  };
};

/**
 * WAS_USER_ADDED_IN_ACTIVE_SESSION
 */

export interface WasUserAddedSchema extends SideEffectSchema {
  data: boolean;
}

export const wasUserAddedInActiveSessionAction = () => {
  return {
    type: TeamConst.WAS_USER_ADDED_IN_ACTIVE_SESSION,
    payload: {}
  };
};

export const wasUserAddedInActiveSessionLoadingAction = () =>
  loadingAction(TeamConst.WAS_USER_ADDED_IN_ACTIVE_SESSION_LOADING);

export const wasUserAddedInActiveSessionErrorAction = (error: string) =>
  errorAction(TeamConst.WAS_USER_ADDED_IN_ACTIVE_SESSION_ERROR, error);

export const wasUserAddedInActiveSessionSuccessAction = () => {
  const payload: WasUserAddedSchema = { data: true, ...successSideEffectState };
  return {
    type: TeamConst.WAS_USER_ADDED_IN_ACTIVE_SESSION_SUCCESS,
    payload
  };
};

/**
 * GET_MAP_FIELDS
 */

export interface GetMapFieldsSchema extends SideEffectSchema {
  Data: string[];
}

export const getMapFieldsAction = () => {
  return {
    type: TeamConst.GET_MAP_FIELDS,
    payload: {}
  };
};

export const getMapFieldsLoadingAction = () =>
  loadingAction(TeamConst.GET_MAP_FIELDS_LOADING);

export const getMapFieldsErrorAction = (error: string) =>
  errorAction(TeamConst.GET_MAP_FIELDS_ERROR, error);

export const getMapFieldsSuccessAction = (data: GetMapFieldsSchema) => {
  const payload: GetMapFieldsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.GET_MAP_FIELDS_SUCCESS,
    payload
  };
};

/**
 * GET_SAML_CONFIG
 */

export interface GetSamlConfigSchema extends SideEffectSchema {
  DataMap: any;
  DefaultLogoutUrl: string;
  FriendlyProviderName: string;
  IdentityProvider: {
    Binding: string;
    Location: string;
    LogOut: string;
    ResponseBindingParam: string;
  };
  IdpCertificate: {
    Certificate: string;
    Key: string;
  };
  IsIdpInitiated: boolean;
  ListInInterface: boolean;
  Provider: string;
  ExpiryDate: string;
  RelayStateParameter: string;
  SamlServiceProvider: string;
  ServiceProviderAcsUrl: string;
  SpCertificate: {
    Certificate: string;
    Key: string;
  };
  clientIP: string;
  description: string;
  errorCode: number;
  message: string;
  queriedAppName: string;
}

export const getSamlConfigAction = (appName: string) => {
  return {
    type: TeamConst.GET_SAML_CONFIG,
    payload: appName
  };
};

export const getSamlConfigLoadingAction = () =>
  loadingAction(TeamConst.GET_SAML_CONFIG_LOADING);

export const getSamlConfigErrorAction = (error: string) =>
  errorAction(TeamConst.GET_SAML_CONFIG_ERROR, error);

export const getSamlConfigSuccessAction = (data: GetSamlConfigSchema) => {
  const payload: GetSamlConfigSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.GET_SAML_CONFIG_SUCCESS,
    payload
  };
};

/**
 * POST_SAML_CONFIG
 */

export interface PostSamlConfigSchema extends SideEffectSchema {
  Data: any[];
}

export const postSamlConfigAction = (body: any, appName: string) => {
  return {
    type: TeamConst.POST_SAML_CONFIG,
    payload: { body, appName }
  };
};

export const postSamlConfigLoadingAction = () =>
  loadingAction(TeamConst.POST_SAML_CONFIG_LOADING);

export const postSamlConfigErrorAction = (error: string) =>
  errorAction(TeamConst.POST_SAML_CONFIG_ERROR, error);

export const postSamlConfigSuccessAction = (data: PostSamlConfigSchema) => {
  const payload: PostSamlConfigSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.POST_SAML_CONFIG_SUCCESS,
    payload
  };
};

/**
 * PUT_SAML_CONFIG
 */

export interface PutSamlConfigSchema extends SideEffectSchema {
  Data: any[];
}

export const putSamlConfigAction = (body: any, appName: string) => {
  return {
    type: TeamConst.PUT_SAML_CONFIG,
    payload: { body, appName }
  };
};

export const putSamlConfigLoadingAction = () =>
  loadingAction(TeamConst.PUT_SAML_CONFIG_LOADING);

export const putSamlConfigErrorAction = (error: string) =>
  errorAction(TeamConst.PUT_SAML_CONFIG_ERROR, error);

export const putSamlConfigSuccessAction = (data: PutSamlConfigSchema) => {
  const payload: PutSamlConfigSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.PUT_SAML_CONFIG_SUCCESS,
    payload
  };
};

/**
 * DELETE_SAML_CONFIG
 */

export interface DeleteSamlConfigSchema extends SideEffectSchema {
  isdeleted: boolean;
}

export const deleteSamlConfigAction = (provider: string, appName: string) => {
  return {
    type: TeamConst.DELETE_SAML_CONFIG,
    payload: { provider, appName }
  };
};

export const deleteSamlConfigLoadingAction = () =>
  loadingAction(TeamConst.DELETE_SAML_CONFIG_LOADING);

export const deleteSamlConfigErrorAction = (error: string) =>
  errorAction(TeamConst.DELETE_SAML_CONFIG_ERROR, error);

export const deleteSamlConfigSuccessAction = (data: DeleteSamlConfigSchema) => {
  const payload: DeleteSamlConfigSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.DELETE_SAML_CONFIG_SUCCESS,
    payload
  };
};

/**
 * GET_APP_USERS
 */

export interface GetAppUsersSchema extends SideEffectSchema {
  data: any;
}

export const getAppUsersAction = () => {
  return {
    type: TeamConst.GET_APP_USERS,
    payload: {}
  };
};

export const getAppUsersLoadingAction = () =>
  loadingAction(TeamConst.GET_APP_USERS_LOADING);

export const getAppUsersErrorAction = (error: string) =>
  errorAction(TeamConst.GET_APP_USERS_ERROR, error);

export const getAppUsersSuccessAction = (data: GetAppUsersSchema) => {
  const payload: GetAppUsersSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.GET_APP_USERS_SUCCESS,
    payload
  };
};

/**
 * TRANSFER_ACCOUNT
 */

export interface TransferAccountSchema extends SideEffectSchema {
  data: any;
}

export const transferAccountAction = transferData => {
  return {
    type: TeamConst.TRANSFER_ACCOUNT,
    payload: transferData
  };
};

export const transferAccountLoadingAction = () =>
  loadingAction(TeamConst.TRANSFER_ACCOUNT_LOADING);

export const transferAccountErrorAction = (error: string) =>
  errorAction(TeamConst.TRANSFER_ACCOUNT_ERROR, error);

export const transferAccountSuccessAction = (data: TransferAccountSchema) => {
  const payload: TransferAccountSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.TRANSFER_ACCOUNT_SUCCESS,
    payload
  };
};

/**
 * UPDATE_CURRENT_USER
 */

export interface UpdateCurrentUserSchema extends SideEffectSchema {
  role: string[];
  data?: any;
}

export const updateCurrentUserAction = () => {
  return {
    type: TeamConst.UPDATE_CURRENT_USER,
    payload: {}
  };
};

export const updateCurrentUserLoadingAction = () =>
  loadingAction(TeamConst.UPDATE_CURRENT_USER_LOADING);

export const updateCurrentUserErrorAction = (error: string) =>
  errorAction(TeamConst.UPDATE_CURRENT_USER_ERROR, error);

export const updateCurrentUserSuccessAction = (
  data: UpdateCurrentUserSchema
) => {
  const payload: any = {
    data,
    ...successSideEffectState
  };
  return {
    type: TeamConst.UPDATE_CURRENT_USER_SUCCESS,
    payload
  };
};

export const resetTeamStateAfterAddingUserAction = () => {
  return {
    type: TeamConst.RESET_TEAM_STATE_AFTER_ADDING_USER,
    payload: {}
  };
};

/**
 * CREATE_USER_AND_TRANSFER_OWNERSHIP
 */

interface CreatedUserDataSchema {
  ID?: string;
  Uid?: string;
  emailDomainValid?: boolean;
  ErrorCode?: number;
  Message?: string;
}

export interface CreatedUserSchema extends SideEffectSchema {
  data: CreatedUserDataSchema;
}

export interface CreateUserAndTransferOwnershipSchema
  extends SideEffectSchema {}

export const createUserAndTransferOwnershipAction = (
  email: string,
  userData: CreatedUserSchema,
  appId: number
) => {
  return {
    type: TeamConst.CREATE_USER_AND_TRANSFER_OWNERSHIP,
    payload: {
      email,
      userData,
      appId
    }
  };
};

export const createUserAndTransferOwnershipLoadingAction = () =>
  loadingAction(TeamConst.CREATE_USER_AND_TRANSFER_OWNERSHIP_LOADING);

export const createUserAndTransferOwnershipErrorAction = (error: string) =>
  errorAction(TeamConst.CREATE_USER_AND_TRANSFER_OWNERSHIP_ERROR, error);

export const createUserandTransferOwnershipSuccessAction = () => {
  const payload: any = {
    ...successSideEffectState
  };
  return {
    type: TeamConst.CREATE_USER_AND_TRANSFER_OWNERSHIP_SUCCESS,
    payload
  };
};

import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import rootReducer from "../reducers";

import rootSaga from "../sagas";

function configureStore(enableLogger = true) {
  const sagaMiddleware = createSagaMiddleware();

  const appliedMiddlewares =
    enableLogger === true
      ? applyMiddleware(sagaMiddleware, logger)
      : applyMiddleware(sagaMiddleware);

  const middleware = ["loginradius.com", "loginradius.io"].filter(i =>
    window.location.origin.endsWith(i)
  ).length
    ? appliedMiddlewares
    : composeWithDevTools(appliedMiddlewares);

  const store = createStore(rootReducer, middleware);

  sagaMiddleware.run(rootSaga);

  return store;
}

export default configureStore;

/* eslint-disable @typescript-eslint/camelcase */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import flexibility from "flexibility";
// import "elementsfrompoint-polyfill";
import Polyfill from "./utils/Polyfill";
Polyfill();
import elementClosest from "element-closest";
import React from "react";
import ReactDOM from "react-dom";
import Axios from "axios";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.scss";
import "./assets/scss/SemanticUI.scss";

import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import configureStore from "./redux/store";
import { setI18NLocaleAction } from "./redux/actions/appStateActions";

import App from "./containers/App";
import { configureLocalForage, raygunStore } from "./utils";
import { ContextProvider } from "./Contexts/contextProvider";

// Configure Redux Store
const store = configureStore(false);
store.dispatch(setI18NLocaleAction());

// Configure LocalForage
configureLocalForage();

ReactDOM.render(
  <Provider store={store}>
    <ContextProvider>
      <App />
    </ContextProvider>
  </Provider>,
  document.getElementById("root")
);

elementClosest(window);
flexibility(document.documentElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

import { take, call, put } from "redux-saga/effects";
import { httpGet, httpPost } from "../../services";

import {
  InsightsConst,
  GetCreateDateSchema,
  getCreateDateLoadingAction,
  getCreateDateSuccessAction,
  getCreateDateErrorAction,
  GetLogsOverviewSchema,
  getLogsOverviewLoadingAction,
  getLogsOverviewSuccessAction,
  getLogsOverviewErrorAction,
  GetUsersOverviewSchema,
  getUsersOverviewLoadingAction,
  getUsersOverviewSuccessAction,
  getUsersOverviewErrorAction,
  GetUserHostsSchema,
  getUserHostsLoadingAction,
  getUserHostsSuccessAction,
  getUserHostsErrorAction,
  GetDeleteUsersSchema,
  getDeleteUsersLoadingAction,
  getDeleteUsersSuccessAction,
  getDeleteUsersErrorAction,
  GetGrowthDataSchema,
  GetGrowthDataActionArgs,
  getGrowthDataLoadingAction,
  getGrowthDataSuccessAction,
  getGrowthDataErrorAction,
  GetUsersDataSchema,
  getUsersDataLoadingAction,
  getUsersDataSuccessAction,
  getUsersDataErrorAction,
  GetRegisteredUsersSchema,
  GetRegisteredUsersArgs,
  GetRegisteredUsersLoadingAction,
  GetRegisteredUsersSuccessAction,
  GetRegisteredUsersErrorAction,
  GetUserLogsArgs,
  GetUserLogsSchema,
  getUserLogsSuccessAction,
  getUserLogsErrorAction,
  getUserLogsLoadingAction,
  GetProviderDataActionArgs,
  getProviderDataLoadingAction,
  GetProviderDataSchema,
  getProviderDataSuccessAction,
  getProviderDataErrorAction,
  GetMonthlyHistoryArgs,
  GetMonthlyHistorySchema,
  getMonthlyHistoryLoadingAction,
  getMonthlyHistorySuccessAction,
  getMonthlyHistoryErrorAction,
  getLogsHostsLoadingAction,
  getLogsHostsSuccessAction,
  getLogsHostsErrorAction,
  GetLogsHostsSchema,
  GetTotalLoginsActionArgs,
  getTotalLoginsLoadingAction,
  getTotalLoginsSuccessAction,
  GetTotalLoginsSchema,
  getTotalLoginsErrorAction,
  GetReturnRateActionArgs,
  getReturnRateLoadingAction,
  getReturnRateSuccessAction,
  GetReturnRateSchema,
  getReturnRateErrorAction,
  GetHighestGrowthDataSchema,
  getHighestGrowthDataAction,
  getHighestGrowthDataLoadingAction,
  GetHighestGrowthDataArgs,
  getHighestGrowthDataSuccessAction,
  getHighestGrowthDataErrorAction,
  GetBestRegistrationStreaksArgs,
  getBestRegistrationStreaksLoadingAction,
  GetBestRegistrationStreaksSchema,
  getBestRegistrationStreaksSuccessAction,
  getBestRegistrationStreaksErrorAction,
  GetWorstRegistrationStreaksArgs,
  GetWorstRegistrationStreaksSchema,
  getWorstRegistrationStreaksLoadingAction,
  getWorstRegistrationStreaksSuccessAction,
  getWorstRegistrationStreaksErrorAction
} from "../actions/insightsActions";

/**
 * Workers
 */
function* getCreateDateHandler() {
  try {
    yield put(getCreateDateLoadingAction());

    const data: GetCreateDateSchema = yield httpGet("/insights/createdate");
    if (data.errorCode) {
      throw new Error(data.description || "");
    }
    if (!data.minimumDate) {
      data.minimumDate = new Date().toISOString();
    }
    yield put(getCreateDateSuccessAction(data.minimumDate));
  } catch (e) {
    yield put(getCreateDateErrorAction(e.message));
  }
}

function* getLogsOverviewHandler(timezone: string) {
  try {
    yield put(getLogsOverviewLoadingAction());

    const data: GetLogsOverviewSchema = yield httpGet(
      "/insights/logsoverview",
      { timezone }
    );
    yield put(getLogsOverviewSuccessAction({ ...data }));
  } catch (e) {
    yield put(getLogsOverviewErrorAction(e.message));
  }
}

function* getUserHostsHandler() {
  try {
    yield put(getUserHostsLoadingAction());

    const data: GetUserHostsSchema = yield httpGet("/insights/userhosts");
    if (data.errorCode) {
      throw new Error(data.description || "");
    }
    yield put(getUserHostsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getUserHostsErrorAction(e.message));
  }
}

function* getLogsHostsHandler() {
  try {
    yield put(getLogsHostsLoadingAction());

    const data: GetLogsHostsSchema = yield httpGet("/insights/logshosts");
    yield put(getLogsHostsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getLogsHostsErrorAction(e.message));
  }
}

function* getDeletedUsersHandler(fromdate: string, todate: string) {
  try {
    yield put(getDeleteUsersLoadingAction());

    const data: GetDeleteUsersSchema = yield httpGet("/insights/deleteusers", {
      fromdate,
      todate
    });
    yield put(getDeleteUsersSuccessAction({ ...data }));
  } catch (e) {
    yield put(getDeleteUsersErrorAction(e.message));
  }
}

function* getUsersOverviewHandler() {
  try {
    yield put(getUsersOverviewLoadingAction());

    const data: GetUsersOverviewSchema = yield httpGet(
      "/insights/usersoverview"
    );
    yield put(getUsersOverviewSuccessAction({ ...data }));
  } catch (e) {
    yield put(getUsersOverviewErrorAction(e.message));
  }
}

function* getGrowthDataHandler(args: GetGrowthDataActionArgs) {
  try {
    yield put(getGrowthDataLoadingAction());

    const { fromdate, todate, type, timezone, host } = args;
    const data: GetGrowthDataSchema = yield httpPost(
      "/insights/growthdata",
      { fromdate, todate, timezone, type },
      { host }
    );
    if (data.ErrorCode) {
      throw new Error(data.Message || "");
    }
    if (!data.growthData) {
      throw new Error("Unable to fetch the data");
    }
    yield put(getGrowthDataSuccessAction({ ...data }));
  } catch (e) {
    yield put(getGrowthDataErrorAction(e.message));
  }
}

function* getUsersDataHandler() {
  try {
    yield put(getUsersDataLoadingAction());

    const data: GetUsersDataSchema = yield httpPost("/insights/usersdata");
    yield put(getUsersDataSuccessAction({ ...data }));
  } catch (e) {
    yield put(getUsersDataErrorAction(e.message));
  }
}

function* getRegisteredUsersHandler(args: GetRegisteredUsersArgs) {
  try {
    yield put(GetRegisteredUsersLoadingAction());

    const { fromdate, todate, type, host } = args;
    const data: GetRegisteredUsersSchema = yield httpPost(
      "/insights/registeredusers",
      { fromdate, todate, type },
      { host }
    );
    if (data.ErrorCode) {
      throw new Error(data.Message || "");
    }
    yield put(GetRegisteredUsersSuccessAction(data));
  } catch (e) {
    yield put(GetRegisteredUsersErrorAction(e.message));
  }
}

function* getUserLogsHandler(args: GetUserLogsArgs) {
  try {
    yield put(getUserLogsLoadingAction());

    const { timezone } = args;
    const data: GetUserLogsSchema = yield httpGet(
      "/insights/userlogsoverview",
      { timezone }
    );

    if (data.logsOverview.err || data.userOverview.err)
      throw new Error("Unable to get logs.");

    yield put(getUserLogsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getUserLogsErrorAction(e.message));
  }
}

function* getProviderDataHandler(args: GetProviderDataActionArgs) {
  try {
    yield put(getProviderDataLoadingAction());

    const { fromdate, todate, timezone, type, host } = args;
    const data: GetProviderDataSchema = yield httpPost(
      "/insights/idproviderpreference",
      { fromdate, todate, timezone, type },
      { host }
    );
    if (data.ErrorCode) {
      throw new Error(data.Message || "");
    }
    yield put(getProviderDataSuccessAction({ ...data }));
  } catch (e) {
    yield put(getProviderDataErrorAction(e.message));
  }
}

function* getMonthlyHistoryHandler(args: GetMonthlyHistoryArgs) {
  try {
    yield put(getMonthlyHistoryLoadingAction());

    const { fromdate, todate, timezone, type, host } = args;
    const response: GetMonthlyHistorySchema = yield httpPost(
      "/insights/monthlyhistory",
      { fromdate, todate, timezone, type },
      { host }
    );

    if (response.status) {
      throw new Error("Unable to get monthly history");
    }

    yield put(getMonthlyHistorySuccessAction({ ...response }));
  } catch (e) {
    yield put(getMonthlyHistoryErrorAction(e.message));
  }
}

function* getTotalLoginsHandler(args: GetTotalLoginsActionArgs) {
  try {
    yield put(getTotalLoginsLoadingAction());

    const { fromdate, todate, timezone, host } = args;
    const data: GetTotalLoginsSchema = yield httpPost(
      "/insights/totallogins",
      { fromdate, todate, timezone },
      { host }
    );

    yield put(getTotalLoginsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getTotalLoginsErrorAction(e.message));
  }
}

function* getReturnRateHandler(args: GetReturnRateActionArgs) {
  try {
    yield put(getReturnRateLoadingAction());

    const { fromdate, todate, timezone, host } = args;
    const data: GetReturnRateSchema = yield httpPost(
      "/insights/returnrate",
      { fromdate, todate, timezone },
      { host }
    );

    yield put(getReturnRateSuccessAction({ ...data }));
  } catch (e) {
    yield put(getReturnRateErrorAction(e.message));
  }
}

function* getHighestGrowthDataHandler(args: GetHighestGrowthDataArgs) {
  try {
    yield put(getHighestGrowthDataLoadingAction());

    const { fromdate, todate, host } = args;
    const { interval, type } = args;
    const data: GetHighestGrowthDataSchema = yield httpPost(
      "/insights/highestgrowth",
      { fromdate, todate, interval, type },
      { host }
    );

    if (data.status || data.errorCode) {
      throw new Error(
        data.description || "Unable to get best registration streaks"
      );
    }

    yield put(getHighestGrowthDataSuccessAction({ ...data }));
  } catch (e) {
    yield put(getHighestGrowthDataErrorAction(e.message));
  }
}

function* getBestRegistrationStreaksHandler(
  args: GetBestRegistrationStreaksArgs
) {
  try {
    yield put(getBestRegistrationStreaksLoadingAction());

    const { fromdate, todate, host } = args;
    const { interval, type, lowerBound } = args;
    const data: GetBestRegistrationStreaksSchema = yield httpPost(
      "/insights/bestregistrationstreaks",
      { fromdate, todate, interval, type, lowerBound },
      { host }
    );

    if (data.status || data.errorCode) {
      throw new Error(
        data.description || "Unable to get best registration streaks"
      );
    }

    yield put(getBestRegistrationStreaksSuccessAction({ ...data }));
  } catch (e) {
    yield put(getBestRegistrationStreaksErrorAction(e.message));
  }
}

function* getWorstRegistrationStreaksHandler(
  args: GetWorstRegistrationStreaksArgs
) {
  try {
    yield put(getWorstRegistrationStreaksLoadingAction());

    const { fromdate, todate, host } = args;
    const { interval, type, upperBound } = args;
    const data: GetWorstRegistrationStreaksSchema = yield httpPost(
      "/insights/worstregistrationstreaks",
      { fromdate, todate, interval, type, upperBound },
      { host }
    );

    if (data.status || data.errorCode) {
      throw new Error(
        data.description || "Unable to get best registration streaks"
      );
    }

    yield put(getWorstRegistrationStreaksSuccessAction({ ...data }));
  } catch (e) {
    yield put(getWorstRegistrationStreaksErrorAction(e.message));
  }
}

/**
 * Watchers
 */
export function* watchGetCreateDate() {
  while (true) {
    yield take(InsightsConst.GET_CREATEDATE);
    yield call(getCreateDateHandler);
  }
}
export function* watchGetLogsOverView() {
  while (true) {
    const {
      payload: { timezone }
    } = yield take(InsightsConst.GET_LOGS_OVERVIEW);
    yield call(getLogsOverviewHandler, timezone);
  }
}
export function* watchGetUserHosts() {
  while (true) {
    yield take(InsightsConst.GET_USER_HOSTS);
    yield call(getUserHostsHandler);
  }
}
export function* watchGetLogsHosts() {
  while (true) {
    yield take(InsightsConst.GET_LOGS_HOSTS);
    yield call(getLogsHostsHandler);
  }
}
export function* watchGetDeletedUsers() {
  while (true) {
    const {
      payload: { fromdate, todate }
    } = yield take(InsightsConst.GET_DELETE_USERS);
    yield call(getDeletedUsersHandler, fromdate, todate);
  }
}
export function* watchGetUsersOverview() {
  while (true) {
    yield take(InsightsConst.GET_USERS_OVERVIEW);
    yield call(getUsersOverviewHandler);
  }
}
export function* watchGetGrowthData() {
  while (true) {
    const { payload } = yield take(InsightsConst.GET_GROWTH_DATA);
    yield call(getGrowthDataHandler, { ...payload });
  }
}
export function* watchGetUsersData() {
  while (true) {
    yield take(InsightsConst.GET_USERS_DATA);
    yield call(getUsersDataHandler);
  }
}
export function* watchGetRegisteredUsers() {
  while (true) {
    const { payload } = yield take(InsightsConst.GET_REGISTERED_USERS);
    yield call(getRegisteredUsersHandler, { ...payload });
  }
}
export function* watchGetUserLogs() {
  while (true) {
    const { payload } = yield take(InsightsConst.GET_USER_LOGS);
    yield call(getUserLogsHandler, { ...payload });
  }
}
export function* watchProviderData() {
  while (true) {
    const { payload } = yield take(InsightsConst.GET_PROVIDER_DATA);
    yield call(getProviderDataHandler, { ...payload });
  }
}
export function* watchGetMonthlyHistory() {
  while (true) {
    const { payload } = yield take(InsightsConst.GET_MONTHLY_HISTORY);
    yield call(getMonthlyHistoryHandler, { ...payload });
  }
}
export function* watchTotalLogins() {
  while (true) {
    const { payload } = yield take(InsightsConst.GET_TOTAL_LOGINS);
    yield call(getTotalLoginsHandler, { ...payload });
  }
}
export function* watchReturnRate() {
  while (true) {
    const { payload } = yield take(InsightsConst.GET_RETURN_RATE);
    yield call(getReturnRateHandler, { ...payload });
  }
}

export function* watchGetHighestGrowthData() {
  while (true) {
    const { payload } = yield take(InsightsConst.GET_HIGHEST_GROWTH_DATA);
    yield call(getHighestGrowthDataHandler, { ...payload });
  }
}

export function* watchGetBestRegistrationStreaks() {
  while (true) {
    const { payload } = yield take(InsightsConst.GET_BEST_REGISTRATION_STREAKS);
    yield call(getBestRegistrationStreaksHandler, { ...payload });
  }
}

export function* watchGetWorstRegistrationStreaks() {
  while (true) {
    const { payload } = yield take(
      InsightsConst.GET_WORST_REGISTRATION_STREAKS
    );
    yield call(getWorstRegistrationStreaksHandler, { ...payload });
  }
}

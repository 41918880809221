import React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Placeholder } from "semantic-ui-react";
// Assets
import profilePhoto from "../../assets/image/default_user_img.png";
import IOlogo from "../../assets/image/io-logo.png";
import logo from "../../assets/image/logo-new.svg";
// Components
import Anchor from "../../components/Anchor";
import Image from "../../components/Image";
import NavMultiDropdown from "../../components/Navbar/NavMultiDropdown";
import NavNoDroppdown from "../../components/Navbar/NavNoDropdown";
import NavSingleDropdown from "../../components/Navbar/NavSingleDropdown";
import NavToggleButton from "../../components/Navbar/NavToggleButton";
import NavUserDropdown from "../../components/Navbar/NavUserDropdown";
import { SidebarItemsSchema } from "../../components/Sidebar";
// I18N
import { LanguageSchema } from "../../i18n";
// Redux
import {
  GetAccountThemeCustomizationSchema,
  switchToOwnedSiteAction,
  switchToSharedSiteAction,
  GetRequestProfileDataSchema
} from "../../redux/actions/accountActions";
import {
  GetSharedSitesResponseSchema,
  GetYourSitesResponseSchema,
  initAppViewPermissionAction,
  SiteResponseSchema
} from "../../redux/actions/authActions";
import { RootSchema } from "../../redux/reducers";
import { appInfoAction } from "./../../redux/actions/securityConfigurationActions";
import { navigationMenuChange } from "../../redux/actions/appStateActions";
// Util
import { removeSymbols, toTitleCase } from "../../utils";
// SCSS
import "./Navigation.scss";

export interface NavSubSectionSchema {
  displayName: string;
  link: string;
  isActive?: boolean;
  sections?: SidebarItemsSchema[];
}
export interface NavSidebarSectionSchema {
  displayName: string;
  link: string;
}
export interface NavSectionSchema {
  name: string;
  link: string;
}

export interface NavigationItemsSchema {
  displayName: string;
  link: string;
  isActive?: boolean;
  isAdmin?: boolean;
  isUserNavItem?: boolean;
  sections: NavSectionSchema[];
  subsections: NavSubSectionSchema[][];
}

// State and Props Schema
export interface NavigationProps extends RouteComponentProps {
  brandName: string;
  appBasicInfo: any;
  accountThemeCustomization: GetAccountThemeCustomizationSchema;
  navigationItems: NavigationItemsSchema[];
  yourSites: GetYourSitesResponseSchema;
  sharedSites: GetSharedSitesResponseSchema;
  userName: string;
  userEmailID: string;
  i18n: LanguageSchema;
  appInfo: SiteResponseSchema;
  switchToOwnedSite?: (appId: number, appName: string, path: string) => void;
  switchToSharedSite?: (appId: number, appName: string, path: string) => void;
  getAppPermission?: () => void;
  appInfoAction?: () => void;
  chnageMenu: (value: string) => void;
  IOLoginUrl: string;
  isSsoOnly: boolean;
  accountGetProfile: GetRequestProfileDataSchema;
  updateAppState: (path) => void;
}

export interface NavigationState {
  navItems: NavigationItemsSchema[];
  currActiveIndices: number[];
  navInitialized: boolean;
  currPath: string;
  isShowing: boolean;
  activeParent: string;
  currentActive: string;
  currentFocused: string;
}

class Navigation extends React.Component<NavigationProps, NavigationState> {
  themeLogo: string;
  COUNT = 0;
  private myRef: React.RefObject<any>;
  constructor(props: NavigationProps) {
    super(props);
    this.myRef = React.createRef();
    this.themeLogo = localStorage.getItem("logoPath") || "";
    const navItems = [...props.navigationItems];

    this.state = {
      navItems,
      currActiveIndices: [-1, -1, -1],
      navInitialized: false,
      currPath: "",
      activeParent: "",
      isShowing: false,
      currentActive: "Dashboard",
      currentFocused: "Dashboard"
    };
  }
  componentWillUpdate(nextProps: NavigationProps) {
    const { navInitialized } = this.state;
    const currPathname = this.props.location.pathname;
    const newPathname = nextProps.location.pathname;
    this.themeLogo = localStorage.getItem("logoPath") || "";
    if (newPathname === "/") return;

    /* update navigation if it's not initialized
     * or the prev path is not same as new path
     */
    if (!navInitialized || currPathname !== newPathname) {
      // get the nacigation items list
      const navItems = [...this.state.navItems];

      // get the navigation indices from the path
      const path = newPathname.split("/");
      path.shift(); // [parent, section, subsection]

      // initialize nav indices
      let p = -1;

      let s = -1;

      let sb = -1;

      // get the parent index
      for (let i = 0; i < navItems.length && p === -1; i++) {
        const parentPath = "/" + path[0];
        if (navItems[i].link === parentPath) {
          p = Number(i);

          // get the section index
          const section = navItems[p].sections;
          if (section.length === 0) s = 0;
          else {
            const sectionPath = "/" + path[1];
            for (let j in section) {
              if (section[j].link === sectionPath) {
                s = Number(j);
              }
            }
          }

          // get the sub section index
          const subSection = navItems[p].subsections[s];
          const subSectionPath =
            "/" + (section.length === 0 ? path[1] : path[2]);
          for (let k in subSection) {
            if (subSection[k].link === subSectionPath) {
              sb = Number(k);
            }
          }
        }
      }

      if (!navInitialized) {
        this.setState({ navInitialized: true });
      }
      this.updateNavigation(p, s, sb);
      //updates intercom quick links to be relevant to current page
      if (typeof window.sh_quickMenuList === "function") {
        window.sh_quickMenuList();
      }
      //Added Current Title
      let pathList = newPathname.split("/");
      pathList.shift();
      let title = "";
      if (pathList.length) {
        const _ind = pathList.length === 1 ? 0 : pathList.length - 2;
        title = toTitleCase(pathList[_ind].replace("-", " ")) + " - ";
      }
      document.title = title + this.props.i18n.PAGE_TITLE_DASHBOARD;
    }
  }

  componentDidMount() {
    this.updateNavigation();
    this.props.getAppPermission && this.props.getAppPermission();
    this.props.appInfoAction && this.props.appInfoAction();
    document.addEventListener("click", this.handleClickOutside, true);
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }
  componentDidUpdate() {
    const { isSsoOnly, history } = this.props;
    if (isSsoOnly && location.pathname !== "/site-list")
      history.push("/site-list");
  }
  handleClickOutside = event => {
    const domNode = this.myRef.current;
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        isShowing: false,
        currentFocused: "",
        currentActive: ""
      });
    }
  };
  handleLinkClick = (name: string) => {
    this.COUNT += 1;

    this.setState({ currentActive: name });
  };
  handleLinkFocus = (name: string) => {
    this.COUNT += 1;
  };

  // update active state for navigation items
  updateNavigation(parentIndex = -1, sectionIndex = -1, subSectionIndex = -1) {
    const navItems = [...this.state.navItems];
    const cParentIndex = this.state.currActiveIndices[0];
    const cSectionIndex = this.state.currActiveIndices[1];
    const cSubSectionIndex = this.state.currActiveIndices[2];
    const currActiveIndices = [parentIndex, sectionIndex, subSectionIndex];

    // reset prev nav item
    if (cParentIndex !== -1) {
      navItems[cParentIndex].isActive = false;
      if (cSectionIndex !== -1 && cSubSectionIndex !== -1) {
        navItems[cParentIndex].subsections[cSectionIndex][
          cSubSectionIndex
        ].isActive = false;
      }
    }

    // update active nav item
    let currPath = "";
    let activeParent = "";
    if (parentIndex !== -1) {
      const parentItem = navItems[parentIndex];
      parentItem.isActive = true;
      activeParent = parentItem.displayName;
      // update breadcrumb parent and section names
      currPath += `/${parentItem.displayName}`;
      if (parentItem.sections[sectionIndex]) {
        currPath += `/${parentItem.sections[sectionIndex].name}`;
      }

      if (sectionIndex !== -1 && subSectionIndex !== -1) {
        parentItem.subsections[sectionIndex][subSectionIndex].isActive = true;

        // update breadcrumb subsection name
        currPath += `/${parentItem.subsections[sectionIndex][subSectionIndex].displayName}`;
      }
    }

    navItems.forEach(item => {
      let pathname = window.location.pathname.split("/").pop() || "";
      pathname = "/" + pathname;
      if (
        window.location.pathname.includes(item.link) &&
        // Exclude the account route because the account dropdown shouldn't be highlighted, even when you are in a page from the Account section
        item.link !== "/account"
      ) {
        item.isActive =
          item.link === "/integration" && pathname === "/integrations"
            ? false
            : true;
      } else {
        item.isActive = false;
      }
    });

    if (activeParent)
      this.setState({
        navItems,
        currActiveIndices,
        currPath,
        activeParent: activeParent
      });
    else this.setState({ navItems, currActiveIndices, currPath });
    this.props.updateAppState(location.pathname);
  }

  switchSite = (siteName: string) => {
    // Determine the ID of the site and whether it's owned:

    let owned = false,
      siteID = -1;
    const {
      yourSites,
      sharedSites,
      switchToSharedSite,
      switchToOwnedSite
    } = this.props;
    for (const site of yourSites) {
      if (site.AppName === siteName) {
        owned = true;
        siteID = site.AppId;
        break;
      }
    }
    if (!owned) {
      for (const site of sharedSites) {
        if (site.AppName === siteName) {
          siteID = site.AppId;
          break;
        }
      }
    }
    if (siteID !== -1 && switchToOwnedSite && switchToSharedSite) {
      if (owned) switchToOwnedSite(siteID, siteName, location.pathname);
      else switchToSharedSite(siteID, siteName, location.pathname);
    }
  };
  childStateSync = (value: boolean, id?: string, currentActive?: string) => {
    const navItems = [...this.state.navItems];
    const activeParent = { ...this.state };

    if (id) {
      for (let i = 0; i < navItems.length; i++) {
        if (
          "lnk_" + removeSymbols(navItems[i].displayName) ===
          this.state.activeParent
        ) {
          navItems[i].isActive = true;
        } else {
          if (navItems[i].displayName !== this.state.activeParent) {
            navItems[i].isActive = false;
          }
        }
      }
    }
    if (currentActive)
      this.setState({
        isShowing: value,
        navItems: navItems,
        currentActive: currentActive
      });
    else this.setState({ isShowing: value, navItems: navItems });
  };
  render() {
    const {
      IOLoginUrl,
      appInfo,
      accountThemeCustomization,
      isSsoOnly
    } = this.props;
    const { navItems } = this.state;
    const { i18n, yourSites, sharedSites, userEmailID } = this.props;
    const itemList = navItems.filter(item => item.isUserNavItem);
    const logoPath =
      this.themeLogo !== "" ? this.themeLogo : IOLoginUrl ? IOlogo : logo;
    const listS = [
      "Deployment",
      "Integration",
      "ProfileManagement",
      "Insights",
      "Support"
    ];
    let mListUpdate = "false";
    let sListUpdate = "false";
    if (!listS.includes(this.state.currentActive)) {
      mListUpdate = "true";
    }
    const listM = [
      "PlatformConfiguration",
      "PlatformSecurity",
      "DataGovernance"
    ];
    if (!listM.includes(this.state.currentActive)) {
      sListUpdate = "true";
    }
    return (
      <React.Fragment>
        <nav
          className="mb-1 navbar navbar-expand-xl navbar-dark global-login-menu-bar "
          style={
            !accountThemeCustomization.isSuccess && !appInfo.AppName
              ? {
                  backgroundColor: "$white"
                }
              : {}
          }
          role="navigation"
        >
          <Anchor
            className="skip-link"
            id="lnk_SkipLink"
            href="#main-content"
          />
          <div className="w-100">
            {accountThemeCustomization.isSuccess && appInfo.AppName && (
              <div className="logo-wrap">
                <Link
                  id="link_Logo"
                  className="navbar-brand"
                  to="/"
                  tabIndex={0}
                >
                  <Image
                    className="brand-logo"
                    src={logoPath}
                    alt="LoginRadius Logo"
                  />
                </Link>
                <NavToggleButton targetId="navbarContent" />
              </div>
            )}
            {(!accountThemeCustomization.isSuccess || !appInfo.AppName) && (
              <Placeholder className="logo-wrap logo-placeholder">
                <Placeholder.Image />
              </Placeholder>
            )}
            <div
              className={
                !accountThemeCustomization.isSuccess || !appInfo.AppName
                  ? "collapse navbar-collapse placeholder-nav-wrap"
                  : "collapse navbar-collapse"
              }
              id="navbarContent"
            >
              {accountThemeCustomization.isSuccess &&
                appInfo.AppName &&
                !isSsoOnly && (
                  <ul className="navbar-nav navbar-nav-main " ref={this.myRef}>
                    {navItems.map((navItem, index) => {
                      const {
                        displayName,
                        link,
                        isActive,
                        isAdmin,
                        isUserNavItem
                      } = navItem;

                      if (!isUserNavItem) {
                        const { sections, subsections } = navItem;

                        const navType = subsections.length
                          ? sections.length
                            ? "multi"
                            : "single"
                          : "nodropdown";

                        if (navType === "nodropdown") {
                          const linkClasses =
                            "nav-link" + (isActive ? " active" : "");
                          return (
                            <NavNoDroppdown
                              key={index}
                              name={displayName}
                              link={link}
                              classes={linkClasses}
                              index={index}
                              navigationHandler={index =>
                                this.updateNavigation(index)
                              }
                              isShowing={isActive ? true : false}
                              handleLinkClick={this.handleLinkClick}
                              currentActive={this.state.currentActive}
                              handleLinkFocus={this.handleLinkFocus}
                              currentFocused={this.state.currentFocused}
                            />
                          );
                        } else if (navType === "single") {
                          const linkClasses =
                            "nav-link dropdown-toggle" +
                            (isActive ? " active" : "") +
                            (isAdmin ? " admin" : "");
                          return (
                            <NavSingleDropdown
                              tabIndex={0}
                              key={index + sListUpdate.toString()}
                              name={displayName}
                              classes={linkClasses}
                              toggleId={"lnk_" + removeSymbols(displayName)}
                              parentIndex={index}
                              parentLink={link}
                              navigationHandler={(p, s, sb) =>
                                this.updateNavigation(p, s, sb)
                              }
                              isShowing={
                                displayName.replace(/\s/, "") ===
                                this.state.currentActive
                                  ? true
                                  : false
                              }
                              handleLinkClick={this.handleLinkClick}
                              currentActive={this.state.currentActive}
                              handleLinkFocus={this.handleLinkFocus}
                              currentFocused={this.state.currentFocused}
                            >
                              {subsections[0]}
                            </NavSingleDropdown>
                          );
                        } else {
                          const linkClasses =
                            "nav-link dropdown-toggle " +
                            (isActive ? "active" : "");

                          return (
                            <NavMultiDropdown
                              tabIndex={0}
                              key={index + mListUpdate.toString()}
                              name={displayName}
                              classes={linkClasses}
                              toggleId={"lnk_" + removeSymbols(displayName)}
                              sections={sections}
                              subsections={subsections}
                              parentLink={link}
                              parentIndex={index}
                              isShowing={
                                displayName.replace(/\s/, "") ===
                                this.state.currentActive
                                  ? true
                                  : false
                              }
                              navigationHandler={(p, s, sb) =>
                                this.updateNavigation(p, s, sb)
                              }
                              handleLinkClick={this.handleLinkClick}
                              currentActive={this.state.currentActive}
                              handleLinkFocus={this.handleLinkFocus}
                              currentFocused={this.state.currentFocused}
                            />
                          );
                        }
                      }
                    })}
                  </ul>
                )}
              {(!accountThemeCustomization.isSuccess || !appInfo.AppName) && (
                <Placeholder className="navbar-nav navbar-nav-main placeholder-item placeholder-nav">
                  <Placeholder.Image />
                </Placeholder>
              )}
              {accountThemeCustomization.isSuccess && appInfo.AppName && (
                <NavUserDropdown
                  isSsoOnly={isSsoOnly}
                  tabIndex={0}
                  name={this.props.appInfo.AppName}
                  email={userEmailID}
                  parentLink={(itemList.length > 0 && itemList[0].link) || ""}
                  thumbnail={profilePhoto}
                  sites={yourSites.map(siteObject =>
                    siteObject.AppName ? siteObject.AppName : ""
                  )}
                  sharedSites={sharedSites.map(sharedSiteObject =>
                    sharedSiteObject.AppName ? sharedSiteObject.AppName : ""
                  )}
                  subsections={
                    itemList.length > 0 && itemList[0].subsections[0]
                  }
                  navigationHandler={() => this.updateNavigation(-1)}
                  switchSiteHandler={this.switchSite}
                  handleLinkClick={this.handleLinkClick}
                  currentActive={this.state.currentActive}
                  handleLinkFocus={this.handleLinkFocus}
                  currentFocused={this.state.currentFocused}
                />
              )}
              {(!accountThemeCustomization.isSuccess || !appInfo.AppName) && (
                <div className="d-flex placeholder-d-flex">
                  <div>
                    <Placeholder className="placeholder-profile-lable">
                      <Placeholder.Image />
                    </Placeholder>
                    <Placeholder className="placeholder-profile-lable-email">
                      <Placeholder.Image />
                    </Placeholder>
                  </div>
                  <Placeholder className="placeholder-profile-logo">
                    <Placeholder.Image />
                  </Placeholder>
                </div>
              )}
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: RootSchema) => {
  return {
    i18n: state.appState.i18n.languages,
    appBasicInfo: state.auth.appBasicInfo,
    accountThemeCustomization: state.account.accountThemeCustomization,
    accountGetProfile: state.account.AccountGetProfile,
    appInfo: state.auth.currentAppinfo,
    IOLoginUrl: state.auth.authConfig.IOLoginUrl
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    switchToOwnedSite: (appId: number, appName: string, path: string) =>
      dispatch(switchToOwnedSiteAction(appId, appName, path)),
    switchToSharedSite: (appId: number, appName: string, path: string) =>
      dispatch(switchToSharedSiteAction(appId, appName, path)),
    getAppPermission: () => {
      dispatch(initAppViewPermissionAction());
    },
    appInfoAction: () => {
      dispatch(appInfoAction());
    },
    chnageMenu: (value: string) => dispatch(navigationMenuChange(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<NavigationProps>(Navigation));

import { take, call, put } from "redux-saga/effects";
import { httpGet, httpPost } from "../../services";

import {
  BillingConst,
  GetAppDataSchema,
  getAppDataLoadingAction,
  getAppDataSuccessAction,
  getAppDataErrorAction,
  GetBillingSubscriptionsSchema,
  getBillingSubscriptionsLoadingAction,
  getBillingSubscriptionsSuccessAction,
  getBillingSubscriptionsErrorAction,
  GetInvoicesSchema,
  getInvoicesLoadingAction,
  getInvoicesSuccessAction,
  getInvoicesErrorAction,
  GetRecurlyAccountDetailsSchema,
  getRecurlyAccountDetailsLoadingAction,
  getRecurlyAccountDetailsSuccessAction,
  getRecurlyAccountDetailsErrorAction,
  getRecurlyAccountDetailsAction
} from "../actions/billingActions";

/**
 * Workers
 */

function* getAppDataHandler(appId: string) {
  try {
    yield put(getAppDataLoadingAction());

    const data: GetAppDataSchema = yield httpPost(
      "/account/billing/appid",
      {},
      { appId }
    );

    yield put(getAppDataSuccessAction({ ...data }));
  } catch (e) {
    yield put(getAppDataErrorAction(e.message));
  }
}

function* getBillingSubscriptionsHandler(recurlyAccountCode: string) {
  try {
    yield put(getBillingSubscriptionsLoadingAction());

    const data: GetBillingSubscriptionsSchema = yield httpGet(
      "/account/billing/subscription",
      { accountcode: encodeURI(recurlyAccountCode) }
    );

    yield put(getBillingSubscriptionsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getBillingSubscriptionsErrorAction(e.message));
  }
}

function* getInvoicesHandler(recurlyAccountCode: string) {
  try {
    yield put(getInvoicesLoadingAction());

    const data: GetInvoicesSchema = yield httpGet("/account/billing/invoice", {
      accountcode: encodeURI(recurlyAccountCode)
    });

    yield put(getInvoicesSuccessAction({ ...data }));
  } catch (e) {
    yield put(getInvoicesErrorAction(e.message));
  }
}

function* getRecurlyAccountDetailsHandler(recurlyAccountCode: string) {
  try {
    yield put(getRecurlyAccountDetailsLoadingAction());

    const data: GetRecurlyAccountDetailsSchema = yield httpGet(
      "/account/billing/recurly/information",
      { accountcode: encodeURI(recurlyAccountCode) }
    );

    yield put(getRecurlyAccountDetailsSuccessAction({ ...data }));
  } catch (e) {
    yield put(getRecurlyAccountDetailsErrorAction(e.message));
  }
}

/**
 * Watchers
 */

export function* watchAppData() {
  while (true) {
    const data = yield take(BillingConst.GET_APP_DATA);
    yield call(getAppDataHandler, data.payload);
  }
}

export function* watchBillingSubscriptions() {
  while (true) {
    const data = yield take(BillingConst.GET_BILLING_SUBSCRIPTIONS);
    yield call(getBillingSubscriptionsHandler, data.payload.accountCode);
  }
}

export function* watchInvoices() {
  while (true) {
    const data = yield take(BillingConst.GET_INVOICES);
    yield call(getInvoicesHandler, data.payload.accountCode);
  }
}

export function* watchGetRecurlyAccountDetails() {
  while (true) {
    const data = yield take(BillingConst.GET_RECURLY_ACCOUNT_DETAILS);
    yield call(getRecurlyAccountDetailsHandler, data.payload.accountCode);
  }
}

import { call, put, select, take } from "redux-saga/effects";
import { httpDelete, httpGet, httpPost, httpPut } from "../../services";
import {
  AccountConst,
  deleteRequestAccountGoogle2FAErrorAction,
  deleteRequestAccountGoogle2FALoadingAction,
  deleteRequestAccountGoogle2FASuccessAction,
  deleteRequestAccountSMS2FAErrorAction,
  deleteRequestAccountSMS2FALoadingAction,
  deleteRequestAccountSMS2FASuccessAction,
  getAccountThemeCustomizationErrorAction,
  getAccountThemeCustomizationLoadingAction,
  getAccountThemeCustomizationSuccessAction,
  getErrorAccountSiteCredential,
  getLoadAccountSiteCredential,
  getRequestAccount2FAErrorAction,
  getRequestAccount2FALoadingAction,
  getRequestAccount2FASuccessAction,
  getRequestAccountBackupCodesErrorAction,
  getRequestAccountBackupCodesLoadingAction,
  getRequestAccountBackupCodesSuccessAction,
  GetRequestAccountLoginSessionDataSchema,
  getRequestAccountResetBackupCodesErrorAction,
  getRequestAccountResetBackupCodesLoadingAction,
  getRequestAccountResetBackupCodesSuccessAction,
  getRequestErrorAction,
  getRequestLoadingAction,
  getRequestLoginSessionErrorAction,
  getRequestLoginSessionLoadingAction,
  getRequestLoginSessionSuccessAction,
  GetRequestProfileDataSchema,
  getRequestProfileSuccessAction,
  getSitesSubscriptionListErrorAction,
  getSitesSubscriptionListLoadingAction,
  getSitesSubscriptionListSuccessAction,
  getSuccessAccountSiteCredential,
  postRequestAccountGoogle2FAErrorAction,
  postRequestAccountGoogle2FALoadingAction,
  postRequestAccountGoogle2FASuccessAction,
  postRequestAccountSMS2FAErrorAction,
  postRequestAccountSMS2FALoadingAction,
  postRequestAccountSMS2FASuccessAction,
  postRequestAccountSMS2FAVerifyErrorAction,
  postRequestAccountSMS2FAVerifyLoadingAction,
  postRequestAccountSMS2FAVerifySuccessAction,
  ProfileRequestSchema,
  RequestSitesSubscriptionListResponse,
  SettingsRequestSchema,
  switchToOwnedSiteErrorAction,
  switchToOwnedSiteResetAction,
  switchToOwnedSiteSuccessAction,
  switchToSharedSiteErrorAction,
  switchToSharedSiteResetAction,
  switchToSharedSiteSuccessAction,
  updateAccountLinkingErrorAction,
  updateAccountLinkingLoadingAction,
  updateAccountLinkingSuccessAction,
  updateErrorAccountSiteCredential,
  updateLoadAccountSiteCredential,
  updateRequestLogOutSessionErrorAction,
  updateRequestLogOutSessionLoadingAction,
  updateRequestLogOutSessionSuccessAction,
  UpdateRequestProfileDataSchema,
  updateRequestProfileErrorAction,
  updateRequestProfileLoadingAction,
  updateRequestProfileSuccessAction,
  updateSuccessAccountSiteCredential,
  updateBillingPlanAction,
  updateBillingPlanLoadinAction,
  updateBillingPlanErrorAction,
  updateBillingPlanSuccessAction,
  getRequestUpgradeAccount2FALoadingAction,
  getRequestUpgradeAccount2FASuccessAction,
  getRequestUpgradeAccount2FAErrorAction,
  GetRequestAccount2FADataSchema,
  postRequestUpgradeAccountGoogle2FALoadingAction,
  postRequestUpgradeAccountGoogle2FASuccessAction,
  postRequestUpgradeAccountGoogle2FAErrorAction
} from "../actions/accountActions";
import { showLoadingAction } from "../actions/appStateActions";
import { AccountPlanConst } from "../actions/accountActions";
import { AuthConst, getYourSitesAction } from "../actions/authActions";
import { RootSchema } from "../reducers";
import { localAppStore } from "../../utils";
function* getSitesSubscriptionListHandler() {
  try {
    yield put(getSitesSubscriptionListLoadingAction());
    let sitesAndAccountCodes = yield select(getSitesAndAccountCodes);
    // If the account's owned sites have not been fetched, do so before continuing:
    if (sitesAndAccountCodes === -1) {
      yield put(getYourSitesAction());
      yield take(AuthConst.GET_YOUR_SITES_SUCCESS);
      sitesAndAccountCodes = yield select(getSitesAndAccountCodes);
    }
    // Fetch the subscription type for each account based on its account code:
    let sitesSubscriptions: RequestSitesSubscriptionListResponse = [];
    const siteLength = sitesAndAccountCodes.length;
    let retriveIndex = 0;
    const getSiteSubscription = async () => {
      for (let i = 0; i < sitesAndAccountCodes.length; i++) {
        const response = await httpGet("/account/billing/subscription/list", {
          accountcode: sitesAndAccountCodes[i].accountcode,
          ind: i,
          isPreviousPlanApp: sitesAndAccountCodes[i].isPreviousPlanApp
        });
        sitesSubscriptions.push({
          AppName: sitesAndAccountCodes[i].AppName,
          Subscription: processSiteSubscriptionResponse(response)
        });
        retriveIndex = i;
      }
      if (retriveIndex === siteLength - 1) {
        return sitesSubscriptions;
      }
    };

    yield getSiteSubscription();
    yield put(
      getSitesSubscriptionListSuccessAction({ response: sitesSubscriptions })
    );
  } catch (e) {
    yield put(getSitesSubscriptionListErrorAction(e.message));
  }
}

/**
 * Navigate to the path provided after switching to the owned site whose app ID and name are provided as arguments.
 * @param appId ID of the owned site to switch to.
 * @param appName Name of the owned site to switch to.
 * @param path  Path to navigate to after switching to the site whose ID and path are provided.
 */
function* switchToOwnedSiteHandler(
  appId: number,
  appName: string,
  path: string
) {
  try {
    const response = yield httpGet("/account/switchapp", { appid: appId });
    if (response.errorCode !== undefined)
      throw Error(response.message || response.description || "");
    commonHandlerForSiteSwitchingResponse(appId, appName, path, response);
    yield put(switchToOwnedSiteSuccessAction());
  } catch (e) {
    yield put(
      switchToOwnedSiteErrorAction(
        `Unsuccessful site switch to ${appName}. ${e.message}`
      )
    );
    yield put(switchToOwnedSiteResetAction()); // A future request to switch to owned site might be successful, so reset status report of the request.
  }
}

/**
 * Navigate to the path provided after switching to the shared site whose app ID and name are provided as arguments.
 * @param appId ID of the owned site to switch to.
 * @param appName Name of the owned site to switch to.
 * @param path  Path to navigate to after switching to the site whose ID and path are provided.
 */
function* switchToSharedSiteHandler(
  appId: number,
  appName: string,
  path: string
) {
  try {
    const response = yield httpGet("/account/switchsharedsite", {
      appid: appId
    });
    commonHandlerForSiteSwitchingResponse(appId, appName, path, response);
    yield put(switchToSharedSiteSuccessAction());
  } catch (e) {
    yield put(
      switchToSharedSiteErrorAction(
        `Unsuccessful site switch to ${appName}. ${e.message}`
      )
    );
    yield put(switchToSharedSiteResetAction()); // A future request to switch to shared site might be successful, so reset status report of the request.
  }
}

/**
 * Common actions performed after receiving a successful response from the site switching endpoints: /account/switchapp and
 * /account/switchsahredsite.
 * @param appId ID of the owned site to switch to.
 * @param appName Name of the owned site to switch to.
 * @param path  Path to navigate to after switching to the site whose ID and path are provided.
 * @param response  a response from either of the /account/switchapp and /account/switchsahredsite endpoints.
 */
export async function commonHandlerForSiteSwitchingResponse(
  appId: number,
  appName: string,
  path: string,
  response: any
) {
  try {
    if (response.xtoken === "" && location.pathname !== "/logout")
      location.href = "/logout";
    else {
      if (response.xsign)
        await localAppStore.setItem("lr-x-sign", response.xsign);
      if (response.xtoken)
        await localAppStore.setItem("lr-x-token", response.xtoken);
      localStorage.setItem("siteName", appName);
      const basicSettings = await httpGet("/auth/basicsettings");
      localAppStore.removeItem("global-root-css");
      localAppStore.removeItem("global-root-current-css");
      if (basicSettings && basicSettings.IsDashboardCustomizationEnabled) {
        const theme = await httpGet("/account/theme/customization", {
          dir: "publish"
        }); // for custom theme
        if (Array.isArray(theme.Css) && theme.Css.length) {
          const css = await httpPost(
            "/account/theme/publish/css",
            {},
            { url: String(theme.Css[0]) }
          );
          if (css && css.error !== "fail") {
            let logoPath = "";
            if (Array.isArray(theme.Images) && theme.Images.length) {
              let array = String(theme.Images[0]).split("/");
              if (array.length) {
                logoPath =
                  "https://cdn.filestackcontent.com/" + array[array.length - 1];
              }
            }

            await localAppStore.setItem("global-root-css", {
              css: css.data,
              appName: appName,
              logoPath: logoPath
            });
            location.href = path;
          } else {
            location.href = path;
          }
        } else location.href = path;
      }

      location.href = path;
    }
  } catch (e) {
    location.href = path;
  }
}

/**
 * Returns an array of {AppName: string, accountcode: string} objects, where AppName is the name
 * of one of the sites the account owns and aaccountcode its recurly account code; if the state
 * does not have the list of the sites the account owns yet, returns -1. If the account does not own
 * any sites, returns an empty object.
 * @param state State of the application's redux store.
 */
const getSitesAndAccountCodes = (state: RootSchema) => {
  if (!state.auth.yourSites.response) return -1;
  else if (state.auth.yourSites.response.length === 0) return {};
  return state.auth.yourSites.response.map(site => {
    return {
      AppName: site.AppName,
      accountcode: site.RecurlyAccountCode || site.AppId,
      isPreviousPlanApp: site.RecurlyAccountCode ? true : false
    };
  });
};

/**
 * Process response sent to the GET /account/billing/subscription/list endpoint and returns the subscription associated
 * to the site whose data was sent in the request to the endpoint.
 * @param siteSubscriptionResponse
 */
const processSiteSubscriptionResponse = (siteSubscriptionResponse: any) => {
  let subscription = "Free";
  if (
    siteSubscriptionResponse != null &&
    siteSubscriptionResponse.resp != null &&
    siteSubscriptionResponse.resp.data != null &&
    siteSubscriptionResponse.resp.data !== 404
  ) {
    try {
      subscription =
        siteSubscriptionResponse.resp.data.subscriptions.subscription instanceof
        Array
          ? siteSubscriptionResponse.resp.data.subscriptions.subscription[0]
              .plan.name
          : siteSubscriptionResponse.resp.data.subscriptions.subscription.plan
              .name;
    } catch (err) {
      subscription = "Free";
    }
  }
  return subscription;
};
function* getRequestProfileHandler() {
  try {
    yield put(getRequestLoadingAction());
    const data: GetRequestProfileDataSchema = yield httpGet("/account/user");
    //yield put(showLoadingAction(false));
    yield put(getRequestProfileSuccessAction(data));
  } catch (e) {
    yield put(getRequestErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* updateRequestProfileHandler(payload: ProfileRequestSchema) {
  try {
    yield put(updateRequestProfileLoadingAction());
    const data: UpdateRequestProfileDataSchema = yield httpPut(
      "/account/user",
      {},
      payload
    );
    yield put(updateRequestProfileSuccessAction(data));
  } catch (e) {
    yield put(updateRequestProfileErrorAction(e.message));
  } finally {
    //    yield put(showLoadingAction(false));
  }
}

function* getAccountCredentialHandler(data: any) {
  try {
    yield put(getLoadAccountSiteCredential());

    const data: any = yield httpGet("/account/site/credentials");
    yield put(getSuccessAccountSiteCredential(data));
  } catch (e) {
    yield put(getErrorAccountSiteCredential(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* updateAccountSettingsHandler(payload: SettingsRequestSchema) {
  try {
    yield put(updateLoadAccountSiteCredential());
    const data: any = yield httpPut("/account/password", {}, payload);
    yield put(updateSuccessAccountSiteCredential(data));
  } catch (e) {
    yield put(updateErrorAccountSiteCredential(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* getRequestLoginSessionHandler(queryParams: any) {
  try {
    yield put(getRequestLoginSessionLoadingAction());
    const data: GetRequestAccountLoginSessionDataSchema = yield httpPost(
      "/team/sessions",
      {},
      queryParams
    );
    yield put(getRequestLoginSessionSuccessAction(data));
  } catch (e) {
    yield put(getRequestLoginSessionErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* updateRequestLogOutSessionHandler(payload: any) {
  try {
    yield put(updateRequestLogOutSessionLoadingAction());
    const data: any = yield httpPost("/team/invalidate/all", {}, payload);
    yield put(updateRequestLogOutSessionSuccessAction(data));
  } catch (e) {
    yield put(updateRequestLogOutSessionErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* updateRequestAccountLinkingHandler(payload: any) {
  try {
    yield put(updateAccountLinkingLoadingAction());
    const data: any = yield httpPost(
      "/account/user/unlink/account/providerid",
      {},
      payload
    );
    yield put(updateAccountLinkingSuccessAction(data));
  } catch (e) {
    yield put(updateAccountLinkingErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* getRequestAccount2FAHandler() {
  try {
    yield put(getRequestAccount2FALoadingAction());
    const data: GetRequestAccountLoginSessionDataSchema = yield httpGet(
      "/account/2fa"
    );
    yield put(getRequestAccount2FASuccessAction(data));
  } catch (e) {
    yield put(getRequestAccount2FAErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* postRequestAccountGoogle2FAHandler(payload: any) {
  try {
    yield put(postRequestAccountGoogle2FALoadingAction());
    const data: any = yield httpPost("/account/2fa/google", {}, payload);
    if (data.ErrorCode) {
      throw new Error(data.Description || data.Message || "");
    } else {
      yield put(postRequestAccountGoogle2FASuccessAction(data));
    }
  } catch (e) {
    yield put(postRequestAccountGoogle2FAErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* deleteRequestAccountGoogle2FAHandler(payload: any) {
  try {
    yield put(deleteRequestAccountGoogle2FALoadingAction());
    const data: any = yield httpDelete("/account/2fa/google", {}, payload);
    yield put(deleteRequestAccountGoogle2FASuccessAction(data));
  } catch (e) {
    yield put(deleteRequestAccountGoogle2FAErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* postRequestAccountSMS2FAVerifyHandler(payload: any) {
  try {
    yield put(postRequestAccountSMS2FAVerifyLoadingAction());
    const data: any = yield httpPost(
      "/account/2fa/sms/verification",
      {},
      payload
    );
    yield put(postRequestAccountSMS2FAVerifySuccessAction(data));
  } catch (e) {
    yield put(postRequestAccountSMS2FAVerifyErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* postRequestAccountSMS2FAHandler(payload: any) {
  try {
    yield put(postRequestAccountSMS2FALoadingAction());
    const data: any = yield httpPost("/account/2fa/sms", {}, payload);
    if (data.ErrorCode) {
      throw new Error(data.Description || data.Message || "");
    } else {
      yield put(postRequestAccountSMS2FASuccessAction(data));
    }
  } catch (e) {
    yield put(postRequestAccountSMS2FAErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* deleteRequestAccountSMS2FAHandler(payload: any) {
  try {
    yield put(deleteRequestAccountSMS2FALoadingAction());
    const data: any = yield httpDelete("/account/2fa/sms", {}, payload);
    yield put(deleteRequestAccountSMS2FASuccessAction(data));
  } catch (e) {
    yield put(deleteRequestAccountSMS2FAErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* getRequestAccountBackupCodesHandler(queryParams: any) {
  try {
    yield put(getRequestAccountBackupCodesLoadingAction());
    const data: any = yield httpGet("/account/backupcodes", queryParams);
    yield put(getRequestAccountBackupCodesSuccessAction(data));
  } catch (e) {
    yield put(getRequestAccountBackupCodesErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* getRequestAccountResetBackupCodesHandler(queryParams: any) {
  try {
    yield put(getRequestAccountResetBackupCodesLoadingAction());
    const data: any = yield httpGet("/account/backupcodes/reset", queryParams);
    yield put(getRequestAccountResetBackupCodesSuccessAction(data));
  } catch (e) {
    yield put(getRequestAccountResetBackupCodesErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* getAccountThemeCustomizationHandler(queryParams: any) {
  try {
    yield put(getAccountThemeCustomizationLoadingAction());
    //yield put(showLoadingAction(true, "Please Wait..."));
    const data: any = yield httpGet(
      "/account/theme/customization",
      queryParams
    );
    yield put(getAccountThemeCustomizationSuccessAction(data));
  } catch (e) {
    yield put(getAccountThemeCustomizationErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
function* updatePlanDetails(data) {
  try {
    yield put(updateBillingPlanLoadinAction());
    const response = yield httpPost("/account/updateproductplan", {}, data);
    yield put(updateBillingPlanSuccessAction(response));
  } catch (e) {
    yield put(updateBillingPlanErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

function* getRequestAccountUpgrade2FAHandler() {
  try {
    yield put(getRequestUpgradeAccount2FALoadingAction());
    const data: GetRequestAccount2FADataSchema = yield httpGet(
      "/account/upgrade/2fa"
    );
    if (data["errorCode"] || data["ErrorCode"]) {
      throw new Error();
    }
    yield put(getRequestUpgradeAccount2FASuccessAction(data));
  } catch (e) {
    yield put(getRequestUpgradeAccount2FAErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}
function* postRequestAccountUpgradeGoogle2FAHandler(payload: any) {
  try {
    yield put(postRequestUpgradeAccountGoogle2FALoadingAction());
    const data: any = yield httpPost(
      "/account/upgrade/2fa/google",
      {},
      payload
    );
    if (data.ErrorCode) {
      let msg = data.Description;
      if (data.ErrorCode === 1102) {
        msg =
          "The entered verification code is incorrect. Please check the code and try again.";
      }
      throw new Error(msg);
    }
    yield put(postRequestUpgradeAccountGoogle2FASuccessAction(data));
  } catch (e) {
    yield put(postRequestUpgradeAccountGoogle2FAErrorAction(e.message));
  } finally {
    yield put(showLoadingAction(false));
  }
}

/**
 * Watchers
 */
export function* watchPlanDetail() {
  while (true) {
    const { payload } = yield take(AccountPlanConst.UPDATE_PLAN_DETAIL);
    yield call(updatePlanDetails, payload);
  }
}
export function* watchGetProfileData() {
  while (true) {
    yield take(AccountConst.GET_REQUEST_PROFILE);
    yield call(getRequestProfileHandler);
  }
}

export function* watchUpdateProfileData() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.UPDATE_REQUEST_PROFILE_DATA
    );
    yield call(updateRequestProfileHandler, payload);
  }
}

export function* watchGetAccountCredentialData() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.GET_REQUEST_ACCOUNT_SITE_CREDENTIAL
    );
    yield call(getAccountCredentialHandler, payload);
  }
}

export function* watchUpdateAccountSettingsData() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.UPDATE_REQUEST_ACCOUNT_SITE_SETTING
    );
    yield call(updateAccountSettingsHandler, payload);
  }
}

export function* watchGetLoginSessionData() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.GET_REQUEST_ACCOUNT_SESSION
    );
    yield call(getRequestLoginSessionHandler, payload);
  }
}

export function* watchUpdateLogOutSession() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.UPDATE_REQUEST_ACCOUNT_SESSION_LOGOUT
    );
    yield call(updateRequestLogOutSessionHandler, payload);
  }
}

export function* watchUpdateAccountLinking() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.UPDATE_ACCOUTN_SOCIAL_LINKING
    );
    yield call(updateRequestAccountLinkingHandler, payload);
  }
}
export function* watchGetSitesSubscriptionList() {
  while (true) {
    yield take(AccountConst.GET_SITES_SUBSCRIPTION_LIST);
    yield call(getSitesSubscriptionListHandler);
  }
}

export function* watchSwitchToOwnedSite() {
  while (true) {
    const {
      payload: { appId, appName, path }
    } = yield take(AccountConst.SWITCH_TO_OWNED_SITE);
    yield call(switchToOwnedSiteHandler, appId, appName, path);
  }
}

export function* watchSwitchToSharedSite() {
  while (true) {
    const {
      payload: { appId, appName, path }
    } = yield take(AccountConst.SWITCH_TO_SHARED_SITE);
    yield call(switchToSharedSiteHandler, appId, appName, path);
  }
}

export function* watchGetAccount2FAData() {
  while (true) {
    yield take(AccountConst.GET_REQUEST_ACCOUNT_2FA);
    yield call(getRequestAccount2FAHandler);
  }
}

export function* watchPostAccountGoogle2FA() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.POST_REQUEST_ACCOUNT_GOOGLE_2FA
    );
    yield call(postRequestAccountGoogle2FAHandler, payload);
  }
}

export function* watchDeleteAccountGoogle2FA() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.DELETE_REQUEST_ACCOUNT_GOOGLE_2FA
    );
    yield call(deleteRequestAccountGoogle2FAHandler, payload);
  }
}

export function* watchPostAccountSMS2FAVerify() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.POST_REQUEST_ACCOUNT_SMS_VERIFY_2FA
    );
    yield call(postRequestAccountSMS2FAVerifyHandler, payload);
  }
}

export function* watchPostAccountSMS2FA() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.POST_REQUEST_ACCOUNT_SMS_2FA
    );
    yield call(postRequestAccountSMS2FAHandler, payload);
  }
}

export function* watchDeleteAccountSMS2FA() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.DELETE_REQUEST_ACCOUNT_SMS_2FA
    );
    yield call(deleteRequestAccountSMS2FAHandler, payload);
  }
}

export function* watchGetAccountBackupCodes2FA() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.GET_REQUEST_ACCOUNT_BACKUP_CODES
    );
    yield call(getRequestAccountBackupCodesHandler, payload);
  }
}

export function* watchGetAccountResetBackupCodes2FA() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.GET_REQUEST_ACCOUNT_RESET_BACKUP_CODES
    );
    yield call(getRequestAccountResetBackupCodesHandler, payload);
  }
}

export function* watchGetAccountThemeCustomization() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.GET_ACCOUNT_THEME_CUSTOMIZATION
    );
    yield call(getAccountThemeCustomizationHandler, payload);
  }
}
export function* watchGetAccountUpgrade2FAData() {
  while (true) {
    yield take(AccountConst.GET_REQUEST_UPGRADE_ACCOUNT_2FA);
    yield call(getRequestAccountUpgrade2FAHandler);
  }
}

export function* watchPostAccountUpgradeGoogle2FA() {
  while (true) {
    const { payload: payload } = yield take(
      AccountConst.POST_REQUEST_UPGRADE_ACCOUNT_GOOGLE_2FA
    );
    yield call(postRequestAccountUpgradeGoogle2FAHandler, payload);
  }
}

import {
  SideEffectSchema,
  loadingAction,
  errorAction,
  successSideEffectState,
  APIErrorResponseSchema,
  generateConst
} from ".";
import { LanguageSchema } from "../../i18n";
export const WebAppsConst = {
  GET_WEB_APPS: "GET_WEB_APPS",
  GET_WEB_APPS_LOADING: "GET_WEB_APPS_LOADING",
  GET_WEB_APPS_SUCCESS: "GET_WEB_APPS_SUCCESS",
  GET_WEB_APPS_ERROR: "GET_WEB_APPS_ERROR",

  ADD_WEB_APPS: "ADD_WEB_APPS",
  ADD_WEB_APPS_LOADING: "ADD_WEB_APPS_LOADING",
  ADD_WEB_APPS_SUCCESS: "ADD_WEB_APPS_SUCCESS",
  ADD_WEB_APPS_ERROR: "ADD_WEB_APPS_ERROR"
};
export const CustomDomainConst = {
  GET_CUSTOM_DOMAIN: "GET_CUSTOM_DOMAIN",
  GET_CUSTOM_DOMAIN_LOADING: "GET_CUSTOM_DOMAIN_LOADING",
  GET_CUSTOM_DOMAIN_SUCCESS: "GET_CUSTOM_DOMAIN_SUCCESS",
  GET_CUSTOM_DOMAIN_ERROR: "GET_CUSTOM_DOMAIN_ERROR",
  SAVE_CUSTOM_DOMAIN: "SAVE_CUSTOM_DOMAIN",
  SAVE_CUSTOM_DOMAIN_LOADING: "SAVE_CUSTOM_DOMAIN_LOADING",
  SAVE_CUSTOM_DOMAIN_SUCCESS: "SAVE_CUSTOM_DOMAIN_SUCCESS",
  SAVE_CUSTOM_DOMAIN_ERROR: "SAVE_CUSTOM_DOMAIN_ERROR",
  DELETE_CUSTOM_DOMAIN: "DELETE_CUSTOM_DOMAIN",
  DELETE_CUSTOM_DOMAIN_LOADING: "DELETE_CUSTOM_DOMAIN_LOADING",
  DELETE_CUSTOM_DOMAIN_SUCCESS: "DELETE_CUSTOM_DOMAIN_SUCCESS",
  DELETE_CUSTOM_DOMAIN_ERROR: "DELETE_CUSTOM_DOMAIN_ERROR"
};

export interface CustomDomainSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  Data: any;
}
export interface WebAppsSchema extends SideEffectSchema {
  CallbackUrl?: string;
  DevDomain?: string;
  AppName: string;
  Key: string;
  production?: string[];
  staging?: string[];
  Secret?: string;
  errorCode?: number;
  message?: string;
  Email: string;
}

export const DeploymentConst = generateConst("DEPLOYMENT", [
  "GET_HOSTED_PAGE",
  "SAVE_HOSTED_PAGE",
  "UPDATE_HOSTED_PAGE",
  "RESET_HOSTED_PAGE",
  "FETCH_HOSTED_PAGE_URL",
  "GET_SMTP_SETTINGS",
  "GET_IEF",
  "GET_IEF_BY_TYPE_AND_THEME",
  "UPDATE_IEF"
]);

export const SMTPSettingsConst = generateConst("DEPLOYMENT", [
  "SAVE_SMTP_SETTINGS",
  "VERIFY_SMTP_SETTINGS",
  "RESET_SMTP_SETTINGS"
]);

export interface MultiIEFSchema extends SideEffectSchema {
  Pages: IEFSchema[];
  errorCode?: string;
  message?: string;
}

// eslint-disable-next-line
export interface IEFSchema {
  BeforeScript: string;
  CommonScript: string;
  CustomCss: string[];
  CustomJS: string[];
  EndScript: string;
  FavIcon: string;
  HeadTags: string[];
  HtmlBody: string;
  IsActive: boolean;
  MainScript?: string;
  PageType: string;
  ThemeName?: string;
  HostNames?: HostNamesSchema[];
  Status?: string;
}

export interface HostNamesSchema {
  CustomDomain: string;
  IsDevelopmentMode: boolean;
}

/**
 * GET_CREATEDATE
 */
export interface GetHostedPageSchema extends SideEffectSchema {
  Pages: HostedPagesSchema[];
  errorCode?: string;
  message?: string;
}
export interface HostedPagesSchema {
  BeforeScript: string;
  CommonScript: string;
  CustomCss: string[];
  CustomJS: string[];
  EndScript: string;
  FavIcon: string;
  HeadTags: string[];
  HtmlBody: string;
  IsActive: boolean;
  MainScript?: string;
  PageType: string;
  Status?: string;
  JQueryScript?: string;
}

export interface UpdateHostedPageContentSchema {
  Content: string;
  fileName: string;
}

export interface UpdateHostedPageSchema {
  BeforeScript?: string;
  CommonScript?: string;
  CustomCss?: UpdateHostedPageContentSchema[];
  CustomJS?: UpdateHostedPageContentSchema[];
  EndScript?: string;
  FavIcon?: string;
  HeadTags?: string[];
  HtmlBody?: string;
  IsActive?: boolean;
  MainScript?: string;
  PageType: string;
  JQueryScript?: string;
}

export interface ResetHostedPageSchema {
  pageType: string;
}

export interface ResetHostedPageResponseSchema extends SideEffectSchema {
  Edited: boolean;
}

export interface FetchHostedPageUrlSchema {
  url: string;
}

export interface FetchHostedPageUrlResponseSchema extends SideEffectSchema {
  description: string;
  error: string;
  data: string;
  url: string;
  filetype: string;
}

export interface SMTPSettingSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  FromEmailId: string;
  FromName: string;
  IsSsl: boolean;
  Password: string;
  SmtpHost: string;
  SmtpPort: number;
  UserName: string;
}

export const getSMTPSettingsAction = () => {
  return {
    type: DeploymentConst.GET_SMTP_SETTINGS,
    payload: {}
  };
};
export const getSMTPSettingsLoadingAction = () =>
  loadingAction(DeploymentConst.GET_SMTP_SETTINGS_LOADING);

export const getSMTPSettingsErrorAction = (error: string) =>
  errorAction(DeploymentConst.GET_SMTP_SETTINGS_ERROR, error);

export const getSMTPSettingsSuccessAction = (data: SMTPSettingSchema) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DeploymentConst.GET_SMTP_SETTINGS_SUCCESS,
    payload
  };
};

export interface SaveSMTPSettingSchema {
  FromName: string;
  FromEmailId: string;
  username?: string;
  password?: string;
  SmtpHost?: string;
  SmtpPort?: string;
  isssl?: string;
  provider?: string;
  key?: string;
  secret?: string;
}

export const saveSMTPSettingsAction = (settings: SaveSMTPSettingSchema) => {
  return {
    type: SMTPSettingsConst.SAVE_SMTP_SETTINGS,
    payload: settings
  };
};
export const saveSMTPSettingsLoadingAction = () =>
  loadingAction(SMTPSettingsConst.SAVE_SMTP_SETTINGS_LOADING);

export const saveSMTPSettingsErrorAction = (error: string) =>
  errorAction(SMTPSettingsConst.SAVE_SMTP_SETTINGS_ERROR, error);

export const saveSMTPSettingsSuccessAction = (data: SMTPSettingSchema) => {
  const payload: SMTPSettingSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: SMTPSettingsConst.SAVE_SMTP_SETTINGS_SUCCESS,
    payload
  };
};

// VERIFY SMTP SETTINGS
export interface VerifySMTPSettingSchema extends SaveSMTPSettingSchema {
  emailId: string;
  subject: string;
  message: string;
}

export const verifySMTPSettingsAction = (
  settings: VerifySMTPSettingSchema,
  i18n: LanguageSchema
) => {
  return {
    type: SMTPSettingsConst.VERIFY_SMTP_SETTINGS,
    payload: settings,
    i18n
  };
};
export const verifySMTPSettingsLoadingAction = () =>
  loadingAction(SMTPSettingsConst.VERIFY_SMTP_SETTINGS_LOADING);

export const verifySMTPSettingsErrorAction = (error: string) =>
  errorAction(SMTPSettingsConst.VERIFY_SMTP_SETTINGS_ERROR, error);

export interface VerifySMTPSettingResponseSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  clientIP: string;
  isOK: boolean;
}

export const verifySMTPSettingsSuccessAction = (
  data: VerifySMTPSettingResponseSchema
) => {
  const payload: VerifySMTPSettingResponseSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: SMTPSettingsConst.VERIFY_SMTP_SETTINGS_SUCCESS,
    payload
  };
};

// RESET SMTP SETTINGS

export const resetSMTPSettingsAction = () => {
  return {
    type: SMTPSettingsConst.RESET_SMTP_SETTINGS,
    payload: {}
  };
};
export const resetSMTPSettingsLoadingAction = () =>
  loadingAction(SMTPSettingsConst.RESET_SMTP_SETTINGS_LOADING);

export const resetSMTPSettingsErrorAction = (error: string) =>
  errorAction(SMTPSettingsConst.RESET_SMTP_SETTINGS_ERROR, error);

export interface ResponseResetSchema extends APIErrorResponseSchema {
  Edited: boolean;
}

export interface ResetSMTPSettingResponseSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  responseReset: ResponseResetSchema;
  responseSMTPSetting: SMTPSettingSchema;
}

export const resetSMTPSettingsSuccessAction = (
  data: ResetSMTPSettingResponseSchema
) => {
  const payload: ResetSMTPSettingResponseSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: SMTPSettingsConst.RESET_SMTP_SETTINGS_SUCCESS,
    payload
  };
};

//Get Web apps
export const getWebAppsAction = (data?: any) => {
  return {
    type: WebAppsConst.GET_WEB_APPS,
    payload: { data }
  };
};
export const getWebAppsLoadingAction = () =>
  loadingAction(WebAppsConst.GET_WEB_APPS_LOADING);

export const getWebAppsErrorAction = (error: string) =>
  errorAction(WebAppsConst.GET_WEB_APPS_ERROR, error);

export const getWebAppsSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: WebAppsConst.GET_WEB_APPS_SUCCESS,
    payload
  };
};

//Add Web apps

export const addWebAppsAction = data => {
  return {
    type: WebAppsConst.ADD_WEB_APPS,
    payload: data
  };
};

export const addWebAppsLoadingAction = () =>
  loadingAction(WebAppsConst.ADD_WEB_APPS_LOADING);

export const addWebAppsErrorAction = (error: string) =>
  errorAction(WebAppsConst.ADD_WEB_APPS_ERROR, error);

export const addWebAppsSuccessAction = (data: WebAppsSchema) => {
  const payload: WebAppsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: WebAppsConst.ADD_WEB_APPS_SUCCESS,
    payload
  };
};

//Get Custom Domain

export const getCustomDomainAction = () => {
  return {
    type: CustomDomainConst.GET_CUSTOM_DOMAIN,
    payload: {}
  };
};
export const getCustomDomainLoadingAction = () =>
  loadingAction(CustomDomainConst.GET_CUSTOM_DOMAIN_LOADING);

export const getCustomDomainErrorAction = (error: string) =>
  errorAction(CustomDomainConst.GET_CUSTOM_DOMAIN_ERROR, error);

export const getCustomDomainSuccessAction = (data: CustomDomainSchema) => {
  const payload: CustomDomainSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomDomainConst.GET_CUSTOM_DOMAIN_SUCCESS,
    payload
  };
};
/**
 * SAVE_CUSTOM_DOMAIN
 */
export interface SaveCustomDomainSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {}

export interface SaveCustomDomainArgs {
  payload: {
    CustomDomain: string;
  };
}

export const saveCustomDomainAction = (payload: SaveCustomDomainArgs) => {
  return {
    type: CustomDomainConst.SAVE_CUSTOM_DOMAIN,
    payload
  };
};

export const saveCustomDomainLoadingAction = () =>
  loadingAction(CustomDomainConst.SAVE_CUSTOM_DOMAIN_LOADING);
export const saveCustomDomainErrorAction = (error: string) =>
  errorAction(CustomDomainConst.SAVE_CUSTOM_DOMAIN_ERROR, error);
export const saveCustomDomainSuccessAction = (data: SaveCustomDomainSchema) => {
  const payload: SaveCustomDomainSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomDomainConst.SAVE_CUSTOM_DOMAIN_SUCCESS,
    payload
  };
};

/**
 * DELETE_CUSTOM_DOMAIN
 */
export interface DeleteCustomDomainSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {}

export interface DeleteCustomDomainArgs {
  payload: {
    CustomDomain: string;
  };
}

export const deleteCustomDomainAction = (payload: DeleteCustomDomainArgs) => {
  return {
    type: CustomDomainConst.DELETE_CUSTOM_DOMAIN,
    payload
  };
};

export const deleteCustomDomainLoadingAction = () =>
  loadingAction(CustomDomainConst.DELETE_CUSTOM_DOMAIN_LOADING);
export const deleteCustomDomainErrorAction = (error: string) =>
  errorAction(CustomDomainConst.DELETE_CUSTOM_DOMAIN_ERROR, error);
export const deleteCustomDomainSuccessAction = (
  data: DeleteCustomDomainSchema
) => {
  const payload: DeleteCustomDomainSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomDomainConst.DELETE_CUSTOM_DOMAIN_SUCCESS,
    payload
  };
};

export const resetHostedPageAction = (data: ResetHostedPageSchema) => {
  return {
    type: DeploymentConst.RESET_HOSTED_PAGE,
    payload: data
  };
};

export const resetHostedPageLoadingAction = () =>
  loadingAction(DeploymentConst.RESET_HOSTED_PAGE_LOADING);

export const resetHostedPageErrorAction = (error: string) =>
  errorAction(DeploymentConst.RESET_HOSTED_PAGE_ERROR, error);

export const resetHostedPageSuccessAction = (
  data: ResetHostedPageResponseSchema
) => {
  const payload: ResetHostedPageResponseSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DeploymentConst.RESET_HOSTED_PAGE_SUCCESS,
    payload
  };
};

export const fetchHostedPageUrlAction = (
  data: FetchHostedPageUrlSchema,
  pageType: string
) => {
  return {
    type: DeploymentConst.FETCH_HOSTED_PAGE_URL,
    payload: data,
    pageType: pageType
  };
};

export const fetchHostedPageUrlLoadingAction = () =>
  loadingAction(DeploymentConst.FETCH_HOSTED_PAGE_URL_LOADING);

export const fetchHostedPageUrlErrorAction = (error: string) =>
  errorAction(DeploymentConst.FETCH_HOSTED_PAGE_URL_ERROR, error);

export const fetchHostedPageUrlSuccessAction = (
  data: FetchHostedPageUrlResponseSchema
) => {
  const payload: FetchHostedPageUrlResponseSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DeploymentConst.FETCH_HOSTED_PAGE_URL_SUCCESS,
    payload
  };
};

export const getHostedPageAction = () => {
  return {
    type: DeploymentConst.GET_HOSTED_PAGE,
    payload: {}
  };
};

export const getHostedPageLoadingAction = () =>
  loadingAction(DeploymentConst.GET_HOSTED_PAGE_LOADING);

export const getHostedPageErrorAction = (error: string) =>
  errorAction(DeploymentConst.GET_HOSTED_PAGE_ERROR, error);

export const getHostedPageSuccessAction = (data: GetHostedPageSchema) => {
  const payload: GetHostedPageSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DeploymentConst.GET_HOSTED_PAGE_SUCCESS,
    payload
  };
};

export const saveHostedPageAction = (requestBody: HostedPagesSchema) => {
  return {
    type: DeploymentConst.SAVE_HOSTED_PAGE,
    payload: { ...requestBody }
  };
};

export const saveHostedPageLoadingAction = () =>
  loadingAction(DeploymentConst.SAVE_HOSTED_PAGE_LOADING);

export const saveHostedPageErrorAction = (error: string) =>
  errorAction(DeploymentConst.SAVE_HOSTED_PAGE_ERROR, error);

export const saveHostedPageSuccessAction = (data: HostedPagesSchema) => {
  const payload: HostedPagesSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DeploymentConst.SAVE_HOSTED_PAGE_SUCCESS,
    payload
  };
};

export const updateHostedPageAction = (data: HostedPagesSchema) => {
  return {
    type: DeploymentConst.UPDATE_HOSTED_PAGE,
    payload: { ...data }
  };
};

export const updateHostedPageLoadingAction = () =>
  loadingAction(DeploymentConst.UPDATE_HOSTED_PAGE_LOADING);

export const updateHostedPageErrorAction = (error: string) =>
  errorAction(DeploymentConst.UPDATE_HOSTED_PAGE_ERROR, error);

export const updateHostedPageSuccessAction = (data: GetHostedPageSchema) => {
  const payload: GetHostedPageSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DeploymentConst.UPDATE_HOSTED_PAGE_SUCCESS,
    payload
  };
};

export const getIEFAction = () => {
  return {
    type: DeploymentConst.GET_IEF,
    payload: {}
  };
};

export const getIEFLoadingAction = () =>
  loadingAction(DeploymentConst.GET_IEF_LOADING);

export const getIEFErrorAction = (error: string) =>
  errorAction(DeploymentConst.GET_IEF_ERROR, error);

export const getIEFSuccessAction = (data: MultiIEFSchema) => {
  const payload: MultiIEFSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DeploymentConst.GET_IEF_SUCCESS,
    payload
  };
};

export interface GetIEFByTypeAndThemeArgs {
  type: string;
  theme: string;
}

export const getIEFByTypeAndThemeAction = (args: GetIEFByTypeAndThemeArgs) => {
  return {
    type: DeploymentConst.GET_IEF_BY_TYPE_AND_THEME,
    payload: { ...args }
  };
};

export const getIEFByTypeAndThemeLoadingAction = () =>
  loadingAction(DeploymentConst.GET_IEF_BY_TYPE_AND_THEME_LOADING);

export const getIEFByTypeAndThemeErrorAction = (error: string) =>
  errorAction(DeploymentConst.GET_IEF_BY_TYPE_AND_THEME_ERROR, error);

export const getIEFByTypeAndThemeSuccessAction = (data: MultiIEFSchema) => {
  const payload: MultiIEFSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DeploymentConst.GET_IEF_BY_TYPE_AND_THEME_SUCCESS,
    payload
  };
};

export const updateIEFAction = (args: IEFSchema) => {
  return {
    type: DeploymentConst.UPDATE_IEF,
    payload: { ...args }
  };
};

export const updateIEFLoadingAction = () =>
  loadingAction(DeploymentConst.UPDATE_IEF_LOADING);

export const updateIEFErrorAction = (error: string) =>
  errorAction(DeploymentConst.UPDATE_IEF_ERROR, error);

export const updateIEFSuccessAction = (data: MultiIEFSchema) => {
  const payload: MultiIEFSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: DeploymentConst.UPDATE_IEF_SUCCESS,
    payload
  };
};

export const MobileAppsConst = {
  GET_MOBILE_APPS_SOTT: "GET_MOBILE_APPS_SOTT",
  GET_MOBILE_APPS_SOTT_LOADING: "GET_MOBILE_APPS_SOTT_LOADING",
  GET_MOBILE_APPS_SOTT_SUCCESS: "GET_MOBILE_APPS_SOTT_SUCCESS",
  GET_MOBILE_APPS_SOTT_ERROR: "GET_MOBILE_APPS_SOTT_ERROR",

  REVOKE_ALL_SOTT: "REVOKE_ALL_SOTT",
  REVOKE_ALL_SOTT_LOADING: "REVOKE_ALL_SOTT_LOADING",
  REVOKE_ALL_SOTT_SUCCESS: "REVOKE_ALL_SOTT_SUCCESS",
  REVOKE_ALL_SOTT_ERROR: "REVOKE_ALL_SOTT_ERROR",

  REVOKE_SOTT: "REVOKE_SOTT",
  REVOKE_SOTT_LOADING: "REVOKE_SOTT_LOADING",
  REVOKE_SOTT_SUCCESS: "REVOKE_SOTT_SUCCESS",
  REVOKE_SOTT_ERROR: "REVOKE_SOTT_ERROR",

  ADD_MOBILE_APPS_SOTT: "ADD_MOBILE_APPS_SOTT",
  ADD_MOBILE_APPS_SOTT_LOADING: "ADD_MOBILE_APPS_SOTT_LOADING",
  ADD_MOBILE_APPS_SOTT_SUCCESS: "ADD_MOBILE_APPS_SOTT_SUCCESS",
  ADD_MOBILE_APPS_SOTT_ERROR: "ADD_MOBILE_APPS_SOTT_ERROR"
};
export interface MobileAppsSchema extends SideEffectSchema {
  Data: AddMobileAppsSchema[];
  errorCode?: string;
}
export interface AddMobileAppsSchema extends SideEffectSchema {
  AuthenticityToken?: string;
  Comment?: string;
  CreatedDate?: string;
  DateRange?: string;
  IsEncoded?: boolean;
  Technology?: string;
  Sott?: string;
  errorCode?: string;
}
export interface DeleteMobileAppsSchema extends SideEffectSchema {
  isdeleted: boolean;
}
export const getMobileAppsSottAction = () => {
  return {
    type: MobileAppsConst.GET_MOBILE_APPS_SOTT,
    payload: {}
  };
};
export const getMobileAppsSottLoadingAction = () =>
  loadingAction(MobileAppsConst.GET_MOBILE_APPS_SOTT_LOADING);

export const getMobileAppsSottErrorAction = (error: string) =>
  errorAction(MobileAppsConst.GET_MOBILE_APPS_SOTT_ERROR, error);

export const getMobileAppsSottSuccessAction = (data: MobileAppsSchema) => {
  const payload: MobileAppsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: MobileAppsConst.GET_MOBILE_APPS_SOTT_SUCCESS,
    payload
  };
};

export const revokeMobileAppsSottAction = (token: string) => {
  return {
    type: MobileAppsConst.REVOKE_SOTT,
    payload: { authenticityToken: token }
  };
};
export const revokeMobileAppsSottLoadingAction = () =>
  loadingAction(MobileAppsConst.REVOKE_SOTT_LOADING);

export const revokeMobileAppsSottErrorAction = (error: string) =>
  errorAction(MobileAppsConst.REVOKE_ALL_SOTT_ERROR, error);

export const revokeMobileAppsSottSuccessAction = (
  data: DeleteMobileAppsSchema
) => {
  const payload: DeleteMobileAppsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: MobileAppsConst.REVOKE_SOTT_SUCCESS,
    payload
  };
};

export const revokeallMobileAppsSottAction = () => {
  return {
    type: MobileAppsConst.REVOKE_ALL_SOTT,
    payload: {}
  };
};
export const revokeallMobileAppsSottLoadingAction = () =>
  loadingAction(MobileAppsConst.REVOKE_ALL_SOTT_LOADING);

export const revokeallMobileAppsSottErrorAction = (error: string) =>
  errorAction(MobileAppsConst.REVOKE_ALL_SOTT_ERROR, error);

export const revokeallMobileAppsSottSuccessAction = (
  data: DeleteMobileAppsSchema
) => {
  const payload: DeleteMobileAppsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: MobileAppsConst.REVOKE_ALL_SOTT_SUCCESS,
    payload
  };
};

export const addMobileAppsSottAction = data => {
  return {
    type: MobileAppsConst.ADD_MOBILE_APPS_SOTT,
    payload: data
  };
};

export const addMobileAppsSottLoadingAction = () =>
  loadingAction(MobileAppsConst.ADD_MOBILE_APPS_SOTT_LOADING);

export const addMobileAppsSottErrorAction = (error: string) =>
  errorAction(MobileAppsConst.ADD_MOBILE_APPS_SOTT_ERROR, error);

export const addMobileAppsSottSuccessAction = (data: AddMobileAppsSchema) => {
  const payload: AddMobileAppsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: MobileAppsConst.ADD_MOBILE_APPS_SOTT_SUCCESS,
    payload
  };
};

export interface SettingsSchema {
  AskRequiredFieldsOnTraditionalLogin: boolean;
  IsLogoutOnEmailVerification: boolean;
  IsUserNameLogin: boolean;
  GoogleRecaptcha: boolean;
  IsV2Recaptcha: boolean;
  IsInvisibleRecaptcha: boolean;
  AskPasswordOnSocialLogin: boolean;
  AskEmailIdForUnverifiedUserLogin: boolean;
  AskOptionalFieldsOnSocialSignup: boolean;
  LoginOnEmailVerification: boolean;
}
export interface SaveSettingSchema extends SideEffectSchema {
  isposted?: boolean;
}
export const JSWidgetsConst = {
  SAVE_ADVANCE_OPTION: "SAVE_ADVANCE_OPTION",
  SAVE_ADVANCE_OPTION_LOADING: "SAVE_ADVANCE_OPTION_LOADING",
  SAVE_ADVANCE_OPTION_ERROR: "SAVE_ADVANCE_OPTION_ERROR",
  SAVE_ADVANCE_OPTION_SUCCESS: "SAVE_ADVANCE_OPTION_SUCCESS",

  UPDATE_OPTIONAL_CAPTCHA: "UPDATE_OPTIONAL_CAPTCHA",
  UPDATE_OPTIONAL_CAPTCHA_LOADING: "UPDATE_OPTIONAL_CAPTCHA_LOADING",
  UPDATE_OPTIONAL_CAPTCHA_ERROR: "UPDATE_OPTIONAL_CAPTCHA_ERROR",
  UPDATE_OPTIONAL_CAPTCHA_SUCCESS: "UPDATE_OPTIONAL_CAPTCHA_SUCCESS"
};
export const saveJSWidgetsAdvanceOptions = (data: SettingsSchema) => {
  return {
    payload: data,
    type: JSWidgetsConst.SAVE_ADVANCE_OPTION
  };
};
export const saveJSWidgetsAdvanceOptionsLoadingAction = () =>
  loadingAction(JSWidgetsConst.SAVE_ADVANCE_OPTION_LOADING);

export const saveJSWidgetsAdvanceOptionsErrorAction = (error: string) =>
  errorAction(JSWidgetsConst.SAVE_ADVANCE_OPTION_ERROR, error);

export const saveJSWidgetsAdvanceOptionsSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: JSWidgetsConst.SAVE_ADVANCE_OPTION_SUCCESS,
    payload
  };
};
export interface OptionalCaptcha {
  IsEnabled: boolean;
  Apis: {
    PostForgotPasswordByEmail: boolean;
    PostForgotPasswordByPhone: boolean;
    PutChangePassword: boolean;
    PostLoginByEmailAndPassword: boolean;
    PostLoginByUserNameAndPassword: boolean;
    PostLoginByPhoneAndPassword: boolean;
    PutUpdateProfile: boolean;
  };
}
export const saveJSWidgetsOptionalCaptcha = (data: OptionalCaptcha) => {
  return {
    payload: { ...data },
    type: JSWidgetsConst.UPDATE_OPTIONAL_CAPTCHA
  };
};
export const saveJSWidgetsOptionalCaptchaLoadingAction = () =>
  loadingAction(JSWidgetsConst.UPDATE_OPTIONAL_CAPTCHA_LOADING);

export const saveJSWidgetsOptionalCaptchaErrorAction = (error: string) =>
  errorAction(JSWidgetsConst.UPDATE_OPTIONAL_CAPTCHA_ERROR, error);

export const saveJSWidgetsOptionalCaptchaSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: JSWidgetsConst.UPDATE_OPTIONAL_CAPTCHA_SUCCESS,
    payload
  };
};
export interface CustomFieldSchema {
  Key: string;
  Display: string;
}
export interface GetCustomFieldSchema extends SideEffectSchema {
  Data: CustomFieldSchema[];
}

export interface RegistarationFieldSchema {
  IsMandatory?: boolean;
  Enabled: boolean;
  Type: string;
  Name: string;
  Display: string;
  Rules: string;
  Parent: string;
  Checked?: boolean;
  Options?: any;
  Permission?: any;
  DataSource?: any;
  ParentDataSource?: any;
}

export interface GetRegistarationFieldSchema extends SideEffectSchema {
  Data: RegistarationFieldSchema[];
}
export const CustomRegistrationField = {
  GET_CUSTOM_FIELD: "GET_CUSTOM_FIELD",
  GET_CUSTOM_FIELD_LOADING: "GET_CUSTOM_FIELD_LOADING",
  GET_CUSTOM_FIELD_ERROR: "GET_CUSTOM_FIELD_ERROR",
  GET_CUSTOM_FIELD_SUCCESS: "GET_CUSTOM_FIELD_SUCCESS",

  GET_REGISTRATION_FORMS_FIELD: "GET_REGISTRATION_FORMS_FIELD",
  GET_REGISTRATION_FORMS_FIELD_LOADING: "GET_REGISTRATION_FORMS_FIELD_LOADING",
  GET_REGISTRATION_FORMS_FIELD_ERROR: "GET_REGISTRATION_FORMS_FIELD_ERROR",
  GET_REGISTRATION_FORMS_FIELD_SUCCESS: "GET_REGISTRATION_FORMS_FIELD_SUCCESS",

  GET_PLATFORM_FIELD: "GET_PLATFORM_FIELD",
  GET_PLATFORM_FIELD_LOADING: "GET_PLATFORM_FIELD_LOADING",
  GET_PLATFORM_FIELD_ERROR: "GET_PLATFORM_FIELD_ERROR",
  GET_PLATFORM_FIELD_SUCCESS: "GET_PLATFORM_FIELD_SUCCESS",

  POST_PLATFORM_FIELD: "POST_PLATFORM_FIELD",
  POST_PLATFORM_FIELD_LOADING: "POST_PLATFORM_FIELD_LOADING",
  POST_PLATFORM_FIELD_ERROR: "POST_PLATFORM_FIELD_ERROR",
  POST_PLATFORM_FIELD_SUCCESS: "POST_PLATFORM_FIELD_SUCCESS",

  UPDATE_DATASCHEMA_FIELDS_DATA: "UPDATE_DATASCHEMA_FIELDS_DATA",
  UPDATE_DATASCHEMA_FIELDS_DATA_SUCCESS: "UPDATE_DATASCHEMA_FIELDS_DATA_SUCCESS"
};
// update props data
export const updatePropsDataSchemaAction = (
  platFormFields: any,
  registrationDataFields: any,
  customFilesds: any
) => {
  return {
    payload: {
      platFormFields: platFormFields,
      registrationDataFields: registrationDataFields,
      customFilesds: customFilesds
    },
    type: CustomRegistrationField.UPDATE_DATASCHEMA_FIELDS_DATA
  };
};
export const updatePropsDataSchemaSuccessAction = (
  platFormFields: any,
  registrationDataFields: any,
  customFilesds: any
) => {
  const payload: any = {
    platFormFields,
    registrationDataFields,
    customFilesds,
    ...successSideEffectState
  };
  return {
    type: CustomRegistrationField.UPDATE_DATASCHEMA_FIELDS_DATA_SUCCESS,
    payload
  };
};

// Custom Field
export const getCustomFieldAction = () => {
  return {
    payload: {},
    type: CustomRegistrationField.GET_CUSTOM_FIELD
  };
};
export const getCustomFieldLoadingAction = () =>
  loadingAction(CustomRegistrationField.GET_CUSTOM_FIELD_LOADING);

export const getCustomFieldErrorAction = (error: string) =>
  errorAction(CustomRegistrationField.GET_CUSTOM_FIELD_ERROR, error);

export const getCustomFieldSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomRegistrationField.GET_CUSTOM_FIELD_SUCCESS,
    payload
  };
};
//Registration Field
export const getRegistrationFieldAction = () => {
  return {
    payload: {},
    type: CustomRegistrationField.GET_REGISTRATION_FORMS_FIELD
  };
};
export const getRegistrationFieldLoadingAction = () =>
  loadingAction(CustomRegistrationField.GET_REGISTRATION_FORMS_FIELD_LOADING);

export const getRegistrationFieldErrorAction = (error: string) =>
  errorAction(
    CustomRegistrationField.GET_REGISTRATION_FORMS_FIELD_ERROR,
    error
  );

export const getRegistrationFieldSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomRegistrationField.GET_REGISTRATION_FORMS_FIELD_SUCCESS,
    payload
  };
};
//Post PlatForm Field
export const postPlatformFieldAction = (data: any) => {
  return {
    payload: { data },
    type: CustomRegistrationField.POST_PLATFORM_FIELD
  };
};
export const postPlatformFieldLoadingAction = () =>
  loadingAction(CustomRegistrationField.POST_PLATFORM_FIELD_LOADING);

export const postPlatformFieldErrorAction = (error: string) =>
  errorAction(CustomRegistrationField.POST_PLATFORM_FIELD_ERROR, error);

export const postPlatformSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomRegistrationField.POST_PLATFORM_FIELD_SUCCESS,
    payload
  };
};
//PlatForm Field
export const getPlatformFieldAction = () => {
  return {
    payload: {},
    type: CustomRegistrationField.GET_PLATFORM_FIELD
  };
};
export const getPlatformFieldLoadingAction = () =>
  loadingAction(CustomRegistrationField.GET_PLATFORM_FIELD_LOADING);

export const getPlatformFieldErrorAction = (error: string) =>
  errorAction(CustomRegistrationField.GET_PLATFORM_FIELD_ERROR, error);

export const getPlatformSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: CustomRegistrationField.GET_PLATFORM_FIELD_SUCCESS,
    payload
  };
};

//IEF
export interface IdentityExperienceFrameworkCustomizeStateSchema {
  activeItem: string;
}

export const IEFConst = {
  SET_IEF_STATE: "SET_IEF_STATE",
  SET_EDIT_CODE_STATE: "SET_EDIT_CODE_STATE",
  SET_BASIC_EDITOR_STATE: "SET_BASIC_EDITOR_STATE",
  SET_LOGIN_STATE: "SET_LOGIN_STATE",
  SET_PROFILE_STATE: "SET_PROFILE_STATE",
  SET_FORGOT_PASSWORD_STATE: "SET_FORGOT_PASSWORD_STATE",
  SET_REGISTER_STATE: "SET_REGISTER_STATE",
  SET_THEME_STATE: "SET_THEME_STATE"
};

export const setIEFCustomizeStateAction = (
  data: IdentityExperienceFrameworkCustomizeStateSchema
) => {
  return {
    type: IEFConst.SET_IEF_STATE,
    payload: data
  };
};

export enum PageType {
  Auth = 0,
  Profile = 1
}
export enum FileType {
  HTML = 0,
  BeforeScript = 1,
  CommonScript = 2,
  EndScript = 3,
  CustomCss = 4,
  CustomJS = 5,
  HeadTags = 6,
  Favicon = 7,
  JQueryScript = 8
}
export enum ActionType {
  None = 0,
  Reset = 1,
  Delete = 2,
  Save = 3,
  Hosting = 4
}

export interface EditCodeStateSchema {
  editorBodyText: string;
  isLoading?: boolean;
  activeItem: string;
  authCollapseID: string;
  profileCollapseID: string;
  pageName: string;
  pageType: PageType;
  activeFileType: FileType;
  prevPageType: PageType;
  prevActiveFileType: FileType;
  isAddAction: boolean;
  isReplaceAction: boolean;
  isConfirmAction: boolean;
  confirmationAction: ActionType;
  confirmationMessage: string;
  activeFileTree: string;
  activeTheme?: string;
  isEditorLoading?: boolean;
  hostedPageUpdate: string;
  icon?: string;
  activeFileTreeIndex: number;
  newFileName: string;
  modalError: string;
  modalWarning: string;
  authCustomCssCollapse: boolean;
  authCustomJsCollapse: boolean;
  authHeadTagsCollapse: boolean;
  profileCustomCssCollapse: boolean;
  profileCustomJsCollapse: boolean;
  profileHeadTagsCollapse: boolean;
}

export const setEditCodeStateAction = (data: EditCodeStateSchema) => {
  return {
    type: IEFConst.SET_EDIT_CODE_STATE,
    payload: data
  };
};

export interface SelfHostedInterfaceOptions {
  language?: string;
  singlepagestyle?: boolean;
  singlepanel?: boolean;
  redirecturl?: BasicEditorRedirectUrl;
  logo?: BasicEditorLogo;
  body?: BasicEditorBody;
  content?: BasicEditorContent;
  input?: BasicEditorInput;
  submitButton?: BasicEditorSubmitButton;
  socialsquarestyle?: boolean;
  workflows?: string[];
}

export interface BasicEditorRedirectUrl {
  afterlogin?: string;
  afterreset?: string;
}

export interface BasicEditorLogo {
  url?: string;
  color?: string;
}

export interface BasicEditorBody {
  backgroundImage?: string;
  backgroundColor?: string;
  textColor?: string;
  fontFamily?: string;
}

export interface BasicEditorContent {
  forgotPWgreet?: string;
  socialandloginDivider?: string;
  socialblockLabel?: string;
  signupandForgotPwrequest?: string;
  signupandForgotPwrequestPhone?: string;
  emailVerifiedMessage?: string;
  signupLink?: string;
}

export interface BasicEditorInput {
  background?: string;
}

export interface BasicEditorSubmitButton {
  backgroundColor?: string;
  textColor?: string;
}

export interface BasicEditorStateSchema {
  language?: string;
  singlePageStyle?: boolean;
  singlePanel?: boolean;
  socialSquareStyle?: boolean;
  showLogin?: boolean;
  showSignUp?: boolean;
  showForgotPassword?: boolean;
  logoUrl?: string;
  logoColor?: string;
  contentForgotPwMessage?: string;
  contentSocialLoginDivider?: string;
  contentSocialBlockLabel?: string;
  contentSignUpAndForgotPwRequest?: string;
  contentSignUpAndForgotPwRequestPhone?: string;
  contentEmailVerifiedMessage?: string;
  contentSignUpLink?: string;
  redirectUrlAfterLogin?: string;
  redirectUrlAfterReset?: string;
  bodyBackgroundImage?: string;
  bodyBackgroundColor?: string;
  bodyTextColor?: string;
  bodyFontFamily?: string;
  inputBackground?: string;
  submitButtonBackgroundColor?: string;
  submitButtonTextColor?: string;
  isLoading: boolean;
  isSaved: boolean;
  isReset: boolean;
}

export interface ThemeStateSchema {
  activeItem: string;
  collapseID: string;
  activeThemeId: string;
  isOverwritePopup: boolean;
  isSave: boolean;
  isAuthSaved: boolean;
  isProfileSaved: boolean;
  isLoading: boolean;
  selectedLanguage: string;
  selectedFont: string;
  isCustomEditor: boolean;
  isResetEditor: boolean;
}

export const setBasicEditorStateAction = (data: BasicEditorStateSchema) => {
  return {
    type: IEFConst.SET_BASIC_EDITOR_STATE,
    payload: data
  };
};

export const setThemeStateAction = (data: ThemeStateSchema) => {
  return {
    type: IEFConst.SET_THEME_STATE,
    payload: data
  };
};

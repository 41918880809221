import axios from "axios";
import config from "../config";
import { localAppStore } from "../utils";

const { apiEndpoint } = config;

interface QuerySchema {
  [key: string]: string;
}

export interface HttpServiceSchema {
  httpGet: (path: string, query: QuerySchema) => Promise<any>;
  httpDelete: (path: string, query: QuerySchema, payload: any) => Promise<any>;
  httpPost: (path: string, query: QuerySchema, payload: any) => Promise<any>;
  httpPut: (path: string, query: QuerySchema, payload: any) => Promise<any>;
}

/**
 * This function returns an encoded URL using the path and query supplied in the arguments
 * @param {string} path the path to microservice endpoint
 * @param {QuerySchema} query optional query containing params in key value pairs
 * @returns {object} encodedURL, headers
 */
interface GetEncodedURLReturnSchema {
  url: string;
  headers: {
    "x-is-loginradius--sign": string | null;
    "x-is-loginradius--token": string | null;
    "x-is-loginradius-ajax": boolean;
    [key: string]: any;
  };
}
async function getEncodedURL(
  path: string,
  query: QuerySchema
): Promise<GetEncodedURLReturnSchema> {
  let encodedURL = `${apiEndpoint}${path}`;

  let urlEncodedQuery = "?";
  let mailazyHeader = query["MailazyHeader"];
  delete query["MailazyHeader"];
  for (let key in query) {
    urlEncodedQuery += key + "=" + encodeURIComponent(query[key]) + "&";
  }

  const sign: string = (await localAppStore.getItem("lr-x-sign")) as string;
  const token: string = (await localAppStore.getItem("lr-x-token")) as string;

  const getHeaders = mailazyHeader => {
    return {
      "x-is-loginradius--sign": sign,
      "x-is-loginradius--token": token,
      "x-is-loginradius-ajax": true,
      "Content-Type": "application/json",
      ...mailazyHeader
    };
  };
  const headers = getHeaders(mailazyHeader);

  // If sign or token is invalid for some reason, force user to re-login
  if (!sign || sign === "undefined" || !token || token === "undefined") {
    delete headers["x-is-loginradius--sign"];
    delete headers["x-is-loginradius--token"];
    localStorage.clear();
    localAppStore.clear();
  }

  let url = encodedURL + urlEncodedQuery;
  return { url, headers };
}

const handleResponseHaveEmptyToken = response => {
  if (
    (response.xtoken === "" || response.xsign === "") &&
    location.pathname !== "/logout" &&
    location.pathname !== "/login"
  ) {
    location.href = "/logout";
  }
  return response;
};

const handleErrorResp = (err: any) => {
  return err.response && err.response.data
    ? Promise.reject(err.response.data)
    : Promise.reject(err);
};

/**
 * This function makes a HTTP GET request using the args provided
 * @param {string} path the path to the microservice endpoint
 * @param {QuerySchema} query optional query containing params in keyvalue pairs
 * @returns {Promise} promise containing response data or errors
 */
export const httpGet = async (path: string, query = {}) => {
  const { url, headers } = await getEncodedURL(path, query);
  return axios
    .get(url, { headers })
    .then(response => handleResponseHaveEmptyToken(response.data))
    .catch(err => handleErrorResp(err));
};

/**
 * This function makes a HTTP POST request using the args provided
 * @param {string} path the path to the microservice endpoint
 * @param {QuerySchema} query optional query containing params in keyvalue pairs
 * @param {QuerySchema} payload optional body containing the request payload
 * @returns {Promise} promise containing response data or errors
 */
export const httpPost = async (path: string, query = {}, payload = {}) => {
  const { url, headers } = await getEncodedURL(path, query);
  return axios
    .post(url, payload, { headers })
    .then(response => handleResponseHaveEmptyToken(response.data))
    .catch(err => handleErrorResp(err));
};

/**
 * This function makes a HTTP PUT request using the args provided
 * @param path the path to the microservice endpoint
 * @param query optional query containing params in keyvalue pairs
 * @param payload optional body containing the request payload
 * @returns promise containing response data or errors
 */
export const httpPut = async (path: string, query = {}, payload = {}) => {
  const { url, headers } = await getEncodedURL(path, query);
  return axios
    .put(url, payload, { headers })
    .then(response => handleResponseHaveEmptyToken(response.data))
    .catch(err => handleErrorResp(err));
};

/**
 * This function makes a HTTP DELETE request using the args provided
 * @param {string} path the path to the microservice endpoint
 * @param {QuerySchema} query optional query containing params in keyvalue pairs
 * @param {QuerySchema} payload optional payload containing data in keyvalue pairs;
 *   although not customary, some of our endpoints for DELETE requests expect a payload.
 * @returns {Promise} containing response data or errors
 */
export const httpDelete = async (path: string, query = {}, payload = {}) => {
  const { url, headers } = await getEncodedURL(path, query);
  return axios
    .delete(url, { data: payload, headers })
    .then(response => handleResponseHaveEmptyToken(response.data))
    .catch(err => handleErrorResp(err));
};

import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { BrowserRouter, Link } from "react-router-dom";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Image from "../../components/Image";
import step1Vector from "../../assets/image/step1-vector.svg";
import googleAuthStepThree from "../../assets/image/verification-code.svg";
import congratulation from "../../assets/image/congratulation.svg";
import addIcon from "../../assets/image/add-icon.svg";
/* eslint-disable @typescript-eslint/camelcase */
// AmChart Theme and License
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_material from "@amcharts/amcharts4/themes/material";
am4core.useTheme(am4themes_material);
am4core.options.commercialLicense = true;
// LocalForage
import { localAppStore, camelCaseToWords } from "../../utils";
// Assets
import "../../assets/scss/App.scss";
// Components
import BranchTag from "../../components/BranchTag";
import CenterDiv from "../../components/CenterDiv";
import Loader from "../../components/Loader";
// Redux
import { LanguageSchema } from "../../i18n/index";
import {
  GetAccountThemeCustomizationSchema,
  GetRequestAccount2FADataSchema,
  getRequestUpgradeAccount2FAAction,
  postRequestAccountGoogle2FAAction,
  postRequestUpgradeAccountGoogle2FAAction
} from "../../redux/actions/accountActions";
import { changeNetworkAction } from "../../redux/actions/appStateActions";
import {
  AppBasicInfoSchema,
  AuthConfigSchema,
  GetIntercomUserDataSchema,
  GetSharedSitesSchema,
  GetYourSitesSchema,
  initAppBasicInfoAction,
  initAuthConfigAction,
  AppFeatureSchema
} from "../../redux/actions/authActions";
import { RootSchema } from "../../redux/reducers";
import Routes from "../../routes";
import Breadcrumb from "../Breadcrumb";
// Containers
import Footer from "../Footer";
import Intercom from "../Intercom";
import Navigation from "../Navigation";
import Toaster, { Toastify } from "./../../components/Toaster";
import Popup from "../../components/Popup";
import Anchor from "../../components/Anchor";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Placeholder from "semantic-ui-react/dist/commonjs/elements/Placeholder";

export interface AppProps {
  showLoading: boolean;
  loadingMessage: string;
  appInitialized: boolean;
  appInitFailed: boolean;
  accountThemeCustomization: GetAccountThemeCustomizationSchema;
  accountOwnedSites: GetYourSitesSchema;
  accountSharedSites: GetSharedSitesSchema;
  isNetworkConnected: boolean;
  intercomUserData: GetIntercomUserDataSchema;
  currentYear: number;
  initAuthConfig: () => void;
  initAppBasicInfo: () => void;
  changeNetworkAction: (status: boolean) => void;
  appBasicInfo: AppBasicInfoSchema;
  isAdmin?: string;
  currentAppId?: string;
  allFeatures: AppFeatureSchema;
  isMaintenance?: boolean;
  i18n: LanguageSchema;
  IOLoginUrl: string;
  authConfig: AuthConfigSchema;
  getRequestUpgradeAccount2FA: () => void;
  getRequestUpgradeAccount2FAData: GetRequestAccount2FADataSchema;
  postGoogleMFA: (data: any) => void;
  postGoogle: any;
}
export interface AppState {
  branchTagName: string;
  isBillingStipShow: string;
  remindMeAfterMins: boolean;
  modalGoogleOpen: boolean;
  showMFABubble: boolean;
  addTinRightOut: boolean;
  barcodeHelp: boolean;
  modalSuccessMFA: boolean;
  googleAuthCode: string;
  showUpgradeMfAPopup: boolean;
  googleAuthCodeError: boolean;
}
let timerID: any = 0;
class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      googleAuthCodeError: true,
      showUpgradeMfAPopup: false,
      modalSuccessMFA: false,
      barcodeHelp: false,
      branchTagName: "",
      isBillingStipShow: "show",
      remindMeAfterMins: false,
      modalGoogleOpen: false,
      showMFABubble: false,
      addTinRightOut: false,
      googleAuthCode: ""
    };
  }

  unLoad = e => {
    const { modalGoogleOpen } = this.state;
    if (modalGoogleOpen) {
      e.cancelBubble = true;
      e.preventDefault();

      e.stopImmediatePropagation();
      e.returnValue = "leave";
    }
  };

  componentDidMount() {
    const { appInitialized, appInitFailed, authConfig } = this.props;
    const { initAuthConfig, changeNetworkAction } = this.props;
    const { appBasicInfo, initAppBasicInfo } = this.props;
    if (!appInitialized && !appInitFailed) {
      initAuthConfig();
    } else if (authConfig.isSuccess) {
      this.getCSS();
      this.showBranchTag(authConfig.appName);
    }
    window.onoffline = () => {
      changeNetworkAction(false);
    };
    window.addEventListener("beforeunload", this.unLoad);
    window.ononline = () => {
      changeNetworkAction(true);
    };
  }
  getUserName() {
    const { intercomUserData, i18n } = this.props;
    if (
      intercomUserData.isSuccess &&
      intercomUserData.response &&
      intercomUserData.response.name !== undefined
    ) {
      return intercomUserData.response.name;
    } else if (intercomUserData.isLoading) {
      return i18n.PLEASE_WAIT;
    }
    return i18n.USER_NAV_DROPDOWN_NAME_UNAVAILABLE;
  }

  getEmailID() {
    const { intercomUserData, i18n } = this.props;
    if (
      intercomUserData.isSuccess &&
      intercomUserData.response &&
      intercomUserData.response.email !== undefined
    ) {
      return intercomUserData.response.email;
    } else if (intercomUserData.isLoading) {
      return i18n.PLEASE_WAIT;
    }
    return i18n.USER_NAV_DROPDOWN_EMAIL_UNAVAILABLE;
  }

  getCSS = async () => {
    const cssJSON: any = await localAppStore.getItem("global-root-css");
    localStorage.removeItem("logoPath");
    if (cssJSON) {
      if (
        cssJSON.appName === localStorage.getItem("siteName") &&
        document.getElementById("global-root-css")
      ) {
        if (cssJSON["logoPath"] && cssJSON["logoPath"] !== "") {
          localStorage.setItem("logoPath", cssJSON["logoPath"]);
        }
        ReactDOM.render(
          <style type="text/css">{cssJSON["css"]}</style>,
          document.getElementById("global-root-css")
        );
      }
    }
  };

  showBranchTag = (appName: string) => {
    let siteName = localStorage.getItem("siteName") || "";
    this.setState({
      branchTagName: "Production"
    });
    switch (appName) {
      case "demotesting":
        this.setState({
          branchTagName: "Development"
        });
        break;
      case "stagingsecure":
        this.setState({
          branchTagName: "Staging"
        });
        break;
      default:
        if (
          siteName.indexOf("dev-") !== -1 ||
          siteName.lastIndexOf("-dev") !== -1
        ) {
          this.setState({
            branchTagName: "Development"
          });
        } else if (
          siteName.indexOf("stag-") !== -1 ||
          siteName.lastIndexOf("-stag") !== -1 ||
          siteName.lastIndexOf("-staging") !== -1 ||
          siteName.indexOf("staging-") !== -1
        ) {
          this.setState({
            branchTagName: "Staging"
          });
        }
    }
  };
  componentDidUpdate(prevProps: AppProps) {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    const {
      intercomUserData,
      appBasicInfo,
      accountThemeCustomization,
      authConfig,
      postGoogle,
      getRequestUpgradeAccount2FAData
    } = this.props;
    const { intercomUserData: pIntercomUserData } = prevProps;
    if (
      prevProps.getRequestUpgradeAccount2FAData.isLoading &&
      !getRequestUpgradeAccount2FAData.isLoading
    ) {
      if (getRequestUpgradeAccount2FAData.isError) {
        const timer = () =>
          setTimeout(
            () =>
              this.setState({
                remindMeAfterMins: true,
                showMFABubble: false,
                addTinRightOut: false
              }),
            10000
          );
        timerID = timer();
        Toastify("error", "Something went wrong. Please try again.");
      }
    }
    if (prevProps.authConfig.isLoading && !authConfig.isLoading) {
      if (authConfig.isSuccess) {
        this.getCSS();
        this.showBranchTag(authConfig.appName);
      }
    }

    if (prevProps.appBasicInfo.isLoading && !appBasicInfo.isLoading) {
      if (
        appBasicInfo.IsDashboardCustomizationEnabled &&
        !accountThemeCustomization.Css
      ) {
        let collectionCSSArray = [
          { key: "global-body", content: "" },
          { key: "global-theme", content: "" },
          { key: "global-container", content: "" },
          { key: "global-table", content: "" },
          { key: "global-btn", content: "" },
          { key: "btn.btn-primary.global-btn", content: "" },
          { key: "global-accordion", content: "" },
          { key: "btn.btn-primary.global-btn-with-icon", content: "" },
          { key: "btn.btn-ghost.global-btn", content: "" },
          { key: "global-login-menu-bar", content: "" },
          { key: "global-breadcrumb", content: "" },
          { key: "global-container .global-sidebar", content: "" },
          { key: "global-sidebar-advance", content: "" },
          { key: "global-footer", content: "" },
          { key: "btn.btn-secondary.global-btn-with-icon", content: "" },
          { key: "global-checkbox", content: "" },
          { key: "global-tab", content: "" },
          { key: "logo", content: "" }
        ];
        let CurrentCss = "";
        for (let i = 0; i < collectionCSSArray.length; i++) {
          CurrentCss = (
            CurrentCss +
            localStorage.getItem(
              "global-css-apply-" + collectionCSSArray[i].key
            )
          ).replace("null", "");
        }
        ReactDOM.render(
          <style type="text/css">{CurrentCss}</style>,
          document.getElementById("global-root-current-css")
        );
      }
    }
    // ChurnZero setup App key
    if (
      isLoggedIn &&
      pIntercomUserData.isLoading &&
      intercomUserData.isSuccess &&
      authConfig.churnzeroAppKey
    ) {
      if (
        intercomUserData.response &&
        intercomUserData.response.authVersion === "1.0" &&
        !this.state.showUpgradeMfAPopup
      ) {
        this.setState({ showUpgradeMfAPopup: true });
      }
      if (
        intercomUserData.response &&
        intercomUserData.response.recurlyId &&
        intercomUserData.response.email
      ) {
        let email = intercomUserData.response.email;
        let emailDomain = email.split("@")[1];
        if (
          emailDomain !== "loginradius.com" &&
          emailDomain !== "nyaconcepts.com"
        ) {
          window.ChurnZero.push(["setAppKey", authConfig.churnzeroAppKey]);
          window.ChurnZero.push([
            "setContact",
            intercomUserData.response.recurlyId,
            intercomUserData.response.email
          ]);
        }
      }
    }

    if (prevProps.postGoogle.isLoading && !postGoogle.isLoading) {
      if (postGoogle.isSuccess) {
        this.setState({
          modalSuccessMFA: true,
          remindMeAfterMins: false,
          showMFABubble: false,
          modalGoogleOpen: false
        });
      } else if (postGoogle.isError) {
        Toastify(
          "error",
          postGoogle.error || "Something went wrong. Please try again."
        );
      }
    }
    if (this.state.modalGoogleOpen) {
      window.document.body.style.overflow = "hidden";
    }
  }

  _closePan = () => {
    this.setState({
      isBillingStipShow: "hide"
    });
  };
  _renderGAModal = () => {
    const { i18n, getRequestUpgradeAccount2FAData, postGoogle } = this.props;
    const { googleAuthCodeError } = this.state;
    return (
      <Popup
        isOpen={this.state.modalGoogleOpen}
        className="mfa_google_auth_popup"
        body={
          <React.Fragment>
            <div>
              <div className="alert alert-warning w-100" role="alert">
                <strong>Important:</strong> {i18n.MFA_RESET_WARNING}
              </div>
              <p>
                {i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_HEADER_PART_1}
                <b>{i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_HEADER_PART_2}</b>
                {i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_HEADER_PART_3}
              </p>
              {!getRequestUpgradeAccount2FAData.isLoading &&
              !postGoogle.isLoading ? (
                <div className="mfa-steps-block">
                  <div className="mfa-steps d-flex align-items-start step2">
                    <div className="mfa-steps-icon">
                      <Image
                        className="media-object "
                        src={getRequestUpgradeAccount2FAData.QRCode}
                        alt={
                          i18n.ACCOUNT_SECURITY_GOOGLE_AUTHENTICATOR_APP_STEP_2_IMAGE_ALTERNATIVE
                        }
                      />
                    </div>
                    <div className="mfa-steps-description">
                      <p>{i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_ONE_TITLE}</p>
                      <h2 className="mb-3 page-sub-heading">
                        {i18n.SETUP_GOOGLE_AUTH}
                      </h2>
                      <p>
                        {i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_TWO_DESCRIPTION}
                      </p>
                      <ol>
                        <li>
                          {i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_TWO_STEPONE_PRE}
                          <img width={24} height={24} src={addIcon} alt="" />
                          {i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_TWO_STEPONE_SUF}
                        </li>
                        <li>
                          {i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_TWO_STEPTWO}
                        </li>
                      </ol>
                      {this.state.barcodeHelp && (
                        <React.Fragment>
                          <div className="barcode-wrap">
                            <p>
                              {
                                i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_TWO_SOLUTION_ONE
                              }
                            </p>
                            <div className="barcode d-flex align-items-center">
                              <code>
                                {
                                  getRequestUpgradeAccount2FAData.ManualEntryCode
                                }
                              </code>
                              <CopyToClipboard
                                text={
                                  getRequestUpgradeAccount2FAData.ManualEntryCode
                                }
                                onCopy={() => {
                                  Toastify("success", "Copied to Clipboard");
                                }}
                              >
                                <div className="copy-btn">
                                  <button className="btn btn-primary small lricon_copy-outline" />
                                </div>
                              </CopyToClipboard>
                            </div>
                            <p className="mt-2">
                              {
                                i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_TWO_SOLUTION_TWO
                              }
                            </p>
                          </div>
                        </React.Fragment>
                      )}
                      <div className="mt-3">
                        <Link
                          className="d-inline show-barcode"
                          to={"#"}
                          id="btn_toggleBarcodeHelp"
                          onClick={() => {
                            this.setState({
                              barcodeHelp: !this.state.barcodeHelp
                            });
                          }}
                        >
                          {i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_TWO_TROUBLESHOOT}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="mfa-steps d-flex align-items-start step3">
                    <div className="mfa-steps-icon">
                      <Image
                        className="media-object google-modal-images ml-3"
                        src={googleAuthStepThree}
                        alt={
                          i18n.ACCOUNT_SECURITY_GOOGLE_AUTHENTICATOR_APP_STEP_3_IMAGE_ALTERNATIVE
                        }
                      />
                    </div>
                    <div className="mfa-steps-description">
                      <p>{i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_TWO_TITLE}</p>
                      <h2 className="mb-3 page-sub-heading">
                        {i18n.ACCOUNT_SECURITY_ENTER_VERIFICATION_CODE}
                      </h2>
                      <p>
                        {i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_THREE_DESCRIPTION}
                      </p>
                      <div className="d-flex">
                        <Input
                          placeholder="Enter verification code"
                          label={i18n.MFA_VERIFICATION_CODE}
                          outerContainerClass="col-6 "
                          containerClass="mt-0"
                          type="number"
                          name="googleAuthCode"
                          value={this.state.googleAuthCode}
                          error={googleAuthCodeError ? "Code is required" : ""}
                          onChange={e => {
                            let error = false;
                            let _val = e.target.value.trim();
                            if (!_val) {
                              error = true;
                            }
                            this.setState({
                              googleAuthCode: _val,
                              googleAuthCodeError: error
                            });
                          }}
                          onBlur={e => {
                            let error = false;
                            let _val = e.target.value.trim();
                            if (!_val) {
                              error = true;
                            }
                            this.setState({ googleAuthCodeError: error });
                          }}
                          required
                        />
                        <div className="col-6 verify-btn">
                          <Button
                            color="primary"
                            disabled={googleAuthCodeError}
                            value={i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_CONFIRM}
                            id="btn_enableGoogleMFA"
                            onClick={() => {
                              this.props.postGoogleMFA({
                                googleCode: this.state.googleAuthCode
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row w-100">
                  <div className="col-sm-12 mb-3">
                    <Placeholder>
                      <Placeholder.Image />
                    </Placeholder>
                  </div>
                  <div className="col-sm-12">
                    <Placeholder>
                      <Placeholder.Image />
                    </Placeholder>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        }
        title={i18n.UPGRADE_MFA_TITLE}
        size="lg"
        toggle={() => {}}
        // buttons={}
      />
    );
  };
  _renderSuccessModal = () => {
    return (
      <Popup
        isOpen={this.state.modalSuccessMFA}
        body={
          <React.Fragment>
            <div className="mfa-congratulation-popup">
              <div className="congratulation-image">
                <Image
                  className="d-block ml-auto mr-auto mt-4"
                  src={congratulation}
                  alt={"congratulations"}
                />
              </div>
              {/* <h2>Great 🎉</h2> */}
              <h2>Great, your account MFA is up-to-date now. </h2>
              <Link
                to="#"
                onClick={() => {
                  this.setState({ modalSuccessMFA: false });
                }}
              >
                Take me to the Admin Console
              </Link>
            </div>
          </React.Fragment>
        }
        // title={i18n.ACCOUNT_SECURITY_GOOGLE_MODAL_TITLE}
        size="lg"
        toggle={() => {}}
      />
    );
  };
  render() {
    const {
      remindMeAfterMins,
      showMFABubble,
      addTinRightOut,
      showUpgradeMfAPopup
    } = this.state;
    const { appInitialized, appInitFailed, IOLoginUrl } = this.props;
    const { i18n, showLoading, loadingMessage } = this.props;
    const { accountOwnedSites, accountSharedSites } = this.props;
    const {
      appBasicInfo,
      isAdmin,
      currentAppId,
      intercomUserData
    } = this.props;

    const {
      isMaintenance,
      isNetworkConnected,
      currentYear,
      allFeatures
    } = this.props;
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    const isDashboardShow = localStorage.getItem("lr-x-dashboard") === "true";
    const { isBillingStipShow } = this.state;
    const planName =
      appBasicInfo.plan_detail && appBasicInfo.plan_detail.displayName;
    let planExpiryDays = 0;
    if (planName) {
      if (appBasicInfo.plan_detail.expiry) {
        planExpiryDays = moment(appBasicInfo.plan_detail.expiry).diff(
          moment(),
          "days"
        );
        if (planExpiryDays < 0) {
          planExpiryDays = 0;
        }
      }
    }
    if (appInitialized === false) {
      if (!appInitFailed) {
        return !appInitFailed ? (
          <div className="main-loader-wrapper">
            <Loader />;
          </div>
        ) : (
          <CenterDiv>
            <span>App initialization failed. Please check your network.</span>
          </CenterDiv>
        );
      }
    } else if (showLoading) {
      return (
        <div className="lr-model-backdrop-wrap">
          <CenterDiv>
            <Loader message={loadingMessage || "Loading..."} />;
          </CenterDiv>
        </div>
      );
    } else {
      let navigationItems = appBasicInfo.navigationItems;
      if (!isAdmin && navigationItems) {
        navigationItems = navigationItems.filter(item => !item.isAdmin);
      } else if (isAdmin && !currentAppId && !navigationItems) {
        navigationItems = [];
      }
      let userName: string = this.getUserName();
      let userEmailID: string = this.getEmailID();
      //let closePlan = window.closePlan;
      let tinRightOut = addTinRightOut ? "tinRightOut" : "";
      return (
        <BrowserRouter>
          <Toaster />
          {isNetworkConnected === false && (
            <div className="overlay">
              {" "}
              <div className="overlay-text">
                {i18n.PLEASE_CHECK_YOUR_INTERNET_CONNECTION}
              </div>
            </div>
          )}
          <div className="site-header">
            {!isMaintenance && navigationItems ? (
              <React.Fragment>
                {planExpiryDays ? (
                  <div
                    className={
                      "billing-notification lr-plan billing-strip-" +
                      isBillingStipShow
                    }
                  >
                    <div className="billing-container">
                      <b>
                        You have <span>{planExpiryDays + 1}</span> days left in
                        your{" "}
                        <a
                          href="https://www.loginradius.com/pricing"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {planName}
                        </a>{" "}
                        trial plan. Please enter you{" "}
                        <a href="/account/billing">billing information here.</a>
                      </b>
                    </div>
                    <div id="developer_plan" onClick={e => this._closePan()}>
                      ×
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <Navigation
                  brandName="LoginRadius"
                  navigationItems={navigationItems}
                  yourSites={accountOwnedSites.response || []}
                  sharedSites={accountSharedSites.response || []}
                  userName={userName}
                  userEmailID={userEmailID}
                  isSsoOnly={
                    allFeatures["only_sso_login_enabled"] &&
                    appBasicInfo["loginProvider"] &&
                    !(
                      appBasicInfo["loginProvider"] === "" ||
                      appBasicInfo["loginProvider"].includes("saml_")
                    )
                  }
                  updateAppState={path => {
                    if (path) {
                      this.setState({
                        branchTagName: this.state.branchTagName
                      });
                    }
                  }}
                />
                <Breadcrumb />
              </React.Fragment>
            ) : null}
          </div>
          <main id="main-content">
            <div className="site-inner">
              {((isLoggedIn && navigationItems) || !isLoggedIn) && <Routes />}
            </div>
            <Intercom />
            <div className="mfa_popup_wrap">
              {this._renderGAModal()}
              {this._renderSuccessModal()}
              {!isMaintenance && navigationItems && intercomUserData.isSuccess && (
                <Popup
                  className={tinRightOut}
                  isOpen={
                    (remindMeAfterMins || showUpgradeMfAPopup) &&
                    location.pathname !== "/account/account-settings/security"
                  }
                  title={i18n.UPGRADE_MFA_TITLE}
                  toggle={() => {
                    this.setState({ addTinRightOut: false });
                  }}
                  size="lg"
                  body={
                    <div className="d-flex step1-popup-body">
                      <div className="description">
                        <div className="mfa_popup_body">
                          {i18n.LETS_START_UPGRADE_MFA}{" "}
                          <b>{i18n.LESS_THAN_MIN}</b>
                          <span className="mfa_popup_sub_heading">
                            {i18n.RECONFIGURE_MFA}
                          </span>
                          <span>{i18n.RECONFIGURE_MFA_DESCIPTION}</span>
                        </div>
                      </div>
                      <div className="image">
                        <img src={step1Vector} />
                      </div>
                    </div>
                  }
                  buttons={[
                    {
                      value: "OK, Let's Do It",
                      color: "primary",
                      onClick: () => {
                        this.props.getRequestUpgradeAccount2FA();
                        this.setState({
                          showUpgradeMfAPopup: false,
                          remindMeAfterMins: false,
                          modalGoogleOpen: true,
                          showMFABubble: false
                        });
                      }
                    },

                    {
                      value: "Remind Me in 10 Min",
                      color: "secondary",
                      onClick: () => {
                        this.setState({ addTinRightOut: true });
                        setTimeout(
                          () =>
                            this.setState({
                              showUpgradeMfAPopup: false,
                              remindMeAfterMins: false,
                              showMFABubble: true
                            }),
                          1000
                        );
                        const timer = () =>
                          setTimeout(
                            () =>
                              this.setState({
                                remindMeAfterMins: true,
                                showMFABubble: false,
                                addTinRightOut: false
                              }),
                            600000
                          );
                        timerID = timer();
                      },
                      disabled: addTinRightOut
                    }
                  ]}
                />
              )}
            </div>
            {isLoggedIn && navigationItems && isDashboardShow && (
              <Footer currentYear={currentYear} />
            )}
            {!IOLoginUrl &&
              isDashboardShow &&
              navigationItems &&
              this.state.branchTagName && (
                <BranchTag branchName={this.state.branchTagName} />
              )}
            {showMFABubble &&
              location.pathname !== "/account/account-settings/security" && (
                <div
                  onClick={() => {
                    this.setState(
                      {
                        showUpgradeMfAPopup: true,
                        remindMeAfterMins: false,
                        showMFABubble: false,
                        addTinRightOut: false
                      },
                      () => {
                        clearTimeout(timerID);
                      }
                    );
                  }}
                  className="mfa_bubble"
                >
                  <svg
                    className="mr-2"
                    width="14"
                    height="14"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.326481 4.12236C-0.108828 3.96129 -0.108828 3.34559 0.326481 3.18451L2.19697 2.49237C2.33383 2.44173 2.44173 2.33382 2.49237 2.19696L3.18452 0.326477C3.3456 -0.108832 3.96129 -0.108832 4.12237 0.326479L4.81451 2.19696C4.86515 2.33382 4.97306 2.44173 5.10992 2.49237L6.9804 3.18451C7.41571 3.34559 7.41571 3.96129 6.9804 4.12236L5.10992 4.81451C4.97306 4.86515 4.86515 4.97306 4.81451 5.10991L4.12237 6.9804C3.96129 7.41571 3.3456 7.41571 3.18452 6.9804L2.49237 5.10991C2.44173 4.97305 2.33383 4.86515 2.19697 4.81451L0.326481 4.12236ZM3.27342 13.569C2.4028 13.2468 2.4028 12.0154 3.27342 11.6932L7.01439 10.309C7.28811 10.2077 7.50392 9.99187 7.60521 9.71815L8.98949 5.97718C9.31165 5.10656 10.543 5.10656 10.8652 5.97718L12.2495 9.71815C12.3508 9.99187 12.5666 10.2077 12.8403 10.309L16.5813 11.6932C17.4519 12.0154 17.4519 13.2468 16.5813 13.569L12.8403 14.9532C12.5666 15.0545 12.3508 15.2703 12.2495 15.5441L10.8652 19.285C10.543 20.1556 9.31165 20.1556 8.98949 19.285L7.60521 15.5441C7.50392 15.2703 7.28811 15.0545 7.01439 14.9532L3.27342 13.569ZM12.9593 3.18451C12.524 3.34559 12.524 3.96128 12.9593 4.12236L14.8298 4.8145C14.9666 4.86514 15.0745 4.97305 15.1252 5.10991L15.8173 6.98039C15.9784 7.4157 16.5941 7.4157 16.7552 6.98039L17.4473 5.10991C17.498 4.97305 17.6059 4.86514 17.7427 4.8145L19.6132 4.12236C20.0485 3.96128 20.0485 3.34559 19.6132 3.18451L17.7427 2.49236C17.6059 2.44172 17.498 2.33382 17.4473 2.19696L16.7552 0.326471C16.5941 -0.108838 15.9784 -0.108838 15.8173 0.326471L15.1252 2.19696C15.0745 2.33382 14.9666 2.44172 14.8298 2.49236L12.9593 3.18451Z"
                      fill="white"
                    />
                  </svg>
                  <span>Upgrade MFA</span>
                </div>
              )}
          </main>
        </BrowserRouter>
      );
    }
  }
}

const mapStateToProps = (state: RootSchema) => {
  return {
    showLoading: state.appState.appUI.showLoading,
    loadingMessage: state.appState.appUI.loadingMessage,
    appInitialized: state.appState.appUI.appInitialized,
    accountThemeCustomization: state.account.accountThemeCustomization,
    appInitFailed: state.auth.authConfig.isError || false,
    accountOwnedSites: state.auth.yourSites || [],
    accountSharedSites: state.auth.sharedSites || [],
    appBasicInfo: state.auth.appBasicInfo,
    isAdmin: state.auth.authConfig.superadminUid,
    isNetworkConnected: state.appState.appUI.networkConnected,
    currentAppId: state.auth.authConfig.currentAppId,
    isMaintenance: state.auth.authConfig.maintenance,
    intercomUserData: state.auth.intercomUserData,
    currentYear: state.auth.authConfig.currentYear || new Date().getFullYear(),
    i18n: state.appState.i18n.languages,
    IOLoginUrl: state.auth.authConfig.IOLoginUrl,
    authConfig: state.auth.authConfig,
    allFeatures: state.auth.appFeatures,
    getRequestUpgradeAccount2FAData: state.account.upgrade2FADetail,
    postGoogle: state.account.AccountPostGoogleUpgrade2FA
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    initAuthConfig: () => dispatch(initAuthConfigAction()),
    initAppBasicInfo: () => dispatch(initAppBasicInfoAction()),
    changeNetworkAction: status => dispatch(changeNetworkAction(status)),
    getRequestUpgradeAccount2FA: () =>
      dispatch(getRequestUpgradeAccount2FAAction()),
    postGoogleMFA: (data: any) =>
      dispatch(postRequestUpgradeAccountGoogle2FAAction(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

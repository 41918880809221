import { take, call, put } from "redux-saga/effects";
import { httpGet, httpPost } from "../../services";

import {
  SupportConst,
  getExistingTicketsLoadingAction,
  getExistingTicketsSuccessAction,
  getExistingTicketsErrorAction,
  getUsersForAppLoadingAction,
  getUsersForAppSuccessAction,
  getUsersForAppErrorAction,
  GetUsersForAppSchema,
  getCustomerDataLoadingAction,
  getCustomerDataSuccessAction,
  getCustomerDataErrorAction,
  GetCustomerDataSchema,
  getFilePickerKeyLoadingAction,
  getFilePickerKeySuccessAction,
  getFilePickerKeyErrorAction,
  GetFilePickerKeySchema,
  sendTicketDataLoadingAction,
  sendTicketDataSuccessAction,
  sendTicketDataErrorAction,
  SendTicketDataSchema
} from "../actions/supportActions";

/**
 * Workers
 */

function* getExistingTicketsHandler() {
  try {
    yield put(getExistingTicketsLoadingAction());
    const response = yield httpGet("/support/tickets");
    yield put(getExistingTicketsSuccessAction({ response }));
  } catch (e) {
    yield put(getExistingTicketsErrorAction(e.message));
  }
}

function* getUsersForAppHandler(data: any) {
  try {
    yield put(getUsersForAppLoadingAction());

    const users: GetUsersForAppSchema = yield httpPost(
      "/support/users",
      {},
      data.payload
    );

    yield put(getUsersForAppSuccessAction({ ...users }));
  } catch (e) {
    yield put(getUsersForAppErrorAction(e.message));
  }
}

function* getCustomerDataHandler() {
  try {
    yield put(getCustomerDataLoadingAction());
    const data: GetCustomerDataSchema = yield httpGet("/support/customer", {});
    yield put(getCustomerDataSuccessAction({ ...data }));
  } catch (e) {
    yield put(getCustomerDataErrorAction(e.message));
  }
}

function* getFilePickerKeyHandler() {
  try {
    yield put(getFilePickerKeyLoadingAction());

    const data: GetFilePickerKeySchema = yield httpGet(
      "/support/credentials",
      {}
    );

    yield put(getFilePickerKeySuccessAction({ ...data }));
  } catch (e) {
    yield put(getFilePickerKeyErrorAction(e.message));
  }
}

function* sendTicketDataHandler(data: SendTicketDataSchema) {
  try {
    yield put(sendTicketDataLoadingAction());
    data.payload.description = data.payload.description
      .split("\n")
      .join("<br />");
    if (data.payload.category === "billing") {
      data.payload.team.push({
        name: "billing",
        checked: true,
        emailid: "billing@loginradius.com",
        isDisable: false
      });
    }
    const users: SendTicketDataSchema = yield httpPost(
      "/support/ticket",
      {},
      data.payload
    );

    yield put(sendTicketDataSuccessAction({ ...users }));
  } catch (e) {
    yield put(sendTicketDataErrorAction(e.message));
  }
}

/**
 * Watchers
 */

export function* watchGetExistingTickets() {
  while (true) {
    yield take(SupportConst.GET_EXISTING_TICKETS);
    yield call(getExistingTicketsHandler);
  }
}

export function* watchGetUsersForApp() {
  while (true) {
    const data = yield take(SupportConst.GET_USERS_FOR_APP);
    yield call(getUsersForAppHandler, data);
  }
}

export function* watchGetCustomerData() {
  while (true) {
    yield take(SupportConst.GET_CUSTOMER_DATA);
    yield call(getCustomerDataHandler);
  }
}

export function* watchGetFilePickerKey() {
  while (true) {
    yield take(SupportConst.GET_FILE_PICKER_KEY);
    yield call(getFilePickerKeyHandler);
  }
}

export function* watchsendTicketData() {
  while (true) {
    const data = yield take(SupportConst.SUBMIT_TICKET);
    yield call(sendTicketDataHandler, data);
  }
}

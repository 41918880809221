import React from "react";
import { Link } from "react-router-dom";
import { removeSymbols } from "../../utils";
import Anchor from "../Anchor";
export interface NavNoDroppdownProps {
  name: string;
  classes: string;
  link: string;
  index: number;
  isShowing: boolean;
  navigationHandler: (index: number) => void;
  handleLinkClick: (name: string) => void;
  currentActive: string;
  handleLinkFocus: (name: string) => void;
  currentFocused: string;
}

const NavNoDroppdown: React.FC<NavNoDroppdownProps> = props => {
  const { classes, name, link, index, navigationHandler } = props;
  return (
    <li className="nav-item">
      {link.startsWith("http") && (
        <Anchor
          id={"lnk_" + removeSymbols(name)}
          target="_blank"
          href={link}
          className="nav-link dropdown-toggle no-underline"
        >
          {name}
        </Anchor>
      )}
      {!link.startsWith("http") && (
        <Link
          id={"lnk_" + removeSymbols(name)}
          className={classes}
          to={link || "#"}
          onClick={() => {
            props.handleLinkClick(name);
            props.handleLinkFocus(name);
            navigationHandler(index);
          }}
        >
          {name}
        </Link>
      )}
    </li>
  );
};

export default NavNoDroppdown;

import { combineReducers } from "redux";
import { ActionSchema } from "../actions";
import { AppFeatureSchema } from "../actions/authActions";
import {
  PlatformConfigurationConst,
  GetApiKeySchema,
  GetGlobalEmailConfigSchema,
  SaveGlobalEmailConfigSchema,
  GetSMSSettingsAndConfigSchema,
  GetSMSTemplatesSchema,
  GetSMSProviderConfigSchema,
  SaveSMSConfigSchema,
  ResetSMSConfigSchema,
  GetGlobalSMSConfigSchema,
  SaveGlobalSMSConfigSchema,
  GetRolesSchema,
  GetConfigurationListSchema,
  TransferConfigurationsSchema,
  GetSocialProviderListSchema,
  GetMultipleSocialProviderSchema,
  MultipleSocialProvider,
  GetAuthServerConfigApiSchema,
  GetCustomSMSProviderConfigSchema,
  SaveCustomSMSConfigSchema,
  GetOAuthProvidersSchema,
  GetJWTProvidersSchema,
  GetSAMLProvidersSchema,
  GetOIDCandOAuthAppsSchema
} from "../actions/platformConfigurationActions";

const getMultipleSocialProviderReducer = (
  state = {},
  action: ActionSchema<GetMultipleSocialProviderSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_MULTIPLE_SOCIAL_PROVIDER_LOADING:
    case PlatformConfigurationConst.GET_MULTIPLE_SOCIAL_PROVIDER_SUCCESS:
    case PlatformConfigurationConst.PUT_MULTIPLE_SOCIAL_PROVIDER_LOADING:
    case PlatformConfigurationConst.PUT_MULTIPLE_SOCIAL_PROVIDER_SUCCESS:
    case PlatformConfigurationConst.POST_MULTIPLE_SOCIAL_PROVIDER_LOADING:
    case PlatformConfigurationConst.POST_MULTIPLE_SOCIAL_PROVIDER_SUCCESS:
    case PlatformConfigurationConst.GET_MULTIPLE_SOCIAL_PROVIDER_ERROR:
      return {
        ...state,
        ...action.payload
      };
    case PlatformConfigurationConst.POST_MULTIPLE_SOCIAL_PROVIDER_ERROR:
    case PlatformConfigurationConst.PUT_MULTIPLE_SOCIAL_PROVIDER_ERROR: {
      const response = {};
      if (action.payload.isError) {
        response["isError"] = action.payload.isError;
        response["isLoading"] = action.payload.isLoading;
        response["isSuccess"] = action.payload.isSuccess;
        response["error"] = action.payload.error;
        response["response"] = state["response"];
        response["updateMethodProp"] = state["updateMethodProp"];
      }
      return {
        ...state,
        ...response
      };
    }
    default:
      return state;
  }
};
// const postMultipleSocialProviderReducer = (
//   state = {},
//   action: ActionSchema<GetMultipleSocialProviderSchema>
// ) => {
//   switch (action.type) {
//     case PlatformConfigurationConst.POST_MULTIPLE_SOCIAL_PROVIDER_LOADING:
//     case PlatformConfigurationConst.POST_MULTIPLE_SOCIAL_PROVIDER_ERROR:
//     case PlatformConfigurationConst.POST_MULTIPLE_SOCIAL_PROVIDER_SUCCESS:
//       return {
//         ...state,
//         ...action.payload
//       };
//     default:
//       return state;
//   }
// };
const deleteMultipleSocialProviderReducer = (
  state = {},
  action: ActionSchema<GetMultipleSocialProviderSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.DELETE_MULTIPLE_SOCIAL_PROVIDER_LOADING:
    case PlatformConfigurationConst.DELETE_MULTIPLE_SOCIAL_PROVIDER_ERROR:
    case PlatformConfigurationConst.DELETE_MULTIPLE_SOCIAL_PROVIDER_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
const getGlobalEmailConfigReducer = (
  state = {},
  action: ActionSchema<GetGlobalEmailConfigSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_GLOBAL_EMAIL_CONFIG_LOADING:
    case PlatformConfigurationConst.GET_GLOBAL_EMAIL_CONFIG_ERROR:
    case PlatformConfigurationConst.GET_GLOBAL_EMAIL_CONFIG_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const getSMSSettingsAndConfigReducer = (
  state = {},
  action: ActionSchema<GetSMSSettingsAndConfigSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_SMS_SETTINGS_AND_CONFIG_SUCCESS:
    case PlatformConfigurationConst.GET_SMS_SETTINGS_AND_CONFIG_LOADING:
    case PlatformConfigurationConst.GET_SMS_SETTINGS_AND_CONFIG_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getSMSTemplatesReducer = (
  state = {},
  action: ActionSchema<GetSMSTemplatesSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_SMS_TEMPLATES_SUCCESS:
    case PlatformConfigurationConst.GET_SMS_TEMPLATES_LOADING:
    case PlatformConfigurationConst.GET_SMS_TEMPLATES_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getSMSProviderConfigReducer = (
  state = {},
  action: ActionSchema<GetSMSProviderConfigSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_SMS_PROVIDER_CONFIG_SUCCESS:
    case PlatformConfigurationConst.GET_SMS_PROVIDER_CONFIG_LOADING:
    case PlatformConfigurationConst.GET_SMS_PROVIDER_CONFIG_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getCustomSMSProviderConfigReducer = (
  state = {},
  action: ActionSchema<GetCustomSMSProviderConfigSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_CUSTOM_SMS_PROVIDER_CONFIG_SUCCESS:
    case PlatformConfigurationConst.GET_CUSTOM_SMS_PROVIDER_CONFIG_LOADING:
    case PlatformConfigurationConst.GET_CUSTOM_SMS_PROVIDER_CONFIG_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const saveGlobalEmailConfigReducer = (
  state = {},
  action: ActionSchema<SaveGlobalEmailConfigSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.SAVE_GLOBAL_EMAIL_CONFIG_LOADING:
    case PlatformConfigurationConst.SAVE_GLOBAL_EMAIL_CONFIG_ERROR:
    case PlatformConfigurationConst.SAVE_GLOBAL_EMAIL_CONFIG_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const saveSMSConfigReducer = (
  state = {},
  action: ActionSchema<SaveSMSConfigSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.SAVE_SMS_CONFIG_SUCCESS:
    case PlatformConfigurationConst.SAVE_SMS_CONFIG_LOADING:
    case PlatformConfigurationConst.SAVE_SMS_CONFIG_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const saveCustomSMSConfigReducer = (
  state = {},
  action: ActionSchema<SaveCustomSMSConfigSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.SAVE_CUSTOM_SMS_CONFIG_SUCCESS:
    case PlatformConfigurationConst.SAVE_CUSTOM_SMS_CONFIG_LOADING:
    case PlatformConfigurationConst.SAVE_CUSTOM_SMS_CONFIG_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const resetSMSConfigReducer = (
  state = {},
  action: ActionSchema<ResetSMSConfigSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.RESET_SMS_CONFIG_SUCCESS:
    case PlatformConfigurationConst.RESET_SMS_CONFIG_LOADING:
    case PlatformConfigurationConst.RESET_SMS_CONFIG_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getApiKeyReducer = (
  state = {},
  action: ActionSchema<GetApiKeySchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_API_KEY_LOADING:
    case PlatformConfigurationConst.GET_API_KEY_ERROR:
    case PlatformConfigurationConst.GET_API_KEY_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const getGlobalSMSSettingsReducer = (
  state = {},
  action: ActionSchema<GetGlobalSMSConfigSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_GLOBAL_SMS_SETTINGS_LOADING:
    case PlatformConfigurationConst.GET_GLOBAL_SMS_SETTINGS_ERROR:
    case PlatformConfigurationConst.GET_GLOBAL_SMS_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const saveGlobalSMSSettingsReducer = (
  state = {},
  action: ActionSchema<SaveGlobalSMSConfigSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.SAVE_GLOBAL_SMS_SETTINGS_LOADING:
    case PlatformConfigurationConst.SAVE_GLOBAL_SMS_SETTINGS_ERROR:
    case PlatformConfigurationConst.SAVE_GLOBAL_SMS_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const getRolesReducer = (state = {}, action: ActionSchema<GetRolesSchema>) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_ROLES_LOADING:
    case PlatformConfigurationConst.GET_ROLES_SUCCESS:
    case PlatformConfigurationConst.GET_ROLES_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getConfigurationListReducer = (
  state = {},
  action: ActionSchema<GetConfigurationListSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_CONFIGURATION_LIST_LOADING:
    case PlatformConfigurationConst.GET_CONFIGURATION_LIST_SUCCESS:
    case PlatformConfigurationConst.GET_CONFIGURATION_LIST_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getSocialProviderListReducer = (
  state = {},
  action: ActionSchema<GetSocialProviderListSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_SOCIALPROVIDER_LIST_LOADING:
    case PlatformConfigurationConst.GET_SOCIALPROVIDER_LIST_SUCCESS:
    case PlatformConfigurationConst.GET_SOCIALPROVIDER_LIST_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const transferConfigurationsReducer = (
  state = {},
  action: ActionSchema<TransferConfigurationsSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.TRANSFER_CONFIGURATIONS_LOADING:
    case PlatformConfigurationConst.TRANSFER_CONFIGURATIONS_SUCCESS:
    case PlatformConfigurationConst.TRANSFER_CONFIGURATIONS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const deleteAuthServerConfigApiReducer = (
  state = {},
  action: ActionSchema<any>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.DELETE_AUTH_SERVER_API_CONFIG_LOADING:
    case PlatformConfigurationConst.DELETE_AUTH_SERVER_API_CONFIG_ERROR:
    case PlatformConfigurationConst.DELETE_AUTH_SERVER_API_CONFIG_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const getAuthServerConfigApiReducer = (
  state = {},
  action: ActionSchema<GetAuthServerConfigApiSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_AUTH_SERVER_API_CONFIG_LOADING:
    case PlatformConfigurationConst.GET_AUTH_SERVER_API_CONFIG_SUCCESS:
    case PlatformConfigurationConst.GET_AUTH_SERVER_API_CONFIG_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const updateAuthServerConfigApiReducer = (
  state = {},
  action: ActionSchema<GetAuthServerConfigApiSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.PUT_AUTH_SERVER_API_CONFIG_SUCCESS:
    case PlatformConfigurationConst.PUT_AUTH_SERVER_API_CONFIG_LOADING:
    case PlatformConfigurationConst.PUT_AUTH_SERVER_API_CONFIG_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const setAuthServerConfigApiReducer = (
  state = {},
  action: ActionSchema<GetAuthServerConfigApiSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.POST_AUTH_SERVER_API_CONFIG_SUCCESS:
    case PlatformConfigurationConst.POST_AUTH_SERVER_API_CONFIG_LOADING:
    case PlatformConfigurationConst.POST_AUTH_SERVER_API_CONFIG_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const setAppFeaturesReducer = (
  state = {},
  action: ActionSchema<AppFeatureSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.SET_APP_FEATURES_SUCCESS:
    case PlatformConfigurationConst.SET_APP_FEATURES_LOADING:
    case PlatformConfigurationConst.SET_APP_FEATURES_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const renewCertificateReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case PlatformConfigurationConst.RENEW_CERTIFICATE_SUCCESS:
    case PlatformConfigurationConst.RENEW_CERTIFICATE_LOADING:
    case PlatformConfigurationConst.RENEW_CERTIFICATE_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getSSOIntegrationReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case PlatformConfigurationConst.SSO_INTEGRATION_SUCCESS:
    case PlatformConfigurationConst.SSO_INTEGRATION_LOADING:
    case PlatformConfigurationConst.SSO_INTEGRATION_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getAvailableSSOIntegrationReducer = (
  state = {},
  action: ActionSchema<any>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.AVAILABLE_SSO_INTEGRATION_SUCCESS:
    case PlatformConfigurationConst.AVAILABLE_SSO_INTEGRATION_LOADING:
    case PlatformConfigurationConst.AVAILABLE_SSO_INTEGRATION_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getUserSamlIntegrationByTypeReducer = (
  state = {},
  action: ActionSchema<any>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.SAML_INTEGRATION_BY_TYPE_SUCCESS:
    case PlatformConfigurationConst.SAML_INTEGRATION_BY_TYPE_LOADING:
    case PlatformConfigurationConst.SAML_INTEGRATION_BY_TYPE_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const postSSOIntegrationReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case PlatformConfigurationConst.POST_SSO_INTEGRATION_SUCCESS:
    case PlatformConfigurationConst.POST_SSO_INTEGRATION_LOADING:
    case PlatformConfigurationConst.POST_SSO_INTEGRATION_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const putSSOIntegrationReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case PlatformConfigurationConst.PUT_SSO_INTEGRATION_SUCCESS:
    case PlatformConfigurationConst.PUT_SSO_INTEGRATION_LOADING:
    case PlatformConfigurationConst.PUT_SSO_INTEGRATION_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const deleteSSOIntegrationReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case PlatformConfigurationConst.DELETE_SSO_INTEGRATION_SUCCESS:
    case PlatformConfigurationConst.DELETE_SSO_INTEGRATION_LOADING:
    case PlatformConfigurationConst.DELETE_SSO_INTEGRATION_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getOAuthProvidersReducer = (
  state = {},
  action: ActionSchema<GetOAuthProvidersSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_OAUTH_PROVIDERS_SUCCESS:
    case PlatformConfigurationConst.GET_OAUTH_PROVIDERS_LOADING:
    case PlatformConfigurationConst.GET_OAUTH_PROVIDERS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getJWTProvidersReducer = (
  state = {},
  action: ActionSchema<GetJWTProvidersSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_JWT_PROVIDERS_SUCCESS:
    case PlatformConfigurationConst.GET_JWT_PROVIDERS_LOADING:
    case PlatformConfigurationConst.GET_JWT_PROVIDERS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getSAMLProvidersReducer = (
  state = {},
  action: ActionSchema<GetSAMLProvidersSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_SAML_PROVIDERS_SUCCESS:
    case PlatformConfigurationConst.GET_SAML_PROVIDERS_LOADING:
    case PlatformConfigurationConst.GET_SAML_PROVIDERS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getOIDCandOAuthAppsReducer = (
  state = {},
  action: ActionSchema<GetOIDCandOAuthAppsSchema>
) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_OIDC_AND_OAUTH_APPS_SUCCESS:
    case PlatformConfigurationConst.GET_OIDC_AND_OAUTH_APPS_LOADING:
    case PlatformConfigurationConst.GET_OIDC_AND_OAUTH_APPS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const getPassKeyConfigReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case PlatformConfigurationConst.GET_PASSKEY_CONFIG_SUCCESS:
    case PlatformConfigurationConst.GET_PASSKEY_CONFIG_LOADING:
    case PlatformConfigurationConst.GET_PASSKEY_CONFIG_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const updatePassKeyConfigReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case PlatformConfigurationConst.UPDATE_PASSKEY_CONFIG_SUCCESS:
    case PlatformConfigurationConst.UPDATE_PASSKEY_CONFIG_LOADING:
    case PlatformConfigurationConst.UPDATE_PASSKEY_CONFIG_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

// Combine Reducers
export const platformConfigurationReducer = combineReducers({
  globalEmailConfig: getGlobalEmailConfigReducer,
  saveGlobalEmailConfigResponse: saveGlobalEmailConfigReducer,
  getApiKey: getApiKeyReducer,
  smsSettingsAndConfig: getSMSSettingsAndConfigReducer,
  smsTemplates: getSMSTemplatesReducer,
  smsProviderConfig: getSMSProviderConfigReducer,
  customSmsProviderConfig: getCustomSMSProviderConfigReducer,
  saveSMSConfigResponse: saveSMSConfigReducer,
  saveCustomSMSConfigResponse: saveCustomSMSConfigReducer,
  resetSMSConfigResponse: resetSMSConfigReducer,
  globalSMSSettings: getGlobalSMSSettingsReducer,
  saveGlobalSMSSettingsResponse: saveGlobalSMSSettingsReducer,
  getRoles: getRolesReducer,
  getConfigurationList: getConfigurationListReducer,
  getSocialProviderList: getSocialProviderListReducer,
  transferConfigurations: transferConfigurationsReducer,
  getMultipleSocialProvider: getMultipleSocialProviderReducer,
  //postMultipleSocialProvider: postMultipleSocialProviderReducer,
  deleteMultipleSocialProvider: deleteMultipleSocialProviderReducer,
  getAuthServerConfigApi: getAuthServerConfigApiReducer,
  deleteAuthServerConfigApi: deleteAuthServerConfigApiReducer,
  updateAuthServerConfigAPi: updateAuthServerConfigApiReducer,
  setAuthServerConfigApi: setAuthServerConfigApiReducer,
  setAppFeatures: setAppFeaturesReducer,
  getRenewCertificateData: renewCertificateReducer,
  getSSOIntegrationData: getSSOIntegrationReducer,
  getAvailableSSOIntegrationData: getAvailableSSOIntegrationReducer,
  getUserSamlIntegration: getUserSamlIntegrationByTypeReducer,
  saveSSOIntegrationData: postSSOIntegrationReducer,
  updateSSOIntegrationData: putSSOIntegrationReducer,
  deleteSSOIntegrationData: deleteSSOIntegrationReducer,
  getOAuthProviders: getOAuthProvidersReducer,
  getJWTProviders: getJWTProvidersReducer,
  getSAMLProviders: getSAMLProvidersReducer,
  getOIDCandOAuthApps: getOIDCandOAuthAppsReducer,
  getPassKeyConfig: getPassKeyConfigReducer,
  updatePassKeyConfig: updatePassKeyConfigReducer
});

// Combine schemas
export interface PlatformConfigurationSchema {
  globalEmailConfig: GetGlobalEmailConfigSchema;
  saveGlobalEmailConfigResponse: SaveGlobalEmailConfigSchema;
  getApiKey: GetApiKeySchema;
  smsSettingsAndConfig: GetSMSSettingsAndConfigSchema;
  smsTemplates: GetSMSTemplatesSchema;
  smsProviderConfig: GetSMSProviderConfigSchema;
  customSmsProviderConfig: GetCustomSMSProviderConfigSchema;
  saveSMSConfigResponse: SaveSMSConfigSchema;
  saveCustomSMSConfigResponse: SaveCustomSMSConfigSchema;
  resetSMSConfigResponse: ResetSMSConfigSchema;
  globalSMSSettings: GetGlobalSMSConfigSchema;
  saveGlobalSMSSettingsResponse: SaveGlobalSMSConfigSchema;
  getRoles: GetRolesSchema;
  getConfigurationList: GetConfigurationListSchema;
  getSocialProviderList: GetSocialProviderListSchema;
  transferConfigurations: TransferConfigurationsSchema;
  getMultipleSocialProvider: GetMultipleSocialProviderSchema;
  //postMultipleSocialProvider: GetMultipleSocialProviderSchema;
  deleteMultipleSocialProvider: any;
  getAuthServerConfigApi: GetAuthServerConfigApiSchema;
  deleteAuthServerConfigApi: GetAuthServerConfigApiSchema;
  updateAuthServerConfigAPi: GetAuthServerConfigApiSchema;
  setAuthServerConfigApi: GetAuthServerConfigApiSchema;
  setAppFeatures: AppFeatureSchema;
  getRenewCertificateData: any;
  getSSOIntegrationData: any;
  getAvailableSSOIntegrationData: any;
  getUserSamlIntegration: any;
  saveSSOIntegrationData: any;
  updateSSOIntegrationData: any;
  deleteSSOIntegrationData: any;
  getOAuthProviders: GetOAuthProvidersSchema;
  getJWTProviders: GetJWTProvidersSchema;
  getSAMLProviders: GetSAMLProvidersSchema;
  getOIDCandOAuthApps: GetOIDCandOAuthAppsSchema;
  getPassKeyConfig: any;
  updatePassKeyConfig: any;
}

export default platformConfigurationReducer;

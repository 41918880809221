import { take, call, put } from "redux-saga/effects";
import axios from "axios";

import {
  UpdatesConst,
  ProductUpdateSchema,
  getProductUpdatesLoadingAction,
  getProductUpdatesSuccessAction,
  getProductUpdatesErrorAction
} from "../actions/updatesActions";
import { httpGet } from "../../services";

/**
 * Watchers
 */

export function* watchGetProductUpdates() {
  while (true) {
    const data = yield take(UpdatesConst.GET_PRODUCT_UPDATES);
    yield call(getProductUpdates, data.payload);
  }
}

/**
 * Workers
 */

function* getProductUpdates(count: number) {
  try {
    yield put(getProductUpdatesLoadingAction());
    /** Using directly the axios module since using our httpGet utility in src/services/index.tsx does not work for this request:
     * the request will fail because the app receiving it cannot handle the preflight request (method OPTIONS) sent by nginx. Additionally,
     * the request does not need any headers or credentials, so the request below is just enough.
     */
    let data = yield httpGet(`/account/productupdate?per_page=${count}`);

    let updates: ProductUpdateSchema[] = [];
    for (let update of data) {
      updates.push({
        display: update.title.rendered,
        link: update.link,
        date: update.date.substr(0, 10)
      });
    }
    yield put(getProductUpdatesSuccessAction({ data: updates }));
  } catch (e) {
    yield put(
      getProductUpdatesErrorAction(
        e.message || "Could not retrieve Product Updates."
      )
    );
  }
}

import { combineReducers } from "redux";
import { ActionSchema } from "../actions";
import {
  BillingConst,
  GetAppDataSchema,
  GetBillingSubscriptionsSchema,
  GetInvoicesSchema,
  GetRecurlyAccountDetailsSchema
} from "../actions/billingActions";

const appDataReducer = (state = {}, action: ActionSchema<GetAppDataSchema>) => {
  switch (action.type) {
    case BillingConst.GET_APP_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case BillingConst.GET_APP_DATA_LOADING:
    case BillingConst.GET_APP_DATA_ERROR:
    default:
      return state;
  }
};

const billingSubscriptionsReducer = (
  state = {},
  action: ActionSchema<GetBillingSubscriptionsSchema>
) => {
  switch (action.type) {
    case BillingConst.GET_BILLING_SUBSCRIPTIONS_SUCCESS:
      return { ...state, ...action.payload };
    case BillingConst.GET_BILLING_SUBSCRIPTIONS_LOADING:
    case BillingConst.GET_BILLING_SUBSCRIPTIONS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const invoicesReducer = (
  state = {},
  action: ActionSchema<GetInvoicesSchema>
) => {
  switch (action.type) {
    case BillingConst.GET_INVOICES_SUCCESS:
      return { ...state, ...action.payload };
    case BillingConst.GET_INVOICES_LOADING:
    case BillingConst.GET_INVOICES_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const recurlyAccountDetailsReducer = (
  state = {},
  action: ActionSchema<GetRecurlyAccountDetailsSchema>
) => {
  switch (action.type) {
    case BillingConst.GET_RECURLY_ACCOUNT_DETAILS_SUCCESS:
      return { ...state, ...action.payload };
    case BillingConst.GET_RECURLY_ACCOUNT_DETAILS_LOADING:
    case BillingConst.GET_RECURLY_ACCOUNT_DETAILS_ERROR:
    default:
      return state;
  }
};

// Combine Reducers
const billingReducer = combineReducers({
  appData: appDataReducer,
  subscriptions: billingSubscriptionsReducer,
  invoices: invoicesReducer,
  recurlyAccountDetails: recurlyAccountDetailsReducer
});

// Combine schemas
export interface BillingSchema {
  appData: GetAppDataSchema;
  subscriptions: GetBillingSubscriptionsSchema;
  invoices: GetInvoicesSchema;
  recurlyAccountDetails: GetRecurlyAccountDetailsSchema;
}

export default billingReducer;

import React, { useState } from "react";
import { MDBTooltip, MDBBtn } from "mdbreact";

import "./ToolTip.scss";

export interface ToolTipProps {
  ariaLabel?: string;
  id?: string;
  placement: "top" | "right" | "bottom" | "left";
  message: string;
  iconClass?: string;
  customIconClass?: string;
  customId?: string;
  size?: "sm" | "md" | "lg";
}

const ToolTip = React.memo((props: ToolTipProps) => {
  const {
    ariaLabel,
    placement,
    message,
    id,
    iconClass,
    customIconClass,
    customId
  } = props;
  const _size = props.size
    ? props.size === "sm"
      ? "0.6"
      : props.size === "md"
      ? "0.8"
      : "1"
    : "1";

  let [isTooltipVisible, updateIsTooltipVisible] = useState(false);

  const handleFocus = () => {
    updateIsTooltipVisible(!isTooltipVisible);
  };

  return (
    <MDBTooltip
      id={id || "mdbToolTip"}
      placement={placement}
      tag="span"
      isVisible={isTooltipVisible}
    >
      <MDBBtn
        className="mdbToolTip-button border border-0 p-0 m-0"
        style={{ transform: `scale(${_size})` }}
        onFocus={handleFocus}
        onBlur={handleFocus}
      >
        <i
          className={
            customIconClass ? customIconClass : "lr-material-" + iconClass
          }
          id={customId ? customId : ""}
          aria-label={ariaLabel || ""}
        />
      </MDBBtn>
      <div>{message}</div>
    </MDBTooltip>
  );
});

export default ToolTip;

import React from "react";

import "./Anchor.scss";

interface AnchorProps {
  ariaLabel?: string;
  className?: string;
  href: string;
  icon?: string;
  id?: string;
  rel?: string;
  target?: string;
  title?: string;
  onClick?: () => void;
}

const Anchor = React.memo((props: React.PropsWithChildren<AnchorProps>) => {
  const {
    ariaLabel,
    className,
    href,
    icon,
    id,
    rel,
    target,
    title,
    onClick
  } = props;

  const anchorIcon = icon ? (
    <i aria-label={icon} className={icon} role="img" />
  ) : null;
  const newTabRel = target === "_blank" ? "noopener noreferrer" : "";

  return (
    <React.Fragment>
      <a
        aria-label={ariaLabel}
        className={`anchor-link global-link ${className || ""}`}
        href={href}
        id={id}
        rel={rel ? `${rel} ${newTabRel}` : newTabRel}
        target={target}
        title={title || ""}
        onClick={onClick}
      >
        {props.children}
      </a>
      {anchorIcon}
    </React.Fragment>
  );
});

export default Anchor;

import { combineReducers } from "redux";
import { ActionSchema } from "../actions";
import {
  TeamConst,
  GetTeamUsersDataSchema,
  GetTeamOwnerDataSchema,
  DeleteTeamUserDataSchema,
  ResendTeamUserPasswordDataSchema,
  GetTeamUserSessionDataSchema,
  GetCurrentUserDataSchema,
  LogoutTeamUserDataSchema,
  GetAppRolesDataSchema,
  UpdateTeamUserRoleDataSchema,
  GetAuditLogsSchema,
  GetRoleListSchema,
  GetOwnerInfoSchema,
  GetCheckedUserSchema,
  CreatedUserSchema,
  SendResetPasswordSchema,
  ExistingUserSchema,
  UserRoleSchema,
  GetMapFieldsSchema,
  GetCurrentUserSchema,
  GetSamlConfigSchema,
  PostSamlConfigSchema,
  PutSamlConfigSchema,
  DeleteSamlConfigSchema,
  GetAppUsersSchema,
  TransferAccountSchema,
  UpdateCurrentUserSchema,
  WasUserAddedSchema,
  CreateUserAndTransferOwnershipSchema,
  DeleteSelfDataSchema
} from "../actions/teamActions";

const teamUsersReducer = (
  state = {},
  action: ActionSchema<GetTeamUsersDataSchema>
) => {
  switch (action.type) {
    case TeamConst.GET_TEAM_USERS_LOADING:
    case TeamConst.GET_TEAM_USERS_SUCCESS:
    case TeamConst.GET_TEAM_USERS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const teamOwnerReducer = (
  state = {},
  action: ActionSchema<GetTeamOwnerDataSchema>
) => {
  switch (action.type) {
    case TeamConst.GET_TEAM_OWNER_LOADING:
    case TeamConst.GET_TEAM_OWNER_SUCCESS:
    case TeamConst.GET_TEAM_OWNER_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const deleteTeamUserReducer = (
  state = {},
  action: ActionSchema<DeleteTeamUserDataSchema>
) => {
  switch (action.type) {
    case TeamConst.DELETE_TEAM_USER_LOADING:
    case TeamConst.DELETE_TEAM_USER_SUCCESS:
    case TeamConst.DELETE_TEAM_USER_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const deleteSelfReducer = (
  state = {},
  action: ActionSchema<DeleteSelfDataSchema>
) => {
  switch (action.type) {
    case TeamConst.DELETE_SELF_LOADING:
    case TeamConst.DELETE_SELF_SUCCESS:
    case TeamConst.DELETE_SELF_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const resendTeamUserPasswordReducer = (
  state = {},
  action: ActionSchema<ResendTeamUserPasswordDataSchema>
) => {
  switch (action.type) {
    case TeamConst.RESEND_TEAM_USER_PASSWORD_LOADING:
    case TeamConst.RESEND_TEAM_USER_PASSWORD_SUCCESS:
    case TeamConst.RESEND_TEAM_USER_PASSWORD_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getTeamUserSessionReducer = (
  state = {},
  action: ActionSchema<GetTeamUserSessionDataSchema>
) => {
  switch (action.type) {
    case TeamConst.GET_TEAM_USER_SESSION_LOADING:
    case TeamConst.GET_TEAM_USER_SESSION_SUCCESS:
    case TeamConst.GET_TEAM_USER_SESSION_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const logOutTeamUserReducer = (
  state = {},
  action: ActionSchema<LogoutTeamUserDataSchema>
) => {
  switch (action.type) {
    case TeamConst.LOG_OUT_TEAM_USER_LOADING:
    case TeamConst.LOG_OUT_TEAM_USER_SUCCESS:
    case TeamConst.LOG_OUT_TEAM_USER_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getCurrentUserReducer = (
  state = {},
  action: ActionSchema<GetCurrentUserDataSchema>
) => {
  switch (action.type) {
    case TeamConst.GET_CURRENT_USER_LOADING:
    case TeamConst.GET_CURRENT_USER_SUCCESS:
    case TeamConst.GET_CURRENT_USER_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getAppRolesReducer = (
  state = {},
  action: ActionSchema<GetAppRolesDataSchema>
) => {
  switch (action.type) {
    case TeamConst.GET_APP_ROLES_LOADING:
    case TeamConst.GET_APP_ROLES_SUCCESS:
    case TeamConst.GET_APP_ROLES_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const updateTeamUserRoleReducer = (
  state = {},
  action: ActionSchema<UpdateTeamUserRoleDataSchema>
) => {
  switch (action.type) {
    case TeamConst.UPDATE_TEAM_USER_ROLE_LOADING:
    case TeamConst.UPDATE_TEAM_USER_ROLE_SUCCESS:
    case TeamConst.UPDATE_TEAM_USER_ROLE_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const auditLogsReducer = (
  state = {},
  action: ActionSchema<GetAuditLogsSchema>
) => {
  switch (action.type) {
    case TeamConst.GET_AUDIT_LOGS_SUCCESS:
    case TeamConst.GET_AUDIT_LOGS_LOADING:
    case TeamConst.GET_AUDIT_LOGS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const roleListReducer = (state = [], action: ActionSchema<any>) => {
  switch (action.type) {
    case TeamConst.GET_ROLE_LIST_SUCCESS:
      return action.payload;
    case TeamConst.GET_ROLE_LIST_LOADING:
    case TeamConst.GET_ROLE_LIST_ERROR:
    default:
      return state;
  }
};

const ownerInfoReducer = (
  state = {},
  action: ActionSchema<GetOwnerInfoSchema>
) => {
  switch (action.type) {
    case TeamConst.GET_OWNER_INFO_SUCCESS:
      return { ...state, ...action.payload };
    case TeamConst.GET_OWNER_INFO_LOADING:
    case TeamConst.GET_OWNER_INFO_ERROR:
    default:
      return state;
  }
};

const checkUserReducer = (
  state = {},
  action: ActionSchema<GetCheckedUserSchema>
) => {
  switch (action.type) {
    case TeamConst.RESET_TEAM_STATE_AFTER_ADDING_USER:
      return {};
    case TeamConst.CHECK_USER_SUCCESS:
      return { ...state, ...action.payload };
    case TeamConst.CHECK_USER_LOADING:
    case TeamConst.CHECK_USER_ERROR:
    default:
      return state;
  }
};

const sendResetPasswordReducer = (
  state = {},
  action: ActionSchema<SendResetPasswordSchema>
) => {
  switch (action.type) {
    case TeamConst.RESET_TEAM_STATE_AFTER_ADDING_USER:
      return {};
    case TeamConst.SEND_RESET_PASSWORD_SUCCESS:
      return { ...state, ...action.payload };
    case TeamConst.SEND_RESET_PASSWORD_LOADING:
    case TeamConst.SEND_RESET_PASSWORD_ERROR:
    default:
      return state;
  }
};

const addExistingUserReducer = (
  state = {},
  action: ActionSchema<ExistingUserSchema>
) => {
  switch (action.type) {
    case TeamConst.RESET_TEAM_STATE_AFTER_ADDING_USER:
      return {};
    case TeamConst.ADD_EXISTING_USER_SUCCESS:
      return { ...state, ...action.payload };
    case TeamConst.ADD_EXISTING_USER_LOADING:
    case TeamConst.ADD_EXISTING_USER_ERROR:
    default:
      return state;
  }
};

const addUserRoleReducer = (
  state = {},
  action: ActionSchema<UserRoleSchema>
) => {
  switch (action.type) {
    case TeamConst.RESET_TEAM_STATE_AFTER_ADDING_USER:
      return {};
    case TeamConst.ADD_USER_ROLE_SUCCESS:
      return { ...state, ...action.payload };
    case TeamConst.ADD_USER_ROLE_LOADING:
    case TeamConst.ADD_USER_ROLE_ERROR:
    default:
      return state;
  }
};

const wasUserAddedInActiveSessionReducer = (
  state = {},
  action: ActionSchema<WasUserAddedSchema>
) => {
  switch (action.type) {
    case TeamConst.WAS_USER_ADDED_IN_ACTIVE_SESSION_SUCCESS:
      return action.payload;
    case TeamConst.WAS_USER_ADDED_IN_ACTIVE_SESSION_LOADING:
    case TeamConst.WAS_USER_ADDED_IN_ACTIVE_SESSION_ERROR:
    default:
      return state;
  }
};

const mapFieldsReducer = (
  state = {},
  action: ActionSchema<GetMapFieldsSchema>
) => {
  switch (action.type) {
    case TeamConst.GET_MAP_FIELDS_SUCCESS:
      return { ...state, ...action.payload };
    case TeamConst.GET_MAP_FIELDS_LOADING:
    case TeamConst.GET_MAP_FIELDS_ERROR:
    default:
      return state;
  }
};

const currentUserReducer = (
  state = {},
  action: ActionSchema<GetCurrentUserSchema>
) => {
  switch (action.type) {
    case TeamConst.GET_CURRENT_USER_SUCCESS:
      return { ...state, ...action.payload };
    case TeamConst.GET_CURRENT_USER_LOADING:
    case TeamConst.GET_CURRENT_USER_ERROR:
    default:
      return state;
  }
};

const getSamlConfigReducer = (
  state = {},
  action: ActionSchema<GetSamlConfigSchema>
) => {
  switch (action.type) {
    case TeamConst.GET_SAML_CONFIG_LOADING:
    case TeamConst.GET_SAML_CONFIG_ERROR:
    case TeamConst.GET_SAML_CONFIG_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const postSamlConfigReducer = (
  state = {},
  action: ActionSchema<PostSamlConfigSchema>
) => {
  switch (action.type) {
    case TeamConst.POST_SAML_CONFIG_LOADING:
    case TeamConst.POST_SAML_CONFIG_ERROR:
    case TeamConst.POST_SAML_CONFIG_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const putSamlConfigReducer = (
  state = {},
  action: ActionSchema<PutSamlConfigSchema>
) => {
  switch (action.type) {
    case TeamConst.PUT_SAML_CONFIG_LOADING:
    case TeamConst.PUT_SAML_CONFIG_ERROR:
    case TeamConst.PUT_SAML_CONFIG_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const deleteSamlConfigReducer = (
  state = {},
  action: ActionSchema<DeleteSamlConfigSchema>
) => {
  switch (action.type) {
    case TeamConst.DELETE_SAML_CONFIG_LOADING:
    case TeamConst.DELETE_SAML_CONFIG_ERROR:
    case TeamConst.DELETE_SAML_CONFIG_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const appUsersReducer = (
  state = {},
  action: ActionSchema<GetAppUsersSchema>
) => {
  switch (action.type) {
    case TeamConst.GET_APP_USERS_SUCCESS:
      return { ...state, ...action.payload };
    case TeamConst.GET_APP_USERS_LOADING:
    case TeamConst.GET_APP_USERS_ERROR:
    default:
      return state;
  }
};

const transferAccountReducer = (
  state = {},
  action: ActionSchema<TransferAccountSchema>
) => {
  switch (action.type) {
    case TeamConst.TRANSFER_ACCOUNT_SUCCESS:
      return { ...state, ...action.payload };
    case TeamConst.TRANSFER_ACCOUNT_LOADING:
    case TeamConst.TRANSFER_ACCOUNT_ERROR:
    default:
      return state;
  }
};

const updateCurrentUserReducer = (
  state = {},
  action: ActionSchema<UpdateCurrentUserSchema>
) => {
  switch (action.type) {
    case TeamConst.UPDATE_CURRENT_USER_SUCCESS:
      return { ...state, ...action.payload };
    case TeamConst.UPDATE_CURRENT_USER_LOADING:
    case TeamConst.UPDATE_CURRENT_USER_ERROR:
    default:
      return state;
  }
};

const createUserAndTransferOwnershipReducer = (
  state = {},
  action: ActionSchema<UpdateCurrentUserSchema>
) => {
  switch (action.type) {
    case TeamConst.CREATE_USER_AND_TRANSFER_OWNERSHIP_SUCCESS:
      return { ...state, ...action.payload };
    case TeamConst.CREATE_USER_AND_TRANSFER_OWNERSHIP_LOADING:
    case TeamConst.CREATE_USER_AND_TRANSFER_OWNERSHIP_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

// Combine Reducers
const teamReducer = combineReducers({
  users: teamUsersReducer,
  owner: teamOwnerReducer,
  deleteTeamUser: deleteTeamUserReducer,
  deleteSelf: deleteSelfReducer,
  resendTeamUserPassword: resendTeamUserPasswordReducer,
  teamUserSession: getTeamUserSessionReducer,
  logOutTeamUser: logOutTeamUserReducer,
  currentUser: getCurrentUserReducer,
  appRoles: getAppRolesReducer,
  updateTeamUserRole: updateTeamUserRoleReducer,
  auditLogs: auditLogsReducer,
  roleList: roleListReducer,
  ownerInfo: ownerInfoReducer,
  checkedUser: checkUserReducer,
  resetPassword: sendResetPasswordReducer,
  existingUser: addExistingUserReducer,
  userRole: addUserRoleReducer,
  wasUserAdded: wasUserAddedInActiveSessionReducer,
  mapFields: mapFieldsReducer,
  samlConfig: getSamlConfigReducer,
  postSamlConfig: postSamlConfigReducer,
  putSamlConfig: putSamlConfigReducer,
  deleteSamlConfig: deleteSamlConfigReducer,
  appUsers: appUsersReducer,
  transferAccount: transferAccountReducer,
  updateCurrentUser: updateCurrentUserReducer,
  createUserAndTransferOwnership: createUserAndTransferOwnershipReducer
});

// Combine schemas
export interface TeamSchema {
  users: GetTeamUsersDataSchema;
  owner: GetTeamOwnerDataSchema;
  deleteTeamUser: DeleteTeamUserDataSchema;
  deleteSelf: DeleteSelfDataSchema;
  resendTeamUserPassword: ResendTeamUserPasswordDataSchema;
  teamUserSession: GetTeamUserSessionDataSchema;
  logOutTeamUser: LogoutTeamUserDataSchema;
  currentUser: GetCurrentUserDataSchema;
  appRoles: GetAppRolesDataSchema;
  updateTeamUserRole: UpdateTeamUserRoleDataSchema;
  auditLogs: GetAuditLogsSchema;
  roleList: GetRoleListSchema;
  ownerInfo: GetOwnerInfoSchema;
  checkedUser: GetCheckedUserSchema;
  createdUser: CreatedUserSchema;
  resetPassword: SendResetPasswordSchema;
  existingUser: ExistingUserSchema;
  userRole: UserRoleSchema;
  wasUserAdded: WasUserAddedSchema;
  mapFields: GetMapFieldsSchema;
  samlConfig: GetSamlConfigSchema;
  postSamlConfig: PostSamlConfigSchema;
  putSamlConfig: PutSamlConfigSchema;
  deleteSamlConfig: DeleteSamlConfigSchema;
  appUsers: GetAppUsersSchema;
  transferAccount: TransferAccountSchema;
  updateCurrentUser: UpdateCurrentUserSchema;
  createUserAndTransferOwnership: CreateUserAndTransferOwnershipSchema;
}

export default teamReducer;

import { ActionSchema } from "../actions";
import {
  GetCustomFieldsSchema,
  SegmentationConst,
  PostUserSegmentationQuerySchema,
  GetTopTenSchema,
  GetSavedSegmentsSchema,
  DeleteSegmentSchema,
  GetAllCustomObjectsSchema,
  GetCustomObjUsersSchema
} from "../actions/segmentationsAction";
import { combineReducers } from "redux";

const customFieldsReducer = (
  state = {},
  action: ActionSchema<GetCustomFieldsSchema>
) => {
  switch (action.type) {
    case SegmentationConst.GET_CUSTOMFIELDS_LOADING:
    case SegmentationConst.GET_CUSTOMFIELDS_SUCCESS:
    case SegmentationConst.GET_CUSTOMFIELDS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const postUserSegmentationQueryReducer = (
  state = {},
  action: ActionSchema<PostUserSegmentationQuerySchema>
) => {
  switch (action.type) {
    case SegmentationConst.POST_USER_SEGMENTATION_QUERY_LOADING:
    case SegmentationConst.POST_USER_SEGMENTATION_QUERY_SUCCESS:
    case SegmentationConst.POST_USER_SEGMENTATION_QUERY_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const savedSegmentsReducer = (
  state = {},
  action: ActionSchema<GetSavedSegmentsSchema>
) => {
  switch (action.type) {
    case SegmentationConst.GET_SAVED_SEGMENTS_LOADING:
    case SegmentationConst.GET_SAVED_SEGMENTS_ERROR:
    case SegmentationConst.GET_SAVED_SEGMENTS_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getTopTenUsersReducer = (
  state = {},
  action: ActionSchema<GetTopTenSchema>
) => {
  switch (action.type) {
    case SegmentationConst.GET_SEGMENTATIONS_TOP_TEN_USERS_LOADING:
    case SegmentationConst.GET_SEGMENTATIONS_TOP_TEN_USERS_SUCCESS:
    case SegmentationConst.GET_SEGMENTATIONS_TOP_TEN_USERS_ERROR:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

const deleteSegmentReducer = (
  state = {},
  action: ActionSchema<DeleteSegmentSchema>
) => {
  switch (action.type) {
    case SegmentationConst.DELETE_SEGMENT_SUCCESS:
    case SegmentationConst.DELETE_SEGMENT_ERROR:
    case SegmentationConst.DELETE_SEGMENT_LOADING:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const getAllCustomObjectsReducer = (
  state = {},
  action: ActionSchema<GetAllCustomObjectsSchema>
) => {
  switch (action.type) {
    case SegmentationConst.GET_ALL_CUSTOM_OBJECTS_SUCCESS:
    case SegmentationConst.GET_ALL_CUSTOM_OBJECTS_ERROR:
    case SegmentationConst.GET_ALL_CUSTOM_OBJECTS_LOADING:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const getCustomObjTopTenUsersReducer = (
  state = {},
  action: ActionSchema<GetCustomObjUsersSchema>
) => {
  switch (action.type) {
    case SegmentationConst.GET_CUSTOMOBJ_TOPTEN_USERS_SUCCESS:
    case SegmentationConst.GET_CUSTOMOBJ_TOPTEN_USERS_ERROR:
    case SegmentationConst.GET_CUSTOMOBJ_TOPTEN_USERS_LOADING:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const getCustomObjUsersReducer = (
  state = {},
  action: ActionSchema<GetCustomObjUsersSchema>
) => {
  switch (action.type) {
    case SegmentationConst.GET_CUSTOMOBJ_USERS_SUCCESS:
    case SegmentationConst.GET_CUSTOMOBJ_USERS_ERROR:
    case SegmentationConst.GET_CUSTOMOBJ_USERS_LOADING:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

// Combine Reducers
const segmentationsReducer = combineReducers({
  customFields: customFieldsReducer,
  postUserSegmentationQueryStatus: postUserSegmentationQueryReducer,
  topTenUsers: getTopTenUsersReducer,
  savedSegments: savedSegmentsReducer,
  deleteSegment: deleteSegmentReducer,
  allCustomObjects: getAllCustomObjectsReducer,
  customObjTopTenUsers: getCustomObjTopTenUsersReducer,
  customObjUsers: getCustomObjUsersReducer
});

// Combine schemas
export interface SegmentationsSchema {
  customFields: GetCustomFieldsSchema;
  postUserSegmentationQueryStatus: PostUserSegmentationQuerySchema;
  topTenUsers: GetTopTenSchema;
  savedSegments: GetSavedSegmentsSchema;
  deleteSegment: DeleteSegmentSchema;
  allCustomObjects: GetAllCustomObjectsSchema;
  customObjTopTenUsers: GetCustomObjUsersSchema;
  customObjUsers: GetCustomObjUsersSchema;
}

export default segmentationsReducer;

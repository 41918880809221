import React, { useEffect } from "react";
import { createContext, ReactNode, useContext, useState } from "react";
import { Edge, Node } from "react-flow-renderer";
interface ContextProps {
  selectedNode?: any;
  setSelectedNode?: any;
  tree?: any;
  setTree?: any;
  nodes?: any;
  setNodes?: any;
  edges?: any;
  setEdges?: any;
  dragOverParentNode?: any;
  setDragOverParentNode?: any;
  setDraggedNode?: any;
  draggedNode?: any;
  policies?: any;
  setPolicies?: any;
  validateNodeProperties?: any;
  isPropertyConfigError?: any;
  setIsPropertyConfigError?: any;
  updateStateOnNodesDelete?: any;
  updateDynamicProperties?: any;
  checkParentNodeDrop?: any;
  nodeValidator?: any;
  updatedTree?: any;
  setUpdatedTree?: any;
}
const StateContext = createContext<ContextProps>({});

interface Tree {
  entryNodeId: string;
  nodes: any;
  staticNodes: {
    successFinalNode: { position: { x: number; y: number } };
    failureFinalNode: { position: { x: number; y: number } };
    initialNode: { position: { x: number; y: number } };
  };
}
const initialTree: Tree = {
  entryNodeId: "",
  nodes: {},
  staticNodes: {
    successFinalNode: { position: { x: 100, y: 300 } },
    failureFinalNode: { position: { x: 400, y: 300 } },
    initialNode: { position: { x: 250, y: -300 } }
  }
};
interface ContextProviderProps {
  children?: ReactNode;
}

type CustomNode = Node & {
  output?: any;
  nodes?: any;
};

export const initialNodes: CustomNode[] = [
  {
    id: "initialNode",
    type: "initial",
    data: { label: "initial node" },
    // className: 'initial-node',
    position: { x: -400, y: -50 }

    // style: { width: '0px', height: '0px' },
  },
  {
    id: "SuccessFinalNode",
    type: "finalPositive",
    data: {
      label: "Success",
      description: "Denotes that the workflow ended in success. ",
      properties: [
        {
          id: "1",
          type: "text",
          value: "",
          label: "Redirect URL"
        }
      ]
    },

    position: { x: 300, y: -150 }
  },
  {
    id: "FailureFinalNode",
    type: "finalNegative",
    data: {
      label: "Failure",
      description: "Denotes that the workflow ended at failure. ",
      properties: [
        {
          id: "1",
          type: "text",
          value: "",
          label: "Redirect URL"
        }
      ]
    },

    position: { x: 300, y: 150 }
  }
];

export const initialPolicies = {
  email: [
    {
      id: "1",
      type: "checkbox",
      value: false,
      label: "Should Restrict",
      elementType: "shouldrestrict"
    },
    {
      id: "2",
      type: "select",
      options: [
        { text: "Blacklist", value: "Blacklist" },
        { text: "Whitelist", value: "Whitelist" }
      ],
      value: "",
      label: "Blacklist/Whitelist",
      elementType: "blacklistwhitelist"
    },
    {
      id: "3",
      type: "multivalue",
      value: "",
      label: "Email/Domain",

      elementType: "emaildomain"
    }
  ],

  password: [
    {
      id: "4",
      type: "text",
      value: "min_length[6]|max_length[32]|required",
      label: "Password Validation",
      elementType: "rules",
      tooltipMessage: "Validate password property must be enabled"
    },
    {
      id: "5",
      type: "selectinput",
      options: [
        { text: "Days", value: "Days" },
        { text: "Month", value: "Month" },
        { text: "Year", value: "Year" }
      ],
      value: "",
      label: "Password Expiration",
      elementType: "passwordexpiration"
    },
    {
      id: "6",
      type: "text",
      value: "",
      label: "Password History",

      elementType: "passwordhistory"
    },
    {
      id: "7",
      type: "checkbox",
      value: false,
      label: "Common Password Protection",

      elementType: "confirmpasswordprotection"
    },
    {
      id: "8",
      type: "checkbox",
      value: false,
      label: "Dictionary Password Prevention",

      elementType: "dictionarypasswordprevention"
    },
    {
      id: "9",
      type: "checkbox",
      value: false,
      label: "Profile Field Password Prevention",

      elementType: "profilefieldpasswordprevention"
    }
  ],

  username: [
    {
      id: "10",
      type: "checkbox",
      value: false,
      label: "Is Primary",
      elementType: "isprimary"
    },
    {
      id: "11",
      type: "checkbox",
      value: false,
      label: "Duplicate Email",
      primaryParentId: "10",
      elementType: "duplicateemail"
    },
    {
      id: "12",
      type: "checkbox",
      value: false,
      label: "Case sensitive Username",

      elementType: "casesensitiveusername"
    }
  ],
  pininput: [
    {
      id: "13",
      type: "text",
      value: "",
      label: "Pin Validation",
      elementType: "rules"
    }
  ],
  phone: [
    {
      id: "14",
      type: "checkbox",
      value: false,
      label: "Is Primary",
      elementType: "isprimary"
    },

    {
      id: "15",
      type: "checkbox",
      value: false,
      label: "Country Code",
      elementType: "countrycode"
    },

    {
      id: "16",
      type: "select",
      value: "",
      primaryParentId: "15",

      isSearchEnabled: true,
      options: "optionsofsetcountrycode",
      label: "Set Default Country Code",
      elementType: "setdefaultcountrycode"
    },
    {
      id: "17",
      type: "multiselect",
      value: [],
      primaryParentId: "15",
      isSearchEnabled: true,
      options: "optionsofallowcountrycode",
      label: "Allow particular country code",
      elementType: "allowparticularcountrycode"
    }
  ]
};

export const ContextProvider = ({ children }: ContextProviderProps) => {
  const [selectedNode, setSelectedNode] = useState(null);
  const [tree, setTree] = useState<any>(initialTree);
  const [nodes, setNodes] = useState<CustomNode[]>(initialNodes);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [dragOverParentNode, setDragOverParentNode] = useState("");
  const [draggedNode, setDraggedNode] = useState<any>(null);
  const [policies, setPolicies] = useState<any>(initialPolicies);
  const [isPropertyConfigError, setIsPropertyConfigError] = useState(new Map());
  const [updatedTree, setUpdatedTree] = useState<any>({});
  // useEffect(() => {
  //   console.log("nodes", nodes);
  // }, [nodes]);

  // useEffect(() => {
  //   console.log("tree", tree);
  // }, [tree]);

  // useEffect(() => {
  //   console.log("edges", edges);
  // }, [edges]);

  // useEffect(() => {
  //   console.log("selectedNode", selectedNode);
  // }, [selectedNode]);

  // useEffect(() => {
  //   console.log("dragOverParentNode", dragOverParentNode);
  // }, [dragOverParentNode]);
  const checkParentNodeDrop = (newNode: any) => {
    const isDropOnParentNode = nodes.find((node: any) => {
      if (
        node.type === "webpage" &&
        newNode.isChildNode &&
        newNode.position.x >= node.position.x &&
        newNode.position.x <= node.position.x + node.width &&
        newNode.position.y >= node.position.y &&
        newNode.position.y <= node.position.y + node.height
      ) {
        // newNode.position.x = newNode.position.x - node.position.x;
        // newNode.position.y = newNode.position.y - node.position.y;
        newNode.position.x = 10;

        newNode.position.y = node.nodes ? node.nodes.length * 42 + 41 : 41;
        return node;
      }
    });

    if (isDropOnParentNode) {
      const { position, output, parentNode, ...childNode } = newNode;

      setDragOverParentNode("");
      setNodes((nds: any) =>
        nds.map((node: any) => {
          if (node.id === isDropOnParentNode.id) {
            const foundNode = node.nodes.find((node: any) => {
              return node.id === childNode.id;
            });
            if (!foundNode) {
              const {
                id,
                type,
                childType,
                isChildNode,
                data: { label }
              } = childNode;
              node.nodes.push({
                id,
                type,
                childType,
                isChildNode,
                data: { label }
              });
            }
            if (
              childNode.type === "button" ||
              childNode.childType === "social"
            ) {
              node.output.push({
                id: childNode.id,
                displayName: childNode.data.label
              });
            }
          }

          return node;
        })
      );

      nodeValidator(newNode.id, newNode.data.properties);
      newNode.parentNode = isDropOnParentNode.id;
      newNode.extent = "parent";
      // newNode.draggable= isDropOnParentNode ? false : true;
      newNode.hidden = true;
      newNode.selected = false;

      if (newNode.type === "button" || newNode.childType === "social") {
        setTree((tr: any) => ({
          ...tr,
          nodes: {
            ...tr.nodes,
            [isDropOnParentNode.id]: {
              ...tr.nodes[isDropOnParentNode.id],
              connections: [
                ...tr.nodes[isDropOnParentNode.id].connections,
                { [childNode.id]: "" }
              ]
            }
          }
        }));
      }

      setEdges((eds: any) =>
        eds.map((edge: any) => {
          return edge;
        })
      );
    }

    return newNode;
  };
  const validateNodeProperties = properties => {
    return properties.filter((property: any) => {
      if (
        property.required &&
        (Array.isArray(property.value)
          ? property.value.length === 0
          : !property.value) &&
        (property.primaryParentId
          ? properties.find((prop: any) => prop.id === property.primaryParentId)
              .value === true
          : false)
      ) {
        return null;
      }
      if (
        property.required &&
        (Array.isArray(property.value)
          ? property.value.length === 0
          : !property.value) &&
        (property.parentPropertyId
          ? Array.isArray(
              properties.find(
                (prop: any) => prop.id === property.parentPropertyId
              ).value
            )
            ? properties
                .find((prop: any) => prop.id === property.parentPropertyId)
                .value.includes(property.parentPropertyValue)
            : properties.find(
                (prop: any) => prop.id === property.parentPropertyId
              ).value === property.parentPropertyValue
          : true)
      ) {
        return property;
      }
    }).length;
  };

  const nodeValidator = (id, properties) => {
    const errorCount = validateNodeProperties(properties);
    setIsPropertyConfigError((prev: any) => {
      return prev.set(id, !!errorCount);
    });
  };

  const updateStateOnNodesDelete = (id: string) => {
    setSelectedNode(null);
    setTree((tr: any) => {
      const copy = { ...tr };
      if (copy.entryNodeId === id) {
        copy.entryNodeId = "";
      }

      nodes.map((node: any) => {
        if (node.parentNode && node.parentNode === id) {
          delete copy.nodes[node.id];
        }
      });

      nodes.map((node: any) => {
        if (node.id === id && !node.isChildNode) {
          delete copy.nodes[id];
        }
      });

      for (const eachNode in copy.nodes) {
        copy.nodes[eachNode].connections.map((elem: any) => {
          Object.keys(elem).map((key: any) => {
            return (elem[key] = elem[key] === id ? "" : elem[key]);
          });
          return elem;
        });
      }
      return copy;
    });

    setNodes((nds: any) => {
      const mappedArray = nds.map((node: any) => {
        node.nodes = node.nodes
          ? node.nodes.filter((nd: any) => {
              return nd.id !== id;
            })
          : null;
        return node;
      });
      const filteredArray = mappedArray.filter((node: any) => {
        return node.id !== id;
      });
      const finalArray = filteredArray.filter((node: any) => {
        if (node.parentNode) {
          if (node.parentNode !== id) {
            return node;
          } else return null;
        }
        return node;
      });
      return finalArray;
    });
    setEdges((eds: any) =>
      eds.filter((edge: any) => {
        return edge.source !== id && edge.target !== id;
      })
    );
    setIsPropertyConfigError(prev => {
      prev.delete(id);
      return prev;
    });

    nodes.map((node: any) => {
      node.id === id && node.type === "webpage" && node.nodes
        ? node.nodes.map((node: any) => {
            setIsPropertyConfigError(prev => {
              prev.delete(node.id);
              return prev;
            });
            return node;
          })
        : null;
      return node;
    });
  };
  const updateDynamicProperties = (
    type: any,
    initialId: string,
    recursiveId: any,
    visitedNodesIdArray: any[] = []
  ) => {
    if (
      nodes.find((node: any) => {
        return node.id === recursiveId && node.type === "webpage";
      })
    ) {
      const foundNode = nodes.find((node: any) => {
        return node.id === recursiveId;
      });
      setNodes((nds: any) =>
        nds.map((node: any) => {
          node.id === initialId
            ? node.data.properties.find((property: any) => {
                return property.id === "1" && Array.isArray(property.options)
                  ? property.options.splice(
                      0,
                      property.options.length,
                      ...(foundNode
                        ? foundNode.nodes
                            .filter((node: any) => {
                              return type === "auth"
                                ? node.type === "email" ||
                                    node.type === "phone" ||
                                    node.type === "username" ||
                                    node.type === "Twitter" ||
                                    node.type === "Facebook" ||
                                    node.type === "Google"
                                : type === "sendotp"
                                ? node.type === "email" || node.type === "phone"
                                : null;
                            })
                            .map((node: any) => {
                              return {
                                text: node.data.label,
                                value: node.type
                              };
                            })
                        : null)
                    )
                  : null;
              })
            : null;
          return node;
        })
      );
    }
    if (!recursiveId) {
      setNodes((nds: any) =>
        nds.map((node: any) => {
          node.id === initialId
            ? node.data.properties.find((property: any) => {
                property.type === "select" && property.id === "1"
                  ? ((property.options.length = 0), (property.value = ""))
                  : null;
              })
            : null;
          return node;
        })
      );

      return;
    } else if (tree.entryNodeId === recursiveId) {
      visitedNodesIdArray.length = 0;

      return;
    }

    const nextNodeIdArray = Object.keys(tree.nodes).filter((node: any) => {
      return tree.nodes[node].connections.find((conn: any) => {
        return (
          conn.output === recursiveId ||
          conn.true === recursiveId ||
          conn.false === recursiveId
        );
      });
    });

    visitedNodesIdArray.push(recursiveId);

    const updatedNextNodeArray = nextNodeIdArray.filter(
      element => !visitedNodesIdArray.includes(element)
    );
    updatedNextNodeArray.length
      ? updatedNextNodeArray.map((nextNodeId: any) => {
          // console.log(visitedNodesIdArray);
          return updateDynamicProperties(
            type,
            initialId,
            nextNodeId,

            visitedNodesIdArray
          );
        })
      : updateDynamicProperties(
          type,
          initialId,
          null,

          visitedNodesIdArray
        );
    return;
  };
  return (
    <StateContext.Provider
      value={{
        selectedNode,
        setSelectedNode,
        tree,
        setTree,
        nodes,
        setNodes,
        edges,
        setEdges,
        dragOverParentNode,
        setDragOverParentNode,
        setDraggedNode,
        draggedNode,
        policies,
        setPolicies,
        validateNodeProperties,
        isPropertyConfigError,
        setIsPropertyConfigError,
        updateStateOnNodesDelete,
        updateDynamicProperties,
        checkParentNodeDrop,
        nodeValidator,
        updatedTree,
        setUpdatedTree
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);

import {
  ObjectSchema,
  SideEffectSchema,
  loadingAction,
  errorAction,
  successSideEffectState,
  generateConst
} from ".";

// Constants
export const SupportConst = generateConst("SUPPORT", [
  "GET_USERS_FOR_APP",
  "GET_CUSTOMER_DATA",
  "GET_FILE_PICKER_KEY",
  "SUBMIT_TICKET",
  "GET_EXISTING_TICKETS"
]);

/**
 * GET_EXISTING_TICKETS
 */

export interface GetExistingTicketsSchema extends SideEffectSchema {
  response: TicketSchema[];
}

export interface TicketSchema {
  id: string;
  status?: string;
  createdAt?: string;
  subject?: string;
}

export const getExistingTicketsAction = () => {
  return {
    type: SupportConst.GET_EXISTING_TICKETS,
    payload: {}
  };
};

export const getExistingTicketsLoadingAction = () =>
  loadingAction(SupportConst.GET_EXISTING_TICKETS_LOADING);

export const getExistingTicketsSuccessAction = (
  data: GetExistingTicketsSchema
) => {
  const payload = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: SupportConst.GET_EXISTING_TICKETS_SUCCESS,
    payload
  };
};

export const getExistingTicketsErrorAction = (error: string) =>
  errorAction(SupportConst.GET_EXISTING_TICKETS_ERROR, error);

/**
 * GET_USERS_FOR_APP
 */

export interface GetUsersForAppSchema extends SideEffectSchema {
  data: any;
}

export const getUsersForAppAction = data => {
  return {
    type: SupportConst.GET_USERS_FOR_APP,
    payload: { ...data }
  };
};

export const getUsersForAppLoadingAction = () =>
  loadingAction(SupportConst.GET_USERS_FOR_APP_LOADING);

export const getUsersForAppErrorAction = (error: string) =>
  errorAction(SupportConst.GET_USERS_FOR_APP_ERROR, error);

export const getUsersForAppSuccessAction = (data: GetUsersForAppSchema) => {
  const payload: GetUsersForAppSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: SupportConst.GET_USERS_FOR_APP_SUCCESS,
    payload
  };
};

/**
 * GET_CUSTOMER_DATA
 */

export interface GetCustomerDataSchema extends SideEffectSchema {
  data: any;
}

export const getCustomerDataAction = () => {
  return {
    type: SupportConst.GET_CUSTOMER_DATA,
    payload: {}
  };
};

export const getCustomerDataLoadingAction = () =>
  loadingAction(SupportConst.GET_CUSTOMER_DATA_LOADING);

export const getCustomerDataErrorAction = (error: string) =>
  errorAction(SupportConst.GET_CUSTOMER_DATA_ERROR, error);

export const getCustomerDataSuccessAction = (data: GetCustomerDataSchema) => {
  const payload: GetCustomerDataSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: SupportConst.GET_CUSTOMER_DATA_SUCCESS,
    payload
  };
};

/**
 * GET_FILE_PICKER_KEY
 */

export interface GetFilePickerKeySchema extends SideEffectSchema {
  data: { FilePickerKey: string; clientIP?: string };
}

export const getFilePickerKeyAction = () => {
  return {
    type: SupportConst.GET_FILE_PICKER_KEY,
    payload: {}
  };
};

export const getFilePickerKeyLoadingAction = () =>
  loadingAction(SupportConst.GET_FILE_PICKER_KEY_LOADING);

export const getFilePickerKeyErrorAction = (error: string) =>
  errorAction(SupportConst.GET_FILE_PICKER_KEY_ERROR, error);

export const getFilePickerKeySuccessAction = data => {
  const payload: GetFilePickerKeySchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: SupportConst.GET_FILE_PICKER_KEY_SUCCESS,
    payload
  };
};

/**
 * SUBMIT_TICKET
 */

export interface SendTicketDataSchema extends SideEffectSchema {
  data?: any;
  payload?: any;
}

export const sendTicketDataAction = data => {
  return {
    type: SupportConst.SUBMIT_TICKET,
    payload: { ...data }
  };
};

export const sendTicketDataLoadingAction = () =>
  loadingAction(SupportConst.SUBMIT_TICKET_LOADING);

export const sendTicketDataErrorAction = (error: string) =>
  errorAction(SupportConst.SUBMIT_TICKET_ERROR, error);

export const sendTicketDataSuccessAction = (data: SendTicketDataSchema) => {
  const payload: SendTicketDataSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: SupportConst.SUBMIT_TICKET_SUCCESS,
    payload
  };
};

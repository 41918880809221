import { combineReducers } from "redux";
import { ActionSchema } from "../actions";
import {
  PasswordPolicyAction,
  AppInfoSchema,
  PeriodicSchema,
  PasswordCompliexitySchema,
  AppBasicSettingsAction,
  RiskBasedAuthenticationAction,
  RiskAuthSchema,
  MFAActionSchema,
  PinAuthAction,
  PinAuthSchema,
  UpdatePinAuthSchema,
  SecurityQuestionsSchema,
  SecurityQuestionsAction,
  WebhookSecretsSchema,
  WebhookSecretsAction,
  PushNotificationsAction,
  PushNotificationsResponse
} from "./../actions/securityConfigurationActions";
import { AppBasicInfoSchema } from "../actions/authActions";

const appInfoReducer = (state = {}, action: ActionSchema<AppInfoSchema>) => {
  switch (action.type) {
    case PasswordPolicyAction.GET_APP_INFO_LOADING:
    case PasswordPolicyAction.GET_APP_INFO_ERROR:
    case PasswordPolicyAction.GET_APP_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
const getMFAActionEnabledReducer = (
  state = {},
  action: ActionSchema<MFAActionSchema>
) => {
  switch (action.type) {
    case RiskBasedAuthenticationAction.GET_MFA_ACTIONS:
    case RiskBasedAuthenticationAction.UPDATE_MFA_ACTIONS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
const getRiskAuthSettingsReducer = (
  state = {},
  action: ActionSchema<RiskAuthSchema>
) => {
  switch (action.type) {
    case RiskBasedAuthenticationAction.GET_RISK_AUTH_SETTINGS_LOADING:
    case RiskBasedAuthenticationAction.GET_RISK_AUTH_SETTINGS_SUCCESS:
    case RiskBasedAuthenticationAction.GET_RISK_AUTH_SETTINGS_ERROR:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const updateRiskAuthSettingsReducer = (
  state = {},
  action: ActionSchema<RiskAuthSchema>
) => {
  switch (action.type) {
    case RiskBasedAuthenticationAction.UPDATE_RISK_AUTH_SETTINGS_LOADING:
    case RiskBasedAuthenticationAction.UPDATE_RISK_AUTH_SETTINGS_SUCCESS:
    case RiskBasedAuthenticationAction.UPDATE_RISK_AUTH_SETTINGS_ERROR:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const getPasswordComplexityReducer = (
  state = {},
  action: ActionSchema<PasswordCompliexitySchema>
) => {
  switch (action.type) {
    case PasswordPolicyAction.GET_PASSWORD_COMPLEXITY_LOADIN:
    case PasswordPolicyAction.GET_PASSWORD_COMPLEXITY_ERROR:
    case PasswordPolicyAction.GET_PASSWORD_COMPLEXITY_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const updateAppBasicSettingsReducer = (
  state = {},
  action: ActionSchema<AppBasicInfoSchema>
) => {
  switch (action.type) {
    case AppBasicSettingsAction.UPDATE_APP_BASIC_INFO_LOADING:
    case AppBasicSettingsAction.UPDATE_APP_BASIC_INFO_ERROR:
    case AppBasicSettingsAction.UPDATE_APP_BASIC_INFO_SUCCESS:
      delete state["errorCode"];
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const PriodicPasswordReducer = (
  state = {},
  action: ActionSchema<PeriodicSchema>
) => {
  switch (action.type) {
    case PasswordPolicyAction.GET_PERIODIC_PASSWORD_LOADING:
    case PasswordPolicyAction.GET_PERIODIC_PASSWORD_ERROR:
    case PasswordPolicyAction.GET_PERIODIC_PASSWORD_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getBreachedPasswordReducer = (
  state = {},
  action: ActionSchema<any>
) => {
  switch (action.type) {
    case PasswordPolicyAction.GET_BREACHED_PASSWORD_POLICY_LOADING:
    case PasswordPolicyAction.GET_BREACHED_PASSWORD_POLICY_ERROR:
    case PasswordPolicyAction.GET_BREACHED_PASSWORD_POLICY_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const postBreachedPasswordReducer = (
  state = {},
  action: ActionSchema<any>
) => {
  switch (action.type) {
    case PasswordPolicyAction.POST_BREACHED_PASSWORD_POLICY_LOADING:
    case PasswordPolicyAction.POST_BREACHED_PASSWORD_POLICY_ERROR:
    case PasswordPolicyAction.POST_BREACHED_PASSWORD_POLICY_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const pinAuthReducer = (state = {}, action: ActionSchema<PinAuthSchema>) => {
  switch (action.type) {
    case PinAuthAction.PIN_AUTH_LOADING:
    case PinAuthAction.PIN_AUTH_ERROR:
    case PinAuthAction.PIN_AUTH_SUCCESS:
      delete state["errorCode"];
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const updatePinAuthReducer = (
  state = {},
  action: ActionSchema<PinAuthSchema>
) => {
  switch (action.type) {
    case PinAuthAction.UPDATE_PIN_AUTH_LOADING:
      delete state["IsPosted"];
      return { ...state, ...action.payload };
    case PinAuthAction.UPDATE_PIN_AUTH_ERROR:
    case PinAuthAction.UPDATE_PIN_AUTH_SUCCESS:
      delete state["errorCode"];
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getSecurityQuestionsReducer = (
  state = {},
  action: ActionSchema<SecurityQuestionsSchema>
) => {
  switch (action.type) {
    case SecurityQuestionsAction.GET_SECURITY_QUESTIONS_SUCCESS:
    case SecurityQuestionsAction.GET_SECURITY_QUESTIONS_LOADING:
    case SecurityQuestionsAction.GET_SECURITY_QUESTIONS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getWebhookSecretOptionsReducer = (
  state = {},
  action: ActionSchema<WebhookSecretsSchema>
) => {
  switch (action.type) {
    case WebhookSecretsAction.GET_WEBHOOK_SECRETS_OPTIONS_SUCCESS:
    case WebhookSecretsAction.GET_WEBHOOK_SECRETS_OPTIONS_LOADING:
    case WebhookSecretsAction.GET_WEBHOOK_SECRETS_OPTIONS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const getPushNotificationsReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case PushNotificationsAction.GET_PUSH_NOTIFICATIONS_SUCCESS:
    case PushNotificationsAction.GET_PUSH_NOTIFICATIONS_LOADING:
    case PushNotificationsAction.GET_PUSH_NOTIFICATIONS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const postPushNotificationsReducer = (
  state = {},
  action: ActionSchema<any>
) => {
  switch (action.type) {
    case PushNotificationsAction.POST_PUSH_NOTIFICATIONS_SUCCESS:
    case PushNotificationsAction.POST_PUSH_NOTIFICATIONS_LOADING:
    case PushNotificationsAction.POST_PUSH_NOTIFICATIONS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const putPushNotificationsReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case PushNotificationsAction.PUT_PUSH_NOTIFICATIONS_SUCCESS:
    case PushNotificationsAction.PUT_PUSH_NOTIFICATIONS_LOADING:
    case PushNotificationsAction.PUT_PUSH_NOTIFICATIONS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const securityConfigurationReducer = combineReducers({
  PasswordComplexity: getPasswordComplexityReducer,
  PriodicPassword: PriodicPasswordReducer,
  appInfo: appInfoReducer,
  updateAppBasicSettings: updateAppBasicSettingsReducer,
  getRiskAuthSettings: getRiskAuthSettingsReducer,
  updateRiskAuthSettings: updateRiskAuthSettingsReducer,
  MFAEnabled: getMFAActionEnabledReducer,
  pinAuth: pinAuthReducer,
  updatePinAuth: updatePinAuthReducer,
  securityQuestions: getSecurityQuestionsReducer,
  webhookSecrets: getWebhookSecretOptionsReducer,
  getPushNotificationsResponse: getPushNotificationsReducer,
  postPushNotificationsResponse: postPushNotificationsReducer,
  putPushNotificationsResponse: putPushNotificationsReducer,
  getBreachedPassword: getBreachedPasswordReducer,
  postBreachedPassword: postBreachedPasswordReducer
});
export interface SecurityConfigurationSchema {
  PasswordComplexity: PasswordCompliexitySchema;
  PriodicPassword: PeriodicSchema;
  appInfo: AppInfoSchema;
  updateAppBasicSettings: AppBasicInfoSchema;
  getRiskAuthSettings: RiskAuthSchema;
  updateRiskAuthSettings: RiskAuthSchema;
  MFAEnabled: MFAActionSchema;
  pinAuth: PinAuthSchema;
  updatePinAuth: UpdatePinAuthSchema;
  securityQuestions: SecurityQuestionsSchema;
  webhookSecrets: WebhookSecretsSchema;
  getPushNotificationsResponse: PushNotificationsResponse;
  postPushNotificationsResponse: PushNotificationsResponse;
  putPushNotificationsResponse: PushNotificationsResponse;
  getBreachedPassword: any;
  postBreachedPassword: any;
}

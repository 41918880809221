import {
  SideEffectSchema,
  successSideEffectState,
  errorAction,
  loadingAction,
  generateConst
} from ".";
// Constants
export const UpdatesConst = generateConst("UPDATES", ["GET_PRODUCT_UPDATES"]);

// Schema
export interface ProductUpdateSchema {
  display: string;
  link: string;
  date: string;
}

export interface GetProductUpdatesDataSchema extends SideEffectSchema {
  data: ProductUpdateSchema[];
}

/**
 * Returns an action object for fetching a specified number of product updates.
 * @param count Count of product udpates to be retrieved.
 */
export const getProductUpdatesAction = (count: number) => {
  return {
    type: UpdatesConst.GET_PRODUCT_UPDATES,
    payload: count
  };
};

export const getProductUpdatesLoadingAction = () =>
  loadingAction(UpdatesConst.GET_PRODUCT_UPDATES_LOADING);

export const getProductUpdatesSuccessAction = (
  data: GetProductUpdatesDataSchema
) => {
  const payload: GetProductUpdatesDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: UpdatesConst.GET_PRODUCT_UPDATES_SUCCESS,
    payload
  };
};

export const getProductUpdatesErrorAction = (error: string) =>
  errorAction(UpdatesConst.GET_PRODUCT_UPDATES_ERROR, error);

import React from "react";
import Anchor from "../../components/Anchor";
import { RootSchema } from "../../redux/reducers";
import {
  getAccountThemeCustomizationAction,
  GetAccountThemeCustomizationSchema
} from "../../redux/actions/accountActions";
import { connect } from "react-redux";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { GetIntercomUserDataSchema } from "../../redux/actions/authActions";
import Image from "../../components/Image";
import IOlogo from "../../assets/image/io-logo.png";
import logo from "../../assets/image/logo-new.svg";
import { SiteResponseSchema } from "../../redux/actions/authActions";
const logoStyle = {
  fill: "#29d"
};
const logoStyleColor = {
  fill: "$white"
};
const logoWidth = {
  width: "128px",
  height: "35px"
};
export interface FooterProps {
  accountTheme: GetAccountThemeCustomizationSchema;
  intercomUserData: GetIntercomUserDataSchema;
  currentYear: number;
  fetchAccountTheme: (dir: string) => void;
  IOLoginUrl: string;
  appInfo: SiteResponseSchema;
}
interface FooterState {
  termsLink: string;
  privacyLink: string;
  systemLink: string;
}
class Footer extends React.Component<FooterProps, FooterState> {
  constructor(props) {
    super(props);
    this.state = {
      termsLink: "",
      privacyLink: "",
      systemLink: ""
    };
  }
  componentDidMount() {
    const {
      accountTheme: { Options },
      fetchAccountTheme
    } = this.props;
    if (!Options) {
      fetchAccountTheme("publish");
    }
  }
  componentWillReceiveProps(newProps: FooterProps) {
    let linkCollection = {
      termsLink: "",
      privacyLink: "",
      systemLink: ""
    };
    if (newProps.accountTheme) {
      let {
        accountTheme: { Options, isSuccess }
      } = newProps;
      if (Options && isSuccess) {
        if (
          Options["footerTheme@global-footer-links@0@footer-trems-value"] !== ""
        ) {
          linkCollection["termsLink"] =
            Options["footerTheme@global-footer-links@0@footer-trems-value"];
          if (
            String(
              Options["footerTheme@global-footer-links@1@footer-privacy-value"]
            ).trim() !== ""
          )
            linkCollection["privacyLink"] =
              Options["footerTheme@global-footer-links@1@footer-privacy-value"];
          if (
            String(
              Options["footerTheme@global-footer-links@2@footer-status-value"]
            ).trim() !== ""
          )
            linkCollection["systemLink"] =
              Options["footerTheme@global-footer-links@2@footer-status-value"];
          this.setState({
            systemLink: linkCollection.systemLink,
            termsLink: linkCollection.termsLink,
            privacyLink: linkCollection.privacyLink
          });
        }
      }
    }
  }

  render() {
    const {
      currentYear,
      accountTheme: { Options, isSuccess },
      intercomUserData,
      IOLoginUrl,
      appInfo
    } = this.props;
    let clientIp = "";

    if (intercomUserData.isSuccess) {
      clientIp = intercomUserData.response.clientIP;
    }
    return (
      <footer className="lr-footer mt-5 d-flex align-items-center  global-footer">
        {isSuccess && appInfo.AppName && (
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p>&copy;{currentYear} LoginRadius Inc.</p>
                <ul>
                  <li>
                    <Anchor
                      id={"lnk_dashboard_Terms"}
                      href={
                        this.state.termsLink ||
                        "https://www.loginradius.com/terms"
                      }
                      target="_blank"
                    >
                      Terms
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      id={"lnk_dashboard_Privacy"}
                      href={
                        this.state.privacyLink ||
                        "https://www.loginradius.com/privacy"
                      }
                      target="_blank"
                    >
                      Privacy
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      id={"lnk_dashboard_System_Status"}
                      href={
                        this.state.systemLink ||
                        "https://status.loginradius.com"
                      }
                      target="_blank"
                    >
                      System Status
                    </Anchor>
                  </li>
                </ul>
              </div>
              <div className="footer-logo">
                {!IOLoginUrl && (
                  <Image
                    className="brand-logo"
                    src={logo}
                    alt="LoginRadius Logo"
                  />
                )}
                {IOLoginUrl && (
                  <Image
                    className="brand-logo"
                    src={IOlogo}
                    alt="LoginRadius IO Logo"
                    width={128}
                  />
                )}
                {/* {typeof Options !== "undefined" && <span>Powered By</span>} */}
              </div>
              <div>
                {!IOLoginUrl && (
                  <p>
                    Last Login Activity:{" "}
                    <Link
                      className="anchor-link global-link"
                      id={"lnk_dashboard_Last_Account_Activity"}
                      to={"/account/account-settings/login-session"}
                    >
                      {intercomUserData.isSuccess &&
                        intercomUserData.response.lastActivityDetail}{" "}
                      ago
                    </Link>
                  </p>
                )}
                <p style={{ textAlign: "right" }}>
                  IP: {clientIp && clientIp.split(":")[0]}
                </p>
              </div>
            </div>
          </div>
        )}
      </footer>
    );
  }
}

const mapStateToProps = (state: RootSchema) => {
  const {
    account: { accountThemeCustomization }
  } = state;
  return {
    accountTheme: accountThemeCustomization,
    intercomUserData: state.auth.intercomUserData,
    IOLoginUrl: state.auth.authConfig.IOLoginUrl,
    appInfo: state.auth.currentAppinfo
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchAccountTheme: (args: string) =>
      dispatch(getAccountThemeCustomizationAction(args))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

import {
  generateConst,
  loadingAction,
  errorAction,
  successSideEffectState,
  SideEffectSchema,
  ObjectSchema,
  APIErrorResponseSchema
} from ".";

// Constants
export const PlatformConfigurationConst = generateConst(
  "PLATFORM_CONFIGURATION",
  [
    "GET_GLOBAL_EMAIL_CONFIG",
    "SAVE_GLOBAL_EMAIL_CONFIG",
    "GET_API_KEY",
    "GET_SMS_SETTINGS_AND_CONFIG",
    "GET_SMS_TEMPLATES",
    "GET_SMS_PROVIDER_CONFIG",
    "GET_CUSTOM_SMS_PROVIDER_CONFIG",
    "SAVE_SMS_CONFIG",
    "SAVE_CUSTOM_SMS_CONFIG",
    "RESET_SMS_CONFIG",
    "GET_GLOBAL_SMS_SETTINGS",
    "SAVE_GLOBAL_SMS_SETTINGS",
    "GET_ROLES",
    "GET_CONFIGURATION_LIST",
    "GET_SOCIALPROVIDER_LIST",
    "TRANSFER_CONFIGURATIONS",
    "GET_MULTIPLE_SOCIAL_PROVIDER",
    "POST_MULTIPLE_SOCIAL_PROVIDER",
    "DELETE_MULTIPLE_SOCIAL_PROVIDER",
    "PUT_MULTIPLE_SOCIAL_PROVIDER",
    "GET_AUTH_SERVER_API_CONFIG",
    "POST_AUTH_SERVER_API_CONFIG",
    "PUT_AUTH_SERVER_API_CONFIG",
    "DELETE_AUTH_SERVER_API_CONFIG",
    "SET_APP_FEATURES",
    "RENEW_CERTIFICATE",
    "RENEW_CERTIFICATE_LOADING",
    "RENEW_CERTIFICATE_SUCCESS",
    "RENEW_CERTIFICATE_ERROR",
    "SSO_INTEGRATION",
    "SSO_INTEGRATION_LOADING",
    "SSO_INTEGRATION_SUCCESS",
    "SSO_INTEGRATION_ERROR",
    "AVAILABLE_SSO_INTEGRATION",
    "AVAILABLE_SSO_INTEGRATION_LOADING",
    "AVAILABLE_SSO_INTEGRATION_SUCCESS",
    "AVAILABLE_SSO_INTEGRATION_ERROR",
    "SAML_INTEGRATION_BY_TYPE",
    "SAML_INTEGRATION_BY_TYPE_LOADING",
    "SAML_INTEGRATION_BY_TYPE_SUCCESS",
    "SAML_INTEGRATION_BY_TYPE_ERROR",
    "POST_SSO_INTEGRATION",
    "POST_SSO_INTEGRATION_LOADING",
    "POST_SSO_INTEGRATION_SUCCESS",
    "POST_SSO_INTEGRATION_ERROR",
    "PUT_SSO_INTEGRATION",
    "PUT_SSO_INTEGRATION_LOADING",
    "PUT_SSO_INTEGRATION_SUCCESS",
    "PUT_SSO_INTEGRATION_ERROR",
    "DELETE_SSO_INTEGRATION",
    "DELETE_SSO_INTEGRATION_LOADING",
    "DELETE_SSO_INTEGRATION_SUCCESS",
    "DELETE_SSO_INTEGRATION_ERROR",
    "GET_OAUTH_PROVIDERS",
    "GET_JWT_PROVIDERS",
    "GET_SAML_PROVIDERS",
    "GET_OIDC_AND_OAUTH_APPS",
    "GET_PASSKEY_CONFIG",
    "GET_PASSKEY_CONFIG_LOADING",
    "GET_PASSKEY_CONFIG_SUCCESS",
    "GET_PASSKEY_CONFIG_ERROR",
    "UPDATE_PASSKEY_CONFIG",
    "UPDATE_PASSKEY_CONFIG_LOADING",
    "UPDATE_PASSKEY_CONFIG_SUCCESS",
    "UPDATE_PASSKEY_CONFIG_ERROR"
  ]
);
/**
 * GET_MULTIPLE_SOCIAL_PROVIDER
 */
export interface MultipleSocialProvider {
  SocialAppName: string;
  AppleSecretConfiguration?: {
    ServiceId: string;
    BundleId: string;
    TeamId: string;
    Certificate: string;
    KeyIdentifier: string;
  };
  Provider: string;
  ProviderKey: string;
  ProviderSecret: string;
  ProviderId: string;
  HtmlFileName: string;
  Scope: string[];
  Status: false;
}
export interface GetMultipleSocialProviderSchema extends SideEffectSchema {
  response: MultipleSocialProvider[];
  method?: string;
  updateMethodProp?: () => void;
  error?: any;
}

export const deleteMultipleSocialProviderAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.DELETE_MULTIPLE_SOCIAL_PROVIDER,
    payload: payload
  };
};
export const deleteMultipleSocialProviderSuccessAction = (response: any) => {
  const payload = {
    response,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.DELETE_MULTIPLE_SOCIAL_PROVIDER_SUCCESS,
    payload
  };
};
export const deleteMultipleSocialProviderLoadingAction = () =>
  loadingAction(
    PlatformConfigurationConst.DELETE_MULTIPLE_SOCIAL_PROVIDER_LOADING
  );
export const deleteMultipleSocialProviderErrorAction = (error: string) =>
  errorAction(
    PlatformConfigurationConst.DELETE_MULTIPLE_SOCIAL_PROVIDER_ERROR,
    error
  );

export const putMultipleSocialProviderAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.PUT_MULTIPLE_SOCIAL_PROVIDER,
    payload: payload
  };
};
export const putMultipleSocialProviderSuccessAction = (response: any) => {
  const payload = {
    response,
    ...successSideEffectState,
    method: "put",
    updateMethodProp: function() {
      this.method = "get";
    }
  };
  return {
    type: PlatformConfigurationConst.PUT_MULTIPLE_SOCIAL_PROVIDER_SUCCESS,
    payload
  };
};
export const putMultipleSocialProviderLoadingAction = () =>
  loadingAction(
    PlatformConfigurationConst.PUT_MULTIPLE_SOCIAL_PROVIDER_LOADING
  );
export const putMultipleSocialProviderErrorAction = (error: string) =>
  errorAction(
    PlatformConfigurationConst.PUT_MULTIPLE_SOCIAL_PROVIDER_ERROR,
    error
  );

export const postMultipleSocialProviderAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.POST_MULTIPLE_SOCIAL_PROVIDER,
    payload: payload
  };
};
export const postMultipleSocialProviderSuccessAction = (response: any) => {
  const payload = {
    response,
    ...successSideEffectState,
    method: "post",
    updateMethodProp: function() {
      this.method = "get";
    }
  };
  return {
    type: PlatformConfigurationConst.POST_MULTIPLE_SOCIAL_PROVIDER_SUCCESS,
    payload
  };
};
export const postMultipleSocialProviderLoadingAction = () =>
  loadingAction(
    PlatformConfigurationConst.POST_MULTIPLE_SOCIAL_PROVIDER_LOADING
  );
export const postMultipleSocialProviderErrorAction = (error: string) =>
  errorAction(
    PlatformConfigurationConst.POST_MULTIPLE_SOCIAL_PROVIDER_ERROR,
    error
  );

export const getMultipleSocialProviderAction = () => {
  return {
    type: PlatformConfigurationConst.GET_MULTIPLE_SOCIAL_PROVIDER,
    payload: {}
  };
};
export const getMultipleSocialProviderLoadingAction = () =>
  loadingAction(
    PlatformConfigurationConst.GET_MULTIPLE_SOCIAL_PROVIDER_LOADING
  );

export const getMultipleSocialProviderSuccessAction = (response: any) => {
  const payload = {
    response,
    ...successSideEffectState,
    method: "get"
  };
  return {
    type: PlatformConfigurationConst.GET_MULTIPLE_SOCIAL_PROVIDER_SUCCESS,
    payload
  };
};

export const getMultipleSocialProviderErrorAction = (error: string) =>
  errorAction(
    PlatformConfigurationConst.GET_MULTIPLE_SOCIAL_PROVIDER_ERROR,
    error
  );
/**
 * GET_GLOBAL_EMAIL_CONFIG
 */

export interface EmailConfigResponseSchema {
  description?: string;
  errorCode?: number;
  message?: string;
  EmailLinkExpire?: number;
  EmailNotificationCount?: number;
  EmailNotificationFrequency?: number;
}

export interface GetGlobalEmailConfigSchema extends SideEffectSchema {
  response: EmailConfigResponseSchema;
}

export const getGlobalEmailConfigAction = () => {
  return {
    type: PlatformConfigurationConst.GET_GLOBAL_EMAIL_CONFIG,
    payload: {}
  };
};

export const getGlobalEmailConfigLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_GLOBAL_EMAIL_CONFIG_LOADING);

export const getGlobalEmailConfigSuccessAction = (
  response: EmailConfigResponseSchema
) => {
  const payload = {
    response,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_GLOBAL_EMAIL_CONFIG_SUCCESS,
    payload
  };
};

export const getGlobalEmailConfigErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.GET_GLOBAL_EMAIL_CONFIG_ERROR, error);

/**
 * SAVE_GLOBAL_EMAIL_CONFIG
 */

// for saving global email config, both the body and the response schema includes the same 3 attributes below,
// so I'm defining 1 common schema for both the body of the request and the received response.
export interface SaveEmailConfigBodyAndResponseSchema {
  EmailLinkExpire?: number;
  EmailNotificationCount?: number;
  EmailNotificationFrequency?: number;
  description?: string;
  errorCode?: number;
  message?: string;
}

export interface SaveGlobalEmailConfigSchema extends SideEffectSchema {
  response: SaveEmailConfigBodyAndResponseSchema;
}

export const saveGlobalEmailConfigAction = (
  payload: SaveEmailConfigBodyAndResponseSchema
) => {
  return {
    type: PlatformConfigurationConst.SAVE_GLOBAL_EMAIL_CONFIG,
    payload
  };
};

export const saveGlobalEmailConfigLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.SAVE_GLOBAL_EMAIL_CONFIG_LOADING);

export const saveGlobalEmailConfigSuccessAction = (
  response: SaveEmailConfigBodyAndResponseSchema
) => {
  const payload = {
    response,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.SAVE_GLOBAL_EMAIL_CONFIG_SUCCESS,
    payload
  };
};

export const saveGlobalEmailConfigErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.SAVE_GLOBAL_EMAIL_CONFIG_ERROR, error);

/**
 * GET_API_KEY
 */

export interface GetApiKeySchema extends SideEffectSchema {
  Key?: string;
  zendeskKey?: string;
  algoliaId?: string;
  intercomUserHash?: string;
}

export const getApiKeyAction = () => {
  return {
    type: PlatformConfigurationConst.GET_API_KEY,
    payload: {}
  };
};

export const getApiKeyErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.GET_API_KEY_ERROR, error);

export const getApiKeyLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_API_KEY_LOADING);

export const getApiKeySuccessAction = (response: GetApiKeySchema) => {
  const payload = {
    ...response,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_API_KEY_SUCCESS,
    payload
  };
};

/**
 * GET_SMS_SETTINGS_AND_CONFIG
 */

export interface GetSMSSettingsAndConfigSchema extends SideEffectSchema {
  data?: any;
}

export const getSMSSettingsAndConfigAction = () => {
  return {
    type: PlatformConfigurationConst.GET_SMS_SETTINGS_AND_CONFIG,
    payload: {}
  };
};

export const getSMSSettingsAndConfigLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_SMS_SETTINGS_AND_CONFIG_LOADING);

export const getSMSSettingsAndConfigErrorAction = (error: string) =>
  errorAction(
    PlatformConfigurationConst.GET_SMS_SETTINGS_AND_CONFIG_ERROR,
    error
  );

export const getSMSSettingsAndConfigSuccessAction = (
  data: GetSMSSettingsAndConfigSchema
) => {
  const payload: GetSMSSettingsAndConfigSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_SMS_SETTINGS_AND_CONFIG_SUCCESS,
    payload
  };
};

/**
 * GET_SMS_TEMPLATES
 */

export interface GetSMSTemplatesSchema extends SideEffectSchema {
  data?: any;
}

export const getSMSTemplatesAction = () => {
  return {
    type: PlatformConfigurationConst.GET_SMS_TEMPLATES,
    payload: {}
  };
};

export const getSMSTemplatesLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_SMS_TEMPLATES_LOADING);

export const getSMSTemplatesErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.GET_SMS_TEMPLATES_ERROR, error);

export const getSMSTemplatesSuccessAction = (data: GetSMSTemplatesSchema) => {
  const payload: GetSMSTemplatesSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_SMS_TEMPLATES_SUCCESS,
    payload
  };
};

/**
 * GET_SMS_PROVIDER_CONFIG
 */

export interface GetSMSProviderConfigSchema extends SideEffectSchema {
  data?: any;
}

export const getSMSProviderConfigAction = () => {
  return {
    type: PlatformConfigurationConst.GET_SMS_PROVIDER_CONFIG,
    payload: {}
  };
};

export const getSMSProviderConfigLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_SMS_PROVIDER_CONFIG_LOADING);

export const getSMSProviderConfigErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.GET_SMS_PROVIDER_CONFIG_ERROR, error);

export const getSMSProviderConfigSuccessAction = (
  data: GetSMSProviderConfigSchema
) => {
  const payload: GetSMSProviderConfigSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_SMS_PROVIDER_CONFIG_SUCCESS,
    payload
  };
};

/**
 * GET_CUSTOM_SMS_PROVIDER_CONFIG
 */

export interface GetCustomSMSProviderConfigSchema extends SideEffectSchema {
  data?: any;
}

export const getCustomSMSProviderConfigAction = () => {
  return {
    type: PlatformConfigurationConst.GET_CUSTOM_SMS_PROVIDER_CONFIG,
    payload: {}
  };
};

export const getCustomSMSProviderConfigLoadingAction = () =>
  loadingAction(
    PlatformConfigurationConst.GET_CUSTOM_SMS_PROVIDER_CONFIG_LOADING
  );

export const getCustomSMSProviderConfigErrorAction = (error: string) =>
  errorAction(
    PlatformConfigurationConst.GET_CUSTOM_SMS_PROVIDER_CONFIG_ERROR,
    error
  );

export const getCustomSMSProviderConfigSuccessAction = (
  data: GetCustomSMSProviderConfigSchema
) => {
  const payload: GetCustomSMSProviderConfigSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_CUSTOM_SMS_PROVIDER_CONFIG_SUCCESS,
    payload
  };
};

/**
 * SAVE_SMS_CONFIG
 */

export interface SaveSMSConfigSchema extends SideEffectSchema {
  data?: any;
}

export const saveSMSConfigAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.SAVE_SMS_CONFIG,
    payload
  };
};

export const saveSMSConfigLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.SAVE_SMS_CONFIG_LOADING);

export const saveSMSConfigErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.SAVE_SMS_CONFIG_ERROR, error);

export const saveSMSConfigSuccessAction = (data: SaveSMSConfigSchema) => {
  const payload: SaveSMSConfigSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.SAVE_SMS_CONFIG_SUCCESS,
    payload
  };
};

/**
 * SAVE_CUSTOM_SMS_CONFIG
 */

export interface SaveCustomSMSConfigSchema extends SideEffectSchema {
  data?: any;
}

export const saveCustomSMSConfigAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.SAVE_CUSTOM_SMS_CONFIG,
    payload
  };
};

export const saveCustomSMSConfigLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.SAVE_CUSTOM_SMS_CONFIG_LOADING);

export const saveCustomSMSConfigErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.SAVE_CUSTOM_SMS_CONFIG_ERROR, error);

export const saveCustomSMSConfigSuccessAction = (
  data: SaveCustomSMSConfigSchema
) => {
  const payload: SaveCustomSMSConfigSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.SAVE_CUSTOM_SMS_CONFIG_SUCCESS,
    payload
  };
};

/**
 * RESET_SMS_CONFIG
 */

export interface ResetSMSConfigSchema extends SideEffectSchema {
  data?: any;
}

export const resetSMSConfigAction = () => {
  return {
    type: PlatformConfigurationConst.RESET_SMS_CONFIG,
    payload: {}
  };
};

export const resetSMSConfigLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.RESET_SMS_CONFIG_LOADING);

export const resetSMSConfigErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.RESET_SMS_CONFIG_ERROR, error);

export const resetSMSConfigSuccessAction = (data: ResetSMSConfigSchema) => {
  const payload: ResetSMSConfigSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.RESET_SMS_CONFIG_SUCCESS,
    payload
  };
};

/**
 * GET_GLOBAL_SMS_SETTINGS
 */

export interface SMSSettingsResponseSchema {
  OtpNotificationCount?: number | string;
  OtpNotificationFrequency?: number | string;
  OtpExpire?: number | string;
  description?: string;
  errorCode?: number;
  message?: string;
  Language?: string;
  RepeatOtpOnCall?: number | string;
  VoiceType?: string;
  OtpDeliveryMethod?: string;
}

export interface GetGlobalSMSConfigSchema extends SideEffectSchema {
  response: SMSSettingsResponseSchema;
}

export const getGlobalSMSSettingsAction = () => {
  return {
    type: PlatformConfigurationConst.GET_GLOBAL_SMS_SETTINGS,
    payload: {}
  };
};

export const getGlobalSMSSettingsLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_GLOBAL_SMS_SETTINGS_LOADING);

export const getGlobalSMSSettingsSuccessAction = (
  response: SMSSettingsResponseSchema
) => {
  const payload = {
    response,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_GLOBAL_SMS_SETTINGS_SUCCESS,
    payload
  };
};

export const getGlobalSMSSettingsErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.GET_GLOBAL_SMS_SETTINGS_ERROR, error);

/**
 * SAVE_GLOBAL_SMS_SETTINGS
 */

export interface SaveSMSSettingsBodyAndResponseSchema {
  OtpNotificationCount?: number | string;
  OtpNotificationFrequency?: number | string;
  OtpExpire?: number | string;
  description?: string;
  errorCode?: number;
  message?: string;
}

export interface SaveGlobalSMSConfigSchema extends SideEffectSchema {
  response: SaveSMSSettingsBodyAndResponseSchema;
}

export const saveGlobalSMSSettingsAction = (
  payload: SaveSMSSettingsBodyAndResponseSchema
) => {
  return {
    type: PlatformConfigurationConst.SAVE_GLOBAL_SMS_SETTINGS,
    payload
  };
};

export const saveGlobalSMSSettingsLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.SAVE_GLOBAL_SMS_SETTINGS_LOADING);

export const saveGlobalSMSSettingsSuccessAction = (
  response: SaveSMSSettingsBodyAndResponseSchema
) => {
  const payload = {
    response,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.SAVE_GLOBAL_SMS_SETTINGS_SUCCESS,
    payload
  };
};

export const saveGlobalSMSSettingsErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.SAVE_GLOBAL_SMS_SETTINGS_ERROR, error);

/**
 * GET_ROLES
 * Get the role(s) from the user
 */
export interface GetRolesSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  Count?: number;
  data?: RoleSchema[];
}
export interface RoleSchema {
  Name: string;
  Permissions: ObjectSchema<boolean>;
}
export const getRolesAction = () => {
  return {
    type: PlatformConfigurationConst.GET_ROLES,
    payload: {}
  };
};
export const getRolesLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_ROLES_LOADING);
export const getRolesErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.GET_ROLES_ERROR, error);
export const getRolesSuccessAction = (data: GetRolesSchema) => {
  const payload: GetRolesSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_ROLES_SUCCESS,
    payload
  };
};

/**
 * GET_CONFIGURATION_LIST
 */
export interface GetConfigurationListSchema extends SideEffectSchema {
  data: { name: string; description: string; params: string[] }[];
}
export const getConfigurationListAction = () => {
  return { type: PlatformConfigurationConst.GET_CONFIGURATION_LIST };
};
export const getConfigurationListLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_CONFIGURATION_LIST_LOADING);
export const getConfigurationListErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.GET_CONFIGURATION_LIST_ERROR, error);
export const getConfigurationListSuccessAction = (
  data: GetConfigurationListSchema
) => {
  const payload: GetConfigurationListSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_CONFIGURATION_LIST_SUCCESS,
    payload
  };
};

/**
 * GET_SOCIALPROVIDER_LIST
 */

export interface ProviderSchema {
  name: string;
  display: string;
  selected: boolean;
  order: number;
  configured: boolean;
  options: any[];
  mdfile: string;
  scopes: string[];
  multiStep?: boolean;
  isActiveClass?: boolean;
}
export interface GetSocialProviderListSchema extends SideEffectSchema {
  data: ProviderSchema[];
}
export interface ScopesSchema {
  Name: string;
  Description: string;
}
export interface AuthServerConfigApiSchema {
  Id: string;
  Scopes: ScopesSchema[];
  Identifier: string;
  Name: string;
}
export interface GetAuthServerConfigApiSchema extends SideEffectSchema {
  Data: AuthServerConfigApiSchema[];
  description?: string;
  errorCode?: number;
  message?: string;
}
export const getSocialProviderListAction = () => {
  return { type: PlatformConfigurationConst.GET_SOCIALPROVIDER_LIST };
};
export const getSocialProviderListLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_SOCIALPROVIDER_LIST_LOADING);
export const getSocialProviderListErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.GET_SOCIALPROVIDER_LIST_ERROR, error);
export const getSocialProviderListSuccessAction = (
  data: GetSocialProviderListSchema
) => {
  const payload: GetSocialProviderListSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_SOCIALPROVIDER_LIST_SUCCESS,
    payload
  };
};

/**
 * TRANSFER_CONFIGURATIONS
 */
export interface TransferConfigurationsSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  Edited?: boolean;
}
export interface TransferConfigurationsArgs {
  sourceAppId: number;
  destAppId: number;
  configurations: string[];
}
export const transferConfigurationsAction = (
  payload: TransferConfigurationsArgs
) => {
  return { type: PlatformConfigurationConst.TRANSFER_CONFIGURATIONS, payload };
};
export const transferConfigurationsLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.TRANSFER_CONFIGURATIONS_LOADING);
export const transferConfigurationsErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.TRANSFER_CONFIGURATIONS_ERROR, error);
export const transferConfigurationsSuccessAction = (
  data: TransferConfigurationsSchema
) => {
  const payload: TransferConfigurationsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.TRANSFER_CONFIGURATIONS_SUCCESS,
    payload
  };
};

export const getAuthServerAPIConfigAction = () => {
  return {
    type: PlatformConfigurationConst.GET_AUTH_SERVER_API_CONFIG,
    payload: {}
  };
};
export const getAuthServerAPIConfigSuccessAction = (response: any) => {
  const payload = {
    ...response,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_AUTH_SERVER_API_CONFIG_SUCCESS,
    payload
  };
};
export const getAuthServerAPIConfigLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_AUTH_SERVER_API_CONFIG_LOADING);
export const getAuthServerAPIConfigErrorAction = (error: string) =>
  errorAction(
    PlatformConfigurationConst.GET_AUTH_SERVER_API_CONFIG_ERROR,
    error
  );

export const putAuthServerAPIConfigAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.PUT_AUTH_SERVER_API_CONFIG,
    payload: payload
  };
};
export const putAuthServerAPIConfigSuccessAction = (response: any) => {
  const payload = {
    response,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.PUT_AUTH_SERVER_API_CONFIG_SUCCESS,
    payload
  };
};
export const putAuthServerAPIConfigLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.PUT_AUTH_SERVER_API_CONFIG_LOADING);
export const putAuthServerAPIConfigErrorAction = (error: string) =>
  errorAction(
    PlatformConfigurationConst.PUT_AUTH_SERVER_API_CONFIG_ERROR,
    error
  );

export const postAuthServerAPIConfigAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.POST_AUTH_SERVER_API_CONFIG,
    payload: payload
  };
};
export const postAuthServerAPIConfigSuccessAction = (response: any) => {
  const payload = {
    response,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.POST_AUTH_SERVER_API_CONFIG_SUCCESS,
    payload
  };
};
export const postAuthServerAPIConfigLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.POST_AUTH_SERVER_API_CONFIG_LOADING);
export const postAuthServerAPIConfigErrorAction = (error: string) =>
  errorAction(
    PlatformConfigurationConst.POST_AUTH_SERVER_API_CONFIG_ERROR,
    error
  );

export const deleteAuthServerAPIConfigAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.DELETE_AUTH_SERVER_API_CONFIG,
    payload: payload
  };
};
export const deleteAuthServerAPIConfigSuccessAction = (response: any) => {
  const payload = {
    response,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.DELETE_AUTH_SERVER_API_CONFIG_SUCCESS,
    payload
  };
};
export const deleteAuthServerAPIConfigLoadingAction = () =>
  loadingAction(
    PlatformConfigurationConst.DELETE_AUTH_SERVER_API_CONFIG_LOADING
  );
export const deleteAuthServerAPIConfigErrorAction = (error: string) =>
  errorAction(
    PlatformConfigurationConst.DELETE_AUTH_SERVER_API_CONFIG_ERROR,
    error
  );

export const setAppFeaturesAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.SET_APP_FEATURES,
    payload: payload
  };
};
export const setAppFeaturesSuccessAction = (response: any) => {
  const payload = {
    response,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.SET_APP_FEATURES_SUCCESS,
    payload
  };
};
export const setAppFeaturesLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.SET_APP_FEATURES_LOADING);
export const setAppFeaturesErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.SET_APP_FEATURES_ERROR, error);

//Renew Certificate

export const renewCertificateAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.RENEW_CERTIFICATE,
    payload: payload
  };
};
export const renewCertificateLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.RENEW_CERTIFICATE_LOADING);

export const renewCertificateErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.RENEW_CERTIFICATE_ERROR, error);

export const renewCertificateSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.RENEW_CERTIFICATE_SUCCESS,
    payload
  };
};

//Fed SSO Integration

export const getSSOIntegrationAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.SSO_INTEGRATION,
    payload
  };
};
export const getSSOIntegrationLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.SSO_INTEGRATION_LOADING);

export const getSSOIntegrationErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.SSO_INTEGRATION_ERROR, error);

export const getSSOIntegrationSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.SSO_INTEGRATION_SUCCESS,
    payload
  };
};

export const getAvailableSSOIntegrationAction = () => {
  return {
    type: PlatformConfigurationConst.AVAILABLE_SSO_INTEGRATION
  };
};
export const getAvailableSSOIntegrationLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.AVAILABLE_SSO_INTEGRATION_LOADING);

export const getAvailableSSOIntegrationErrorAction = (error: string) =>
  errorAction(
    PlatformConfigurationConst.AVAILABLE_SSO_INTEGRATION_ERROR,
    error
  );

export const getAvailableSSOIntegrationSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.AVAILABLE_SSO_INTEGRATION_SUCCESS,
    payload
  };
};

export const postSSOIntegrationAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.POST_SSO_INTEGRATION,
    payload: payload
  };
};
export const postSSOIntegrationLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.POST_SSO_INTEGRATION_LOADING);

export const postSSOIntegrationErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.POST_SSO_INTEGRATION_ERROR, error);

export const postSSOIntegrationSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.POST_SSO_INTEGRATION_SUCCESS,
    payload
  };
};

export const putSSOIntegrationAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.PUT_SSO_INTEGRATION,
    payload: payload
  };
};
export const putSSOIntegrationLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.PUT_SSO_INTEGRATION_LOADING);

export const putSSOIntegrationErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.PUT_SSO_INTEGRATION_ERROR, error);

export const putSSOIntegrationSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.PUT_SSO_INTEGRATION_SUCCESS,
    payload
  };
};

export const deleteSSOIntegrationAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.DELETE_SSO_INTEGRATION,
    payload: payload
  };
};
export const deleteSSOIntegrationLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.DELETE_SSO_INTEGRATION_LOADING);

export const deleteSSOIntegrationErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.DELETE_SSO_INTEGRATION_ERROR, error);

export const deleteSSOIntegrationSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.DELETE_SSO_INTEGRATION_SUCCESS,
    payload
  };
};

/**
 * GET_OAUTH_PROVIDERS
 */

export interface GetOAuthProvidersSchema extends SideEffectSchema {
  data?: any;
}

export const getOAuthProvidersAction = () => {
  return {
    type: PlatformConfigurationConst.GET_OAUTH_PROVIDERS,
    payload: {}
  };
};

export const getOAuthProvidersLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_OAUTH_PROVIDERS_LOADING);

export const getOAuthProvidersErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.GET_OAUTH_PROVIDERS_ERROR, error);

export const getOAuthProvidersSuccessAction = (
  data: GetOAuthProvidersSchema
) => {
  const payload: GetOAuthProvidersSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_OAUTH_PROVIDERS_SUCCESS,
    payload
  };
};

/**
 * GET_JWT_PROVIDERS
 */

export interface GetJWTProvidersSchema extends SideEffectSchema {
  data?: any;
}

export const getJWTProvidersAction = () => {
  return {
    type: PlatformConfigurationConst.GET_JWT_PROVIDERS,
    payload: {}
  };
};

export const getJWTProvidersLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_JWT_PROVIDERS_LOADING);

export const getJWTProvidersErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.GET_JWT_PROVIDERS_ERROR, error);

export const getJWTProvidersSuccessAction = (data: GetJWTProvidersSchema) => {
  const payload: GetJWTProvidersSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_JWT_PROVIDERS_SUCCESS,
    payload
  };
};

/**
 * GET_SAML_PROVIDERS
 */

export interface GetSAMLProvidersSchema extends SideEffectSchema {
  data?: any;
}

export const getSAMLProvidersAction = () => {
  return {
    type: PlatformConfigurationConst.GET_SAML_PROVIDERS,
    payload: {}
  };
};

export const getSAMLProvidersLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_SAML_PROVIDERS_LOADING);

export const getSAMLProvidersErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.GET_SAML_PROVIDERS_ERROR, error);

export const getSAMLProvidersSuccessAction = (data: GetSAMLProvidersSchema) => {
  const payload: GetSAMLProvidersSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_SAML_PROVIDERS_SUCCESS,
    payload
  };
};

export interface GetOIDCandOAuthAppsSchema extends SideEffectSchema {
  data?: any;
}

export const getOIDCandOAuthAppsAction = () => {
  return {
    type: PlatformConfigurationConst.GET_OIDC_AND_OAUTH_APPS,
    payload: {}
  };
};

export const getOIDCandOAuthAppsLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_OIDC_AND_OAUTH_APPS_LOADING);

export const getOIDCandOAuthAppsErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.GET_OIDC_AND_OAUTH_APPS_ERROR, error);

export const getOIDCandOAuthAppsSuccessAction = (
  data: GetOIDCandOAuthAppsSchema
) => {
  const payload: GetOIDCandOAuthAppsSchema = {
    data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_OIDC_AND_OAUTH_APPS_SUCCESS,
    payload
  };
};

export const getPassKeyConfigAction = () => {
  return {
    type: PlatformConfigurationConst.GET_PASSKEY_CONFIG,
    payload: {}
  };
};

export const getPassKeyConfigLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.GET_PASSKEY_CONFIG_LOADING);

export const getPassKeyConfigErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.GET_PASSKEY_CONFIG_ERROR, error);

export const getPassKeyConfigSuccessAction = (data: any) => {
  const payload: any = {
    data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.GET_PASSKEY_CONFIG_SUCCESS,
    payload
  };
};

export const putPassKeyConfigAction = (payload: any) => {
  return {
    type: PlatformConfigurationConst.UPDATE_PASSKEY_CONFIG,
    payload: payload
  };
};
export const putPassKeyConfigLoadingAction = () =>
  loadingAction(PlatformConfigurationConst.UPDATE_PASSKEY_CONFIG_LOADING);

export const putPassKeyConfigErrorAction = (error: string) =>
  errorAction(PlatformConfigurationConst.UPDATE_PASSKEY_CONFIG_ERROR, error);

export const putPassKeyConfigSuccessAction = (data: any) => {
  const payload: any = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: PlatformConfigurationConst.UPDATE_PASSKEY_CONFIG_SUCCESS,
    payload
  };
};

import {
  AppStateConst,
  AppUISchema,
  SetI18NSchema
} from "../actions/appStateActions";
import { combineReducers } from "redux";
import { ActionSchema } from "../actions";

// Reducers
const appUIReducer = (state = {}, action: ActionSchema<AppUISchema>) => {
  switch (action.type) {
    case AppStateConst.SHOW_LOADING:
    case AppStateConst.INIT_APP_SUCCESS:
    case AppStateConst.NETWORK_UPDATE_SUCCESS:
    case AppStateConst.INIT_LOGIN_EVENT_SUCCESS:
    case AppStateConst.NAVIGATION_MENU_CHANGE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

// Reducers
const i18nReducer = (state = {}, action: ActionSchema<SetI18NSchema>) => {
  if (action.type === AppStateConst.SET_I18N_LOCALE) {
    return { ...state, ...action.payload };
  }
  return state;
};

// Combine Reducers
const appStateReducer = combineReducers({
  appUI: appUIReducer,
  i18n: i18nReducer
});

// Combine Schemas
export interface AppStateSchema {
  appUI: AppUISchema;
  i18n: SetI18NSchema;
}

export default appStateReducer;

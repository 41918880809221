import React from "react";
import { connect } from "react-redux";
import { NavSubSectionSchema } from "../../containers/Navigation";
import { removeSymbols, handleMenuItemType } from "../../utils";
import { RootSchema } from "../../redux/reducers";
import { AppUISchema } from "./../../redux/actions/appStateActions";
export interface NavSingleDropdownProps {
  name: string;
  classes: string;
  toggleId: string;
  children: NavSubSectionSchema[];
  parentLink: string;
  parentIndex: number;
  tabIndex?: number;
  isShowing: boolean;
  navigationHandler: (i: number, s: number, sb: number) => void;
  handleLinkClick: (name: string) => void;
  currentActive: string;
  handleLinkFocus: (name: string) => void;
  currentFocused: string;
  appUI: AppUISchema;
}

export interface NavSingleDropdownState {
  isShowing: boolean;
  isFocused: boolean;
}

class NavSingleDropdown extends React.Component<
  NavSingleDropdownProps,
  NavSingleDropdownState
> {
  COUNT = 0;
  constructor(props: NavSingleDropdownProps) {
    super(props);
    this.state = {
      isShowing: props.isShowing,
      isFocused: props.isShowing
    };
  }

  focusFirstElement = () => {
    const { children } = this.props;
    if (Array.isArray(children)) {
      const { displayName } = children[0];
      const firstDropdownItem = document.getElementById(
        `lnk_${removeSymbols(displayName)}`
      );
      if (firstDropdownItem instanceof HTMLAnchorElement) {
        firstDropdownItem.focus();
      }
    }
  };

  handleBlur = (event: React.FocusEvent) => {
    this.props.handleLinkFocus(removeSymbols(this.props.name));
    this.setState({ isFocused: event.target === document.activeElement });
  };

  handleKeyUp = (event: React.KeyboardEvent) => {
    this.props.handleLinkFocus(removeSymbols(this.props.name));
    let key = event.key;
    this.setState(
      { isFocused: event.target === document.activeElement },
      () => {
        if (key === "Enter") {
          this.setState(
            prevState => ({ isShowing: !prevState.isShowing }),
            () => {
              this.COUNT += 1;
              this.props.handleLinkClick(removeSymbols(this.props.name));
              // Using a 0ms setTimeout so it gets added to the event queue, because otherwise, the DOM element won't be focusable yet
              setTimeout(this.focusFirstElement, 0);
            }
          );
        } else if (key === "Escape") {
          this.setState({ isShowing: false });
        }
      }
    );
  };

  handleListItemClick = (event: React.MouseEvent) => {
    this.props.handleLinkClick(removeSymbols(this.props.name));
    this.props.handleLinkFocus(removeSymbols(this.props.name));

    this.setState(prevState => ({
      //isFocused: !prevState.isFocused,
      isShowing: false
    }));
  };

  render() {
    const {
      name,
      classes,
      toggleId,
      children,
      parentLink,
      parentIndex,
      tabIndex,
      navigationHandler
    } = this.props;
    let classShow = false;
    if (
      this.state.isShowing &&
      this.props.currentActive === removeSymbols(name)
    )
      classShow = true;
    return (
      <li
        key={"s" + this.COUNT + name}
        className={classShow ? "nav-item show" : "nav-item"}
        tabIndex={-1}
        onClick={this.handleListItemClick}
      >
        <a
          className={classes}
          id={toggleId}
          aria-haspopup="true"
          aria-expanded={
            (this.state.isShowing &&
              this.props.currentActive === removeSymbols(name)) ||
            (this.state.isFocused &&
              this.props.currentFocused === removeSymbols(name))
          }
          data-toggle="dropdown"
          role="button"
          tabIndex={tabIndex}
          onKeyUp={this.handleKeyUp}
          onBlur={this.handleBlur}
          onClick={this.handleListItemClick}
        >
          {name}
        </a>
        <div
          className={
            this.state.isShowing &&
            this.props.currentActive === removeSymbols(name)
              ? "dropdown-menu dropdown-secondary custom-single-dropdown show"
              : "dropdown-menu dropdown-secondary custom-single-dropdown"
          }
          aria-labelledby={toggleId}
        >
          {children.map((subSection, subSectionIndex) => (
            <div
              key={subSectionIndex}
              onKeyDown={(event: React.KeyboardEvent) => {
                const isFirstItem = subSectionIndex === 0;
                const isLastItemInDropdown =
                  subSectionIndex === children.length - 1 ||
                  !Object.keys(children[subSectionIndex + 1]).length;

                if (
                  (isFirstItem && event.shiftKey && event.key === "Tab") ||
                  (isLastItemInDropdown && event.key === "Tab") ||
                  event.key === "Enter"
                ) {
                  this.setState({ isShowing: false });
                }
              }}
              onKeyUp={(event: React.KeyboardEvent) => {
                event.stopPropagation();

                if (event.key === "Enter" || event.key === "Escape") {
                  this.setState({ isShowing: false });
                }
              }}
            >
              {subSection.link &&
                handleMenuItemType(
                  parentLink,
                  parentIndex,
                  subSection,
                  subSectionIndex,
                  navigationHandler
                )}
            </div>
          ))}
        </div>
      </li>
    );
  }
}
const mapStateToProps = (state: RootSchema) => {
  return {
    appUI: state.appState.appUI
  };
};
export default connect(mapStateToProps)(NavSingleDropdown);

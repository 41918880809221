const hostname = window.location.hostname;

const backendMap = {
  dev: "https://devadmin-console-api.lrinternal.com",
  staging: "https://stagingadmin-console-api.lrinternal.com",
  prod: "https://adminconsole-api.loginradius.com",
};

let apiEndpoint = backendMap.prod;

if (hostname.includes("localhost") || hostname.includes("127.0.0.1")) {
  apiEndpoint = backendMap.dev;
} else {
  if (hostname.includes("dev")) {
    apiEndpoint = backendMap.dev;
  } else if (hostname.includes("staging")) {
    apiEndpoint = backendMap.staging;
  }
}

export default {
  version: "25.12.0",
  apiEndpoint,
};

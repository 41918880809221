import { take, call, put, select, takeEvery } from "redux-saga/effects";
import { LanguageSchema } from "../../i18n";
import { httpGet, httpDelete, httpPost, httpPut } from "../../services";
import cloneDeep from "lodash/cloneDeep";

import {
  IntegrationsConst,
  GetDataExportDumpRequestsResponseSchema,
  getDataExportDumpRequestsLoadingAction,
  getDataExportDumpRequestsSuccessAction,
  getDataExportDumpRequestsErrorAction,
  RemoveDataExportDumpRequestResponseSchema,
  removeDataExportDumpRequestLoadingAction,
  removeDataExportDumpRequestSuccessAction,
  removeDataExportDumpRequestErrorAction,
  addDataExportDumpRequestLoadingAction,
  addDataExportDumpRequestSuccessAction,
  addDataExportDumpRequestErrorAction,
  DataExportRequestSchema,
  getExportLogsLoadingAction,
  GetExportLogsSchema,
  getExportLogsSuccessAction,
  getExportLogsErrorAction,
  getDataSyncFeaturesLoadingAction,
  GetDataSyncFeaturesSchema,
  getDataSyncFeaturesSuccessAction,
  getDataSyncFeaturesErrorAction,
  getWebHookRequestsLoadingAction,
  getWebHookRequestsSuccessAction,
  getWebHookRequestsErrorAction,
  GetWebHookRequestsSchema,
  WebHookRequestSchema,
  deleteWebHookLoadingAction,
  deleteWebHookSuccessAction,
  deleteWebHookErrorAction,
  DeleteWebHookResponseSchema,
  updateWebHookLoadingAction,
  updateWebHookErrorAction,
  AddWebhookRequestSchema,
  DeleteWebhookRequestSchema,
  getWebHookRequestsAction,
  addWebHookLoadingAction,
  addWebHookSuccessAction,
  addWebHookErrorAction,
  getWebHookQuotaLoadingAction,
  WebHookQuotaSchema,
  getWebHookQuotaSuccessAction,
  getWebHookQuotaErrorAction,
  setDefaultQuotaLoadingAction,
  setDefaultQuotaSuccessAction,
  setDefaultQuotaErrorAction,
  getWebHookQuotaAction,
  SetDefaultQuotaResponseSchema,
  EtlLogsResponseSchema,
  getEtlLogsLoadingAction,
  getEtlLogsSuccessAction,
  getEtlLogsErrorAction,
  EtlLogsParamsSchema,
  QueueBulkActionParamsSchema,
  queueBulkActionLoadingAction,
  queueBulkActionSuccessAction,
  queueBulkActionErrorAction,
  QueueBlukActionResponseSchema,
  AvailableIntegrationsSchema,
  getAvailableIntegrationsLoadingAction,
  getAvailableIntegrationsSuccessAction,
  getAvailableIntegrationsErrorAction,
  getCreatedIntegrationsLoadingAction,
  CreatedIntegrationsSchema,
  getCreatedIntegrationsSuccessAction,
  getCreatedIntegrationsErrorAction,
  ActivateIntegrationArgs,
  activateIntegrationLoadingAction,
  ActivateIntegrationSchema,
  activateIntegrationSuccessAction,
  activateIntegrationErrorAction,
  DeactivateIntegrationArgs,
  deactivateIntegrationLoadingAction,
  DeactivateIntegrationSchema,
  deactivateIntegrationSuccessAction,
  deactivateIntegrationErrorAction,
  IntegrationSchema,
  GetIntegrationDataArgs,
  getIntegrationDataLoadingAction,
  getIntegrationDataSuccessAction,
  getIntegrationDataErrorAction,
  getIntegrationConfigLoadingAction,
  GetIntegrationConfigSchema,
  getIntegrationConfigSuccessAction,
  getIntegrationConfigErrorAction,
  saveIntegrationDataLoadingAction,
  SaveIntegrationDataSchema,
  SaveIntegrationDataArgs,
  saveIntegrationDataSuccessAction,
  saveIntegrationDataErrorAction,
  UpdateWebhookRequestSchema,
  updateWebHookSuccessAction,
  UpdateWebHookResponseSchema,
  saveAuditLogConfigLoadingAction,
  saveAuditLogConfigSuccessAction,
  saveAuditLogConfigErrorAction,
  getAuditLogConfigAction,
  getAuditLogConfigLoadingAction,
  GetAuditLogConfigSchema,
  getAuditLogConfigSuccessAction,
  getAuditLogConfigErrorAction,
  resetAuditLogConfigLoadingAction,
  resetAuditLogConfigSuccessAction,
  resetAuditLogConfigErrorAction
} from "../actions/integrationsActions";

import { RootSchema } from "../reducers";
import { ActionSchema } from "../actions";
/**
 * Workers
 */
function* getDataExportDumpRequestsHandler() {
  try {
    yield put(getDataExportDumpRequestsLoadingAction());
    const response: GetDataExportDumpRequestsResponseSchema = yield httpGet(
      "/integrations/dataexport/dump"
    );
    if (response.errorCode && response.errorCode !== 1079) {
      // Error code 1079 means: 'There are no active data dump requests configured for this site.'
      throw Error(
        response.message ||
          response.description ||
          "Error when fetching data dump requests."
      );
    }
    response.Data = response.Data === undefined ? [] : response.Data;
    yield put(getDataExportDumpRequestsSuccessAction({ response }));
  } catch (e) {
    yield put(getDataExportDumpRequestsErrorAction(e.message));
  }
}

function* removeDataExportDumpRequestHandler(dataExportRequestId: string) {
  try {
    yield put(removeDataExportDumpRequestLoadingAction());
    const response: RemoveDataExportDumpRequestResponseSchema = yield httpDelete(
      "/integrations/dataexport/dump",
      {},
      { id: dataExportRequestId }
    );
    if (response.errorCode !== undefined) {
      throw Error(
        response.message ||
          response.description ||
          "Error when attempting to remove data dump request."
      );
    }
    yield put(removeDataExportDumpRequestSuccessAction({ response }));
  } catch (e) {
    yield put(removeDataExportDumpRequestErrorAction(e.message));
  }
}

function* addDataExportDumpRequestHandler(
  dataExportRequest: DataExportRequestSchema
) {
  try {
    yield put(addDataExportDumpRequestLoadingAction());
    const response: GetDataExportDumpRequestsResponseSchema = yield httpPost(
      "/integrations/dataexport/dump",
      {},
      { ...dataExportRequest }
    );
    if (response.description) {
      throw Error(response.description);
    }
    if (response.lrmessage) {
      throw Error(response.lrmessage);
    }
    yield put(addDataExportDumpRequestSuccessAction({ response }));
  } catch (e) {
    yield put(addDataExportDumpRequestErrorAction(e.message));
  }
}

function* getExportLogsHandler() {
  try {
    yield put(getExportLogsLoadingAction());
    let response: GetExportLogsSchema = yield httpGet(
      "/integrations/dataexport/dump/logs"
    );
    if (response.errorCode && response.errorCode !== 927) {
      // Error code 927 is for no record found for export logs
      throw Error(
        response.message ||
          response.description ||
          "Error when fetching data dump requests."
      );
    }
    response.Data =
      response.Data === undefined ? [] : response.Data.slice().reverse();
    yield put(getExportLogsSuccessAction(response));
  } catch (e) {
    yield put(getExportLogsErrorAction(e.message));
  }
}

function* getDataSyncFeaturesHandler() {
  try {
    yield put(getDataSyncFeaturesLoadingAction());
    let response: GetDataSyncFeaturesSchema = yield httpGet(
      "/integrations/datasync/feature"
    );
    yield put(getDataSyncFeaturesSuccessAction(response));
  } catch (e) {
    yield put(getDataSyncFeaturesErrorAction(e.message));
  }
}

function* getWebHookRequestsHandler() {
  try {
    yield put(getWebHookRequestsLoadingAction());

    let res: GetWebHookRequestsSchema = yield httpGet("/integrations/webhook");
    res.Data = res.Data || [];
    // res.Data = res.Data.filter(v => !!v.Name);

    yield put(getWebHookRequestsSuccessAction(res));
  } catch (e) {
    yield put(getWebHookRequestsErrorAction(e.message));
  }
}

function* deleteWebHookHandler(req: DeleteWebhookRequestSchema) {
  try {
    yield put(deleteWebHookLoadingAction());
    const res = yield httpDelete("/integrations/webhook/" + req, {});
    if (res.errorCode && res.description) {
      throw new Error(res.description);
    }

    yield put(deleteWebHookSuccessAction(res));
    yield put(getWebHookRequestsAction());
  } catch (e) {
    yield put(deleteWebHookErrorAction(e.message));
    yield put(getWebHookRequestsAction());
  }
}
function* updateWebHookHandler(
  req: UpdateWebhookRequestSchema,
  i18n: LanguageSchema
) {
  try {
    yield put(updateWebHookLoadingAction());
    const res = yield httpPut(
      "/integrations/webhook/" + req.HookId,
      {},
      { CustomObjects: req.CustomObjects, SecretName: req.SecretName }
    );
    if (res.errorCode === 1229) {
      let msg: string = i18n.CANNOT_UPDATE_WEBHOOK;
      throw new Error(msg);
    } else if (res.errorCode && res.description) {
      throw new Error(res.description);
    }
    yield put(updateWebHookSuccessAction(res));
    yield put(getWebHookRequestsAction());
  } catch (e) {
    yield put(updateWebHookErrorAction(e.message));
    yield put(getWebHookRequestsAction());
  }
}

function* getAuditLogConfigHandler() {
  try {
    yield put(getAuditLogConfigLoadingAction());

    let res: GetAuditLogConfigSchema = yield httpGet("/integrations/auditlog");
    res.data = res.data || [];
    // res.Data = res.Data.filter(v => !!v.Name);

    yield put(getAuditLogConfigSuccessAction(res));
  } catch (e) {
    yield put(getAuditLogConfigErrorAction(e.message));
  }
}
function* saveAuditLogConfigHandler(req: AddWebhookRequestSchema) {
  try {
    yield put(saveAuditLogConfigLoadingAction());

    const res = yield httpPost("/integrations/auditlog", {}, req);
    if (res.errorCode && res.description) {
      throw new Error(res.description);
    }

    yield put(saveAuditLogConfigSuccessAction(res));
    yield put(getAuditLogConfigSuccessAction(res));
  } catch (e) {
    yield put(saveAuditLogConfigErrorAction(e.message));
  }
}
function* resetAuditLogConfigHandler() {
  try {
    yield put(resetAuditLogConfigLoadingAction());
    const res = yield httpDelete("/integrations/auditlog", {});
    if (res.errorCode && res.description) {
      throw new Error(res.description);
    } else {
      yield put(resetAuditLogConfigSuccessAction(res));
      yield put(getAuditLogConfigSuccessAction(res));
    }
  } catch (e) {
    yield put(resetAuditLogConfigErrorAction(e.message));
  }
}
function* addWebHookHandler(req: AddWebhookRequestSchema) {
  try {
    yield put(addWebHookLoadingAction());

    const res = yield httpPost("/integrations/webhook", {}, req);
    if (res.errorCode && res.description) {
      throw new Error(res.description);
    }

    yield put(addWebHookSuccessAction(res));
    yield put(getWebHookRequestsAction());
  } catch (e) {
    yield put(addWebHookErrorAction(e.message));
    yield put(getWebHookRequestsAction());
  }
}

function* getWebHookQuotaHandler() {
  try {
    yield put(getWebHookQuotaLoadingAction());
    let response: WebHookQuotaSchema = yield httpGet(
      "/integrations/datasync/webhook/quota"
    );
    yield put(getWebHookQuotaSuccessAction(response));
  } catch (e) {
    yield put(getWebHookQuotaErrorAction(e.message));
  }
}

function* setDefaultQuotaHandler() {
  try {
    yield put(setDefaultQuotaLoadingAction());
    let response: SetDefaultQuotaResponseSchema = yield httpPost(
      "/integrations/datasync/webhook/quota"
    );
    yield put(setDefaultQuotaSuccessAction(response));

    yield put(getWebHookQuotaAction());
  } catch (e) {
    yield put(setDefaultQuotaErrorAction(e.message));
  }
}

function* queueBulkActionHandler(payload: QueueBulkActionParamsSchema) {
  try {
    yield put(queueBulkActionLoadingAction());

    let response = yield httpPost("/etl/blob", {}, payload.formData);

    if (response.lrmessage) {
      throw new Error(response.lrmessage);
    } else if (!response.Key) {
      throw new Error("Problem uploading CSV file. Please try again.");
    }

    let queueBulkActionBody = { options: cloneDeep(payload.options) };
    queueBulkActionBody.options.StorageId = response.Key;

    const queueBulkActionResponse: QueueBlukActionResponseSchema = yield httpPost(
      "/etl/bulk-action",
      {},
      queueBulkActionBody
    );

    if (queueBulkActionResponse.lrmessage) {
      throw new Error(queueBulkActionResponse.lrmessage);
    } else if (queueBulkActionResponse.statusCode !== 200) {
      throw new Error(
        "Problem submitting Data Migration request. Please try again."
      );
    }

    yield put(queueBulkActionSuccessAction(queueBulkActionResponse));
  } catch (e) {
    yield put(queueBulkActionErrorAction(e.message));
  }
}

function* getEtlLogsHandler(payload: EtlLogsParamsSchema) {
  try {
    yield put(getEtlLogsLoadingAction());
    let response: EtlLogsResponseSchema = yield httpGet(
      "/etl/bulk-action",
      payload
    );

    if (response.errorCode && response.errorCode === 1156) {
      response.Data = [];
    }

    yield put(getEtlLogsSuccessAction(response));
  } catch (e) {
    yield put(getEtlLogsErrorAction(e.message));
  }
}

function* getAvailableIntegrations() {
  try {
    yield put(getAvailableIntegrationsLoadingAction());
    let response: AvailableIntegrationsSchema = yield httpGet(
      "/integrations/authorizedintegration"
    );

    if (response.errorCode || !response.AuthorizedIntegrations) {
      throw new Error(
        response.message || "Unable to get available integrations."
      );
    }

    yield put(getAvailableIntegrationsSuccessAction(response));
  } catch (e) {
    yield put(getAvailableIntegrationsErrorAction(e.message));
  }
}

function* getCreatedIntegrations() {
  try {
    yield put(getCreatedIntegrationsLoadingAction());
    let response: CreatedIntegrationsSchema = yield httpGet(
      "/integrations/integration"
    );

    if (response.errorCode || !response.Data) {
      //! Overriding the partner API error message spell issue
      //! original repsonse `Integartions not found`
      throw new Error("Integrations not found.");
    }

    yield put(getCreatedIntegrationsSuccessAction(response));
  } catch (e) {
    yield put(getCreatedIntegrationsErrorAction(e.message));
  }
}

function* activateIntegration(payload: ActivateIntegrationArgs) {
  try {
    yield put(activateIntegrationLoadingAction(payload.id));
    let response: ActivateIntegrationSchema = yield httpPost(
      "/integrations/integration",
      {},
      payload
    );

    if (response.data.errorCode || !response.data) {
      throw new Error(response.data.message);
    }

    // Update the created integration store
    const createdIntegrations: IntegrationSchema[] = yield select(
      (state: RootSchema) => state.integrations.createdIntegrations.Data
    );
    for (let data of createdIntegrations) {
      if (
        payload.IntegrationName === data.IntegrationName &&
        payload.Event === data.Event
      ) {
        data.IsEnable = true;
      }
    }
    yield put(
      getCreatedIntegrationsSuccessAction({ Data: createdIntegrations })
    );

    yield put(activateIntegrationSuccessAction(response));
  } catch (e) {
    yield put(activateIntegrationErrorAction(e.message));
  }
}

function* deactivateIntegration(payload: DeactivateIntegrationArgs) {
  try {
    yield put(deactivateIntegrationLoadingAction(payload.integrationId));

    let response: DeactivateIntegrationSchema = yield httpDelete(
      "/integrations/integration",
      {},
      payload
    );

    if (response.errorCode || response.isdeleted === undefined) {
      throw new Error(response.message);
    }

    // Update the created integration store
    const createdIntegrations: IntegrationSchema[] = yield select(
      (state: RootSchema) => state.integrations.createdIntegrations.Data
    );
    for (let data of createdIntegrations) {
      if (payload.integrationId === data.Id) {
        data.IsEnable = false;
      }
    }
    yield put(
      getCreatedIntegrationsSuccessAction({ Data: createdIntegrations })
    );
    yield put(deactivateIntegrationSuccessAction(response));
  } catch (e) {
    yield put(deactivateIntegrationErrorAction(e.message));
  }
}

function* getIntegrationData(payload: GetIntegrationDataArgs) {
  try {
    yield put(getIntegrationDataLoadingAction());

    let response: any = yield httpGet("/integrations/integration/plugin", {
      integrationId: payload.id
    });

    if (response.errorCode) {
      throw new Error(response.message);
    }

    yield put(getIntegrationDataSuccessAction({ data: response }));
  } catch (e) {
    yield put(getIntegrationDataErrorAction(e.message));
  }
}

function* getIntegrationConfig() {
  try {
    yield put(getIntegrationConfigLoadingAction());

    let response: GetIntegrationConfigSchema = yield httpGet(
      "/integrations/integration/schema"
    );

    if (response.errorCode || !response.schema) {
      throw new Error(response.message || "Permission denied.");
    }

    yield put(getIntegrationConfigSuccessAction(response));
  } catch (e) {
    yield put(getIntegrationConfigErrorAction(e.message));
  }
}

function* saveIntegrationData(payload: SaveIntegrationDataArgs) {
  try {
    yield put(saveIntegrationDataLoadingAction());

    let { IntegrationName, Event } = payload;
    if (!payload.Id) {
      const createResponse: ActivateIntegrationSchema = yield httpPost(
        "/integrations/integration",
        {},
        { IntegrationName, Event, isEnable: true }
      );

      if (createResponse.data.errorCode || !createResponse.data) {
        throw new Error(createResponse.data.message || "Permission denied.");
      } else {
        payload.Id = createResponse.data.Id;
      }
    }

    let response: SaveIntegrationDataSchema = yield httpPost(
      "/integrations/integration/plugin",
      { integrationId: payload.Id },
      { Data: [payload] }
    );

    if (response.errorCode || response.IsPosted === undefined) {
      throw new Error(response.description || "Permission denied.");
    }

    // Update the integrations list
    let createdIntegrations: IntegrationSchema[] = yield select(
      (state: RootSchema) => state.integrations.createdIntegrations.Data
    );
    createdIntegrations = createdIntegrations || [];

    let found = false;
    for (let i in createdIntegrations) {
      if (createdIntegrations[i].Id === payload.Id) {
        found = true;
        createdIntegrations[i] = {
          ...createdIntegrations[i],
          Event: payload.ChangeEventTo || createdIntegrations[i].Event,
          IsConfigured: true // !!(payload.Credentials && payload.Meta)
        };
        break;
      }
    }

    if (!found) {
      createdIntegrations.push({
        Event: payload.Event,
        Id: payload.Id,
        IntegrationName: payload.IntegrationName,
        IntegrationDescription: null,
        IntegrationLabel: null,
        IsActive: true,
        IsEnable: true,
        IsConfigurable: true,
        IsConfigured: true,
        IsCustom: false
      });
    }
    yield put(
      getCreatedIntegrationsSuccessAction({ Data: createdIntegrations })
    );

    yield put(saveIntegrationDataSuccessAction(response));
  } catch (e) {
    yield put(saveIntegrationDataErrorAction(e.message));
  }
}

/**
 * Watchers
 */
export function* watchGetDataExportDumpRequests() {
  while (true) {
    yield take(IntegrationsConst.GET_DATA_EXPORT_DUMP_REQUESTS);
    yield call(getDataExportDumpRequestsHandler);
  }
}

export function* watchRemoveDataExportDumpRequest() {
  while (true) {
    const {
      payload: { dataExportRequestId }
    } = yield take(IntegrationsConst.REMOVE_DATA_EXPORT_DUMP_REQUEST);
    yield call(removeDataExportDumpRequestHandler, dataExportRequestId);
  }
}

export function* watchAddDataExportDumpRequest() {
  while (true) {
    const {
      payload: { dataExportRequest }
    } = yield take(IntegrationsConst.ADD_DATA_EXPORT_DUMP_REQUEST);
    yield call(addDataExportDumpRequestHandler, dataExportRequest);
  }
}

export function* watchGetExportLogs() {
  while (true) {
    yield take(IntegrationsConst.GET_EXPORT_LOGS);
    yield call(getExportLogsHandler);
  }
}

export function* watchGetDataSyncFeatures() {
  while (true) {
    yield take(IntegrationsConst.GET_DATA_SYNC_FEATURES);
    yield call(getDataSyncFeaturesHandler);
  }
}

export function* watchGetWebHookRequests() {
  while (true) {
    yield take(IntegrationsConst.GET_WEB_HOOK_REQUESTS);
    yield call(getWebHookRequestsHandler);
  }
}

export function* watchDeleteWebHook() {
  while (true) {
    const { payload } = yield take(IntegrationsConst.DELETE_WEB_HOOK);
    yield call(deleteWebHookHandler, payload);
  }
}

export function* watchAddWebHook() {
  while (true) {
    const { payload } = yield take(IntegrationsConst.ADD_WEB_HOOK);
    yield call(addWebHookHandler, payload);
  }
}
export function* watchUpdateWebHook() {
  while (true) {
    const { payload, i18n } = yield take(IntegrationsConst.UPDATE_WEB_HOOK);
    yield call(updateWebHookHandler, payload, i18n);
  }
}

export function* watchGetAuditLogConfig() {
  while (true) {
    yield take(IntegrationsConst.GET_AUDIT_LOG_DATA);
    yield call(getAuditLogConfigHandler);
  }
}
export function* watchSaveAuditLogConfig() {
  while (true) {
    const { payload } = yield take(IntegrationsConst.SAVE_AUDIT_LOG_DATA);
    yield call(saveAuditLogConfigHandler, payload);
  }
}
export function* watchGetWebHookQuota() {
  while (true) {
    yield take(IntegrationsConst.GET_WEB_HOOK_QUOTA);
    yield call(getWebHookQuotaHandler);
  }
}
export function* watchResetAuditLogConfig() {
  while (true) {
    yield take(IntegrationsConst.DELETE_AUDIT_LOG_DATA);
    yield call(resetAuditLogConfigHandler);
  }
}

export function* watchSetDefaultQuota() {
  while (true) {
    yield take(IntegrationsConst.SET_DEFAULT_QUOTA);
    yield call(setDefaultQuotaHandler);
  }
}

export function* watchQueueBulkAction() {
  while (true) {
    const { payload } = yield take(IntegrationsConst.QUEUE_BULK_ACTION);
    yield call(queueBulkActionHandler, payload);
  }
}

export function* watchGetEtlLogs() {
  while (true) {
    const { payload } = yield take(IntegrationsConst.GET_ETL_LOGS);
    yield call(getEtlLogsHandler, payload);
  }
}

export function* watchGetAvailableIntegrations() {
  while (true) {
    yield take(IntegrationsConst.GET_AVAILABLE_INTEGRATIONS);
    yield call(getAvailableIntegrations);
  }
}

export function* watchGetCreatedIntegrations() {
  while (true) {
    yield take(IntegrationsConst.GET_CREATED_INTEGRATIONS);
    yield call(getCreatedIntegrations);
  }
}

export function* watchActivateIntegration() {
  // yield takeEvery(
  //   IntegrationsConst.ACTIVATE_INTEGRATION,
  //   ({ payload }: ActionSchema<ActivateIntegrationArgs>) =>
  //     activateIntegration(payload)
  // );
  while (true) {
    const { payload } = yield take(IntegrationsConst.ACTIVATE_INTEGRATION);
    yield call(activateIntegration, payload);
  }
}

export function* watchDeactivateIntegration() {
  // yield takeEvery(
  //   IntegrationsConst.DEACTIVATE_INTEGRATION,
  //   ({ payload }: ActionSchema<DeactivateIntegrationArgs>) =>
  //     deactivateIntegration(payload)
  // );
  while (true) {
    const { payload } = yield take(IntegrationsConst.DEACTIVATE_INTEGRATION);
    yield call(deactivateIntegration, payload);
  }
}

export function* watchGetIntegrationData() {
  while (true) {
    const { payload } = yield take(IntegrationsConst.GET_INTEGRATION_DATA);
    yield call(getIntegrationData, payload);
  }
}

export function* watchGetIntegrationConfig() {
  while (true) {
    yield take(IntegrationsConst.GET_INTEGRATION_CONFIG);
    yield call(getIntegrationConfig);
  }
}

export function* watchSaveIntegrationData() {
  while (true) {
    const { payload } = yield take(IntegrationsConst.SAVE_INTEGRATION_DATA);
    yield call(saveIntegrationData, payload);
  }
}

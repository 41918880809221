import {
  SideEffectSchema,
  loadingAction,
  errorAction,
  successSideEffectState,
  generateConst,
  APIErrorResponseSchema,
  ObjectSchema
} from ".";
import { LanguageSchema } from "../../i18n";
import { Action } from "redux";

export const IntegrationsConst = generateConst("INTEGRATIONS", [
  "GET_DATA_EXPORT_DUMP_REQUESTS",
  "REMOVE_DATA_EXPORT_DUMP_REQUEST",
  "ADD_DATA_EXPORT_DUMP_REQUEST",
  "GET_EXPORT_LOGS",
  "GET_DATA_SYNC_FEATURES",
  "GET_WEB_HOOK_REQUESTS",
  "DELETE_WEB_HOOK",
  "ADD_WEB_HOOK",
  "UPDATE_WEB_HOOK",
  "GET_WEB_HOOK_QUOTA",
  "SET_DEFAULT_QUOTA",
  "QUEUE_BULK_ACTION",
  "GET_ETL_LOGS",
  "GET_AVAILABLE_INTEGRATIONS",
  "GET_CREATED_INTEGRATIONS",
  "ACTIVATE_INTEGRATION",
  "DEACTIVATE_INTEGRATION",
  "GET_INTEGRATION_DATA",
  "GET_INTEGRATION_CONFIG",
  "SAVE_INTEGRATION_DATA",
  "GET_AUDIT_LOG_DATA",
  "SAVE_AUDIT_LOG_DATA",
  "DELETE_AUDIT_LOG_DATA"
]);

export interface DataExportDumpRequestSchema {
  Id: string;
  Type: string;
  StartDate?: string;
  EndDate?: string;
  IsDone: boolean;
  IsRunning: boolean;
  Frequency?: string;
  Emails: string[];
}

export interface GetDataExportDumpRequestsResponseSchema {
  Data: DataExportDumpRequestSchema[];
  description?: string;
  errorCode?: number;
  message?: string;
  clientIP: string;
  lrmessage?: string;
}

export interface GetDataExportDumpRequestsDataSchema extends SideEffectSchema {
  response: GetDataExportDumpRequestsResponseSchema;
}

export const getDataExportDumpRequestsAction = () => {
  return {
    type: IntegrationsConst.GET_DATA_EXPORT_DUMP_REQUESTS,
    payload: {}
  };
};

export const getDataExportDumpRequestsLoadingAction = () =>
  loadingAction(IntegrationsConst.GET_DATA_EXPORT_DUMP_REQUESTS_LOADING);

export const getDataExportDumpRequestsSuccessAction = (
  data: GetDataExportDumpRequestsDataSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.GET_DATA_EXPORT_DUMP_REQUESTS_SUCCESS,
    payload
  };
};

export const getDataExportDumpRequestsErrorAction = (error: string) =>
  errorAction(IntegrationsConst.GET_DATA_EXPORT_DUMP_REQUESTS_ERROR, error);

export interface RemoveDataExportDumpRequestResponseSchema {
  clientIP: string;
  isdeleted: boolean;
  description?: string;
  errorCode?: string;
  message?: string;
}

export interface RemoveDataExportDumpRequestDataSchema
  extends SideEffectSchema {
  response: RemoveDataExportDumpRequestResponseSchema;
}

export const removeDataExportDumpRequestAction = (
  dataExportRequestId: string
) => {
  return {
    type: IntegrationsConst.REMOVE_DATA_EXPORT_DUMP_REQUEST,
    payload: { dataExportRequestId }
  };
};

export const removeDataExportDumpRequestLoadingAction = () =>
  loadingAction(IntegrationsConst.REMOVE_DATA_EXPORT_DUMP_REQUEST_LOADING);

export const removeDataExportDumpRequestSuccessAction = (
  data: RemoveDataExportDumpRequestDataSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.REMOVE_DATA_EXPORT_DUMP_REQUEST_SUCCESS,
    payload
  };
};

export const removeDataExportDumpRequestErrorAction = (error: string) =>
  errorAction(IntegrationsConst.REMOVE_DATA_EXPORT_DUMP_REQUEST_ERROR, error);

export interface DataExportRequestSchema {
  EndDate?: string;
  StartDate?: string;
  Type: string;
  Frequency: string;
  ScheduleRunTime?: number;
  ScheduleWeekDay?: string;
  ScheduleMonthDate: number;
  Emails: string[];
  FilteredQuery?: string;
  SelectedFields: string[];
  ExportDataFormat: string;
  Comment: string;
  FileEncryptionKey: string | number;
}

export const addDataExportDumpRequestAction = (
  dataExportRequest: DataExportRequestSchema
) => {
  return {
    type: IntegrationsConst.ADD_DATA_EXPORT_DUMP_REQUEST,
    payload: { dataExportRequest }
  };
};

export const addDataExportDumpRequestLoadingAction = () =>
  loadingAction(IntegrationsConst.ADD_DATA_EXPORT_DUMP_REQUEST_LOADING);

export const addDataExportDumpRequestSuccessAction = (
  data: GetDataExportDumpRequestsDataSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.ADD_DATA_EXPORT_DUMP_REQUEST_SUCCESS,
    payload
  };
};

export const addDataExportDumpRequestErrorAction = (error: string) =>
  errorAction(IntegrationsConst.ADD_DATA_EXPORT_DUMP_REQUEST_ERROR, error);

export interface ExportLogSchema {
  DumpDataDownloadLink: string;
  DumpDemandRequestId: string;
  EndTime: string;
  ErrorMessage: string;
  StartTime: string;
  Comment: string;
  Status: number;
}

export interface GetExportLogsSchema extends SideEffectSchema {
  Data?: ExportLogSchema[];
  errorCode?: number;
  message?: string;
  description?: string;
}

export const getExportLogsAction = () => {
  return {
    type: IntegrationsConst.GET_EXPORT_LOGS,
    payload: {}
  };
};

export const getExportLogsLoadingAction = () =>
  loadingAction(IntegrationsConst.GET_EXPORT_LOGS_LOADING);

export const getExportLogsSuccessAction = (data: GetExportLogsSchema) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.GET_EXPORT_LOGS_SUCCESS,
    payload
  };
};

export const getExportLogsErrorAction = (error: string) =>
  errorAction(IntegrationsConst.GET_EXPORT_LOGS_ERROR, error);

export interface GetDataSyncFeaturesSchema extends SideEffectSchema {
  Data?: any[];
  lrmessage?: any;
}

export const getDataSyncFeaturesAction = () => {
  return {
    type: IntegrationsConst.GET_DATA_SYNC_FEATURES,
    payload: {}
  };
};

export const getDataSyncFeaturesLoadingAction = () =>
  loadingAction(IntegrationsConst.GET_DATA_SYNC_FEATURES_LOADING);

export const getDataSyncFeaturesSuccessAction = (
  data: GetDataSyncFeaturesSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.GET_DATA_SYNC_FEATURES_SUCCESS,
    payload
  };
};

export const getDataSyncFeaturesErrorAction = (error: string) =>
  errorAction(IntegrationsConst.GET_DATA_SYNC_FEATURES_ERROR, error);

export interface WebHookRequestSchema {
  Data?: any[];
}

export interface GetWebHookRequestsSchema extends SideEffectSchema {
  Data?: any[];
}

export const getWebHookRequestsAction = () => {
  return {
    type: IntegrationsConst.GET_WEB_HOOK_REQUESTS,
    payload: {}
  };
};

export const getAuditLogConfigAction = () => {
  return {
    type: IntegrationsConst.GET_AUDIT_LOG_DATA,
    payload: {}
  };
};

export const getAuditLogConfigLoadingAction = () =>
  loadingAction(IntegrationsConst.GET_AUDIT_LOG_DATA_LOADING);

export const getAuditLogConfigSuccessAction = (
  data: GetAuditLogConfigSchema
) => {
  let payload = { data, ...successSideEffectState };
  return {
    type: IntegrationsConst.GET_AUDIT_LOG_DATA_SUCCESS,
    payload
  };
};

export const getAuditLogConfigErrorAction = (error: string) =>
  errorAction(IntegrationsConst.GET_AUDIT_LOG_DATA_ERROR, error);

export const getWebHookRequestsLoadingAction = () =>
  loadingAction(IntegrationsConst.GET_WEB_HOOK_REQUESTS_LOADING);

export const getWebHookRequestsSuccessAction = (
  data: GetWebHookRequestsSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.GET_WEB_HOOK_REQUESTS_SUCCESS,
    payload
  };
};

export const getWebHookRequestsErrorAction = (error: string) =>
  errorAction(IntegrationsConst.GET_WEB_HOOK_REQUESTS_ERROR, error);

export interface DeleteWebHookResponseSchema extends SideEffectSchema {
  IsDeleted?: boolean;
}

export interface DeleteWebhookRequestSchema {
  HookId: string;
}

export const deleteWebHookAction = (payload: DeleteWebhookRequestSchema) => {
  return {
    type: IntegrationsConst.DELETE_WEB_HOOK,
    payload
  };
};

export const deleteWebHookLoadingAction = () =>
  loadingAction(IntegrationsConst.DELETE_WEB_HOOK_LOADING);

export const deleteWebHookSuccessAction = (
  data: DeleteWebHookResponseSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.DELETE_WEB_HOOK_SUCCESS,
    payload
  };
};

export const deleteWebHookErrorAction = (error: string) =>
  errorAction(IntegrationsConst.DELETE_WEB_HOOK_ERROR, error);

export interface AddWebHookResponseSchema extends SideEffectSchema {
  IsPosted?: boolean;
}

export interface AddWebhookRequestSchema {
  name: string;
  event: string;
  targeturl: string;
  secretname: string;
}
export const saveAuditLogConfigAction = (payload: AuditLogBodySchema) => {
  return {
    type: IntegrationsConst.SAVE_AUDIT_LOG_DATA,
    payload
  };
};
export const saveAuditLogConfigLoadingAction = () =>
  loadingAction(IntegrationsConst.SAVE_AUDIT_LOG_DATA_LOADING);

export const saveAuditLogConfigSuccessAction = (
  data: AddWebHookResponseSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.SAVE_AUDIT_LOG_DATA_SUCCESS,
    payload
  };
};

export const saveAuditLogConfigErrorAction = (error: string) =>
  errorAction(IntegrationsConst.SAVE_AUDIT_LOG_DATA_ERROR, error);

export const resetAuditLogConfigAction = () => {
  return {
    type: IntegrationsConst.DELETE_AUDIT_LOG_DATA,
    payload: {}
  };
};
export const resetAuditLogConfigLoadingAction = () =>
  loadingAction(IntegrationsConst.DELETE_AUDIT_LOG_DATA_LOADING);

export const resetAuditLogConfigSuccessAction = (
  data: ResetAuditLogConfigSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.DELETE_AUDIT_LOG_DATA_SUCCESS,
    payload
  };
};

export const resetAuditLogConfigErrorAction = (error: string) =>
  errorAction(IntegrationsConst.DELETE_AUDIT_LOG_DATA_ERROR, error);

export const addWebHookAction = (payload: AddWebhookRequestSchema) => {
  return {
    type: IntegrationsConst.ADD_WEB_HOOK,
    payload
  };
};

export const addWebHookLoadingAction = () =>
  loadingAction(IntegrationsConst.ADD_WEB_HOOK_LOADING);

export const addWebHookSuccessAction = (data: AddWebHookResponseSchema) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.ADD_WEB_HOOK_SUCCESS,
    payload
  };
};

export const addWebHookErrorAction = (error: string) =>
  errorAction(IntegrationsConst.ADD_WEB_HOOK_ERROR, error);

interface QuotaUsageSchema {
  Quota: number;
  Type: string;
}

export interface UpdateWebHookResponseSchema extends SideEffectSchema {
  IsPosted?: boolean;
}

export interface UpdateWebhookRequestSchema {
  CustomObjects: string;
  HookId: string;
  SecretName: string;
}

export const updateWebHookAction = (
  payload: UpdateWebhookRequestSchema,
  i18n: LanguageSchema
) => {
  return {
    type: IntegrationsConst.UPDATE_WEB_HOOK,
    payload,
    i18n
  };
};

export const updateWebHookLoadingAction = () =>
  loadingAction(IntegrationsConst.UPDATE_WEB_HOOK_LOADING);

export const updateWebHookSuccessAction = (
  data: UpdateWebHookResponseSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.UPDATE_WEB_HOOK_SUCCESS,
    payload
  };
};

export const updateWebHookErrorAction = (error: string) =>
  errorAction(IntegrationsConst.UPDATE_WEB_HOOK_ERROR, error);

export interface WebHookQuotaSchema extends SideEffectSchema {
  QuotaUsage?: QuotaUsageSchema[];
  description?: string;
  errorCode?: number;
  message?: string;
  lrmessage?: string;
}

export const getWebHookQuotaAction = () => {
  return {
    type: IntegrationsConst.GET_WEB_HOOK_QUOTA,
    payload: {}
  };
};

export const getWebHookQuotaLoadingAction = () =>
  loadingAction(IntegrationsConst.GET_WEB_HOOK_QUOTA_LOADING);

export const getWebHookQuotaSuccessAction = (data: WebHookQuotaSchema) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.GET_WEB_HOOK_QUOTA_SUCCESS,
    payload
  };
};

export const getWebHookQuotaErrorAction = (error: string) =>
  errorAction(IntegrationsConst.GET_WEB_HOOK_QUOTA_ERROR, error);

export interface SetDefaultQuotaResponseSchema extends SideEffectSchema {
  data: any;
}

export const setDefaultQuotaAction = () => {
  return {
    type: IntegrationsConst.SET_DEFAULT_QUOTA,
    payload: {}
  };
};

export const setDefaultQuotaLoadingAction = () =>
  loadingAction(IntegrationsConst.SET_DEFAULT_QUOTA_LOADING);

export const setDefaultQuotaSuccessAction = (
  data: SetDefaultQuotaResponseSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.SET_DEFAULT_QUOTA_SUCCESS,
    payload
  };
};

export const setDefaultQuotaErrorAction = (error: string) =>
  errorAction(IntegrationsConst.SET_DEFAULT_QUOTA_ERROR, error);

//
// QUEUE_BULK_ACTION
//

export interface QueueBlukActionResponseSchema extends SideEffectSchema {
  lrmessage?: string;
  status?: string;
  statusCode?: number;
}

export interface UploadBlobResponseSchema {
  Bucket: string;
  ETag: string;
  Key: string;
  Location: string;
  clientIP: string;
  key: string;
}

export interface QueueBulkActionParamsSchema {
  formData: FormData;
  options: {
    Action: {
      Type: ActionTypeEnum;
      Lookup: {
        Type: LookupTypeEnum;
        Key: string;
      };
      Return: string[] | null;
    };
    StorageId: string;
    Password: string;
    Settings: {
      Plugin: string;
      ExtraOptions: any;
      Maps: {
        Type: MapTypeEnum;
        ColumnIndex: number;
        Key: string;
        Value: string | null;
      }[];
    };
    CreatedBy: string;
  };
}

export enum ActionTypeEnum {
  CREATE = "Create",
  UPDATE = "Update",
  DELETE = "Delete",
  UPSERT = "Upsert",
  GET = "Get"
}

export enum LookupTypeEnum {
  UID = "Uid",
  USERNAME = "UserName",
  PHONEID = "PhoneId",
  EMAIL = "Email"
}

export enum MapTypeEnum {
  REFERENCE = "reference",
  VALUE = "value"
}

export const queueBulkActionAction = (payload: QueueBulkActionParamsSchema) => {
  return {
    type: IntegrationsConst.QUEUE_BULK_ACTION,
    payload
  };
};

export const queueBulkActionLoadingAction = () =>
  loadingAction(IntegrationsConst.QUEUE_BULK_ACTION_LOADING);

export const queueBulkActionSuccessAction = (
  data: QueueBlukActionResponseSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.QUEUE_BULK_ACTION_SUCCESS,
    payload
  };
};

export const queueBulkActionErrorAction = (error: string) =>
  errorAction(IntegrationsConst.QUEUE_BULK_ACTION_ERROR, error);

//
// GET_ETL_LOGS
//

export interface EtlLogsResponseSchema extends SideEffectSchema {
  Data: {
    AppId: number;
    AppName: string;
    RequestId: string;
    RequestStatus: string;
    RequestCreateTime: string;
    RequestCompleteTime?: string;
    LogLocationLink: string;
    ActionType: string;
  }[];
  TotalLogCount: number;
  description?: string;
  errorCode?: number;
  message?: string;
}

export interface EtlLogsParamsSchema {
  take: number;
  skip: number;
}

export const getEtlLogsAction = (payload: EtlLogsParamsSchema) => {
  return {
    type: IntegrationsConst.GET_ETL_LOGS,
    payload
  };
};

export const getEtlLogsLoadingAction = () =>
  loadingAction(IntegrationsConst.GET_ETL_LOGS_LOADING);

export const getEtlLogsSuccessAction = (data: EtlLogsResponseSchema) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.GET_ETL_LOGS_SUCCESS,
    payload
  };
};

export const getEtlLogsErrorAction = (error: string) =>
  errorAction(IntegrationsConst.GET_ETL_LOGS_ERROR, error);

/**
 * GET_AVAILABLE_INTEGRATIONS
 */
export interface AvailableIntegrationsSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  AuthorizedIntegrations: string[];
}

export const getAvailableIntegrationsAction = () => {
  return {
    type: IntegrationsConst.GET_AVAILABLE_INTEGRATIONS
  };
};

export const getAvailableIntegrationsLoadingAction = () =>
  loadingAction(IntegrationsConst.GET_AVAILABLE_INTEGRATIONS_LOADING);

export const getAvailableIntegrationsErrorAction = (error: string) =>
  errorAction(IntegrationsConst.GET_AVAILABLE_INTEGRATIONS_ERROR, error);

export const getAvailableIntegrationsSuccessAction = (
  data: AvailableIntegrationsSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.GET_AVAILABLE_INTEGRATIONS_SUCCESS,
    payload
  };
};

/**
 * GET_CREATED_INTEGRATIONS
 */
export interface IntegrationSchema {
  Event: string;
  Id: string;
  IntegrationDescription: string | null;
  IntegrationLabel: string | null;
  IntegrationName: string;
  IsActive: boolean;
  IsConfigurable: boolean;
  IsConfigured: boolean;
  IsCustom: boolean;
  IsEnable: boolean;
}
export interface CreatedIntegrationsSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  Data: IntegrationSchema[];
}

export const getCreatedIntegrationsAction = () => {
  return {
    type: IntegrationsConst.GET_CREATED_INTEGRATIONS
  };
};

export const getCreatedIntegrationsLoadingAction = () =>
  loadingAction(IntegrationsConst.GET_CREATED_INTEGRATIONS_LOADING);

export const getCreatedIntegrationsErrorAction = (error: string) =>
  errorAction(IntegrationsConst.GET_CREATED_INTEGRATIONS_ERROR, error);

export const getCreatedIntegrationsSuccessAction = (
  data: CreatedIntegrationsSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.GET_CREATED_INTEGRATIONS_SUCCESS,
    payload
  };
};

/**
 * ACTIVATE_INTEGRATION
 */
export interface ActivateIntegrationSchema extends SideEffectSchema {
  data: IntegrationSchema & APIErrorResponseSchema;
  id?: string;
}
export interface ActivateIntegrationArgs {
  Event: string;
  IntegrationName: string;
  id: string;
  isEnable?: boolean;
}
export const activateIntegrationAction = (payload: ActivateIntegrationArgs) => {
  return {
    type: IntegrationsConst.ACTIVATE_INTEGRATION,
    payload: { ...payload, isEnable: true }
  };
};

export const activateIntegrationLoadingAction = (id: string) =>
  loadingAction(IntegrationsConst.ACTIVATE_INTEGRATION_LOADING, { id });

export const activateIntegrationErrorAction = (error: string) =>
  errorAction(IntegrationsConst.ACTIVATE_INTEGRATION_ERROR, error, {
    id: undefined
  });

export const activateIntegrationSuccessAction = (
  data: ActivateIntegrationSchema
) => {
  let payload = { ...data, id: undefined, ...successSideEffectState };
  return {
    type: IntegrationsConst.ACTIVATE_INTEGRATION_SUCCESS,
    payload
  };
};

/**
 * DEACTIVATE_INTEGRATION
 */
export interface DeactivateIntegrationSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  isdeleted: boolean;
  id?: string;
}
export interface DeactivateIntegrationArgs {
  integrationId: string;
}
export const deactivateIntegrationAction = (
  payload: DeactivateIntegrationArgs
) => {
  return {
    type: IntegrationsConst.DEACTIVATE_INTEGRATION,
    payload
  };
};

export const deactivateIntegrationLoadingAction = (id: string) =>
  loadingAction(IntegrationsConst.DEACTIVATE_INTEGRATION_LOADING, { id });

export const deactivateIntegrationErrorAction = (error: string) =>
  errorAction(IntegrationsConst.DEACTIVATE_INTEGRATION_ERROR, error, {
    id: undefined
  });

export const deactivateIntegrationSuccessAction = (
  data: DeactivateIntegrationSchema
) => {
  let payload = { ...data, id: undefined, ...successSideEffectState };
  return {
    type: IntegrationsConst.DEACTIVATE_INTEGRATION_SUCCESS,
    payload
  };
};

/**
 * GET_INTEGRATION_DATA
 */
export interface IntegrationDataSchema {
  Credentials: ObjectSchema<string> | null;
  Description: string | null;
  DownwardMapping: ObjectSchema<string> | null;
  Event: string;
  Filter: ObjectSchema<any>[] | null;
  Id: string;
  IntegrationName: string;
  IsActive: boolean;
  IsEnable: boolean;
  Label: string | null;
  Mapping: { Map: ObjectSchema<any>[] } | null;
  Meta: ObjectSchema<string> | null;
  PluginFilters: ObjectSchema<any>[] | null;
  Script: string | null;
}

export interface GetIntegrationDataSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  data: IntegrationDataSchema;
}
export interface GetIntegrationDataArgs {
  id: string;
}
export const getIntegrationDataAction = (payload: GetIntegrationDataArgs) => {
  return {
    type: IntegrationsConst.GET_INTEGRATION_DATA,
    payload
  };
};

export const getIntegrationDataLoadingAction = () =>
  loadingAction(IntegrationsConst.GET_INTEGRATION_DATA_LOADING);

export const getIntegrationDataErrorAction = (error: string) =>
  errorAction(IntegrationsConst.GET_INTEGRATION_DATA_ERROR, error);

export const getIntegrationDataSuccessAction = (
  data: GetIntegrationDataSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.GET_INTEGRATION_DATA_SUCCESS,
    payload
  };
};

/**
 * GET_INTEGRATION_CONFIG
 */
export interface IntegrationConfigSchema {
  CredRequiredParams: string[];
  HasDownwardMapping: false;
  MetaRequiredParams: string[];
}

export interface GetIntegrationConfigSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  schema: ObjectSchema<IntegrationConfigSchema>;
}
export const getIntegrationConfigAction = () => {
  return {
    type: IntegrationsConst.GET_INTEGRATION_CONFIG
  };
};

export const getIntegrationConfigLoadingAction = () =>
  loadingAction(IntegrationsConst.GET_INTEGRATION_CONFIG_LOADING);

export const getIntegrationConfigErrorAction = (error: string) =>
  errorAction(IntegrationsConst.GET_INTEGRATION_CONFIG_ERROR, error);

export const getIntegrationConfigSuccessAction = (
  data: GetIntegrationConfigSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.GET_INTEGRATION_CONFIG_SUCCESS,
    payload
  };
};

/**
 * SAVE_INTEGRATION_DATA
 */
export interface SaveIntegrationDataSchema
  extends SideEffectSchema,
    APIErrorResponseSchema {
  IsPosted: boolean;
}
export interface SaveIntegrationDataArgs {
  Id?: string;
  PluginFilters?: ObjectSchema<string>;
  Filter?: ObjectSchema<string>;
  Script?: string;
  DownwardMapping?: ObjectSchema<string> | null;
  IntegrationName: string;
  Event: string;
  ChangeEventTo?: string;
  IsEnable: boolean;
  IsActive: boolean;
  Mapping: ObjectSchema<string> | null;
  Credentials: ObjectSchema<string>;
  Meta: ObjectSchema<string>;
}
export const saveIntegrationDataAction = (payload: SaveIntegrationDataArgs) => {
  return {
    type: IntegrationsConst.SAVE_INTEGRATION_DATA,
    payload
  };
};

export const saveIntegrationDataLoadingAction = () =>
  loadingAction(IntegrationsConst.SAVE_INTEGRATION_DATA_LOADING);

export const saveIntegrationDataErrorAction = (error: string) =>
  errorAction(IntegrationsConst.SAVE_INTEGRATION_DATA_ERROR, error);

export const saveIntegrationDataSuccessAction = (
  data: SaveIntegrationDataSchema
) => {
  let payload = { ...data, ...successSideEffectState };
  return {
    type: IntegrationsConst.SAVE_INTEGRATION_DATA_SUCCESS,
    payload
  };
};
//auditlog
export interface AuditLogConfigResponseSchema {
  description?: string;
  errorCode?: number;
  message?: string;
  Provider?: string;
  Endpoint?: string;
  IsSSLAuthEnabled?: boolean;
  RootCAPath?: string;
  ClientCertPath?: string;
  ClientPrivateKey?: string;
  Domain?: string;
  StorageCollection?: string;
  Credentials?: {
    Username?: string;
    Token?: string;
  };
}
export interface GetAuditLogConfigSchema extends SideEffectSchema {
  data?: any;
}
export interface SaveAuditLogConfigSchema extends SideEffectSchema {
  data?: any;
}
export interface ResetAuditLogConfigSchema extends SideEffectSchema {
  data?: any;
}

export interface AuditLogBodySchema {
  description?: string;
  errorCode?: number;
  message?: string;
  Provider?: string;
  Endpoint?: string;
  IsSSLAuthEnabled?: boolean;
  RootCAPath?: string;
  ClientCertPath?: string;
  ClientPrivateKey?: string;
  Domain?: string;
  StorageCollection?: string;
  Credentials?: {
    Username?: string;
    Token?: string;
    Password?: string;
  };
}

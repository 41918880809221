import * as React from "react";
import "./image.scss";

export interface ImageProps {
  ariaLabel?: string;
  ariaLabelledby?: string;
  className?: string;
  id?: string;
  src: string;
  title?: string;
  width?: number;
  height?: number;
  alt: string;
  role?: string;
  onClick?: (event: any) => void;
}

const Image = React.memo((props: ImageProps) => {
  const { ariaLabel, ariaLabelledby } = props;
  return (
    <img {...props} aria-label={ariaLabel} aria-labelledby={ariaLabelledby} />
  );
});

export default Image;

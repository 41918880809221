import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Route, Redirect, withRouter } from "react-router-dom";

// Schemas
import { RootSchema } from "../redux/reducers";
import { ObjectSchema } from "../redux/actions/index";
import { AppBasicInfoSchema } from "../redux/actions/authActions";
import { NavigationItemsSchema } from "../containers/Navigation";

// Components
import ExternalRedirect from "../components/ExternalRedirect";

export interface PrivateRouteProps extends RouteComponentProps {
  loginDomain: string;
  isAdminRoute?: boolean;
  isMaintenanceRoute?: boolean;
  [key: string]: any;
  isAdmin?: string;
  initAppBasicInfo?: () => void;
  currentAppId?: string;
  isMaintenance?: boolean;
  appBasicInfo: AppBasicInfoSchema;
  IOLoginUrl: string;
  IOLogoutUrl: string;
}

let accessCache: ObjectSchema<boolean> | undefined;
const PrivateRoute = ({
  isAdminRoute,
  isMaintenanceRoute,
  loginDomain,
  isAdmin,
  currentAppId,
  initAppBasicInfo,
  isMaintenance,
  appBasicInfo,
  location,
  IOLoginUrl,
  children,
  ...rest
}: PrivateRouteProps) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const path = location.pathname;

  return (
    <Route
      {...rest}
      render={props => {
        if (appBasicInfo.isSuccess) {
          if (!accessCache) {
            accessCache = createAccessCache(appBasicInfo.navigationItems);
          }
          const _isAuthorized = accessCache[path];
          if (!_isAuthorized) {
            return <Redirect to="/dashboard" />;
          }
        }

        if (isAdmin && !currentAppId) {
          if (isAdminRoute) return children;
          else return <Redirect to="/superadmin/main" />;
        } else {
          if (isMaintenance) {
            if (isMaintenanceRoute) return children;
            else return <Redirect to="/maintenance" />;
          } else return children;
        }
      }}
    />
  );
};

const mapStateToProps = (state: RootSchema) => {
  return {
    loginDomain: state.auth.authConfig.domain,
    isAdmin: state.auth.authConfig.superadminUid,
    currentAppId: state.auth.authConfig.currentAppId,
    isMaintenance: state.auth.authConfig.maintenance,
    appBasicInfo: state.auth.appBasicInfo,
    IOLoginUrl: state.auth.authConfig.IOLoginUrl,
    IOLogoutUrl: state.auth.authConfig.IOLogoutUrl
  };
};

/**
 * This function retuns a object containing mapping of paths and their access
 * @param navItems List of navigation items
 */
const createAccessCache = (navItems: NavigationItemsSchema[]) => {
  const _cache: ObjectSchema<boolean> = {};
  for (let nInd in navItems) {
    // Cache the root path
    const _root = navItems[nInd].link;
    _cache[_root] = true;

    const _sections = navItems[nInd].sections;
    const _subsections = navItems[nInd].subsections;
    for (let sInd = 0; sInd < _subsections.length; sInd++) {
      // Cache the section path, if exists
      const _secPath = _root + (_sections[sInd] ? _sections[sInd].link : "");
      _cache[_secPath] = true;

      // Cache the subsection path
      const _subsection = _subsections[sInd];
      for (let _sub of _subsection) {
        const _subSecPath = _secPath + _sub.link;
        _cache[_subSecPath] = true;

        // Cache the tab path
        if (_sub.sections) {
          for (let _tab of _sub.sections) {
            const _tabPath = _subSecPath + _tab.link;
            _cache[_tabPath] = true;
          }
        }
      }
    }
  }
  _cache["/site-list"] = true;
  return _cache;
};

export default connect(mapStateToProps)(withRouter(PrivateRoute));

import { call, put, select, take } from "redux-saga/effects";
import {
  ControlValuesSchema,
  LoginTemplateValuesSchema,
  BrandCustomizationConst,
  setControlValuesLoadingAction,
  setControlValuesErrorAction,
  setControlValuesSuccessAction,
  setLoginTemplateValuesLoadingAction,
  setLoginTemplateValuesErrorAction,
  setLoginTemplateValuesSuccessAction,
  LoginTemplateValuesSelectSchema,
  ControlValuesSelectSchema
} from "../actions/brandCustomizationActions";
// import { httpGet } from "../../services";

function* setControlValuesHandler(payload: ControlValuesSelectSchema) {
  try {
    yield put(setControlValuesLoadingAction());

    // const data: GetWorkflowsSchema = yield httpGet(
    //   "/deployment/orchestration/workflows",
    //   {}
    // );

    yield put(setControlValuesSuccessAction(payload));
  } catch (e) {
    yield put(setControlValuesErrorAction(e.message));
  }
}

export function* watchSetControlValues() {
  while (true) {
    const { payload } = yield take(BrandCustomizationConst.SET_CONTROL_VALUES);
    const controlValues = yield select(
      state => state.brandCustomization.controlValues
    );
    yield call(setControlValuesHandler, { ...controlValues, ...payload });
  }
}

function* setLoginTemplateValuesHandler(
  payload: LoginTemplateValuesSelectSchema
) {
  try {
    yield put(setLoginTemplateValuesLoadingAction());

    // const data: GetWorkflowsSchema = yield httpGet(
    //   "/deployment/orchestration/workflows",
    //   {}
    // );

    yield put(setLoginTemplateValuesSuccessAction(payload));
  } catch (e) {
    yield put(setLoginTemplateValuesErrorAction(e.message));
  }
}

export function* watchSetLoginTemplateValues() {
  while (true) {
    const { payload } = yield take(
      BrandCustomizationConst.SET_LOGIN_TEMPLATE_VALUES
    );
    const loginTemplateValues = yield select(
      state => state.brandCustomization.loginTemplateValues
    );
    yield call(setLoginTemplateValuesHandler, {
      ...loginTemplateValues,
      ...payload
    });
  }
}

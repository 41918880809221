import { put, takeLatest } from "redux-saga/effects";
import { httpGet } from "../../services";
import { ActionSchema } from "../actions";
import {
  ApiMetricsConst,
  GetApiMetricsArgs,
  getPerformanceDataErrorAction,
  getPerformanceDataLoadingAction,
  GetPerformanceDataSchema,
  getPerformanceDataSuccessAction,
  getRequestCountDataErrorAction,
  getRequestCountDataLoadingAction,
  getRequestCountDataSuccessAction,
  getResponseCodeDataErrorAction,
  getResponseCodeDataLoadingAction,
  getResponseCodeDataSuccessAction,
  GetSmsAnalyticsArgs,
  getSmsAnalyticsDataErrorAction,
  getSmsAnalyticsDataLoadingAction,
  getSmsAnalyticsDataSuccessAction,
  GetSmsStatusArgs,
  getSmsStatusDataLoadingAction,
  getSmsStatusDataSuccessAction,
  getSmsStatusDataErrorAction,
  GetLogsArgs,
  getLogsDataSuccessAction,
  getLogsDataErrorAction,
  getLogsDataLoadingAction
} from "../actions/apiMetricsActions";

/**
 * Handlers
 */
function* getRequestCountDataHandler(args: GetApiMetricsArgs) {
  try {
    yield put(getRequestCountDataLoadingAction());
    const { startTime, apiKey, endTime, timeslice } = args;
    const response = yield httpGet("/api-metrics/request-count", {
      startTime,
      endTime,
      timeslice,
      apiKey
    });

    yield put(getRequestCountDataSuccessAction({ data: response.data }));
  } catch (e) {
    yield put(getRequestCountDataErrorAction(e.message));
  }
}

function* getResponseCodeDataHandler(args: GetApiMetricsArgs) {
  try {
    yield put(getResponseCodeDataLoadingAction());
    const { startTime, apiKey, endTime, timeslice } = args;
    const response = yield httpGet("/api-metrics/response-code", {
      startTime,
      apiKey,
      endTime,
      timeslice
    });

    if (response.code) {
      throw new Error("Error getting data. Please try again.");
    }

    yield put(getResponseCodeDataSuccessAction({ data: response.data }));
  } catch (e) {
    yield put(getResponseCodeDataErrorAction(e.message));
  }
}

function* getPerformanceDataHandler(args: GetApiMetricsArgs) {
  try {
    yield put(getPerformanceDataLoadingAction());
    const { startTime, endpoint, apiKey, endTime, timeslice } = args;
    const response: GetPerformanceDataSchema = yield httpGet(
      `/api-metrics/performance/${endpoint}`,
      {
        startTime,
        endTime,
        apiKey,
        timeslice
      }
    );

    if (response.code) {
      throw new Error("Error getting data. Please try again.");
    }

    yield put(getPerformanceDataSuccessAction({ data: response.data }));
  } catch (e) {
    yield put(getPerformanceDataErrorAction(e.message));
  }
}

function* getSmsAnalyticsDataHandler(args: GetSmsAnalyticsArgs) {
  try {
    yield put(getSmsAnalyticsDataLoadingAction());
    const { startTime, endTime, appName, appId, timeslice } = args;
    const response = yield httpGet("/api-metrics/sms-count", {
      startTime,
      endTime,
      timeslice,
      appName,
      appId
    });

    yield put(getSmsAnalyticsDataSuccessAction({ data: response.data }));
  } catch (e) {
    yield put(getSmsAnalyticsDataErrorAction(e.message));
  }
}

function* getSmsStatusDataHandler(args: GetSmsStatusArgs) {
  try {
    yield put(getSmsStatusDataLoadingAction());
    const { startTime, endTime, twilioId, timeslice } = args;
    const response = yield httpGet("/api-metrics/sms-status", {
      startTime,
      endTime,
      timeslice,
      twilioId
    });

    yield put(getSmsStatusDataSuccessAction({ data: response.data }));
  } catch (e) {
    yield put(getSmsStatusDataErrorAction(e.message));
  }
}

function* getLogsDataHandler(args: GetLogsArgs) {
  try {
    yield put(getLogsDataLoadingAction());
    const { startTime, endTime, appName, appId } = args;
    const response = yield httpGet("/api-metrics/saml-error", {
      appName,
      appId,
      startTime,
      endTime
    });
    yield put(getLogsDataSuccessAction({ data: response.data }));
  } catch (e) {
    yield put(getLogsDataErrorAction(e.message));
  }
}

/**
 * Watchers
 */
export function* watchRequestCountData() {
  yield takeLatest(
    ApiMetricsConst.GET_REQUEST_COUNT_DATA,
    ({ payload }: ActionSchema<GetApiMetricsArgs>) =>
      getRequestCountDataHandler(payload)
  );
}

export function* watchGetResponseCodeData() {
  yield takeLatest(
    ApiMetricsConst.GET_RESPONSE_CODE_DATA,
    ({ payload }: ActionSchema<GetApiMetricsArgs>) =>
      getResponseCodeDataHandler(payload)
  );
}

export function* watchPerformanceData() {
  yield takeLatest(
    ApiMetricsConst.GET_PERFORMANCE_DATA,
    ({ payload }: ActionSchema<GetApiMetricsArgs>) =>
      getPerformanceDataHandler(payload)
  );
}

export function* watchSmsAnalyticsData() {
  yield takeLatest(
    ApiMetricsConst.GET_SMS_ANALYTICS_DATA,
    ({ payload }: ActionSchema<GetSmsAnalyticsArgs>) =>
      getSmsAnalyticsDataHandler(payload)
  );
}

export function* watchSmsStatusData() {
  yield takeLatest(
    ApiMetricsConst.GET_SMS_STATUS_DATA,
    ({ payload }: ActionSchema<GetSmsStatusArgs>) =>
      getSmsStatusDataHandler(payload)
  );
}

export function* watchLogsData() {
  yield takeLatest(
    ApiMetricsConst.GET_LOGS_DATA,
    ({ payload }: ActionSchema<GetLogsArgs>) => getLogsDataHandler(payload)
  );
}

export const currencyFormatter = (
  amountInDollars = 0,
  threeLetterCurrencyCode = "USD",
  languageCountryCode = "en-US"
) => {
  return new Intl.NumberFormat(languageCountryCode, {
    style: "currency",
    currency: threeLetterCurrencyCode
  }).format(amountInDollars);
};

/*
We don't neesd to declare any magic numver. you can use below function.
Example-: getMagicNumber("NUM_198");
*/
var MagicMaker;
(function(MagicMaker) {
  MagicMaker[(MagicMaker["1"] = 1)] = "1";
  MagicMaker[(MagicMaker["2"] = 2)] = "2";
  MagicMaker[(MagicMaker["3"] = 3)] = "3";
  MagicMaker[(MagicMaker["4"] = 4)] = "4";
  MagicMaker[(MagicMaker["5"] = 5)] = "5";
  MagicMaker[(MagicMaker["6"] = 6)] = "6";
  MagicMaker[(MagicMaker["7"] = 7)] = "7";
  MagicMaker[(MagicMaker["8"] = 8)] = "8";
  MagicMaker[(MagicMaker["9"] = 9)] = "9";
  MagicMaker[(MagicMaker["0"] = 0)] = "0";
})(MagicMaker || (MagicMaker = {}));
export const getMagicNumber = function(data: string) {
  var returnData = "";
  for (var i = 0; i < data.length; i++) {
    var filterChar =
      MagicMaker[
        String(data)
          .charAt(i)
          .toLowerCase()
      ];
    if (filterChar) returnData = returnData + filterChar;
  }
  return Number(returnData);
};

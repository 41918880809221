import * as React from "react";
import "./Loader.scss";
import { MDBSpinner } from "mdbreact";

export interface LoaderProps {
  message?: string;
  type?: "border" | "grow" | "placeholder";
  big?: boolean;
  small?: boolean;
}

/**
 * This component creates 3 types of loaders: border, grow and placeholder
 * It takes the width and height of the parent component, and displays a
 * center aligned loading indicator.
 * @param props Accepts a type and message. Other parameters are passed as it is to the MDBSpinner
 */
const Loader = React.memo((props: LoaderProps) => {
  let { type, message } = props;

  type = type || "border";

  // FIXME:The CSS in this implementation has some issues.
  if (type === "placeholder") {
    return (
      <div className="loader-wrapper">
        <div className="loader-content">
          <div className="ph-item">
            <div className="ph-col-12">
              <div className="ph-picture" />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === "border") {
    return (
      <div className="loader-wrapper">
        <div className="loader-content">
          <MDBSpinner {...props} />
          <span>{message || ""}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="loader-wrapper">
        <div className="loader-content">
          <div className={`spinner-grow text-primary`} role="status">
            <span className="sr-only">{message || ""}</span>
          </div>
          <span>{message || ""}</span>
        </div>
      </div>
    );
  }
});

export default Loader;

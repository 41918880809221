import {
  SideEffectSchema,
  loadingAction,
  errorAction,
  successSideEffectState,
  generateConst
} from ".";

// Constants
export const InsightsConst = generateConst("INSIGHTS", [
  "GET_CREATEDATE",
  "GET_LOGS_OVERVIEW",
  "GET_USER_HOSTS",
  "GET_LOGS_HOSTS",
  "GET_DELETE_USERS",
  "GET_USERS_OVERVIEW",
  "GET_GROWTH_DATA",
  "GET_USERS_DATA",
  "GET_REGISTERED_USERS",
  "GET_USER_LOGS",
  "GET_PROVIDER_DATA",
  "GET_MONTHLY_HISTORY",
  "GET_TOTAL_LOGINS",
  "GET_RETURN_RATE",
  "GET_HIGHEST_GROWTH_DATA",
  "GET_BEST_REGISTRATION_STREAKS",
  "GET_WORST_REGISTRATION_STREAKS"
]);

/**
 * GET_CREATEDATE
 */
export interface GetCreateDateSchema extends SideEffectSchema {
  minimumDate: string;
  errorCode?: string;
  description?: string;
}

export const getCreateDateAction = () => {
  return {
    type: InsightsConst.GET_CREATEDATE,
    payload: {}
  };
};

export const getCreateDateLoadingAction = () =>
  loadingAction(InsightsConst.GET_CREATEDATE_LOADING);

export const getCreateDateErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_CREATEDATE_ERROR, error);

export const getCreateDateSuccessAction = (minimumDate: string) => {
  const payload: GetCreateDateSchema = {
    minimumDate,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_CREATEDATE_SUCCESS,
    payload
  };
};

/**
 * GET_LOGS_OVERVIEW
 */
export interface GetLogsOverviewSchema extends SideEffectSchema {
  totalLogins: number;
  totalLoginsMonth: number;
  totalLoginsWeek: number;
  totalActiveUsersMonth: number;
  totalActiveUsersWeek: number;
  heatMapData: { [timestamp: string]: number };
}

export const getLogsOverviewAction = (timezone: string) => {
  return {
    type: InsightsConst.GET_LOGS_OVERVIEW,
    payload: { timezone }
  };
};

export const getLogsOverviewLoadingAction = () =>
  loadingAction(InsightsConst.GET_LOGS_OVERVIEW_LOADING);

export const getLogsOverviewErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_LOGS_OVERVIEW_ERROR, error);

export const getLogsOverviewSuccessAction = (data: GetLogsOverviewSchema) => {
  const payload: GetLogsOverviewSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_LOGS_OVERVIEW_SUCCESS,
    payload
  };
};

/**
 * GET_DELETE_USERS
 */
export interface GetDeleteUsersSchema extends SideEffectSchema {
  accountsWithGreaterThanThreeProfiles: number;
  accountsWithOneProfile: number;
  accountsWithThreeProfiles: number;
  accountsWithTwoProfiles: number;
  selectedAccounts: number;
  selectedBlockedProfiles: number;
  selectedDeletedProfiles: number;
  selectedProfiles: number;
  totalAccounts: number;
  totalBlockedProfiles: number;
  totalDeletedProfiles: number;
  totalProfiles: number;
}

export const getDeleteUsersAction = (fromdate: string, todate: string) => {
  return {
    type: InsightsConst.GET_DELETE_USERS,
    payload: { fromdate, todate }
  };
};

export const getDeleteUsersLoadingAction = () =>
  loadingAction(InsightsConst.GET_DELETE_USERS_LOADING);

export const getDeleteUsersErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_DELETE_USERS_ERROR, error);

export const getDeleteUsersSuccessAction = (data: GetDeleteUsersSchema) => {
  const payload: GetDeleteUsersSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_DELETE_USERS_SUCCESS,
    payload
  };
};

/**
 * GET_USERS_OVERVIEW
 */
export interface GetUsersOverviewSchema extends SideEffectSchema {
  topCountry: string;
  topCountryMonth: string;
  topCountryWeek: string;
  topProvider: string;
  topProviderMonth: string;
  topProviderWeek: string;
  totalProfiles: number;
  totalUsers: number;
  totalUsersMonth: number;
  totalUsersWeek: number;
  heatMapData: { [timestamp: string]: number };
  users: any[]; // TODO: replace any with users schema
}

export const getUsersOverviewAction = () => {
  return {
    type: InsightsConst.GET_USERS_OVERVIEW,
    payload: {}
  };
};

export const getUsersOverviewLoadingAction = () =>
  loadingAction(InsightsConst.GET_USERS_OVERVIEW_LOADING);

export const getUsersOverviewErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_USERS_OVERVIEW_ERROR, error);

export const getUsersOverviewSuccessAction = (data: GetUsersOverviewSchema) => {
  const payload: GetUsersOverviewSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_USERS_OVERVIEW_SUCCESS,
    payload
  };
};

/**
 * GET_USER_HOSTS
 */
export interface GetUserHostsSchema extends SideEffectSchema {
  hostData: string[];
  totalUsers: number;
  errorCode?: number;
  description?: string;
}

export const getUserHostsAction = () => {
  return {
    type: InsightsConst.GET_USER_HOSTS,
    payload: {}
  };
};

export const getUserHostsLoadingAction = () =>
  loadingAction(InsightsConst.GET_USER_HOSTS_LOADING);

export const getUserHostsErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_USER_HOSTS_ERROR, error);

export const getUserHostsSuccessAction = (data: GetUserHostsSchema) => {
  const payload: GetUserHostsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_USER_HOSTS_SUCCESS,
    payload
  };
};

/**
 * GET_LOGS_HOSTS
 */
export interface GetLogsHostsSchema extends SideEffectSchema {
  hostData: string[];
  totalUsers: number;
}

export const getLogsHostsAction = () => {
  return {
    type: InsightsConst.GET_LOGS_HOSTS,
    payload: {}
  };
};

export const getLogsHostsLoadingAction = () =>
  loadingAction(InsightsConst.GET_LOGS_HOSTS_LOADING);

export const getLogsHostsErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_LOGS_HOSTS_ERROR, error);

export const getLogsHostsSuccessAction = (data: GetLogsHostsSchema) => {
  const payload: GetLogsHostsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_LOGS_HOSTS_SUCCESS,
    payload
  };
};

/**
 * GET_GROWTH_DATA
 */
export interface GetGrowthDataSchema extends SideEffectSchema {
  totalUsers: number;
  growthData: {
    "Customers Deleted": number;
    Date: string;
    "New Customers": number;
    "Total Customers": number;
  }[];
  clientIP: string;
  ErrorCode?: number;
  Message?: string;
}
export interface GetGrowthDataActionArgs {
  fromdate: string;
  todate: string;
  type: string;
  timezone: string;
  host: string[] | null;
}

export const getGrowthDataAction = (args: GetGrowthDataActionArgs) => {
  return {
    type: InsightsConst.GET_GROWTH_DATA,
    payload: { ...args }
  };
};

export const getGrowthDataLoadingAction = () =>
  loadingAction(InsightsConst.GET_GROWTH_DATA_LOADING);

export const getGrowthDataErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_GROWTH_DATA_ERROR, error);

export const getGrowthDataSuccessAction = (data: GetGrowthDataSchema) => {
  const payload: GetGrowthDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_GROWTH_DATA_SUCCESS,
    payload
  };
};

/**
 * GET_USERS_DATA
 */
export interface GetUsersDataSchema extends SideEffectSchema {
  isNotRaas: boolean;
  topProvider: string;
  totalUsers: number;
  totalUsersMonth: number;
  users: any[]; // TODO: replace any with users schema
  status?: number;
}

export const getUsersDataAction = () => {
  return {
    type: InsightsConst.GET_USERS_DATA,
    payload: {}
  };
};

export const getUsersDataLoadingAction = () =>
  loadingAction(InsightsConst.GET_USERS_DATA_LOADING);

export const getUsersDataErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_USERS_DATA_ERROR, error);

export const getUsersDataSuccessAction = (data: GetUsersDataSchema) => {
  const payload: GetUsersDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_USERS_DATA_SUCCESS,
    payload
  };
};

/**
 * GET_REGISTERED_USERS
 */
export interface GetRegisteredUsersSchema extends SideEffectSchema {
  ageData: { percent: number; age: string }[];
  browserData: { title: string; value: string }[]; // TODO: return number from service instead of string. Make NaN as 0
  deviceData: {
    mobile: { title: string; value: string };
    desktop: { title: string; value: string };
    others: { title: string; value: string };
  };
  genderData: { title: string; value: number }[];
  geoData: { name: string; value: string }[];
  providerData: {
    "ID Providers": string;
    "Total Users": number;
    color: string;
  }[];
  totalUsers: number;
  ErrorCode?: number;
  Message?: string;
}
export interface GetRegisteredUsersArgs {
  fromdate: string;
  todate: string;
  type: string;
  host: string[] | null;
}

export const GetRegisteredUsersAction = (args: GetRegisteredUsersArgs) => {
  return {
    type: InsightsConst.GET_REGISTERED_USERS,
    payload: { ...args }
  };
};

export const GetRegisteredUsersLoadingAction = () =>
  loadingAction(InsightsConst.GET_REGISTERED_USERS_LOADING);

export const GetRegisteredUsersErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_REGISTERED_USERS_ERROR, error);

export const GetRegisteredUsersSuccessAction = (
  data: GetRegisteredUsersSchema
) => {
  const payload: GetRegisteredUsersSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_REGISTERED_USERS_SUCCESS,
    payload
  };
};

/**
 * GET_USER_LOGS
 */
export interface GetUserLogsSchema extends SideEffectSchema {
  logsOverview: {
    data: {
      heatMapData: { [epochTime: string]: number };
      totalActiveUsersMonth: number;
      totalActiveUsersWeek: number;
      totalLogins: number;
      totalLoginsMonth: number;
      totalLoginsWeek: number;
    };
    err: boolean;
  };
  userOverview: {
    data: {
      heatMapData: { [epochTime: string]: number };
      topCountry: string;
      topCountryMonth: string;
      topCountryWeek: string;
      topProvider: string;
      topProviderMonth: string;
      topProviderWeek: string;
      totalProfiles: number;
      totalUsers: number;
      totalUsersMonth: number;
      totalUsersWeek: number;
    };
    err: boolean;
  };
  clientIP: string;
}
export interface GetUserLogsArgs {
  timezone: string;
}

export const getUserLogsAction = (args: GetUserLogsArgs) => {
  return {
    type: InsightsConst.GET_USER_LOGS,
    payload: { ...args }
  };
};

export const getUserLogsLoadingAction = () =>
  loadingAction(InsightsConst.GET_USER_LOGS_LOADING);

export const getUserLogsErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_USER_LOGS_ERROR, error);

export const getUserLogsSuccessAction = (data: GetUserLogsSchema) => {
  const payload: GetUserLogsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_USER_LOGS_SUCCESS,
    payload
  };
};

/**
 * GET PROVIDER DATA
 */
export interface GetProviderDataSchema extends SideEffectSchema {
  ageData: { Email: number; category: string }[];
  clientIP: string;
  dayWiseData: { Email: number; date: string }[];
  genderData: { Email: number; category: string }[];
  geoData: { Email: number; category: string }[];
  providerData: string[];
  totalUsers: number;
  ErrorCode?: number;
  Message?: string;
}

export interface GetProviderDataActionArgs {
  fromdate: string;
  todate: string;
  type: string;
  timezone: string;
  host: string[] | null;
}

export const getProviderDataAction = (args: GetProviderDataActionArgs) => {
  return {
    type: InsightsConst.GET_PROVIDER_DATA,
    payload: { ...args }
  };
};
export const getProviderDataLoadingAction = () =>
  loadingAction(InsightsConst.GET_PROVIDER_DATA_LOADING);

export const getProviderDataErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_PROVIDER_DATA_ERROR, error);

export const getProviderDataSuccessAction = (data: GetProviderDataSchema) => {
  const payload: GetProviderDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_PROVIDER_DATA_SUCCESS,
    payload
  };
};

/**
 * GET_MONTHLY_HISTORY
 */
export interface MonthlyDataSchema {
  [monthAndYear: string]: {
    Date: string;
    value: number;
  }[];
}
export interface GetMonthlyHistorySchema extends SideEffectSchema {
  totalUsers: number;
  monthlyData: MonthlyDataSchema;
  status?: number;
  clientIP: string;
}
export interface GetMonthlyHistoryArgs {
  fromdate: string;
  todate: string;
  timezone: string;
  type: "userprofiles" | "accounts";
  host: string[] | null;
}
export const getMonthlyHistoryAction = (args: GetMonthlyHistoryArgs) => {
  return {
    type: InsightsConst.GET_MONTHLY_HISTORY,
    payload: { ...args }
  };
};

export const getMonthlyHistoryLoadingAction = () =>
  loadingAction(InsightsConst.GET_MONTHLY_HISTORY_LOADING);

export const getMonthlyHistoryErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_MONTHLY_HISTORY_ERROR, error);
export const getMonthlyHistorySuccessAction = (
  data: GetMonthlyHistorySchema
) => {
  const payload: GetMonthlyHistorySchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_MONTHLY_HISTORY_SUCCESS,
    payload
  };
};

/**
 * GET_TOTAL_LOGINS
 */
export interface GetLoginUsersSchema {
  browserData: { title: string; value: string }[];
  deviceData: {
    mobile: { title: string; value: string };
    desktop: { title: string; value: string };
    others: { title: string; value: string };
  };
  loginData: { "Total Logins": number; Date: string }[];
  providerData: { "Total Users": number; Provider: string; color: string }[];
  topProvider: string;
  totalUsers: number;
}
export interface GetTotalLoginsSchema extends SideEffectSchema {
  LoginUser: { data: GetLoginUsersSchema; err: boolean };
  TotalUsers: {
    data: { topProvider: string; totalUsers: number };
    err: boolean;
  };
  clientIP: string;
}

export interface GetTotalLoginsActionArgs {
  fromdate: string;
  todate: string;
  timezone: string;
  host: string[] | null;
}

export const getTotalLoginsAction = (args: GetTotalLoginsActionArgs) => {
  return {
    type: InsightsConst.GET_TOTAL_LOGINS,
    payload: { ...args }
  };
};
export const getTotalLoginsLoadingAction = () =>
  loadingAction(InsightsConst.GET_TOTAL_LOGINS_LOADING);

export const getTotalLoginsErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_TOTAL_LOGINS_ERROR, error);

export const getTotalLoginsSuccessAction = (data: GetTotalLoginsSchema) => {
  const payload: GetTotalLoginsSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_TOTAL_LOGINS_SUCCESS,
    payload
  };
};

/**
 * GET_RETURN_RATE
 */

interface GetReturnRateObjSchema {
  returnRateData: { "column-1": string; category: string; color: string }[];
  totalUsers: number;
}
interface GetUserEngagementSchema {
  dailyActiveData: { "Total Users": number; Date: string }[];
  monthlyActiveData: { value: number; Date: string }[];
  totalUsers: number;
}
export interface GetReturnRateSchema extends SideEffectSchema {
  ReturnRate: { data: GetReturnRateObjSchema; err: boolean };
  UserEngagement: { data: GetUserEngagementSchema; err: boolean };
  clientIP: string;
}

export interface GetReturnRateActionArgs {
  fromdate: string;
  todate: string;
  timezone: string;
  host: string[] | null;
}

export const getReturnRateAction = (args: GetReturnRateActionArgs) => {
  return {
    type: InsightsConst.GET_RETURN_RATE,
    payload: { ...args }
  };
};
export const getReturnRateLoadingAction = () =>
  loadingAction(InsightsConst.GET_RETURN_RATE_LOADING);

export const getReturnRateErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_RETURN_RATE_ERROR, error);

export const getReturnRateSuccessAction = (data: GetReturnRateSchema) => {
  const payload: GetReturnRateSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_RETURN_RATE_SUCCESS,
    payload
  };
};

/**
 * GET_HIGHEST_GROWTH__DATA
 */
export interface GetHighestGrowthDataSchema extends SideEffectSchema {
  dates: string[];
  maxUserRegistered: number;
  errorCode?: number;
  description?: string;
  status?: number;
  clientIP: string;
}
export interface GetHighestGrowthDataArgs {
  fromdate: string;
  todate: string;
  interval: "day" | "week" | "month" | "quarter" | "year";
  type: "userprofiles" | "accounts";
  host: string[] | null;
}

export const getHighestGrowthDataAction = (args: GetHighestGrowthDataArgs) => {
  return {
    type: InsightsConst.GET_HIGHEST_GROWTH_DATA,
    payload: { ...args }
  };
};

export const getHighestGrowthDataLoadingAction = () =>
  loadingAction(InsightsConst.GET_HIGHEST_GROWTH_DATA_LOADING);

export const getHighestGrowthDataErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_HIGHEST_GROWTH_DATA_ERROR, error);

export const getHighestGrowthDataSuccessAction = (
  data: GetHighestGrowthDataSchema
) => {
  const payload: GetHighestGrowthDataSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_HIGHEST_GROWTH_DATA_SUCCESS,
    payload
  };
};

/**
 * GET_BEST_REGISTRATION_STREAKS
 */
export interface GetBestRegistrationStreaksSchema extends SideEffectSchema {
  bestStreaks: {
    dateStart: string;
    doc_count: number;
    streak: number;
    dateEnd: string;
  }[];
  errorCode?: number;
  description?: string;
  status?: number;
  clientIP: string;
}
export interface GetBestRegistrationStreaksArgs {
  fromdate: string;
  todate: string;
  interval: "day" | "week" | "month" | "quarter" | "year";
  lowerBound: number;
  type: "userprofiles" | "accounts";
  host: string[] | null;
}

export const getBestRegistrationStreaksAction = (
  args: GetBestRegistrationStreaksArgs
) => {
  return {
    type: InsightsConst.GET_BEST_REGISTRATION_STREAKS,
    payload: { ...args }
  };
};

export const getBestRegistrationStreaksLoadingAction = () =>
  loadingAction(InsightsConst.GET_BEST_REGISTRATION_STREAKS_LOADING);

export const getBestRegistrationStreaksErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_BEST_REGISTRATION_STREAKS_ERROR, error);

export const getBestRegistrationStreaksSuccessAction = (
  data: GetBestRegistrationStreaksSchema
) => {
  const payload: GetBestRegistrationStreaksSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_BEST_REGISTRATION_STREAKS_SUCCESS,
    payload
  };
};

/**
 * GET_WORST_REGISTRATION_STREAKS
 */
export interface GetWorstRegistrationStreaksSchema extends SideEffectSchema {
  worstStreaks: {
    dateStart: string;
    doc_count: number;
    streak: number;
    dateEnd: string;
  }[];
  errorCode?: number;
  description?: string;
  status?: number;
  clientIP: string;
}
export interface GetWorstRegistrationStreaksArgs {
  fromdate: string;
  todate: string;
  interval: "day" | "week" | "month" | "quarter" | "year";
  upperBound: number;
  type: "userprofiles" | "accounts";
  host: string[] | null;
}

export const getWorstRegistrationStreaksAction = (
  args: GetWorstRegistrationStreaksArgs
) => {
  return {
    type: InsightsConst.GET_WORST_REGISTRATION_STREAKS,
    payload: { ...args }
  };
};

export const getWorstRegistrationStreaksLoadingAction = () =>
  loadingAction(InsightsConst.GET_WORST_REGISTRATION_STREAKS_LOADING);

export const getWorstRegistrationStreaksErrorAction = (error: string) =>
  errorAction(InsightsConst.GET_WORST_REGISTRATION_STREAKS_ERROR, error);

export const getWorstRegistrationStreaksSuccessAction = (
  data: GetWorstRegistrationStreaksSchema
) => {
  const payload: GetWorstRegistrationStreaksSchema = {
    ...data,
    ...successSideEffectState
  };
  return {
    type: InsightsConst.GET_WORST_REGISTRATION_STREAKS_SUCCESS,
    payload
  };
};

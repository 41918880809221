import { combineReducers } from "redux";
import { ActionSchema } from "../actions";
import {
  IntegrationsConst,
  GetDataExportDumpRequestsDataSchema,
  RemoveDataExportDumpRequestDataSchema,
  GetExportLogsSchema,
  GetDataSyncFeaturesSchema,
  GetWebHookRequestsSchema,
  DeleteWebHookResponseSchema,
  AddWebHookResponseSchema,
  WebHookQuotaSchema,
  SetDefaultQuotaResponseSchema,
  EtlLogsResponseSchema,
  QueueBlukActionResponseSchema,
  AvailableIntegrationsSchema,
  CreatedIntegrationsSchema,
  ActivateIntegrationSchema,
  DeactivateIntegrationSchema,
  GetIntegrationDataSchema,
  GetIntegrationConfigSchema,
  SaveIntegrationDataSchema,
  UpdateWebHookResponseSchema,
  GetAuditLogConfigSchema,
  SaveAuditLogConfigSchema,
  AuditLogBodySchema,
  ResetAuditLogConfigSchema
} from "../actions/integrationsActions";

const getDataExportDumpRequestsReducer = (
  state = {},
  action: ActionSchema<GetDataExportDumpRequestsDataSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.GET_DATA_EXPORT_DUMP_REQUESTS_LOADING:
    case IntegrationsConst.GET_DATA_EXPORT_DUMP_REQUESTS_SUCCESS:
    case IntegrationsConst.GET_DATA_EXPORT_DUMP_REQUESTS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const removeDataExportDumpRequestReducer = (
  state = {},
  action: ActionSchema<RemoveDataExportDumpRequestDataSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.REMOVE_DATA_EXPORT_DUMP_REQUEST_LOADING:
    case IntegrationsConst.REMOVE_DATA_EXPORT_DUMP_REQUEST_SUCCESS:
    case IntegrationsConst.REMOVE_DATA_EXPORT_DUMP_REQUEST_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const addDataExportDumpRequestReducer = (
  state = {},
  action: ActionSchema<GetDataExportDumpRequestsDataSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.ADD_DATA_EXPORT_DUMP_REQUEST_LOADING:
    case IntegrationsConst.ADD_DATA_EXPORT_DUMP_REQUEST_SUCCESS:
    case IntegrationsConst.ADD_DATA_EXPORT_DUMP_REQUEST_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getExportLogsReducer = (
  state = {},
  action: ActionSchema<GetExportLogsSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.GET_EXPORT_LOGS_LOADING:
    case IntegrationsConst.GET_EXPORT_LOGS_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.GET_EXPORT_LOGS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getDataSyncFeaturesReducer = (
  state = {},
  action: ActionSchema<GetDataSyncFeaturesSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.GET_DATA_SYNC_FEATURES_LOADING:
    case IntegrationsConst.GET_DATA_SYNC_FEATURES_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.GET_DATA_SYNC_FEATURES_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const getAuditLogConfigReducer = (
  state = {},
  action: ActionSchema<GetAuditLogConfigSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.GET_AUDIT_LOG_DATA_LOADING:
    case IntegrationsConst.GET_AUDIT_LOG_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.GET_AUDIT_LOG_DATA_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const saveAuditLogConfigReducer = (
  state = {},
  action: ActionSchema<SaveAuditLogConfigSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.SAVE_AUDIT_LOG_DATA_LOADING:
    case IntegrationsConst.SAVE_AUDIT_LOG_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.SAVE_AUDIT_LOG_DATA_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const resetAuditLogConfigReducer = (
  state = {},
  action: ActionSchema<ResetAuditLogConfigSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.DELETE_AUDIT_LOG_DATA_LOADING:
    case IntegrationsConst.DELETE_AUDIT_LOG_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.DELETE_AUDIT_LOG_DATA_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const getWebHookRequestsReducer = (
  state = {},
  action: ActionSchema<GetWebHookRequestsSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.GET_WEB_HOOK_REQUESTS_LOADING:
    case IntegrationsConst.GET_WEB_HOOK_REQUESTS_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.GET_WEB_HOOK_REQUESTS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const deleteWebHookReducer = (
  state = {},
  action: ActionSchema<DeleteWebHookResponseSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.DELETE_WEB_HOOK_LOADING:
    case IntegrationsConst.DELETE_WEB_HOOK_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.DELETE_WEB_HOOK_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const updateWebHookReducer = (
  state = {},
  action: ActionSchema<UpdateWebHookResponseSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.UPDATE_WEB_HOOK_LOADING:
    case IntegrationsConst.UPDATE_WEB_HOOK_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.UPDATE_WEB_HOOK_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const addWebHookReducer = (
  state = {},
  action: ActionSchema<AddWebHookResponseSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.ADD_WEB_HOOK_LOADING:
    case IntegrationsConst.ADD_WEB_HOOK_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.ADD_WEB_HOOK_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getWebHookQuotaReducer = (
  state = {},
  action: ActionSchema<WebHookQuotaSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.GET_WEB_HOOK_QUOTA_LOADING:
    case IntegrationsConst.GET_WEB_HOOK_QUOTA_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.GET_WEB_HOOK_QUOTA_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const setDefaultQuotaReducer = (
  state = {},
  action: ActionSchema<SetDefaultQuotaResponseSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.SET_DEFAULT_QUOTA_LOADING:
    case IntegrationsConst.SET_DEFAULT_QUOTA_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.SET_DEFAULT_QUOTA_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const queueBulkActionReducer = (
  state = {},
  action: ActionSchema<QueueBlukActionResponseSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.QUEUE_BULK_ACTION_LOADING:
    case IntegrationsConst.QUEUE_BULK_ACTION_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.QUEUE_BULK_ACTION_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getEtlLogsReducer = (
  state = {},
  action: ActionSchema<EtlLogsResponseSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.GET_ETL_LOGS_LOADING:
    case IntegrationsConst.GET_ETL_LOGS_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.GET_ETL_LOGS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getAvailableIntegrationsReducer = (
  state = {},
  action: ActionSchema<AvailableIntegrationsSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.GET_AVAILABLE_INTEGRATIONS_LOADING:
    case IntegrationsConst.GET_AVAILABLE_INTEGRATIONS_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.GET_AVAILABLE_INTEGRATIONS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getCreatedIntegrationsReducer = (
  state = {},
  action: ActionSchema<CreatedIntegrationsSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.GET_CREATED_INTEGRATIONS_LOADING:
    case IntegrationsConst.GET_CREATED_INTEGRATIONS_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.GET_CREATED_INTEGRATIONS_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const activateIntegrationReducer = (
  state = {},
  action: ActionSchema<ActivateIntegrationSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.ACTIVATE_INTEGRATION_LOADING:
    case IntegrationsConst.ACTIVATE_INTEGRATION_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.ACTIVATE_INTEGRATION_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const deactivateIntegrationReducer = (
  state = {},
  action: ActionSchema<DeactivateIntegrationSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.DEACTIVATE_INTEGRATION_LOADING:
    case IntegrationsConst.DEACTIVATE_INTEGRATION_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.DEACTIVATE_INTEGRATION_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getIntegrationDataReducer = (
  state = {},
  action: ActionSchema<GetIntegrationDataSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.GET_INTEGRATION_DATA_LOADING:
    case IntegrationsConst.GET_INTEGRATION_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.GET_INTEGRATION_DATA_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getIntegrationConfigReducer = (
  state = {},
  action: ActionSchema<GetIntegrationConfigSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.GET_INTEGRATION_CONFIG_LOADING:
    case IntegrationsConst.GET_INTEGRATION_CONFIG_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.GET_INTEGRATION_CONFIG_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const saveIntegrationDataReducer = (
  state = {},
  action: ActionSchema<SaveIntegrationDataSchema>
) => {
  switch (action.type) {
    case IntegrationsConst.SAVE_INTEGRATION_DATA_LOADING:
    case IntegrationsConst.SAVE_INTEGRATION_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case IntegrationsConst.SAVE_INTEGRATION_DATA_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

// Combine Reducers
const integrationsReducer = combineReducers({
  dataExportDumpRequests: getDataExportDumpRequestsReducer,
  removeDataExportDumpRequestStatus: removeDataExportDumpRequestReducer,
  addDataExportDumpRequestStatus: addDataExportDumpRequestReducer,
  exportLogs: getExportLogsReducer,
  dataSyncFeatures: getDataSyncFeaturesReducer,
  webHookRequests: getWebHookRequestsReducer,
  deleteWebHookResponse: deleteWebHookReducer,
  addWebHookResponse: addWebHookReducer,
  updateWebHookResponse: updateWebHookReducer,
  webHookQuota: getWebHookQuotaReducer,
  setDefaultQuotaResponse: setDefaultQuotaReducer,
  queueBulkActionResponse: queueBulkActionReducer,
  etlLogs: getEtlLogsReducer,
  availableIntegrations: getAvailableIntegrationsReducer,
  createdIntegrations: getCreatedIntegrationsReducer,
  activateIntegration: activateIntegrationReducer,
  deactivateIntegration: deactivateIntegrationReducer,
  integrationData: getIntegrationDataReducer,
  integrationConfig: getIntegrationConfigReducer,
  saveIntegrationData: saveIntegrationDataReducer,
  AuditLogConfig: getAuditLogConfigReducer,
  saveAuditLogConfigResponse: saveAuditLogConfigReducer,
  resetAuditLogConfigResponse: resetAuditLogConfigReducer
});

// Combine schemas
export interface IntegrationsSchema {
  dataExportDumpRequests: GetDataExportDumpRequestsDataSchema;
  removeDataExportDumpRequestStatus: RemoveDataExportDumpRequestDataSchema;
  addDataExportDumpRequestStatus: GetDataExportDumpRequestsDataSchema;
  exportLogs: GetExportLogsSchema;
  dataSyncFeatures: GetDataSyncFeaturesSchema;
  webHookRequests: GetWebHookRequestsSchema;
  deleteWebHookResponse: DeleteWebHookResponseSchema;
  addWebHookResponse: AddWebHookResponseSchema;
  updateWebHookResponse: UpdateWebHookResponseSchema;
  webHookQuota: WebHookQuotaSchema;
  setDefaultQuotaResponse: SetDefaultQuotaResponseSchema;
  queueBulkActionResponse: QueueBlukActionResponseSchema;
  etlLogs: EtlLogsResponseSchema;
  availableIntegrations: AvailableIntegrationsSchema;
  createdIntegrations: CreatedIntegrationsSchema;
  activateIntegration: ActivateIntegrationSchema;
  deactivateIntegration: DeactivateIntegrationSchema;
  integrationData: GetIntegrationDataSchema;
  integrationConfig: GetIntegrationConfigSchema;
  saveIntegrationData: SaveIntegrationDataSchema;
  AuditLogConfig: GetAuditLogConfigSchema;
  saveAuditLogConfigResponse: SaveAuditLogConfigSchema;
  resetAuditLogConfigResponse: ResetAuditLogConfigSchema;
}

export default integrationsReducer;

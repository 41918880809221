import React, { RefObject } from "react";

import ToolTip from "../ToolTip";

import "./Input.scss";

import { removeSymbols } from "../../utils";

export interface InputProps {
  accept?: string;
  ariaLabel?: string;
  ariaLabelledby?: string;
  autoComplete?: string;
  outerContainerClass?: string;
  containerClass?: string;
  className?: string;
  disabled?: boolean;
  error?: string;
  errorClass?: string;
  errorid?: string;
  group?: boolean; // adds a class called "form-group"
  hint?: string;
  id?: string;
  label?: string; // creates a form label, with data-success and data-error attributes
  labelClass?: string;
  labelid?: string;
  min?: number;
  max?: number;
  maxLength?: number;
  name?: string;
  placeholder?: string;
  required?: boolean;
  checked?: boolean;

  success?: string; // message that is displayed on success (must have a label prop)
  tabindex?: number;
  type?: string;
  validate?: boolean; // activates MDBootstrap validation and styling
  value?: string | number | string[];
  defaultValue?: string;
  autoFocus?: any;
  rows?: string;
  title?: string;
  onBlur?: (event: any) => void;
  onChange?: (event: any) => void;
  onFocus?: (event: any) => void;
  onInput?: (event: any) => void;
  onKeyUp?: (event: any) => void;
  readOnly?: boolean;
  onKeyPress?: (event: any) => void;
  onClick?: (event: any) => void;
  style?: React.CSSProperties;
  tooltip?: string;
  tooltipAriaLabel?: string;
  tooltipPlacement?: "right" | "top" | "bottom" | "left";
  tooltipIconClass?: string;
  hideOptionalLabel?: boolean;
  innerRef?: RefObject<any>;
}

const generateInputLabel = ({
  label,
  props,
  ariaLabelId,
  id,
  tooltip,
  tooltipAriaLabel,
  tooltipPlacement,
  tooltipIconClass,
  hideOptionalLabel,
}) => {
  if (label)
    return (
      <div className="d-flex align-items-center">
        <label
          className={`input-label text-truncate ${props.labelClass || ""} ${
            props.error ? "red-label" : ""
          }`}
          id={ariaLabelId}
          htmlFor={id}
        >
          {label}
          {props.required
            ? ""
            : label
            ? hideOptionalLabel
              ? ""
              : " (Optional)"
            : ""}
        </label>
        {tooltip && (
          <ToolTip
            ariaLabel={tooltipAriaLabel}
            message={tooltip}
            placement={tooltipPlacement || "right"}
            iconClass={tooltipIconClass || "help"}
          />
        )}
      </div>
    );
};

const Input = React.memo(
  ({
    ariaLabel,
    ariaLabelledby,
    ...props
  }: React.PropsWithChildren<InputProps>) => {
    const {
      outerContainerClass,
      id,
      tooltip,
      tooltipAriaLabel,
      tooltipPlacement,
      tooltipIconClass,
      label,
      hideOptionalLabel,
    } = props;
    const ariaLabelId = props.labelid || `lbl_${removeSymbols(label || "")}`;
    const className = props.className === undefined ? "" : props.className;

    const { labelClass, children, ..._props } = props;

    return (
      <div className={`input-container ${outerContainerClass || ""}`}>
        <div className="form-group">
          {generateInputLabel({
            label,
            props,
            ariaLabelId,
            id,
            tooltip,
            tooltipAriaLabel,
            tooltipPlacement,
            tooltipIconClass,
            hideOptionalLabel,
          })}
          <span
            className={`label-hint text-truncate ${labelClass || ""} ${
              props.error ? "red-label" : ""
            }`}
          >
            {props.hint || ""}
          </span>
          <input
            {..._props}
            ref={props.innerRef}
            aria-label={ariaLabel || label}
            aria-labelledby={ariaLabelledby || ariaLabelId}
            className={`form-control form-control-md input-field ${className} ${
              props.error ? "red-underline" : ""
            }`}
          />
          {children}
          {props.errorid && (
            <span
              className={`input-error ${props.errorClass || ""}`}
              id={props.errorid}
            >
              {props.error}
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default Input;

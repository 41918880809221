import React from "react";

export interface NavbarToggleButtonProps {
  targetId: string;
}

const NavbarToggleButton: React.FC<NavbarToggleButtonProps> = props => {
  return (
    <button
      id="btn_NavbarToggle"
      className="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target={`#${props.targetId}`}
      aria-controls="navbarContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <div
        id="navbar-toggler-icon"
        className="navbar-toggler-icon default-color-toggler"
      >
        <span />
      </div>
    </button>
  );
};

export default NavbarToggleButton;

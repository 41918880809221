import { ActionSchema } from "../actions";
import {
  WebAppsSchema,
  WebAppsConst,
  CustomDomainConst,
  CustomDomainSchema,
  MobileAppsConst,
  MobileAppsSchema,
  AddMobileAppsSchema,
  DeleteMobileAppsSchema,
  DeploymentConst,
  GetHostedPageSchema,
  ResetHostedPageSchema,
  ResetHostedPageResponseSchema,
  FetchHostedPageUrlSchema,
  FetchHostedPageUrlResponseSchema,
  JSWidgetsConst,
  SaveSettingSchema,
  CustomRegistrationField,
  GetCustomFieldSchema,
  GetRegistarationFieldSchema,
  SMTPSettingSchema,
  VerifySMTPSettingResponseSchema,
  SMTPSettingsConst,
  ResetSMTPSettingResponseSchema,
  IEFConst,
  IdentityExperienceFrameworkCustomizeStateSchema,
  MultiIEFSchema,
  EditCodeStateSchema,
  BasicEditorStateSchema,
  ThemeStateSchema,
  SaveCustomDomainSchema,
  DeleteCustomDomainSchema
} from "../actions/deploymentActions";
import { combineReducers } from "redux";
import { staticData } from "../../utils/constants";

//Reducers
const optionalCaptchaReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case JSWidgetsConst.UPDATE_OPTIONAL_CAPTCHA_LOADING:
    case JSWidgetsConst.UPDATE_OPTIONAL_CAPTCHA_SUCCESS:
    case JSWidgetsConst.UPDATE_OPTIONAL_CAPTCHA_ERROR:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const updateDataSchemaProps = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case CustomRegistrationField.UPDATE_DATASCHEMA_FIELDS_DATA:
    case CustomRegistrationField.UPDATE_DATASCHEMA_FIELDS_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};
const platformFieldSettingReducer = (state = {}, action: ActionSchema<any>) => {
  switch (action.type) {
    case CustomRegistrationField.GET_PLATFORM_FIELD_LOADING:
    case CustomRegistrationField.GET_PLATFORM_FIELD_SUCCESS:
    case CustomRegistrationField.GET_PLATFORM_FIELD_ERROR:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
const registrationFormsCustomeFieldSettingReducer = (
  state = {},
  action: ActionSchema<any>
) => {
  switch (action.type) {
    case CustomRegistrationField.GET_CUSTOM_FIELD_LOADING:
    case CustomRegistrationField.GET_CUSTOM_FIELD_SUCCESS:
    case CustomRegistrationField.GET_CUSTOM_FIELD_ERROR:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
const registrationFormsRegistrationFieldSettingReducer = (
  state = {},
  action: ActionSchema<any>
) => {
  switch (action.type) {
    case CustomRegistrationField.GET_REGISTRATION_FORMS_FIELD_LOADING:
    case CustomRegistrationField.GET_REGISTRATION_FORMS_FIELD_SUCCESS:
    case CustomRegistrationField.GET_REGISTRATION_FORMS_FIELD_ERROR:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
const platformRegistrationFieldSettingReducer = (
  state = {},
  action: ActionSchema<any>
) => {
  switch (action.type) {
    case CustomRegistrationField.POST_PLATFORM_FIELD_LOADING:
    case CustomRegistrationField.POST_PLATFORM_FIELD_SUCCESS:
    case CustomRegistrationField.POST_PLATFORM_FIELD_ERROR:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
const jsWidgetAdvanceOptionReduser = (
  state = {},
  action: ActionSchema<any>
) => {
  switch (action.type) {
    case JSWidgetsConst.SAVE_ADVANCE_OPTION_LOADING:
    case JSWidgetsConst.SAVE_ADVANCE_OPTION_SUCCESS:
    case JSWidgetsConst.SAVE_ADVANCE_OPTION_ERROR:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const webAppsReducer = (state = {}, action: ActionSchema<WebAppsSchema>) => {
  switch (action.type) {
    case WebAppsConst.GET_WEB_APPS_LOADING:
    case WebAppsConst.GET_WEB_APPS_SUCCESS:
    case WebAppsConst.GET_WEB_APPS_ERROR:
    case WebAppsConst.ADD_WEB_APPS_LOADING:
    case WebAppsConst.ADD_WEB_APPS_SUCCESS:
    case WebAppsConst.ADD_WEB_APPS_ERROR:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const customDomainReducer = (
  state = {},
  action: ActionSchema<CustomDomainSchema>
) => {
  switch (action.type) {
    case CustomDomainConst.GET_CUSTOM_DOMAIN_LOADING:
    case CustomDomainConst.GET_CUSTOM_DOMAIN_SUCCESS:
    case CustomDomainConst.GET_CUSTOM_DOMAIN_ERROR:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
const saveCustomDomainReducer = (
  state = {},
  action: ActionSchema<SaveCustomDomainSchema>
) => {
  switch (action.type) {
    case CustomDomainConst.SAVE_CUSTOM_DOMAIN_LOADING:
    case CustomDomainConst.SAVE_CUSTOM_DOMAIN_ERROR:
    case CustomDomainConst.SAVE_CUSTOM_DOMAIN_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const deleteCustomDomainReducer = (
  state = {},
  action: ActionSchema<DeleteCustomDomainSchema>
) => {
  switch (action.type) {
    case CustomDomainConst.DELETE_CUSTOM_DOMAIN_LOADING:
    case CustomDomainConst.DELETE_CUSTOM_DOMAIN_ERROR:
    case CustomDomainConst.DELETE_CUSTOM_DOMAIN_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const iefReducer = (state = {}, action: ActionSchema<MultiIEFSchema>) => {
  switch (action.type) {
    case DeploymentConst.GET_IEF_LOADING:
    case DeploymentConst.GET_IEF_ERROR:
    case DeploymentConst.GET_IEF_SUCCESS:
    case DeploymentConst.GET_IEF_BY_TYPE_AND_THEME_LOADING:
    case DeploymentConst.GET_IEF_BY_TYPE_AND_THEME_ERROR:
    case DeploymentConst.GET_IEF_BY_TYPE_AND_THEME_SUCCESS:
    case DeploymentConst.UPDATE_IEF_LOADING:
    case DeploymentConst.UPDATE_IEF_ERROR:
    case DeploymentConst.UPDATE_IEF_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const hostedPageReducer = (state = {}, action: ActionSchema<any>) => {
  let { payload } = action,
    newPayload = {};

  switch (action.type) {
    case DeploymentConst.GET_HOSTED_PAGE_LOADING:
    case DeploymentConst.GET_HOSTED_PAGE_ERROR:
    case DeploymentConst.SAVE_HOSTED_PAGE_LOADING:
    case DeploymentConst.SAVE_HOSTED_PAGE_ERROR:
    case DeploymentConst.UPDATE_HOSTED_PAGE_LOADING:
    case DeploymentConst.UPDATE_HOSTED_PAGE__ERROR:
      return { ...state, ...payload };
    case DeploymentConst.GET_HOSTED_PAGE_SUCCESS:
    case DeploymentConst.SAVE_HOSTED_PAGE_SUCCESS:
    case DeploymentConst.UPDATE_HOSTED_PAGE_SUCCESS: {
      //TODO remove below static code once partner api done with enhacement.
      const { isLoading, isError, isSuccess } = payload;
      if (payload.errorCode === 1069)
        newPayload = { ...staticData, errorCode: 1069 };
      else if (payload.errorCode !== 1069 && payload.errorCode)
        throw new Error(payload.message || "");
      else if (payload.Pages.length < 1)
        newPayload = { ...staticData, ...payload, errorCode: undefined };
      else if (payload.Pages.length > 1) {
        if (payload.Pages[0].PageType === "Auth")
          newPayload = {
            Pages: [
              { ...staticData.Pages[0], ...payload.Pages[0] },
              { ...staticData.Pages[1], ...payload.Pages[1] }
            ]
          };
        else
          newPayload = {
            Pages: [
              { ...staticData.Pages[0], ...payload.Pages[1] },
              { ...staticData.Pages[1], ...payload.Pages[0] }
            ]
          };
        return {
          ...state,
          ...newPayload,
          ...{ isLoading, isError, isSuccess },
          errorCode: undefined
        };
      } else if (payload.Pages.length === 1) {
        if (payload.Pages[0].PageType === "Auth")
          newPayload = {
            Pages: [
              { ...staticData.Pages[0], ...payload.Pages[0] },
              { ...staticData.Pages[1] }
            ],
            errorCode: "Profile"
          };
        else
          newPayload = {
            Pages: [
              { ...staticData.Pages[0] },
              { ...staticData.Pages[1], ...payload.Pages[0] }
            ],
            errorCode: "Auth"
          };
        return {
          ...state,
          ...newPayload,
          ...{ isLoading, isError, isSuccess }
        };
      } else newPayload = { ...newPayload, errorCode: undefined };

      return {
        ...state,
        ...newPayload,
        ...{ isLoading, isError, isSuccess }
      };
    }
    default:
      return state;
  }
};

const resetHostedPageReducer = (
  state = {},
  action: ActionSchema<ResetHostedPageSchema>
) => {
  switch (action.type) {
    case DeploymentConst.RESET_HOSTED_PAGE_LOADING:
    case DeploymentConst.RESET_HOSTED_PAGE_ERROR:
    case DeploymentConst.RESET_HOSTED_PAGE_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const fetchHostedPageUrlReducer = (
  state = {},
  action: ActionSchema<FetchHostedPageUrlSchema>
) => {
  switch (action.type) {
    case DeploymentConst.FETCH_HOSTED_PAGE_URL_LOADING:
    case DeploymentConst.FETCH_HOSTED_PAGE_URL_ERROR:
    case DeploymentConst.FETCH_HOSTED_PAGE_URL_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const getmobileAppsReducer = (
  state = {},
  action: ActionSchema<MobileAppsSchema>
) => {
  switch (action.type) {
    case MobileAppsConst.GET_MOBILE_APPS_SOTT_LOADING:
    case MobileAppsConst.GET_MOBILE_APPS_SOTT_ERROR:
    case MobileAppsConst.GET_MOBILE_APPS_SOTT_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
const deletemobileAppsReducer = (
  state = {},
  action: ActionSchema<DeleteMobileAppsSchema>
) => {
  switch (action.type) {
    case MobileAppsConst.REVOKE_SOTT_SUCCESS:
    case MobileAppsConst.REVOKE_SOTT_ERROR:
    case MobileAppsConst.REVOKE_SOTT_LOADING:
    case MobileAppsConst.REVOKE_ALL_SOTT_LOADING:
    case MobileAppsConst.REVOKE_ALL_SOTT_ERROR:
    case MobileAppsConst.REVOKE_ALL_SOTT_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const addmobileAppsReducer = (
  state = {},
  action: ActionSchema<MobileAppsSchema>
) => {
  switch (action.type) {
    case MobileAppsConst.ADD_MOBILE_APPS_SOTT_LOADING:
    case MobileAppsConst.ADD_MOBILE_APPS_SOTT_ERROR:
    case MobileAppsConst.ADD_MOBILE_APPS_SOTT_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
const getSMTPSettingsReducer = (
  state = {},
  action: ActionSchema<SMTPSettingSchema>
) => {
  switch (action.type) {
    case DeploymentConst.GET_SMTP_SETTINGS_LOADING:
    case DeploymentConst.GET_SMTP_SETTINGS_ERROR:
    case DeploymentConst.GET_SMTP_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const saveSMTPSettingsReducer = (
  state = {},
  action: ActionSchema<SMTPSettingSchema>
) => {
  switch (action.type) {
    case SMTPSettingsConst.SAVE_SMTP_SETTINGS_LOADING:
    case SMTPSettingsConst.SAVE_SMTP_SETTINGS_ERROR:
    case SMTPSettingsConst.SAVE_SMTP_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
const verifySMTPSettingsReducer = (
  state = {},
  action: ActionSchema<VerifySMTPSettingResponseSchema>
) => {
  switch (action.type) {
    case SMTPSettingsConst.VERIFY_SMTP_SETTINGS_LOADING:
    case SMTPSettingsConst.VERIFY_SMTP_SETTINGS_ERROR:
    case SMTPSettingsConst.VERIFY_SMTP_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const resetSMTPSettingsReducer = (
  state = {},
  action: ActionSchema<ResetSMTPSettingResponseSchema>
) => {
  switch (action.type) {
    case SMTPSettingsConst.RESET_SMTP_SETTINGS_LOADING:
    case SMTPSettingsConst.RESET_SMTP_SETTINGS_ERROR:
    case SMTPSettingsConst.RESET_SMTP_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

const iefCustomizeStateReducer = (
  state = {},
  action: ActionSchema<IdentityExperienceFrameworkCustomizeStateSchema>
) => {
  switch (action.type) {
    case IEFConst.SET_IEF_STATE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const customizeEditCodeStateReducer = (
  state = {},
  action: ActionSchema<EditCodeStateSchema>
) => {
  switch (action.type) {
    case IEFConst.SET_EDIT_CODE_STATE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const customizebasicEditorStateReducer = (
  state = {},
  action: ActionSchema<BasicEditorStateSchema>
) => {
  switch (action.type) {
    case IEFConst.SET_BASIC_EDITOR_STATE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const themeStateReducer = (
  state = {},
  action: ActionSchema<ThemeStateSchema>
) => {
  switch (action.type) {
    case IEFConst.SET_THEME_STATE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

// Combine Schemas
export interface DeploymentSchema {
  webApps: WebAppsSchema;
  customDomain: CustomDomainSchema;
  hostedPage: GetHostedPageSchema;
  resetHostedPage: ResetHostedPageResponseSchema;
  fetchHostedPageUrl: FetchHostedPageUrlResponseSchema;
  advanceOption: SaveSettingSchema;
  getmobileApps: MobileAppsSchema;
  addmobileApps: AddMobileAppsSchema;
  deletemobileApps: DeleteMobileAppsSchema;
  customFieldCollection: GetCustomFieldSchema;
  registrationFieldCollection: GetRegistarationFieldSchema;
  platformFieldSetting: GetRegistarationFieldSchema;
  platformFiled: GetRegistarationFieldSchema;
  optionCaptchaSetting: any;
  smtpSettings: SMTPSettingSchema;
  saveSMTPSettingsStatus: SMTPSettingSchema;
  verifySMTPSettingsStatus: VerifySMTPSettingResponseSchema;
  resetSMTPSettingsStatus: ResetSMTPSettingResponseSchema;
  dataSchemaFields: any;
  iefCustomizeState: IdentityExperienceFrameworkCustomizeStateSchema;
  editCodeState: EditCodeStateSchema;
  basicEditorState: BasicEditorStateSchema;
  themeState: ThemeStateSchema;
  ief: MultiIEFSchema;
  saveCustomDomain: SaveCustomDomainSchema;
  deleteCustomDomain: DeleteCustomDomainSchema;
}

// Combine Reducers
const deploymentReducer = combineReducers({
  webApps: webAppsReducer,
  customDomain: customDomainReducer,
  hostedPage: hostedPageReducer,
  resetHostedPage: resetHostedPageReducer,
  fetchHostedPageUrl: fetchHostedPageUrlReducer,
  advanceOption: jsWidgetAdvanceOptionReduser,
  getmobileApps: getmobileAppsReducer,
  addmobileApps: addmobileAppsReducer,
  deletemobileApps: deletemobileAppsReducer,
  customFieldCollection: registrationFormsCustomeFieldSettingReducer,
  registrationFieldCollection: registrationFormsRegistrationFieldSettingReducer,
  optionCaptchaSetting: optionalCaptchaReducer,
  platformFieldSetting: platformFieldSettingReducer,
  smtpSettings: getSMTPSettingsReducer,
  saveSMTPSettingsStatus: saveSMTPSettingsReducer,
  verifySMTPSettingsStatus: verifySMTPSettingsReducer,
  resetSMTPSettingsStatus: resetSMTPSettingsReducer,
  platformFiled: platformRegistrationFieldSettingReducer,
  dataSchemaFields: updateDataSchemaProps,
  iefCustomizeState: iefCustomizeStateReducer,
  ief: iefReducer,
  customizeEditCodeState: customizeEditCodeStateReducer,
  basicEditorState: customizebasicEditorStateReducer,
  themeState: themeStateReducer,
  saveCustomDomain: saveCustomDomainReducer,
  deleteCustomDomain: deleteCustomDomainReducer
});

export default deploymentReducer;

import * as React from "react";
import "./CenterDiv.scss";
import { ObjectSchema } from "../../redux/actions";
export interface CenterDivProps {
  id?: string;
  content?: JSX.Element;
  className?: string;
  style?: ObjectSchema<number | string>;
}

const CenterDiv: React.FC<CenterDivProps> = props => {
  let { className, style, id } = props;

  return (
    <div
      id={id || "dv_CenterDiv"}
      className={"lr-modal-backdrop " + (className ? className : "")}
      style={style ? style : {}}
    >
      {props.content}
      {props.children}
    </div>
  );
};

export default CenterDiv;

import React, { Component } from "react";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from "mdbreact";
import Button, { ButtonProps } from "../Button";
import ButtonWithIcon from "../Button/ButtonWithIcon";
import "./Popup.scss";
import { removeSymbols } from "../../utils";
// // schema to set up each button on the Popup. This should be done in the component/container that uses the Popup
// export interface PopupButtonSchema {
//   displayName: string;
//   color: "primary" | "secondary"; // "primary" and "secondary" are the two color options that has been customized. Primary button adheres to LoginRadius' color and secondary button is flat style by MDB
//   onClick: (data: any) => void; // this is the callback that is invoked once the button is clicked
// }
//
export interface PopupProps {
  isOpen: boolean;
  title?: string;
  body?: JSX.Element; // a JSX component can be passed in to render the body of the dialog
  buttons?: ButtonProps[];
  icon?: string;
  spin?: boolean;
  size?: string; // default value is "md" (medium), possible values are "sm" for small, "lg" for large and "fluid" for full width modal
  toggle: () => void; // this is the callback that will be called when the dialog is dismissed (by clicking outside of the dialog, etc...).
  // a state has to be maintained on the parent component level to handle show/hide of the popup.
  className?: string;
  overflowHidden?: boolean;
  overflowVisible?: boolean;
}

class Popup extends Component<PopupProps> {
  componentDidMount() {
    if (this.props.isOpen) {
      window.document.body.style.overflow = "hidden";
    }
  }
  componentDidUpdate(oldProps: PopupProps) {
    if (!oldProps.isOpen && this.props.isOpen) {
      window.document.body.style.overflow = "hidden";
    }
    if (oldProps.isOpen && !this.props.isOpen && !this.props.overflowHidden) {
      window.document.body.style.overflow = "auto";
    }
  }
  componentWillUnmount() {
    // Enable body scroll
    if (!this.props.overflowHidden) {
      window.document.body.style.overflow = "auto";
    }
  }

  generatePopupButtons = () => {
    return this.props.buttons
      ? this.props.buttons.map((button, index) => (
          <React.Fragment key={index}>
            {button.icon && (
              <ButtonWithIcon
                id={"btn_" + removeSymbols(button.value || "default")}
                className={"" + (button.className || "")}
                color={button.color === "secondary" ? "ghost" : button.color}
                spin={button.spin}
                onClick={button.onClick}
                icon={button.icon}
                value={button.value}
                disabled={button.disabled}
              />
            )}
            {!button.icon && (
              <Button
                id={"btn_" + removeSymbols(button.value || "default")}
                className={"" + (button.className || "")}
                color={button.color === "secondary" ? "ghost" : button.color}
                onClick={button.onClick}
                value={button.value}
                disabled={button.disabled}
              />
            )}
          </React.Fragment>
        ))
      : "";
  };

  render() {
    return (
      <MDBModal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        centered
        size={this.props.size}
        className={this.props.className}
      >
        {this.props.title && (
          <MDBModalHeader
            id={"dv_Title"}
            toggle={this.props.toggle}
            className="global-modal-header"
          >
            {this.props.title}
          </MDBModalHeader>
        )}
        <MDBModalBody
          style={{
            overflow: this.props.overflowVisible ? "visible" : ""
          }}
          id={"dv_Body"}
        >
          {this.props.body}
        </MDBModalBody>
        <MDBModalFooter>{this.generatePopupButtons()}</MDBModalFooter>
      </MDBModal>
    );
  }
}

export default Popup;
